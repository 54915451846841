import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { searchNutrition, searchBrandedFoods, searchFoundationFoods } from '../actions/dashboardActions'
import { Card, CardContent, CircularProgress, Slide, Backdrop, Fade } from '@material-ui/core'
import { ModalSlide, Chip, FoodCard, FavoritesCards, CustomFoodCards, SearchBar, Image, Selector } from './'
import AddIcon from '@material-ui/icons/Add'
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import api from '../api'
import { Color, toTitleCase } from '../util'
import { createRef } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { BiBarcodeReader } from 'react-icons/bi'
import SearchIcon from '@material-ui/icons/SearchRounded'
import apis from "../api";
import InfiniteScroll from 'react-infinite-scroll-component'

const SearchListing = (props) => {
    const renderFood = (food) => {
        if (!food) return null
        switch (food.class) {
            case ('custom') :
            case ('recipe') : {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        { props.food.image_url ? <Image src={props.food.image_url} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', backgroundColor: 'grey', border: 'none', marginRight: '8px' }}/> : null }
                        <p style={{ margin: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}> { toTitleCase(props.food.name) } </p>       
                        <div style={{ marginLeft: 'auto', padding: '3px', backgroundColor: 'rgba(0,0,0,.05)', color: Color.PRIMARY, borderRadius: '50%' }}>
                            <AddIcon/>
                        </div>
                    </div>
                )
            }

            case ('branded') :
            case ('common') : {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        { props.food.photo.thumb ? <Image src={props.food.photo.thumb} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', backgroundColor: 'grey', border: 'none', marginRight: '8px' }}/> : null }
                        <div style={{ maxWidth: '250px' }}>
                            <p style={{ margin: '0px', marginRight: '10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}> { toTitleCase(props.food.food_name) }  </p>       
                            { props.food.brand_name ? <p style={{ margin: '0px', marginRight: '10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '10px', opacity: '.7' }}> { toTitleCase(props.food.brand_name) }  </p> : null }      
                        </div>
                        <div style={{ marginLeft: 'auto', padding: '3px', backgroundColor: 'rgba(0,0,0,.05)', color: Color.PRIMARY, borderRadius: '50%' }}>
                            <AddIcon/>
                        </div>
                    </div>
                )
            }
        }
    }

    return props.food ? <Fade in={true}>
            <div style={{ margin: '10px 0px 10px 0px', padding: '8px', backgroundColor: 'rgba(0,0,0,.05)', borderRadius: '10px', fontSize: '14px' }} onClick={() => props.handleInfoOpen?.({ meal: props.food, populate: true })}>
            { renderFood(props.food) } 
        </div>
    </Fade> : null
}

const AutocompleteResults = ({ results, loading, search, handleInfoOpen, ...props }) => {
    const renderSearchResults = (results) => {
        let render = <div style={{ fontSize: '14px',  marginBottom: '4px', display: 'flex', flexDirection: 'column', gap: '2px', marginBottom: '60px' }}>
            <Fade in={true} onClick={(e) => props.onViewAll(e, 'All', search)}>
                <p style={{ textAlign: 'center', opacity: '.7', marginTop: '10px', marginBottom: '10px', height: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}> <SearchIcon style={{ backgroundColor: '#CCC', borderRadius: '50%', padding: '5px', fontSize: '26px' }}/> Search for "{search}" </p>
            </Fade>
            { results.common && results.common.length ? <div style={{ marginBottom: '10px' }}>
                {
                    results.common.map((result, i) => {
                        return <SearchListing key={i} food={result} handleInfoOpen={handleInfoOpen} handleAddOpen={props.handleAddOpen}/>
                    })
                }
                { props.onViewAll ? <p style={{ textAlign: 'center', color: Color.PRIMARY, margin: '0px', fontSize: '14px' }} onClick={(e) => props.onViewAll(e, 'Generic', search)}> View All Common </p> : null }
            </div> : null }  
            { results.branded && results.branded.length ? <div style={{ marginBottom: '10px' }}>
                {
                    results.branded.map((result, i) => {
                        return <SearchListing key={i} food={result} handleInfoOpen={handleInfoOpen} handleAddOpen={props.handleAddOpen}/>
                    })
                }
                { props.onViewAll ? <p style={{ textAlign: 'center', color: Color.PRIMARY, margin: '0px', fontSize: '14px' }} onClick={(e) => props.onViewAll(e, 'Branded', search)}> View All Branded </p> : null }
            </div> : null }
        </div>

        if (!search) {
            return null
        } else if (loading) {
            return (<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' , height: '28px' }}>
                <CircularProgress size={25} key={1}/>
            </div>)
        }

        return render
    }

    return (
        <>
            { renderSearchResults(results) }
        </>
    )
}

class CustomMealSearchModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            autocompleteResults: [],
            autocompleteLoading: false,
            searchDisplayTypes: ['Search'],
            searchDisplayType: 'Search',
            searchModes: ['All', 'Generic', 'Branded'],
            searchMode: 'All',
            searchVal: '',
            searchConfirm: '',
            history: [],
            historyLoading: false,
            historyLoaded: false,
            historyHasMore: true,
            historyPage: 0,
            back: true,
            scrollPosition: 0,
            barcodeOpen: false,
            barcodeLoading: false,
            barcodeError: false,
            common: [],
            branded: [],
            recipes: [],
            combined: [],
            commonTerm: '',
            brandedTerm: '',
            recipesTerm: '',
            combinedTerm: '',
            searchLoading: false,
            chips: {}
        }
    }

    componentDidMount() {
        this.search = createRef()
        this.content = createRef()
        this.chipContainer = createRef()

        let chips = {}
        this.state.searchDisplayTypes.map((type) => chips[type] = createRef())
        this.setState({ chips })
    }
    
    renderHistory = () => {
        return this.state.history.map((meal, i) => {
            return <FoodCard key={i} food={meal.target} meal={false} time={this.props.time} handleAddOpen={this.props.handleAddOpen} handleInfoOpen={this.props.handleInfoOpen}/>
        })
    }

    renderSearchDisplay = () => {
        if (this.state.searchVal !== this.state.searchConfirm && this.state.searchVal.length) return <AutocompleteResults loading={this.state.autocompleteLoading} search={this.state.searchVal} handleInfoOpen={this.props.handleInfoOpen} onViewAll={this.handleSearchViewAll} results={this.state.autocompleteResults}/>
        switch(this.state.searchDisplayType) {
            case ('Recents') : return <div>
                <InfiniteScroll scrollableTarget='scrollableDiv' dataLength={this.state.history.length} hasMore={this.state.historyHasMore} next={this.loadHistory} loader={
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginBottom: '10px', height: '100%' }}>
                        <CircularProgress size={25}/>
                    </div>}>
                    { 
                        this.state.history.length && this.state.historyLoaded ? this.state.history.map((meal, i) => {
                            return <FoodCard key={i} food={meal.target} meal={false} time={this.props.time} handleAddOpen={this.props.handleAddOpen} handleInfoOpen={this.props.handleInfoOpen}/>
                        }) : (!this.state.history.length && !this.state.historyLoading ? <p style={{ marginTop: '30px', textAlign: 'center', opacity: '.7' }}> No Recents </p> : null)
                    }
                </InfiniteScroll>
            </div>

            case ('Favorites') : return <div>
                <div style={{ height: 'auto' }}>
                    <FavoritesCards exclusions={false} open={true} type={'food'} handleAddOpen={this.props.handleAddOpen} handleInfoOpen={this.props.handleInfoOpen}/>
                </div>
            </div>

            case ('Custom') : return <div>
                <CustomFoodCards open={true} handleAddOpen={this.props.handleAddOpen} handleInfoOpen={this.props.handleInfoOpen} handleCustomUpdateOpen={this.props.handleCustomUpdateOpen}/>
            </div>

            case ('Search') : {
                switch (this.state.searchMode) {
                    case ('All') : return <div style={{ marginBottom: '60px' }}>
                        { !this.state.searchLoading ? (this.state.combined.length ? this.state.combined.map((food, i) => {
                            return <SearchListing key={i + this.state.searchDisplayType} food={food} handleInfoOpen={this.props.handleInfoOpen}/>
                        }) : <p style={{ marginTop: '30px', textAlign: 'center', opacity: '.7' }}> Start Searching Above </p>) : <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginBottom: '10px' , height: '28px' }}>
                            <CircularProgress size={25}/>
                        </div> }
                    </div>

                    case ('Branded') : return <div style={{ marginBottom: '60px' }}>
                        { !this.state.searchLoading ? (this.state.branded.length ? this.state.branded.map((food, i) => {
                            return <SearchListing key={i + this.state.searchDisplayType} food={food} handleInfoOpen={this.props.handleInfoOpen}/>
                        }) : <p style={{ marginTop: '30px', textAlign: 'center', opacity: '.7' }}> Start Searching Above </p>) : <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginBottom: '10px' , height: '28px' }}>
                            <CircularProgress size={25}/>
                        </div> }
                    </div>
    
                    case ('Generic') : return <div style={{ marginBottom: '60px' }}>
                        { !this.state.searchLoading ? (this.state.common.length ? this.state.common.map((food, i) => {
                            return <SearchListing key={i + this.state.searchDisplayType} food={food} handleInfoOpen={this.props.handleInfoOpen}/>
                        }) : <p style={{ marginTop: '30px', textAlign: 'center', opacity: '.7' }}> Start Searching Above </p>) : <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginBottom: '10px' , height: '28px' }}>
                            <CircularProgress size={25}/>
                        </div> }
                    </div>
        
                    case ('Recipes') : return <div style={{ marginBottom: '60px' }}>
                        { !this.state.searchLoading ? (this.state.recipes.length ? this.state.recipes.map((food, i) => {
                            return <SearchListing key={i + this.state.searchDisplayType} food={food} handleInfoOpen={this.props.handleInfoOpen}/>
                        }) : <p style={{ marginTop: '30px', textAlign: 'center', opacity: '.7' }}> Start Searching Above </p>) : <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginBottom: '10px' , height: '28px' }}>
                            <CircularProgress size={25}/>
                        </div> }
                    </div>   
                    
                    default: return <p> Unknown Search Mode </p>
                }
            }

            default : return <h6> Unknown Tab </h6>
        }
    }

    handleScroll = (event) => {
        let scrollPosition = event.target.scrollTop
        if (scrollPosition < 0) scrollPosition = 0
        if (scrollPosition > this.state.scrollPosition ) this.setState({ back: false, scrollPosition })
        else this.setState({ back: true, scrollPosition })
    }

    handleSearchDisplayChange = async (type, query) => {
        this.setState({ searchMode: type })
        switch (type) {
            case ('All') : {
                if (query === this.state.combinedTerm) return null         
                try {
                    this.setState({ searchLoading: true })
                    let response = await apis.searchFoods({ params: { q: query, branded: true, common: true, combined: true }})
                    this.setState({ combined: response.data.combined, searchLoading: false, combinedTerm: this.state.searchConfirm })
                } catch (e) {
                    this.setState({ searchLoading: false })
                }

                break
            }

            case ('Branded'): {       
                if (query === this.state.brandedTerm) return null         
                try {
                    this.setState({ searchLoading: true })
                    let response = await apis.searchFoods({ params: { q: query, branded: true }})
                    this.setState({ branded: response.data.branded, searchLoading: false, brandedTerm: this.state.searchConfirm })
                } catch (e) {
                    this.setState({ searchLoading: false })
                }

                break
            }

            case ('Generic'): {
                if (query === this.state.commonTerm) return null         
                try {
                    this.setState({ searchLoading: true })
                    let response = await apis.searchFoods({ params: { q: query, common: true }})
                    this.setState({ common: response.data.common, searchLoading: false, commonTerm: this.state.searchConfirm })
                } catch (e) {
                    this.setState({ searchLoading: false })
                }

                break
            }

            default: return null
        }
    }

    handleBarcode = (data) => {
        if (this.state.barcodeLoading || this.state.barcodeError || !data) return null
        this.setState({ barcodeLoading: true })
        apis.getBrandedByUPC(data, {}).then((response) => {
            this.setState({ barcodeLoading: false, barcodeOpen: false })
            this.props.handleInfoOpen({ meal: response.data })
        }).catch((error) => {
            this.setState({ barcodeLoading: false, barcodeError: true })
        })
    }

    handleSearchViewAll = (event, type, value) => {
        this.setState({ searchConfirm: this.state.searchVal, searchDisplayType: 'Search' }, () => {
            return this.handleSearchDisplayChange(type, value)
        })
    }

    handleSearchChange = (event) => {
        this.setState({ searchVal: event.target.value })
    }

    handleSearchKeypress = (event) => {
        if (event.key !== 'Enter') return null
        this.setState({ searchDisplayType: 'Search', searchConfirm: event.target.value })
        this.handleSearchDisplayChange(this.state.searchMode, event.target.value)
    }

    handleRetry = (x, e) => {
        e.stopPropagation()
        this.setState({ barcodeError: false })
    }

    handleAutocompleteChange = (results) => {
        this.setState({
            autocompleteResults: results
        })
    } 

    handleAutocompleteLoading = (loading) => {
        this.setState({
            autocompleteLoading: loading
        })
    }

    render() {
        return (
            <div>
                <ModalSlide direction='left' in={this.props.open}>
                    <Card id='scrollableDiv' onScroll={this.handleScroll} style={{ zIndex: '1000', width: '100%', height: '100%', overflowY: 'auto', overflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', boxShadow: '' }}>
                        <CardContent style={{ padding: '20px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <BackIcon style={{ fontSize: '22px', marginRight: '8px', opacity: '.5' }} onClick={this.props.handleClose}/>
                                <h5 style={{ marginBottom: '0px' }}> Add {this.props.tod ? toTitleCase(this.props.tod) : 'Item'} to Meal </h5>
                            </div>

                            { this.state.barcodeOpen ? <div style={{ position: 'fixed', height: '100%', width: '100%', top: '0px', left: '0px', zIndex: '4000' }}>
                                <Backdrop in={true}/>
                                { !this.state.barcodeError ? <div style={{ height: '100%' }}>
                                    <BarcodeScannerComponent onUpdate={(err, data) => this.handleBarcode(data?.text)}/>
                                        { this.state.barcodeLoading ? 
                                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)', padding: '10px', backgroundColor: 'white', borderRadius: '50%', fontSize: '0px', zIndex: '5001' }}>
                                                <CircularProgress size={20}/>
                                            </div>
                                        : null }
                                    <div style={{ position: 'absolute', left: '50%', bottom: '150px', transform: 'translateX(-50%)', color: 'white', backgroundColor: Color.PRIMARY, padding: '6px 9px', borderRadius: '25px'}} onClick={() => this.setState({ barcodeOpen: false })}> Close Scanner </div>
                                    <div style={{ border: `2px solid rgba(255, 255, 255, 1)`,  boxShadow: '0 0 490px black', borderRadius: '15px', height: '150px', width: '300px', zIndex: '5000', position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)' }}/>
                                </div> : <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translateX(-50%) translateY(-50%)', display: 'flex', flexDirection: 'column'}}>
                                    <p style={{ marginBottom: '5px', color: 'white' }}> UPC not found </p>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Chip style={{ backgroundColor: 'red', border: 'none', color: 'white' }} label={'Retry'} handleClick={this.handleRetry}/>
                                    </div>
                                </div> }
                            </div> : null }

                            <SearchBar suff={<BiBarcodeReader style={{ fontSize: '22px', marginRight: '5px' }} onClick={() => this.setState({ barcodeOpen: true })}/>} noAutocomplete inputStyle={{ marginBottom: '6px' }} onViewAll={this.handleSearchViewAll} onKeyPress={this.handleSearchKeypress} onChange={this.handleSearchChange} onAutocomplete={this.handleAutocompleteChange} onLoading={this.handleAutocompleteLoading} scroll={this.state.scrollPosition} handleInfoOpen={this.props.handleInfoOpen} handleAddOpen={this.props.handleAddOpen}/>

                            <div style={{ position: 'fixed', left: '50%', bottom: '17px', transform: 'translateX(-50%)', zIndex: '1010'}}>
                                <Slide direction='up' in={this.state.back && this.state.searchDisplayType !== 'Search'}>
                                    <div onClick={this.props.handleClose} style={{ backgroundColor: Color.PRIMARY, borderRadius: '25px', padding: '7px 12px 7px 12px',  display: 'flex', alignItems: 'center', boxShadow: '0 6px 20px 10px rgba(0, 0, 0, 0.1)' }}>
                                        <BackIcon style={{ color: 'white', fontSize: '14px', marginRight: '3px' }}/>
                                        <p style={{ fontSize: '14px', color: 'white', marginBottom: '0px'}}> Back to Nutrition </p>
                                    </div>
                                </Slide>
                            </div>

                            <div style={{ position: 'fixed', left: '50%', bottom: '17px', transform: 'translateX(-50%)', zIndex: this.state.searchDisplayType !== 'Search' ? '1000' : '1015'}}>
                                <Slide direction='up' in={this.state.searchDisplayType === 'Search'}>
                                    <div style={{ backgroundColor: 'rgba(255,255,255,1)', borderRadius: '25px', padding: '7px 10px 7px 10px',  display: 'flex', alignItems: 'center', boxShadow: '0 6px 20px 10px rgba(0, 0, 0, 0.1)' }}>
                                        <BackIcon onClick={this.props.handleClose} style={{ color: 'white', backgroundColor: 'rgba(0,0,0,.4)', padding: '4px', borderRadius: '50%', fontSize: '24px', marginRight: '8px' }}/>
                                        <Selector handleChange={(value) => this.handleSearchDisplayChange(value, this.state.searchConfirm)} value={this.state.searchMode} style={{ marginBottom: '0px' }} options={[{ value: 'All', label: 'All' }, { value: 'Generic', label: 'Generic' }, { value: 'Branded', label: 'Branded' }]}/>
                                    </div>
                                </Slide>
                            </div>

                            { this.renderSearchDisplay() }
                        </CardContent>
                    </Card>
                </ModalSlide>  
            </div>  
        );
    }
}

function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        searchNutrition,
        searchBrandedFoods,
        searchFoundationFoods
     }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomMealSearchModal)