//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
//Components and Pages
import { withRouter } from 'react-router-dom'
import { Card, CardContent, Grow } from '@material-ui/core'
import { ArticleCard, PageCard, SkeletonCard, StickyHeader, BackButton } from '../components'
import { Color } from '../util'
import { getModules } from '../actions/dashboardActions'
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

class University extends Component {
    constructor (props) {
        super(props)
		this.state = {
            time: new Date(),
            articleOpen: false,
            article_id: null,
            mounted: false
		}
    }

    componentDidMount() {
        this.timeClock = setInterval(() => this.tick(), 1000)
        if (!this.props.dash.moduleLoading && !this.props.dash.moduleLoaded && this.props.auth.user) {
            this.setState({ mounted: true })
            this.props.getModules()
        }
    }

    componentDidUpdate() {
        if (!this.props.dash.moduleLoading && !this.props.dash.moduleLoaded && !this.state.mounted) {
            this.setState({ mounted: true })
            this.props.getModules()
        }
    }

    componentWillUnmount() { 
        clearInterval(this.timeClock)
        document.body.style.overflow = 'unset';
    }

    handleArticleOpen = (article_id, module_id) => {
        this.props.history.push(`/dashboard/stnbly-u/${article_id}?module_id=${module_id}`)
        //this.setState({ article_id, module_id, articleOpen: true })
    }

    tick = () => {
        this.setState({ time: new Date() })
    }

    generateLock = (article) => {
        if (!article.lock) return { val: false, time: null }
        let date = new Date(this.props.auth.user.createdAt)
        date.setDate(date.getDate() + article.lock_days)
        if (date.getTime() < Date.now()) return { val: false, time: date.getTime() }
        return { val: true, time: date.getTime() }
    }

    generateRecentLock = (articles) => {
        let values = articles.map((article) => { return this.generateLock(article) })
        values = values.filter((value) => value.val === true)
        if (!values.length) return { val: false, time: null }
        values.sort((a, b) => a.time - b.time)
        return values[0]
    }

    generateMaxLock = (articles) => {
        let values = articles.map((article) => { return this.generateLock(article) })
        values.sort((a, b) => b.time - a.time)
        return values[0]
    }

    generateLockString = (lock) => {
        if (!lock.val) return <LockOpenOutlinedIcon style={{ fontSize: '20px', color: Color.PRIMARY, marginLeft: '5px' }}/>
        return <LockOutlinedIcon style={{ fontSize: '20px', color: Color.PRIMARY, marginLeft: '5px' }}/>
    }

    renderModule = (module, head, rowSize, key) => {
        if (this.props.preview) { 
            return <ArticleCard preview training article={module ? module.articles[0].article : {}} module={module} style={{ height: '153px', maxWidth: '100%', marginBottom: '10px' }}/>
        }

        return (
            module ? <div style={{ marginTop: '10px' }} key={key}>
                { head ? <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h6 style={{ marginBottom: '0px' }}> {module.title} </h6>
                    { this.generateLockString(this.generateRecentLock(module.articles)) }
                </div> : null }
                <div>
                    { module.articles.map((article, i) => {
                        return <ArticleCard training key={i} article={article.article} module={module} handleOpen={this.handleArticleOpen}/>
                    })}
                </div>
            </div> : <Grow in={true} key={key}>
                <Card style={{ position: 'relative', borderRadius: '25px', marginBottom: '15px', marginTop: '20px', backgroundColor: 'grey', opacity: '.5'}}>
                    <CardContent>
                        <h6 style={{ textAlign: 'center' , marginTop: '20px'}}> No Modules Listed </h6>
                    </CardContent>
                </Card>
            </Grow>
        )
    }

    renderModules = (preview) => {
        let modules = this.props.dash.modules, form = []
        for (let element in modules) {
            let module = modules[element], lock = this.generateMaxLock(module.articles)
            modules[element].minLock = lock
        }

        modules.sort((a, b) => {
            let aDate = new Date(a.createdAt), bDate = new Date(b.createdAt)
            return a.minLock.time - b.minLock.time || aDate.getTime() - bDate.getTime()
        })

        if (preview) return this.renderModule(modules[0], false, 1)
        if (this.props.dash.moduleLoaded && !this.props.dash.moduleLoading) form = modules.map((module, i) => { return this.renderModule(module, true, undefined, i) }) 
        else form = [<SkeletonCard/>]

        if (!form.length) form.push(
            <Grow in={true} key={form.length}>
                <Card style={{ position: 'relative', borderRadius: '25px', marginBottom: '15px', marginTop: '20px', backgroundColor: 'grey', opacity: '.5'}}>
                    <CardContent>
                        <h6 style={{ textAlign: 'center' , marginTop: '20px'}}> No Modules Listed </h6>
                    </CardContent>
                </Card>
            </Grow>
        )

        return form
    }

    renderOverview = () => {
        return (
            <PageCard title={'Sustainably U'} style={{ paddingTop: '0px', marginTop: '-15px' }}>
                { this.renderModules() }
            </PageCard>
        )
    }

    render() {        
        if (this.props.preview) return this.renderModules(true)
        
        return (
            <div>
                <StickyHeader>
                    <h5 style={{ marginBottom: '0px' }}> Sustainably U </h5>
                </StickyHeader>
                <BackButton/>
                {this.renderOverview()}
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        getModules }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(University))
