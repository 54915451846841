//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
//Components and Pages
import { withRouter } from 'react-router-dom'
import { ArticleAddModal, ArticleFormatter, Table } from '../components'
import { Color } from '../util'
import api from '../api'
//Images
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add'
import FilterListIcon from '@material-ui/icons/FilterList';
import ArticleUpdateModal from '../components/ArticleUpdateModal'

class AdminArticles extends Component {
    constructor (props) {
        super(props)
		this.state = {
            time: new Date(),
            loading: false,
            updateOpen: false,
            updateArticle: null,
            articles: [],
            tooltipLoading: false
		}
    }

    componentDidMount() {
        this.timeClock = setInterval(this.tick, 1000)
        this.getUsers()
    }

    getAuthRequest = () => {
        const token = localStorage.getItem('accessToken')
        const req = {
            headers: {
                'Content-type': 'application/json'
            }
        }
    
        if (token) req.headers['x-access-token'] = token
        return req
    }

    getUsers = () => {
        this.setState({ loading: true })
        let req = this.getAuthRequest()
        req.params = { sort: "[[\"createdAt\", -1]]" }
        api.getArticle(req).then((response) => {
            this.setState({ articles: response.data, loading: false })
        }).catch((error) => {
            this.setState({ loading: false })
        })
    }

    getTypeColor = (type) => {
        switch(type) {
            case ('article'):
                return Color.PRIMARY
            case ('training'):
                return Color.PROTEIN
            default:
                return Color.PRIMARY
        }
    }

    componentWillUnmount() { 
        clearInterval(this.timeClock)
        document.body.style.overflow = 'unset';
    }

    handleRefresh = () => {
        this.getUsers()
    }

    handleClose = (refresh) => {
        this.setState({ articleAdd: false })
        if (refresh) this.handleRefresh()
    }

    handleUpdateOpen = (article) => {
        this.setState({ updateArticle: article, updateOpen: true })
    }
    
    tick = () => {
        this.setState({ time: new Date() })
    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                <h1> Articles </h1>
                <div style={{ outline: 'none', userSelect: 'none', marginBottom: '20px' }}>
                    <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={this.handleRefresh}> Refresh <RefreshIcon style={{ fontSize: '13px' }}/> </span>
                    <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={() => this.setState({ articleAdd: true })}> Create <AddIcon style={{ fontSize: '13px'}}/> </span>
                    <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={() => this.setState({ filters: !this.state.filters })}> Filters <FilterListIcon style={{ fontSize: '13px'}}/> </span>
                </div>

                <Table loading = {this.state.loading} columns = {[
                    { width: 15, name: 'ID' },
                    { width: 20, name: 'Title', handleClick: (entry, raw) => this.handleUpdateOpen(raw), renderHover: (report) => <p style={{ marginBottom: '0px', fontSize: '12px', padding: '5px', color: Color.PRIMARY }}> { report } </p> }, 
                    { width: 15, name: 'Author'}, 
                    { width: 10, name: 'Views'},
                    { width: 15, name: 'Type', render: (session) => { return <span style={{ fontSize: '12px', backgroundColor: this.getTypeColor(session), verticalAlign: 'center', color: 'white', borderRadius: '5px', padding: '4px' }}> { session  } </span> }},
                    { width: 25, name: 'Date Created', transform: (date) => { 
                            let obj = new Date(date)
                            return obj.toLocaleString()
                        }
                    }
                ]} data = {
                    this.state.articles.map((row) => {
                        return [
                            row._id,
                            row.title,
                            row.author,
                            row.views,
                            row.type,
                            row.createdAt
                        ]
                    })
                } raw = {this.state.articles} />
                { this.props.auth.user ? <ArticleAddModal open={this.state.articleAdd} handleClose={this.handleClose}/> : null }
                { this.props.auth.user ? <ArticleUpdateModal updateArticle={this.state.updateArticle} open={this.state.updateOpen} handleClose={() => this.setState({ updateOpen: false })}/> : null }
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

export default connect(mapStateToProps)(withRouter(AdminArticles))
