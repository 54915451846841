//Classes and Functions
import React, { Component } from 'react'
import DateFnsUtils from "@date-io/date-fns";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateSession, deleteSession } from '../actions/dashboardActions'
//Components and Pages
import { TextField, CircularProgress, CardContent, InputAdornment } from '@material-ui/core';
import { Modal, CardFloat, ModalButton, Input } from './'
import { Measure, Color } from '../util'
//Images
import DeleteIcon from '@material-ui/icons/Delete'

const initialState = {
    targetTime: new Date(),
    hours: '',
    minutes: '',
    seconds: '',
    heartrate: '',
    steps: '',
    calories: '',
    updated: false,
    success: false,
    error: false
}

class TrackingUpdateModalActivities extends Component {
    constructor (props) {
        super(props)
        this.state = initialState
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.targetTime !== prevProps.targetTime) {
            this.setState({ targetTime: this.props.targetTime, Time: this.state.targetTime.getDate() === this.props.time.getDate() ? "now" : "" } )
        }

        if (this.state.targetTime !== prevState.targetTime)  {
            this.setState({ Time: this.state.targetTime.getDate() === this.props.time.getDate() ? "now" : ""})
        }

        if (!prevProps.open && this.props.open) {
            let times = this.getTimes(this.props.session.metadata.duration)
            this.setState({ 
                targetTime: new Date(this.props.session.time), 
                hours: times.hours,
                minutes: times.minutes,
                seconds: times.seconds,
                heartrate: this.props.session.metadata.heartrate === null ? '' : this.props.session.metadata.heartrate ,
                steps: this.props.session.metadata.steps === null ? '' : this.props.session.metadata.steps ,
                calories: this.props.session.metadata.calories === null ? '' : this.props.session.metadata.calories,
            })
        }
    }

    getTimes = (seconds) => {
        let duration = seconds
        let hours = Math.floor(duration / 3600)
        duration = duration - hours * 3600
        let minutes = Math.floor(duration / 60)
        duration = duration - minutes * 60
        return { hours, minutes, seconds: duration }
    }

    handleUpdate = () => {
        let targetTime = new Date(this.props.session.time), temp = new Date(this.state.targetTime)

        if (this.state.targetTime.getDate() !== targetTime) {
            temp.setHours(targetTime.getHours(), targetTime.getMinutes(), targetTime.getSeconds(), targetTime.getMilliseconds())
            targetTime = temp            
        }

        this.props.updateSession(this.props.session._id, { 
            time: targetTime.getTime(), 
            metadata: { 
                ...this.props.session.metadata, 
                duration: this.getSeconds(this.state.hours, this.state.minutes, this.state.seconds), 
                calories: this.state.calories !== '' ? Number(this.state.calories) : null,
                heartrate: this.state.heartrate !== '' ? Number(this.state.heartrate) : null,
                steps: this.state.steps !== '' ? Number(this.state.steps) : null
            }}, this.props.session.target).then((response) => {
                this.handleSuccess()
            }).catch((error) => {
                this.handleError()
            })
    }

    handleSuccess = () => { 
        this.setState({ success: true })
        setTimeout(this.handleClose, 400)
    }

    handleError = () => {
        
    }

    getSeconds = (hours, minutes, seconds) => {
        return Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds)
    }

    handleChange = (question, answer) => {
        this.setState( { [question]: answer, updated: true })
    }

    handleDelete = () => {
        this.props.deleteSession(this.props.session._id).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.handleError()
        })
    }

    handleClose = () => {
        this.setState(initialState)
        this.props.handleClose()
    }

    getInputAdornment = (type) => {
        if (this.props.auth.user.settings) {
            return <InputAdornment> {Measure[this.props.auth.user.settings.unit][type].unit} </InputAdornment>
        }
    }

    confirmSubmit = () => {
        if (!(this.getSeconds(this.state.hours, this.state.minutes, this.state.seconds) > 0)) return false
        return this.state.updated
    }

	render() {
        return (
            <Modal direction='up' in={this.props.open} handleClose={this.handleClose} head={`Update ${this.props.session && this.props.session.target ? this.props.session.target.name : "activity"}`} functions={[
                this.props.dash.sessionLoading ? <CircularProgress size={20} style={{ color: 'white' }}/> : <DeleteIcon style={{ fontSize: '20px', color: 'white', marginBottom: '50%', backgroundColor: Color.CALORIES_ERROR }} onClick={() => this.handleDelete(this.props.session, this.props.workout)}/>
            ]}>
                <CardFloat>
                    <CardContent style={{ padding: '16px' }}>
                    <div>
                            { this.props.session && this.props.session?.target.walking ? <div style={{ width: '100%' }}> 
                                <Input 
                                    type="number"
                                    style={{ marginBottom: '10px', width: '100%' }}
                                    error={this.state.steps < 0}
                                    value={this.state.steps} 
                                    placeholder="Steps"
                                    onChange = {(event) => {
                                    this.handleChange("steps", event.target.value)}}/>
                            </div> : null }
                        </div>
                        <div>
                            <div style={{ display: 'flex' }}>
                                <Input 
                                    type="number"
                                    style={{ marginRight: '10px', width: '100%' }}
                                    error={this.state.hours < 0}
                                    value={this.state.hours} 
                                    placeholder="Hours"
                                    onChange = {(event) => {
                                    this.handleChange("hours", event.target.value)}}/>
                                <Input 
                                    type="number"
                                    style={{ marginRight: '10px', width: '100%' }}
                                    error={this.state.minutes < 0}
                                    value={this.state.minutes} 
                                    placeholder="Minutes"
                                    onChange = {(event) => {
                                    this.handleChange("minutes", event.target.value)}}/>
                                <Input 
                                    type="number"
                                    style={{ width: '100%' }}
                                    error={this.state.seconds < 0}
                                    value={this.state.seconds} 
                                    placeholder="Seconds"
                                    onChange = {(event) => {
                                    this.handleChange("seconds", event.target.value)}}/>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Input 
                                    type="number"
                                    style={{ marginRight: '10px', width: '100%' }}
                                    error={this.state.calories < 0}
                                    value={this.state.calories} 
                                    placeholder="Calories"
                                    onChange = {(event) => {
                                    this.handleChange("calories", event.target.value)}}/>
                                <Input 
                                    type="number"
                                    style={{  width: '100%' }}
                                    error={this.state.heartrate < 0}
                                    value={this.state.heartrate} 
                                    placeholder="Heartrate"
                                    suff={'bpm'}
                                    onChange = {(event) => {
                                    this.handleChange("heartrate", event.target.value)}}/>
                            </div>
                        </div>
                    </CardContent>
                </CardFloat>
                <ModalButton success={this.state.success} disabled={!this.confirmSubmit()} loading={this.props.dash.sessionLoading} handleClick={this.handleUpdate} label={`Update ${this.props.session && this.props.session.target ? this.props.session.target.name : 'activity'}`}/>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
	return {
        auth: state.auth,
		dash: state.dash,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        updateSession,
        deleteSession }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingUpdateModalActivities)

