//Classes and Functions
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
//Components and Pages
import { loadCustom, loadCustomWorkouts, loadCustomActivities, loadActivities } from '../actions/dashboardActions'
import { ActivityCard, FoodCard, WorkoutCard, ChipSelector } from '../components'
import { CircularProgress, Fade } from '@material-ui/core'
import { MdSort } from 'react-icons/md'
//Images

const sortingOptions = [
    {
        value: 'newest',
        label: 'Newest'
    },
    {
        value: 'oldest',
        label: 'Oldest'
    },
    {
        value: 'most used',
        label: 'Most used'
    },
    {
        value: 'least used',
        label: 'Least used'
    }
]

const CustomFoodCards = (props) => {
    const [sort, setSort] = useState(props.dash.customSort || 'newest')

    useEffect(() => {
        if (sort === props.dash.customSort) return
        props.loadCustom(props.auth.user._id, sort)
    }, [sort])

    return (
        <div>
            {
                <div style={{ display: 'flex', justifyContent: 'right', marginTop: '5px', alignItems: 'center' }}>
                    <ChipSelector handleChange={(value) => setSort(value)} pref={<MdSort style={{ marginRight: '4px' }}/>} style={{ maxWidth: '120px'}} value={sort} options={sortingOptions}/>
                </div>
            }
            {
                props.dash.customLoading ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginBottom: '10px' }}>
                    <CircularProgress size={25}/>
                </div> : (props.dash.customFoods.length ? <div>
                    {
                        props.dash.customFoods.map((food, i) => {
                            return <FoodCard key={i} food={food} handleAddOpen={props.handleAddOpen} handleInfoOpen={props.handleInfoOpen} handleCustomUpdateOpen={props.handleCustomUpdateOpen}/>
                        })
                    }
                </div> : <p style={{ marginTop: '30px', textAlign: 'center', opacity: '.7' }}> No Custom Foods </p>)
            }
        </div>
    )
}


// class CustomFoodCards extends Component {
//     componentDidMount = () => {
//         if (this.props.auth.user && this.props.open && !this.props.dash.customFoodLoaded && !this.props.dash.customLoading) {
//             if (!this.props.workouts && !this.props.activities && !this.props.dash.customFoodLoaded) this.props.loadCustom(this.props.auth.user._id)
//             else if (this.props.workouts && !this.props.dash.customWorkoutsLoaded) this.props.loadCustomWorkouts(this.props.auth.user._id)  
//             else if (this.props.activities && !this.props.dash.customActivitiesLoaded) {
//                 this.props.loadCustomActivities(this.props.auth.user._id)
//                 this.props.loadActivities()
//             }
//         }
//     }

//     componentDidUpdate = (prevProps) => {
//         if (this.props.open && !this.props.dash.customLoading) {
//             if (this.props.auth.user && !prevProps.auth.user) {
//                 if (!this.props.workouts && !this.props.activities && !this.props.dash.customFoodLoaded) this.props.loadCustom(this.props.auth.user._id)
//                 else if (this.props.workouts && !this.props.dash.customWorkoutsLoaded) this.props.loadCustomWorkouts(this.props.auth.user._id)  
//                 else if (this.props.activities && !this.props.dash.customActivitiesLoaded) {
//                     this.props.loadCustomActivities(this.props.auth.user._id)
//                     this.props.loadActivities()
//                 }
//             }

//             if (!prevProps.open && this.props.auth.user) {
//                 if (!this.props.workouts && !this.props.activities && !this.props.dash.customFoodLoaded) this.props.loadCustom(this.props.auth.user._id)
//                 else if (this.props.workouts && !this.props.dash.customWorkoutsLoaded) this.props.loadCustomWorkouts(this.props.auth.user._id)  
//                 else if (this.props.activities && !this.props.dash.customActivitiesLoaded) {
//                     this.props.loadCustomActivities(this.props.auth.user._id)
//                     this.props.loadActivities()
//                 }
//             }
//         }
//     }

//     handleSortChange = (value) => {
//         this.setState({ sort: value })
//         this.loadCustomActivities(this.props.auth.user._id)
//     }

//     render() {
//         let custom = []
//         if (!this.props.workouts && !this.props.activities) {
//             custom = this.props.dash.customFoods.map((food, i) => {
//                 return <FoodCard key={i} food={food} handleAddOpen={this.props.handleAddOpen} handleInfoOpen={this.props.handleInfoOpen} handleCustomUpdateOpen={this.props.handleCustomUpdateOpen}/>
//             })
//         } else if (this.props.workouts) {
//             custom = this.props.dash.customWorkouts.map((workout, i) => {
//                 return <WorkoutCard key={i} workout={workout} handleAddOpen={this.props.handleAddOpen} handleInfoOpen={this.props.handleInfoOpen} handleCustomUpdateOpen={this.props.handleCustomUpdateOpen}/>
//             })
//         } else if (this.props.activities) {
//             custom = this.props.dash.customActivities.map((activity, i) => {
//                 return <ActivityCard key={i} activity={activity} handleAddOpen={this.props.handleAddOpen} handleInfoOpen={this.props.handleInfoOpen} handleCustomUpdateOpen={this.props.handleCustomUpdateOpen}/>
//             })

//             custom = [...custom, this.props.dash.activities.map((activity, i) => {
//                 return <ActivityCard key={i} activity={activity} handleAddOpen={this.props.handleAddOpen} handleInfoOpen={this.props.handleInfoOpen}/>
//             })]
//         }

//         custom = [<div style={{ display: 'flex', justifyContent: 'right', marginTop: '5px', alignItems: 'center' }}>
//             <ChipSelector handleChange={this.handleSortChange} pref={<MdSort style={{ marginRight: '4px' }}/>} style={{ maxWidth: '120px'}} value={this.state.sort} options={sortingOptions}/>
//         </div>,...custom]

//         if (this.props.dash.customLoading) {
//             custom.push(<div key={custom.length} style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginBottom: '10px' }}>
//                 <CircularProgress size={25}/>
//             </div>)
//         } else if (!custom.length) {
//             custom.push(<p style={{ marginTop: '30px', textAlign: 'center', opacity: '.7' }}> No Custom Foods </p>)
//         }

//         return custom
//     }
// }

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        loadCustom,
        loadCustomWorkouts,
        loadCustomActivities,
        loadActivities,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomFoodCards)