import React, { Component } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Fade } from '@material-ui/core'

class MoreIcon extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            right: 0,
            bottom: 0,
            height: 0,
            width: 0
        }
    }

    render() {
        return (
            <div>
                <div ref={(el) => {
                    if (!el) return
                    let { right, bottom, height, width } = el.getBoundingClientRect()
                    if (right !== this.state.right || bottom !== this.state.bottom || height !== this.state.height || width !== this.state.width) this.setState({ right, bottom, height, width })
                }} style={{ margin: '10px', position: 'absolute', height: '30px', width: '30px', zIndex: '1001', borderRadius: '50%', padding: '5px', backgroundColor: 'grey', cursor: 'pointer', top: '8px', right: '48px', opacity: '.8' }} onClick={() => this.setState({ open: true })}>
                    <MoreHorizIcon style={{ fontSize: '20px', color: 'white', marginBottom: '50%' }}/>
                </div>

                <Fade in={this.state.open}>
                    <div style={{ position: 'fixed', zIndex: '1020', inset: '0px' }} onClick={() => this.setState({ open: false })}>
                        <div style={{ position: 'absolute', backgroundColor: 'white', borderRadius: '10px', left: this.state.right, top: this.state.bottom + 10, transform: 'translateX(-100%)', boxShadow: '0 6px 20px 10px rgba(0, 0, 0, 0.15)', overflowY: 'hidden' }}>
                            { this.props.children }
                        </div>
                    </div>
                </Fade>
            </div>
        );
    }
}

export default MoreIcon;