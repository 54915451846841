import styled from "styled-components";
import { Slider } from '@material-ui/core'
import { CardFloat } from '../components'
import { useState, useEffect } from "react";
import { Color } from '../util'

import { ReactComponent as M09 } from '../images/M09.svg'
import { ReactComponent as M12 } from '../images/M12.svg'
import { ReactComponent as M18 } from '../images/M18.svg'
import { ReactComponent as M21 } from '../images/M21.svg'
import { ReactComponent as M28 } from '../images/M28.svg'
import { ReactComponent as M33 } from '../images/M33.svg'

import { ReactComponent as F13 } from '../images/F13.svg'
import { ReactComponent as F19 } from '../images/F19.svg'
import { ReactComponent as F25 } from '../images/F25.svg'
import { ReactComponent as F33 } from '../images/F33.svg'
import { ReactComponent as F38 } from '../images/F38.svg'
import { ReactComponent as F45 } from '../images/F45.svg'

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 10px;
`

const CustomButton = styled.button`
    background-color: inherit;
    color: ${Color.PRIMARY};
    font-size: 14px;
    border: none;
    margin: 15px;
`

const QuestionCard = (props) => {
	return (
		<CardFloat outline style={{ border: props.disabled ? `1px solid ${props.color || Color.PRIMARY}` : null, margin: '0px 5px 0px 5px', backgroundColor: props.disabled ? ((props.color || Color.PRIMARY) + '08') : 'white', color: props.disabled ? props.color || Color.PRIMARY : null, transition: 'all .3s ease', textAlign: 'center', padding: '16px', borderRadius: '15px', cursor: 'pointer', ...props.style }} onClick={props.onClick}>
			{props.children}
		</CardFloat>
	)
}

const BodyFatSelector = (props) => {
    const options = {
        Male: [
            {
                value: .05,
                label: '<10%',
                image: M09
            },
            {
                value: .125,
                label: '10-15%',
                image: M12
            },
            {
                value: .175,
                label: '15-20%',
                image: M18
            },
            {
                value: .225,
                label: '20-25%',
                image: M21
            },
            {
                value: .275,
                label: '25-30%',
                image: M28
            },
            {
                value: .35,
                label: '30%+',
                image: M33
            }
        ],
        Female: [
            {
                value: .125,
                label: '<15%',
                image: F13
            },
            {
                value: .185,
                label: '15-23%',
                image: F19
            },
            {
                value: .265,
                label: '23-30%',
                image: F25
            },
            {
                value: .325,
                label: '30-35%',
                image: F33
            },
            {
                value: .375,
                label: '35-40%',
                image: F38
            },
            {
                value: .45,
                label: '40%+',
                image: F45
            },
        ],
    }

    const [bodyFatPercentage, setBodyFatPercentage] = useState(null)
    const [gender, setGender] = useState('Female')
    const [custom, setCustom] = useState(false)

    useEffect(() => {
        setCustom(props.custom)
    }, [props.custom])

    useEffect(() => {
        setBodyFatPercentage(props.value)
    }, [props.value])
    
    useEffect(() => {
        if (props.gender === 'Other' || props.gender === 'Prefer not to say') return
        setGender(props.gender)
    }, [props.custom])

    const handleChange = (value) => {
        if (props.onChange) props.onChange(value) 
        setBodyFatPercentage(value)
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            { !custom ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Container>
                    {
                        options[gender || 'Female'].map((option, i) => {
                            const Image = option.image
                            return (
                                <QuestionCard key={i} onClick={() => handleChange(option.value)} disabled={bodyFatPercentage === option.value} style={{ height: '140px', width: '140px'}}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <h6 style={{ margin: '0px' }}> {option.label} </h6>
                                        <Image style={{ position: 'absolute', left: '50%', bottom: '-110px', transform: 'translateX(-50%)', height: '200px' }}/>
                                    </div>
                                </QuestionCard>
                            )
                        })
                    }
                </Container>
            </div> : <Slider 
            	min={.08} 
            	valueLabelFormat={(value) => Math.round(value * 100) + '%'} 
            	value={bodyFatPercentage} 
            	step={.01} 
            	max={.5} 
            	valueLabelDisplay="auto"
            	style={{ marginLeft: '5px', marginRight: '5px'}} 
            	onChange={(event, value) => handleChange(value)}/>      
            }

            <CustomButton onClick={() => setCustom(!custom)}> { !custom ? 'Enter custom amount' : 'Back to options' } </CustomButton>
        </div>
    ) 
}

export default BodyFatSelector