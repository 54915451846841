import React, { useState } from 'react';
import styled from 'styled-components'
import { Color } from "../util";

const Field = styled.input`
    transition: all 300ms ease;
    outline: none;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    padding: 8px;
`

const Container = styled.div`
    transition: all 300ms ease;
    position: relative;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.03);
    border-radius: 10px;
    background-color: white;
    display: flex;
    border: 1px solid ${(props) => props.error ? Color.CALORIES_ERROR : (props.focused ? Color.PRIMARY : 'rgba(0,0,0,.1)')};
`

const Input = (props) => {
    const [focused, setFocused] = useState(false)

    return (
        <div style={{  marginBottom: '15px', ...props.style }}>
            <Container error={props.error} focused={focused}>
                { props.icon ? <div style={{ margin: '0px 0px 0px 10px', display: 'flex', alignItems: 'center' }}>
                    { props.icon }
                </div> : null }
                { props.pref ? <div style={{ opacity: '.6', padding: '8px 0px 8px 8px', display: 'flex', alignItems: 'center' }}>
                    { props.pref } 
                </div> : null }
                { !props.disablePlaceholder ? <div style={{ overflow: 'hidden', position: 'absolute', top: '-10px', left: '5px', backgroundColor: 'white', fontSize: '12px', width: (props.value && props.value !== '') || props.label ? `calc(${(props.label?.length || props.placeholder?.length) + 2}ch)` : '0px', transition: 'all 300ms ease'  }}>
                    <p style={{ padding: '0px 4px 0px 4px', fontSize: '12px', transition: 'all 300ms ease', color: props.error ? 'red' : 'rgba(0,0,0,.5)', borderRadius: '5px', margin: '0px', whiteSpace: 'nowrap' }}> {props.label || props.placeholder} </p>
                </div> : null }
                <Field autoFocus={props.autoFocus} value={props.value} disabled={props.disabled} onKeyPress={!(props.disabled || props.loading) ? props.onKeyPress : null} onChange={props.onChange} placeholder={props.placeholder} type={props.type || 'text'} onBlur={() => {
                    setFocused(false)
                    if (props.onBlur) props.onBlur()
                }} onFocus={() => {
                    setFocused(true)
                    if (props.onFocus) props.onFocus()
                }} style={{ width: '100%' }}/>
                { props.suff ? <div style={{ opacity: '.6', padding: '8px 8px 8px 0px', display: 'flex', alignItems: 'center' }}>
                    { props.suff } 
                </div> : null }
                { props.selector ? <div style={{ backgroundColor: 'rgba(0,0,0,.1)', borderRadius: '0px 9px 9px 0px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    { props.selector }
                </div> : null }
            </Container>
            { props.error && props.error.length ? <div style={{ display: 'flex', justifyContent: props?.injectStyles?.errorSide || 'left' }}>
                <p style={{ fontSize: '12px', margin: '10px 0px 0px 0px', color: 'red' }}> ⚠️ { props.error } </p>
            </div> : null }
        </div>
    )
}

export default Input;