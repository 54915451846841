import React, { Component } from 'react';
import { Image, Input, Video } from './'
import { Editor, EditorState, convertToRaw, AtomicBlockUtils, RichUtils, CompositeDecorator, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Color } from '../util'

import FormatBoldIcon from '@material-ui/icons/FormatBold';
import ImageIcon from '@material-ui/icons/Image';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import TitleIcon from '@material-ui/icons/Title';
import LinkIcon from '@material-ui/icons/Link';

class ArticlePreview extends Component {
    constructor(props) {
        super(props)

        const decorator = new CompositeDecorator([
            {
              strategy: this.findLinkEntities,
              component: this.link,
            },
        ]);

        this.state = {
            video: false,
            image: false,
            link: false,
            editorState: EditorState.createEmpty(decorator)
        }
    }

    componentDidMount() {
        const decorator = new CompositeDecorator([
            {
              strategy: this.findLinkEntities,
              component: this.link,
            },
        ]);
        try { 
            if (this.props.convertFromRaw) this.setState({ editorState: EditorState.createWithContent(convertFromRaw(this.props.convertFromRaw), decorator) })
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        const decorator = new CompositeDecorator([
            {
              strategy: this.findLinkEntities,
              component: this.link,
            },
        ]);
        try {
            if (!this.props.initial && this.props.convertFromRaw && prevProps.convertFromRaw !== this.props.convertFromRaw) this.setState({ editorState: EditorState.createWithContent(convertFromRaw(this.props.convertFromRaw), decorator) })
        } catch (error) {
            console.log(error)
        }
    }

    mediaBlockRenderer = (block) => {
        if (block.getType() === 'atomic') {
            return {
              component: this.media,
              editable: false,
            };
          }
  
          return null;    
    }

    findLinkEntities(contentBlock, callback, contentState) {
        contentBlock.findEntityRanges(
          (character) => {
            const entityKey = character.getEntity();
            return (
              entityKey !== null &&
              contentState.getEntity(entityKey).getType() === 'link'
            );
          },
          callback
        );
    }

    media = (props) => {
        const entity = props.contentState.getEntity(props.block.getEntityAt(0))
        const prop = entity.getData()
        const type = entity.getType()

        if (type === 'image') return <Image style={{ width: '100%', minHeight: '100px', borderRadius: '15px'}} {...prop}/>
        if (type === 'video') return <Video style={{ width: '100%', height: '100%', minHeight: '200px' }} loadingStyle={{ borderRadius: '15px' }} {...prop}/>
        if (type === 'link') return this.link(props)
    }

    link = (props) => {
        let href = props.contentState.getEntity(props.entityKey).getData().href;
        return <a style={{ fontSize: '16px', color: Color.PRIMARY, textDecoration: 'none' }} target='_blank' rel='noopener noreferrer' href={href}>{props.children}</a>
    }

    handleImageCreation = (url) => {
        const contentState = this.state.editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', { src: url })  
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(this.state.editorState, { currentContent: contentStateWithEntity })
        this.handleEditorStateChange(AtomicBlockUtils.insertAtomicBlock( newEditorState, entityKey, ' ' ), () => this.removeLines(entityKey))
    }

    handleVideoCreation = (url) => {
        const contentState = this.state.editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('video', 'IMMUTABLE', { src: url })  
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(this.state.editorState, { currentContent: contentStateWithEntity })
        this.handleEditorStateChange(AtomicBlockUtils.insertAtomicBlock( newEditorState, entityKey, ' ' ), () => this.removeLines(entityKey))
    }

    handleLinkCreation = (url) => {
        const contentState = this.state.editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('link', 'IMMUTABLE', { href: url })  
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(this.state.editorState, { currentContent: contentStateWithEntity })
        this.handleEditorStateChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey))
        //this.setState({ editorState: AtomicBlockUtils.insertAtomicBlock( newEditorState, entityKey, ' ' )}, () => this.removeLines(entityKey))
    }

    removeLines = (entityKey) => {
        let editorState = this.state.editorState
        let contentState = editorState.getCurrentContent()
        const newAtomicBlock = contentState.getBlockMap().find(b=>b.getEntityAt(0)===entityKey).getKey();
        const newBlockMap = contentState.getBlockMap().delete(contentState.getBlockBefore(newAtomicBlock)).delete(contentState.getBlockAfter(newAtomicBlock));
        const newContentState = contentState.set('blockMap', newBlockMap);
        const newEditorState = EditorState.push(editorState, newContentState, 'delete-empty-lines');
        this.handleEditorStateChange(newEditorState)
    }

    handleEditorStateChange = (editorState, callback) => {
        this.setState({ editorState }, () => {
            if (callback) callback()
            return this.props.handleChange ? this.props.handleChange(convertToRaw(editorState.getCurrentContent())) : null
        })
    }

    handleBoldClick = () => {
        this.setState({ bold: true })
        this.handleEditorStateChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'))
    }

    handleItalicsClick = () => {
        this.setState({ italics: true })
        this.handleEditorStateChange(RichUtils.toggleInlineStyle(this.state.editorState, 'ITALIC'))
    }

    handleUnderlineClick = () => {
        this.setState({ underline: true })
        this.handleEditorStateChange(RichUtils.toggleInlineStyle(this.state.editorState, 'UNDERLINE'))
    }

    handleHeadClick = () => {
        this.setState({ head: true })
        this.handleEditorStateChange(RichUtils.toggleBlockType(this.state.editorState, 'header-five'))
    }

    handleUnorderedListClick = () => {
        this.handleEditorStateChange(RichUtils.toggleBlockType(this.state.editorState, 'unordered-list-item'))
    }

    handleOrderedListClick = () => {
        this.handleEditorStateChange(RichUtils.toggleBlockType(this.state.editorState, 'ordered-list-item'))
    }

    handleImageClick = () => {
        this.setState({ image: true, link: false, video: false })
    }

    handleVideoClick = () => {
        this.setState({ video: true, link: false, image: false })
    }

    handleLinkClick = () => {
        this.setState({ link: true, image: false, video: false })
    }

    handleImageSubmit = (event) => {
        if (event.key !== 'Enter') return
        this.setState({ image: false })
        this.handleImageCreation(event.target.value)
    }
    
    handleVideoSubmit = (event) => {
        if (event.key !== 'Enter') return
        this.setState({ video: false })
        this.handleVideoCreation(event.target.value)
    }

    handleLinkSubmit = (event) => {
        if (event.key !== 'Enter') return
        this.setState({ link: false })
        this.handleLinkCreation(event.target.value)
    }

    render() {
        const functions = [
            {
                attribute: 'bold',
                handleClick: this.handleBoldClick,
                icon: <FormatBoldIcon style={{ fontSize: '20px' }}/>,
            },
            {
                attribute: 'italic',
                handleClick: this.handleItalicsClick,
                icon: <FormatItalicIcon style={{ fontSize: '20px' }}/>,
            },
            {
                attribute: 'underline',
                handleClick: this.handleUnderlineClick,
                icon: <FormatUnderlinedIcon style={{ fontSize: '20px' }}/>,
            },
            {
                attribute: 'title',
                handleClick: this.handleHeadClick,
                icon: <TitleIcon style={{ fontSize: '20px' }}/>,
                active: RichUtils.getCurrentBlockType(this.state.editorState) === 'header-five'
            },
            {
                attribute: 'ol',
                handleClick: this.handleOrderedListClick,
                icon: <FormatListNumberedIcon style={{ fontSize: '20px' }}/>,
                active: RichUtils.getCurrentBlockType(this.state.editorState) === 'ordered-list-item'
            },
            {
                attribute: 'ul',
                handleClick: this.handleUnorderedListClick,
                icon: <FormatListBulletedIcon style={{ fontSize: '20px' }}/>,
                active: RichUtils.getCurrentBlockType(this.state.editorState) === 'unordered-list-item'
            },
            {
                attribute: 'image',
                handleClick: this.handleImageClick,
                icon: <ImageIcon style={{ fontSize: '20px' }}/>
            },
            {
                attribute: 'video',
                handleClick: this.handleVideoClick,
                icon: <VideoLibraryIcon style={{ fontSize: '20px' }}/>
            },
            {
                attribute: 'link',
                handleClick: this.handleLinkClick,
                icon: <LinkIcon style={{ fontSize: '20px' }}/>
            }
        ]

        return (
            <div>
                { !this.props.readOnly ? <div style={{ display: 'flex', marginBottom: '5px' }}>
                    {
                        functions.map((attr) => {
                            return (
                                <div style={{ color: attr.active ? Color.PRIMARY : 'rgba(0,0,0,.5)', padding: '5px', cursor: 'pointer', borderRadius: '5px', border: attr.active ? `1px solid ${Color.PRIMARY}` : '1px solid rgba(0,0,0,.1)', fontSize: '0px', marginRight: '5px' }} onClick={attr.handleClick}>
                                    { attr.icon }
                                </div>
                            )
                        })
                    }
                </div> : null }
                { !this.props.readOnly && (this.state.image || this.state.link || this.state.video) ? <div style={{ margin: '5px 0px 5px 0px'}}>
                    { this.state.image ? <Input small onKeyPress={this.handleImageSubmit} placeholder='Image url' style={{ fontSize: '12px', width: '200px' }}/> : null }
                    { this.state.link ? <Input small onKeyPress={this.handleLinkSubmit} placeholder='URL' style={{ fontSize: '12px', width: '200px' }}/> : null}
                    { this.state.video ? <Input small onKeyPress={this.handleVideoSubmit} placeholder='Video url' style={{ fontSize: '12px', width: '200px' }}/> : null }
                </div> : null }
                <div style={{ borderRadius: '5px', border: '1px solid rgba(0,0,0,.1)', padding: '10px', ...this.props.style }}>
                    <Editor
                        readOnly={this.props.readOnly}
                        blockRendererFn={this.mediaBlockRenderer}
                        editorState={this.state.editorState}
                        onChange={this.handleEditorStateChange}
                    />
                </div>
            </div>
        );
    }
}

export default ArticlePreview;