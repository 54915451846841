//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getPreferences } from '../actions/dashboardActions'
//Components and Pages
import { Modal, FavoritesCards } from './'
//Images

class FavoritesModal extends Component {
    componentDidMount = () => {
        if (!this.props.dash.preferencesLoaded && !this.props.dash.preferencesLoading && this.props.auth.user) {
            this.props.getPreferences(this.props.auth.user._id)
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!this.props.dash.preferencesLoaded && !this.props.dash.preferencesLoading && this.props.auth.user) {
            this.props.getPreferences(this.props.auth.user._id)
        }
    }

	render() {
        return (
            <Modal direction='up' in={this.props.open} handleClose={this.props.handleClose} head={this.props.exclusions ? 'Your Exclusions' : 'Your Favorites'}>
                <FavoritesCards exclusions={this.props.exclusions} open={this.props.open} type={this.props.type} handleAddOpen={this.props.handleAddOpen} handleInfoOpen={this.props.handleInfoOpen}/>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        getPreferences }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesModal)

