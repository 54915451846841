//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createRecalibration, updateProfile } from '../actions/authActions'
import { Measure, equipment, health_conditions, physical_limitations, Color, toTitleCase } from '../util'
//Components and Pages
import { Card, CardContent, Slider, InputAdornment, Fade } from '@material-ui/core';
import { BodyFatCard, PageHandler, CardFloat, Page, Input, ModalSlide, ModalButton, GenerationCalendar } from './'
import styled from 'styled-components'
//Images
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import { MdOutlineShortcut } from 'react-icons/md'

const fitness_level = [
    {
        value: 1,
        head: 'Beginner',
        body: "I can do the basics"
    },
    {
        value: 2,
        head: 'Intermediate',
        body: "I am comfortable with a moderate challenge"
    },
    {
        value: 3,
        head: 'Advanced',
        body: "I can train with intensity"
    }
]

const QuestionHeader = styled.h6`
	font-size: 16px;
	text-align: center;
	margin-bottom: ${(props) => props.hasSubheader ? '5px' : '20px' };
`

const QuestionSubheader = styled.h6`
	font-size: 14px;
	text-align: center;
	margin-bottom: 20px;
	opacity: .5;
`

const QuestionContainer = styled.div`
    margin: 10px;
`

const QuestionCard = (props) => {
	return (
		<CardFloat outline style={{ border: props.disabled ? `1px solid ${Color.PRIMARY}` : null, color: props.disabled ? Color.PRIMARY : null, transition: 'border .3s ease', textAlign: 'center', padding: '16px', borderRadius: '15px', cursor: 'pointer' }} onClick={props.onClick}>
			{props.children}
		</CardFloat>
	)
}

const TabCard = (props) => {
	return (
		<CardFloat>
			<CardContent style={{ padding: '12px' }}>
				{ props.children }
			</CardContent>
		</CardFloat>
	)
}

const PageIcon = (props) => {
    return (
        <div style={{ backgroundColor: Color.PRIMARY, width: props.mini ? '5px' : (props.active ? '24px' : '8px'), height: props.mini ? '5px' : '5px', borderRadius: '6px', margin: '3px', transition: 'width 400ms ease, opacity 400ms ease' }}/>
    )
}

const PageIconHandler = (props) => {
    const { children } = props?.children?.props, { tab, max } = props

    const renderPageIcons = () => {
        let render = [], pages = Math.ceil(children.length / max), page = Math.ceil((tab + 1) / max), count = children.length - max * (page - 1)
        if (count > max) count = max  

        if (page > 1) render.push(<PageIcon mini/>)
        for (let i = 0; i < count; i++) render.push(<PageIcon active={i % max === tab % max}/>)
        if (page < pages) render.push(<PageIcon mini/>)

        return render
    }

    return (
        <div>
            <div style={{ position: 'absolute', top: '30px', left: '50%', transform: 'translateX(-50%)', transition: 'all 500ms ease' }}>
                <div style={{ display: 'flex', marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                    {
                        renderPageIcons()
                    }
                </div>
            </div>
            { props.children }
        </div>
    )
}

const Header = (props) => <h4 {...props} style={{ marginBottom: '20px', display: 'flex' }}> {props.children} </h4>
const Subheader = (props) => <h6 {...props} style={{ marginBottom: '15px', opacity: '.7', ...props.style }}> {props.children} </h6>

class RecalibrationModal extends Component {
    constructor (props) {
        super(props)
        this.state = {
            preloaded: false,
            loading: false,
            tab: 0,
            tt : {
				basic : {
                    active: true,
					weight : {
						answer : "",
						type : "weight",
						err : false
                    },
                    waist_size : {
                        answer : "",
                        type : "waist",
                        err : false
                    },
                    body_fat : {
                        answer: "",
                        type: "body_fat",
                        err: false
                    }
                },
                rec_gen_map: {
                    active: true,
                    rec_gen_map: {
                        answer: [],
                        type: 'etc',
                        err: false
                    }
                },
                adjust_goals_toggle: {
                    active: true,
                    adjust_goals_toggle: {
                        answer: '',
                        type: 'toggle',
                        err: false
                    }
                },
                target_weight: {
                    active: false,
                    group: 'goals',
                    save_state: 'settings',
                    target_weight: {
                        answer: '',
                        type: 'weight',
                        err: false
                    },
                    target_body_fat: {
                        answer: '',
                        type: 'body_fat',
                        err: false
                    }
                },
                feedback_toggle: {
                    active: true,
                    feedback_toggle: {
                        answer: '',
                        type: 'toggle',
                        err: false
                    }
                },
                feedback: {
                    active: false,
                    feedback: {
                        answer: '',
                        type: 'settings',
                        err: false
                    }
                },
                response: {
                    active: true,
                    response: {
                        answer: '',
                        type: 'exclude',
                        err: false
                    }
                }
            }
		}
    }

    componentDidUpdate(prevProps) {
        if (!this.state.preloaded && this.props.open) this.preload()
    }

    preload = async () => {
		let tabs = Object.entries(this.state.tt)
		let { settings } = this.props.auth.user 
		let answers = { ...this.state.tt }

		for (let i in tabs) {
			let [tab, questions_obj] = tabs[i]
			let questions = Object.entries(questions_obj)
			for (let j in Object.entries(questions)) {
                let [question, value] = questions[j]
                let answer = this.formatLoadAnswer(question, value.save_state === 'profile' ? this.props.auth.user[question] : settings[question])
                switch (question) {
                    case ('body_fat') : 
                        const history = this.props.dash.history
                        history.sort((a, b) => b.time - a.time)
                        answers = { ...answers, [tab]: { ...answers[tab], [question]: { ...answers[tab][question], answer: history[0].body_fat}}}
                        break
                    case ('group') :
                    case ('active') :
                    case ('save_state') : continue
                    default : answers = { ...answers, [tab]: { ...answers[tab], [question]: { ...answers[tab][question], answer: answer === undefined ? '' : answer }}}       
                }
			}
		}

        this.setState({ tt: answers, preloaded: true })
	}

    formatLoadAnswer = (question, answer) => {
		switch (question) {
			case ('height_feet'): return Math.round(this.props.auth.user.settings.height / 12)
			case ('height_inches'): return this.props.auth.user.settings.height % 12
			case ('height_meters'): return this.adjustMeasure('length', this.props.auth.user.settings.height, true, 'Metric') / 100
			case ('month'): return (new Date(this.props.auth.user.dob)).getMonth()
			case ('day'): return (new Date(this.props.auth.user.dob)).getDate()
			case ('year'): return (new Date(this.props.auth.user.dob)).getFullYear() 
			case ('breakfast'): return this.props.auth.user.settings?.rec_cooking?.breakfast 
			case ('lunch'): return this.props.auth.user.settings?.rec_cooking?.lunch 
			case ('dinner'): return this.props.auth.user.settings?.rec_cooking?.dinner 
			case ('physical_limitations_active'): return this.props.auth.user.settings.physical_limitations?.length ? true : false
			case ('allergies_active'): return this.props.auth.user.settings.allergies?.length ? true : false
 			case ('health_conditions_active'): return this.props.auth.user.settings.health_conditions?.length ? true : false
			default: return answer
		}
	}

    adjustMeasure = (type, num) => {
        return Math.round(num * Measure[this.props.auth.user.settings.unit][type].mult * 10) / 10
    } 

	handleChange = (question, answer, tab) => {
		let tabs = this.state.tt
        tabs[tab ? tab : Object.keys(tabs)[this.state.tab]][question] = {...tabs[tab ? tab : Object.keys(tabs)[this.state.tab]][question], answer}
        this.setState({ tt : tabs })
    }

	handleError = (question, error) => {
		let tabs = this.state.tt
		tabs[Object.keys(tabs)[this.state.tab]][question] = {...tabs[Object.keys(tabs)[this.state.tab]][question], err: error}
		this.setState({ tt : tabs })
	}

	verifyQuestion = (question) => {
		switch (question.type) {
			case ('height') :
			case ('exclude') :
			case ('physical_limitations') :
			case ('allergies') :
			case ('health_conditions') :
			case ('birth'):
			case ('convenient') : return true
			case ('cooking_breakfast'): 
			case ('cooking_lunch'):
			case ('cooking_dinner'): return question.answer >= 0
			case ('day') : return question.answer <= 31
			case ('year') : return question.answer >= 1920
			case ('month') : return question.answer >= 0 && question.answer <= 11

			case ('percent') : {
				if (question.answer > 100 || question.answer < 0 || question.answer === "") {
					return false
				} else {
					return true 
				}
			}

			case ('weight') : {
				if (this.adjustMeasure("mass", question.answer) > 500 || this.adjustMeasure("mass", question.answer) < 70 || this.adjustMeasure("mass", question.answer) === "") {
					return false
				} else {
					return true 
				}
			}

			default : {
				if (Array.isArray(question.answer)) {
					if (question.answer.length > 0) {
						return true
					}
				} else if (question.answer !== "") {
					return true
				}
			}
		}

		return false
	}

	verifyEntries = (questions) => {
		let valid = 0, count = 0
		for (let property in questions) {
			if (property !== 'active') {
				if (this.verifyQuestion(questions[property])) valid += 1
				count += 1
			}
		} 

		return Math.round(valid / count * 100)
	}

    verifyRecent = () => {
        let recentRecalibration, history = this.props.dash.history
        history.sort((a, b) => a.time - b.time)
        for (let element in history) {
            let date = history[element]
            if (date.type === 'initial_weigh_in' || date.type === 'recalibration') {
                recentRecalibration = date
                break
            }
        }

        if (recentRecalibration && (recentRecalibration.waist_size === null || recentRecalibration.waist_size === this.state.tt.basic.waist_size.answer) && recentRecalibration.weight === Number(this.state.tt.basic.weight.answer)) return true
        return false
    }
    
    handleContinue = () => {
        let [key, tab] = Object.entries(this.state.tt)[this.state.tab]
        let questions = Object.keys(tab).filter((item) => item !== 'save_state' && item !== 'active' && item !== 'group')
        if (tab?.save_state === 'settings') return this.handleSettingsUpdate(tab, questions)
        return this.handleContinueTab()
    }

    handleBack = () => {
		let tab = this.state.tab - 1
        if (tab < 0) this.handleClose()
		while (this.state.tt[Object.keys(this.state.tt)[tab]] && this.state.tt[Object.keys(this.state.tt)[tab]].active === false) tab -= 1
		window.scrollTo(0, 0)
		this.setState({ tab: tab < 0 ? 0 : tab })	
	}

    handleContinueTab = (iterator) => {
		let tab = this.state.tab + ((typeof iterator) === 'number' ? iterator : 1)
		if (this.state.tt[Object.keys(this.state.tt)[tab]]) while (this.state.tt[Object.keys(this.state.tt)[tab]] && this.state.tt[Object.keys(this.state.tt)[tab]].active === false) tab += 1
		this.setState({ tab })
		return tab
	}

    handleSettingsUpdate = (tab, questions) => {
        let settings = {}
        for (let i = 0; i < questions.length; i++) settings[questions[i]] = this.formatAnswer(questions[i], tab[questions[i]].answer)

        this.setState({ loading: true })
        this.props.updateProfile(this.props.auth.user._id, { settings }).then((response) => {
            this.setState({ loading: false })
            this.handleContinueTab()
        }).catch((error) => {
            this.setState({ loading: false })
        })
    }

    handleRecalibration = () => {
        this.setState({ loading: true })
        this.props.createRecalibration(this.props.auth.user._id, { 
            weight: this.formatAnswer('weight', this.state.tt.basic.weight.answer),
            body_fat: Number(this.state.tt.basic.body_fat.answer),
            waist_size: Number(this.state.tt.basic.waist_size.answer),
            feedback: this.state.tt.feedback.feedback.answer
        }).then((response) => {
            this.setState({ loading: false })
        }).catch((error) => {
            this.setState({ loading: false })
        })
    }

    formatAnswer = (question, answer) => {
		switch (question) {
			case ('height_meters') :
			case ('height_inches') :
			case ('height_feet') : 
			case ('breakfast') :
			case ('lunch') :
			case ('dinner') :
			case ('day') :
			case ('month') :
			case ('year') :
			case ('emotional') : return null
			case ('rec_cooking') : return { breakfast: this.state.tt.rec_cooking.breakfast.answer, lunch: this.state.tt.rec_cooking.lunch.answer, dinner: this.state.tt.rec_cooking.dinner.answer }
			case ('dob') : return (new Date(`${this.state.tt.basic.year.answer}-${this.state.tt.basic.month.answer + 1}-${this.state.tt.basic.day.answer}`)).getTime()
			case ('target_weight') : 
            case ('weight') : 
			case ('starting_weight') : return this.adjustMeasure("mass", answer)
			case ('height') :
				if (this.state.tt.basic.unit.answer === 'Imperial') {
					return Number(this.state.tt.basic.height_inches.answer) + Number(this.state.tt.basic.height_feet.answer) * 12
				} else {
					return this.adjustMeasure("length", Number(this.state.tt.basic.height_meters.answer) * 100)
				}
			default: return answer
		}
	}
    
    getInputAdornment = (type) => {
        if (this.props.auth.user.settings) {
            return <InputAdornment> {Measure[this.props.auth.user.settings.unit][type].unit} </InputAdornment>
        }
    }

    getRecentBodyFat = () => {
        let history = this.props.dash.history
        history.sort((a, b) => b.time - a.time)
        for (let i in history) {
            if (history[i].body_fat) {
                return history[i].body_fat
            }
        }

        return undefined
    }

    mapHead = (value, pool) => {
        for (let element in pool) {
            let att = pool[element]
            if (Number(att.value) === value) return att.head.toLowerCase()
        }

        return null
    }

    handleActiveChange = (tab, value, next) => {
        let tabs = this.state.tt
        tabs[tab] = { ...tabs[tab], active: value }
        this.setState({ tt: { ...tabs } }, next)
    }

    handleGroupActiveChange = (group, value, next) => {
        let tabs = this.state.tt, tabs_array = Object.entries(tabs)
        tabs_array.map(([question, tab]) => { if (tab.group === group) tabs[question] = { ...tabs[question], active: value } })
        this.setState({ tt: { ...tabs } }, next)
    }

    handleClose = () => {
        this.props.handleClose()
    }

    formatAnswer = (question, answer) => {
		switch (question) {
			case ('height_meters') :
			case ('height_inches') :
			case ('height_feet') : 
			case ('breakfast') :
			case ('lunch') :
			case ('dinner') :
			case ('day') :
			case ('month') :
			case ('year') :
			case ('emotional') : return null
			case ('rec_cooking') : return { breakfast: this.state.tt.rec_cooking.breakfast.answer, lunch: this.state.tt.rec_cooking.lunch.answer, dinner: this.state.tt.rec_cooking.dinner.answer }
			case ('dob') : return (new Date(`${this.state.tt.basic.year.answer}-${this.state.tt.basic.month.answer + 1}-${this.state.tt.basic.day.answer}`)).getTime()
			case ('target_weight') : return this.adjustMeasure("mass", answer)
			case ('starting_weight') : return this.adjustMeasure("mass", answer)
			default: return answer
		}
	}

    renderQuestion = (values, name, multi, options) => {
		let answer = this.state.tt[name][options?.question || name].answer
		return values.map((condition, i) => {
			let label = null
			let action = null
			let active = null
			let render = null

			if (options) {
				if (options.labels) label = options.labels[condition]
				if (options.actions) action = options.actions[condition]
				if (options.active) active = options.active[condition]
				if (options.render) render = options.render[condition]
			}

			return (
				multi ? <QuestionCard key={i} disabled={active ? active(answer) : answer.includes(condition)} onClick={(event) => { 
					this.handleChange(options?.question || name, answer.includes(condition) ? answer.filter((ans) => ans !== condition) : [...answer, condition])
					if (action) action()
				}}>
					{ (active ? active(answer) : answer.includes(condition)) ? <RemoveIcon style={{ position: 'absolute', right: '15px', top: '50%', transform: 'translateY(-50%)', fontSize: '16px'}}/> : <AddIcon style={{ position: 'absolute', right: '15px', top: '50%', transform: 'translateY(-50%)', fontSize: '16px' }}/> }
					{ render ? render () : <div>
						<h6 style={{ fontSize: '14px', marginBottom: '0px', margin: '0px 30px 0px 30px'}}> {label || toTitleCase(condition)} </h6> 
					</div> }
				</QuestionCard>	: <QuestionCard key={i} disabled={active ? active(answer) : answer === condition} onClick={(event) => { 
					if (action) action()
					this.handleChange(options?.question || name, condition)
				}}>
					{ render ? render () : <div>
						<h6 style={{ fontSize: '14px', marginBottom: '0px', margin: '0px 30px 0px 30px'}}> {label || toTitleCase(condition)} </h6> 
					</div> }				
				</QuestionCard>	
			)
		})
	}

    renderTab = () => {
        return (
            <PageHandler tab={this.state.tab}>
                <Page> { /* basic */ }
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '40px', padding: '10px' }}>
                        <div>
                            <QuestionHeader> What's your current weight? </QuestionHeader>
                            <Input disablePlaceholder suff={this.getInputAdornment("mass")} type="number" style={{ marginBottom: '10px', width: '100%'}} error={this.state.tt.basic.weight.err} onBlur={() => this.handleError("weight", !this.verifyQuestion(this.state.tt.basic.weight))} value={this.state.tt.basic.weight.answer} placeholder={"Weight"} onChange = {(event) => {this.handleChange("weight", event.target.value)}}/>
                        </div>
                        <div>
                            <QuestionHeader> What's your current waist size? </QuestionHeader>
                            <Input disablePlaceholder suff={this.getInputAdornment("length")} type="number" style={{ marginBottom: '10px', width: '100%'}} error={this.state.tt.basic.waist_size.err} onBlur={() => this.handleError("waist", !this.verifyQuestion(this.state.tt.basic.waist_size))} value={this.state.tt.basic.waist_size.answer} placeholder={"Waist"} onChange = {(event) => {this.handleChange("waist_size", event.target.value)}}/> 
                        </div>
                        <div>
                            <QuestionHeader hasSubheader> What's your current body fat? </QuestionHeader>
                            <QuestionSubheader> Move the slider to select </QuestionSubheader> 
                            <Slider value={this.state.tt.basic.body_fat.answer !== '' ? this.state.tt.basic.body_fat.answer : 0} valueLabelFormat={(value) => Math.round(value * 100) + '%'} step={.01} max={.5} min={.08} valueLabelDisplay="auto" style={{ marginLeft: '5px', marginRight: '5px'}} onChange={(event, value) => this.handleChange("body_fat", value)}/>                                    
                            { this.state.tt.basic.body_fat.answer !== '' ? <BodyFatCard foreground={{ body_fat: this.state.tt.basic.body_fat.answer, gender: this.props.auth.user.gender }} background={this.getRecentBodyFat() ? { body_fat: this.getRecentBodyFat(), gender: this.props.auth.user.gender } : null}/> : null}
                        </div>
                    </div>
                </Page>
                <Page>
                    <div style={{ padding: '10px' }}>
                        <QuestionHeader hasSubheader> Would you like to adjust your meal plan recommendations? </QuestionHeader>
                        <QuestionSubheader> If not, feel free to continue! </QuestionSubheader>
                        <GenerationCalendar value={this.state.tt.rec_gen_map.rec_gen_map.answer} onChange={this.handleChange}/>
                    </div>
                </Page>
                <Page> { /* adjust_goals_toggle */ }
                    <div style={{ padding: '10px' }}>
                        <QuestionHeader> Have your weight or body composition goals changed? </QuestionHeader>
                        { 
                            this.renderQuestion([true, false], 'adjust_goals_toggle', false, {
                                labels: {
                                    [true] : 'Yes',
                                    [false] : 'No'
                                },
                                actions : {
                                    [true] : () => this.handleGroupActiveChange('goals', true),
                                    [false] : () => this.handleGroupActiveChange('goals', false)
                                }
                            })
                        }
                    </div>
                </Page>
                <Page> { /* target_weight */ }
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', gap: '40px' }}> 
                        <div>
                            <QuestionHeader> What's your target weight? </QuestionHeader> 
                            <Input disablePlaceholder suff={this.getInputAdornment("mass")} type="number" style={{ width: '100%'}} error={this.state.tt.target_weight.target_weight.err} onBlur={() => this.handleError("weight", !this.verifyQuestion(this.state.tt.target_weight.target_weight.answer))} value={this.state.tt.target_weight.target_weight.answer} placeholder={"Weight"} onChange = {(event) => {this.handleChange("target_weight", event.target.value)}}/>
                        </div>
                        <div>
                            <QuestionHeader hasSubheader> What's your target body fat? </QuestionHeader>
                            <QuestionSubheader> Move the slider to select </QuestionSubheader> 
                            <Slider value={this.state.tt.target_weight.target_body_fat.answer} valueLabelFormat={(value) => Math.round(value * 100) + '%'} step={.01} max={.5} min={.08} valueLabelDisplay="auto" style={{ marginLeft: '5px', marginRight: '5px'}} onChange={(event, value) => this.handleChange("target_body_fat", value)}/>                                     
                            { this.state.tt.target_weight.target_body_fat.answer !== '' ? <BodyFatCard foreground={{ body_fat: this.state.tt.target_weight.target_body_fat.answer, gender: this.props.auth.user.gender }} background={this.getRecentBodyFat() ? { body_fat: this.getRecentBodyFat(), gender: this.props.auth.user.gender } : null}/> : null}
                        </div>
                    </div>                
                </Page>
                <Page> { /* feedback_toggle */ }
                    <div style={{ padding: '10px' }}>
                        <QuestionHeader> We're always looking to improve our platform. Do you have any feedback from your experience? </QuestionHeader> 
                        { 
                            this.renderQuestion([true, false], 'feedback_toggle', false, {
                                labels: {
                                    [true] : 'Yes',
                                    [false] : 'No'
                                },
                                actions : {
                                    'true' : () => this.handleActiveChange('feedback', true),
                                    'false' : () => this.handleActiveChange('feedback', false)
                                }
                            })
                        }
                    </div>
                </Page>
                <Page> { /* feedback */ }
                    <div style={{ padding: '10px' }}>
                        <QuestionHeader> We're always looking to improve our platform. Do you have any feedback from your experience? </QuestionHeader> 
                        <Input disablePlaceholder placeholder={'My macros were too high...'} style={{ width: '100%'}} onChange={(event) => this.handleChange('feedback', event.target.value)}/>
                    </div>
                </Page>
                <Page onRender={this.handleRecalibration} onDismount={this.handleClose}> { /* response */ }
                    <div style={{ padding: '10px' }}>
                        <QuestionHeader style={{ textAlign: 'left', marginRight: '20px'}}> These are the updates to your program to help you make rapid progress and prevent any plateaus. </QuestionHeader>
                        <QuestionCard>
                            <div style={{ marginTop: '0px' }}>
                                <h6 style={{ fontSize: '15px' }}> Nutrition </h6>
                                <h6 style={{ opacity: '.7', textAlign: 'left', fontSize: '14px', marginRight: '20px' }}> Your new calories are <span style={{ color: Color.CALORIES }}> {this.props.auth.user.curr.macros.calories}</span>, from <span style={{ color: Color.PROTEIN, opacity: '1'}}>{ this.props.auth.user.curr.macros.protein}</span> grams of protein, <span style={{ color: Color.CARBS, opacity: '1'}}>{ this.props.auth.user.curr.macros.carbs}</span> grams of carbs, and <span style={{ color: Color.FAT, opacity: '1'}}> {this.props.auth.user.curr.macros.fat}</span> grams of fat. </h6>
                            </div>
                        </QuestionCard>
                    </div>
                </Page>
            </PageHandler>
        )
    }

	render() {
        return (
            <ModalSlide direction='left' in={this.props.open}>
                <Card style={{ position: 'fixed', top: '0', right: '0', left: '0', marginRight: 'auto', marginLeft: 'auto', zIndex: '1000', width: '100%', height: '100%', overflowY: 'scroll', overflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', boxShadow: '' }}>
                    <CardContent style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Fade in={true}>
                            <div>
                                <ArrowBackIcon style={{ fontSize: '22px', opacity: '.7', position: 'absolute', top: '25px', left: '25px', zIndex: '2', cursor: 'pointer' }} onClick={this.handleBack}/>
                            </div>
                        </Fade>
                        <div style={{ marginTop: '60px', padding: '5px' }}>
                            <PageIconHandler tab={this.state.tab} max={5}>
                                { this.renderTab() }
                            </PageIconHandler>
                        </div>
                        <ModalButton icon={<MdOutlineShortcut/>} label={'Continue'} disabled={this.verifyEntries(this.state.tt[Object.keys(this.state.tt)[this.state.tab]]) !== 100} loading={this.state.loading} handleClick={this.handleContinue} style={{ position: 'sticky', bottom: '25px', width: '100%', left: '20px', marginTop: 'auto' }}/>
                    </CardContent>
                </Card>
            </ModalSlide>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        createRecalibration,
        updateProfile 
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RecalibrationModal)

