import React, { useState, useRef, createRef, useEffect } from 'react';
import { Color } from '../util'
import styled from 'styled-components'

const Option = styled.div`
    transition: color ease .2s;
    border-radius: 20px;
    padding: 7px 12px 7px 12px;
    cursor: pointer;
    color: ${(props) => props.selected ? 'white' : 'rgba(0,0,0,.5)'}
`

const Selector = (props) => {
    const [refs, setRefs] = useState({})
    const [selectedRef, setSelectedRef] = useState(null)
    const containerRef = useRef()

    useEffect(() => {
        let newRefs = {}
        props.options.forEach((option) => {
            newRefs[option.value] = createRef()
        })
        setRefs({ ...newRefs })
    }, [props.options])

    useEffect(() => {
        setSelectedRef(refs[props.value])
    }, [refs, props.value])

    const getSelectedRect = () => {
        if (!selectedRef || !selectedRef.current) return null
        let dimensions = selectedRef.current.getBoundingClientRect()
        if (!containerRef || !containerRef.current) return null
        let containerDimensions = containerRef.current.getBoundingClientRect()
        return { bottom: containerDimensions.bottom - dimensions.bottom, top: dimensions.top - containerDimensions.top, right: containerDimensions.right - dimensions.right, width: dimensions.width, height: dimensions.height }
    }

    return (
        <div ref={containerRef} style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', position: 'relative', ...props.style }}>
            { getSelectedRect() ? <div style={{ position: 'absolute', top: `${getSelectedRect().top}px`, right: `${getSelectedRect().right}px`, width: `${getSelectedRect().width}px`, height: `${getSelectedRect().height}px`, backgroundColor: Color.PRIMARY, zIndex: '0', transition: 'top 300ms ease, width 300ms ease, opacity 300ms ease, right 300ms ease', borderRadius: '25px' }}/> : null }
            <div style={{ display: 'flex', borderRadius: '20px', fontSize: '0', zIndex: '20'}}>
                { props.options.map((option, i) => {
                    return (
                        <Option ref={refs[option.value]} key={i} selected={props.value === option.value} onClick={() => props.handleChange(option.value, option.variable)}>
                            <p style={{ marginBottom: '0px', fontSize: '13px', zIndex: '20' }}> {option.label} </p>
                        </Option>
                    )
                })}
            </div>
        </div>
    )
}

export default Selector;