import React, { Component } from 'react';
import ChatIcon from '@material-ui/icons/ChatRounded';
import { Color } from '../util' 

//import { IntercomProvider } from "react-use-intercom";

//const INTERCOM_APP_ID = "f3vzltnk";
class HelpButton extends Component {
    /*componentDidMount() {
        window.Intercom("boot", {
            app_id: "f3vzltnk",
            custom_launcher_selector: "#support_chat",
        });

        window.Intercom("update", {
            hide_default_launcher: true,
        });
    }*/

    /* <IntercomProvider appId={INTERCOM_APP_ID} autoBoot> */

    render() {
        return (
            <a href={'mailto: justin@stnbly.io'} style={{ textDecoration: 'none', marginLeft: 'auto' }}>
                <div id={'support_chat'} style={{ color: Color.PRIMARY, alignItems: 'center', display: 'flex' }}>
                    <p style={{ marginBottom: '0px', fontSize: '16px', color: Color.PRIMARY, marginLeft: 'auto', cursor: 'pointer' }}> Chat </p>
                    <ChatIcon style={{ fontSize: '16px', marginLeft: '6px', marginTop: '2px'}}/>
                </div> 
            </a>           
        );
    }
}

export default HelpButton;