import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Hoverable } from '.';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components'

const TableContainer = styled.div`
    padding: 0px 20px 10px 20px;
`
    //overflow-y: scroll;
    //max-height: 500px;


const TableRows = styled.div`
    transition: max-height 3s ease;
`

class Table extends Component {
    generateHeaders = () => {
        return (
            <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)', position: 'sticky', top: '0px', zIndex: '3', backgroundColor: 'white' }}>
                { this.props.columns.map((column) => {
                    return ( <div style={{ display: 'inline-block', width: `${column.width}%`, padding: '10px 10px 10px 5px' }}>
                        <h6 style={{ marginBottom: '0px', fontSize: '13px', textOverflow: 'ellipsis', opacity: '.9'  }}> {column.name} </h6>
                    </div> )
                })}
            </div>
        )
    }

    generateRows = () => { 
        return ( 
            <TableRows empty={!this.props.data.length}>
                {
                    this.props.data.length ? this.props.data.map((row, i) => {
                        return (
                            <Hoverable key={i} functions={this.props.functions?.map((func) => { return {...func, handleClick: () => func.handleClick(this.props.raw[i])}})}>
                                <div style={{ height: '41px', cursor: 'pointer' }} onClick={this.props.handleClick ? () => this.props.handleClick(this.props.raw[i]) : undefined}>
                                    { this.props.columns.map((column, j) => {
                                        let entry = column.transform ? column.transform(row[j]) : row[j]
                                        return (
                                            <div style={{ display: 'inline-block', width: `${column.width}%`, borderRight: '1px solid rgba(0, 0, 0, 0.05)', borderBottom: '1px solid rgba(0, 0, 0, 0.05)', padding: '10px', verticalAlign: 'top', height: '100%'}} onClick={column.handleClick ? () => column.handleClick(row[j], this.props.raw[i]) : undefined}>
                                                <Hoverable handleHover={column.handleHover ? () => column.handleHover(this.props.raw[i]) : undefined} tooltip={ column.renderHover ? column.renderHover(row[j], this.props.raw[i]) : undefined }>
                                                    { !column.render ? 
                                                        <p style={{ marginBottom: '0px', fontSize: '14px', textOverflow: 'ellipsis', whiteSpace: 'nowrap',  overflow: 'hidden', verticalAlign: 'top'  }}> { entry !== null ? entry : 'null' } </p>
                                                        : column.render(row[j], this.props.raw[i]) 
                                                    }
                                                </Hoverable>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Hoverable>
                        )
                    }) : <h6 style={{ fontSize: '13px', opacity: '.7', textAlign: 'center', padding: '20px' }}> No Results </h6> 
                }
            </TableRows>
        )
    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                <TableContainer>
                    { this.props.loading ? 
                        <div style={{ position: 'absolute', top: '0', right: '0'}}>
                            <CircularProgress size={15}/>
                        </div> : null
                    }
                    { this.generateHeaders() }
                    { this.generateRows() }
                </TableContainer>
            </div>
        );
    }
}

Table.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    style: PropTypes.object
};

export default Table