//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateCustomActivity, deleteCustomActivity } from '../actions/dashboardActions'
//Components and Pages
import { CircularProgress, CardContent, Chip } from '@material-ui/core';
import { AttributeModal, Modal, CardFloat, ModalButton, Input } from './'
import { equipment, extremities, main_muscle, movement, Color } from '../util'
//Images
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

const initialState = {
    name: '',
    equipment: [],
    attributeOpen: false,
    attributeOptions: [],
    attributeName: '',
    attributeLabel: '',
    updated: false,
    success: false,
    error: false
}

const attributes = {
    equipment: {
        label: 'Equipment',
        options: equipment
    },
    muscle_groups: {
        label: 'Muscle Groups',
        options: main_muscle
    },
    extremities: {
        label: 'Extremities',
        options: extremities
    },
    movement: { 
        label: 'Movement',
        options: movement
    }
}

class CustomActivityEditModal extends Component {
    constructor (props) {
        super(props)
        this.state = initialState
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            if (this.props.activity && this.props.activity !== null) {
                let { name, equipment } = this.props.activity
                this.setState({ name, equipment })
            }
        }
    }

    handleChange = (question, answer) => {
        this.setState( { [question]: answer, updated: true })
    }

    handleClose = () => {
        this.setState(initialState)
        this.props.handleClose()
    }

    confirmSubmit = () => {
        if (this.props.dash.customLoading) return false
        else if (this.state.name === '') return false
        return this.state.updated
    }
    
    handleUpdate = () => {
        let { name, equipment } = this.state
        this.props.updateCustomActivity(this.props.auth.user._id, this.props.activity._id, { name, equipment }).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.handleError()
        })
    }

    handleDelete = () => {
        this.props.deleteCustomActivity(this.props.auth.user._id, this.props.activity._id).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.handlError()
        })
    }

    handleSuccess = () => {
        this.setState({ success: true })
        setTimeout(this.handleClose, 400)
    }

    handleError = () => {

    }

    renderAttributes = (name) => {
        let chips = this.state[name].length ? this.state[name].map((muscle_group, i) => {
            return <Chip key={i} style={{ margin: '2px'}} label={muscle_group} onDelete={() => this.handleChange(name, this.state[name].filter(item => item !== muscle_group))}/>
        }) : [<Chip key={0} style={{ margin: '2px'}} label={'None'}/>]

        chips.push(<AddIcon key={chips.length} fontSize='small' style={{ margin: '2px', opacity: '.7', cursor: 'pointer'}} onClick={() => this.setState({ attributeOpen: true, attributeName: name, attributeOptions: attributes[name].options, attributeLabel: attributes[name].label })}/>)
        return chips
    }

	render() {
        return (
            <div>
                <Modal direction='up' in={this.props.open && !this.state.attributeOpen} handleClose={this.handleClose} head={`Update ${this.props.activity ? this.props.activity.name : ''}`} functions={[
                    this.props.dash.customLoading ? <CircularProgress size={20} style={{ color: 'white' }}/> : <DeleteIcon style={{ marginBottom: '50%', color: 'white', fontSize: '20px', backgroundColor: Color.CALORIES_ERROR }} onClick={this.handleDelete}/>
                ]}>
                    <CardFloat>
                        <CardContent style={{ padding: '20px' }}>
                            <Input 
                                style={{ marginBottom: '10px', width: '100%' }}
                                value={this.state.name} 
                                placeholder="Name"
                                onChange = {(event) => {
                                this.handleChange("name", event.target.value)}}/>
                        </CardContent>
                    </CardFloat>
                    <ModalButton success={this.state.success} disabled={!this.confirmSubmit()} loading={this.props.dash.customLoading} handleClick={this.handleUpdate} label={`Update ${this.state.name}`}/>
                </Modal>
                <AttributeModal value={this.state[this.state.attributeName]} open={this.state.attributeOpen} label={this.state.attributeLabel} handleClose={() => this.setState({ attributeOpen: false })} options={this.state.attributeOptions} handleChange={this.handleChange} name={this.state.attributeName}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        updateCustomActivity,
        deleteCustomActivity }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomActivityEditModal)

