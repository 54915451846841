//Classes and Functions
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
//Components and Pages
import { CardContent, Fade } from '@material-ui/core'
import { SkeletonCard, CardFloat, Button, NutritionBar, Image } from './'
//Images
import CheckIcon from '@material-ui/icons/Check';
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import EditIcon from '@material-ui/icons/Edit';
import { Color, getAttribute, toTitleCase } from '../util'
import { TbRepeat } from 'react-icons/tb'

class FoodCard extends Component {
    verifyMeal = (meal) => {
        let sessions = this.props.dash.sessions.filter((session) => session.type === 'food')
        for (let index in sessions) {
            let session = sessions[index], temp = new Date(session.time), curr = (this.props.trackingTime ? new Date(this.props.trackingTime) : new Date())
            if (temp.getDate() === curr.getDate() && session.target_id === meal._id) return true
        }

        return false
    }

    handleClick = (event, link) => {
        event.stopPropagation()
        event.preventDefault()
        if (link) {
            this.props.history.push(link)
        }
    }

    renderButton = () => {
        let button = null
        if (this.props.actionLink) {
            button = (
                <Button disabled={this.props.disabled} onClick={(event) => this.props.handleAddOpen ? this.props.handleAddOpen({ event: event, meal: this.props.food, serving: this.props.amount }) : null}>
                    <AddRoundedIcon style={{ fontSize: '18px' }}/>
                </Button>
            )
        } else if (this.props.handleUpdateOpen && this.props.session) {
            button = (
                <Button style={{ color: Color.PRIMARY, backgroundColor: 'rgba(0,0,0,.05)' }}onClick={(event) => this.props.handleUpdateOpen({ event: event, session: this.props.session })}>
                    <EditIcon style={{ fontSize: '18px' }}/>
                </Button>
            )
        } else if (this.props.handleAddOpen) {
            if (this.props.verifyFood && this.verifyMeal(this.props.food)) {
                button = (
                    <Button style={{ backgroundColor: Color.CARBS_SUCCESS }}>
                        <CheckIcon style={{ fontSize: '18px'}}/>
                    </Button>
                )
            } else {
                button = (
                    <Button style={{ color: Color.PRIMARY, backgroundColor: 'rgba(0,0,0,.05)'}} disabled={this.props.disabled} onClick={(event) => this.props.handleAddOpen({ event: event, meal: this.props.food, serving: this.props.amount })}>
                        <AddRoundedIcon style={{ fontSize: '18px' }} />
                    </Button>
                )
            }
        }

        return (
            <div onClick={(event) => this.handleClick(event, this.props.actionLink)} style={{ display: 'flex', marginLeft: 'auto', gap: '5px', marginRight: '10px' }}>
                { this.props.handleSwapOpen ? <Button style={{ color: Color.PRIMARY, backgroundColor: 'rgba(0,0,0,.05)' }} disabled={this.props.disabled} onClick={this.props.handleSwapOpen}>
                    <TbRepeat style={{ fontSize: '18px' }} />
                </Button> : null }
                { button }
            </div>
        )
    }

    isFDA = (food) => {
        return food.foodClass && food.foodClass !== 'Custom'
    }

    isCustom = (food) => {
        return this.props.food.foodClass && this.props.food.foodClass === 'Custom'
    }

    renderMacros = (food) => {
        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
                    <NutritionBar style={{ position: 'relative', width: '100px', height: '8px'}} carbs={getAttribute('carbs', food, this.props.session)} protein={getAttribute('protein', food, this.props.session)} fat={getAttribute('fat', food, this.props.session)}/>
                    <p style={{ marginBottom: '0px', fontSize: '12px', marginLeft: '5px', opacity: '.7' }}> {getAttribute('calories', food, this.props.session)} Cals </p>
                </div>
                <p style={{ fontSize: '12px', marginBottom: '0px' }}> <span style={{ color: Color.CARBS }}> {getAttribute('carbs', food, this.props.session)}C </span> <span style={{ color: Color.PROTEIN }}> {getAttribute('protein', food, this.props.session)}P </span> <span style={{ color: Color.FAT }}> {getAttribute('fat', food, this.props.session)}F </span> </p>
            </div>
        )
    }

    renderMiniMacros = (food) => {
        return (
            <div>
                <p style={{ fontSize: '12px', margin: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}> 
                    <span style={{ color: Color.CARBS }}> {getAttribute('carbs', food, this.props.session)}C </span> 
                    <span style={{ color: Color.PROTEIN }}> {getAttribute('protein', food, this.props.session)}P </span> 
                    <span style={{ color: Color.FAT }}> {getAttribute('fat', food, this.props.session)}F </span> 
                    {getAttribute('calories', food, this.props.session)} Cals 
                </p>
            </div>
        )  
    }

    renderHeader = (food) => {
        return <div style={{ width: '90%' }}>
            <h6 style={{ marginBottom: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '14px' }}> 
                { toTitleCase(food.name) } 
                { this.props.amount && this.props.amount !== 1 ? <span style={{ opacity: '.5', fontSize: '12px' }}> x{this.props.amount} </span> : null } 
                {/* this.props.food.class === 'custom' && this.props.handleCustomUpdateOpen ? <EditIcon style={{ opacity: '.5', fontSize: '18px', marginBottom: '3px', marginLeft: '4px'}} onClick={(event) => this.props.handleCustomUpdateOpen({ event, meal: food })}/> : null */} 
            </h6>
            { food.brand_name ? <p style={{ margin: '0px', fontSize: '12px', opacity: '.7' }}> {food.brand_name} </p> : null }
        </div> 
    }

    renderMini = () => {
        return <Fade in={true}>
            <CardFloat outline style={{ cursor: this.props.handleInfoOpen ? 'pointer' : null, backgroundColor: 'rgba(0,0,0,.05)', minWidth: '0px', boxShadow: 'none', height: '75px', borderRadius: '20px', dropShadow: '', border: 'none', display: 'flex', alignItems: 'center', padding: '3px', margin: '10px 0px 10px 0px' }} onClick={(event) => this.props.handleInfoOpen ? this.props.handleInfoOpen({ event: event, meal: this.props.food, session: this.props.session, amount: this.props.amount }) : null}>
                { this.props.food.image_url || this.props.food.thumb_url ? <Image src={this.props.food.thumb_url || this.props.food.image_url} style={{ height: '55px', width: '55px', objectFit: 'cover', borderRadius: '16px', margin: '5px 0px 5px 7px' }}/> : null }
                <div style={{ padding: '8px', width: '100%', minWidth: '0px' }}> 
                    <div>
                        { this.renderHeader(this.props.food) }
                        { this.renderMiniMacros(this.props.food) }
                    </div>
                    { /* this.props.session ? <p style={{ position: 'absolute', right: '20px', opacity: '50%', bottom: '8px', fontSize: '12px', marginBottom: '0px' }}> {this.props.session.metadata.servings} {this.props.session.metadata.serving_unit} </p> : null */ }
                </div>
                { this.renderButton() }
            </CardFloat>
        </Fade> 
    }

    render() {
        return (
            <div>
                { this.props.food ? 
                    <div onClick={(event) => this.handleClick(event, this.props.link)}>
                        { this.props.mini ? <Fade in={true}>
                            <CardFloat outline style={{ cursor: this.props.handleInfoOpen ? 'pointer' : null, backgroundColor: 'rgba(0,0,0,.05)', boxShadow: 'none', dropShadow: '', border: 'none', display: 'flex', alignItems: 'center', padding: '3px', margin: '10px 0px 10px 0px' }} onClick={(event) => this.props.handleInfoOpen ? this.props.handleInfoOpen({ event: event, meal: this.props.food, session: this.props.session, amount: this.props.amount }) : null}>
                                { this.props.food.image_url || this.props.food.thumb_url ? <Image src={this.props.food.thumb_url || this.props.food.image_url} style={{ height: '64px', width: '64px', objectFit: 'cover', borderRadius: '15px', marginLeft: '14px' }}/> : null }
                                <div style={{ padding: '14px', width: '250px' }}>
                                    <div style={{ marginRight: '35px' }} >
                                        { this.renderHeader(this.props.food) }
                                        { this.renderMacros(this.props.food) }
                                    </div>
                                    { /* this.props.session ? <p style={{ position: 'absolute', right: '20px', opacity: '50%', bottom: '8px', fontSize: '12px', marginBottom: '0px' }}> {this.props.session.metadata.servings} {this.props.session.metadata.serving_unit} </p> : null */ }
                                </div>
                                { this.renderButton() }
                            </CardFloat>
                        </Fade> : this.renderMini() }
                    </div> : <SkeletonCard/> }
            </div>
        )
    }
}

FoodCard.propTypes = {
    food: PropTypes.object,
    verifyFood: PropTypes.bool,
    serving: PropTypes.object,
    session: PropTypes.object,
    serving_id: PropTypes.string,
    amount: PropTypes.number,
    handleAddOpen: PropTypes.func,
    handleUpdateOpen: PropTypes.func,
    handleInfoOpen: PropTypes.func,
    actionLink: PropTypes.object,
    time: PropTypes.object
}

function mapStateToProps(state) {
	return {
		dash: state.dash,
	}
}

export default connect(mapStateToProps)(withRouter(FoodCard))