//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createCustomFood } from '../actions/dashboardActions'
//Components and Pages
import { CardContent, CircularProgress, Fade } from '@material-ui/core';
import { Modal, CardFloat, ModalButton, Input, Selector, SearchBar, Image, ChipSelector, CustomMealSearchModal } from './'
import { toTitleCase, Color, getAttribute } from "../util";
import EditIcon from '@material-ui/icons/Edit';
//Images
import NutritionInfo from './NutritionInfo';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import AddIcon from '@material-ui/icons/Add'
import api from '../api'

const initialState = {
    name: '',
    protein: '',
    carbs: '',
    fat: '',
    calories: '',
    success: false,
    error: false,
    type: 'food',
    components: [],
    data: [],
    edit: false,
    mealSearchOpen: false
}

const ComponentListing = (props) => {
    return (
        props.food ? <div style={{ margin: '7px 0px 7px 0px', padding: '8px', backgroundColor: 'rgba(0,0,0,.05)', borderRadius: '10px', fontSize: '14px', width: '50%' }} onClick={() => props.handleInfoOpen?.({ meal: props.food, populate: true })}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                { props.food.image_url || props.food.thumb_url ? <Image src={props.food.image_url || props.food.thumb_url} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', backgroundColor: 'grey', border: 'none', marginRight: '8px' }}/> : null }
                <p style={{ margin: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}> { toTitleCase(props.food.name) }  </p>       
            </div>
        </div> : null
    )
}

class CustomFoodAddModal extends Component {
    constructor (props) {
        super(props)
        this.state = initialState
    }

    handleChange = (question, answer) => {
        this.setState( { [question]: answer })
    }

    handleClose = (food) => {
        this.setState(initialState)
        this.props.handleClose(food)
    }

    adjustCalories = () => {
        this.setState({ calories: Number(this.state.protein * 4 + this.state.carbs * 4 + this.state.fat * 9) })
    }

    verifyCalories = () => {
        if (this.state.calories || this.state.protein || this.state.carbs || this.state.fat) {
            if (Number(this.state.calories) !== Number(this.state.protein * 4 + this.state.carbs * 4 + this.state.fat * 9)) {
                if (this.state.protein === "" && this.state.carbs === "" && this.state.fat === "") {
                    return { bool: false, res: 'macros'}
                } else {
                    return { bool: false, res: 'calories'}
                }
            }
        }

        return { bool: this.state.calories !== '' }
    }

    confirmSubmit = () => {
        return this.state.name !== '' && (this.state.type !== 'meal' || this.state.components.length)
    }
    
    handleCreate = () => {
        let { calories, carbs, protein, fat, name } = this.state
        
        let components = this.state.components.map((component) => {
            return {
                target_class: component.food.class,
                target_id: component.food._id,
                target_nix_id: component.food.nix_item_id,
                target_tag_id: component.food.tag_id,
                target_upc: component.food.upc,
                target: component.food.class === 'common' || component.food.class === 'branded' ? {
                    ...component.food,
                    class: null
                } : null,
                metadata: {
                    serving_unit: component.serving_unit,
                    servings: component.servings
                }
            }
        })

        this.props.createCustomFood({ 
            name,
            is_meal: this.state.type === 'meal',
            components: this.state.type === 'meal' ? components : null,
            calories: calories !== '' ? calories : 0, 
            carbs: carbs !== '' ? carbs : 0, 
            protein: protein !== '' ? protein : 0, 
            fat: fat !== '' ? fat : 0  
        }).then((response) => {
            this.handleSuccess(response)
        }).catch((error) => {
            this.handleError()
        })
    }

    handleSuccess = (food) => {
        this.setState({ success: true })
        setTimeout(() => this.handleClose(food), 400)
    }

    handleError = () => {
        
    }

    populate = async (food) => {
        let populate = null
        this.setState({ populating: true })

        try { 
            switch (food.class) {
                case ('common') : 
                    populate = await api.getCommonFoods(food.food_name) // get common nix foods
                    break
                case ('branded') : 
                    populate = await api.getBrandedFoods(food.nix_item_id) // get branded nix foods
                    break
                case ('recipe') : 
                    populate = await api.getRecipeById(food._id)
            }
        } catch (error) {
            console.log(error)
        }

        return populate?.data 
    }

    verify = (food) => food.is_complete !== false

    handleComponentAdd = async (options) => {
        let food = options.meal
        if (!this.verify(food)) {
            this.setState({ loadingComponent: true })
            food = await this.populate(food)
            return this.setState({ 
                calories: Number(this.state.calories) + getAttribute('calories', food, { metadata: { serving_unit: food.def_unit || 'serving', servings: food.def_size || 1 }}),
                carbs: Number(this.state.carbs)+ getAttribute('carbs', food, { metadata: { serving_unit: food.def_unit || 'serving', servings: food.def_size || 1 }}),
                protein: Number(this.state.protein) + getAttribute('protein', food, { metadata: { serving_unit: food.def_unit || 'serving', servings: food.def_size || 1 }}),
                fat: Number(this.state.fat) + getAttribute('fat', food, { metadata: { serving_unit: food.def_unit || 'serving', servings: food.def_size || 1 }}),
                components: [...this.state.components, { food, serving_unit: food.def_unit || 'serving', servings: food.def_size || 1 }], 
                loadingComponent: false,
                mealSearchOpen: false
            })
        }
    }

    handleComponentDelete = (i) => {
        let remove = this.state.components[i]
        this.setState({ 
            calories: this.state.calories - getAttribute('calories', remove.food, { metadata: { serving_unit: remove.serving_unit, servings: remove.servings }}),
            carbs: this.state.carbs - getAttribute('carbs', remove.food, { metadata: { serving_unit: remove.serving_unit, servings: remove.servings }}),
            protein: this.state.protein - getAttribute('protein', remove.food, { metadata: { serving_unit: remove.serving_unit, servings: remove.servings }}),
            fat: this.state.fat - getAttribute('fat', remove.food, { metadata: { serving_unit: remove.serving_unit, servings: remove.servings }}),
        })

        let components = this.state.components.filter((component, index) => i !== index)
        if (components.length === 0) this.setState({ edit: false })
        this.setState({ components })
    }

    handleComponentChange = (i, variable, value) => {
        let components = this.state.components
        let prev = { 
            calories: getAttribute('calories', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }}),
            carbs: getAttribute('carbs', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }}),
            protein: getAttribute('protein', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }}),
            fat: getAttribute('fat', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }})
        }
        
        components[i] = { ...components[i], [variable]: value }
        
        let next = {
            calories: getAttribute('calories', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }}),
            carbs: getAttribute('carbs', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }}),
            protein: getAttribute('protein', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }}),
            fat: getAttribute('fat', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }})
        }
        
        this.setState({ 
            components,
            calories: this.state.calories - prev.calories + next.calories,
            carbs: this.state.carbs - prev.carbs + next.carbs,
            protein: this.state.protein - prev.protein + next.protein,
            fat: this.state.fat - prev.fat + next.fat
        })
    }

    handleSearchFocus = () => {
        this.setState({ q: '', data: [] })
    }

    handleSearchChange = (e) => {
        this.setState({ q: e.target.value })
    }

    handleSearchKeyPress = (e) => {
        if (e.key !== 'Enter') return 
        this.handleSearch(this.state.q)
    }

    handleSearch = (q) => {
        this.setState({ loading: true })
        api.searchFoods({ params: { q, branded: true, common: true, recipes: true, combined: true, limit: 3 }}).then((response) => {
            this.setState({ data: response.data.combined })
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    handleMealSearchOpen = () => {
        this.setState({
            mealSearchOpen: true
        })
    }

    handleMealSearchClose = () => {
        this.setState({
            mealSearchOpen: false
        })
    }

    renderMenuItems = (food) => {
        if (!food) return []
        if (!food.alt_units) return [food.def_unit || 'serving']
        let items = food.alt_units.map((unit) => unit.measure)
        return items
    }

	render() {
        return (
            // Custom food panel
            <Modal direction='up' in={this.props.open} handleClose={() => this.handleClose()} head={`Create custom ${this.state.type}`}>
                <p style={{ margin: '0px', opacity: '.7', marginTop: '-10px', fontSize: '14px' }}> Log go-to meals faster </p>
                <NutritionInfo add trackingTime={this.props.trackingTime} time={new Date()} food={{ calories: this.state.calories, carbs: this.state.carbs, fat: this.state.fat, protein: this.state.protein }} servings={1}/>
                <Selector value={this.state.type} options={[{ label: 'Food', value: 'food' }, { label: 'Meal', value: 'meal' }]} handleChange={(value) => this.setState({ type: value })}/>
                { this.state.type === 'food' ? <CardFloat> 
                    <CardContent style={{ padding: '16px', overflow: 'scroll' }}>
                        <div>
                            <div>
                                <Input 
                                    style={{ marginBottom: '10px', width: '100%' }}
                                    value={this.state.name} 
                                    placeholder="Name"
                                    onChange = {(event) => {
                                    this.handleChange("name", event.target.value)}}/>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <Input 
                                        type="number"
                                        style={{ width: '100%' }}
                                        error={this.state.servings <= 0}
                                        value={this.state.carbs} 
                                        placeholder="Carbs"
                                        suff={'g'}
                                        onChange = {(event) => {
                                        this.handleChange("carbs", event.target.value)}}/>
                                </div>
                                <div style={{ margin: '0px 10px 0px 10px' }}>
                                    <Input 
                                        type="number"
                                        style={{ width: '100%' }}
                                        error={this.state.servings <= 0}
                                        value={this.state.protein} 
                                        placeholder="Protein"
                                        suff={'g'}
                                        onChange = {(event) => {
                                        this.handleChange("protein", event.target.value)}}/>
                                </div>
                                <div>
                                    <Input 
                                        type="number"
                                        style={{ width: '100%' }}
                                        error={this.state.servings <= 0}
                                        value={this.state.fat} 
                                        placeholder="Fat"
                                        suff={'g'}
                                        onChange = {(event) => {
                                        this.handleChange("fat", event.target.value)}}/>
                                </div>
                            </div>
                            <div>
                                <div style={{ width: '100%', display: 'flex' }}> 
                                    <Input 
                                        type="number"
                                        style={{ marginBottom: '10px', width: '100%' }}
                                        error={!this.verifyCalories().bool && this.verifyCalories().res === 'calories'}
                                        value={this.state.calories} 
                                        placeholder="Calories"
                                        suff={'kcal'}
                                        onChange = {(event) => {
                                        this.handleChange("calories", event.target.value)}}/>
                                    <div style={{ width: !this.verifyCalories().bool && this.verifyCalories().res === 'calories' ? '80px' : '0px', transition: 'all 300ms ease', overflow: 'hidden', marginLeft: !this.verifyCalories().bool && this.verifyCalories().res === 'calories' ? '5px' : '0px' }}>
                                        <ModalButton handleClick={this.adjustCalories} label={'Fix'} style={{ marginBottom: '10px', height: '40px' }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </CardFloat> : 
                // Custom meal panel
                <div> 
                    <CardFloat style={{ overflow: 'visible' }}>
                        <CardContent style={{ padding: '16px' }}>
                            <Input 
                                style={{ marginBottom: '10px', width: '100%' }}
                                value={this.state.name} 
                                placeholder={`Ex: ${this.props.auth.user.first_name}'s Weekday Breakfast`}
                                label="Meal Name"
                                onChange = {(event) => {
                                this.handleChange("name", event.target.value)}}/>
                            {/* <SearchBar value={this.state.q} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onKeyPress={this.handleSearchKeyPress} inputStyle={{ marginBottom: '10px' }} placeholder='Add food'/> */}
                            {/* <div>
                                {
                                    this.state.data.map((food, i) => {
                                        return <SearchListing food={food} handleInfoOpen={this.handleComponentAdd}/>
                                    })
                                }
                            </div> */}
                            <div style={{ borderTop: '1px solid rgba(0,0,0,.1)', paddingTop: '5px', marginTop: '15px' }}>
                                {
                                    this.state.components.map((component, i) => {
                                        return (
                                            <div style={{ display: 'flex' }}>
                                                <ComponentListing food={component.food}/>
                                                { !this.state.edit ? <Input 
                                                    type="number"
                                                    style={{ margin: '7px 0px 7px 5px', width: '50%' }}
                                                    error={Number(component.servings) <= 0}
                                                    value={component.servings} 
                                                    placeholder="Servings"
                                                    selector={<ChipSelector value={component.serving_unit} style={{ backgroundColor: 'inherit', border: 'none', boxShadow: '', zIndex: '5' }} handleChange={(value) => this.handleComponentChange(i, 'serving_unit', value)} options={this.renderMenuItems(component.food).map((portion) => {
                                                        return {
                                                            value: portion,
                                                            label: portion,
                                                            variable: 'serving_unit'
                                                        }
                                                    })}/>}
                                                    onChange = {(event) => { this.handleComponentChange(i, "servings", event.target.value) }}/> : <ModalButton handleClick={() => this.handleComponentDelete(i)} label={'Remove'} style={{ backgroundColor: 'red', width: '50%', margin: '7px 0px 7px 5px' }}/> }
                                            </div>
                                        )
                                    })
                                }
                                <p style={{ color: Color.PRIMARY, textAlign: 'center', margin: '0px', paddingTop: '10px' }} onClick={this.handleMealSearchOpen}>Add Meal Item</p>
                            </div>
                            { this.state.loadingComponent ? <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px 20px 0px' }}> <CircularProgress size={24}/> </div> : null }
                            { this.state.components.length ? <EditIcon style={{ position: 'absolute', bottom: '18px', right: '25px', opacity: '.7', fontSize: '28px', color: this.state.edit ? 'white' : 'black', backgroundColor: this.state.edit ? Color.PRIMARY : '', borderRadius: '50%', padding: '5px', transition: 'all 300ms ease' }} onClick={() => this.setState({ edit: !this.state.edit })}/> : null }
                        </CardContent>
                    </CardFloat>
                </div> }
                <ModalButton success={this.state.success} icon={<AddCircleRoundedIcon/>} handleClick={this.handleCreate} disabled={!this.confirmSubmit()} loading={this.props.dash.customLoading} label={`Create ${this.state.name || 'food'}`}/>
                <CustomMealSearchModal open={this.state.mealSearchOpen} handleInfoOpen={this.handleComponentAdd} handleClose={this.handleMealSearchClose}/>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        createCustomFood }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomFoodAddModal)

