import { clearDashboard, loadPlanNutrition, loadPlanFitness, getHistory, getSessions } from '../actions/dashboardActions'
import { timeToLocal, localStart, localEnd, getAuthRequest } from '../util'
import { setSnackbar } from './snackbarActions'
import { authActionTypes, dashActionTypes } from './types'
import api from '../api'

export const changePassword = (id, payload) => {
    return (async (dispatch) => {
        let req = getAuthRequest()
        req.params = { id }

        dispatch({ type: authActionTypes.CHANGE_PASSWORD_LOADING })
        return api.changePassword({ password: payload.password, newPassword: payload.new_password}, req).then((response) => {
            dispatch({ type: authActionTypes.CHANGE_PASSWORD_SUCCESS, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: authActionTypes.CHANGE_PASSWORD_ERROR })
            throw error
        })
    })
}

export const resetToken = (resetToken) => {
    return ((dispatch) => {
        dispatch({ type: 'RESET_TOKEN', payload: resetToken })
    })
}

export const clearResetToken = () => {
    return ((dispatch) => {
        dispatch({ type: 'CLEAR_RESET_TOKEN' })
    })
}

export const updateSubscription = (subscription_id, payload) => {
    let req = getAuthRequest()
    req.params = { subscription_id }
    
    return ((dispatch) => {
        dispatch({ type: authActionTypes.SUBSCRIPTION_LOADING })
        api.stripeUpdateSubscription(payload, req).then((response) => {
            dispatch({ type: authActionTypes.SUBSCRIPTION_LOAD_SUCCESS, payload: response.data })
            dispatch(setSnackbar({ type: 'success', msg: 'Subscription Updated'}))
        }).catch((error) => {
            console.log(error)
            dispatch({ type: authActionTypes.SUBSCRIPTION_LOAD_ERROR })
        })
    })
}

export const createCancellation = (cancellation) => {
    let req = getAuthRequest()
    return ((dispatch) => {
        api.createCancellation(cancellation, req).then((response) => {
            dispatch(setSnackbar({ type: 'success', msg: 'Feedback Sent'}))
        }).catch((error) => {
            console.log(error)
        })
    })
}

export const refreshToken = (refresh, redirect) => {
    return ((dispatch) => {
        api.refreshToken({}).then((response) => {
            if (refresh) window.location.reload(false)
            if (redirect) window.location.href = redirect
            dispatch({ type: "USER_DATA_LOADED", payload: response.data.user })
        })
    })
}

export const updateProfile = (id, payload) => {
    let req = getAuthRequest()
    req.params = { id }

    return (async (dispatch) => {
        let update = { ...payload.profile, settings: payload.settings, preserve: payload.preserve || [] }

        dispatch({ type: authActionTypes.UPDATE_PROFILE_LOADING })
        return api.updateProfile(update, req).then((response) => {
            dispatch(setSnackbar({ type: "success", msg: "Profile Updated"}))
            dispatch({ type: authActionTypes.UPDATE_PROFILE_SUCCESS, payload: response.data })

            if (payload.settings && (payload.settings.diet || payload.settings.allergies || payload.settings.cooking_level || payload.settings.equipment || payload.settings.fitness_level)) {
                let date = new Date()
                dispatch(generatePlans(id, { offset: date.getTimezoneOffset() }))
            }

            return response.data
        }).catch((error) => {
            dispatch({ type: authActionTypes.UPDATE_PROFILE_ERROR })
            throw error
        })
    })
}

export const verifyUser = (user_id, otp) => {
    return (async (dispatch) => {
        dispatch({ type: authActionTypes.UPDATE_PROFILE_LOADING })
        return api.otp({ user_id, otp }).then((response) => {
            dispatch({ type: authActionTypes.LOGIN_SUCCESS, payload: { user: response.data }})
            return response.data
        }).catch((error) => {
            dispatch({ type: authActionTypes.UPDATE_PROFILE_ERROR })
            throw error
        })
    })
}

export const activateUser = (user_id) => {
    return (async (dispatch) => {
        dispatch({ type: authActionTypes.UPDATE_PROFILE_LOADING })
        return api.activateUser(user_id).then((response) => {
            dispatch({ type: authActionTypes.UPDATE_PROFILE_SUCCESS, payload: response.data.user })
            dispatch({ type: dashActionTypes.HISTORY_ADD_SUCCESS, payload: response.data.weighin })
        }).catch((error) => {
            dispatch({ type: authActionTypes.UPDATE_PROFILE_ERROR })
        })
    })
}

export const updateAvatar = (payload) => {
    let req = getAuthRequest()
    return ((dispatch) => {
        dispatch({ type: authActionTypes.UPDATE_PROFILE_LOADING })
        api.updateAvatar(payload, req).then((response) => {
            dispatch(setSnackbar({ type: "success", msg: "Avatar Updated"}))
            dispatch({ type: authActionTypes.UPDATE_PROFILE_SUCCESS, payload: response.data })
        }).catch((error) => {
            dispatch({ type: authActionTypes.UPDATE_PROFILE_ERROR })
        })
    })
}

export const generatePlans = (id, payload) => {
    return ((dispatch) => {
        let req = getAuthRequest()
        req.params = { id }
        //dispatch(createNotification(planNotification))
        dispatch({ type: "GENERATE_PLAN_LOADING" })
        api.generatePlans(payload, req).then((response) => {
            let plan = {}
            for (let i = 0; i < response.data.curr.days.length; i++) {
                if (timeToLocal(Date.now()) === response.data.curr.days[i].date) {
                    plan = response.data.curr.days[i]
                    break
                }
            }

            dispatch({ type: "GENERATE_PLAN_SUCCESS" })
            dispatch({ type: "USER_DATA_LOADED", payload: response.data })
            dispatch({ type: "LOAD_PLAN", payload: response.data.curr })
            dispatch(loadPlanNutrition([plan]))
            dispatch(loadPlanFitness([plan]))
            //dispatch(clearNotification(planNotification.msg))
            dispatch(setSnackbar({ msg: "Plans Generated", type: "success" }))
        }).catch((error) => {
            dispatch({ type: "GENERATE_PLAN_ERROR" })
            //dispatch(clearNotification(planNotification))
        })
    })
}

export const mountUser = (payload) => {
    return ((dispatch) => {
        dispatch({
            type: "USER_DATA_LOADED",
            payload: payload
        })
    })
}

export const createRecalibration = (id, payload, settings) => {
    let req = getAuthRequest()
    req.params = { id }
    console.log(settings)

    return ((dispatch) => {
        dispatch({ type: "RECALIBRATION_LOADING"})
        return api.userRecalibrate(payload, req).then((response) => {
            dispatch({ type: authActionTypes.UPDATE_PROFILE_SUCCESS, payload: response.data.user })
            dispatch({ type: dashActionTypes.HISTORY_ADD_SUCCESS, payload: response.data.weighin })
            return response.data
        }).catch((error) => {
            console.log(error)
            dispatch({ type: "RECALIBRATION_ERROR" })
            throw error
        })
    })
}

export const loginUser = (payload) => {
    return (async (dispatch) => {
        dispatch({ type: authActionTypes.LOGIN_LOADING })
        return api.authUser(payload).then((response) => {
            localStorage.setItem('accessToken', response.data.accessToken)
            dispatch({ type: authActionTypes.LOGIN_SUCCESS, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: authActionTypes.LOGIN_ERROR })
            throw error
        })
    })
}

export const loadUser = (payload) => {
    let req = getAuthRequest()

    return ((dispatch) => {
        dispatch(({ type: authActionTypes.TOKEN_LOADING }))      
        api.getUser(req).then((response) => {
            dispatch({ type: authActionTypes.TOKEN_LOAD_SUCCESS, payload: response.data })
        }).catch((error) => {
            dispatch({ type: authActionTypes.TOKEN_LOAD_ERROR })
        })
    })
}

export const signout = () => {
    return ((dispatch) => {
        const req = getAuthRequest()
        dispatch({ type: "USER_SIGNOUT_LOADING" })
        api.revokeToken({}, req).then((response) => {
            dispatch(clearDashboard())
            localStorage.removeItem('accessToken')
            dispatch({ type: authActionTypes.USER_SIGNOUT_SUCCESS })
        }).catch((error) => {
            console.log(error)
        })
    })
}

export const getUser = (id) => {
    let req = getAuthRequest()
    return ((dispatch) => {
        req.params = { _id: id }
        api.getUserData(req).then((response) => {
            if (!response.data.length) throw Error('Failed to load user')
            response.data = response.data[response.data.length - 1]

            let plan = {}
            for (let i = 0; i < response.data.curr.days.length; i++) {
                if (timeToLocal(Date.now()) === response.data.curr.days[i].date) {
                    plan = response.data.curr.days[i]
                    break
                }
            }

            dispatch({ type: "USER_DATA_LOADED", payload: response.data })
            dispatch({ type: "LOAD_PLAN", payload: response.data.curr })
            dispatch(getHistory(response.data._id))

            dispatch(getSessions({ time : { $gte: localStart(), $lt: localEnd() }, user_id: response.data._id, expand: ['target'] }))
            dispatch(loadPlanNutrition([plan]))
            dispatch(loadPlanFitness([plan]))
            //dispatch(getArticles({ type: 'article' }, true, 5))
        }).catch((error) => {
            console.log(error)
        })
    })
}

export const createUser = (payload) => {
    return ((dispatch) => {
        dispatch({ type: authActionTypes.USER_CREATE_LOADING })
        return api.insertUser(payload).then((response) => {
            dispatch({ type: authActionTypes.USER_CREATE_SUCCESS, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: authActionTypes.USER_CREATE_ERROR })
            throw error
        })
    })
}