import { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CardFloat, Input, ModalButton } from './'
import { CardContent, Fade, Slide } from '@material-ui/core'
import { verifyUser } from '../actions/authActions'
import { Color } from '../util'
import { MdOutlineShortcut } from "react-icons/md"

const OTPCard = (props) => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [otp, setOTP] = useState('')

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') handleOTP(event)
    }

    const handleOTP = (event) => {
        setLoading(true)
        props.verifyUser(props.user_id, otp).then((response) => {
            setLoading(false)
            setSuccess(true)
            setTimeout(props.handleRedirect, 400)
        }).catch((error) => {
            setLoading(false)
        })
    }

    return (
        <div>
            <div style={{ position: 'fixed', left: '50%', top: '17px', transform: 'translateX(-50%)', zIndex: '1010'}}>
                <Slide direction='down' in={true}>
                    <div style={{ backgroundColor: Color.PRIMARY, borderRadius: '25px', padding: '7px 12px 7px 12px',  display: 'flex', alignItems: 'center', boxShadow: '0 6px 20px 10px rgba(0, 0, 0, 0.1)' }}>
                        <p style={{ fontSize: '14px', color: 'white', marginBottom: '0px', whiteSpace: 'nowrap'}}> Code sent to <b>{props.email}</b></p>
                    </div>
                </Slide>
            </div>
            <Fade in={true} key={'verify'}>
                <CardFloat style={{ marginBottom: '15px'}}>
                    <CardContent>
                        <Input autoFocus value={otp} onKeyPress={handleKeyPress} onChange={(e) => setOTP(e.target.value)} placeholder={'One Time Password'} style={{ width: '100%' }}/>
                        <ModalButton success={success} icon={<MdOutlineShortcut/>} loading={loading} handleClick={handleOTP} label={'Verify'} style={{ position: 'relative', margin: '20px 0px 0px 0px', width: '100%', bottom: '0px' }}/>
                    </CardContent>
                </CardFloat>
            </Fade>
        </div>
    )
} 

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        verifyUser
     }, dispatch)
}

export default connect(null, mapDispatchToProps)(OTPCard)
