import React from 'react'
import { connect } from 'react-redux'
import { Modal, WorkoutCard, ModalButton } from '.'
import { getWorkoutType } from '../util'

function FitnessPreviewModal(props) {
    const getCompletionPercentage = (workouts) => {
        if (!workouts) return 0

        let count = 0
        for (let i = 0; i < workouts.length; i++) {
            let workout = workouts[i]
            if (getWorkoutVerified(workout, props.trackingTime)) count += 1
        }

        return count / workouts.length
    }

    const getWorkoutVerified = (workout, trackingTime) => {
        let sessions = props.dash.sessions.filter((session) => session.type === 'workout')
        for (let index in sessions) {
            let session = sessions[index], temp = new Date(session.time), curr = (trackingTime ? new Date(trackingTime) : new Date())
            if (temp.getDate() === curr.getDate() && session.target_id === workout._id) return true
        }

        return false
    }

    return (
        <Modal nobackdrop direction='left' style={{ height: '100%', borderRadius: 'none', boxShadow: 'none' }} in={props.open} handleClose={props.handleClose} head={`${getWorkoutType(props.workouts)} Workout`}>
            <div>
                {
                    props.workouts ? props.workouts.map((workout) => {
                        return (
                            <WorkoutCard verifyWorkout trackingTime={props.trackingTime} workout={workout.workout} sets={workout.session} handleInfoOpen={props.handleInfoOpen}/>
                        )
                    }) : null
                }
            </div>
            <ModalButton handleClick={(event) => props.handleAddOpen({ event, workouts: props.workouts })} label={`${getCompletionPercentage(props.workouts ? props.workouts.map((workout) => workout.workout) : null) ? 'Edit' : 'Start'} Workout`}/>
        </Modal>
    )
}

function mapStateToProps(state) {
	return {
		dash: state.dash,
	}
}

export default connect(mapStateToProps)(FitnessPreviewModal)