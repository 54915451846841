//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateSession, deleteSession } from '../actions/dashboardActions'
//Components and Pages
import { CircularProgress, CardContent } from '@material-ui/core';
import { Modal, CardFloat, ModalButton, ChipSelector, Input } from './'
import { Color, searchFoodPortions } from '../util'
//Images
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit'

const initialState = {
    targetTime: new Date(),
    servings: 1,
    servingType: null,
    time: '',
    success: false,
    error: false,
}

class TrackingUpdateModalNutrition extends Component {
    constructor (props) {
        super(props)
        this.state = initialState
    }

    componentDidUpdate(prevProps) {
        if (this.props.session && this.props.session !== prevProps.session) {
            this.setState({ targetTime: new Date(this.props.session.time), servings: this.props.session.metadata.servings, servingType: this.props.session.metadata.serving_unit, time: this.props.session.metadata.meal } )
        }

        if (!prevProps.open && this.props.open) {
            this.setState({ targetTime: new Date(this.props.session.time), servings: this.props.session.metadata.servings, servingType: this.props.session.metadata.serving_unit, time: this.props.session.metadata.meal } )
        }
    }

    handleChange = (question, answer) => {
        switch (question) {
            case ('servingType') : {
                return this.setState({
                    servingType: answer,
                    servings: Math.round((
                        this.state.servings * (searchFoodPortions(this.state.servingType, this.props.session.target) / searchFoodPortions(answer, this.props.session.target)) || 1
                    ))
                })
            }

            default: this.setState( { [question]: answer })
        }
    }
    
    handleClose = () => {
        this.setState(initialState)
        this.props.handleClose()
    }

    toTitleCase = (str) => {
        str = str.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
            if (str !== 'and') str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
    };


    handleUpdate = () => {
        let targetTime = new Date(this.props.session.time)
        let temp = new Date(this.state.targetTime)

        if (this.state.targetTime.getDate() !== targetTime) {
            temp.setHours(targetTime.getHours(), targetTime.getMinutes(), targetTime.getSeconds(), targetTime.getMilliseconds())
            targetTime = temp            
        }

        this.props.updateSession(this.props.session._id, { 
                time: targetTime.getTime(), 
                metadata: { 
                    ...this.props.session.metadata,
                    meal: this.state.time, 
                    servings: Number(this.state.servings),
                    serving_unit: this.state.servingType
                }
            }, this.props.session.target).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.handleError()
        })
    }

    handleDelete = () => {
        this.props.deleteSession(this.props.session._id).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.handleError()
        })
    }

    handleSuccess = () => {
        this.setState({ success: true })
        setTimeout(this.handleClose, 400)
    }

    handleDate = () => {
        if (this.props.serving && this.state.changed === false) {
            return new Date(this.props.serving.time)
        } else {
            return this.state.targetTime
        }
    }

    considerChange = () => {
        let targetTime = new Date(this.props.session.time)
        if ((this.state.servings !== this.props.session.metadata.servings && this.state.servings > 0) || targetTime.getDate() !== this.state.targetTime.getDate() || this.props.session.metadata.meal !== this.state.time || this.props.session.metadata.serving_unit !== this.state.servingType) { 
            return true
        } else {
            return false
        }
    }

    renderMenuItems = (food) => {
        if (!food) return []
        if (!food.alt_units) return [food.def_unit || 'serving']
        let items = food.alt_units.map((unit) => unit.measure)
        return items
    }

	render() {
        let options = [{ variable: 'time', value: 'breakfast', label: 'Breakfast'}, { variable: 'time', value: 'lunch', label: 'Lunch'}, { variable: 'time', value: 'dinner', label: 'Dinner'}, { variable: 'time', value: 'snack', label: 'Snack'}]
        let food = this.props.session?.target

        return (
            <Modal direction='up' in={this.props.open} handleClose={this.handleClose} head={'Update ' + (food ? (food.name ? this.toTitleCase(food.name) : (food.shortName ? this.toTitleCase(food.shortName) : (food.description ? this.toTitleCase(food.description) : null))): "food")}>
                <CardFloat>
                    <CardContent style={{ padding: '16px' }}>
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center' }}> 
                                <Input 
                                    type="number"
                                    style={{ width: '100%', marginBottom: '5px' }}
                                    error={Number(this.state.servings) <= 0}
                                    value={this.state.servings} 
                                    placeholder="Servings"
                                    selector={<ChipSelector value={this.state.servingType} style={{ backgroundColor: 'inherit', border: 'none', boxShadow: '' }} handleChange={(value) => this.handleChange('servingType', value)} options={this.renderMenuItems(food).map((portion) => {
                                        return {
                                            value: portion,
                                            label: portion,
                                            variable: 'servingType'
                                        }
                                    })}/>}
                                    onChange = {(event) => {
                                    this.handleChange("servings", event.target.value)}}/>
                                    <ChipSelector blankLabel={'Select Time'} value={this.state.time} handleChange={(value) => this.handleChange('time', value)} options={options} style={{ padding: '10px', borderRadius: '10px', height: '100%', marginBottom: '5px', marginLeft: '10px' }} />
                            </div>
                        </div>
                    </CardContent>
                </CardFloat>
                <div style={{ display: 'flex' }}>
                    <ModalButton icon={<DeleteIcon style={{ fontSize: '20px' }}/>} success={this.state.success} label={`Remove`} handleClick={() => this.handleDelete(this.props.session, food)} disabled={this.props.dash.sessionLoading} loading={this.props.dash.sessionLoading} style={{ width: '50%', backgroundColor: 'red' }}/>
                    <ModalButton icon={<EditIcon style={{ fontSize: '20px' }}/>} success={this.state.success} label={`Update`} handleClick={this.handleUpdate} disabled={this.props.dash.sessionLoading || !(this.props.session ? this.considerChange() : false)} loading={this.props.dash.sessionLoading} style={{ marginLeft: '10px', width: '50%' }}/>      
                </div>
            </Modal>
        )
	}
}

function mapStateToProps(state) {
	return {
		dash: state.dash,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        updateSession,
        deleteSession }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingUpdateModalNutrition)

