import React, { Component, createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { getSessions } from '../actions/dashboardActions'
import { Color, days, months, getAttribute, localStart, localEnd, timeToLocal } from '../util'
import { Slide, Backdrop } from '@material-ui/core';
import { SpeedDialIcon, SpeedDial, SpeedDialAction } from '@material-ui/lab'
import { ActivityCard, FoodCard, Modal, NutritionInfo, PageCard, PageLoading, TrackingUpdateModalActivities, CustomActivityAddModal, TrackingAddModalActivities, ActivityAddModal, TrackingUpdateModalNutrition, WorkoutCard, WeighinInfoModal } from '../components';
import RestaurantIcon from '@material-ui/icons/RestaurantRounded';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenterRounded';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRunRounded';
import PersonIcon from '@material-ui/icons/Person';

const Toggle = styled.div`
  border-radius: 50%;
  padding: 4px;
  border: 1px solid;
  display: flex;
  border-color: ${ (props) => props.color ? props.color : Color.PRIMARY };
  margin-right: 15px;
  background-color: ${ (props) => props.complete ? (props.color ? props.color : Color.PRIMARY) : 'white' };
  color: ${ (props) => props.complete ? 'white' : (props.color ? props.color : Color.PRIMARY) };
`

const DateHead = styled.p`
    margin-bottom: 0px;
    font-size: 14px;
    margin-left: auto;
    color: ${ (props) => props.today ? Color.PRIMARY : 'rgba(0,0,0,0.5)' };
`

const Day = styled.h6`
    margin-bottom: 0px;
    color: ${ (props) => props.today ? Color.PRIMARY : 'black' };
`
const DateBorder = styled.div`
    height: 1px;
    border-top: ${(props) => props.today ? '1px' : '1px' } solid;
    margin: 10px 0px 15px 0px;
    opacity: ${(props) => props.today ? '.7' : '.2'};
    border-color: ${ (props) => props.today ? Color.PRIMARY : 'black' };
`

const DayWrapper = styled.div`
    margin-top: ${ (props) => props.first ? '0' : '10px' };
`

class Calendar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            days: this.handleDays(7),
            loadingTop: false,
            loadingBottom: false,
            scrollPosition: null,
            sessionPreviewOpen: false,
            sessionPreviewType: '',
            sessionPreviewSessions: [],
            sessionPreviewDate: null,
            sessionPreviewHeader: '',

            nutritionUpdateOpen: false,
            nutritionUpdateSession: null,
            fitnessUpdateOpen: false,
            fitnessUpdateSession: null,
            activityUpdateOpen: false,
            activityUpdateSession: null,

            activityOpen: false,
            activityAddOpen: false,
            activityAdd: null,
            customActivityAddOpen: false,

            weighinPreviewOpen: false,
            weighinPreviewInfo: null
        }
    }

    handleScrollTop = (node) => {
        if (this.props.dash.sessionsLoading) return
        if (this.topObserver.current) this.topObserver.current.disconnect()
        this.topObserver.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                let first = new Date(this.state.days[0])
                first.setDate(first.getDate() - 1)
                let days = this.handleDays(7, first.getTime())
                this.getTargetSessions(days[0], days[days.length - 1])
                let prevScroll = window.pageYOffset, prevHeight = window.document.body.offsetHeight
                this.setState({ days: [...days, ...this.state.days] }, () => this.retainScroll(prevScroll, prevHeight))
            }
        })
        if (node) this.topObserver.current.observe(node)
    }

    handleScrollBottom = (node) => {
        if (this.props.dash.sessionsLoading) return
        if (this.botObserver.current) this.botObserver.current.disconnect()
        this.botObserver.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                let first = new Date(this.state.days[this.state.days.length - 1])
                first.setDate(first.getDate() + 1)
                let days = this.handleDays(7, first.getTime())
                this.getTargetSessions(days[0], days[days.length - 1])
                this.setState({ days: [...this.state.days, ...days] })
            }
        })
        if (node) this.botObserver.current.observe(node)
    }

    getCompletionPercentage = (workouts, trackingTime) => {
        if (!workouts) return 0

        let count = 0
        for (let i = 0; i < workouts.length; i++) {
            let workout = workouts[i]
            if (this.getWorkoutVerified(workout, trackingTime)) count += 1
        }

        return count / workouts.length
    }

    getWorkoutVerified = (workout, trackingTime) => {
        let sessions = this.props.dash.sessions.filter((session) => session.type === 'workout')
        for (let index in sessions) {
            let session = sessions[index], temp = new Date(session.time), curr = (trackingTime ? new Date(trackingTime) : new Date())
            if (temp.getDate() === curr.getDate() && session.target_id === workout.workout_id) return true
        }

        return false
    }

    retainScroll = (prevScroll, prevHeight) => {
        let height = window.document.body.offsetHeight
        window.scrollTo(0, prevScroll + height - prevHeight)
    }

    topObserver = createRef(null)
    botObserver = createRef(null)
    topDay = this.handleScrollTop.bind(this)
    botDay = this.handleScrollBottom.bind(this)

    componentDidUpdate(prevProps) {
        if (!prevProps.auth.user && this.props.auth.user) {
            let start = localStart(this.state.days[0]), end = localEnd(this.state.days[this.state.days.length - 1])
            this.getTargetSessions(start, end)
        }
    }

    componentDidMount() {
        if (this.props.auth.user && !this.props.sessionsLoading) {
            let start = localStart(this.state.days[0]), end = localEnd(this.state.days[this.state.days.length - 1])
            this.getTargetSessions(start, end)
        }
    }

    getTargetSessions = (date1, date2) => {
        if (!this.props.auth.user) return
        let start = localStart(date1), end = localEnd(date2)
        if (!this.verifyBuffer(start, end)) return
        let req = { time: { $gte: start, $lt: end }, user_id: this.props.auth.user._id, expand: ['target'] }
        this.props.getSessions(req)
    }

    verifyBuffer = (start, end) => {
        return start > this.props.dash.sessionBuffer.max || start < this.props.dash.sessionBuffer.min || end < this.props.dash.sessionBuffer.min || end > this.props.dash.sessionBuffer.max
    }

    handleDays = (num, initial) => {
        let date = (initial ? new Date(initial) : new Date())
        let day = date.getDay(), start = new Date(date), days = []
        start.setDate(start.getDate() - day)
        start.setHours(0, 0, 0, 0)
        for (let i = 0; i < num; i++) {
            days.push(start.getTime())
            start.setDate(start.getDate() + 1)
        }

        return days
    }

    handleActivityAddOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ activityAddOpen: true, addActivity: args.activity, activityDuration: args.duration })
    }

    handleActivityAddClose = () => {
        this.setState({ activityAddOpen: false, activityUpdateOpen: false, infoOpen: false })
    }

    handleActivityOpen = () => {
        this.setState({ activityOpen: true, dial: false })
    }

    handleActivityClose = () => {
        console.log(this.state.dial)
        this.setState({ activityOpen: false, dial: false })
    }

    handleHistoryType = (type) => {
        switch(type) {
            case ('recalibration') :
                return 'Recalibration'
            case ('initial_weigh_in') :
                return 'Initial Weigh-in'
            default :
                return 'Weigh-in'
        }
    }

    handleNutritionUpdateOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ nutritionUpdateOpen: true, nutritionUpdateSession: args.session })
    }

    handleNutritionUpdateClose = (event) => {
        this.setState({ nutritionUpdateOpen: false, nutritionUpdateSession: null, sessionPreviewOpen: false })
    }

    handleFitnessUpdateOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ fitnessUpdateOpen: true, fitnessUpdateSession: args.session })
    }

    handleFitnessUpdateClose = (event) => {
        this.setState({ fitnessUpdateOpen: false, fitnessUpdateSession: null, sessionPreviewOpen: false })
    }

    handleActivityUpdateOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ activityUpdateOpen: true, activityUpdateSession: args.session })
    }

    handleActivityUpdateClose = (event) => {
        this.setState({ activityUpdateOpen: false, activityUpdateSession: null, sessionPreviewOpen: false })
    }

    handleWeighinInfoOpen = (infoSession) => {
        this.setState({ weighinPreviewOpen: true, weighinPreviewInfo: infoSession })
    }

    handleWeighinInfoClose = (event) => {
        this.setState({ weighinPreviewOpen: false })
    }

    isEqual = (date1, date2) => {
        date1 = new Date(date1)
        date2 = new Date(date2)
        return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getYear() === date2.getYear()
    }

    getMetaData = (activeStat, percent, minDate, maxDate) => {
        let calories = 0, sessions = this.props.dash.sessions.filter((session) => session.type === 'food' && session.time > minDate && session.time < maxDate)
        
        for (let index in sessions) {
            let session = sessions[index], date = new Date(session.time), food = session.target
            calories += Math.round(getAttribute(activeStat, food, session))
        }

        if (percent) return calories / (this.props.auth.user.settings.macros[activeStat] || this.props.sampleMacros[activeStat]) * 100
        return calories
    }

    handlePreviewSessions = (event, type, sessions, date, head) => {
        this.setState({ sessionPreviewOpen: true, sessionPreviewType: type, sessionPreviewSessions: sessions, sessionPreviewDate: date, sessionPreviewHeader: head })
    }

    findPlan = (time) => {
        for (let i = 0; i < this.props.dash.plan.length; i++) {
            if (this.props.dash.plan[i].date === time) return this.props.dash.plan[i]
        }

        return null
    }

    renderSessions = (date) => {
        let sessions = this.props.dash.sessions.filter((session) => this.isEqual(date, session.time)), entries = []
        let history = this.props.dash.history.filter((weighin) => this.isEqual(date, weighin.time))
        let plan = this.findPlan(timeToLocal(date))
        let workouts = sessions.filter((session) => session.type === 'workout')
        let meals = sessions.filter((session) => session.type === 'food')
        let activities = sessions.filter((session) => session.type === 'activity')

        if (meals.length) {
            let calories = this.getMetaData('calories', false, localStart(date), localEnd(date))
            let carbs = this.getMetaData('carbs', false, localStart(date), localEnd(date))
            let protein = this.getMetaData('protein', false, localStart(date), localEnd(date))
            let fat = this.getMetaData('fat', false, localStart(date), localEnd(date))

            entries.push(
                <div style={{ display: 'flex', alignItems: 'center'}} onClick={(event) => this.props.history.push(`/dashboard/nutrition?t=${date.getTime()}`)}>
                    <div>
                        <Toggle complete={true}>
                            <RestaurantIcon style={{ fontSize: '16px', color: 'white'}}/>
                        </Toggle>
                    </div>
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px'}}>
                            <h6 style={{ fontSize: '14px', marginBottom: '0px'}}> {`${meals.length} Item${meals.length > 1 ? 's' : ''}`}  </h6>
                        </div>
                        <p style={{ fontSize: '12px', opacity: '.7', marginBottom: '0px'}}> {`${calories} calories, ${carbs}g carbs, ${protein}g protein, ${fat}g fat`} </p>
                    </div>
                </div>
            )
        }

        /*if (workouts.length) {
            let subline = ''
            workouts.map((workout, i) => {
                if (workout.target) {
                    if (i < workouts.length - 1) subline += `${workout.target.name}, `
                    else subline += `${workout.target.name}`
                }

                return null
            })

            entries.push(
                <div style={{ display: 'flex', alignItems: 'center'}} onClick={(event) => this.handlePreviewSessions(event, 'workout', workouts, date, `${workouts.length} Exercise${workouts.length > 1 ? 's' : ''}`)}>
                    <div>
                        <Toggle complete={true} color={Color.PROTEIN}>
                            <FitnessCenterIcon style={{ fontSize: '16px', color: 'white'}}/>
                        </Toggle>
                    </div>
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px'}}>
                            <h6 style={{ fontSize: '14px', marginBottom: '0px'}}> {`${workouts.length} Exercise${workouts.length > 1 ? 's' : ''}`} </h6>
                        </div>
                        <p style={{ fontSize: '12px', opacity: '.7', marginBottom: '0px'}}> {subline} </p>
                    </div>
                </div>
            )
        }*/

        /* if (activities.length) {
            let duration = 0, calories = 0, steps = 0
            
            activities.map((activity) => {
                duration += activity.metadata.duration
                calories += activity.metadata.calories
                steps += activity.metadata.steps
            })

            entries.push(
                <div style={{ display: 'flex', alignItems: 'center'}} onClick={(event) => this.handlePreviewSessions(event, 'activity', activities, date, `${activities.length} Activit${activities.length > 1 ? 'ies' : 'y'}`)}>
                    <div>
                        <Toggle complete={true} color={Color.FAT}>
                            <DirectionsRunIcon style={{ fontSize: '16px', color: 'white'}}/>
                        </Toggle>
                    </div>
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
                            <h6 style={{ fontSize: '14px', marginBottom: '0px'}}> {`${activities.length} Activit${activities.length > 1 ? 'ies' : 'y'}`} </h6>
                        </div>
                        <p style={{ fontSize: '12px', opacity: '.7', marginBottom: '0px'}}> {`${Math.round(duration / 60)} minutes, ${calories} calories, ${steps} steps`} </p>
                    </div>
                </div>
            )
        } */

        if (history.length) {
            history.map((weighin) => {
                entries.push(
                    <div style={{ display: 'flex', alignItems: 'center'}} onClick={(event) => this.handleWeighinInfoOpen(weighin)}>
                        <div>
                            <Toggle complete={true} color={Color.CARBS}>
                                <PersonIcon style={{ fontSize: '16px' }}/>
                            </Toggle>
                        </div>
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px'}}>
                                <h6 style={{ fontSize: '14px', marginBottom: '0px'}}> {this.handleHistoryType(weighin.type)} </h6>
                            </div>
                            <p style={{ fontSize: '12px', opacity: '.7', marginBottom: '0px'}}> {`${weighin.weight} lbs, ${Math.round(weighin.body_fat * 100)}% body fat, ${weighin.waist_size}" waist`} </p>
                        </div>
                    </div>
                )

                return null
            })
        }

        /* if (plan && plan.fitness.length) {
            entries.push(
                <div style={{ display: 'flex', alignItems: 'center'}} onClick={() => this.props.history.push(`/dashboard/fitness?trackingTime=${date.getTime()}`)}>
                    <div>
                        <Toggle complete={this.getCompletionPercentage(plan.fitness, date)} color={Color.PROTEIN}>
                            <FitnessCenterIcon style={{ fontSize: '16px' }}/>
                        </Toggle>                        
                    </div>
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px'}}>
                            <h6 style={{ fontSize: '14px', marginBottom: '0px'}}> Workout {this.getCompletionPercentage(plan.fitness, date) ? 'Completed' : 'Scheduled' } </h6>
                        </div>
                        <p style={{ fontSize: '12px', opacity: '.7', marginBottom: '0px'}}> {plan.fitness.length} Exercises </p>
                    </div>
                </div>
            )
        } */

        /* if (plan && plan.activities.length) {
            let duration = 0
            for (let i = 0; i < plan.activities.length; i++) {
                duration += plan.activities[i].duration
            }

            entries.push(
                <div style={{ display: 'flex', alignItems: 'center'}} onClick={() => this.props.history.push(`/dashboard/fitness?trackingTime=${date.getTime()}`)}>
                    <div>
                        <Toggle complete={false} color={Color.FAT}>
                            <DirectionsRunIcon style={{ fontSize: '16px' }}/>
                        </Toggle>    
                    </div>                
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px'}}>
                            <h6 style={{ fontSize: '14px', marginBottom: '0px'}}> Activity Scheduled </h6>
                        </div>
                        <p style={{ fontSize: '12px', opacity: '.7', marginBottom: '0px'}}> {duration} minutes </p>
                    </div>
                </div>
            )
        } */

        return (
            entries.length ? <div>
                { entries.map((entry, i) => {
                    return (
                        <div key={i} style={{ cursor: 'pointer'}}>
                            { entry } 
                            { i < entries.length - 1 ? <div style={{ height: '1px', borderTop: '1px solid', margin: '15px 20px 15px 20px', opacity: '.1' }}/> : null }
                        </div>
                    )
                }) }        
            </div> : null
        )
    }

    renderButtons = () => {
        let actions = [/*{
            name: 'Search',
            icon: <SearchIcon/>,
            tooltipTitle: 'Search',
            onClick: () => this.setState({ search: true })
        }*/, {
            name: 'Activity',
            icon: <DirectionsRunIcon/>,
            tooltipTitle: 'Activities',
            onClick: this.handleActivityOpen
        }, /*{
            name: 'Create',
            icon: <NoteAddIcon/>,
            tooltipTitle: 'Create',
            onClick: () => this.setState({ customOpen: true })
        }, {
            name: 'Favorites',
            icon: <ThumbUp/>,
            tooltipTitle: 'Favorites',
            onClick: this.handleFavoritesOpen
        }, {
            name: 'Exclusions',
            icon: <ThumbDown/>,
            tooltipTitle: 'Exclusions',
            onClick: this.handleExclusionsOpen
        }*/]

        return (
            <div>
                { 
                    <SpeedDial FabProps={{ style: { outline: 'none', boxShadow: '0 6px 20px 5px rgba(0, 0, 0, 0.15)'} }} ariaLabel="SpeedDial tooltip example" icon={<SpeedDialIcon style={{ outline: 'none'}}/>} color="primary" style={{ '*:focus' : '{ outline: none }', outline: 'none', margin: '10px', position: 'fixed', bottom:'70px', right:'0', zIndex: '50' }} onOpen={() => this.setState({ dial: true })} onClose={() => this.setState({ dial: false })} open={this.state.dial}>
                        { actions.map((action) => {
                            return (<SpeedDialAction
                                FabProps={{ style: { outline: 'none'} }}
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                style={{ outline: 'none' }}
                                tooltipOpen
                                onClick={action.onClick}
                            />)
                        })}
                    </SpeedDial>
                }

                <Backdrop style={{ zIndex: '49' }} in={this.state.dial}/>
            </div>
        )
    }

    render() {
        return (
            <PageCard title={'Calendar'} style={{ paddingTop: '0px' }}>
                <div>
                    {/* this.renderButtons() */}
                    <PageLoading direction='up' loading={this.props.dash.sessionLoading}/>
                    { this.state.days.map((day, i) => {
                        day = new Date(day)
                        return (
                            <DayWrapper ref={i === 0 ? this.topDay : (i === this.state.days.length - 1 ? this.botDay : null) } key={i} first={!i}>
                                <div style={{ position: 'sticky', paddingTop: '15px', backgroundColor: 'white', top: '0px', zIndex: '10' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Day today={this.isEqual(Date.now(), day)}> { days[day.getDay()] } </Day>
                                        <DateHead today={this.isEqual(Date.now(), day)}> { `${months[day.getMonth()]} ${day.getDate()}` } </DateHead>
                                    </div>
                                    <DateBorder today={this.isEqual(Date.now(), day)}/>
                                </div>
                                { this.renderSessions(day) }
                            </DayWrapper>
                        )
                    })}
                    <Modal head={this.state.sessionPreviewHeader} in={this.state.sessionPreviewOpen && !this.state.nutritionUpdateOpen && !this.state.fitnessUpdateOpen && !this.state.activityUpdateOpen} handleClose={() => this.setState({ sessionPreviewOpen: false })} direction='up'>
                        <SessionPreview simple_macros={this.props.auth?.user.settings.simple_macros} handleNutritionUpdateOpen={this.handleNutritionUpdateOpen} handleFitnessUpdateOpen={this.handleFitnessUpdateOpen} handleActivityUpdateOpen={this.handleActivityUpdateOpen} type={this.state.sessionPreviewType} sessions={this.state.sessionPreviewSessions} date={this.state.sessionPreviewDate}/>
                    </Modal>
                    <TrackingUpdateModalNutrition time={new Date()} open={this.state.nutritionUpdateOpen} session={this.state.nutritionUpdateSession} handleClose={this.handleNutritionUpdateClose}/>
                    {/*<TrackingUpdateModalFitness time={new Date()} open={this.state.fitnessUpdateOpen} session={this.state.fitnessUpdateSession} handleClose={this.handleFitnessUpdateClose}/>*/}
                    <TrackingUpdateModalActivities time={new Date()} open={this.state.activityUpdateOpen} session={this.state.activityUpdateSession} handleClose={this.handleActivityUpdateClose}/>
                    <TrackingAddModalActivities open={this.state.activityAddOpen} activity={this.state.addActivity} time={this.state.time} handleClose={this.handleActivityAddClose}/>
                    <CustomActivityAddModal open={this.state.customActivityAddOpen} handleClose={() => this.setState({ customActivityAddOpen: false })}/>
                    <ActivityAddModal open={this.state.activityOpen} handleClose={this.handleActivityClose} handleCustomActivityAddOpen={() => this.setState({ customActivityAddOpen: true })} handleCustomActivityUpdateOpen={this.handleCustomActivityUpdateOpen} handleActivityAddOpen={this.handleActivityAddOpen}/>
                    <WeighinInfoModal open={this.state.weighinPreviewOpen} infoSession={this.state.weighinPreviewInfo} handleClose={this.handleWeighinInfoClose}/>
                </div>
            </PageCard>
        );
    }
}

function PageHeader(props) {
    const [open, setOpen] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setOpen(false)
        }, 2000)
    }, [])

    return (
        <Slide direction={'down'} in={open}>
            <h4 style={{ position: 'fixed', top: '7px', left: '7px', padding: '13px', boxShadow: '0 6px 20px 5px rgba(0, 0, 0, 0.15)', backgroundColor: 'white', borderRadius: '50px', zIndex: '200'}}> Calendar </h4>
        </Slide>
    )
}

function SessionPreview(props) {
    if (props.type === 'food') {
        return (
            <div>
                <h6 style={{ opacity: '.7' }}> {months[props.date.getMonth()]} {props.date.getDate()}, {props.date.getFullYear()} </h6>
                <NutritionInfo simple_macros={props.simple_macros} trackingTime={props.date}/>
                { props.sessions.map((session, i) => {
                    return <FoodCard key={i} session={session} food={session.target} handleUpdateOpen={props.handleNutritionUpdateOpen}/>
                })}
            </div>                
        )
    }

    if (props.type === 'workout') {
        return (
            <div>
                <h6 style={{ opacity: '.7' }}> {months[props.date.getMonth()]} {props.date.getDate()}, {props.date.getFullYear()} </h6>
                { props.sessions.map((session, i) => {
                    return <WorkoutCard key={i} session={session} workout={session.target} handleUpdateOpen={props.handleFitnessUpdateOpen}/>
                })}
            </div>
        )
    }

    if (props.type === 'activity') {
        return (
            <div>
                <h6 style={{ opacity: '.7' }}> {months[props.date.getMonth()]} {props.date.getDate()}, {props.date.getFullYear()} </h6>
                { props.sessions.map((session, i) => {
                    return <ActivityCard key={i} session={session} activity={session.target} handleUpdateOpen={props.handleActivityUpdateOpen}/>
                })}
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ 
        getSessions
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)