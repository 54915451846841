import React from 'react';
import { Route } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@material-ui/core'

const PrivateRoute = ({ isAuthenticated, render, ...rest}) => {
    if (!isAuthenticated) window.location.href = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login?redirect_uri=${window.location.pathname}`
    return (
        isAuthenticated ? <Route {...rest} render = {render}/> : <Backdrop transitionDuration={600} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default PrivateRoute