//Classes and Functions
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
//Components and Pages
import { CardContent, Grow, Chip } from '@material-ui/core'
import { SkeletonCard, InfoPopup, CardFloat, Button, Image } from './' 
import { Color } from '../util'
//Images
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit';
import PlayArrowIcon from '@material-ui/icons/PlayArrowRounded';


class WorkoutCard extends Component {
    verifyWorkout = (workout) => {
        let sessions = this.props.dash.sessions.filter((session) => session.type === 'workout')
        for (let index in sessions) {
            let session = sessions[index], temp = new Date(session.time), curr = (this.props.trackingTime ? new Date(this.props.trackingTime) : new Date())
            if (temp.getDate() === curr.getDate() && session.target_id === workout._id) return true
        }

        return false
    }

    handleClick = (event, link) => {
        event.stopPropagation()
        event.preventDefault()
        if (link) {
            this.props.history.push(link)
        }
    }

    renderButton = () => {
        let button = null
        if (this.props.actionLink) {
            button = (
                <Button disabled={this.props.disabled}  onClick={(event) => this.props.handleAddOpen ? this.props.handleAddOpen({ event: event, workout: this.props.workout, session: this.props.sets }) : null }>
                    <AddIcon style={{ fontSize: '18px' }}/>
                </Button>
            )
        } else if (this.props.handleUpdateOpen && this.props.session) {
            button = (
                <Button onClick={(event) => this.props.handleUpdateOpen({ event: event, session: this.props.session })}>
                    <EditIcon style={{ fontSize: '18px' }}/>
                </Button>
            )
        } else if (this.props.verifyWorkout && this.verifyWorkout(this.props.workout)) {
            button = (
                <Button style={{ backgroundColor: Color.CARBS_SUCCESS }}>
                    <CheckIcon style={{ fontSize: '18px'}}/>
                </Button>
            )
        } else if (this.props.handleAddOpen) {
            button = (
                <Button disabled={this.props.disabled} onClick={(event) => this.props.handleAddOpen({ event: event, workout: this.props.workout, session: this.props.sets })}>
                    <AddIcon style={{ fontSize: '18px' }}/>
                </Button>
            )
        }

        return (
            <div onClick={(event) => this.handleClick(event, this.props.actionLink)}>
                { button }
            </div>
        )
    }

    getDuration = (duration) => {
        let string = ""
        if (duration / 3600 >= 1) {
            string += `${Math.floor(duration/3600)}hr${Math.floor(duration/3600) > 1 ? 's ' : ' '}`
            duration -= Math.floor(duration/3600) * 3600
        }

        if (duration / 60 >= 1) {
            string += `${Math.floor(duration/60)}min${Math.floor(duration/60) > 1 ? 's ' : ' '}`
            duration -= Math.floor(duration/60) * 60
        }

        if (duration  >= 1) {
            string += `${duration}sec${duration > 1 ? 's ' : ' '}`
        }

        return string
    }

    getVideoId = (video_url) => {
        if (!video_url) return null
        let breaks = video_url.split('/')
        if (breaks.length) return breaks[breaks.length - 1]
        return null
    }

    render() {
        return (
            <div>
                {this.props.workout ? (
                    <div onClick={(event) => this.handleClick(event, this.props.link)}>
                        <CardFloat outline style={{ cursor: this.props.handleInfoOpen ? 'pointer' : null }} onClick={(event) => !this.props.workout.cardio ? (this.props.handleInfoOpen ? this.props.handleInfoOpen({ event: event, workout: this.props.workout, session_id: this.props.session_id, session: this.props.session, sets: this.props.sets }) : null) : this.props.handleUpdateOpen({ event: event, session: this.props.session, session_id: this.props.session_id, workout: this.props.workout})}>
                            { this.renderButton() }
                            { this.props.workout.video_url ? <Image src={`https://vumbnail.com/${this.getVideoId(this.props.workout.video_url)}.jpg`} style={{ position: 'absolute', height: '100%', left: '0px', top: '0px', objectFit: 'cover', width: '90px' }}/> : null }
                            { this.props.workout.video_url ? <div style={{ backgroundColor: Color.PRIMARY, borderRadius: '0px 15px 0px 0px', height: '30px', width: '30px', position: 'absolute', bottom: '0px', left: '0px'}}>
                                <PlayArrowIcon style={{ color: 'white', fontSize: '18px', position: 'absolute', top: '5px', right: '6px' }}/>
                            </div> : null }
                            <CardContent style={{ minHeight: '90px', marginLeft: this.props.workout.video_url ? '90px' : '0px'  }}>
                                <div style={{ overflowWrap: 'break-word', marginRight: '40px'}} >
                                    <h6 style={{ marginBottom: '5px' }}> {this.props.workout.name} {this.props.workout.workoutClass && this.props.workout.workoutClass === 'Custom' && this.props.handleCustomUpdateOpen ? <EditIcon style={{ opacity: '.5', fontSize: '18px', marginBottom: '3px', marginLeft: '4px'}} onClick={(event) => this.props.handleCustomUpdateOpen({ event, workout: this.props.workout })}/> : null } </h6>
                                    { this.props.sets ? <h6 style={{ opacity: '.7', fontSize: '14px'}}> { this.props.sets.sets } sets, {this.props.sets.reps ? `${this.props.sets.reps} reps` : ''} {this.props.sets.duration ? `${this.props.sets.duration}s` : ''} </h6> : null }
                                    { this.props.session && this.props.session.session_meta.sets ? <h6 style={{ opacity: '.7', fontSize: '14px' }}> {this.props.session.session_meta.sets.length} set{this.props.session.session_meta.sets.length > 1 ? 's' : ''} </h6> : null }
                                    { this.props.session && this.props.session.session_meta.duration ? <h6 style={{ opacity: '.7', fontSize: '14px' }}> {this.getDuration(this.props.session.session_meta.duration)} </h6> : null }
                                </div>
                            </CardContent>
                        </CardFloat>
                    </div> ) : <SkeletonCard/> 
                }
            </div>
        )
    }
}

WorkoutCard.propTypes = {
    workout: PropTypes.object,
    verifyWorkout: PropTypes.bool,
    session: PropTypes.object,
    sets: PropTypes.object,
    handleAddOpen: PropTypes.func,
    handleUpdateOpen: PropTypes.func,
    handleInfoOpen: PropTypes.func,
    actionLink: PropTypes.object
}

function mapStateToProps(state) {
	return {
		dash: state.dash,
	}
}

export default connect(mapStateToProps)(withRouter(WorkoutCard))