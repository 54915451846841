import React, { Component } from 'react';
import styled from 'styled-components'

const ScrollArea = styled.div`
    display: flex;
    position: relative;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
`

class HorizontalScroll extends Component {
    render() {
        return (
            <ScrollArea>
                { this.props.children }
            </ScrollArea>
        );
    }
}

export default HorizontalScroll;