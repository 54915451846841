//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { loginUser, loadUser } from '../actions/authActions'
import { setSnackbar } from '../actions/snackbarActions'
//Components and Pages
import { TextField, Grow, CircularProgress, CardContent } from '@material-ui/core'
import { Redirect } from 'react-router-dom';
import { Modal, CardFloat } from './'
import api from '../api'
//Images
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

class ForgotPasswordModal extends Component {
    constructor (props) {
        super(props)
        this.state = {
            email: '',
            reseting: false
		}
    }

    handleChange = (question, answer) => {
        this.setState( { [question]: answer })
	}

	handleForgotPassword = () => {
        this.setState({ reseting: true })
		api.userForgotPassword({ email: this.state.email }).then((response) => {
            this.setState({ reseting: false, status: "sentActive" })
            this.props.setSnackbar({ msg: 'Email Sent', type: 'success' })
		}).catch((error) => {
            this.setState({ reseting: false })
		})
    }

	render() {
		return (
			<div>
                { this.props.auth.isAuthenticated && this.props.redirect ? <Redirect to='/dashboard'/> : null}

                <Modal direction='up' in={this.props.open} head={'Reset Password'}>
                    <CardFloat>
                        <CardContent>
                            <div style={{ margin: '10px'}}>
                                <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Email </h6> 
                                <TextField 
                                    type="email"
                                    autoFocus
                                    error={this.props.sbar.msg === "User does not exist"}
                                    value={this.state.email} 
                                    placeholder="Email"
                                    style={{ width: '60%'}}
                                    onChange = {(event) => {
                                    this.handleChange("email", event.target.value)}}/> 	
                            </div>

                            <Grow in={(this.state.email) ? true : false} style={{ position: 'absolute', right: '10%', top: '20px'}}>
                                <div style={{ color: '#3f50b5' }} onClick = {() => this.handleForgotPassword()}>
                                    <p> Submit </p>
                                    {this.state.reseting ? <CircularProgress style={{ marginLeft: '14px' }}size={20} /> : <ArrowForwardIcon style={{ marginLeft: '14px', marginBottom: '10px'}}/>}
                                </div>
                            </Grow>
                        </CardContent>
                    </CardFloat>
                    <h6 onClick={this.props.handleSignin} style={{ textAlign: 'center', cursor: 'pointer'}}> Back to Sign In </h6>
                </Modal>                              
		    </div>
		)
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
		sbar: state.sbar
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        loadUser,
	 	loginUser,
        setSnackbar }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordModal)
