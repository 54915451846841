//Classes and Functions
import React, { Component } from 'react' 
import { connect } from 'react-redux'
//Components and Pages
import { CardContent, CircularProgress, Grow } from '@material-ui/core';
import { Measure, Color } from '../util'
import { SkeletonCard, CardFloat, Selector } from './'
import Trend from 'react-trend'
import ChipSelector from './ChipSelector';
import { months } from '../util'
//Images

class Infographic extends Component {
    constructor(props) {
        super(props)

        this.state = {
            type: undefined
        }
    }

    componentDidMount() {
        if (this.props.type) {
            if (Array.isArray(this.props.type)) this.setState({ type: this.props.type[0].value })
            else this.setState({ type: this.props.type })
        }
    }

    hasMultipleTypes = () => {
        return this.props.type && Array.isArray(this.props.type)
    }

    hasMultipleTitles = () => {
        return this.props.title && Array.isArray(this.props.title)
    }

    getHistory = (type) => {
        let history = this.props.dash.history.filter((date) => date[type])
        history.sort((a, b) => a.time - b.time)
        return history
    }

    getData = (type) => {
        if (type === 'custom') {
            return ({
                data: this.props.data.points,
                recentDate: this.props.data.recentDate,
                recent: this.props.data.points[this.props.data.points.length - 1],
                previousDate: this.props.data.previousDate,
                previous: this.props.data.points[0],
                maximum : Math.round(Math.max(...this.props.data.points)),
                minimum : Math.round(Math.min(...this.props.data.points)),
                unit: this.props.data.unit
            })
        }

        let history = this.getHistory(type)
        let data = history.map((date) => { return date[type] }), previous = undefined, recent = undefined, previousDate = undefined, recentDate = undefined, unit = undefined, previousDateUTC = undefined, recentDateUTC = undefined
        
        if (history.length > 0) {
            recent = history[0]
            recentDate = new Date(history[0].time)
            previous = history[history.length - 1]
            previousDate = new Date(history[history.length - 1].time)
        }

        switch(type) {
            case ('weight') : {
                unit = Measure[this.props.auth.user.settings.unit]['mass'].unit
                data = data.map((point) => { return Math.round(point / Measure[this.props.auth.user.settings.unit]['mass'].mult) })
                break
            }

            case ('waist_size') : {
                unit = Measure[this.props.auth.user.settings.unit]['length'].unit
                data = data.map((point) => { return Math.round(point / Measure[this.props.auth.user.settings.unit]['length'].mult) })
                break
            }

            case ('body_fat') : {
                unit = '%'
                data = data.map((point) => { return Math.round(point * 100) })
                break
            }

            default : {
                break
            }
        }

        if (data.length === 1) {
            data = [...data, ...data]
        }

        return {
            data,
            recent,
            recentDate,
            recentDateUTC,
            previous,
            previousDate,
            previousDateUTC,
            maximum : Math.max(...data),
            minimum : Math.min(...data),
            unit
        }
    }

    handleChange = (variable, value) => { 
        this.setState({ [variable] : value })
    }

    getMeasure = (type) => {
        return Measure[this.props.auth.user.settings.unit][type].unit
    }

    getColor = (type) => {
        return [Color.PRIMARY, Color.CARBS]
    }

    getTitle = () => {
        if (this.hasMultipleTypes() && this.hasMultipleTitles()) {
            let titles = this.props.title.filter((title) => title.type === this.state.type)
            if (titles.length) return titles[0].title
            else return null
        } else return this.props.title
    }

    renderTypeChange = (type) => {
        return (
            !this.props.hidden ? <div>
                <Selector style={{ marginBottom: '0px', marginTop: '10px'}} options={[{ label: 'All', value: undefined }, { label: 'Weigh-Ins', value: 'weigh_in' }, { label: 'Recals', value: 'recalibration' }]} handleChange={(value) => this.handleChange('type', value)} value={this.state.type}/>
            </div> : null
        )
    }

    renderGraph = () => {
        return (
            <svg height="20" width='23'>
                <rect x='0' y='6' width="6" height="14" fill='grey' rx='3'/>
                <rect x='7' y='0' width="6" height="20" fill="grey" rx='3'/>
                <rect x='14' y='4' width="6" height="16" fill="grey" rx='3'/>
            </svg>
        )
    }
    
    render() {
        let data = this.props.auth.user ? this.getData(this.state.type) : null

        return (
            this.props.auth.user ?
                <CardFloat style={{ ...this.props.style }}>
                    <CardContent style={{ padding: '12px' }}>
                        { this.hasMultipleTypes() ? <ChipSelector style={{ position: 'absolute', top: '15px', right: '15px', zIndex: '4' }} options={this.props.type} value={this.state.type} handleChange={(value) => this.handleChange('type', value)}/> : null }
                        { this.props.dash.historyLoading ? <CircularProgress size={20} style={{ position: 'absolute', top: '15px', right: '15px' }} /> : null }
                        <h6 style={{ marginBottom: '3px', opacity: '.7', fontSize: '14px' }}> {this.getTitle()} </h6>
                        <h6 style={{ marginBottom: '3px', fontSize: '18px' }}> {data.data[data.data.length - 1]}{data.unit} </h6>
                        { data.data.length > 1 ? <div style={{ marginTop: '25px' }}>
                            <div style={{ borderLeft: '1px dotted rgba(0, 0, 0, .2)', borderBottom: '1px dotted rgba(0, 0, 0, .2)', marginBottom: '4px' }}><Trend style={{ maxHeight: '100px', width: '100%' }} data={data.data} gradient={this.getColor(this.state.type)} strokeLinecap={'round'} smooth radius={10} strokeWidth={5} autoDraw autoDrawDuration={1500} autoDrawEasing="ease-in"/></div>
                            <div style={{ display: 'flex' }}>
                                <h6 style={{ opacity: '.5', fontSize: '14px', marginBottom: '0px'}}> {months[data.recentDate.getMonth()]?.substring(0, 3)} </h6>
                                <h6 style={{ opacity: '.5', fontSize: '14px', marginBottom: '0px', marginLeft: 'auto' }}> {months[data.previousDate.getMonth()]?.substring(0, 3)} </h6>
                            </div>
                        </div> : 
                        <Grow in={true}>
                            <p style={{ textAlign: 'center', opacity: '.7', marginTop: '40px', marginBottom: '60px' }}> No Data </p>
                        </Grow> } 
                    </CardContent>
                </CardFloat> : <SkeletonCard/>
        )
    }
}

function mapStateToProps(state) {
	return {
        auth: state.auth,
        dash: state.dash
	}
}

export default connect(mapStateToProps)(Infographic)
