//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearSnackbar } from '../actions/snackbarActions'
import { Color } from '../util'
//Components and Pages
import { Snackbar } from '@material-ui/core';
//Images
import CheckIcon from '@material-ui/icons/Check'

class ToastNotifications extends Component {
    render() {
        return (
            <Snackbar open={this.props.sbar.snackbar} onClose = {() => this.props.clearSnackbar()} autoHideDuration={1500}>
                <div style={{ borderRadius: '25px', marginBottom: '5px', backgroundColor: this.props.sbar.type === 'error' ? Color.CARBS_ERROR : Color.CALORIES_SUCCESS, color: 'white', width: 'auto'}}>
                    { this.props.sbar.status && this.props.sbar.type === 'error' ? <div style={{ margin: '5px', backgroundColor: Color.CALORIES_ERROR, color: 'white', borderRadius: '25px', width: 'auto',  float: 'left' }}>
                        <h6 style={{ fontSize: '12px', margin: '10px'}}> {this.props.sbar.status} </h6>
                    </div> : null }
                    { this.props.sbar.type === 'success' ? <div style={{ margin: '5px', backgroundColor: Color.CARBS_SUCCESS, color: 'white', borderRadius: '25px', width: 'auto', float: 'left', padding: '3px'}}>
                        <CheckIcon style={{ fontSize: '16px', margin: '6px'}}/>
                    </div> : null }
                    <h6 style={{ fontSize: '14px', margin: '15px 15px 15px 0px', float: 'right'}}> {this.props.sbar.msg} </h6>
                </div>
            </Snackbar>        
        )
    }
}

function mapStateToProps(state) {
	return {
        sbar: state.sbar
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        clearSnackbar
     }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastNotifications)
