//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
//Components and Pages
import { withRouter } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core';
import { ArticleAddModal, Hoverable } from '../components'
import { Color } from '../util'
import api from '../api'
//Images
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add'
import FilterListIcon from '@material-ui/icons/FilterList';

class AdminArticle extends Component {
    constructor (props) {
        super(props)
		this.state = {
            time: new Date(),
            loading: false,
            articles: [],
            tooltipLoading: false
		}
    }

    componentDidMount() {
        this.timeClock = setInterval(this.tick, 1000)
        this.getUsers()
    }

    getAuthRequest = () => {
        const token = localStorage.getItem('accessToken')
        const req = {
            headers: {
                'Content-type': 'application/json'
            }
        }
    
        if (token) req.headers['x-access-token'] = token
        return req
    }

    getUsers = () => {
        this.setState({ loading: true })
        let req = this.getAuthRequest()
        req.params = { sort: "[[\"createdAt\", -1]]", _id: this.props.match.params.id }
        api.getArticle(req).then((response) => {
            this.setState({ articles: response.data, loading: false })
        }).catch((error) => {
            this.setState({ loading: false })
        })
    }

    getTypeColor = (type) => {
        switch(type) {
            case ('article'):
                return Color.PRIMARY
            case ('training'):
                return Color.PROTEIN
            default:
                return Color.PRIMARY
        }
    }

    componentWillUnmount() { 
        clearInterval(this.timeClock)
        document.body.style.overflow = 'unset';
    }

    handleRefresh = () => {
        this.getUsers()
    }
    
    tick = () => {
        this.setState({ time: new Date() })
    }

    render() {
        return (
            <div>
                <div style={{ position: 'relative' }}>
                    <h1> Articles </h1>
                    <div style={{ outline: 'none', userSelect: 'none' }}>
                        <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={this.handleRefresh}> Refresh <RefreshIcon style={{ fontSize: '13px' }}/> </span>
                        <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={() => this.setState({ articleAdd: true })}> Create <AddIcon style={{ fontSize: '13px'}}/> </span>
                        <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={() => this.setState({ filters: !this.state.filters })}> Filters <FilterListIcon style={{ fontSize: '13px'}}/> </span>
                    </div>
                    { !this.state.loading ? <div>
                        <div style={{ width: '100%', padding: '10px 20px 10px 20px' }}>
                            <div style={{ borderBottom: '1px dashed rgba(0, 0, 0, 0.2)' }}>
                                <div style={{ display: 'inline-block', width: '30%', padding: '10px' }}>
                                    <h6 style={{ marginBottom: '0px', fontSize: '14px', textOverflow: 'ellipsis'  }}> Title </h6>
                                </div>
                                <div style={{ display: 'inline-block', width: '20%', padding: '10px' }}>
                                    <h6 style={{ marginBottom: '0px', fontSize: '14px', textOverflow: 'ellipsis'  }}> Author </h6>
                                </div>
                                <div style={{ display: 'inline-block', width: '10%', padding: '10px' }}>
                                    <h6 style={{ marginBottom: '0px', fontSize: '14px' }}> Views </h6>
                                </div>
                                <div style={{ display: 'inline-block', width: '15%', padding: '10px' }}>
                                    <h6 style={{ marginBottom: '0px', fontSize: '14px' }}> Type </h6>
                                </div>
                                <div style={{ display: 'inline-block', width: '25%', padding: '10px' }}>
                                    <h6 style={{ marginBottom: '0px', fontSize: '14px', textOverflow: 'ellipsis' }}> Date Created </h6>
                                </div>
                            </div>
                            { this.state.articles.map((article, i) => {
                                let date = new Date(article.createdAt)
                                return (
                                    <Hoverable key={i}>
                                        <div style={{ borderBottom: '1px dashed rgba(0, 0, 0, 0.2)', height: '41px', position: 'relative', cursor: 'pointer' }}>
                                            <div style={{ display: 'inline-block', width: '30%', borderRight: '1px dashed rgba(0, 0, 0, 0.2)', padding: '10px', verticalAlign: 'top', overflow: 'hidden', height: '100%'  }}>
                                                <p style={{ marginBottom: '0px', fontSize: '14px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', verticalAlign: 'center'  }}> { article.title } </p>
                                            </div>
                                            <div style={{ display: 'inline-block', width: '20%', borderRight: '1px dashed rgba(0, 0, 0, 0.2)', padding: '10px', verticalAlign: 'top', overflow: 'hidden', height: '100%' }}>
                                                <p style={{ marginBottom: '0px', fontSize: '14px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', verticalAlign: 'center'  }}> { article.author || <span style={{ opacity: '.4'}}> n/a </span> } </p>
                                            </div>
                                            <div style={{ display: 'inline-block', width: '10%', borderRight: '1px dashed rgba(0, 0, 0, 0.2)', padding: '10px', verticalAlign: 'top', overflow: 'hidden', height: '100%'   }}>
                                                <p style={{ marginBottom: '0px', fontSize: '14px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', verticalAlign: 'center'  }}> { article.views } </p>
                                            </div>
                                            <div style={{ display: 'inline-block', width: '15%', borderRight: '1px dashed rgba(0, 0, 0, 0.2)', padding: '8px', verticalAlign: 'top', overflow: 'hidden', height: '100%'   }}>
                                                <span style={{ fontSize: '12px', backgroundColor: this.getTypeColor(article.type), verticalAlign: 'center', color: 'white', borderRadius: '5px', padding: '4px' }}> { article.type } </span>
                                            </div>
                                            <div style={{ display: 'inline-block', width: '25%', borderRight: '1px dashed rgba(0, 0, 0, 0.2)', padding: '10px', verticalAlign: 'top', height: '100%'   }}>
                                                <Hoverable tooltip={ <p style={{ marginBottom: '0px', fontSize: '12px', padding: '5px', color: Color.PRIMARY }}> { date.toUTCString() } </p>}>
                                                    <p style={{ marginBottom: '0px', fontSize: '14px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', verticalAlign: 'top' }}> { date.toLocaleString() } </p>
                                                </Hoverable>                                            
                                            </div>
                                        </div>
                                        { /* <ArticleFormatter content={article.content}/> */ }
                                    </Hoverable>
                                )
                            })} 
                            <h6 style={{ textAlign: 'center', opacity: '.7', margin: '20px', fontSize: '13px' }}> {this.state.articles.length} Results </h6>
                        </div>
                    </div> : <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                        <CircularProgress size={25}/>
                    </div> }
                </div>
                { this.props.auth.user ? <ArticleAddModal open={this.state.articleAdd} handleClose={() => this.setState({ articleAdd: false })}/> : null }
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

export default connect(mapStateToProps)(withRouter(AdminArticle))
