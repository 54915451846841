import { combineReducers } from "redux"

import auth from "./authReducer"
import dash from "./dashboardReducer"
import sbar from './snackbarReducer'

export default combineReducers({
    auth,
    dash,
    sbar
})
