//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import api from '../api'
//Components and Pages
import { Card, CardContent, Grow } from '@material-ui/core';
import { CheckoutModal, Modal } from './'
//Images
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import SkeletonCard from './SkeletonCard'

const defaultState = {
    plans: [],
    plan: {},
    reset: false,
    checkout: false
}

class ActionRequiredModal extends Component {
    constructor (props) {
        super(props)
        this.state = { ...defaultState }
    }

    handleChange = (question, answer) => {
        this.setState( { [question]: answer })
    }

    componentDidMount() {
        api.stripeGetSubscription({
            headers : {
                'Content-type': 'application/json',
                'x-access-token' : localStorage.getItem('accessToken')
            },
            params : {
                subscription_id : this.props.auth.user.subscription.subscription_id
            }
        }).then((response) => {
            this.setState({ subscription: response.data })
        }).catch((error) => {
            console.log(error)
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open === false && this.props.open === true) {
            api.stripeGetSubscription({
                headers : {
                    'Content-type': 'application/json',
                    'x-access-token' : localStorage.getItem('accessToken')
                },
                params : {
                    subscription_id : this.props.auth.user.subscription.subscription_id
                }
            }).then((response) => {
                this.setState({ subscription: response.data })
            }).catch((error) => {
                console.log(error)
            })
        }
    }

	render() {
        return (
            <div>
                <Modal direction='up' in={this.props.open && !this.state.checkout} head={'Manage Subscription'}>
                    { this.state.subscription ?
                    <Grow in={true} >
                        <Card style={{margin: '5px', marginTop: '20px', borderRadius: '25px', marginBottom: '20px', paddingTop: '10px',  position: 'relative',  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09)'}}>
                            <CardContent>
                                <h6> Action Required </h6>
                                <h6 style={{ opacity: '.7', marginRight: '70px', fontSize: '14px'}}> { this.state.subscription.latest_invoice.payment_intent.status === 'requires_action' ? 'Requires Verification' : 'Requires New Payment Method' }</h6>
                                <ArrowForwardIos fontSize={'small'} style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)', opacity: '.7' }} onClick = {() => this.setState({ checkout: true })}/>
                            </CardContent>
                        </Card>
                    </Grow> : <SkeletonCard/> }
                </Modal>
                <CheckoutModal open={this.state.checkout} plan={this.state.plan} subscription={this.state.subscription} handleClose={() => this.setState({ checkout: false })}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
		err: state.err
	}
}

export default connect(mapStateToProps)(ActionRequiredModal)

