import CloseIcon from '@material-ui/icons/CloseRounded';
import logo from '../images/jbf_logo.png'

const AffirmCancelled = () => {
    return (
        <div className="max-w-screen-md mx-auto container p-8 flex flex-col items-center gap-4 h-screen justify-center">
            <div className='h-[150px] fixed top-0 w-full flex items-center border-b border-[#CCCCCC] border'>
                <img src={logo} className='h-full w-full object-contain'/>
            </div>
            <div className='rounded-full bg-calories-error text-white bg-opacity-70 p-2 flex items-center'>
                <CloseIcon style={{ fontSize: '50px' }}/>
            </div>
            <div className='flex flex-col items-center gap-2 text-center'>
                <h2 className='mb-0'> Something went wrong </h2>
                <h6 className='font-normal text-xl opacity-70 mb-0'> Your payment was cancelled </h6>
                <p className='mt-4'> Please revisit the Affirm email or contact your representative at JBF to complete your checkout </p>
            </div>
        </div>
    )
}

export default AffirmCancelled