import React from 'react';
import { Fade } from '@material-ui/core'

function Page(props) {
    return (
        <Fade in={true}>
            <div>
                { props.children }
            </div>
        </Fade>
    )
}

export default Page;