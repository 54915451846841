import axios from 'axios'
import store from '../store'
import { setSnackbar } from '../actions/snackbarActions'

const api = axios.create({
	baseURL: process.env.NODE_ENV === 'production' ? `${process.env.PUBLIC_URL}/api` : 'http://localhost:8000/api',
	withCredentials: true 
})

//User Calls
const verifyEmail = (payload, req) => api.post('/auth/verify-email', payload, req)
const otp = (payload, req) => api.post('/auth/otp', payload, req)
const authUser = (payload) => api.post('/auth/password', payload)
const getUser = (req) => api.get('/auth/token', req)
const getUserData = (req) => api.get('/users', req)
const insertUser = (payload) => api.post('/users', payload)
const deleteUser = (req) => api.delete('/users', req)
const userForgotPassword = (payload) => api.post('/auth/forgot', payload)
const userResetPassword = (token, payload) => api.post(`/auth/reset/${token}`, payload)
const userRecalibrate = (payload, req) => api.post('/users/recalibrate', payload, req)
const generatePlans = (payload, req) => api.post('/users/plans', payload, req)
const changePassword = (payload, req) => api.post('/users/password', payload, req)
const addHistory = (payload, req) => api.post('/users/history', payload, req)
const updateProfile = (payload, req) => api.put('/users', payload, req)
const updateAvatar = (payload, req) => api.post('/users/avatar', payload, req)
const activateUser = (user_id) => api.post(`/users/${user_id}/activate`, {}, {})

//Plan Calls
const getMealPlan = (req) => api.get('/plans', req)
const createMealPlan = (payload, req) => api.post('/plans', payload, req)
const getMealPlanSwaps = (req) => api.get('/plans/swap', req)
const swapMealPlanItem = (payload, req) => api.put('/plans/swap', payload, req)

//Image Calls
const getImage = (req) => api.get('/image', req)
const uploadImage = (payload, req) => api.post('/image/upload', payload, req)

//Favorites and Exclusions
const getPreference = (req) => api.get('/preferences', req)
const createPreference = (payload, req) => api.post('/preferences', payload, req)
const updatePreference = (_id, payload, req) => api.put(`/preferences/${_id}`, payload, req)
const deletePreference = (_id, req) => api.delete(`/preferences/${_id}`, req)

//Custom Food
const getCustomFoods = (req) => api.get('/food/custom', req)
const createCustomFood = (payload, req) => api.post('/food/custom', payload, req)
const updateCustomFood = (payload, req) => api.put('/food/custom', payload, req)
const deleteCustomFood = (req) => api.delete('/food/custom', req)

//Custom Workout Calls
const getCustomWorkouts = (req) => api.get('/workout/custom', req)
const createCustomWorkout = (payload, req) => api.post('/workout/custom', payload, req)
const updateCustomWorkout = (payload, req) => api.put('/workout/custom', payload, req)
const deleteCustomWorkout = (req) => api.delete('/workout/custom', req)

//Custom Activity
const getCustomActivities = (req) => api.get('/activities/custom', req)
const createCustomActivity = (payload, req) => api.post('/activities/custom', payload, req)
const updateCustomActivity = (payload, req) => api.put('/activities/custom', payload, req)
const deleteCustomActivity = (req) => api.delete('/activities/custom', req)

//Activities
const getActivities = (req) => api.get('/activities', req)

//Session Calls
const getSessions = (req) => api.get('/sessions', req)
const createSession = (payload, req) => api.post('/sessions', payload, req)
const updateSession = (payload, req) => api.put('/sessions', payload, req)
const deleteSession = (req) => api.delete('/sessions', req)
const getSessionStats = (req) => api.get('/sessions/stat', req)
const getNutritionSessionStats = (req) => api.get('/sessions/nutrition/stats', req)
const getRecentSessions = (req) => api.get('/sessions/recent', req)
const copySessions = (payload, req) => api.post('/sessions/copy', payload, req)

//Articles
const getArticle = (req) => api.get('/articles', req)
const getArticleById = (_id, req) => api.get(`/articles/${_id}`, req)
const createArticle = (payload, req) => api.post('/articles', payload, req)
const updateArticle = (payload, req) => api.put('/articles', payload, req)
const deleteArticle = (req) => api.delete('/modules', req)

//Modules
const getModule = (req) => api.get('/modules', req)
const createModule = (payload, req) => api.post('/modules', payload, req)
const updateModule = (payload, req) => api.put('/modules', payload, req)
const deleteModule = (req) => api.delete('/modules', req)

//Views
const createView = (payload, req) => api.post('/views', payload, req)
const getViews = (req) => api.get('/views', req)

//History
const createWeighin = (payload, req) => api.post('/history', payload, req)
const getWeighins = (payload, req) => api.get('/history', payload, req)
const getWeighinsStat = (req) => api.get('/history/stat', req)

//Recalibrations
const getRecalibrations = (req) => api.get('/recalibration', req)

//auth calls
const refreshToken = (payload) => api.get('/auth/refresh', payload)
const revokeToken = (payload, req) => api.post('/auth/revoke', payload, req)

//Food Calls
const searchFoods = (req, options) => api.get('/food/search', req)
const getPredictive = (payload, req) => api.post('/food/predictive', payload, req)
const sample = (payload) => api.post('/food/sample', payload)

const getFoods = (req) => api.get('/food', req)
const getBrandedFoods = (_id, req) => api.get(`/food/branded/${_id}`, req)
const getCommonFoods = (_id, req) => api.get(`/food/common/${_id}`, req)
const getRecipeById = (_id, req) => api.get(`/food/recipes/${_id}`, req)
const getBrandedByUPC = (upc, req) => api.get(`/food/upc/${upc}`, req)

const foodSync = (payload, req) => api.post('/food/sync', payload, req)

//Workout Calls
const getWorkouts = (req) => api.get('/workout', req)
const workoutSample = (payload) => api.post('/workout/sample', payload)

//Stripe Calls
const stripeGetPromoCode = (req) => api.get('/stripe/promotion-code', req) 
const stripeGetPlans = (req) => api.get('/stripe/plans', req)
const stripeGetCustomer = (req) => api.get('/stripe/customer', req)
const stripeGetSubscription = (req) => api.get('/stripe/customer/subscription', req)
const stripeCreateSubscription = (payload) => api.post('/stripe/customer/subscription', payload)
const stripeUpdateSubscription = (payload, req) => api.put('/stripe/customer/subscription', payload, req)
const stripeUpdatePaymentMethod = (payload, req) => api.post('/stripe/customer/payment-method', payload, req)
const stripeRetryInvoice = (payload) => api.post('/stripe/customer/subscription/retry-invoice', payload)

//Affirm Calls
const createAffirmCheckoutSandbox = (payload) => api.post('/affirm/sandbox/create-checkout', payload)
const confirmAffirmCheckoutSandbox = (payload) => api.post('/affirm/sandbox/confirm-checkout', payload)
const createAffirmCheckout = (payload) => api.post('/affirm/create-checkout', payload)
const confirmAffirmCheckout = (payload) => api.post('/affirm/confirm-checkout', payload)

//BugReport Calls
const createBugReport = (payload, req) => api.post('/report', payload, req)
const updateBugReport = (payload, req) => api.put('/report', payload, req)
const getBugReports = (req) => api.get('/report', req)

//Cancellation Calls
const createCancellation = (payload, req) => api.post('/cancellation', payload, req)
const getCancellations = (req) => api.get('/cancellation', req)

//Login Calls
const getLogins = (req) => api.get('/login', req)
const getLoginAverage = (req) => api.get('/login/stat', req)
const getLoginActive = (req) => api.get('/login/active', req)

api.interceptors.response.use((response) => { return response }, async (error) => {
	if (!error.response && !axios.isCancel(error)) {
		store.dispatch(setSnackbar({ type: 'error', msg: 'Network Error', status: 404}))
		throw error
	}
	
	if (error.response) {
		switch(error.response.status) {
			case (401) : {
				switch(error.response.data.msg) {
					case ("No Token Found") :
					case ('Invalid Refresh Token') : {
						store.dispatch({ type: 'USER_SIGNOUT_SUCCESS' })
						store.dispatch({ type: 'CLEAR_DASHBOARD' })
						break
					}
	
					default : {
						//store.dispatch(setSnackbar({ type: "error", msg: error.response.data.msg, status: error.response.status }))
						throw error
					}
				}
				break
			}
	
			default : {
				//store.dispatch(setSnackbar({ type: "error", msg: error.response.data.msg, status: error.response.status }))
				throw error
			}
		}
	}
})

/*case ("Invalid Access Token") : {
	return refreshToken({}).then((response) => {
		let state = store.getState()
		localStorage.setItem('accessToken', response.data.token)
		error.config.headers['x-access-token'] = response.data.token
		if (state.auth.user) store.dispatch(mountUser(response.data.user))
		return axios.request(error.config)
	}).catch((error) => {
		throw error
	})
}*/

const apis = {
	insertUser,
	getUser,
	authUser,
	getMealPlan,
	createMealPlan,
	getMealPlanSwaps,
	swapMealPlanItem,
	stripeCreateSubscription,
	stripeRetryInvoice,
	deleteUser,
	getUserData,
	getFoods,
	searchFoods,
	getWorkouts,
	stripeGetCustomer,
	stripeGetPromoCode,
	stripeUpdatePaymentMethod,
	stripeUpdateSubscription,
	stripeGetSubscription,
	userForgotPassword,
	userResetPassword,
	userRecalibrate,
	sample,
	workoutSample,
	stripeGetPlans,
	generatePlans,
	changePassword,
	updateProfile,
	addHistory,
	revokeToken,
	refreshToken,
	getBrandedFoods,
	getCommonFoods,
	getPredictive,
	getCustomFoods,
	createCustomFood,
	updateCustomFood,
	deleteCustomFood,
	createBugReport,
	updateBugReport,
	getBugReports,
	createCancellation,
	getCancellations,
	getPreference,
	createPreference,
	updatePreference,
	deletePreference,
	getArticle,
	createArticle,
	updateArticle,
	deleteArticle,
	createView,
	getViews,
	createWeighin,
	getWeighins,
	getWeighinsStat,
	getCustomWorkouts,
	createCustomWorkout,
	updateCustomWorkout,
	deleteCustomWorkout,
	getSessions,
	createSession,
	updateSession,
	deleteSession,
	getSessionStats,
	getNutritionSessionStats,
	getRecentSessions,
	getModule,
	createModule,
	updateModule,
	deleteModule,
	getCustomActivities,
	createCustomActivity,
	updateCustomActivity,
	deleteCustomActivity,
	getActivities,
	getLogins,
	getLoginAverage,
	getLoginActive,
	getRecalibrations,
	getImage,
	uploadImage,
	updateAvatar,
	getRecipeById,
	getBrandedByUPC,
	copySessions,
	foodSync,
	verifyEmail,
	otp,
	activateUser,
	getArticleById,
	createAffirmCheckout,
	createAffirmCheckoutSandbox,
	confirmAffirmCheckout,
	confirmAffirmCheckoutSandbox
}

export default apis
