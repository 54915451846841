import React, { useEffect, useState } from 'react'
import { Color } from '../util'

function Timer(props) {
    const [time, setTime] = useState(props.duration)

    useEffect(() => {
        const timer = setInterval(() => setTime(time => time - 1), 1000)
        return () => clearInterval(timer)
    }, [])

    return (
        <div style={{ height: '60px', width: '100%', backgroundColor: Color.PRIMARY, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'sticky', top: '0px', zIndex: '10'  }}>
            <h5 style={{ textAlign: 'center', color: 'white', marginBottom: '0px' }}> {Math.floor(time / 60)}:{time % 60 < 10 ? `0${time % 60}` : time % 60} </h5>
        </div>
    )
}

export default Timer