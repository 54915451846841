//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
//Components and Pages
import { CardContent, withStyles, LinearProgress } from '@material-ui/core'
import { SkeletonCard, CardFloat } from './' 
//Images
import { Measure, Color, timeToLocal, months, days } from '../util'
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';

const BorderLinearProgress = withStyles(theme => ({
	root: {
	  height: 7,
      borderRadius: 10
	},
	colorPrimary: {
	  backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
	},
	bar: {
	  borderRadius: 10,
	}
  }))(LinearProgress);


class RecalibrationCard extends Component {
    handleClick = (event, link) => {
        event.preventDefault()
        event.stopPropagation()
        if (link && !(this.props.recalibrateLink && this.isRecalibrationDate())) this.props.history.push(link)
        else if (this.props.recalibrateLink && this.isRecalibrationDate()) this.props.history.push(this.props.recalibrateLink)
    }

    getMeasure = (type, num) => {
        return Math.round(num / Measure[this.props.auth.user.settings.unit][type].mult * 10) / 10
    } 

    isRecalibrationDate = () => {
        return this.props.auth.user.curr.recalibration_date !== null && this.props.auth.user.curr.recalibration_date <= timeToLocal(this.props.time.getTime())
    }

    renderCard = () => {
        let history = this.props.dash.history, currentWeight = 0, startingWeight = 0
        history.sort((a, b) => b.time - a.time)
        if (history[history.length - 1]) startingWeight = history[history.length - 1].weight
        if (history[0]) currentWeight = history[0].weight

        return (
            this.props.mini ? <CardFloat style={{ marginBottom: '10px', maxWidth: '100%' }}>
                <CardContent style={{ height: '143px'}}>
                    <h6 style={{ fontSize: '14px', marginBottom: '10px' }}> Next Recalibration </h6>
                    <h6 style={{ color: Color.PRIMARY }}> {days[this.getDateObject(this.props.auth.user.curr.recalibration_date).getUTCDay()]} </h6>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', margin: '12px 0px 8px 0px' }}>
                        <BorderLinearProgress style={{ width: '180px'}} variant='determinate' value={(startingWeight - currentWeight) / (startingWeight - this.props.auth.user.settings.target_weight) * 100}/>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
                        <p style={{ fontSize: '12px', opacity: '.7', marginBottom: '0px' }}> {this.getMeasure("mass", startingWeight)} {Measure[this.props.auth.user.settings.unit].mass.unit}s </p>
                        <p style={{ fontSize: '12px', marginLeft: 'auto', opacity: '.7', marginBottom: '0px' }}> {this.getMeasure("mass", this.props.auth.user.settings.target_weight)} {Measure[this.props.auth.user.settings.unit].mass.unit}s </p>
                    </div>
                </CardContent>
            </CardFloat> : <CardFloat style={{ ...this.props.style}} onClick={(event) => this.handleClick(event, this.props.link)}>
                {this.renderButton()}
                <CardContent>
                    <h6> Next Recalibration </h6>
                    <h6 style={{ opacity: '.7', fontSize: '14px', marginBottom: '5px' }}> {this.props.auth.user.curr.recalibration_date !== null ? 
                        <div> { this.props.auth.user.curr.recalibration_date > timeToLocal(this.props.time.getTime()) ? days[this.getDateObject(this.props.auth.user.curr.recalibration_date).getUTCDay()] : "Today"}, {months[this.getDateObject(this.props.auth.user.curr.recalibration_date).getUTCMonth()] + " " + this.getDateObject(this.props.auth.user.curr.recalibration_date).getUTCDate()} </div> : "No Active Recalibration"} </h6>
                    <div style={{ width: '180px', display: 'flex', alignItems: 'center', margin: '12px 0px 12px 0px' }}>
                        <BorderLinearProgress style={{ width: '180px'}} variant='determinate' value={(startingWeight - currentWeight) / (startingWeight - this.props.auth.user.settings.target_weight) * 100}/>
                    </div>
                    <div style={{ width: '180px', display: 'flex', alignItems: 'center'}}>
                        <h6 style={{ fontSize: '12px', opacity: '.5', marginBottom: '0px' }}> {this.getMeasure("mass", startingWeight)} {Measure[this.props.auth.user.settings.unit].mass.unit}s </h6>
                        <h6 style={{ fontSize: '12px', marginLeft: 'auto', opacity: '.5', marginBottom: '0px' }}> {this.getMeasure("mass", this.props.auth.user.settings.target_weight)} {Measure[this.props.auth.user.settings.unit].mass.unit}s </h6>
                    </div>
                </CardContent>
            </CardFloat>
        )
    }

    renderButton = () => {
        return (
            <div>
                {
                    this.isRecalibrationDate() ? <div onClick={(event) => this.handleClick(event, this.props.recalibrateLink)}> 
                        <ArrowForwardIcon fontSize={'small'} style={{ position: 'absolute',  top:'50%', transform:'translateY(-50%)',  right: '35px', opacity: '.7'}} onClick={(event) => this.props.handleOpen ? this.props.handleOpen({ event: event }) : null}/>
                    </div> : null
                }
            </div> 
        )
    }

    getDateObject = (time) => {
        return new Date(time)
    }

    render() {
        return (
            <div>
                { this.props.auth.isAuthenticated && this.props.auth.user ? this.renderCard() : <SkeletonCard/> }
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

export default connect(mapStateToProps)(withRouter(RecalibrationCard))