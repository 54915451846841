//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
//Components and Pages
import { CardContent, LinearProgress, withStyles } from '@material-ui/core'
import { CardFloat, HelpButton, PageCard, StickyHeader } from '../components'
import { withRouter } from 'react-router-dom'
import { Color, timeToLocal, getAuthRequest, localStart, localEnd } from '../util'
import styled from 'styled-components'
import api from '../api'
//Images
import { CircularProgress } from '@material-ui/core'
import { Fade } from '@material-ui/core'
import Close from '@material-ui/icons/CloseRounded';

const BorderLinearProgress = withStyles(theme => ({
	root: {
	  height: 5,
      borderRadius: 5
	},
	colorPrimary: {
	  backgroundColor:
		theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
	},
	bar: {
	  borderRadius: 5,
	}
  }))(LinearProgress);

const Toggle = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-color: ${ Color.PRIMARY };
  background-color: ${ (props) => props.complete ? Color.PRIMARY : 'none' };
`

const TaskBody = styled.div`
  margin: 7px 2px 2px 2px;
`

class Overview extends Component {
    constructor (props) {
        super(props)
		this.state = {
            mounted: false,
            mounting: false,
            cards: [],
            history: [],
            sessions: [],
		}
    }


    componentDidUpdate(prevProps) {
        if (this.props.auth.user && !this.state.mounted && !this.state.mounting) {
            this.setState({ mounting: true })

            let req = getAuthRequest()
            req.params = { user_id: this.props.auth.user._id, time : { $gte: localStart(new Date()), $lt: localEnd(new Date()) }}
            api.getSessions(req).then((response) => {
                this.setState({ sessions: response.data })
                req.params = { user_id: this.props.auth.user._id } 
                return api.getWeighins(req)
            }).then((response) => {
                this.setState({ history: response.data })
                this.mountState()
            }).catch((error) => {
                this.setState({ mounting: false, mounted: true })
            })
        }
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    mountState = () => {
        let removed = localStorage.getItem('removed-tasks')
        if (removed) removed = removed.split(',')
        const cards = [
            {
                head: 'Join The Community',
                description: 'Meet like-minded people in our STNBLY Facebook Group',
                onClick: () => this.openInNewTab('https://www.facebook.com/groups/30bootcamp'),
                visible: this.handleJoinCommunityVisible() && false,
                optional: true,
                completed: this.handleJoinCommunityComplete()
            },
            {
                head: 'Phone Number',
                description: 'Enter your phone number for easier communication and guidance',
                onClick: () => this.props.history.push('/dashboard/profile/settings'),
                visible: this.handlePhoneNumberVisible(),
                optional: true,
                completed: this.handlePhoneNumberComplete(),
                id: 1
            },
            {
                head: 'Add Icon to Homescreen',
                description: 'Add a homescreen icon for easy access to STNBLY',
                onClick: undefined,
                visible: this.handleSaveHomeScreenVisible(),
                optional: true,
                completed: this.handleSaveHomeScreenComplete(),
                id: 2
            },
            {
                head: 'Recalibrate Tomorrow',
                description: 'Please remember to get a dry weight and waist',
                onClick: () => this.props.history.push({ pathname: '/dashboard/profile/stats'}),
                visible: this.handleRecalibrationTomorrowVisible(),
                optional: true,
                completed: this.handleRecalibrationTomorrowComplete(),
                id: 3
            },
            {
                head: 'Recalibrate Today',
                description: 'Add your dry weight and waist to keep up your progress',
                onClick: () => this.props.history.push({ pathname: '/dashboard/profile/stats', state: { recalibrationOpen: true }}),
                visible: this.handleRecalibrationTodayVisible(),
                optional: false,
                completed: this.handleRecalibrationTodayComplete(),
                id: 4
            },
            {
                head: 'Waist Size',
                description: 'Add your waist measurement to start tracking inches',
                onClick: () => this.props.history.push({ pathname: '/dashboard/profile/stats', state: { weightInOpen: true }}),
                visible: this.handleWaistSizeVisible(),
                optional: false,
                completed: this.handleWaistSizeComplete(),
                id: 5
            },
            {
                head: 'Workout Today',
                description: 'You have a workout scheduled today, tap to get started',
                onClick: () => this.props.history.push({ pathname: '/dashboard/fitness', state: { tab: 1 }}),
                visible: this.handleWorkoutTodayVisible() && false, 
                optional: false,
                completed: this.handleWorkoutTodayComplete()
            },
            {
                head: 'Start Tracking',
                description: 'Enter your nutrition information, tap to get started',
                onClick: () => this.props.history.push('/dashboard/nutrition'),
                visible: this.handleStartTrackingVisible(),
                optional: false,
                completed: this.handleStartTrackingComplete(),
                id: 6
            },
            {
                head: 'Walking Today',
                description: 'Get your movement in for today, tap to get started',
                onClick: () => this.props.history.push({ pathname: '/dashboard/fitness', state: { tab: 1 }}),
                visible: this.handleWalkingTodayVisible() && false,
                optional: false,
                completed: this.handleWalkingTodayComplete()
            }, 
            {
                head: 'Date of Birth',
                description: 'Let us know your birth date to help us improve your macro-tuning',
                onClick: () => this.props.history.push('/dashboard/profile/settings'),
                visible: this.handleBirthDateVisible(),
                optional: true,
                completed: this.handleBirthDateComplete(),
                id: 7
            }, 
            {
                head: 'Add Profile Picture',
                description: 'Personalize your profile by updating your avatar with a picture',
                onClick: () => this.props.history.push('/dashboard/profile'),
                visible: this.handleAvatarVisible(),
                optional: true,
                completed: this.handleAvatarComplete(),
                id: 8
            },
            {
                head: 'Facebook',
                description: 'Add Alex (Head of Success)',
                visible: true,
                optional: true,
                completed: false,
                id: 9
            }
        ]

        this.setState({ 
            mounted: true,
            mounting: false,
            cards: cards.filter((card) => removed ? !removed.includes(String(card.id || '')) : true) 
        })
    }

    // Helper Functions
    getPlan = (date) => {
        for (let i = 0; i < this.props.auth.user.curr.days.length; i++) {
            let planDate = new Date(this.props.auth.user.curr.days[i].date), localDate = new Date(timeToLocal(date))
            if (planDate.getDate() === localDate.getDate()) return this.props.auth.user.curr.days[i]
        }

        return null
    }

    handleClickEvent = (event) => {
        event.preventDefault()
    }

    // Accessibility Wrappers
    onClickWrapper = (handleClick, component) => {
        return (    
            <div onClick={handleClick ? (event) => { 
                this.handleClickEvent(event)
                return handleClick() 
            } : null}>
                { component }
            </div>
        )
    }
    

    // Visibility Handlers
    handleJoinCommunityVisible = () => {
        return true
    }

    handlePhoneNumberVisible = () => {
        return true
    }

    handleSaveHomeScreenVisible = () => {
        return true
    }

    handleRecalibrationTomorrowVisible = () => {
        let difference = (this.props.auth.user.curr.recalibration_date - timeToLocal(new Date())) / (1000*60*60*24)
        return difference <= 1 && difference > 0
    }

    handleRecalibrationTodayVisible = () => {
        let difference = (this.props.auth.user.curr.recalibration_date - timeToLocal(new Date())) / (1000*60*60*24)
        return difference <= 0 && this.props.auth.user.curr.recalibration_date
    }

    handleWaistSizeVisible = () => {
        return true
    }

    handleWorkoutTodayVisible = () => {
        let day = this.getPlan(new Date())
        return day ? (day.fitness.length ? true : false) : false
    }

    handleStartTrackingVisible = () => {
        return true
    }

    handleWalkingTodayVisible = () => {
        let day = this.getPlan(new Date())
        return day ? (day.activities.length ? true : false) : false
    }

    handleBirthDateVisible = () => {
        return true
    }
 


    // Completion Handlers
    handleJoinCommunityComplete = () => {
        return false
    }

    handlePhoneNumberComplete = () => {
        return this.props.auth.user.phone_number ? true : false
    }

    handleSaveHomeScreenComplete = () => {
        return false
    }

    handleRecalibrationTomorrowComplete = () => {
        return false
    }

    handleRecalibrationTodayComplete = () => {
        return false
    }

    handleWaistSizeComplete = () => {
        for (let i = 0; i < this.state.history.length; i++) {
            if (this.state.history[i].waist_size) return true
        }
        return false
    }

    handleWorkoutTodayComplete = () => {
        let day = this.getPlan(new Date()), sessions = this.state.sessions.filter((session) => session.type === 'workout')
        if (!day || !day.fitness.length) return false
        let mapping = day.fitness.map((exercise) => {
            for (let i = 0; i < sessions.length; i++) {
                if (sessions[i].target_id === exercise.workout_id) return true
            }

            return false
        })
        
        return mapping.every(entry => entry ? true : false)
    }

    handleStartTrackingComplete = () => {
        return this.state.sessions.filter((session) => session.type === 'food').length ? true : false 
    }

    handleWalkingTodayComplete = () => {
        let day = this.getPlan(new Date()), sessions = this.state.sessions.filter((session) => session.type === 'activity')
        if (!day || !day.activities.length) return false
        let mapping = day.activities.map((activity) => {
            for (let i = 0; i < sessions.length; i++) {
                if (sessions[i].target_id === activity.activity_id) return true
            }

            return false
        })
        
        return mapping.every(entry => entry ? true : false)
    }

    handleBirthDateComplete = () => {
        return this.props.auth.user.dob ? true : false
    }

    handleAvatarVisible = () => {
        return true
    }

    handleAvatarComplete = () => {
        return this.props.auth.user.avatar ? true : false
    }

    handleClick = (event, onClick) => {
        event.preventDefault()
        if (onClick) onClick()
    }

    // Render Functions
    renderProgress = () => {
        let percentage = Math.round((this.state.cards.filter((card) => card.completed && card.visible).length / this.state.cards.filter((card) => card.visible).length) * 100) || 0
        return (
            <div style={{ margin: '20px 0px 0px 0px', display: 'flex', verticalAlign: 'center', position: 'relative', alignItems: 'center'}}>
                <h6 style={{ flexGrow: '4', marginBottom: '0px'}}> Tasks </h6>
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <BorderLinearProgress variant= {'determinate'} value={percentage} style={{ width: '100px', flexGrow: '1' }}/>
                    <p style={{ flexGrow: '1', textAlign: 'center', fontSize: '12px', width: '20px', color: Color.PRIMARY, margin: '0px 0px 0px 10px' }}> {`${percentage}%`} </p>
                </div>
            </div>
        )
    }

    removeTask = (e, id) => {
        e.stopPropagation()
        e.preventDefault()
        let removed = localStorage.getItem('removed-tasks')
        if (removed) removed += `,${id}`
        else removed = id
        localStorage.setItem('removed-tasks', removed)
        this.mountState()
    }

    renderCards = (max) => {
        let cards = this.state.cards.filter((card, i) => card.visible)
        max = max + 1
        cards.sort((a, b) => a.completed - b.completed || a.optional - b.optional)

        return (
            <div>
                <CardFloat style={{ marginBottom: '10px', marginTop: !this.props.cards ? '0px' : null, ...this.props.style }}>
                    <CardContent style={{ height: this.props.cards ? '175px' : null, minHeight: '175px' }}>
                        <div style={{ display: 'flex' }}>
                            <h6 style={{ fontSize: '14px' }}> Tasks </h6>
                            { cards.length > max ? <h6 style={{ marginLeft: 'auto', fontSize: '14px', color: Color.PRIMARY }}> See All </h6> : null }
                        </div>
                        <TaskBody mounted={cards.length && !this.state.mounting && this.state.mounted}>
                            <Fade in={cards.length}>
                                <div>
                                    {
                                        cards.map((card, i) => {
                                            if (!max || i < max - 1) {
                                                return (
                                                    <div key={i}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '14px' }} onClick={(event) => this.handleClick(event, card.onClick)}>
                                                            <div>
                                                                <Toggle complete={card.completed}/>
                                                            </div>
                                                            <div style={{ position: 'relative', width: 'calc(100% - 80px)' }}>
                                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px'}}>
                                                                    <h6 style={{ fontSize: '14px', marginBottom: '0px'}}> { card.head } {/* card.optional ? <i style={{ opacity: '.5', fontSize: '12px'}}> (Optional) </i> : null */} </h6>
                                                                </div>
                                                                <p style={{ fontSize: '12px', opacity: '.7', marginBottom: '0px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}> { card.description } </p>
                                                            </div>
                                                            <div onClick={(e) => this.removeTask(e, card.id)} style={{ width: '26px', height: '26px', borderRadius: '50%', backgroundColor: Color.CALORIES_ERROR + '30', flexShrink: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <Close style={{ color: Color.CALORIES_ERROR, fontSize: '20px' }}/>
                                                            </div>
                                                        </div>
                                                        { i < cards.length - 1 && (!max || i < max - 2) ? <div style={{ height: '1px', borderTop: '1px solid', margin: '15px 20px 15px 20px', opacity: '.1' }}/> : null }
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </Fade>
                        </TaskBody>

                        { !this.state.mounting && this.state.mounted && !cards.length ? <h6 style={{ opacity: '.7', textAlign: 'center', marginBottom: '0px', fontSize: '14px', margin: '45px 10px 10px 10px' }}> You're all caught up! </h6> : null }
                        { this.state.mounting || !this.state.mounted ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}> <CircularProgress size={25}/> </div>  : null }
                    </CardContent>
                </CardFloat>
            </div>
        )
    }

    // Return Render
    render() {
        if (this.props.cards) return this.renderCards(this.props.maxCards)
        return (
            <div>
                <StickyHeader>
                    <h5 style={{ marginBottom: '0px' }}> Overview </h5>
                </StickyHeader>
                <PageCard title={'Overview'} style={{ paddingTop: '0px' }}>
                    { this.renderCards() }
                </PageCard>
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

export default connect(mapStateToProps)(withRouter(Overview))