//Classes and Functions
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
//Components and Pages
import { CardContent, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { CardFloat } from './'
//Images
import { Color, Threshold, getAttribute } from '../util'

const BorderCircularProgress = withStyles(theme => ({
    circle: { strokeLinecap: "round" }
}))(CircularProgress);

function NutritionInfo(props) {
    const [tab, setTab] = useState(props.add ? 1 : 0)

    useEffect(() => {
        setTab(props.add ? 1 : 0)
    }, [props.add])

    const getMetaData = (activeStat, percent, additive) => {
        let calories = 0, sessions = props.dash.sessions.filter((session) => session.type === 'food')
        additive = getAttribute(activeStat, additive, { metadata: { serving_unit: props.servingType, servings: props.servings }})
        if (additive) calories += additive

        for (let index in sessions) {
            let session = sessions[index], date = new Date(session.time), food = session.target
            if (food && date.getDate() === (!props.today && props.trackingTime ? props.trackingTime.getDate() : props.time.getDate())) calories += Math.round(getAttribute(activeStat, food, session))
        }

        if (percent) return (props.sampleCurr ? props.sampleCurr[activeStat] + (additive ? additive : 0) : calories) / (props.sampleMacros ? props.sampleMacros[activeStat] : props.auth.user.settings.macros[activeStat]) * 100
        return props.sampleCurr ? props.sampleCurr[activeStat] : calories
    }

    const getColor = (activeStat) => {
        let additive = undefined

        if (props.food) {
            additive = props.food
        }

        if (props.auth.user && ((tab !== 0 || !props.food))) {
            if (getMetaData(activeStat, true, additive) > 100 - Threshold[activeStat.toUpperCase()] && getMetaData(activeStat, true, additive) < 100 + Threshold[activeStat.toUpperCase()]) {
                return Color.CALORIES_SUCCESS
            } else if (getMetaData(activeStat, true, additive) > 100 + Threshold[activeStat.toUpperCase()]) {
                return Color.CALORIES_ERROR
            }
        }
        
        return Color[activeStat.toUpperCase()]
    }

    const getBackgroundColor = (activeStat) => {
        let additive = undefined

        if (props.food) {
            additive = props.food
        }

        if (props.auth.user && ((tab !== 0 || !props.food))) {
            if (getMetaData(activeStat, true, additive) > 100 - Threshold[activeStat.toUpperCase()] && getMetaData(activeStat, true, additive) < 100 + Threshold[activeStat.toUpperCase()]) {
                return 'rgba(0, 200, 83, .3)'
            } else if (getMetaData(activeStat, true, additive) > 100 + Threshold[activeStat.toUpperCase()]) {
                return 'rgba(221, 44, 0, .3)'
            }
        }

        if (activeStat === 'carbs') {
            return 'rgba(77, 208, 225, .3)'
        } else if (activeStat === 'fat') {
            return 'rgba(186, 104, 200, .3)'
        } else if (activeStat === 'protein') {
            return 'rgba(255, 138, 101, .3)'
        } else {
            return 'rgba(121, 134, 203, .3)'
        }
    }

    const getProjectedColor = (activeStat) => {
        let additive = undefined

        if (props.food) {
            additive = props.food
        }

        if (props.auth.user && ((tab !== 0 || !props.food))) {
            if (getMetaData(activeStat, true, additive) > 100 - Threshold[activeStat.toUpperCase()] && getMetaData(activeStat, true, additive) < 100 + Threshold[activeStat.toUpperCase()]) {
                return 'rgba(0, 200, 83, .7)'
            } else if (getMetaData(activeStat, true, additive) > 100 + Threshold[activeStat.toUpperCase()]) {
                return 'rgba(221, 44, 0, .5)'
            }
        }

        if (activeStat === 'carbs') {
            return 'rgba(77, 208, 225, .5)'
        } else if (activeStat === 'fat') {
            return 'rgba(186, 104, 200, .5)'
        } else if (activeStat === 'protein') {
            return 'rgba(255, 138, 101, .5)'
        } else {
            return 'rgba(121, 134, 203, .5)'
        }
    }

    const getLabel = (activeStat) => {
        return `+${getAttribute(activeStat, props.food, { metadata: { serving_unit: props.servingType, servings: props.servings }})}`
    }

    return (
        props.mini ? <CardFloat style={{ marginBottom: '10px' }}>
            <CardContent style={{ height: '143px'}}>
                <div style={{ backgroundColor: getBackgroundColor('calories'), fontSize: '0px', borderRadius: '50%', position: 'absolute', top: '20px', left: '20px' }}>
                    <BorderCircularProgress variant='determinate' size={50} value={!props.food ? (props.auth.user || props.preview ? (getMetaData("calories", true) > 100 ? 100 : getMetaData("calories", true)) : 0) : 0} style={{ color: getColor('calories') }}/>
                    <h6 style={{ color: getColor('calories'), position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)', fontSize: '12px' }}> {getMetaData('calories')} </h6>
                </div>
                { !props.simple_macros ? <div style={{ backgroundColor: getBackgroundColor('carbs'), fontSize: '0px', borderRadius: '50%', position: 'absolute', top: '20px', right: '20px' }}>
                    <BorderCircularProgress variant='determinate' size={50} value={!props.food ? (props.auth.user || props.preview ? (getMetaData("carbs", true) > 100 ? 100 : getMetaData("carbs", true)) : 0) : 0} style={{ color: getColor('carbs') }}/>
                    <h6 style={{ color: getColor('carbs'), position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)', fontSize: '12px' }}> {getMetaData('carbs')} </h6>
                </div> : null }
                <div style={{ backgroundColor: getBackgroundColor('protein'), fontSize: '0px', borderRadius: '50%', position: 'absolute', bottom: '20px', left: '20px' }}>
                    <BorderCircularProgress variant='determinate' size={50} value={!props.food ? (props.auth.user || props.preview ? (getMetaData("protein", true) > 100 ? 100 : getMetaData("protein", true)) : 0) : 0} style={{ color: getColor('protein') }}/>
                    <h6 style={{ color: getColor('protein'), position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)', fontSize: '12px' }}> {getMetaData('protein')} </h6>
                </div>
                { !props.simple_macros ? <div style={{ backgroundColor: getBackgroundColor('fat'), fontSize: '0px', borderRadius: '50%', position: 'absolute', bottom: '20px', right: '20px' }}>
                    <BorderCircularProgress variant='determinate' size={50} value={!props.food ? (props.auth.user || props.preview ? (getMetaData("fat", true) > 100 ? 100 : getMetaData("fat", true)) : 0) : 0} style={{ color: getColor('fat') }}/>
                    <h6 style={{ color: getColor('fat'), position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)', fontSize: '12px' }}> {getMetaData('fat')} </h6>
                </div> : null }
            </CardContent>
        </CardFloat> : <CardFloat style={{ ...props.style }}>
            <CardContent style={{ display: 'flex', alignItems: 'center', height: '120px', paddingTop: '24px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontSize: '0px', width: '0', flex: '1' }}>
                    <div style={{ position: 'relative', width: '', backgroundColor: getBackgroundColor('calories'), borderRadius: '50%', width: '50px', height: '50px' }}>
                        <BorderCircularProgress variant='determinate' size={50} value={(props.auth.user || props.preview ? (getMetaData("calories", true) > 100 ? 100 : getMetaData("calories", true)) : 0)} style={{ color: getColor('calories') }}/>
                        { tab === 1 && props.food ? <BorderCircularProgress variant='determinate' size={50} value={(props.auth.user || props.preview ? (getMetaData("calories", true, props.food) > 100 ? 100 : getMetaData("calories", true, props.food)) : 0)} style={{ color: getProjectedColor('calories'), position: 'absolute', left: '0px' }}/> : null }
                        <h6 style={{ color: getColor('calories'), position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)', fontSize: '12px' }}> {getMetaData('calories')} </h6>
                    </div>
                    <p style={{ color: getColor('calories'), fontSize: '14px', margin: '8px 0px 0px 0px' }}> Calories </p>
                    { tab === 1 && props.food ? <p style={{ position: 'absolute', bottom: '10px', marginBottom: '0px', fontSize: '12px', color: getColor('calories'), opacity: '.7', marginRight: '5px' }}> {getLabel('calories')} </p> :
                    <p style={{ position: 'absolute', bottom: '10px', marginBottom: '0px', fontSize: '12px', color: getColor('calories'), opacity: '.7' }}> {props.sampleCurr ? props.sampleCurr.calories : props.auth.user.settings.macros.calories} </p>  }                                    
                </div>
                { !props.simple_macros ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontSize: '0px', width: '0', flex: '1' }}>
                    <div style={{ backgroundColor: getBackgroundColor('carbs'), fontSize: '0px', borderRadius: '50%', position: 'relative', width: '50px', height: '50px'  }}>
                        <BorderCircularProgress variant='determinate' size={50} value={(props.auth.user || props.preview ? (getMetaData("carbs", true) > 100 ? 100 : getMetaData("carbs", true)) : 0)} style={{ color: getColor('carbs') }}/>
                        { tab === 1 && props.food ? <BorderCircularProgress variant='determinate' size={50} value={(props.auth.user || props.preview ? (getMetaData("carbs", true, props.food) > 100 ? 100 : getMetaData("carbs", true, props.food)) : 0)} style={{ color: getProjectedColor('carbs'), position: 'absolute', left: '0px' }}/> : null }
                        <h6 style={{ color: getColor('carbs'), position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)', fontSize: '12px' }}> {getMetaData('carbs')} </h6>
                    </div>
                    <p style={{ color:getColor('carbs'), fontSize: '14px', margin: '8px 0px 0px 0px' }}> Carbs </p>
                    { tab === 1 && props.food ? <p style={{ position: 'absolute', bottom: '10px', marginBottom: '0px', fontSize: '12px', color: getColor('carbs'), opacity: '.7', marginRight: '5px' }}> {getLabel('carbs')} </p> : 
                    <p style={{ position: 'absolute', bottom: '10px', marginBottom: '0px', fontSize: '12px', color: getColor('carbs'), opacity: '.7' }}> {props.sampleCurr ? props.sampleCurr.carbs : props.auth.user.settings.macros.carbs}g </p>  }
                </div> : null }
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontSize: '0px', width: '0', flex: '1' }}>
                    <div style={{ backgroundColor: getBackgroundColor('protein'), fontSize: '0px', borderRadius: '50%', position: 'relative', width: '50px', height: '50px' }}>
                        <BorderCircularProgress variant='determinate' size={50} value={(props.auth.user || props.preview ? (getMetaData("protein", true) > 100 ? 100 : getMetaData("protein", true)) : 0)} style={{ color: getColor('protein') }}/>
                        { tab === 1 && props.food ? <BorderCircularProgress variant='determinate' size={50} value={(props.auth.user || props.preview ? (getMetaData("protein", true, props.food) > 100 ? 100 : getMetaData("protein", true, props.food)) : 0)} style={{ color: getProjectedColor('protein'), position: 'absolute', left: '0px' }}/> : null }
                        <h6 style={{ color: getColor('protein'), position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)', fontSize: '12px' }}> {getMetaData('protein')} </h6>
                    </div>
                    <p style={{ color: getColor('protein'), fontSize: '14px', margin: '8px 0px 0px 0px' }}> Protein </p>
                    { tab === 1 && props.food ? <p style={{ position: 'absolute', bottom: '10px', marginBottom: '0px', fontSize: '12px', color: getColor('protein'), opacity: '.7', marginRight: '5px' }}> {getLabel('protein')} </p> : 
                    <p style={{ position: 'absolute', bottom: '10px', marginBottom: '0px', fontSize: '12px', color: getColor('protein'), opacity: '.7' }}> {props.sampleCurr ? props.sampleCurr.protein : props.auth.user.settings.macros.protein}g </p>  }               
                </div>
                { !props.simple_macros ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontSize: '0px', width: '0', flex: '1' }}>
                    <div style={{ backgroundColor: getBackgroundColor('fat'), fontSize: '0px', borderRadius: '50%', position: 'relative', width: '50px', height: '50px' }}>
                        <BorderCircularProgress variant='determinate' size={50} value={(props.auth.user || props.preview ? (getMetaData("fat", true) > 100 ? 100 : getMetaData("fat", true)) : 0)} style={{ color: getColor('fat') }}/>
                        { tab === 1 && props.food ? <BorderCircularProgress variant='determinate' size={50} value={(props.auth.user || props.preview ? (getMetaData("fat", true, props.food) > 100 ? 100 : getMetaData("fat", true, props.food)) : 0)} style={{ color: getProjectedColor('fat'), position: 'absolute', left: '0px' }}/> : null }
                        <h6 style={{ color: getColor('fat'), position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)', fontSize: '12px' }}> {getMetaData('fat')} </h6>
                    </div>
                    <p style={{ color: getColor('fat'), fontSize: '14px', margin: '8px 0px 0px 0px' }}> Fat </p>
                    { tab === 1 && props.food ? <p style={{ position: 'absolute', bottom: '10px', marginBottom: '0px', fontSize: '12px', color: getColor('fat'), marginRight: '5px', opacity: '.7' }}> {getLabel('fat')} </p> :
                    <p style={{ position: 'absolute', bottom: '10px', marginBottom: '0px', fontSize: '12px', color: getColor('fat'), opacity: '.7' }}> {props.sampleCurr ? props.sampleCurr.fat : props.auth.user.settings.macros.fat}g </p>  }   
                </div> : null }
            </CardContent>
        </CardFloat>
    )
}

function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

export default connect(mapStateToProps)(withRouter(NutritionInfo))