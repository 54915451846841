import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createPreference, updatePreference, deletePreference } from '../actions/dashboardActions'
import { CircularProgress, CardContent, Card } from '@material-ui/core'
import { Modal, CardFloat, Chip, HorizontalScroll } from './'
import { Color } from '../util'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import ThumbDown from '@material-ui/icons/ThumbDownAltRounded'
import ThumbUp from '@material-ui/icons/ThumbUpAltRounded'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';

class FitnessInfoModal extends Component {
    handleFavorite = (food) => {
        let preferences = this.props.dash.preferences.filter((preference) => preference.target_id === food._id)
        if (preferences.length) return this.props.updatePreference(preferences[0]._id, { ...preferences[0], exclusion: false })
        return this.props.createPreference({ type: 'workout', target_class: food.workoutClass ? food.workoutClass : 'workout', target_id: food._id, user_id: this.props.auth.user._id, exclusion: false }, food)}

    handleUnfavorite = (food) => {
        let preferences = this.props.dash.preferences.filter((preference) => preference.target_id === food._id)
        if (preferences.length) this.props.deletePreference(preferences[0]._id)
    }

    handleExclude = (food) => {
        let preferences = this.props.dash.preferences.filter((preference) => preference.target_id === food._id)
        if (preferences.length) return this.props.updatePreference(preferences[0]._id, { ...preferences[0], exclusion: true })
        return this.props.createPreference({ type: 'workout', target_class: food.workoutClass ? food.workoutClass : 'workout', target_id: food._id, user_id: this.props.auth.user._id, exclusion: true }, food)
    }

    handleUnexclude = (food) => {
        let preferences = this.props.dash.preferences.filter((preference) => preference.target_id === food._id)
        if (preferences.length) this.props.deletePreference(preferences[0]._id)
    }

    renderFavoritesButton = (workout) => {
        if (this.props.auth.user && workout && workout._id) {
            if (this.props.dash.preferences && this.props.dash.preferences.filter((preference) => preference.target_id === workout._id && preference.exclusion === false).length > 0) {
                return (
                    <ThumbUp style={{ marginBottom: '50%', fontSize: '20px', color: 'white', backgroundColor: Color.PRIMARY }} onClick={() => this.handleUnfavorite(this.props.infoWorkout)}/>
                )
            }
    
            return (
                <ThumbUp style={{ marginBottom: '50%', color: 'white', fontSize: '20px' }} onClick={() => this.handleFavorite(this.props.infoWorkout)}/>
            )
        }
    }

    renderExclusionsButton = (workout) => {
        if (this.props.auth.user && workout && workout._id) {
            if (this.props.dash.preferences && this.props.dash.preferences.filter((preference) => preference.target_id === workout._id && preference.exclusion === true).length > 0) {
                return (
                    <ThumbDown style={{ marginBottom: '50%', fontSize: '20px', color: 'white', backgroundColor: Color.CARBS_ERROR }} onClick={() => this.handleUnexclude(this.props.infoWorkout)}/>
                )
            }
    
            return (
                <ThumbDown style={{ marginBottom: '50%', color: 'white', fontSize: '20px'}} onClick={() => this.handleExclude(this.props.infoWorkout)}/>
            )
        }
    }

    renderInstructions = (description) => {
        return description.split('\n').map((element, i) => {
            return <li key={i}> {element} </li>
        })
    }

    renderDifficulty = (difficulty) => {
        return (difficulty === 'Advanced' ? 3 : (difficulty === 'Intermediate' ? 2 : 1))
    }

    renderDescription = (instructions) => {
        if (!instructions) return null
        let temp = instructions, list = []
        try {
            let instr = temp.split('\n')
            for (let element in instr) {
                let instruction = instr[element]
                list.push(
                    <div key={element} style={{ display: 'flex', alignItems: 'center', padding: '0px 10px 0px 10px' }}>
                        <h6 style={{ color: Color.PRIMARY, marginRight: '20px', marginBottom: '0px' }}> {Number(element) + 1}. </h6>
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px'}}>
                                <h6 style={{ fontSize: '14px', marginBottom: '0px'}}> {instruction} </h6>
                            </div>
                        </div>
                    </div>
                )
            }
        } catch (error) {
            console.log(error)
        }
        
        return (
            <div style={{ position: 'relative' }}>
                {
                    list.map((item, i) => {
                        return (
                            <div key={i}>
                                { item }
                                { i < list.length - 1 ? <div style={{ height: '1px', borderTop: '1px solid', margin: '15px 20px 15px 20px', opacity: '.1' }}/> : null }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    render() {
        return (
            <Modal head={this.props.infoWorkout ? this.props.infoWorkout.name : null} video={this.props.infoWorkout ? this.props.infoWorkout.video_url : null} direction='up' in={this.props.open} handleClose={this.props.handleClose} functions={[
                this.renderExclusionsButton(this.props.infoWorkout),
                this.renderFavoritesButton(this.props.infoWorkout),
                this.props.infoSession ? <EditIcon style={{ marginBottom: '50%', fontSize: '20px', color: 'white' }} onClick={(event) => this.props.handleUpdateOpen({ event: event, session: this.props.infoSession, session_id: this.props.infoSession_id, workout: this.props.infoWorkout })}/> : null,
            ]}>
                { this.props.infoWorkout ? 
                    <div> 
                        <CardFloat>
                            <CardContent>
                                <div style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '14px' }}> Main Muscle Groups </h6>
                                    <div style={{ display: 'flex', position: 'relative' }}>
                                        <div style={{ position: 'absolute', left: '0px', height: '100%', width: '8px', zIndex: '2', background: `linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))` }}/>
                                        <div style={{ position: 'absolute', right: '0px', height: '100%', width: '8px', zIndex: '2', background: `linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))` }}/>
                                        <HorizontalScroll>
                                            <div style={{ display: 'flex', marginLeft: '3px', marginRight: '3px' }}>
                                                { this.props.infoWorkout.main_muscle.length ?
                                                    this.props.infoWorkout.main_muscle.map((equipment, i) => {
                                                        return <Chip key={i} style={{ backgroundColor: Color.FAT, border: 'none', color: 'white', marginRight: '5px', marginBottom: '5px' }} label={equipment}/>
                                                    }) : null 
                                                }    
                                            </div>
                                        </HorizontalScroll>
                                    </div>
                                </div>
                                <div style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '14px' }}> Mechanics </h6>
                                    <div style={{ display: 'flex', position: 'relative' }}>
                                        <div style={{ position: 'absolute', left: '0px', height: '100%', width: '8px', zIndex: '2', background: `linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))` }}/>
                                        <div style={{ position: 'absolute', right: '0px', height: '100%', width: '8px', zIndex: '2', background: `linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))` }}/>
                                        <HorizontalScroll>
                                            <div style={{ display: 'flex', marginLeft: '3px', marginRight: '3px' }}>
                                                { this.props.infoWorkout.mechanics && this.props.infoWorkout.mechanics !== null ?
                                                    <Chip style={{ backgroundColor: Color.CALORIES, border: 'none', color: 'white', marginRight: '5px', marginBottom: '5px' }} label={this.props.infoWorkout.mechanics}/>
                                                : null }
                                            </div>
                                        </HorizontalScroll>
                                    </div>
                                </div>

                                <div style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '14px' }}> Equipment </h6>
                                    <div style={{ display: 'flex', position: 'relative' }}>
                                        <div style={{ position: 'absolute', left: '0px', height: '100%', width: '8px', zIndex: '2', background: `linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))` }}/>
                                        <div style={{ position: 'absolute', right: '0px', height: '100%', width: '8px', zIndex: '2', background: `linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))` }}/>
                                        <HorizontalScroll>
                                            <div style={{ display: 'flex', marginLeft: '3px', marginRight: '3px' }}>
                                                { this.props.infoWorkout.equipment.map((equipment, i) => {
                                                        return <Chip key={i} style={{ backgroundColor: Color.CARBS, border: 'none', color: 'white', marginRight: '5px', marginBottom: '5px' }}label={equipment}/>
                                                }) }
                                            </div>
                                        </HorizontalScroll>
                                    </div>
                                </div>
                            </CardContent>
                        </CardFloat>
                        { this.props.infoWorkout.description ? <div>
                            <h6 style={{ marginBottom: '0px' }}> Instructions </h6>
                            <CardFloat outline>
                                <CardContent>
                                    { this.renderDescription(this.props.infoWorkout.description) }
                                </CardContent>
                            </CardFloat>
                        </div> : null }
                    </div> : <CircularProgress/>
                }
            </Modal>
        );
    }
}

function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        createPreference,
        updatePreference,
        deletePreference
     }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(FitnessInfoModal)