import { setSnackbar } from './snackbarActions'
import { dashActionTypes } from '../actions/types'
import { getAuthRequest, getFoodHelper } from '../util'
import axios from 'axios'
import api from '../api'

/*
    General action handlers
*/

export const clearDashboard = () => {
    return ({
        type: dashActionTypes.CLEAR_DASHBOARD
    })
}

/*
    Tutorial action handlers
*/

export const openTutorial = () => {
    return ({
        type: dashActionTypes.TUTORIAL_OPEN
    })
}

export const closeTutorial = () => {
    return ({
        type: dashActionTypes.TUTORIAL_CLOSE
    })
}

/*
    Notification action handlers
*/

export const createNotification = (payload) => {
    return({
        type: dashActionTypes.CREATE_NOTIFICATION,
        payload: payload
    })
}

export const clearNotification = (payload) => {
    return({
        type: dashActionTypes.REMOVE_NOTIFICATION,
        payload: payload
    })
}

/*
    Meal plan action handlers
*/

export const getMealPlan = () => {
    return (async (dispatch) => {
        let req = getAuthRequest()
        dispatch({ type: dashActionTypes.MEALPLAN_LOADING })
        return api.getMealPlan(req).then((response) => {
            dispatch({ type: dashActionTypes.MEALPLAN_LOADED, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: dashActionTypes.MEALPLAN_ERROR })
            throw error
        })
    })
}

export const createMealPlan = (payload) => {
    return (async (dispatch) => {
        let req = getAuthRequest()
        dispatch({ type: dashActionTypes.MEALPLAN_LOADING })
        return api.createMealPlan(payload, req).then((response) => {
            dispatch({ type: dashActionTypes.MEALPLAN_LOADED, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: dashActionTypes.MEALPLAN_ERROR })
            throw error
        })
    })
}

export const swapMealPlanItem = (payload) => {
    return (async (dispatch) => {
        let req = getAuthRequest()
        dispatch({ type: dashActionTypes.MEALPLAN_LOADING })
        return api.swapMealPlanItem(payload, req).then((response) => {
            dispatch({ type: dashActionTypes.MEALPLAN_LOADED, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: dashActionTypes.MEALPLAN_ERROR })
            throw error
        })
    })
}

export const loadActivities = () => {
    return ((dispatch) => {
        let req = getAuthRequest()
        dispatch({ type: dashActionTypes.CUSTOM_LOADING })
        api.getActivities(req).then((response) => {
            dispatch({ type: dashActionTypes.CUSTOM_ACTIVITY_LOAD_SUCCESS, payload: response.data })
        }).catch((error) => {
            dispatch({ type: dashActionTypes.CUSTOM_ACTIVITY_LOAD_ERROR })
        })
    })
}

export const getModules = () => {
    let req = getAuthRequest()
    return ((dispatch) => {
        dispatch({ type: dashActionTypes.MODULE_LOADING })
        api.getModule(req).then((response) => {
            dispatch({ type: dashActionTypes.MODULE_GET_SUCCESS, payload: response.data })
        }).catch((error) => {
            dispatch({ type: dashActionTypes.MODULE_GET_ERROR })
        })
    })
}

/*
    Session action handlers
*/

export const createSession = (payload, target) => {
    let req = getAuthRequest()
    return (async (dispatch) => {
        dispatch({ type: dashActionTypes.SESSION_LOADING })
        return api.createSession(payload, req).then((response) => {
            let payload = response.data
            if (Array.isArray(payload)) {
                for (let index in payload) {
                    let session = response.data[index]
                    for (let element in target) {
                        let targetElement = target[element]
                        if (session.target_id === targetElement._id) payload[index].target = targetElement
                    }
                }
            } else payload = [{ ...payload, target }]
            dispatch({ type: dashActionTypes.SESSION_ADD_SUCCESS, payload })
            return payload
        }).catch((error) => {
            dispatch({ type: dashActionTypes.SESSION_ADD_ERROR })
            throw error
        })
    })
}

export const loadSessions = (sessions) => {
    return (async (dispatch) => {
        if (!sessions.length) return 
        dispatch({ type: dashActionTypes.SESSION_LOADING })
        let ids = { food: {}, workouts: {}, activities: {} }

        sessions.map((session) => {
            switch (session.type) {
                case ('activity') : {
                    ids.activities[session.target_class] = ids.workouts[session.target_class] ? ([...ids.workouts[session.target_class], session.target_id]) : [session.target_id]
                    break
                }

                case ('workout') : {
                    ids.workouts[session.target_class] = ids.workouts[session.target_class] ? ([...ids.workouts[session.target_class], session.target_id]) : [session.target_id]
                    break
                }

                case ('food') : {
                    ids.food[session.target_class] = ids.food[session.target_class] ? ([...ids.food[session.target_class], session.target_id]) : [session.target_id]
                    break
                }

                default : {
                    ids.food[session.target_class] = ids.food[session.target_class] ? ([...ids.food[session.target_class], session.target_id]) : [session.target_id]
                }
            }

            return session
        })

        let entries = Object.entries(ids.food), targets = []

        for (let i = 0; i < entries.length; i++) {
            let entry = entries[i]
            try {
                let target = await getFoodHelper(entry[1], entry[0])
                targets = [...targets, ...target.data]
            } catch (error) {
                dispatch({ type: dashActionTypes.SESSION_LOAD_ERROR })
            }
        }

        entries = Object.entries(ids.workouts)

        for (let i = 0; i < entries.length; i++) {
            let entry = entries[i]
            try {
                let target = await getWorkoutHelper(entry[1], entry[0])
                targets = [...targets, ...target.data]
            } catch (error) {
                dispatch({ type: dashActionTypes.SESSION_LOAD_ERROR })
            }
        }

        entries = Object.entries(ids.activities)

        for (let i = 0; i < entries.length; i++) {
            let entry = entries[i]
            try {
                let target = await getActivityHelper(entry[1], entry[0])
                targets = [...targets, ...target.data]
            } catch (error) {
                dispatch({ type: dashActionTypes.SESSION_LOAD_ERROR })
            }
        }

        dispatch({ type: dashActionTypes.SESSION_LOAD_SUCCESS, payload: targets })
    })
}

export const getSessions = (query) => {
    let req = getAuthRequest()
    req.params = query

    return ((dispatch) => {
        dispatch({ type: dashActionTypes.SESSION_LOADING, payload: { min: query.time.$gte, max: query.time.$lt } })
        api.getSessions(req).then((response) => {
            dispatch({ type: dashActionTypes.SESSION_GET_SUCCESS, payload: response.data })
        }).catch((error) => {
            dispatch({ type: dashActionTypes.SESSION_GET_ERROR })
        })
    })
}

export const addSessions = (payload) => {
    return ((dispatch) => {
        dispatch({ type: dashActionTypes.SESSION_GET_SUCCESS, payload })
    })
}

export const updateSession = (session_id, payload, target) => {
    let req = getAuthRequest()
    req.params = { _id: session_id }

    return (async (dispatch) => {
        dispatch({ type: dashActionTypes.SESSION_LOADING })
        return api.updateSession(payload, req).then((response) => {
            dispatch({ type: dashActionTypes.SESSION_UPDATE_SUCCESS, payload: { ...response.data, target }})
            return { ...response.data, target }
        }).catch((error) => {
            dispatch({ type: dashActionTypes.SESSION_UPDATE_ERROR })
            throw error
        })
    })
}

export const deleteSession = (session_id) => {
    let req = getAuthRequest()
    req.params = { _id: session_id }

    return (async (dispatch) => {
        dispatch({ type: dashActionTypes.SESSION_LOADING })
        return api.deleteSession(req).then((response) => {
            dispatch({ type: dashActionTypes.SESSION_DELETE_SUCCESS, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: dashActionTypes.SESSION_DELETE_ERROR })
            throw error
        })
    })
}

export const getSessionStats = () => {
    let req = getAuthRequest()
    
    return ((dispatch) => {
        dispatch({ type: dashActionTypes.SESSION_STATS_LOADING })
        api.getSessionStats(req).then((response) => {
            dispatch({ type: dashActionTypes.SESSION_STATS_GET_SUCCESS, payload: response.data })
        }).catch((error) => {
            dispatch({ type: dashActionTypes.SESSION_STATS_GET_ERROR })
        })
    })
}

/*
    Weigh-in and history action handlers
*/

export const createWeighin = (payload) => {
    let req = getAuthRequest()
    return(async (dispatch) => {
        dispatch({ type: dashActionTypes.HISTORY_LOADING })
        return api.createWeighin(payload, req).then((response) => {
            dispatch({ type: dashActionTypes.HISTORY_ADD_SUCCESS, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: dashActionTypes.HISTORY_ADD_ERROR })
            throw error
        })
    })
}

export const getHistory = (user_id) => {
    let req = getAuthRequest()
    req.params = { user_id }
    return ((dispatch) => {
        dispatch({ type: 'HISTORY_LOADING'})
        api.getWeighins(req).then((response) => {
            dispatch({
                type: "HISTORY_GET_SUCCESS",
                payload: response.data
            })
        }).catch((error) => {
            dispatch({ type: 'HISTORY_GET_ERROR'})
        })
    })
}

/*
    View action handlers
*/

export const createView = (article_id) => {
    let req = getAuthRequest()
    return((dispatch) => {
        api.createView({ article_id }, req).then((response) => {
            //console.log(response.data)
        }).catch((error) => {
            //console.log(error)
        })
    })
}

/*
    Article action handlers
*/

export const getArticles = (filters, head, limit, module_id, skip) => {
    let req = getAuthRequest()
    req.params = {}
    req.params.sort = JSON.stringify([['created_at', -1]])
    if (head) req.params.select = '-content -author -author_img -author_link'
    if (skip) req.params.skip = skip
    if (limit) req.params.limit = limit
    if (filters) req.params.filters = filters

    return ((dispatch) => {
        dispatch({ type: 'ARTICLE_GET_LOADING' })
        api.getArticle(req).then((response) => {
            dispatch({ type: 'ARTICLE_GET_SUCCESS', payload: { data: response.data, module_id }})
        }).catch((error) => {
            console.log(error)
        })
    })
}

export const createArticle = (article) => {
    let req = getAuthRequest()
    return ((dispatch) => {
        api.createArticle(article, req).then((response) => {
            console.log(response.data)
        }).catch((error) => {
            console.log(error)
        })
    })
}

/*
    Preference action handlers
*/

export const getPreferences = (user_id, sort = "newest") => {
    let req = getAuthRequest()

    return ((dispatch) => {
        dispatch({ type: dashActionTypes.PREFERENCES_LOADING, payload: sort })
        req.params = { user_id: user_id, sort }
        api.getPreference(req).then((response) => {
            dispatch({ type: dashActionTypes.PREFERENCES_GET_SUCCESS, payload: response.data })
        }).catch((error) => {
            dispatch({ type: dashActionTypes.PREFERENCES_GET_ERROR })
        })
    })
}

export const createPreference = (preference, target) => {
    let req = getAuthRequest()

    return ((dispatch) => {
        dispatch({ type: dashActionTypes.PREFERENCES_LOADING })
        api.createPreference(preference, req).then((response) => {
            dispatch({ type: dashActionTypes.PREFERENCES_ADD_SUCCESS, payload: { ...response.data, target }})
            dispatch(setSnackbar({ type: "success", msg: preference.exclusion ? "Added to Exclusions" : "Added to Favorites"}))
        }).catch((error) => {
            dispatch({ type: dashActionTypes.PREFERENCES_ADD_ERROR })
        })
    })
}

export const updatePreference = (_id, updates) => {
    return ((dispatch) => {
        dispatch({ type: dashActionTypes.PREFERENCES_LOADING })
        api.updatePreference(_id, updates, {}).then((response) => {
            dispatch({ type: dashActionTypes.PREFERENCES_UPDATE_SUCCESS, payload: response.data })
            dispatch(setSnackbar({ type: "success", msg: response.data.exclusion ? "Added to Exclusions" : "Added to Favorites"}))
        }).catch((error) => {
            dispatch({ type: dashActionTypes.PREFERENCES_UPDATE_ERROR })
        })
    })
}

export const deletePreference = (_id) => {
    return ((dispatch) => {
        dispatch({ type: dashActionTypes.PREFERENCES_LOADING })
        api.deletePreference(_id, {}).then((response) => {
            dispatch({ type: dashActionTypes.PREFERENCES_DELETE_SUCCESS, payload: _id })
            dispatch(setSnackbar({ type: "success", msg: response.data.exclusion ? "Removed from Exclusions" : "Removed from Favorites" }))
        }).catch((error) => {
            dispatch({ type: dashActionTypes.PREFERENCES_DELETE_ERROR })
        })
    })
}

export const loadPreferences = (preferences) => {
    return (async (dispatch) => {
        dispatch({ type: 'PREFERENCES_LOADING', payload: preferences.map(preference => preference._id)})
        let ids = { food: {}, workouts: {} }

        preferences.map((preference) => {
            switch (preference.type) {
                case ('workout') : {
                    ids.workouts[preference.target_class] = ids.workouts[preference.target_class] ? ([...ids.workouts[preference.target_class], preference.target_id]) : [preference.target_id]
                    break
                }

                case ('food') : {
                    ids.food[preference.target_class] = ids.food[preference.target_class] ? ([...ids.food[preference.target_class], preference.target_id]) : [preference.target_id]
                    break
                }

                default : {
                    ids.food[preference.target_class] = ids.food[preference.target_class] ? ([...ids.food[preference.target_class], preference.target_id]) : [preference.target_id]
                }
            }

            return preference
        })

        let entries = Object.entries(ids.food), targets = []

        for (let i = 0; i < entries.length; i++) {
            let entry = entries[i]
            try {
                let target = await getFoodHelper(entry[1], entry[0])
                targets = [...targets, ...target.data]
            } catch (error) {
                console.log(error.message)
            }
        }

        entries = Object.entries(ids.workouts)

        for (let i = 0; i < entries.length; i++) {
            let entry = entries[i]
            try {
                let target = await getWorkoutHelper(entry[1], entry[0])
                targets = [...targets, ...target.data]
            } catch (error) {
                console.log(error.message)
            }
        }

        dispatch({ type: 'PREFERENCES_LOAD_SUCCESS', payload: targets })
    })
}

/*
    Bug report action handlers
*/

export const createBugReport = (payload) => {
    let req = getAuthRequest()
    return (async (dispatch) => {
        dispatch({ type: 'BUG_REPORT_LOADING' })
        return api.createBugReport(payload, req).then((response) => {
            dispatch({ type: 'BUG_REPORT_SUCCESS' })
            return response.data
        }).catch((error) => {
            dispatch({ type: 'BUG_REPORT_ERROR' })
            throw error
        })
    })
}

export const loadCustomActivities = (user_id) => {
    return ((dispatch) => {
        let req = getAuthRequest()
        req.params = { user_id }
        dispatch({ type: dashActionTypes.CUSTOM_LOADING })
        api.getCustomActivities(req).then((response) => {
            dispatch({ type: dashActionTypes.CUSTOM_ACTIVITY_LOAD_SUCCESS, payload: response.data })
        }).catch((error) => {
            dispatch({ type: dashActionTypes.CUSTOM_ACTIVITY_LOAD_ERROR })
        })
    })
}

export const createCustomActivity = (food) => {
    let req = getAuthRequest()
    return (async (dispatch) => {
        dispatch({ type: dashActionTypes.CUSTOM_LOADING})
        return api.createCustomActivity(food, req).then((response) => {
            dispatch({ type: dashActionTypes.CUSTOM_ACTIVITY_ADD_SUCCESS, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: dashActionTypes.CUSTOM_ACTIVITY_ADD_ERROR })
            throw error
        })
    })
}

export const updateCustomActivity = (user_id, id, payload) => {
    let req = getAuthRequest()
    req.params = { _id: id, user_id }

    return (async (dispatch) => {
        dispatch({ type: dashActionTypes.CUSTOM_LOADING })
        return api.updateCustomActivity(payload, req).then((response) => {
            dispatch({ type: dashActionTypes.CUSTOM_ACTIVITY_UPDATE_SUCCESS, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: dashActionTypes.CUSTOM_ACTIVITY_UPDATE_ERROR })
            throw error
        })
    })
}

export const deleteCustomActivity = (user_id, id) => {
    let req = getAuthRequest()
    req.params = { _id: id, user_id }

    return (async (dispatch) => {
        dispatch({ type: dashActionTypes.CUSTOM_LOADING })
        return api.deleteCustomActivity(req).then((response) => {
            dispatch({ type: dashActionTypes.CUSTOM_ACTIVITY_DELETE_SUCCESS, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: dashActionTypes.CUSTOM_ACTIVITY_DELETE_ERROR })
            throw error
        })
    })
}

export const loadCustom = (user_id, sort) => {
    let req = getAuthRequest()
    req.params = { user_id, sort }
    return ((dispatch) => {
        dispatch({ type: dashActionTypes.CUSTOM_LOADING, payload: sort })
        api.getCustomFoods(req).then((response) => {
            dispatch({ type: dashActionTypes.CUSTOM_FOOD_LOAD_SUCCESS, payload: response.data })
        }).catch((error) => {
            dispatch({ type: dashActionTypes.CUSTOM_FOOD_LOAD_ERROR })
        })
    })
}

export const createCustomFood = (food) => {
    let req = getAuthRequest()
    return (async (dispatch) => {
        dispatch({ type: dashActionTypes.CUSTOM_LOADING})
        return api.createCustomFood(food, req).then((response) => {
            dispatch({ type: dashActionTypes.CUSTOM_FOOD_ADD_SUCCESS, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: dashActionTypes.CUSTOM_FOOD_ADD_ERROR })
            throw error
        })
    })
}

export const updateCustom = (user_id, id, payload) => {
    let req = getAuthRequest()
    req.params = { _id: id, user_id }

    return (async (dispatch) => {
        dispatch({ type: dashActionTypes.CUSTOM_LOADING })
        return api.updateCustomFood(payload, req).then((response) => {
            dispatch({ type: dashActionTypes.CUSTOM_FOOD_UPDATE_SUCCESS, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: dashActionTypes.CUSTOM_FOOD_UPDATE_ERROR })
            throw error
        })
    })
}

export const deleteCustom = (user_id, id) => {
    let req = getAuthRequest()
    req.params = { _id: id, user_id }

    return (async (dispatch) => {
        dispatch({ type: dashActionTypes.CUSTOM_LOADING })
        return api.deleteCustomFood(req).then((response) => {
            dispatch({ type: dashActionTypes.CUSTOM_FOOD_DELETE_SUCCESS, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: dashActionTypes.CUSTOM_FOOD_DELETE_ERROR })
            throw error
        })
    })
}

export const loadCustomWorkouts = (user_id) => {
    return ((dispatch) => {
        let req = getAuthRequest()
        req.params = { user_id }
        dispatch({ type: dashActionTypes.CUSTOM_LOADING })
        api.getCustomWorkouts(req).then((response) => {
            dispatch({ type: dashActionTypes.CUSTOM_WORKOUT_LOAD_SUCCESS, payload: response.data })
        }).catch((error) => {
            dispatch({ type: dashActionTypes.CUSTOM_WORKOUT_LOAD_SUCCESS })
        })
    })
}

export const createCustomWorkout = (payload) => {
    let req = getAuthRequest()
    return (async (dispatch) => {
        dispatch({ type: dashActionTypes.CUSTOM_LOADING })
        return api.createCustomWorkout(payload, req).then((response) => {
            dispatch({ type: dashActionTypes.CUSTOM_WORKOUT_ADD_SUCCESS, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: dashActionTypes.CUSTOM_WORKOUT_ADD_ERROR })
            throw error
        })
    })
}

export const updateCustomWorkout = (user_id, id, payload) => {
    let req = getAuthRequest()
    req.params = { _id: id, user_id }

    return (async (dispatch) => {
        dispatch({ type: dashActionTypes.CUSTOM_LOADING })
        return api.updateCustomWorkout(payload, req).then((response) => {
            dispatch({ type: dashActionTypes.CUSTOM_WORKOUT_UPDATE_SUCCESS, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: dashActionTypes.CUSTOM_WORKOUT_UPDATE_ERROR })
            throw error
        })
    })
}

export const deleteCustomWorkout = (user_id, id) => {
    let req = getAuthRequest()
    req.params = { _id: id, user_id }

    return (async (dispatch) => {
        dispatch({ type: dashActionTypes.CUSTOM_LOADING })
        return api.deleteCustomWorkout(req).then((response) => {
            dispatch({ type: dashActionTypes.CUSTOM_WORKOUT_DELETE_SUCCESS, payload: response.data })
            return response.data
        }).catch((error) => {
            dispatch({ type: dashActionTypes.CUSTOM_WORKOUT_DELETE_ERROR })
            throw error
        })
    })
}

const getActivityHelper = async (payload, target_class) => {
    let req = getAuthRequest()
    req.params = { filters : { _id : { $in : payload }}}

    switch (target_class) {
        case ('Custom') : {
            return api.getCustomActivities(req)
        }

        default : {
            return api.getActivities(req)
        }
    }
}

const getWorkoutHelper = async (payload, target_class) => {
    let req = getAuthRequest()
    req.params = { filters : { _id : { $in : payload }}}

    switch (target_class) {
        case ('Custom') : {
            return api.getCustomWorkouts(req)
        }

        default : {
            return api.getWorkouts(req)
        }
    }
}

export const predictiveMealplan = (macros, settings, options) => {
    return ((dispatch) => {
        let req = getAuthRequest()
        console.log(options)
        dispatch({ type: dashActionTypes.PREDICTIVE_LOADING, payload: { macros, settings, options }})
        api.getPredictive({ macros, settings, options }, req).then((response) => {
            console.log(response.data)
            dispatch({
                type: dashActionTypes.PREDICTIVE_SUCCESS,
                payload: { macros: macros, settings: settings, plan: response.data }
            })
        }).catch((error) => {
            dispatch({
                type: dashActionTypes.PREDICTIVE_ERROR
            })
        })
    })
}

export const searchFoundationFoods = (payload) => {
    return ((dispatch) => {
        dispatch({
            type: dashActionTypes.NUTRITION_SEARCH_LOADING
        })

        /*let req = getAuthRequest()
        let filters = {}

        for (let key in Object.keys(payload.filters)) {
            if (Array.isArray(payload.filters[Object.keys(payload.filters)[key]])) {
                if (payload.filters[Object.keys(payload.filters)[key]].length > 0) {
                    if (Object.keys(payload.filters)[key] === 'Allergies') {
                        filters[Object.keys(payload.filters)[key]] = { $nin : payload.filters[Object.keys(payload.filters)[key]]}
                    } else {
                        filters[Object.keys(payload.filters)[key]] = { $in : payload.filters[Object.keys(payload.filters)[key]]}
                    }
                }
            } else {
                filters[Object.keys(payload.filters)[key]] = payload.filters[Object.keys(payload.filters)[key]]
            }
        }

        filters.description = payload.description*/

        api.getFoundationFoods({ params: payload }).then((response) => {
            dispatch({
                type: dashActionTypes.NUTRITION_SEARCH_SUCCESS,
                payload: response.data
            })
        }).catch((error) => {
            dispatch({
                type: dashActionTypes.NUTRITION_SEARCH_ERROR,
                payload: error.response.data
            })
        })
    })
}

export const searchBrandedFoods = (payload) => {
    return ((dispatch) => {
        dispatch({
            type: dashActionTypes.NUTRITION_SEARCH_LOADING
        })

        let req = getAuthRequest()
        let filters = {}

        for (let key in Object.keys(payload.filters)) {
            if (Array.isArray(payload.filters[Object.keys(payload.filters)[key]])) {
                if (payload.filters[Object.keys(payload.filters)[key]].length > 0) {
                    if (Object.keys(payload.filters)[key] === 'allergies') {
                        filters[Object.keys(payload.filters)[key]] = { $nin : payload.filters[Object.keys(payload.filters)[key]]}
                    } else {
                        filters[Object.keys(payload.filters)[key]] = { $in : payload.filters[Object.keys(payload.filters)[key]]}
                    }
                }
            } else {
                filters[Object.keys(payload.filters)[key]] = payload.filters[Object.keys(payload.filters)[key]]
            }
        }

        filters.description = payload.description

        req.params = {
            limit: 15,
            filters: filters
        }
 
        api.getBrandedFoods(req).then((response) => {
            dispatch({
                type: dashActionTypes.NUTRITION_SEARCH_SUCCESS,
                payload: response.data
            })
        }).catch((error) => {
            dispatch({
                type: dashActionTypes.NUTRITION_SEARCH_ERROR,
                payload: error.response.data
            })
        })
    })
}

// try local cancelToken
let cancelToken = null

export const searchNutrition = (q) => {
    return ((dispatch) => {
        if (cancelToken) cancelToken.cancel()
        cancelToken = axios.CancelToken.source()
        dispatch({ type: dashActionTypes.NUTRITION_SEARCH_LOADING })
        api.searchFoods({ params: { q }, cancelToken: cancelToken.token }).then((response) => {
            if (response) {
                dispatch({
                    type: dashActionTypes.NUTRITION_SEARCH_SUCCESS,
                    payload: response.data
                })
            }
        }).catch((error) => {
            if (!axios.isCancel(error)) {
                dispatch({
                    type: dashActionTypes.NUTRITION_SEARCH_ERROR,
                    payload: error.response ? error.response.data : null
                })
            }
        })
    })
}

export const searchFitness = (payload) => {
    return ((dispatch) => {
        dispatch({ type: dashActionTypes.FITNESS_SEARCH_LOADING })

        let req = getAuthRequest()
        let filters = {}

        for (let key in Object.keys(payload.filters)) {
            if (Array.isArray(payload.filters[Object.keys(payload.filters)[key]])) {
                if (payload.filters[Object.keys(payload.filters)[key]].length > 0) {
                    if (Object.keys(payload.filters)[key] === 'physical_limitations') {
                        filters[Object.keys(payload.filters)[key]] = { $nin : payload.filters[Object.keys(payload.filters)[key]]}
                    } else {
                        filters[Object.keys(payload.filters)[key]] = { $in : payload.filters[Object.keys(payload.filters)[key]]}
                    }
                }
            } else {
                filters[Object.keys(payload.filters)[key]] = payload.filters[Object.keys(payload.filters)[key]]
            }
        }

        req.params = {
            filters: filters,
            limit: 5
        }

        api.getWorkouts(req).then((response) => {
            dispatch({
                type: dashActionTypes.FITNESS_SEARCH_SUCCESS,
                payload: response.data
            })
        }).catch((error) => {
            dispatch({
                type: dashActionTypes.FITNESS_SEARCH_ERROR,
                payload: error.response.data
            })
        })
    })
}

export const loadPlanNutrition = (day) => {
    return ((dispatch) => {
        let ids = []

        for (let time in day) {
            for (let food in day[time].nutrition) {
                if (!("food" in day[time].nutrition[food]) && ids.indexOf(day[time].nutrition[food].food_id) === -1) {
                    ids.push(day[time].nutrition[food].food_id)
                }
            }
        }

        let req = getAuthRequest()
        req.params = {
            _id : { $in : ids }
        }

        if (ids.length > 0) {
            dispatch({
                type: dashActionTypes.PLAN_LOADING,
                payload: day
            })

            api.getFoods(req).then((response) => {
                for (let time in day) {
                    for (let object in response.data) {
                        for (let property in day[time].nutrition){
                            if (day[time].nutrition[property].food_id === response.data[object]._id) {
                                day[time].nutrition[property].food = response.data[object]
                            }
                        }
                    }
                }

                console.log(response.data)

                dispatch({
                    type: dashActionTypes.PLAN_LOAD_SUCCESS,
                    payload: day
                })
            }).catch((error) => {
                dispatch({
                    type: dashActionTypes.PLAN_LOAD_ERROR
                })
            })
        }
    })
}

export const loadPlanFitness = (day) => {
    return (async (dispatch) => {
        let ids = [], cardioIds = []
        for (let time in day) {
            for (let food in day[time].fitness) {
                if (!("workout" in day[time].fitness[food]) && ids.indexOf(day[time].fitness[food].workout_id) === -1) {
                    ids.push(day[time].fitness[food].workout_id)
                }
            }   
            
            for (let food in day[time].activities) {
                if (!("activity" in day[time].activities[food]) && ids.indexOf(day[time].activities[food].activity_id) === -1) {
                    cardioIds.push(day[time].activities[food].activity_id)
                }
            }            
        }


        let activities = []

        try {
            let target = await getActivityHelper(cardioIds)
            activities = [...activities, ...target.data]
        } catch (error) {
            dispatch({ type: dashActionTypes.PLAN_LOAD_ERROR })
        }

        let workouts = []

        try {
            let target = await getWorkoutHelper(ids)
            workouts = [...workouts, ...target.data]
        } catch (error) {
            dispatch({ type: dashActionTypes.PLAN_LOAD_ERROR })
        }

        for (let time in day) {
            for (let object in workouts) {
                for (let property in day[time].fitness){
                    if (day[time].fitness[property].workout_id === workouts[object]._id) {
                        day[time].fitness[property].workout = workouts[object]
                    }
                }
            }

            for (let object in activities) {
                for (let property in day[time].activities){
                    if (day[time].activities[property].activity_id === activities[object]._id) {
                        day[time].activities[property].activity = activities[object]
                    }
                }
            }
        }

        dispatch({ type: dashActionTypes.PLAN_LOAD_SUCCESS, payload: day })
    })
}