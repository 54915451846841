//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
//Components and Pages
import { withRouter } from 'react-router-dom'
import { TextField, MenuItem } from '@material-ui/core';
import { Modal, RadioButtons, Table } from '../components'
import { Color } from '../util'
import api from '../api'
//Images
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh'
import FilterListIcon from '@material-ui/icons/FilterList';

class AdminUsers extends Component {
    constructor (props) {
        super(props)
        let q = new URLSearchParams(this.props.location.search)
		this.state = {
            time: new Date(),
            loading: false,
            filters: {
                email: q.get('email'),
                first_name: q.get('first_name'), 
                last_name: q.get('last_name'),
                admin: q.get('admin'),
                'subscription.status' : q.get('subscription.status'),
                'subscription.cancel_at_end' : q.get('subscription.cancel_at_end')
            },
            sort: JSON.parse(q.get('sort')) || [['created_at', -1]],
            filtersOpen: false,
            users: [],
		}
    }

    componentDidMount() {
        this.timeClock = setInterval(this.tick, 1000)
        this.getUsers()
    }

    getFilter = (filter) => {
        if (filter in this.state.filters) return this.state.filters[filter]
        return ''
    }

    handleFilterChange = (filter, value) => {
        let filters = {...this.state.filters, [filter] : value }
        if (filter === 'subscription.status' && value === 'active') filters['subscription.cancel_at_end'] = false
        else if (filter === 'subscription.status' && value === 'cancelling') {
            filters['subscription.cancel_at_end'] = true
            filters['subscription.status'] = 'active'
        }
        else if (filter === 'subscription.status' && value !== 'active') delete filters['subscription.cancel_at_end']
        if (value === '') delete filters[filter]
        this.setState({ filters })
    }

    handleSetQueryString = () => {
        let q = new URLSearchParams()
        q.set('sort', JSON.stringify(this.state.sort))
        Object.keys(this.state.filters).map((key) => { return (this.state.filters[key] !== null ? q.set(key, this.state.filters[key]) : null) })
        this.props.history.push(this.props.location.pathname + `?${q.toString()}`)
    }

    handleSortChange = (index, key, value) => {
        let sort = this.state.sort
        sort[index][key] = value
        this.setState({ sort }, this.handleSetQueryString)
    }

    handleSortAdd = () => {
        this.setState({ sort: [ ...this.state.sort, [] ] }, this.handleSetQueryString)                
    }

    handleSortRemove = (index) => {
        this.setState({ sort: this.state.sort.filter((item, i) => i !== index) }, this.handleSetQueryString)                
    }

    handleFilterOpen = () => {
        this.setState({ filtersOpen: true })
    }

    handleFilterClear = () => {
        this.setState({ filters: {} }, this.handleSetQueryString)
    }

    handleFilterClose = () => {
        this.handleSetQueryString()
        this.setState({ filtersOpen: false })
        this.handleRefresh()
    }

    getAuthRequest = () => {
        const token = localStorage.getItem('accessToken')
        const req = {
            headers: {
                'Content-type': 'application/json'
            }
        }
    
        if (token) req.headers['x-access-token'] = token
        return req
    }

    getUsers = () => {
        this.setState({ loading: true })
        let req = this.getAuthRequest()
        req.params = {
            sort: JSON.stringify(this.state.sort),
            limit: 100,
            ...this.state.filters
        }

        if (this.state['n/a']) req.params.filters = {'subscription.status': { $ne: null }}
        api.getUserData(req).then((response) => {
            this.setState({ users: response.data, loading: false })
        }).catch((error) => {
            this.setState({ loading: false })
        })
    }

    getTypeColor = (type) => {
        switch(type) {
            case ('active'):
                return Color.CALORIES_SUCCESS
            case ('canceled'):
                return Color.CALORIES_ERROR
            case ('past_due'):
                return Color.PROTEIN
            case ('cancelling') :
                return Color.PROTEIN_ERROR
            default:
                return 'lightgrey'
        }
    }

    handleRefresh = () => {
        this.getUsers()
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') this.handleRefresh()
    }

    componentWillUnmount() { 
        clearInterval(this.timeClock)
        document.body.style.overflow = 'unset';
    }

    tick = () => {
        this.setState({ time: new Date() })
    }

    handleChange = (variable, value) => {
        this.setState({ [variable] : value })
    }

    renderFilters = () => {
        let admin = [true, false]
        let status = [null, 'active', 'past_due', 'canceled', 'cancelling']
        let fields = ['created_at', 'admin', 'subscription.status']
        let direction = [1, -1]

        return (
            <Modal desktop in={this.state.filtersOpen} handleClose={this.handleFilterClose}>
                <h6> Filters </h6>
                <div style={{ padding: '10px' }}>
                    <h6 style={{ fontSize: '14px', marginBottom: '10px', opacity: '.8'}}> Remove N/A </h6> 
                    <RadioButtons value={this.state['n/a']} options={[{ label: 'True', value: true, variable: 'n/a' }, { label: 'False', value: false, variable: 'n/a'}]} handleChange={this.handleChange}/>
                </div>
                <div style={{ padding: '10px' }}>
                    <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Email </h6> 
                    <TextField 
                        style={{ marginBottom: '10px', width: '100%' }}
                        value={this.getFilter('email')} 
                        placeholder="Email"
                        onChange={(event) => this.handleFilterChange('email', event.target.value)}/>
                </div>
                <div style={{ padding: '10px', display: 'inline-block', width: '50%' }}>
                    <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> First name </h6> 
                    <TextField 
                        style={{ marginBottom: '10px', width: '100%' }}
                        value={this.getFilter('first_name')} 
                        placeholder="First Name"
                        onChange={(event) => this.handleFilterChange('first_name', event.target.value)}/>
                </div>
                <div style={{ padding: '10px', display: 'inline-block', width: '50%' }}>
                    <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Last Name </h6> 
                    <TextField 
                        style={{ marginBottom: '10px', width: '100%' }}
                        value={this.getFilter('last_name')} 
                        placeholder="Last Name"
                        onChange={(event) => this.handleFilterChange('last_name', event.target.value)}/>
                </div>
                <div style={{ padding: '10px', display: 'inline-block', width: '50%' }}>
                    <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Status </h6> 
                    <TextField 
                        select
                        style={{ marginBottom: '10px', width: '100%' }}
                        value={this.getFilter('subscription.status')} 
                        placeholder="Status"
                        onChange={(event) => this.handleFilterChange('subscription.status', event.target.value)}>
                        { status.map((portion, i) => {
                            return <MenuItem key={i} value={portion}> { portion ? portion : 'n/a' } </MenuItem>
                        })}
                    </TextField>
                </div>
                <div style={{ padding: '10px', display: 'inline-block', width: '50%' }}>
                    <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Admin </h6> 
                    <TextField 
                        select
                        style={{ marginBottom: '10px', width: '100%' }}
                        value={this.getFilter('admin')} 
                        placeholder="Admin"
                        onChange={(event) => this.handleFilterChange('admin', event.target.value)}>
                        { admin.map((portion, i) => {
                            return <MenuItem key={i} value={portion}> { portion ? 'true' : 'false' } </MenuItem>
                        })}
                    </TextField>
                </div>
                <h6> Sort </h6>
                {
                    this.state.sort.length ? this.state.sort.map((rule, i) => {
                        return (
                            <div style={{ position: 'relative'}}>
                                <p style={{ position: 'absolute', top: '0', right: '0', color: Color.PRIMARY, fontSize: '12px', cursor: 'pointer' }} onClick={() => this.handleSortRemove(i)}> Remove </p>
                                <div style={{ padding: '10px', display: 'inline-block', width: '50%'}}>
                                    <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Field </h6> 
                                    <TextField 
                                        select
                                        style={{ marginBottom: '10px', width: '100%' }}
                                        value={rule[0]} 
                                        placeholder="Admin"
                                        onChange={(event) => this.handleSortChange(i, 0, event.target.value)}>
                                        { fields.map((portion, i) => {
                                            return <MenuItem key={i} value={portion}> { portion } </MenuItem>
                                        })}
                                    </TextField>
                                </div>
                                <div style={{ padding: '10px', display: 'inline-block', width: '50%'}}>
                                    <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Direction </h6> 
                                    <TextField 
                                        select
                                        style={{ marginBottom: '10px', width: '100%' }}
                                        value={rule[1]} 
                                        placeholder="Admin"
                                        onChange={(event) => this.handleSortChange(i, 1, event.target.value)}>
                                        { direction.map((portion, i) => {
                                            return <MenuItem key={i} value={portion}> { portion > 0 ? 'ascending' : 'descending' } </MenuItem>
                                        })}
                                    </TextField>
                                </div>
                            </div>
                        )
                    }) : <p style={{ textAlign: 'center' }}> No Rules </p>
                }
                <div style={{ float: 'right', margin: '10px' }}>
                    <p style={{ color: Color.PRIMARY, cursor: 'pointer', display: 'inline-block', marginRight: '40px' }} onClick={this.handleSortAdd}> Add Rule </p>
                    <p style={{ color: Color.PRIMARY, cursor: 'pointer', display: 'inline-block' }} onClick={this.handleFilterClear}> Clear All </p>
                </div>
            </Modal>
        )
    }

    getStatus = (status, cancelAtEnd) => {
        if (cancelAtEnd && status === 'active') return 'cancelling'
        return status
    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                    <h1> Users </h1>
                    <div style={{ outline: 'none', userSelect: 'none', marginBottom: '20px' }}>
                        <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={this.handleRefresh}> Refresh <RefreshIcon style={{ fontSize: '13px' }}/> </span>
                        <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}}> Create <AddIcon style={{ fontSize: '13px'}}/> </span>
                        <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={this.handleFilterOpen}> Filters <FilterListIcon style={{ fontSize: '13px'}}/> </span>
                    </div>
                    
                    <Table loading = {this.state.loading} handleClick={(entry) => this.props.history.push(this.props.match.path + `/${entry._id}`)} columns = {[
                        { width: 30, name: 'Email'}, 
                        { width: 20, name: 'Name' }, 
                        { width: 15, name: 'Status', render: (status, raw) => { return <span style={{ fontSize: '12px', backgroundColor: this.getTypeColor(this.getStatus(status, raw.subscription.cancel_at_end)), verticalAlign: 'center', color: 'white', borderRadius: '5px', padding: '4px' }}> { this.getStatus(status, raw.subscription.cancel_at_end) || <span style={{ opacity: '.7'}}> n/a </span> } </span> } },
                        { width: 15, name: 'Admin', transform: (admin) => { return admin ? 'true' : 'false' }},
                        { width: 20, name: 'Date Created', transform: (date) => { 
                                let obj = new Date(date)
                                return obj.toLocaleString()
                            }
                        }
                    ]} data = {
                        this.state.users.map((row) => {
                            return [
                                row.email,
                                `${row.first_name} ${row.last_name}`,
                                row.subscription.status,
                                row.admin,
                                row.created_at
                            ]
                        })
                    } raw = {this.state.users} />
                { this.renderFilters() }
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

export default connect(mapStateToProps)(withRouter(AdminUsers))
