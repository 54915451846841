//Classes and Functions
import React, { Component, useEffect, useState } from 'react' 
import { connect } from 'react-redux'
//Components and Pages
import { CardContent, CircularProgress, Grow } from '@material-ui/core';
import { Measure, Color } from '../util'
import { SkeletonCard, CardFloat, Selector } from './'
import Trend from 'react-trend'
import ChipSelector from './ChipSelector';
import { days_pref } from '../util'
import api from '../api'
//Images

const types = [
    {
        value: 'Calories',
        label: 'Calories'
    },
    {
        value: 'Carbs',
        label: 'Carbs'
    },
    {
        value: 'Protein',
        label: 'Protein'
    },
    {
        value: 'Fat',
        label: 'Fat'
    }
]

const NutritionInfographic = (props) => {
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState('Calories')
    const [max, setMax] = useState(false)
    const [info, setInfo] = useState(null)
    const date = (new Date()).getDay() 

    useEffect(() => {
        api.getNutritionSessionStats({
            params: {
                offset: -(new Date()).getTimezoneOffset()
            }
        }).then((response) => {
            const info = response.data
            info.days = rebaseDays(info.days)
            setInfo(response.data)
            setMax(Math.max(...response.data.days.map((day) => day.calories), props.auth.user.settings.macros.calories))
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (!info) return
        setMax(Math.max(...info.days.map((day) => day[type.toLowerCase()]), props.auth.user.settings.macros[type.toLowerCase()]))
    }, [type, info])

    const rebaseDays = (days) => {
        const index = days.findIndex((day) => day._id === date)
        if (index === days.length - 1) return days
        return [...days.slice(index + 1, days.length), ...days.slice(0, index), days[index]]
    }

    const getCurrentDayValue = (date) => {
        if (!info) return 
        let value = null
        info.days.forEach((day) => {
            if (day._id === date) {
                value = getCurrentTypeValue(day)
            }
        })
        return value
    }

    const getCurrentTypeAverage = () => {
        if (!info) return 0
        return info[`avg_${type.toLowerCase()}`]
    }

    const getCurrentTypeValue = (day) => {
        if (!info) return 0
        return day[type.toLowerCase()]
    }
    
    return (
        <CardFloat style={{ ...props.style }}>
            <CardContent style={{ padding: '12px', height: '190px', display: 'flex', flexDirection: 'column' }}>
                <ChipSelector style={{ position: 'absolute', top: '15px', right: '15px', zIndex: '4' }} options={types} value={type} handleChange={setType}/>
                <h6 style={{ marginBottom: '3px', opacity: '.7', fontSize: '14px' }}> {type} </h6>
                <h6 style={{ marginBottom: '3px', fontSize: '18px' }}> {getCurrentDayValue(date)}{type === 'Calories' ? '' : 'g'} </h6>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end', marginTop: 'auto' }}>
                    { loading ? <CircularProgress size={20} style={{ position: 'absolute', top: '15px', right: '15px' }} /> : null }
                    { info ? <div style={{ height: '80px', alignItems: 'end', width: '90%', position: 'relative' }}>
                        <div style={{ height: '0px', position: 'absolute', width: 'calc(100% + 30px)', bottom: `${getCurrentTypeAverage() / max * 80 + 28}px`, left: '-25px', transition: 'all 300ms ease', display: 'flex', alignItems: 'center', opacity: '.5' }}> 
                            <p style={{ fontSize: '12px', margin: '0px', marginRight: '3px' }}> {Math.round(getCurrentTypeAverage())} </p>
                            <div style={{ borderBottom: '1px dashed black', flex: '1' }}/>
                        </div>
                        <div style={{ height: '0px', position: 'absolute', width: 'calc(100% + 30px)', bottom: `${props.auth.user.settings.macros[type.toLowerCase()] / max * 80 + 28}px`, left: '-25px', transition: 'all 300ms ease', display: 'flex', alignItems: 'center' }}> 
                            <p style={{ fontSize: '12px', margin: '0px', marginRight: '3px' }}> {Math.round(props.auth.user.settings.macros[type.toLowerCase()])} </p>
                            <div style={{ borderBottom: '1px solid black', flex: '1' }}/>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', height: '100%' }}>
                            {
                                info.days.map((day) => {
                                    return (
                                        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <div style={{ width: '35px', height: `${getCurrentDayValue(day._id) / max * 80}px`, transition: 'all 300ms ease', backgroundColor: date == day._id ? Color.PRIMARY : 'rgba(0,0,0,.1)', borderRadius: '10px' }}/>
                                            <p style={{ fontSize: '12px', margin: '5px', opacity: '.7' }}> {days_pref[day._id]} </p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div> : null }
                </div>
            </CardContent>
        </CardFloat>
    )
}

function mapStateToProps(state) {
	return {
        auth: state.auth,
        dash: state.dash
	}
}

export default connect(mapStateToProps)(NutritionInfographic)
