import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getSessionStats } from '../actions/dashboardActions'
import { CardFloat, HorizontalScroll } from './'
import { CardContent, CircularProgress } from '@material-ui/core'

class SessionStats extends Component {
    componentDidMount() {
        if (this.props.auth.user && !this.props.dash.sessionStatsLoading && !this.props.dash.sessionStatsLoaded) this.props.getSessionStats()
    }

    componentDidUpdate() {
        if (this.props.auth.user && !this.props.dash.sessionStatsLoading && !this.props.dash.sessionStatsLoaded) this.props.getSessionStats()
    }

    render() {
        return (
            <div style={{ display: 'flex' }}>
                <CardFloat style={{ flexGrow: '1', marginBottom: '0px' }}>
                    <CardContent>
                        <h6> {this.props.dash.sessionStats.count || 0}  </h6>
                        <h6 style={{ fontSize: '14px', opacity: '.7'}}> Exercises Completed </h6>
                        { this.props.dash.sessionStatsLoading ? <CircularProgress size={15} style={{ position: 'absolute', top: '15px', right: '15px' }}/> : null }
                    </CardContent>
                </CardFloat>
                <CardFloat style={{ flexGrow: '1', marginBottom: '0px' }}>
                    <CardContent>
                        <h6> {this.props.dash.sessionStats.steps || 0}  </h6>
                        <h6 style={{ fontSize: '14px', opacity: '.7'}}> Steps Walked </h6>
                        { this.props.dash.sessionStatsLoading ? <CircularProgress size={15} style={{ position: 'absolute', top: '15px', right: '15px' }}/> : null }
                    </CardContent>
                </CardFloat>
            </div>
        );
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        getSessionStats }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(SessionStats);