import { Component } from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location && 
        !(this.props.location.pathname.includes('articles') && prevProps.location.pathname.includes('articles')) && 
        !(this.props.location.pathname.includes('stnbly-u') && prevProps.location.pathname.includes('stnbly-u'))) {
        window.scrollTo(0, 0)
      }
    }
  
    render() {
      return this.props.children
    }
  }
  
  export default withRouter(ScrollToTop)