//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createSession } from '../actions/dashboardActions'
//Components and Pages
import { CardContent, InputAdornment } from '@material-ui/core';
import { Modal, CardFloat, ModalButton, Input } from './'
import { Measure } from '../util'
//Images

const initialState = {
    targetTime: new Date(),
    Time: 'now',
    hours: '',
    minutes: '',
    seconds: '',
    heartrate: '',
    steps: '',
    calories: '',
    success: false,
    error: false
}

class TrackingAddModalActivities extends Component {
    constructor (props) {
        super(props)
        this.state = initialState
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.targetTime !== prevProps.targetTime) this.setState({ targetTime: this.props.targetTime, time: this.state.targetTime.getDate() === this.props.time.getDate() ? "now" : "" } )
        if (this.state.targetTime !== prevState.targetTime) this.setState({ time: this.state.targetTime.getDate() === this.props.time.getDate() ? "now" : ""})
        if (this.props.open && !prevProps.open) {
            if (this.props.duration) this.setState({ minutes: this.props.duration })
        }
    }

    handleAdd = () => {
        let temp = this.state.targetTime
        if (this.state.time === 'now') temp = this.props.time

        return this.props.createSession({ 
            time: temp.getTime(), 
            type: 'activity', 
            target_class: this.props.activity.class,
            target_id: this.props.activity._id, 
            metadata: { 
                duration: this.getSeconds(this.state.hours, this.state.minutes, this.state.seconds), 
                calories: this.state.calories !== '' ? Number(this.state.calories) : null,
                heartrate: this.state.heartrate !== '' ? Number(this.state.heartrate) : null,
                steps: this.state.steps !== '' ? Number(this.state.steps) : null
            }}, this.props.activity).then((response) => {
                this.handleSuccess()
            }).catch((error) => {
                this.handleError()
            })
    }

    handleSuccess = () => {
        this.setState({ success: true })
        setTimeout(this.handleClose, 400)
    }

    handleError = () => {

    }

    getSeconds = (hours, minutes, seconds) => {
        return Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds)
    }

    handleChange = (question, answer) => {
        this.setState( { [question]: answer })
    }

    handleClose = () => {
        this.setState(initialState)
        this.props.handleClose()
    }

    getInputAdornment = (type) => {
        if (this.props.auth.user.settings) {
            return <InputAdornment> {Measure[this.props.auth.user.settings.unit][type].unit} </InputAdornment>
        }
    }

    confirmSubmit = () => {
        return this.getSeconds(this.state.hours, this.state.minutes, this.state.seconds) > 0
    }

	render() {
        return (
            <Modal direction='up' in={this.props.open} handleClose={this.handleClose} head={`Add ${this.props.activity && this.props.activity.name ? this.props.activity.name : "activity"}`}>
                <CardFloat>
                    <CardContent style={{ padding: '16px' }}>
                        <div>
                            { this.props.activity && this.props.activity.walking ? <div style={{ width: '100%' }}> 
                                <Input 
                                    type="number"
                                    style={{ marginBottom: '10px', width: '100%' }}
                                    error={this.state.steps < 0}
                                    value={this.state.steps} 
                                    placeholder="Steps"
                                    onChange = {(event) => {
                                    this.handleChange("steps", event.target.value)}}/>
                            </div> : null }
                        </div>
                        <div>
                            <div style={{ display: 'flex' }}>
                                <Input 
                                    type="number"
                                    style={{ marginRight: '10px', width: '100%' }}
                                    error={this.state.hours < 0}
                                    value={this.state.hours} 
                                    placeholder="Hours"
                                    onChange = {(event) => {
                                    this.handleChange("hours", event.target.value)}}/>
                                <Input 
                                    type="number"
                                    style={{ marginRight: '10px', width: '100%' }}
                                    error={this.state.minutes < 0}
                                    value={this.state.minutes} 
                                    placeholder="Minutes"
                                    onChange = {(event) => {
                                    this.handleChange("minutes", event.target.value)}}/>
                                <Input 
                                    type="number"
                                    style={{ width: '100%' }}
                                    error={this.state.seconds < 0}
                                    value={this.state.seconds} 
                                    placeholder="Seconds"
                                    onChange = {(event) => {
                                    this.handleChange("seconds", event.target.value)}}/>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Input 
                                    type="number"
                                    style={{ marginRight: '10px', width: '100%' }}
                                    error={this.state.calories < 0}
                                    value={this.state.calories} 
                                    placeholder="Calories"
                                    onChange = {(event) => {
                                    this.handleChange("calories", event.target.value)}}/>
                                <Input 
                                    type="number"
                                    style={{  width: '100%' }}
                                    error={this.state.heartrate < 0}
                                    value={this.state.heartrate} 
                                    placeholder="Heartrate"
                                    suff={'bpm'}
                                    onChange = {(event) => {
                                    this.handleChange("heartrate", event.target.value)}}/>
                            </div>
                        </div>
                    </CardContent>
                </CardFloat>
                <ModalButton success={this.state.success} disabled={!this.confirmSubmit()} loading={this.props.dash.sessionLoading} handleClick={this.handleAdd} label={`Add ${this.props.activity ? this.props.activity.name : 'activity'}`}/>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
	return {
        auth: state.auth,
		dash: state.dash,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        createSession }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingAddModalActivities)

