//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
//Components and Pages
import { setSnackbar } from '../actions/snackbarActions'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardContent, Grow, Chip, Fab } from '@material-ui/core';
import { RecalibrationCard, CardFloat, WeighinModal, RecalibrationModal, SkeletonCard, Infographic, Grid, PageCard, HelpButton, WeighinInfoModal, StickyHeader, BackButton } from '../components'
import { SpeedDialIcon } from '@material-ui/lab'
//Images
import { Color, Measure, timeToLocal } from '../util'
import RefreshIcon from '@material-ui/icons/Refresh';

const labels = { "weigh_in" : { name: "Weigh-in", color: Color.CARBS }, "recalibration" : { name: "Recalibration", color: Color.CALORIES },  "initial_weigh_in" : { name: "Initial Weigh-in", color: Color.FAT }}
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

class Recalibration extends Component {
    constructor (props) {
        super(props)
		this.state = {
            recalibrate: this.props.location.state ? this.props.location.state.recalibrationOpen : false,
            weighIn: this.props.location.state ? this.props.location.state.weighInOpen : false,
            time: new Date(),
            infoOpen: false,
            dial: false
		}
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search)
        let recalibrationToggle = query.get('r')
        if (recalibrationToggle) this.setState({ recalibrate: recalibrationToggle === 'true' })
        this.timeClock = setInterval(this.tick, 1000)
    }

    componentWillUnmount() { 
        clearInterval(this.timeClock)
        this.setState({ mounted: false })
    }

    tick = () => {
        this.setState({ time: new Date() })
    }

    getMeasure = (type, num) => {
        if (this.props.auth.user) {
            return Math.round( num / Measure[this.props.auth.user.settings.unit][type].mult  * 10) / 10
        } else {
            return 0
        }
    }

    handleInfoOpen = (session) => {
        this.setState({ infoOpen: true, infoSession: session })
    }

    handleInfoClose = () => {
        this.setState({ infoOpen: false })
    }

    renderHistory = () => {
        let hist = this.props.dash.history
        let count = { "weigh_in" : hist.filter((element) => element.type === "weigh_in").length, "recalibration" : hist.filter((element) => element.type === "recalibration").length}
        hist.sort((a, b) => b.time - a.time)

        let history = hist.map((element, i) => {
            count[element.type] = count[element.type] - 1
            let startTime = new Date(hist[hist.length - 1].time)
            let time = new Date(element.time)

            return (
                <CardFloat style={{ cursor: 'pointer' }} outline onClick={() => this.handleInfoOpen(element)}>
                    <CardContent style={{ display: 'flex', padding: '15px', alignItems: 'center' }}>
                        <div>
                            <h6 style={{ margin: '0px' }}> {months[time.getMonth()]} {time.getDate()} </h6>
                            <h6 style={{ margin: '0px', opacity: '.7', fontSize: '14px' }}> {days[time.getDay()]} </h6>
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <h5 style={{ margin: '0px', fontSize: '18px', textAlign: 'right' }}> {this.getMeasure("mass", element.weight)} </h5>
                            <p style={{ textAlign: 'right', color: hist.length === i + 1 || hist[i+1].weight - element.weight === 0 ? null : element.weight - hist[i+1].weight > 0 ? 'red' : 'green', margin: '0px', fontSize: '14px', opacity: '.7' }}> {i < hist.length-1 ? this.getMeasure("mass", element.weight - hist[i+1].weight) : this.getMeasure("mass", element.weight)} {Measure[this.props.auth.user.settings.unit].mass.unit} </p>
                        </div>
                        { /* <h6 style={{ opacity: '.7', fontSize: '14px' }}> { this.getMeasure("mass", element.weight - hist[hist.length-1].weight)} { Measure[this.props.auth.user.settings.unit].mass.unit } from {months[startTime.getMonth()]} {startTime.getDate()}</h6> */ }
                    </CardContent>
                </CardFloat>
            )
        })

        if (history.length < 1) {
            return (
                <Card style={{ borderRadius: '25px', marginBottom: '15px', marginTop: '20px', backgroundColor: 'grey', opacity: '.5'}}>
                    <CardContent>
                        <h6 style={{ textAlign: 'center', marginTop: '20px'}}> No History </h6>
                    </CardContent>
                </Card>
            )
        }

        return history
    }

    renderInfo = () => {
        return <WeighinInfoModal open={this.state.infoOpen && !this.state.addOpen && !this.state.updateOpen} handleClose={this.handleInfoClose} infoSession={this.state.infoSession}/>
    }

    renderOverview = () => {
        return (
            <PageCard title={'Body Stats'} style={{ paddingTop: '0px' }}>
                <Infographic style={{ marginTop: '0px' }} type={[ {value: 'weight', label: 'Weight'}, { value: 'waist_size', label: 'Waist size'}, { value: 'body_fat', label: 'Body fat'}]} title={[{ type: 'body_fat', title: 'Body Fat'}, { type: 'weight', title: 'Weight' }, { type: 'waist_size', title: 'Waist Size' }]} buttons/>  
                <div style={{ padding: '15px 0px 0px 0px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ marginBottom: '0px' }}> History </h5>
                    </div>
                    {this.props.auth.user ? this.renderHistory() : <SkeletonCard/>}
                </div>
            </PageCard>
        )
    }

    handleRecalibrationOpen = () => {
        if (this.props.auth.user.curr.recalibration_date !== null && this.props.auth.user.curr.recalibration_date <= timeToLocal(this.state.time.getTime())) this.setState({ recalibrate: true })
        else {
            this.setState({ dial: false })
            this.props.setSnackbar({ type: 'error', msg: 'Not your recalibration date', status: 400 })
        }
    }

    renderButtons = () => {
        return (
            <Grow in={!this.state.weighIn && !this.state.recalibrate && !this.state.infoOpen}>
                <Fab color='primary' style={{ '*:focus' : '{ outline: none }', backgroundColor: Color.PRIMARY, color: 'white',  outline: 'none', margin: '10px', position: 'fixed', bottom:'65px', right:'0', zIndex: '50', boxShadow: '0 6px 20px 5px rgba(0, 0, 0, 0.15)'}} onClick={() => this.setState({ weighIn: true })}>
                    <SpeedDialIcon/>
                </Fab>
            </Grow>
        )
    }

    isRecalibrationDate = () => {
        return this.props.auth.user.curr.recalibration_date !== null && this.props.auth.user.curr.recalibration_date <= timeToLocal(this.state.time.getTime())
    }

    render() {
        return (
            <div>
                <StickyHeader>
                    <h5 style={{ marginBottom: '0px' }}> Body Stats </h5>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', cursor: 'pointer', color: !this.isRecalibrationDate() ? 'rgba(0,0,0,0.5)' : Color.PRIMARY }} onClick={() => this.isRecalibrationDate() ? this.setState({ recalibrate: true }) : null}>
                        <p style={{ marginBottom: '0px', fontSize: '16px' }}> Recalibrate </p>
                        <RefreshIcon style={{ fontSize: '16px', marginLeft: '6px', marginTop: '2px' }}/>
                    </div>
                </StickyHeader>
                { this.renderOverview() }

                { this.renderButtons() }

                {this.renderInfo()}

                <BackButton/>

                {this.props.auth.user ? <WeighinModal time={this.state.time} open={this.state.weighIn} handleClose={() => this.setState({ weighIn: false })}/> : null}
                {this.props.auth.user ? <RecalibrationModal open={this.state.recalibrate} handleClose={() => this.setState({ recalibrate: false })}/> : null}
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ 
        setSnackbar
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Recalibration))