import React, { Component } from 'react' 
import { Chip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';


class RadioButtons extends Component {
    handleClick = (action, variable, value, event) => {
        event.stopPropagation()
        event.preventDefault()
        if (action) action(variable, value)
        if (this.props.handleChange) {
            if (this.props.multi) this.props.handleChange(variable, this.props.value.includes(value) ? this.props.value.filter((instance) => instance !== value) : [...this.props.value, value])
            else this.props.handleChange(variable, value)
        }
    }

    renderChip = (option, styles, i) => {
        return (
            !this.props.multi ? <Chip style={{ ...styles, marginRight: '5px', marginBottom: '5px'}} color='primary' key={i} label={<div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ marginBottom: '0px', paddingBottom: '0px'}}>{option.label}</p>
                { this.props.value === option.value ? <CheckIcon fontSize="small" style={{ padding: '2px', fontSize: '16px', marginLeft: '2px' }}/> : null }
            </div>} disabled={this.props.value === option.value} onClick={(event) => this.handleClick(option.action, option.variable, option.value, event)}/> : 
            <Chip style={{ ...styles, marginRight: '5px', marginBottom: '5px', opacity: this.props.value.includes(option.value) ? '.5' : '1' }} color='primary' key={i} label={<div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ marginBottom: '0px', paddingBottom: '0px'}}>{option.label}</p>
                { this.props.value.includes(option.value) ? <CheckIcon fontSize="small" style={{ padding: '2px', fontSize: '16px', marginLeft: '2px' }}/> : null }
            </div>} onClick={(event) => this.handleClick(option.action, option.variable, option.value, event)}/>
        )
    }

    render() {
        return (
            <div style={{ paddingTop: '5px', position: 'relative' }}>
                { this.props.options ? this.props.options.map((option, i) => {
                    let styles = { ...this.props.style, ...option.style}
                    return this.props.vertical ? <div key={i}>
                        { this.renderChip(option, styles, i) }
                    </div> : this.renderChip(option, styles, i)
                }) : null }       
            </div>
        )
    }
}

export default RadioButtons