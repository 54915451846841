import React,  { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getArticles, createView } from '../actions/dashboardActions'
import { Skeleton } from '@material-ui/lab'
import { Image, Modal, Views, ArticlePreview } from './'
import { Color } from '../util'
import api from '../api'

function ArticleModal(props) {
    const [open, setOpen] = useState(true)
    const [article, setArticle] = useState(null)
    const [loading, setLoading] = useState(false)

    //Create view on component mount, set article from redux state
    useEffect(() => {
        props.createView(props.article_id)
        setArticle(getArticle(props.article_id, props.module_id))
    }, [])

    //Load article after module load
    useEffect(() => {
        setArticle(getArticle(props.article_id, props.module_id))
    }, [props.dash.modules, props.dash.articles])

    //Request content after load
    useEffect(() => {
        if (article && !article.content) {
            setLoading(true)
            api.getArticleById(article._id, {}).then((response) => {
                setArticle(response.data)
                setLoading(false)
            }).catch((error) => {
                setLoading(false)
            })
        }
    }, [article])

    useEffect(() => {
        if (!getArticle(props.article_id, props.module_id)) props.getArticles({ _id: props.article_id }, true, undefined, props.module_id)
    }, [props.article_id, props.module_id])

    const getArticle = (article_id, module_id) => {
        if (props.module_id) {
            let module = props.dash.modules.filter((module) => module._id === module_id)[0]
            let articles = module ? module.articles.filter((entry) => entry.article._id === article_id): []
            return articles.map((article) => article.article)[0]
        }

        return props.dash.articles.filter((article) => article._id === article_id)[0]
    }

    const renderViews = () => {
        return (
            article.views !== undefined ? <div style={{ position: 'absolute', top: '-60px', left: '-5px', zIndex: '1003', backgroundColor: 'white', padding: '6px 9px 6px 9px', borderRadius: '25px' }}>
                <Views views={article.views}/> 
            </div> : null
        )
    }

    const handleClose = () => {
        setOpen(false)
        setTimeout(() => props.history.goBack(), 400)
    }

    return (
        <Modal nobackdrop style={{ height: '100%', borderRadius: '0px', boxShadow: 'none', width: '100%' }} direction="left" in={open} handleClose={handleClose} head={article ? article.title : null} img={article ? article.img : 'skeleton'}>
            { article ? <div>
                <h6 style={{ opacity: '.7', marginBottom: '5px' }}> {article.short_description} </h6>
                <p style={{ color: Color.PRIMARY, fontSize: '14px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}> {article.tags ? article.tags.map(tag => `#${tag} `) : null } </p>
                <div style={{ display: 'flex' }}>
                    <Image style={{ borderRadius: '50%', height: '40px', width: '40px', objectFit: 'cover' }} src={article.author_img}/>
                    <div style={{ marginLeft: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <p style={{ color: 'rgba(0,0,0,.5)', fontSize: '12px', marginBottom: '0px'}}> Written by <span style={{ color: 'rgba(0,0,0,.8)' }}>{article.author}</span> </p>
                        <p style={{ color: 'rgba(0,0,0,.5)', fontSize: '12px', marginBottom: '0px'}}> Updated over a week ago</p>
                    </div>
                </div>
                { props.views ? renderViews() : null }
            </div> : null }

            <div style={{ position: 'relative', marginTop: '20px'}}>
                { article && article.content ? <ArticlePreview convertFromRaw={article.content} readOnly style={{ border: 'none', padding: '0px' }}/> : <div>
                    <Skeleton variant="text" style={{ borderRadius: '8px', marginRight: '70px'}}/>
                    <Skeleton variant="text" style={{ borderRadius: '8px', marginRight: '15px'}}/>
                    <Skeleton variant="text" style={{ borderRadius: '8px', marginRight: '15px'}}/>
                </div> }
            </div>
        </Modal>
    )
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getArticles,
        createView
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleModal)