import React, { Component } from 'react' 
import { Slide, Backdrop } from '@material-ui/core';
import { Modal } from '@material-ui/core';

class ModalSlide extends Component {
    render() {
        return (
            <Modal open={this.props.in} BackdropComponent={Backdrop} BackdropProps={{ timeout: 400 }} hideBackdrop={!this.props.backdrop} onClose={this.props.handleClose}>
                <Slide direction={this.props.direction} in={this.props.in} timeout={this.props.timeout || 250} style={{ outline: 'none' }}>
                    { this.props.children }
                </Slide>
            </Modal>
        )
    }
}

export default ModalSlide
