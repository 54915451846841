import React, { useState, useRef, useEffect } from 'react'
import api from '../api'
import { CircularProgress, Fade } from '@material-ui/core'
import { Input, Image, ChipSelector  } from './'
import SearchIcon from '@material-ui/icons/SearchRounded'
import { toTitleCase, Color } from '../util'
import AddIcon from '@material-ui/icons/AddRounded'
import axios from 'axios'

const SearchListing = (props) => {
    const renderFood = (food) => {
        if (!food) return null
        switch (food.class) {
            case ('custom') :
            case ('recipe') : {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        { props.food.image_url ? <Image src={props.food.image_url} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', backgroundColor: 'grey', border: 'none', marginRight: '8px' }}/> : null }
                        <p style={{ margin: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}> { toTitleCase(props.food.name) } </p>       
                        { props.handleAddOpen ? <div style={{ marginLeft: 'auto', padding: '3px', backgroundColor: 'rgba(0,0,0,.05)', color: Color.PRIMARY, borderRadius: '50%' }} /*onClick={(e) => handleAddOpen(e, { event: e, meal: props.food, populate: true })}*/>
                            <AddIcon/>
                        </div> : null }
                    </div>
                )
            }

            case ('branded') :
            case ('common') : {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        { props.food.photo.thumb ? <Image src={props.food.photo.thumb} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', backgroundColor: 'grey', border: 'none', marginRight: '8px' }}/> : null }
                        <p style={{ margin: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}> { toTitleCase(props.food.food_name) }  </p>       
                        { props.handleAddOpen ? <div style={{ marginLeft: 'auto', padding: '3px', backgroundColor: 'rgba(0,0,0,.05)', color: Color.PRIMARY, borderRadius: '50%' }} /*onClick={(e) => handleAddOpen(e, { event: e, meal: props.food, populate: true })}*/>
                            <AddIcon/>
                        </div> : null }
                    </div>
                )
            }
        }
    }

    return (
        props.food ? <div style={{ margin: '7px 0px 7px 0px', padding: '8px', backgroundColor: 'rgba(0,0,0,.05)', borderRadius: '10px' }} onClick={() => props.handleInfoOpen?.({ meal: props.food, populate: true })}>
            { renderFood(props.food) } 
        </div> : null
    )
}

const SearchBar = (props) => {
    const searchRef = useRef()
    const contentRef = useRef()
    const timerRef = useRef()
    const cancelTokenRef = useRef()

    const [results, setResults] = useState({})
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [focused, setFocused] = useState(false)
    const [searchType, setSearchType] = useState('nix')

    useEffect(() => {
        if (!search || props.disabled) return
        if (cancelTokenRef.current) cancelTokenRef.current.cancel()
        clearTimeout(timerRef.current)
        setLoading(true)
        timerRef.current = setTimeout(handleSearch, 500)
    }, [search, searchType])

    useEffect(() => {
        if (props.onAutocomplete) props.onAutocomplete(results)
    }, [results])

    useEffect(() => {
        if (props.onLoading) props.onLoading(loading)
    }, [loading])

    useEffect(() => {
        setSearch(props.value)
    }, [props.value])

    const handleSearch = () => {
        cancelTokenRef.current = axios.CancelToken.source()
        api.searchFoods({ params: { q: search, limit: 3, recipes: true, common: true, branded: true, history: true }, cancelToken: cancelTokenRef.current?.token }).then((response) => {
            if (!response) return
            setResults(response.data)
            setLoading(false)
        }).catch((error) => {
            if (axios.isCancel(error)) return
            setResults({})
            setLoading(false)
        })
    }

    const getSearchBounds = () => {
        if (!searchRef || !searchRef.current) return {}
        return searchRef.current.getBoundingClientRect()
    }

    const getContentBounds = () => {
        if (!contentRef || !contentRef.current) return {}
        return contentRef.current.getBoundingClientRect()
    }

    const handleInfoOpen = (options) => {
        if (props.clearOnClick) setSearch('')
        props.handleInfoOpen(options)
    }

    const renderSearchResults = (results) => {
        let render = <div style={{ fontSize: '14px', marginTop: '10px', marginBottom: '4px' }}>
            <p style={{ position: 'absolute', top: '20px', right: '20px', fontSize: '12px', opacity: '.7'}}>{results.time}ms</p>
            { results.common && results.common.length ? <div style={{ marginBottom: '10px' }}>
                <h6 style={{ margin: '0px', fontSize: '14px' }}> Common </h6>
                {
                    results.common.map((result, i) => {
                        return <SearchListing key={i} food={result} handleInfoOpen={handleInfoOpen} handleAddOpen={props.handleAddOpen}/>
                    })
                }
                { props.onViewAll ? <p style={{ textAlign: 'center', color: Color.PRIMARY, margin: '0px', fontSize: '14px' }} onClick={(e) => props.onViewAll(e, 'Generic', search)}> View All </p> : null }
            </div> : null }  
            { results.branded && results.branded.length ? <div style={{ marginBottom: '10px' }}>
                <h6 style={{ margin: '0px', fontSize: '14px' }}> Branded </h6>
                {
                    results.branded.map((result, i) => {
                        return <SearchListing key={i} food={result} handleInfoOpen={handleInfoOpen} handleAddOpen={props.handleAddOpen}/>
                    })
                }
                { props.onViewAll ? <p style={{ textAlign: 'center', color: Color.PRIMARY, margin: '0px', fontSize: '14px' }} onClick={(e) => props.onViewAll(e, 'Branded', search)}> View All </p> : null }
            </div> : null }
            {/* results.recipes && results.recipes.length ? <div>
                <h6 style={{ margin: '0px', fontSize: '14px' }}> Recipes </h6>
                {
                    results.recipes.map((result, i) => {
                        return <SearchListing key={i} food={result} handleInfoOpen={handleInfoOpen} handleAddOpen={props.handleAddOpen}/>
                    })
                }
                { props.onViewAll ? <p style={{ textAlign: 'center', color: Color.PRIMARY, margin: '0px', fontSize: '14px' }} onClick={(e) => props.onViewAll(e, 'Recipes')}> View All </p> : null }
            </div> : null */}
        </div>

        if (!search) {
            return null
        } else if (loading) {
            return (<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' , height: '28px' }}>
                <CircularProgress size={25} key={1}/>
            </div>)
        } else if (
            !(results.branded && results.branded.length) &&
            !(results.recipes && results.recipes.length) &&
            !(results.common && results.common.length) &&
            !loading) {
            return (<Fade in={true}>
                <p style={{ textAlign: 'center', opacity: '.7', marginTop: '30px', marginBottom: '13px', height: '25px'  }}> No Results </p>
            </Fade>)
        }

        return render
    }

    const handleChange = (event) => {
        if (props.onChange) props.onChange(event)
        setSearch(event.target.value)
    }

    const handleFocus = (e) => {
        if (props.onFocus) props.onFocus(e)
        setFocused(true)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') event.target.blur()
        if (props.onKeyPress) props.onKeyPress(event)
    }

    return (
        <div style={{ position: 'relative', zIndex: '5', ...props.style }}>
            <div ref={searchRef} style={{ position: 'relative', zIndex: '5'}}>
                <Input suff={props.suff} disabled={props.disabled} onKeyPress={handleKeyPress} selector={props.recipes ? <ChipSelector value={searchType} style={{ backgroundColor: 'inherit', border: 'none', boxShadow: 'none' }} handleChange={setSearchType} options={[{ value: 'nix', label: 'Common' }, { value: 'recipes', label: 'Recipes' }]}/> : null} disablePlaceholder onFocus={handleFocus} onBlur={() => setFocused(false)} icon={<SearchIcon style={{ fontSize: '18px', opacity: '.7'}}/>} value={search} onChange={handleChange} placeholder={props.placeholder || 'Search Food'} style={{ width: '100%', margin: '5px 0px 15px 0px', zIndex: '400', ...props.inputStyle }}/> 
            </div>
            { !props.noAutocomplete ? <div style={{ position: 'absolute', backgroundColor: 'white', borderRadius: '0px 0px 15px 15px', height: focused && search ? getContentBounds().height: 0, width: getSearchBounds().width, top: '32px', boxShadow: '0 0px 20px 5px rgba(0,0,0,.08)', zIndex: '1', overflow: 'hidden', transition: 'height 300ms ease' }}>
                <div ref={contentRef} style={{ padding: '10px 20px 10px 20px' }}>
                    { renderSearchResults(results) }
                </div>
            </div> : null }
            { props.displayResults ? <div>
                { renderSearchResults(results) }
            </div> : null }
        </div>
    )
}

export default SearchBar