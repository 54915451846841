import React from 'react'
import PersonIcon from '@material-ui/icons/PersonRounded';

const View = (props) => {

    let views = props.views ? props.views : 0
    if (views < 100) views = '<100'
    
    return (
        <div style={{ ...props.style, fontSize: '0px', display: 'flex', alignItems: 'center' }}>
            <PersonIcon style={{ fontSize: '16px' }}/>
            <p style={{ display: 'inline-block', fontSize: '12px', marginLeft: '3px', marginBottom: '0px' }}> {views} </p>
        </div>
    )
}

export default View