//Classes and Functions
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
//Components and Pages
import { CardContent, Fade, Chip } from '@material-ui/core'
import { SkeletonCard, CardFloat, Button } from './' 
import { Color } from '../util'
//Images
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit';

class ActivityCard extends Component {
    handleClick = (event, link) => {
        event.stopPropagation()
        event.preventDefault()
        if (link) this.props.history.push(link)
    }

    verifyActivity = (workout) => {
        let sessions = this.props.dash.sessions.filter((session) => session.type === 'activity')
        for (let index in sessions) {
            let session = sessions[index], temp = new Date(session.time), curr = (this.props.trackingTime ? new Date(this.props.trackingTime) : new Date())
            if (temp.getDate() === curr.getDate() && session.target_id === workout._id) return true
        }

        return false
    }

    renderButton = () => {
        let button = null
        if (this.props.actionLink) {
            button = (
                <Button disabled={this.props.disabled} onClick={(event) => this.props.handleAddOpen ? this.props.handleAddOpen({ event: event, activity: this.props.activity, duration: this.props.duration }) : null }>
                    <AddIcon style={{ fontSize: '18px' }}/>
                </Button>
            )
        } else if (this.props.handleUpdateOpen && this.props.session) {
            button = (
                <Button onClick={(event) => this.props.handleUpdateOpen({ event: event, session: this.props.session })}>
                    <EditIcon style={{ fontSize: '18px' }}/>
                </Button>
            )
        } else if (this.props.handleAddOpen) {
            if (this.props.verifyWorkout && this.verifyActivity(this.props.activity)) {
                button = (
                    <Button style={{ backgroundColor: Color.CARBS_SUCCESS }}>
                        <CheckIcon style={{ fontSize: '18px'}}/>
                    </Button>
                )
            } else {
                button = (
                    <Button disabled={this.props.disabled} onClick={(event) => this.props.handleAddOpen({ event: event, activity: this.props.activity, duration: this.props.duration })}>
                        <AddIcon style={{ fontSize: '18px' }}/>
                    </Button>
                )
            }
        }

        return (
            <div onClick={(event) => this.handleClick(event, this.props.actionLink)}>
                { button }
            </div>
        )
    }

    getDuration = (duration) => {
        let string = ""
        if (duration / 3600 >= 1) {
            string += `${Math.floor(duration/3600)} hour${Math.floor(duration/3600) > 1 ? 's ' : ' '}`
            duration -= Math.floor(duration/3600) * 3600
        }

        if (duration / 60 >= 1) {
            string += `${Math.floor(duration/60)} minute${Math.floor(duration/60) > 1 ? 's ' : ' '}`
            duration -= Math.floor(duration/60) * 60
        }

        if (duration  >= 1) {
            string += `${duration} second${duration > 1 ? 's ' : ' '}`
        }

        return string
    }

    render() {
        return (
            <div>
                {this.props.activity ? (
                    <div onClick={(event) => this.handleClick(event, this.props.link)}>
                        <Fade in={true}>
                            <CardFloat outline>
                                { this.renderButton() }
                                <CardContent style={{ width: '100%', minHeight: '90px' }}>
                                    <div style={{ overflowWrap: 'break-word', marginRight: '40px'}} >
                                        <h6 style={{ marginBottom: '5px' }}> {this.props.activity.name} {this.props.activity?.class === 'custom' && this.props.handleCustomUpdateOpen ? <EditIcon style={{ opacity: '.5', fontSize: '18px', marginBottom: '3px', marginLeft: '4px'}} onClick={(event) => this.props.handleCustomUpdateOpen({ event, activity: this.props.activity })}/> : null } </h6>
                                        { this.props.duration ? <h6 style={{ opacity: '.7', fontSize: '14px' }}> {this.props.duration} minutes </h6> : null }
                                    </div>
                                    { this.props.session?.metadata.duration ? <p style={{ position: 'absolute', right: '20px', opacity: '50%', bottom: '8px', fontSize: '12px', marginBottom: '0px' }}> {this.getDuration(this.props.session.metadata.duration)} </p> : null }
                                </CardContent>
                            </CardFloat>
                        </Fade> 
                    </div> ) : <SkeletonCard/> 
                }
            </div>
        )
    }
}

ActivityCard.propTypes = {
    activity: PropTypes.object,
    session: PropTypes.object,
    handleAddOpen: PropTypes.func,
    handleUpdateOpen: PropTypes.func,
    actionLink: PropTypes.object
}

function mapStateToProps(state) {
	return {
		dash: state.dash,
	}
}

export default connect(mapStateToProps)(withRouter(ActivityCard))