//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { deleteCustom, updateCustom } from '../actions/dashboardActions'
//Components and Pages
import { CircularProgress, CardContent } from '@material-ui/core';
import { CardFloat, Input, Modal, ModalButton, SearchBar, ChipSelector, Image, CustomMealSearchModal } from './'
import { Color, toTitleCase, getAttribute } from '../util'
//Images
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit';
import apis from '../api'

const initialState = {
    name: '',
    protein: '',
    carbs: '',
    fat: '',
    calories: '',
    success: false,
    error: false,
    components: [],
    meal: false,
    mealSearchOpen: false
}

const SearchListing = (props) => {
    return (
        props.food ? <div style={{ margin: '7px 0px 7px 0px', padding: '8px', backgroundColor: 'rgba(0,0,0,.05)', borderRadius: '10px', fontSize: '14px', width: '50%' }} onClick={() => props.handleInfoOpen?.({ meal: props.food, populate: true })}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                { props.food.image_url || props.food.thumb_url ? <Image src={props.food.image_url || props.food.thumb_url} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', backgroundColor: 'grey', border: 'none', marginRight: '8px' }}/> : null }
                <p style={{ margin: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}> { toTitleCase(props.food.name) }  </p>       
            </div>
        </div> : null
    )
}

class CustomFoodEditModal extends Component {
    constructor (props) {
        super(props)
        this.state = initialState
    }

    async componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            if (this.props.food && this.props.food !== null) {
                let { protein, carbs, calories, fat, name, components, is_meal } = this.props.food
                this.setState({ protein, carbs, calories, fat, name, components: components?.map((component) => {
                    return { food: { ...component.target, class: component.target_class }, serving_unit: component.metadata.serving_unit || 'serving', servings: component.metadata.servings || 1 }
                }), meal: is_meal })
            }
        }
    }

    handleChange = (question, answer) => {
        this.setState( { [question]: answer })
    }

    handleClose = () => {
        this.setState(initialState)
        this.props.handleClose()
    }

    adjustCalories = () => {
        this.setState({ calories: Number(this.state.protein * 4 + this.state.carbs * 4 + this.state.fat * 9) })
    }

    verifyCalories = () => {
        if (this.state.calories || this.state.protein || this.state.carbs || this.state.fat) {
            if (Number(this.state.calories) !== Number(this.state.protein * 4 + this.state.carbs * 4 + this.state.fat * 9)) {
                if (this.state.protein === "" && this.state.carbs === "" && this.state.fat === "") {
                    return { bool: false, res: 'macros'}
                } else {
                    return { bool: false, res: 'calories'}
                }
            }
        }

        return { bool: this.state.calories !== '' }
    }

    populate = async (food) => {
        let populate = null
        this.setState({ populating: true })

        try { 
            switch (food.class) {
                case ('common') : 
                    populate = await apis.getCommonFoods(food.food_name) // get common nix foods
                    break
                case ('branded') : 
                    populate = await apis.getBrandedFoods(food.nix_item_id) // get branded nix foods
                    break
                case ('recipe') : 
                    populate = await apis.getRecipeById(food._id)
            }
        } catch (error) {
            console.log(error)
        }

        return populate?.data 
    }

    verify = (food) => food.is_complete !== false

    confirmSubmit = () => {
        if (this.state.name === '') return false
        else if (this.state.name === this.props.food.name && this.state.protein === this.props.food.protein && 
            this.state.carbs === this.props.food.carbs && this.state.calories === this.props.food.calories && 
            this.state.fat === this.props.food.fat ) return false 

        return true
    }

    handleDelete = () => {
        this.props.deleteCustom(this.props.auth.id, this.props.food._id).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.handleError()
        })
    }
    
    handleUpdate = () => {
        let components = this.state.components?.map((component) => {
            return {
                target_class: component.food.class,
                target_id: component.food._id,
                target_nix_id: component.food.nix_item_id,
                target_tag_id: component.food.tag_id,
                target_upc: component.food.upc,
                target: component.food.class === 'common' || component.food.class === 'branded' ? {
                    ...component.food,
                    class: null
                } : null,
                metadata: {
                    serving_unit: component.serving_unit,
                    servings: component.servings
                }
            }
        })

        let { calories, carbs, protein, fat, name } = this.state
        this.props.updateCustom(this.props.auth.id, this.props.food._id, { 
            components: this.state.meal ? components : null,
            calories, carbs: carbs !== '' ? carbs : 0, 
            protein: protein !== '' ? protein : 0, 
            fat: fat !== '' ? fat : 0, 
            name,
         }).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.handleError()
        })
    }

    handleComponentAdd = async (options) => {
        let food = options.meal
        if (!this.verify(food)) {
            this.setState({ loadingComponent: true })
            food = await this.populate(food)
            return this.setState({ 
                calories: Number(this.state.calories) + getAttribute('calories', food, { metadata: { serving_unit: food.def_unit || 'serving', servings: food.def_size || 1 }}),
                carbs: Number(this.state.carbs)+ getAttribute('carbs', food, { metadata: { serving_unit: food.def_unit || 'serving', servings: food.def_size || 1 }}),
                protein: Number(this.state.protein) + getAttribute('protein', food, { metadata: { serving_unit: food.def_unit || 'serving', servings: food.def_size || 1 }}),
                fat: Number(this.state.fat) + getAttribute('fat', food, { metadata: { serving_unit: food.def_unit || 'serving', servings: food.def_size || 1 }}),
                components: [...this.state.components, { food, serving_unit: food.def_unit || 'serving', servings: food.def_size || 1 }], 
                loadingComponent: false,
                mealSearchOpen: false
            })
        }
    }

    handleComponentDelete = (i) => {
        let remove = this.state.components[i]
        this.setState({ 
            calories: this.state.calories - getAttribute('calories', remove.food, { metadata: { serving_unit: remove.serving_unit, servings: remove.servings }}),
            carbs: this.state.carbs - getAttribute('carbs', remove.food, { metadata: { serving_unit: remove.serving_unit, servings: remove.servings }}),
            protein: this.state.protein - getAttribute('protein', remove.food, { metadata: { serving_unit: remove.serving_unit, servings: remove.servings }}),
            fat: this.state.fat - getAttribute('fat', remove.food, { metadata: { serving_unit: remove.serving_unit, servings: remove.servings }}),
        })

        let components = this.state.components.filter((component, index) => i !== index)
        if (components.length === 0) this.setState({ edit: false })
        this.setState({ components })
    }

    handleComponentChange = (i, variable, value) => {
        let components = this.state.components
        let prev = { 
            calories: getAttribute('calories', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }}),
            carbs: getAttribute('carbs', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }}),
            protein: getAttribute('protein', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }}),
            fat: getAttribute('fat', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }})
        }
        
        components[i] = { ...components[i], [variable]: value }
        
        let next = {
            calories: getAttribute('calories', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }}),
            carbs: getAttribute('carbs', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }}),
            protein: getAttribute('protein', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }}),
            fat: getAttribute('fat', components[i].food, { metadata: { serving_unit: components[i].serving_unit, servings: components[i].servings }})
        }
        
        this.setState({ 
            components,
            calories: this.state.calories - prev.calories + next.calories,
            carbs: this.state.carbs - prev.carbs + next.carbs,
            protein: this.state.protein - prev.protein + next.protein,
            fat: this.state.fat - prev.fat + next.fat
        })
    }

    renderMenuItems = (food) => {
        if (!food) return []
        if (!food.alt_units) return [food.def_unit || 'serving']
        let items = food.alt_units.map((unit) => unit.measure)
        return items
    }


    handleSuccess = () => {
        this.setState({ success: true })
        setTimeout(this.handleClose, 400)
    }

    handleError = () => {

    }

    handleMealSearchOpen = () => {
        this.setState({
            mealSearchOpen: true
        })
    }

    handleMealSearchClose = () => {
        this.setState({
            mealSearchOpen: false
        })
    }

	render() {
        return (
            <Modal direction='up' in={this.props.open} handleClose={this.handleClose} head={`Edit ${this.props.food ? this.props.food.name : 'food'}`} functions={[this.props.dash.customLoading ? <CircularProgress size={20} style={{ color: 'white' }}/> : <DeleteIcon style={{ marginBottom: '50%', color: 'white', fontSize: '20px', backgroundColor: Color.CALORIES_ERROR }} onClick={() => this.handleDelete()}/>]}>
                { !this.state.meal ? <CardFloat>
                    <CardContent style={{ padding: '16px' }}>
                        <div>
                            <div>
                                <Input 
                                    style={{ marginBottom: '10px', width: '100%' }}
                                    error={this.state.servings <= 0}
                                    value={this.state.name} 
                                    placeholder="Name"
                                    onChange = {(event) => {
                                    this.handleChange("name", event.target.value)}}/>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <Input 
                                        type="number"
                                        style={{ width: '100%' }}
                                        error={this.state.servings <= 0}
                                        value={this.state.carbs} 
                                        placeholder="Carbs"
                                        suff={'g'}
                                        onChange = {(event) => {
                                        this.handleChange("carbs", event.target.value)}}/>
                                </div>
                                <div style={{ margin: '0px 10px 0px 10px' }}>
                                    <Input 
                                        type="number"
                                        style={{ width: '100%' }}
                                        error={this.state.servings <= 0}
                                        value={this.state.protein} 
                                        placeholder="Protein"
                                        suff={'g'}
                                        onChange = {(event) => {
                                        this.handleChange("protein", event.target.value)}}/>
                                </div>
                                <div>
                                    <Input 
                                        type="number"
                                        style={{ width: '100%' }}
                                        error={this.state.servings <= 0}
                                        value={this.state.fat} 
                                        placeholder="Fat"
                                        suff={'g'}
                                        onChange = {(event) => {
                                        this.handleChange("fat", event.target.value)}}/>
                                </div>
                            </div>
                            <div>
                                <div style={{ width: '100%', display: 'flex' }}> 
                                    <Input 
                                        type="number"
                                        style={{ marginBottom: '10px', width: '100%' }}
                                        error={!this.verifyCalories().bool && this.verifyCalories().res === 'calories'}
                                        value={this.state.calories} 
                                        placeholder="Calories"
                                        suff={'kcal'}
                                        onChange = {(event) => {
                                        this.handleChange("calories", event.target.value)}}/>
                                    <div style={{ width: !this.verifyCalories().bool && this.verifyCalories().res === 'calories' ? '80px' : '0px', transition: 'all 300ms ease', overflow: 'hidden', marginLeft: !this.verifyCalories().bool && this.verifyCalories().res === 'calories' ? '5px' : '0px' }}>
                                        <ModalButton handleClick={this.adjustCalories} label={'Fix'} style={{ marginBottom: '10px', height: '40px' }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </CardFloat> : <div>
                    <CardFloat style={{ overflow: 'visible' }}>
                        <CardContent style={{ padding: '16px' }}>
                            <Input 
                                style={{ marginBottom: '10px', width: '100%' }}
                                value={this.state.name} 
                                placeholder={`Ex: ${this.props.auth.user.first_name}'s Weekday Breakfast`}
                                label="Meal Name"
                                onChange = {(event) => {
                                this.handleChange("name", event.target.value)}}/>
                            {/* <SearchBar clearOnClick style={{ padding: '20px' }} inputStyle={{ marginBottom: '10px' }} placeholder='Add food' handleInfoOpen={this.handleComponentAdd}/> */}
                            <div style={{ borderTop: '1px solid rgba(0,0,0,.1)', paddingTop: '5px', marginTop: '15px' }}>
                                {
                                    this.state.components.map((component, i) => {
                                        return (
                                            <div style={{ display: 'flex' }}>
                                                <SearchListing food={component.food}/>
                                                { !this.state.edit ? <Input 
                                                    type="number"
                                                    style={{ margin: '7px 0px 7px 5px', width: '50%' }}
                                                    error={Number(component.servings) <= 0}
                                                    value={component.servings} 
                                                    placeholder="Servings"
                                                    selector={<ChipSelector value={component.serving_unit} style={{ backgroundColor: 'inherit', border: 'none', boxShadow: '', zIndex: '5' }} handleChange={(value) => this.handleComponentChange(i, 'serving_unit', value)} options={this.renderMenuItems(component.food).map((portion) => {
                                                        return {
                                                            value: portion,
                                                            label: portion,
                                                            variable: 'serving_unit'
                                                        }
                                                    })}/>}
                                                    onChange = {(event) => { this.handleComponentChange(i, "servings", event.target.value) }}/> : <ModalButton handleClick={() => this.handleComponentDelete(i)} label={'Remove'} style={{ backgroundColor: 'red', width: '50%', margin: '7px 0px 7px 5px' }}/> }
                                            </div>
                                        )
                                    })
                                }
                                <p style={{ color: Color.PRIMARY, textAlign: 'center', margin: '0px', paddingTop: '10px' }} onClick={this.handleMealSearchOpen}>Add Meal Item</p>
                            </div>
                            { this.state.loadingComponent ? <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px 20px 0px' }}> <CircularProgress size={24}/> </div> : null }
                            {/* <p style={{ opacity: '.7', textAlign: 'center', marginBottom: '0px', marginTop: '10px' }}> {this.state.components.length} items added </p> */}
                            { this.state.components.length ? <EditIcon style={{ position: 'absolute', bottom: '18px', right: '25px', opacity: '.7', fontSize: '28px', color: this.state.edit ? 'white' : 'black', backgroundColor: this.state.edit ? Color.PRIMARY : '', borderRadius: '50%', padding: '5px', transition: 'all 300ms ease' }} onClick={() => this.setState({ edit: !this.state.edit })}/> : null }
                        </CardContent>
                    </CardFloat>
                </div> }
                <CustomMealSearchModal open={this.state.mealSearchOpen} handleInfoOpen={this.handleComponentAdd} handleClose={this.handleMealSearchClose}/>
                <ModalButton success={this.state.success} handleClick={this.handleUpdate} disabled={!this.confirmSubmit()} loading={this.props.dash.customLoading} label={`Update ${this.state.name || 'food'}`}/>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        deleteCustom,
        updateCustom }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomFoodEditModal)

