import { sbarActionTypes } from './types'

export const setSnackbar = (payload) => {
    return ({
        type: sbarActionTypes.SET_SNACKBAR,
        payload: {
            type: payload.type,
            msg: payload.msg,
            status: payload.status
        }
    })
}

export const clearSnackbar = () => {
    return ({ type: sbarActionTypes.CLEAR_SNACKBAR })
}
