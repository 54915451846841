import { useState, useEffect } from 'react'
import { ChipSelector, Input } from './'

const DateInput = (props) => {
    const [time, setTime] = useState(props.value ? new Date(props.value) : new Date())
    const [date, setDate] = useState(time.getDate())
    const [month, setMonth] = useState(time.getMonth())
    const [year, setYear] = useState(time.getFullYear())

    useEffect(() => {
        if (props.value && props.value !== time.getTime()) {
            let temp = new Date(props.value)
            setTime(temp)
            setDate(time.getDate())
            setMonth(time.getMonth())
            setYear(time.getFullYear()) 
        }
    }, [time, props.value])

    const handleChange = (type, value) => {
        let temp = new Date(time)
        switch (type) {
            case ('date') :
                temp.setDate(value)
                setDate(value)
                break
            case ('month') :
                temp.setMonth(value)
                setMonth(value)
                break
            case ('year') :
                temp.setFullYear(value)
                setYear(value)
                break
        }

        setTime(new Date(temp))
        if (props.handleChange) props.handleChange(temp.getTime())
    }

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '40%' }}>
                <ChipSelector value={month} handleChange={(event) => handleChange('month', event)} style={{ borderRadius: '12px', padding: '5px', fontSize: '16px', padding: '8px', height: '100%', width: '100%', maxWidth: '' }} options={[
                    { label: 'January', value: 0 },
                    { label: 'February', value: 1 },
                    { label: 'March', value: 2 },
                    { label: 'April', value: 3 },
                    { label: 'May', value: 4 },
                    { label: 'June', value: 5 }, 
                    { label: 'July', value: 6 },
                    { label: 'August', value: 7 },
                    { label: 'September', value: 8 },
                    { label: 'October', value: 9 },
                    { label: 'November', value: 10 },
                    { label: 'December', value: 11 }   
                ]}/>
            </div>
            <Input onChange={(event) => handleChange('date', event.target.value)} value={date} disablePlaceholder placeholder={'Date'} style={{ marginBottom: '0px', marginLeft: '10px', width: '30%' }}/>
            <Input onChange={(event) => handleChange('year', event.target.value)} value={year} disablePlaceholder placeholder={'Year'} style={{ marginBottom: '0px', marginLeft: '10px', width: '30%' }}/>     
        </div>
    )
}

export default DateInput