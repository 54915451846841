import { Elements, ElementsConsumer}  from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Checkout_revised from './Checkout_revised'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API);

const Checkout_revised_2 = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <ElementsConsumer>
                {({elements, stripe}) => (
                    <Checkout_revised elements={elements} stripe={stripe} location={props.location}/>
                )}  
            </ElementsConsumer>
        </Elements>
    )
}

export default Checkout_revised_2
