import React, { Component } from 'react'
import api from '../api'
//Components and Pages
import { CardFloat, SkeletonCard } from './'
import { CardContent, Fab, Grow, Divider } from '@material-ui/core'
//Images
import BlockIcon from '@material-ui/icons/Block';
import bikeDrawing from '../images/bikeDrawing.svg'

class PlanCards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            plans: []
        }
    }

    componentDidMount() {
        api.stripeGetPlans().then((response) => {
            this.setState({ plans: response.data.data })
        })
    }

    render() {
		let plans = this.state.plans.map((plan, i) => {
			if (process.env.NODE_ENV === 'production' ? plan.id === "price_1HGpGkFE3sVcpjXe48fUsftI" : plan.id === 'price_1HGpR6FE3sVcpjXeX9qAN53D') {
				return (
					<Grow key={i} in={true}>
						<CardFloat style={{ paddingTop: '10px' }}>
							<img alt='plan' src={bikeDrawing} style={{ position: 'absolute', bottom: '0px', right: '0px', width: '225px', opacity: '.6'}}/>
							<CardContent style={{margin: '5px', position: 'relative'}}>
								<h4> STNBLY Transformation </h4>
								<div style={{ position: 'relative'}}>
									<h5 style={{ opacity: '.5', width: '50%', marginBottom: !this.props.projection ? '20px' : null}}> ${plan.unit_amount/100}/month </h5>
									{ this.props.projection ? <div>
											<h6 style={{ opacity: '.5'}}>For less than a dollar a day, you can get to { this.props.projection.target_weight }lbs by { this.props.projection.month } and maintain it for life. </h6>
											<h6 style={{ opacity: '.5'}}> STNBLY is different. No yo-yo dieting, no frustration- Just a few simple adjustments to transform your life for good. </h6>
											<h6 style={{ opacity: '.5'}}> You just need to be shown the correct approach. Get your customized plan and never look back! </h6>
											<Divider style={{ margin: '15px'}} />
										</div>	: null}		
								</div>
								<p style={{ opacity: '.7'}}> <BlockIcon fontSize='small' style={{ margin: '2px'}}/> No Hidden Fees </p>
								<p style={{ opacity: '.7'}}> <BlockIcon fontSize='small' style={{ margin: '2px'}}/> No Upsells </p>
								<p style={{ opacity: '.7'}}> <BlockIcon fontSize='small' style={{ margin: '2px'}}/> Cancel Anytime </p>
								<div style={{ position: 'relative', width: '100%', padding: '10px 30px 0px 30px'}}>
									<Grow in={!this.state.checkout} hidden={this.state.checkout}>
										<Fab onClick = {() => this.props.handleCheckout(plan)} color="primary" variant="extended" style={{ textAlign: 'center', width: '100%' }}>
											Show me my plan
										</Fab>
									</Grow>
								</div>
							</CardContent>
						</CardFloat>
					</Grow>
				)
			} else {
				return null
			}
		})

		if (plans.length < 1) return <SkeletonCard/>
        
        return plans
    }

}

export default PlanCards