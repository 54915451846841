import React, { Component } from 'react';
import { months } from '../util'
import { Fade } from '@material-ui/core'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIosRounded'

class DateSelector extends Component {
    handleForward = () => {
        let date = new Date(this.props.value)
        date.setDate(date.getDate() + 1)
        this.props.handleChange(date)
    }

    handleBack = () => {
        let date = new Date(this.props.value)
        date.setDate(date.getDate() - 1) 
        this.props.handleChange(date)
    }

    handleDate = () => {
        let date = new Date()
        let yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        let tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        if (this.props.value.getDate() === date.getDate() && this.props.value.getMonth() === date.getMonth() && this.props.value.getYear() === date.getYear()) return `Today, ${months[this.props.value.getMonth()]} ${this.props.value.getDate()}`
        else if (this.props.value.getDate() === tomorrow.getDate() && this.props.value.getMonth() === tomorrow.getMonth() && this.props.value.getYear() === tomorrow.getYear()) return `Tomorrow, ${months[this.props.value.getMonth()]} ${this.props.value.getDate()}`
        else if (this.props.value.getDate() === yesterday.getDate() && this.props.value.getMonth() === yesterday.getMonth() && this.props.value.getYear() === yesterday.getYear()) return `Yesterday, ${months[this.props.value.getMonth()]} ${this.props.value.getDate()}`
        else return `${months[this.props.value.getMonth()]} ${this.props.value.getDate()}, ${this.props.value.getFullYear()}`
    }

    render() {
        return (
            <div style={{ display: 'flex', alignItems: 'center', margin: '15px 0px 20px 0px', ...this.props.style }}>
                <Fade key={this.handleDate()} in={true}>
                    <h6 style={{ fontSize: '15px', margin: '0px 5px 0px 5px', textAlign: 'center' }}> {this.handleDate()} </h6>
                </Fade>
                <div style={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
                    <ArrowBackIosIcon style={{ opacity: '.7', fontSize: '16px', cursor: 'pointer', marginRight: '5px' }} onClick={this.handleBack}/>
                    <ArrowForwardIosIcon style={{ opacity: '.7', fontSize: '16px', cursor: 'pointer' }} onClick={this.handleForward}/>
                </div>
            </div> 
        );
    }
}

export default DateSelector;