//Classes and Functions
import React from 'react'
//Components and Pages
import { Skeleton } from '@material-ui/lab'
import { CardFloat } from './'


const SkeletonCard = (props) => {
    return (
        <CardFloat outline style={{ padding: '6px', paddingTop: '15px', height: '100px' }}>
            <Skeleton variant="rect" height={100} width={70} style={{ position: 'absolute', borderRadius: '25px 0px 0px 25px', bottom: '0px', left: '0px' }}/>
            <Skeleton variant="text" style={{ width: '60px', height: '20px', marginLeft: '80px', borderRadius: '8px', position: 'absolute', bottom: '60px'}}/>
            <Skeleton variant="text" style={{ width: '150px', marginLeft: '80px', height: '20px', borderRadius: '8px', position: 'absolute', bottom: '40px'}}/>
            <Skeleton variant="text" style={{ width: '120px', marginLeft: '80px', height: '20px', borderRadius: '8px', position: 'absolute', bottom: '20px'}}/>
        </CardFloat>
    )
}

export default SkeletonCard