//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { signout, updateProfile, updateSubscription, generatePlans } from '../actions/authActions'
import { openTutorial } from '../actions/dashboardActions'
//Components and Pages
import { Fade, Slider } from '@material-ui/core';
import { ChangePasswordModal, Modal, DateInput, BackButton, PageCard, CardFloat, BodyFatCard, StickyHeader, ModalButton, Input, GenerationCalendar } from '../components'
import { Measure, months,  allergies, health_conditions } from '../util'
import { Color, toTitleCase, diets } from '../util'
//Images
import { MdCheck } from 'react-icons/md'
import { DiModernizr } from 'react-icons/di'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { MdEmail, MdPhone, MdBreakfastDining, MdTransgender, MdPassword, MdOutlineShortcut, MdPeople, MdDateRange, MdTune, MdAssignmentInd, MdTipsAndUpdates, MdMedication, MdPieChart, MdFreeCancellation, MdMonitorWeight, MdWaterfallChart, MdNoFood, MdLanguage, MdRowing, MdFilterList, MdStyle } from 'react-icons/md' 
import { BiHealth } from 'react-icons/bi'

const QuestionCard = (props) => {
    return <CardFloat outline style={{ border: props.disabled ? `1px solid ${props.color || Color.PRIMARY}` : null, color: props.disabled ? props.color || Color.PRIMARY : null, backgroundColor: props.disabled ? ((props.color || Color.PRIMARY) + '08') : 'white', transition: 'border .3s ease', textAlign: 'center', padding: '16px', borderRadius: '15px', cursor: 'pointer' }} onClick={props.onClick}>
        {props.children}
    </CardFloat>
}

const Group = (props) => {
    return <Fade in={true}>
        <div style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h6 style={{ opacity: '.7' }}> { props.name } </h6>
                { props.button }
            </div>
            { 
                props.children.length ? props.children.map((child, i) => {
                    return <div>
                        { child }
                        { i < props.children.length - 1 ? <Divider/>: null }
                    </div>
                }) : props.children
            }
        </div>
    </Fade>
}

const Divider = (props) => {
    return <div style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}/>
}

const Line = (props) => {
    return <div style={{ height: '45px', width: '100%', borderRadius: '12px', padding: '2px', display: 'flex', alignItems: 'center', margin: '2px 0px 2px 0px' }} onClick={props.onClick}>
        <div style={{ borderRadius: '50%', backgroundColor: props.color || 'blue', width: '28px', height: '28px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}> 
            { props.icon }
        </div>
        <p style={{ margin: '0px 0px 0px 10px' }}> {props.name} </p>
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <p style={{ opacity: '.7', margin: '0px 5px 0px 0px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden' }}> {props.value} </p>
            <ArrowForwardIosIcon style={{ fontSize: '14px', opacity: '.7' }} />
        </div>
    </div>
}

const pace = {
    1 : {
        head: "Slow",
        body: "I prefer slow, steady and easy"
    },
    2 : {
        head: "Medium",
        body: "I prefer a combination of speed and social life"
    },
    3 : {
        head: "Fast",
        body: "I want to be pushed and challenged. Warning: Fast means FAST!"
    }
}

const fitness_level = {
    1 : {
        head: "Beginner",
        body: "I can do the basics"
    },
    2 : {
        head: "Intermediate",
        body: "I am comfortable with a moderate challenge"
    },
    3 : {
        head: "Advanced",
        body: "I can train with intensity"
    }
}

const fat_percentage = {
    .35 : {
        head: 'Fats',
        body: 'I prefer cheese, nuts, avocados and other fats'
    },
    .3 : {
        head: 'Carbs',
        body: 'I prefer bread, pasta, chips, rice and other carbs'
    },
    .33 : {
        head: 'Balanced',
        body: 'I prefer a mixture of both'
    }
}

const workouts_per_week = {
    0 : {
        head: 'None',
        body: 'I don\'t want to exercise'
    },
    3 : {
        head: '3 Per Week',
        body: 'I want to effectively build muscle without too much commitment'
    },
    4 : {
        head: '4 Per Week',
        body: 'I want to exercise more to build my physique'
    }
}

class Settings extends Component {
    constructor (props) {
        super(props)
		this.state = {
            edit : false,
            editPayment: false,
            changePassword: false,
            changePaymentMethod: false,
            attributeOpen: false,
            attributeActive: null,
            settings : {},
            profile : {},
            options: {},
            value: null
		}
    }

    handleCancel = () => {
        this.props.updateSubscription(this.props.auth.user.subscription.subscription_id, { cancel_at_period_end: true })
    }

    handleRenew = () => {
        this.props.updateSubscription(this.props.auth.user.subscription.subscription_id, { cancel_at_period_end: false })
    }

    getInputAdornment = (type) => {
        if (this.props.auth.user && this.props.auth.user.settings) return Measure[this.props.auth.user.settings.unit][type].unit 
    }

    getDate = (time) => {
        time = new Date(time)
        return `${months[time.getMonth()].substring(0, 3)} ${time.getDate()}, ${time.getFullYear()}`
    }

    renderFields = () => {
        return (
            <div>
                <Group name={'General'}>
                    <Line icon={<MdLanguage/>} color={Color.PROTEIN} name={'Unit'} value={this.props.auth.user.settings.unit}
                        onClick={() => this.openHandler(this.props.auth.user.settings.unit, { name: 'Unit', variable: 'unit', field: 'select', options: ['Imperial', 'Metric'] })}/> 
                    <Line icon={<MdMonitorWeight/>} color={Color.PROTEIN} name={'Target Weight'} value={`${this.props.auth.user.settings.target_weight} ${this.getInputAdornment('mass')}`}
                        onClick={() => this.openHandler(this.props.auth.user.settings.target_weight, { name: 'Target Weight', variable: 'target_weight', field: 'text', type: 'number', suff: this.getInputAdornment('mass') })}/>    
                    <Line icon={<MdWaterfallChart/>} color={Color.PROTEIN} name={'Target Body Fat'} value={Math.round(this.props.auth.user.settings.target_body_fat * 100) + '%'}
                        onClick={() => this.openHandler(this.props.auth.user.settings.target_body_fat, { name: 'Target Body Fat', variable: 'target_body_fat', field: 'slider', min: .05, max: .55, labelFormat: (value) => Math.round(value * 100) + '%', step: .01, body_fat: true })}/>     
                    <Line icon={<MdRowing/>} color={Color.PROTEIN} name={'Pace'} value={pace[this.props.auth.user.settings.pace].head}
                        onClick={() => this.openHandler(this.props.auth.user.settings.pace, { name: 'Pace', variable: 'pace', field: 'select', options: ['Slow', 'Medium', 'Fast'], values: [1, 2, 3], descriptions: ['I prefer slow, steady, and easy', 'I prefer a combination of speed and social life', 'I want to be pushed and challenged'] })}/>       
                    <Line icon={<BiHealth/>} color={Color.PROTEIN} name={'Health Conditions'} value={this.props.auth.user.settings.health_conditions.length ? this.props.auth.user.settings.health_conditions.map((condition, i) => i < this.props.auth.user.settings.health_conditions.length - 1 ? `${condition}, ` : condition ) : 'None'}
                        onClick={() => this.openHandler(this.props.auth.user.settings.health_conditions, { name: 'Health Conditions', variable: 'health_conditions', field: 'select-multi', options: health_conditions })}/>       
                </Group>
                <Group name={'Meal Plan'}>
                    <Line icon={<MdFilterList/>} color={Color.FAT} name={'Diet'} value={toTitleCase(this.props.auth.user.settings.diet)}
                        onClick={() => this.openHandler(this.props.auth.user.settings.diet, { name: 'Diet', variable: 'diet', field: 'select', options: diets })}/>
                    <Line icon={<MdNoFood/>} color={Color.FAT} name={'Allergies'} value={this.props.auth.user.settings.allergies.length ? this.props.auth.user.settings.allergies.map((allergy, i) => i < this.props.auth.user.settings.allergies.length - 1 ? `${allergy}, ` : allergy ) : 'None'}
                        onClick={() => this.openHandler(this.props.auth.user.settings.allergies, { name: 'Allergies', variable: 'allergies', field: 'select-multi-negative', options: allergies })}/>
                    <Line icon={<MdMedication/>} color={Color.FAT} name={'Protein Powder'} value={this.props.auth.user.settings.rec_protein_powder ? 'Yes' : 'No' }
                        onClick={() => this.openHandler(this.props.auth.user.settings.rec_protein_powder, { name: 'Protein Powder', variable: 'rec_protein_powder', field: 'select', options: ['Yes', 'No'], values: [true, false] })}/> 
                    <Line icon={<MdTune/>} color={Color.FAT} name={'Food Preference'} value={fat_percentage[this.props.auth.user.settings.fat_percentage].head}
                        onClick={() => this.openHandler(this.props.auth.user.settings.fat_percentage, { name: 'Food Preference', variable: 'fat_percentage', field: 'select', options: ['Fats', 'Carbs', 'Balanced'], values: [.35, .3, .33], descriptions: ['I prefer cheese, nuts, avocados and other fats', 'I prefer bread, pasta, chips, rice and other carbs', 'I prefer a mixture of both'] })}/>
                    <Line icon={<MdFreeCancellation/>} color={Color.FAT} name={'Meal Planner'}
                        onClick={() => this.openHandler(this.props.auth.user.settings.rec_gen_map, { name: 'Meal Planner', field: 'calendar', variable: 'rec_gen_map' })}/>
                    <Line icon={<DiModernizr/>} color={Color.FAT} name={'Macro Type'} value={this.props.auth.user.settings.simple_macros ? 'Simple' : 'Full' }
                        onClick={() => this.openHandler(this.props.auth.user.settings.simple_macros, { name: 'Macro Type', variable: 'simple_macros', field: 'select', options: ['Simple', 'Full'], values: [true, false] })}/>
                    <Line icon={<MdPieChart/>} color={Color.FAT} name={'Macro Mode'} value={toTitleCase(this.props.auth.user.settings.macro_mode) || 'Generative'}
                        onClick={() => this.openHandler({ macro_mode: this.props.auth.user.settings.macro_mode, macros: this.props.auth.user.settings.custom_macros }, { name: 'Macro Mode', variable: 'macro_mode', field: 'custom_macros', options: ['Generative', 'Custom'], values: ['generative', 'custom'] })}/>
                </Group>
                <Group name={'Account'}>
                    <Line icon={<MdAssignmentInd/>} color={Color.PRIMARY} name={'First Name'} value={this.props.auth.user.first_name} 
                        onClick={() => this.openHandler(this.props.auth.user.first_name, { name: 'First Name', variable: 'first_name', field: 'text', type: 'text', profile: true })}/> 
                    <Line icon={<MdPeople/>} color={Color.PRIMARY} name={'Last Name'} value={this.props.auth.user.last_name}
                        onClick={() => this.openHandler(this.props.auth.user.last_name, { name: 'Last Name', variable: 'last_name', field: 'text', type: 'text', profile: true })}/> 
                    <Line icon={<MdEmail/>} color={Color.PRIMARY} name={'Email'} value={this.props.auth.user.email}
                        onClick={() => this.openHandler(this.props.auth.user.email, { name: 'Email', variable: 'email', field: 'text', type: 'text', profile: true })}/> 
                    <Line icon={<MdPassword/>} color={Color.PRIMARY} name={'Password'}
                        onClick={() => this.openHandler(null, { name: 'Password', field: 'password', exclude_save: true })}/> 
                    <Line icon={<MdPhone/>} color={Color.PRIMARY} name={'Phone Number'} value={this.props.auth.user.phone_number}
                        onClick={() => this.openHandler(this.props.auth.user.phone_number, { name: 'Phone Number', variable: 'phone_number', field: 'text', type: 'text', profile: true })}/> 
                    <Line icon={<MdBreakfastDining/>} color={Color.PRIMARY} name={'Occupation'} value={this.props.auth.user.occupation}
                        onClick={() => this.openHandler(this.props.auth.user.occupation, { name: 'Occupation', variable: 'occupation', field: 'text', type: 'text', profile: true })}/> 
                    <Line icon={<MdTransgender/>} color={Color.PRIMARY} name={'Gender'} value={this.props.auth.user.gender}
                        onClick={() => this.openHandler(this.props.auth.user.gender, { name: 'Gender', variable: 'gender', field: 'select', type: 'text', options: ['Male', 'Female', 'Other'], profile: true })}/> 
                    <Line icon={<MdDateRange/>} color={Color.PRIMARY} name={'Date of Birth'} value={this.getDate(this.props.auth.user.dob)}
                        onClick={() => this.openHandler(this.props.auth.user.dob, { name: 'Date of Birth', variable: 'dob', field: 'date', profile: true })}/> 
                    { /* <Line icon={<MdStyle/>} color={Color.PRIMARY} name={'Subscription'} value={this.props.auth.user.subscription.status}/> */ }
                </Group>

                { this.renderHandler() }
            </div>
        )
    }

    handleSave = async (variable, value, profile) => {
        this.setState({ loading: true })
        let updates = {}
        
        // Pre-update handlers
        switch (variable) {
            case ('macro_mode') :
                updates = { settings : { macro_mode: value.macro_mode }}
                if (value.macro_mode === 'custom') {
                    if (!value.macros) {
                        this.setState({ loading: false })
                        return
                    }

                    updates = {
                        settings: {
                            ...updates.settings,
                            custom_macros: {
                                calories: Number(value.macros.calories),
                                carbs: Number(value.macros.carbs),
                                protein: Number(value.macros.protein),
                                fat: Number(value.macros.fat)
                            },
                        },
                        preserve: ['settings.custom_macros']
                    }
                }
                break
            default :
                updates = { profile: profile ? { [variable]: value } : undefined, settings: !profile ? { [variable]: value } : undefined }
        }

        this.props.updateProfile(this.props.auth.user._id, updates).then((response) => {
            this.setState({ loading: false, open: false })

            // Post-update handlers
            switch (variable) {
                case ('pace'):
                case ('diet'):
                case ('allergies'):
                case ('rec_protein_powder'):
                case ('fat_percentage'):
                    this.props.generatePlans(this.props.auth.user._id, { offset: 0 })
            }
        }).catch((error) => {
            this.setState({ loading: false })
        })
    }

    handleChange = (temp_value) => {
        this.setState({ temp_value })
    }

    openHandler = (temp_value, options) => {
        this.setState({ temp_value, options, open: true }) // options = { component, field, type, options, profile, name, suff, max, min, step }
    }

    renderHandler = () => {
        let input = null
        switch (this.state.options?.field) {
            case ('text') : {
                input = (
                    <Input 
                        disablePlaceholder
                        style={{ marginBottom: '10px' }}
                        type={this.state.options.type}
                        value={this.state.temp_value}
                        suff={this.state.options.suff}
                        onChange={(event) => this.handleChange(event.target.value)}
                        placeholder="Amount"/>
                )
                break
            }

            case ('slider') : {
                input = (
                    <div>
                        <Slider min={this.state.options.min} valueLabelFormat={this.state.options.labelFormat} value={this.state.temp_value} step={this.state.options.step} max={this.state.options.max} valueLabelDisplay="auto" style={{ marginLeft: '5px', marginRight: '5px'}} onChange={(event, value) => this.handleChange(value)}/>                                    
                        { this.state.options.body_fat ? <BodyFatCard style={{ marginTop: '0px' }} foreground={{ gender: this.props.auth.user.gender, body_fat: this.state.temp_value }}/> : null }
                    </div>
                )
                break
            }

            case ('select') : {
                input = (
                    <div>
                        { 
                            this.state.options.options.map((option, i) => {
                                return <QuestionCard disabled={this.state.temp_value == (this.state.options?.values?.[i] === undefined ? option : this.state.options?.values?.[i])} onClick={(event) => this.handleChange(this.state.options?.values?.[i] === undefined ? option : this.state.options?.values?.[i])}>
                                    <div>
                                        <h6 style={{ fontSize: '14px', marginBottom: '0px', margin: '0px 30px 0px 30px'}}> { toTitleCase(option) } </h6> 
                                        { this.state.options.descriptions ? <h6 style={{ opacity: '.7', fontSize: '12px', marginBottom: '0px', marginTop: '5px' }}> { this.state.options.descriptions[i] } </h6> : null }
                                    </div>                                 
                                </QuestionCard>
                            })
                        }
                    </div>
                )
                break 
            }

            case ('custom_macros') : {
                input = (
                    <div>
                        { 
                            this.state.options.options.map((option, i) => {
                                return <QuestionCard disabled={this.state.temp_value.macro_mode == (this.state.options?.values?.[i] === undefined ? option : this.state.options?.values?.[i])} onClick={(event) => this.handleChange({ ...this.state.temp_value, macro_mode: this.state.options?.values?.[i] === undefined ? option : this.state.options?.values?.[i]})}>
                                    <div>
                                        <h6 style={{ fontSize: '14px', marginBottom: '0px', margin: '0px 30px 0px 30px'}}> { toTitleCase(option) } </h6> 
                                        { this.state.options.descriptions ? <h6 style={{ opacity: '.7', fontSize: '12px', marginBottom: '0px', marginTop: '5px' }}> { this.state.options.descriptions[i] } </h6> : null }
                                    </div>                                 
                                </QuestionCard>
                            })
                        }
                        { this.state.temp_value.macro_mode === 'custom' ? <div style={{ padding: '0px 5px', marginTop: '30px' }}>
                            {/* First row */}
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <div style={{ flex: '1' }}>
                                    <Input placeholder='Carbs' value={this.state.temp_value.macros?.carbs} suff='g' onChange={(e) => this.handleChange({ ...this.state.temp_value, macros: { ...this.state.temp_value.macros, carbs: e.target.value }})}/>
                                </div>
                                <div style={{ flex: '1' }}>
                                    <Input placeholder='Protein' value={this.state.temp_value.macros?.protein} suff='g' onChange={(e) => this.handleChange({ ...this.state.temp_value, macros: { ...this.state.temp_value.macros, protein: e.target.value }})}/>
                                </div>
                                <div style={{ flex: '1' }}>
                                    <Input placeholder='Fat' value={this.state.temp_value.macros?.fat} suff='g' onChange={(e) => this.handleChange({ ...this.state.temp_value, macros: { ...this.state.temp_value.macros, fat: e.target.value }})}/>
                                </div>
                            </div>

                            {/* Second row */}
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <div style={{ flex: '1' }}>
                                    <Input placeholder='Calories' value={this.state.temp_value.macros?.calories} suff='cals' onChange={(e) => this.handleChange({ ...this.state.temp_value, macros: { ...this.state.temp_value.macros, calories: e.target.value }})}/>
                                </div>
                            </div>
                            
                            {/* Custom macro handling here */}
                        </div> : null }
                    </div>
                )
                break   
            }

            case ('select-multi') : {
                input = (
                    <div>
                        { 
                            this.state.options.options.map((option, i) => {
                                return <QuestionCard disabled={this.state.temp_value.includes(this.state.options?.values?.[i] || option)} onClick={(event) => this.handleChange(this.state.temp_value.includes(this.state.options?.values?.[i] || option) ? this.state.temp_value.filter((ans) => ans !== (this.state.options?.values?.[i] || option)) : [...this.state.temp_value, this.state.options?.values?.[i] || option])}>
                                    <div>
                                        { this.state.temp_value.includes(this.state.options?.values?.[i] || option) ? <MdCheck style={{ position: 'absolute', right: '15px', top: '50%', transform: 'translateY(-50%)', fontSize: '16px' }}/> : null }
                                        <h6 style={{ fontSize: '14px', marginBottom: '0px', margin: '0px 30px 0px 30px'}}> { toTitleCase(option) } </h6> 
                                    </div>                                 
                                </QuestionCard>
                            })
                        }
                    </div>
                )
                break 
            }

            case ('select-multi-negative') : {
                input = (
                    <div>
                        { 
                            this.state.options.options.map((option, i) => {
                                const is_selected = this.state.temp_value.includes(this.state.options?.values?.[i] || option)
                                return <QuestionCard color={Color.CALORIES_ERROR} disabled={is_selected} onClick={(event) => this.handleChange(this.state.temp_value.includes(this.state.options?.values?.[i] || option) ? this.state.temp_value.filter((ans) => ans !== (this.state.options?.values?.[i] || option)) : [...this.state.temp_value, this.state.options?.values?.[i] || option])}>
                                    <div>
                                        <h6 style={{ fontSize: '14px', marginBottom: '0px', margin: '0px 30px 0px 30px'}}> { !is_selected ? toTitleCase(option) : <strike> {toTitleCase(option)} </strike> } </h6> 
                                    </div>                                 
                                </QuestionCard>
                            })
                        }
                    </div> 
                )
                break
            }

            case ('date') : {
                input = (
                    <DateInput value={this.state.temp_value} handleChange={this.handleChange}/>
                )
                break
            }

            case ('calendar') : {
                input = <GenerationCalendar value={this.state.temp_value} handleChange={this.handleChange}/>
                break
            }

            case ('password') : {
                input = <div style={{ marginBottom: '-15px' }}> <ChangePasswordModal handleClose={() => this.setState({ open: false })}/> </div>
                break
            }

            case ('custom') : {
                input = this.state.options.component(this.state.value)
                break
            }
        }

        return (
            <Modal backdrop direction="up" in={this.state.open} handleClose={() => this.setState({ open: false })} head={this.state.options?.name}>
                <div style={{ margin: '10px 0px 15px 0px', ...this.state?.options.style }}>
                    { input }
                </div>
                { !this.state.options.exclude_save ? <ModalButton icon={<MdOutlineShortcut/>} label={'Save'} loading={this.state.loading} handleClick={() => this.handleSave(this.state.options.variable, this.state.temp_value, this.state.options.profile)}/> : null }
            </Modal>
        )
    }

    /*renderCancel = () => {
        return (
            <Modal direction='up' in={this.state.cancel} handleClose={() => this.setState({ cancel: false })} head={'Cancellation Form'}>
                <Card style={{margin: '5px', marginTop: '20px', borderRadius: '25px', marginBottom: '20px', paddingTop: '10px',  position: 'relative',  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09)'}}>
                    <CardContent>
                        <h6> Cancellation Form </h6>
                        <div style={{ margin: '10px'}}>
                            <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Reason </h6> 
                            <Input 
                                select
                                style={{ marginBottom: '10px', width: '100%' }}
                                placeholder="Servings"
                                onChange = {(event) => {
                                this.handleChange("servingType", event.target.value)}}>
                                    { ['Other'].map((portion, i) => {
                                        return <MenuItem key={i} value={portion}> {portion} </MenuItem>
                                    })}
                            </Input>
                        </div>
                        <div style={{ margin: '10px'}}>
                            <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Details </h6> 
                            <Input 
                                style={{ marginBottom: '10px', width: '100%' }}
                                value={this.state.servingType} 
                                placeholder="Explain"
                                multiline/>
                        </div>
                    </CardContent>
                </Card>
                <Fab disabled={this.props.auth.subscriptionLoading} onClick={this.handleCancel}  style={{ marginBottom: '20px', outline: 'none', float: 'right' }} color="primary" variant="extended">
                    {this.props.auth.subscriptionLoading ? <CircularProgress size={20} /> : 'Cancel'}
                </Fab>
            </Modal>
        )
    }*/

    render() {
        return (
            <div>
                <StickyHeader>
                    <h5 style={{ marginBottom: '0px' }}> Settings </h5>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', color: Color.PRIMARY, cursor: 'pointer' }} onClick={this.props.openTutorial}>
                        <p style={{ marginBottom: '0px', fontSize: '16px' }}> Tutorial </p>
                        <MdTipsAndUpdates style={{ fontSize: '16px', marginLeft: '6px', marginTop: '2px' }}/>
                    </div>
                </StickyHeader>
            
                <PageCard title={'Settings'} style={{ paddingTop: '0px' }}>
                    { this.renderFields() }
                    <BackButton/>
                </PageCard>
                { /* this.props.auth.user ? <CancelModal open={this.state.cancel} handleClose={() => this.setState({ cancel: false })}/> : null */ }
                { /* this.props.auth.user ? <CheckoutModal changePaymentMethod type="edit" open={this.state.editPayment} name={"Test"} handleClose={() => this.setState({ editPayment: false })}/> : null */ }
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        signout,
        updateProfile,
        updateSubscription,
        openTutorial,
        generatePlans
     }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Settings))
