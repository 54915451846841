import { useState } from "react"
import { bindActionCreators } from "redux"
import { createMealPlan } from "../actions/dashboardActions"
import { connect } from "react-redux"
import { CardFloat, ChipSelector, Modal, ModalButton } from './'
import { MdAddCircle } from "react-icons/md"

const CreateMealPlanModal = (props) => {
    const [length, setLength] = useState(3)
    const [type, setType] = useState('generative')
    const [loading, setLoading] = useState(false)

    const handleCreate = () => {
        setLoading(true)
        props.createMealPlan({
            user_id: props.auth.user._id,
            length
        }).then((response) => {
            props.handleClose()
        }).catch((error) => {

        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Modal in={props.open} direction={'up'} handleClose={props.handleClose} head={'Create Meal Plan'}>
            <CardFloat style={{ backgroundColor: '#DDD', fontSize: '14px', color: '#666', padding: '15px', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <p style={{ margin: '0px' }}> <b>Diet Type</b>: { props.auth.user.settings.diet } </p>
                <p style={{ margin: '0px' }}> <b>Allergies</b>: { props.auth.user.settings.allergies.length ? props.auth.user.settings.allergies.map((allergy, i) => `${allergy}${i === props.auth.user.settings.allergies.length - 1 ? '' : ', '}`) : 'None'} </p>
                <p style={{ margin: '0px' }}> <b>Macros</b>: { props.auth.user.settings.macro_mode } {`(${props.auth.user.settings.macros.calories} calories, ${props.auth.user.settings.macros.carbs}g carbs, ${props.auth.user.settings.macros.protein}g protein, ${props.auth.user.settings.macros.fat}g fat)`} </p>
            </CardFloat>
            <div style={{ display: 'flex', marginBottom: '20px', padding: '5px', gap: '10px' }}>
                <div style={{ flex: '1' }}>
                    <h6> Days </h6>
                    <ChipSelector value={length} handleChange={setLength} options={[{ value: 1, label: '1 day' }, { value: 2, label: '2 days' }, { value: 3, label: '3 days' }]} style={{ borderRadius: '10px', maxWidth: '' }}/>
                </div>
                <div style={{ flex: '1' }}>
                    <h6> Type </h6>
                    <ChipSelector value={type} handleChange={setType} options={[{ value: 'generative', label: 'Generative' }, { value: 'interactive', label: 'Interactive' }]} style={{ borderRadius: '10px', maxWidth: '' }}/>
                </div>
            </div>
            <ModalButton icon={<MdAddCircle/>} label={'Create Meal Plan'} loading={loading} handleClick={handleCreate}/>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        dash: state.dash,
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        createMealPlan
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMealPlanModal)