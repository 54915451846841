import React, { useState } from 'react'
import { Skeleton } from '@material-ui/lab'
import { CardElement } from '@stripe/react-stripe-js'
import { Color } from '../util'
import styled from 'styled-components'

const Container = styled.div`
    transition: all 300ms ease;
    margin-bottom: 10px;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.03);
    border-radius: 10px;
    background-color: white;
    display: flex;
    padding: 11px 10px 11px 10px;
    border: 1px solid ${(props) => props.error ? Color.CALORIES_ERROR : (props.focused ? Color.PRIMARY : 'rgba(0,0,0,.1)')};
`

const CardElementLoad = (props) => { 
    const [inputLoaded, setInputLoaded] = useState(false)
    const [focused, setFocused] = useState(false)

    return (
        <Container focused={focused}>
            <div style={{ display: !inputLoaded ? 'none' : null, width: '100%' }}>
                <CardElement onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} onReady={() => setInputLoaded(true)}/>
            </div>
            <Skeleton variant={'rect'} animation={'wave'} style={{ height: '16.8px', width: '100%', display: inputLoaded ? 'none' : null, borderRadius: '25px' }}/>
        </Container>
    )
}

export default CardElementLoad