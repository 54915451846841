import React, { useState } from 'react'
import styled from 'styled-components'
import DeleteIcon from '@material-ui/icons/Delete';

const Hover = styled.div`
    position: relative;
    transition: background-color .2s ease;

    &:hover {
        background-color: #f2f2f2;
    }
`

const Card = styled.div`
    background-color: white;
    padding: 6px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09);

    animation: fade .3s;

    @keyframes fade {
        from { 
            opacity: 0;
        }
        to   { 
            opacity: 1;
        }
    }
`

const CardHover = styled.div`
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateY(-100%) translateX(-50%);
    padding-bottom: 5px;
    z-index: 100;
`

const CardBubble = styled.div`
    transform: translateY(-100%) translateX(-50%) rotate(45deg);
    background-color: white;
    border-radius: 1px;
    position: absolute;
    bottom: -6px;
    width: 8px;
    height: 8px;
    left: 50%;
`

const Hoverable = (props) => {
    const [hover, setHover] = useState(false)
    
    const handleHover = () => {
        if (props.handleHover) hoverChecker() 
        setHover(true)
    }

    const hoverChecker = () => {
        props.handleHover()
    }

    return (
        <Hover child={props.child} onMouseEnter={handleHover} onMouseLeave={() => setHover(false)}>
            { props.children }
            { hover && props.functions ? <div style={{ display: 'flex', position: 'absolute', right: '0px', bottom: '50%', transform: 'translateY(50%)' }}>
                { props.functions.map((func) => <div onClick={func.handleClick} style={{ padding: '3px', backgroundColor: 'white', borderRadius: '5px', fontSize: '0px', boxShadow: '0 6px 20px 10px rgba(0, 0, 0, 0.15)', cursor: 'pointer', marginRight: '10px' }}>
                    { func.icon }
                </div>) }
            </div> : null }
            { hover && props.tooltip ? <CardHover>
                <Card>
                    <div style={{ overflow: 'auto', maxHeight: '150px', width: '100%' }}>
                        { props.tooltip }
                    </div>
                    <CardBubble/>
                </Card>
            </CardHover> : null }
        </Hover>
    )
}

export default Hoverable