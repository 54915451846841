//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetToken } from '../actions/authActions'
//Components and Pages
import { Link } from 'react-router-dom'
import { Card, CardContent, Grow } from '@material-ui/core';
import { NutritionInfo, RecalibrationCard, FitnessCardGroup, PageCard, HelpButton, FitnessInfo, StickyHeader, FoodWizard } from '../components'
import { Coaching, Articles, Overview, University } from '../pages'
import { timeToLocal, days } from '../util'

class Dashboard extends Component {
    constructor (props) {
        super(props)
		this.state = {
            time: new Date(),
		}
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.resetToken) this.props.resetToken(this.props.location.state.resetToken)
        this.timeClock = setInterval(this.tick, 1000)
    }

    componentWillUnmount() { 
        clearInterval(this.timeClock)
    }

    tick = () => {
        this.setState({ time: new Date()})
    }

    findPlan = (time) => {
        for (let i = 0; i < this.props.dash.plan.length; i++) {
            if (this.props.dash.plan[i].date === time) {
                return this.props.dash.plan[i]
            }
        }

        return false
    }

    findTOD = (time) => {
        let date = new Date(time), hours = date.getHours()
        if (hours < 12) return 'Good Morning'
        else if (hours > 12 && hours < 18) return 'Good Afternoon'
        return 'Good Evening'
    }

    renderFitnessCard = () => {
        let plan = this.findPlan(timeToLocal(this.state.time)), forward = []

        if (!plan || !plan.fitness[0]) {
            for (let index in this.props.dash.plan) {
                let item = this.props.dash.plan[index]
                let date = new Date(item.date)
                if (item.date >= timeToLocal(this.state.time) && item.fitness[0]) {
                    forward.push(date)
                    break
                }
            }
        }

        return (
            <div>
            { !plan || !plan.fitness[0] ?                 
                ( forward.length === 0 ? <Grow in={true}>
                    <Card style={{ position: 'relative', borderRadius: '25px', marginBottom: '15px', backgroundColor: 'grey', opacity: '.5', marginTop: '20px'}}>
                        <CardContent>
                            <h6 style={{ textAlign: 'center' , marginTop: '20px'}}> No Workouts for Today </h6>
                        </CardContent>
                    </Card>
                </Grow> : <Grow in={true}>
                    <Card style={{ position: 'relative', borderRadius: '25px', marginBottom: '15px', backgroundColor: 'grey', opacity: '.5', marginTop: '20px'}}>
                        <CardContent>
                            <h6 style={{ textAlign: 'center' , marginTop: '20px'}}> Next Workout on {days[forward[0].getUTCDay()]} </h6>
                        </CardContent>
                    </Card>
                </Grow> ) : 
                <FitnessCardGroup workouts={plan.fitness} expandLink={{ pathname: '/dashboard/fitness', state: { tab: 1, expanded: plan.date }}} preview/>
            }
            </div>
        )
    }

    render() { 
        return (
            <div>
                <StickyHeader>
                    <h5 style={{ marginBottom: '0px' }}> {this.findTOD(Date.now())}, {this.props.auth.user ? this.props.auth.user.first_name : 'name'}  </h5>
                    <HelpButton/>
                </StickyHeader>
                <PageCard title={'Dashboard'} style={{ paddingTop: '0px' }}>
                    <Link to={{ pathname: '/dashboard/overview' }} style={{ textDecoration: 'none', color: 'inherit'}}>
                        <Overview cards maxCards={2} history={this.props.history} style={{ marginTop: '0px'}}/>
                        <h6 style={{ fontSize: '12px', opacity: '.5', textAlign: 'center', marginBottom: '0px' }}> Overview </h6>
                    </Link>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '5px', width: 'calc(50% - 5px)' }}>
                            <Link to={{ pathname: '/dashboard/nutrition' }} style={{ textDecoration: 'none', color: 'inherit'}}>
                                <NutritionInfo mini time={this.state.time}/>
                                <h6 style={{ fontSize: '12px', opacity: '.5', textAlign: 'center', marginBottom: '0px' }}> Nutrition </h6>
                            </Link>
                        </div>
                        <div style={{ marginLeft: '5px', width: 'calc(50% - 5px)' }}>
                            <Link to={{ pathname: '/dashboard/stnbly-u', state: { from: 'Dashboard' }}} style={{ textDecoration: 'none', color: 'inherit'}}>
                                <University preview max={1}/>
                                <h6 style={{ fontSize: '12px', opacity: '.5', textAlign: 'center', marginBottom: '0px' }}> University </h6>
                            </Link>
                        </div>
                    </div>
                    <Link to={{ pathname: '/dashboard/nutrition' }} style={{ textDecoration: 'none', color: 'inherit'}}>
                        <div style={{ margin: '15px 5px 0px 5px' }}>
                            <FoodWizard active handleAddOpen={(e) => {
                                e.stopPropagation(); e.preventDefault()
                                this.props.history.push('/dashboard/nutrition')
                            }}/>
                        </div>
                        <h6 style={{ fontSize: '12px', opacity: '.5', textAlign: 'center' }}> Food Wizard </h6>
                    </Link>
                    <Link to={{ pathname: '/dashboard/articles', state: { from: 'Dashboard' }}} style={{ textDecoration: 'none', color: 'inherit'}}>
                        <Articles preview max={1}/>
                        <h6 style={{ fontSize: '12px', opacity: '.5', textAlign: 'center', marginBottom: '0px' }}> Articles </h6>
                    </Link>
                </PageCard>   
            </div>  
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        resetToken }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
