import React, { Component } from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add'
import { CircularProgress, Fade } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { addSessions } from '../actions/dashboardActions'
import { NutritionBar, RecommendedCardGroup } from './'
import { Times, Color, localStart, localEnd } from '../util'
import { MdContentCopy } from 'react-icons/md'
import apis from '../api'

class MealGroup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: this.handleOpenStart(new Date())
        }
    }

    handleOpenStart = (date) => {
        switch(this.props.name.toLowerCase()) {
            case ('breakfast') :
                return (date.getHours() <= Times.LUNCH)
            case ('lunch') :
                return (date.getHours() <= Times.DINNER && date.getHours() > Times.LUNCH)
            case ('dinner') :
                return (date.getHours() > Times.DINNER)
            default :
                return false
        }
    }

    validateCopy = (copy) => {
        let previousMeal = this.props.dash.sessions.find((session) => session.type === 'food' && ((new Date(session.time)).getDate()) === this.props.trackingTime.getDate() - 1 && session.metadata.meal === this.props.name.toLowerCase())
        return copy && !this.props.children?.length && previousMeal
    }

    handleCopy = () => {
        let temp = new Date(this.props.trackingTime)
        temp.setDate(temp.getDate() - 1)
        let start = localStart(temp), end = localEnd(temp)
        this.setState({ copyLoading: true })
        apis.copySessions({ time: { $gte: start, $lt: end }, user_id: this.props.auth.user._id, type: 'food', ["metadata.meal"]: this.props.name.toLowerCase(), new_time: this.props.trackingTime.getTime() }, {}).then((response) => {
            this.setState({ copyLoading: false })
            this.props.addSessions(response.data)
        }).catch((error) => {
            this.setState({ copyLoading: false })
            console.log(error)
        })
    }

    render() {
        let { name, carbs, protein, fat, calories } = this.props

        return (
            <div>
                <div style={{ marginBottom: '8px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', position: 'relative', marginTop: '8px', ...this.props.style }} onClick={this.props.expandable ? () => this.setState({ open: !this.state.open }) : null}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h6 style={{ marginBottom: '0px' }}> {name} </h6>
                            { this.props.expandable ? (this.state.open ? <RemoveIcon style={{ fontSize: '16px', opacity: '.7', marginLeft: '5px' }}/> : <AddIcon style={{ fontSize: '16px', opacity: '.7', marginLeft: '5px' }} />) : null }
                            { !this.props.expandable && this.props.handleAddOpenRevised ? <AddIcon style={{ color: 'white', backgroundColor: Color.PRIMARY, padding: '3px', borderRadius: '50%', fontSize: '19px', marginLeft: '5px' }} onClick={this.props.handleAddOpenRevised}/> : null }
                        </div>
                        { !this.props.nobar ? <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                            <p style={{ marginBottom: '0px', fontSize: '12px', marginRight: '5px', opacity: '.7' }}> {calories} Cals </p>
                            <div style={{ marginTop: '15px' }}>
                                <NutritionBar key={this.props.trackingTime} style={{ width: '100px', height: '8px', position: 'relative' }} carbs={carbs} fat={fat} protein={protein}/>
                                <div style ={{ display: 'flex', justifyContent: 'end', gap: '5px' }}>
                                    <p style={{ marginBottom: '0px', fontSize: '12px', opacity: '.7', color: Color.CARBS }}> {Math.round(carbs)}C </p>
                                    <p style={{ marginBottom: '0px', fontSize: '12px', opacity: '.7', color: Color.PROTEIN }}> {Math.round(protein)}P </p>
                                    <p style={{ marginBottom: '0px', fontSize: '12px', opacity: '.7', color: Color.FAT }}> {Math.round(fat)}F </p>
                                </div>
                            </div>
                        </div> : null }
                    </div>
                    
                    { !this.props.nocopy && this.validateCopy(this.props.copy) ? ( this.state.copyLoading ? <div style={{ display: 'flex', justifyContent: 'center', margin: '17px' }}><CircularProgress size={20}/></div> : <Fade in={true}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                            <div style={{ display: 'flex', alignItems: 'center', color: Color.PRIMARY, fontSize: '13px', marginTop: '2px', padding: '6px 0px 6px 9px', borderRadius: '25px', color: Color.PRIMARY, marginBottom: '-12px' }} onClick={this.handleCopy}>
                                <MdContentCopy style={{ marginRight: '5px' }}/>
                                <p style={{ marginBottom: '0px' }}> Copy Yesterday </p>
                            </div>
                        </div> 
                    </Fade>) : null }
                </div>

                <div style={{ display: this.state.open || !this.props.expandable ? null : 'none' }}>
                    { this.props.children }
                    { this.props.recommended && this.props.children.length < 2 ? <RecommendedCardGroup meal={name.toLowerCase()} handleAddOpen={this.props.handleAddOpen} handleInfoOpen={this.props.handleInfoOpen}/> : null }
                </div>         
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        addSessions
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MealGroup)