import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { loginUser } from '../actions/authActions'
import { CardContent, Fade, Backdrop, CircularProgress } from '@material-ui/core'
import { CardFloat, Input, ModalButton, PageCard, OTPCard } from '../components'
import { Color } from '../util';
import api from '../api'
import { MdOutlineShortcut } from 'react-icons/md'

import stnbly from '../images/sustainablyapp.svg'
import google from '../images/google.svg'

class SignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            success: false,
            user: null,
            error: { 
                email: null,
                password: null
            },
        }
    }

    handleChange = (variable, value) => {
        this.setState({ [variable] : value, error: { ...this.state.error, [variable]: null }})
    }

    handleRedirect = () => {
        let urlParams = new URLSearchParams(this.props.location.search), redirect_uri = urlParams.get('redirect_uri');
        if (redirect_uri) window.location.href = redirect_uri
        else window.location.href = '/dashboard'
    }

    handleKeyPress = (event) => {
        if (!this.state.user && event && event.key === 'Enter') return this.handleVerifyEmail()
        if (event && event.key === 'Enter') return this.handleLogin()
    }

    handleLogin = () => {
        this.props.loginUser({ email: this.state.email.toLowerCase(), password: this.state.password }).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.setState({ error: { password: error.response.data.msg, email: null }})
        })
    }

    handleVerifyEmail = () => {
        this.setState({ verifyLoading: true })
        api.verifyEmail({ email: this.state.email }).then((response) => {
            this.setState({ user: response.data.user, needs_verification: response.data.needs_verification, verifyLoading: false })
        }).catch((error) => {
            this.setState({ verifyLoading: false, error: { password: null, email: error.response.data.msg }})
        })
    }

    handleSuccess = () => {
        this.setState({ success: true })
        setTimeout(this.handleRedirect, 400)
    }

    handleError = (error) => {
        this.setState({ error: true, errorMessage: error })
        setTimeout(() => {
            this.setState({ error: false, errorMessage: null })
        }, 1000)
    }

    render() {
        return (
            <PageCard style={{ padding: '0px' }} title={'Sign In'}>
                <div style={{ display: 'flex', justifyContent: 'center', height: '70px', borderBottom: '1px solid rgba(0,0,0,.1)'  }}>
                    <img alt='Sustainably Logo' src={stnbly} style={{ width: '50px' }} />
                </div>
                <div style={{ maxWidth: '450px', margin: 'auto', padding: '0px 20px 15px 20px' }}>
                    <div>
                        <Backdrop transitionDuration={600} open={false} style={{ zIndex: '200', color: 'white' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-50px', justifyContent: 'center', alignItems: 'center' }}>
                                <h5> Activating your account </h5>
                                <CircularProgress size={40} style={{ color: 'white' }}/>
                            </div>
                        </Backdrop>
                        <h6 style={{ textAlign: 'center', fontSize: '18px', marginTop: '20px' }}> To continue, sign into Sustainably </h6>
                        <div style={{ paddingBottom: '30px', borderBottom: '1px solid rgba(0,0,0,.1)' }}>
                            { !this.state.user ? <Fade in={true} key={'email'}>
                                <CardFloat style={{ marginBottom: '15px'}}>
                                    <CardContent>
                                        <Input error={this.state.error.email} value={this.state.email} onFocus={() => this.setState({ error: { ...this.state.error, email: null } })} onKeyPress={this.handleKeyPress} onChange={(event) => this.handleChange('email', event.target.value)} placeholder={'Email Address'} type='email' style={{ width: '100%'}}/>
                                        <ModalButton icon={<MdOutlineShortcut/>} success={this.state.success} loading={this.state.verifyLoading} handleClick={this.handleVerifyEmail} label={'Continue'} style={{ position: 'relative', margin: '20px 0px 0px 0px', width: '100%', bottom: '0px' }}/>
                                    </CardContent>
                                </CardFloat>
                            </Fade> : ( !this.state.needs_verification ? <Fade in={true} key={'password'}>
                                <CardFloat style={{ marginBottom: '15px'}}>
                                    <CardContent>
                                        <Input autoFocus error={this.state.error.password} value={this.state.password} onKeyPress={this.handleKeyPress} onChange={(event) => this.handleChange('password', event.target.value)} placeholder={'Password'} type='password' style={{ width: '100%' }}/>
                                        <ModalButton icon={<MdOutlineShortcut/>} success={this.state.success} loading={this.props.auth.isLogging} handleClick={this.handleLogin} label={'Sign In'} style={{ position: 'relative', margin: '20px 0px 0px 0px', width: '100%', bottom: '0px' }}/>
                                    </CardContent>
                                </CardFloat>
                            </Fade> : <OTPCard handleRedirect={this.handleRedirect} user_id={this.state.user._id} email={this.state.user.email}/> )}
                            <div style={{ textAlign: 'center', marginBottom: '30px', paddingBottom: '30px', borderBottom: '1px solid rgba(0,0,0,.1)', position: 'relative'  }}>
                                <a href={'/reset'} style={{ color: Color.PRIMARY }}> Forgot your password? </a>
                                <p style={{ backgroundColor: 'white', position: 'absolute', bottom: '-28px', padding: '0px 15px 0px 15px', left: '50%', transform: 'translateX(-50%)' }}> Or </p>
                            </div>
                            <div style={{ padding: '0px 20px 0px 20px'}}>
                                <a href={process.env.NODE_ENV === 'production' ? `${process.env.PUBLIC_URL}/api/auth/google` : 'http://localhost:8000/api/auth/google'} style={{ textDecoration: 'none' }}>
                                    <ModalButton icon={<img alt='Google Logo' src={google} style={{ height: '20px' }}/>} label={'Sign in with Google'} style={{ backgroundColor: 'black', marginBottom: '0px' }}/>
                                </a>
                                {/* a href={'http://localhost:8000/api/auth/google'} style={{ textDecoration: 'none' }}>
                                    <ModalButton icon={<img src={facebook} style={{ height: '20px' }}/>} label={'Sign in with Facebook'} style={{ backgroundColor: 'rgb(3,156,229)' }}/>
                                </a> */ }
                            </div>
                        </div>
                        <h6 style={{ textAlign: 'center', fontSize: '18px', marginTop: '20px' }}> Don't have an account? </h6>
                        <div style={{ padding: '0px 20px 0px 20px'}}>
                            <ModalButton handleClick={() => window.location.href = '/signup'} fontColor={Color.PRIMARY} label={'Sign Up for Sustainably'} style={{ position: 'relative', margin: '15px 0px 0px 0px', bottom: '0px', width: '100%', backgroundColor: '', border: '1px solid', borderColor: Color.PRIMARY, boxShadow: '', color: Color.PRIMARY }}/>
                        </div>
                    </div>
                </div>
            </PageCard>
        );
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        loginUser: loginUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn))