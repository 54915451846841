import { useEffect, useState } from "react"
import { CardContent, Card, CircularProgress, Slide, Fade } from "@material-ui/core"
import InfiniteScroll from 'react-infinite-scroll-component'
import { ModalSlide, Image } from './'
import { Color, toTitleCase } from '../util'
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import api from "../api"
import { TbRepeat } from 'react-icons/tb'

const limit = 20

const SearchListing = (props) => {
    const renderFood = (food) => {
        if (!food) return null
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                { props.food.image_url ? <Image src={props.food.image_url} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', backgroundColor: 'grey', border: 'none', marginRight: '8px' }}/> : null }
                <p style={{ margin: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '80%' }}> { toTitleCase(props.food.name) } </p>       
                <div style={{ marginLeft: 'auto', flexShrink: '0', backgroundColor: 'rgba(0,0,0,.05)', color: Color.PRIMARY, borderRadius: '50%', width: '28px', height: '28px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <TbRepeat style={{ fontSize: '18px' }}/>
                </div>
            </div>
        )
    }

    return props.food ? <Fade in={true}>
            <div style={{ margin: '10px 0px 10px 0px', padding: '8px', backgroundColor: 'rgba(0,0,0,.05)', borderRadius: '10px', fontSize: '14px' }} onClick={() => props.handleInfoOpen?.({ meal: props.food, populate: true })}>
            { renderFood(props.food) } 
        </div>
    </Fade> : null
}

const MealPlanSwapModal = (props) => {
    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    
    const [scrollPosition, setScrollPosition] = useState(0)
    const [back, setBack] = useState(true)
    
    const [pages, setPages] = useState(0)

    useEffect(() => {
        if (props.open) {
            loadSwaps()
        } else {
            setResults([])
            setHasMore(true)
            setPages(0)
        }
    }, [props.open])
    
    const loadSwaps = () => {
        if (!props.food || !props.open || props.loading) return
        setLoading(true)
        api.getMealPlanSwaps({ params: {
            food_id: props.food._id,
            calories: props.food.calories,
            protein: props.food.protein,
            skip: pages * limit,
            limit
        }}).then((response) => {
            setPages(pages + 1)
            setHasMore(response.data.has_more)
            setResults([...results, ...response.data.data])
        }).catch((error) => {
            setHasMore(false)
        }).finally(() => {
            setLoading(false)
        })
    }
    
    const handleScroll = (event) => {
        let newScrollPosition = event.target.scrollTop
        if (newScrollPosition < 0) newScrollPosition = 0
        if (newScrollPosition > scrollPosition) setBack(false)
        else setBack(true)
        setScrollPosition(newScrollPosition)
    }

    const handleClose = () => {
        props.handleClose()
    }

    const renderInfiniteScroll = () => {
        return (
            <InfiniteScroll scrollableTarget='scrollableDiv' dataLength={results.length} hasMore={hasMore} next={loadSwaps} loader={
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginBottom: '10px', height: '100%' }}>
                    <CircularProgress size={25}/>
                </div>}>
                { 
                    results && results.length ? results.map((food, i) => {
                        return <SearchListing key={i} food={food} handleInfoOpen={() => props.handleInfoOpen(food)} handleSwap={() => props.handleSwap(food, props.date, props.meal)}/>
                    }) : (!loading && !results.length ? <p style={{ marginTop: '30px', textAlign: 'center', opacity: '.7' }}> No swaps </p> : null)
                }
            </InfiniteScroll>
        )
    }

    return (
        <div>
            <ModalSlide direction='left' in={props.open}>
                <Card id='scrollableDiv' onScroll={handleScroll} style={{ zIndex: '1000', width: '100%', height: '100%', overflowY: 'auto', overflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', boxShadow: '' }}>
                    <CardContent style={{ padding: '20px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', overflow: 'hidden' }}>
                            <BackIcon style={{ fontSize: '22px', marginRight: '8px', opacity: '.5' }} onClick={props.handleClose}/>
                            <h5 style={{ marginBottom: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}> Swap {props.food ? toTitleCase(props.food.name) : 'food'} </h5>
                        </div>
                        
                        { renderInfiniteScroll() }

                        <div style={{ position: 'fixed', left: '50%', bottom: '17px', transform: 'translateX(-50%)', zIndex: '1010'}}>
                            <Slide direction='up' in={back}>
                                <div onClick={handleClose} style={{ backgroundColor: Color.PRIMARY, borderRadius: '25px', padding: '7px 12px 7px 12px',  display: 'flex', alignItems: 'center', boxShadow: '0 6px 20px 10px rgba(0, 0, 0, 0.1)' }}>
                                    <BackIcon style={{ color: 'white', fontSize: '14px', marginRight: '3px' }}/>
                                    <p style={{ fontSize: '14px', color: 'white', marginBottom: '0px'}}> Back to Meal Plan </p>
                                </div>
                            </Slide>
                        </div>
                    </CardContent>
                </Card>
            </ModalSlide>
        </div>
    )
}

export default MealPlanSwapModal