import React, { Component } from 'react';
import { CardContent } from '@material-ui/core'
import { PageCard, CardFloat, ModalButton, Input } from '../components'
import stnbly from '../images/sustainablyapp.svg'
import api from '../api'
import { Color } from '../util'
import { MdOutlineShortcut } from 'react-icons/md'

class PasswordReset extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
			verify_password: '',
            loading: false,
            success: false,
            error: false
        }
    }

    handleChange = (variable, value) => {
        this.setState({ [variable] : value })
    }

    handleForgotPassword = () => {
        this.setState({ loading: true })
		api.userResetPassword(this.props.match.params.token, { new_password: this.state.password }).then((response) => {
            this.setState({ loading: false, success: true })
            setTimeout(() => window.location.href = '/login', 400)
		}).catch((error) => {
            this.setState({ loading: false })
		})
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') this.handleForgotPassword()
    }

    render() {
        return (
            <PageCard style={{ padding: '0px' }} title='Forgot Password'>
                <div style={{ display: 'flex', justifyContent: 'center', height: '70px', borderBottom: '1px solid rgba(0,0,0,.1)'  }}>
                    <img alt='STNBLY Icon' src={stnbly} style={{ width: '50px' }} />
                </div>
                <div style={{ maxWidth: '450px', margin: 'auto' }}>
                    <div>
                        <h6 style={{ textAlign: 'center', fontSize: '18px', marginTop: '20px' }}> Reset your password </h6>
                        <div style={{ padding: '0px 20px 15px 20px' }}>
                            <CardFloat style={{ marginBottom: '15px'}}>
                                <CardContent>
                                    <Input type={'password'} value={this.state.password} onChange={(event) => this.handleChange('password', event.target.value)} placeholder={'New Password'} style={{ width: '100%'}}/>
									<Input type={'password'} value={this.state.verify_password} onKeyPress={this.handleKeyPress} onChange={(event) => this.handleChange('verify_password', event.target.value)} placeholder={'Repeat Password'} style={{ width: '100%'}}/>
									<ModalButton icon={<MdOutlineShortcut/>} disabled={!this.state.password || !this.state.verify_password || this.state.password !== this.state.verify_password} success={this.state.success} loading={this.state.loading} handleClick={this.handleForgotPassword} label={'Change'} style={{ position: 'relative', margin: '20px 0px 0px 0px', width: '100%', bottom: '0px' }}/>
                                </CardContent>
                            </CardFloat>
                            <p style={{ textAlign: 'center', marginBottom: '10px' }}>
                                <a href={'/login'} style={{ color: Color.PRIMARY }}> Back to Sign In </a>
                            </p>
                        </div>
                    </div>
                </div>
            </PageCard>
        );
    }
}

export default PasswordReset;