//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Checkout } from '../pages'
import { ChangePaymentMethod, Modal } from './'
import { Elements, ElementsConsumer}  from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
//Images

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API);

class CheckoutModal extends Component {
	render() {
        return (
            <Modal direction='up' in={this.props.open} handleClose={this.props.handleClose} head={ this.props.changePaymentMethod ? 'Edit Payment Method' : 'Get Your Success Plan' }>
                { !this.props.changePaymentMethod ? 
                    <Elements stripe={stripePromise}>
                        <ElementsConsumer>
                            {({elements, stripe}) => (
                                <Checkout ReactPixel={this.props.ReactPixel} SnapchatPixel={this.props.SnapchatPixel} projection={this.props.projection} code={this.props.code} open={this.props.open} elements={elements} stripe={stripe} redirect={this.props.redirect} plan={this.props.plan} subscription={this.props.subscription} checkoutSuccess={this.props.auth.checkoutSuccess} checkoutLoading={this.props.auth.checkoutLoading} handleSuccess={this.props.handleSuccess} handleClose={this.props.handleClose}/>
                            )}  
                        </ElementsConsumer>                                          
                    </Elements> : 
                    <Elements stripe={stripePromise}>
                        <ElementsConsumer>
                            {({elements, stripe}) => (
                                <ChangePaymentMethod open={this.props.open} elements={elements} stripe={stripe} checkoutSuccess={this.props.auth.checkoutSuccess} checkoutLoading={this.props.auth.checkoutLoading} handleSuccess={this.props.handleSuccess} handleClose={this.props.handleClose}/>
                            )}
                        </ElementsConsumer>
                    </Elements>
                }
            </Modal>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}
export default connect(mapStateToProps)(CheckoutModal)

