//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
//Components and Pages
import { withRouter } from 'react-router-dom'
import { Modal, Table, Input, ModalButton } from '../components'
import { Color } from '../util'
import api from '../api'
//Images
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add'
import FilterListIcon from '@material-ui/icons/FilterList';
import UploadFileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { BiTrash } from 'react-icons/bi'

class AdminRecipes extends Component {
    constructor (props) {
        super(props)
		this.state = {
            time: new Date(),
            loading: false,
            articles: [],
            tooltipLoading: false,
            importModal: false,
            loading: false,
            range: '',
            success: false
		}
    }

    componentDidMount() {
        this.timeClock = setInterval(this.tick, 1000)
        this.getUsers()
    }

    getAuthRequest = () => {
        const token = localStorage.getItem('accessToken')
        const req = {
            headers: {
                'Content-type': 'application/json'
            }
        }
    
        if (token) req.headers['x-access-token'] = token
        return req
    }

    getUsers = () => {
        this.setState({ loading: true })
        let req = this.getAuthRequest()
        req.params = { limit: 100 }
        api.getFoods(req).then((response) => {
            this.setState({ articles: response.data, loading: false })
        }).catch((error) => {
            this.setState({ loading: false })
        })
    }

    getTypeColor = (type) => {
        switch(type) {
            case ('article'):
                return Color.PRIMARY
            case ('training'):
                return Color.PROTEIN
            default:
                return Color.PRIMARY
        }
    }

    componentWillUnmount() { 
        clearInterval(this.timeClock)
        document.body.style.overflow = 'unset';
    }

    handleRefresh = () => {
        this.getUsers()
    }

    handleClose = (refresh) => {
        this.setState({ articleAdd: false })
        if (refresh) this.handleRefresh()
    }
    
    tick = () => {
        this.setState({ time: new Date() })
    }

    handleSuccess = () => {
        
    }

    handleError = () => {

    }

    successTick = () => {

    }

    handleImport = (range) => {
        console.log(range)
        this.setState({ loading: true })
        api.foodSync({ range }, {}).then((response) => {
            this.setState({ loading: false, success: true })
        }).catch((error) => {
            this.setState({ loading: false, success: false })
            console.log(error)
        })
    }

    importModal = () => {
        return (
            <Modal desktop in={this.state.importModal} handleClose={() => this.setState({ importModal: false })}>
                <h6 style={{ marginBottom: '20px' }}> Import from Google Sheets </h6>
                <div style={{ display: 'flex' }}>
                    <Input label={'Range'} value={this.state.range} onChange={(e) => this.setState({ range: e.target.value })} style={{ width: '80%' }}/>
                    <ModalButton style={{ marginLeft: '10px', width: '20%' }} label={'Load'} loading={this.state.loading} handleClick={() => this.handleImport(this.state.range)}/>
                </div>
                { this.state.success ? <p> Success </p> : null }
            </Modal>
        )
    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                <h1> Food </h1>
                <div style={{ outline: 'none', userSelect: 'none', marginBottom: '20px' }}>
                    <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={this.handleRefresh}> Refresh <RefreshIcon style={{ fontSize: '13px' }}/> </span>
                    <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={() => this.setState({ articleAdd: true })}> Create <AddIcon style={{ fontSize: '13px'}}/> </span>
                    <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={() => this.setState({ filters: !this.state.filters })}> Filters <FilterListIcon style={{ fontSize: '13px'}}/> </span>
                    <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={() => this.setState({ importModal: true })}> Import <UploadFileIcon style={{ fontSize: '13px'}}/> </span>
                </div>

                { this.importModal() }

                <Table functions={[
                    {
                        icon: <BiTrash style={{ fontSize: '20px' }}/>,
                        handleClick: (value) => console.log(value._id)
                    }
                ]} loading={this.state.loading} columns = {[
                    { width: 40, name: 'Name'}, 
                    { width: 15, name: 'Calories'}, 
                    { width: 15, name: 'Carbs'},
                    { width: 15, name: 'Protein'},
                    { width: 15, name: 'Fat'},
                ]} data = {
                    this.state.articles.map((row) => {
                        return [
                            row.name,
                            row.calories,
                            row.carbs,
                            row.protein,
                            row.fat,
                        ]
                    })
                } raw = {this.state.articles} />
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

export default connect(mapStateToProps)(withRouter(AdminRecipes))
