import React from 'react'

const ModalFunctions = (props) => {
    const renderFunctions = () => {
        if (props.children) {
            if (Array.isArray(props.children)) {
                return props.children.map((comp, i) => {
                    if (!comp) return null
                    return <div key={i} style={{ margin: '10px 0px 10px 10px', height: '30px', width: '30px', zIndex: '1001', borderRadius: '50%', padding: '5px', backgroundColor: comp.props && comp.props.backgroundColor ? comp.props.backgroundColor : 'rgba(128,128,128,.85)', display: 'inline-block', cursor: 'pointer' }} onClick={props.handleClose}>
                        { comp }
                    </div>
                })
            } else {
                if (props.children !== null)  {
                    return <div style={{ margin: '10px 0px 10px 10px', height: '30px', width: '30px', zIndex: '1001', borderRadius: '50%', padding: '5px', backgroundColor: props.children.props && props.children.props.backgroundColor ? props.children.props.backgroundColor : 'rgba(128,128,128,.85)', display: 'inline-block', cursor: 'pointer' }} onClick={props.handleClose}>
                        { props.children }
                    </div>
                }
            }
        }

        return null
    }

    return (
        <div style={{ ...props.style, marginRight: '10px', zIndex: '1003' }}>
            { renderFunctions() }
        </div>
    )
}

export default ModalFunctions