//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateProfile } from '../actions/authActions'
import { loadPlanNutrition, getSessions, loadSessions, getPreferences } from '../actions/dashboardActions'
//Components and Pages
import { withRouter } from 'react-router-dom'
import { Grow } from '@material-ui/core'
import { SpeedDialIcon } from '@material-ui/lab'
import { FoodCard, NutritionInfoModal, NutritionInfo, StickyHeader, TrackingAddModalNutrition, RecommendedGenerationGroup, Switch, TrackingUpdateModalNutrition, RecalibrationCard, CustomFoodEditModal, CustomFoodAddModal, PageCard, DateSelector, Selector, PageLoading, MealGroup, NutritionAddModal, Chip, FoodWizard } from '../components'
import { timeToLocal, Color, localStart, localEnd, months, days, getAttribute } from '../util'
//Images
import { Fab } from '@material-ui/core'

class Nutrition extends Component {
    constructor (props) {
        super(props)
		this.state = {
            //Add Modal Information
            addOpen: this.props.location.state ? this.props.location.state.addOpen : false,
            addMeal: this.props.location.state ? this.props.location.state.addMeal : null,
            addServing: this.props.location.state ? this.props.location.state.addServing: undefined,
            addTime: '',
            //Update Modal Information
            updateOpen: false,
            updateSession: null,
            //Custom Add Modal Information
            customAddOpen: false,
            //Custom Update Modal Information
            customUpdateOpen: false,
            customMeal: null,
            //InfoTab Information
            infoOpen: this.props.location.state ? this.props.location.state.infoOpen : false,
            infoMeal: this.props.location.state ? this.props.location.state.infoMeal : null,
            infoSession: null,
            infoAmount: null,
            infoTab: 0,
            //General Nutrition Information
            tab: 0,
            time: new Date(),
            trackingTime: new Date(), //date associated with calendar feature
            today: true, //enables tracking of live-time feature and loading
            dial: false,
            recommended: this.props.auth.user.settings.is_rec        
        }

        let time = new Date()
        time.setDate(time.getDate() + 1)
    }

    //Time-clock and initialization functions
    componentDidMount() {
        this.timeClock = setInterval(this.tick, 60000)

        let temp = new Date(this.state.time)
        temp.setDate(temp.getDate() - 1)
        this.handlePreload(temp)
        
        // if (!this.props.dash.preferencesLoaded && !this.props.dash.preferencesLoading) {
        //     this.props.getPreferences(this.props.auth.user._id)
        // }

        let query = new URLSearchParams(this.props.location.search)
        let trackingTime = query.get('t')
        if (trackingTime) this.handleTrackingChange(new Date(Number(query.get('t'))))
    }

    componentDidUpdate() {
        if (!this.props.dash.preferencesLoaded && !this.props.dash.preferencesLoading) {
            this.props.getPreferences(this.props.auth.user._id)
        }
    }

    handleChange = (variable, value) => {
        this.setState({ [variable]: value })
    }

    // Timeclock function and dismount
    componentWillUnmount() { 
        clearInterval(this.timeClock)
        this.setState({ mounted: false })
    }

    tick = () => {
        this.setState({ time: new Date() })
    }

    handleRecommendedChange = () => {
        this.setState({ recommended: !this.state.recommended })
        this.props.updateProfile(this.props.auth.user._id, { settings: { is_rec: !this.state.recommended }})
    }

    //Component Handlers
    handleTab = (tab) => {
        this.setState({ tab })
    }

    //Modal Controllers
    handleAddOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ addOpen: true, addMeal: args.meal, addServing: args.serving, addTime: args.tod || this.state.addTime })
    }

    handleAddClose = () => {
        this.setState({ addOpen: false, addCustom: false, customOpen: false, favoritesOpen: false, addMeal: undefined, addServing: undefined, updateOpen: false, infoOpen: false })
    }

    handleUpdateOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ updateOpen: true, updateSession: args.session })
    }

    handleUpdateClose = () => {
        this.setState({ updateOpen: false, updateType: undefined, addOpen: false, infoOpen: false })
    }

    handleCustomUpdateOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ customUpdateOpen: true, customMeal: args.meal })
    }

    handleCustomUpdateClose = () => {
        this.setState({ customUpdateOpen: false })
    }

    handleInfoOpen = (args) => {
        if (!args.meal) { return }
        this.setState({ infoMeal: args.meal, infoSession: args.session, infoOpen: true, infoAmount: args.amount })
    }

    handleInfoClose = () => {
        this.setState({ infoOpen: false })
    }

    handleSearchOpen = (time) => {
        this.setState({ search: true, addTime: time ? time : '' })
    }

    handleSearchClose = () => {
        this.setState({ search: false, addTime: '' })
    }

    //Adaptive Button Handler
    handleRedirect = () => {
        if (this.state.search) this.setState({ search: false })
        else if (this.state.tab !== 0) this.setState({ tab: this.state.tab - 1 })
        else this.props.history.push('/dashboard')
    }

    handlePreload = (date) => {
        let start = localStart(date), end = localEnd(date)
        if (this.verifyBuffer(start, end)) this.props.getSessions({ time: { $gte: start, $lt: end }, user_id: this.props.auth.user._id, expand: ['target'] })
    }

    handleTrackingChange = (event) => {
        if (event.getDate() === this.state.time.getDate()) this.setState({ trackingTime: event, today: true })
        else this.setState({ trackingTime: event, today: false })

        let start = localStart(event), end = localEnd(event)
        if (this.verifyBuffer(start, end)) this.props.getSessions({ time: { $gte: start, $lt: end }, user_id: this.props.auth.user._id, expand: ['target'] })

        let temp = new Date(event)
        temp.setDate(temp.getDate() - 1)
        this.handlePreload(temp)

        let plan = this.findPlan(timeToLocal(event))
        if (plan) {
            let dates = []
            if (this.props.dash.buffer.indexOf(plan.date) === -1) {
                dates.push(plan)
            } else {
                for (let i in plan.nutrition) {
                    if (!plan.nutrition[i].food) dates.push(plan)
                }
            }

            if (dates.length) this.props.loadPlanNutrition(dates)
        }
    }

    verifyBuffer = (start, end) => {
        return start > this.props.dash.sessionBuffer.max || start < this.props.dash.sessionBuffer.min || end < this.props.dash.sessionBuffer.min || end > this.props.dash.sessionBuffer.max
    }

    handleExpand = (open) => {
        if (open) {
            let temp = new Date(this.state.time)
            let dates = []
    
            while (this.findPlan(timeToLocal(temp))) { 
                let plan = this.findPlan(timeToLocal(temp))
                if (this.props.dash.buffer.indexOf(plan.date) === -1) {
                    dates.push(plan)
                } else {
                    for (let i in plan.nutrition) {
                        if (!(plan.nutrition[i].food)) {
                            dates.push(plan)
                        }
                    }
                }

                temp.setDate(temp.getDate() + 1)

            }
    
            if (dates.length > 0) {
                this.props.loadPlanNutrition(dates)
            }
        }

        this.setState({ expanded : open })
    }

    findPlan = (time) => {
        for (let i = 0; i < this.props.dash.plan.length; i++) {
            if (this.props.dash.plan[i].date === time) {
                return this.props.dash.plan[i]
            }
        }

        return false
    }

    toTitleCase = (str) => {
        str = str.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
            if (str !== 'and') str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
    };

    renderType = (category, name) => {
        let carbs = 0, protein = 0, fat = 0, calories = 0
        for (let i = 0; i < category.length; i++) {
            let meal = category[i]
            if (meal.target) {
                carbs += getAttribute('carbs', meal.target, meal)
                protein += getAttribute('protein', meal.target, meal)
                fat += getAttribute('fat', meal.target, meal)
                calories += getAttribute('calories', meal.target, meal)
            }
        }

        return (
            <MealGroup recommended={this.state.today && this.state.recommended && false} copy trackingTime={this.state.trackingTime} carbs={carbs} fat={fat} protein={protein} calories={calories} name={name} handleAddOpenRevised={() => this.handleSearchOpen(name)} handleAddOpen={this.handleAddOpen} handleInfoOpen={this.handleInfoOpen}>
                { category.map((session, i) =>
                    <FoodCard key={i} food={session.target} session={session} time={this.state.time} handleUpdateOpen={this.handleUpdateOpen} handleInfoOpen={this.handleInfoOpen} handleCustomUpdateOpen={this.handleCustomUpdateOpen}/>
                ) }
            </MealGroup>
        )
    }

    renderWeek = () => {
        let meals = { breakfast: [], lunch: [], dinner: [], snack: [] }
        let sessions = this.props.dash.sessions.filter((session) => session.type === 'food' && ((new Date(session.time)).getDate()) === (this.state.today ? this.state.time : this.state.trackingTime).getDate() )
        sessions.map((session) => meals[session.metadata.meal].push(session))
        
        let { breakfast, lunch, dinner, snack } = meals
        if (breakfast.length > 1) breakfast.sort((a, b) => a.created_at - b.created_at)
        if (lunch.length > 1) lunch.sort((a, b) => a.created_at - b.created_at)
        if (dinner.length > 1) dinner.sort((a, b) => a.created_at - b.created_at)
        if (snack.length > 1) snack.sort((a, b) => a.created_at - b.created_at)

        return (
            <div>
                <FoodWizard active={this.state.recommended && this.state.today} handleAddOpen={this.handleAddOpen} handleInfoOpen={this.handleInfoOpen}/>
                <div>
                    {this.renderType(breakfast, 'Breakfast')}
                    {this.renderType(lunch, 'Lunch')}
                    {this.renderType(dinner, 'Dinner')}
                    {this.renderType(snack, 'Snack')}
                </div>
            </div>
        )
    }

    renderTime = (category, name) => {
        let carbs = 0, protein = 0, fat = 0, calories = 0
        for (let i = 0; i < category.length; i++) {
            let meal = category[i]
            if (meal.food) {
                carbs += meal.food.carbs
                protein += meal.food.protein
                fat += meal.food.fat
                calories += meal.food.calories
            }
        }

        return (
            <MealGroup trackingTime={this.state.trackingTime} carbs={carbs} fat={fat} protein={protein} calories={calories} name={name}>
                { category.map((meal, i) =>
                    <FoodCard key={i} food={meal.food} amount={meal.serving} verifyFood={true} trackingTime={this.state.trackingTime} time={this.state.time} handleAddOpen={this.handleAddOpen} handleInfoOpen={this.handleInfoOpen}/>
                )}
            </MealGroup>
        )
    }

    //Renders User-Curr Meals
    renderMeals = (timeT, stamps) => {
        let breakfast = [], lunch = [], dinner = [], etc = []
        let time = new Date(timeT), plan = this.findPlan(timeToLocal(time))

        if (plan) {
            for (let i = 0; i < plan.nutrition.length; i++) {
                switch(plan.nutrition[i].meal) {
                    case ("breakfast") :
                        breakfast.push(plan.nutrition[i])
                        break
                    case ("lunch") :
                        lunch.push(plan.nutrition[i])
                        break
                    case ("dinner") :
                        dinner.push(plan.nutrition[i])
                        break
                    default : 
                        etc.push(plan.nutrition[i])
                }
            }
        }

        return (
            <div style={{ position: 'relative'}}>
                { stamps ? (
                    <Grow in={true}>
                        {time.getDate() === this.state.time.getDate() ? <h6 style={{ opacity: '.7'}}> Today </h6> :                         
                            <div>
                                <h5> { days[time.getDay()] } </h5>
                                <h6 style={{ opacity: '.7'}}> { months[time.getMonth()] + " " + time.getDate() + ", " + time.getFullYear() } </h6> 
                            </div>  }
                    </Grow> ) : null
                }

                <div>
                    {this.renderTime(breakfast, 'Breakfast')}
                    {this.renderTime(lunch, 'Lunch')}
                    {this.renderTime(dinner, 'Dinner')}
                    {this.renderTime(etc, 'Snack')}
                </div>
            </div>
        )
    }

    //Add nutrition loaded attritbute to week reducer, make sure that the circular progress is not static if food is not loaded
    renderOverview = () => {
        return (
            <div>
                <NutritionInfo style={{ marginTop: '0px' }} info simple_macros={this.props.auth.user.settings.simple_macros} today={this.state.today} trackingTime={this.state.trackingTime} time={this.state.time}/>
                <div style={{ marginTop: '20px' }}>
                    <div style={{ display: 'flex', margin: '20px 0px 20px 0px', alignItems: 'center' }}>
                        { /* <Selector style={{ marginBottom: '0px', justifyContent: 'left' }} value={this.state.tab} options={[{ value: 0, label: 'Tracking'}, { value: 1, label: 'Meal Plan'}]} handleChange={(value) => this.setState({ tab: value })}/> */ }
                        { this.state.today ? <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                            <p style={{ color: 'rgba(0,0,0,.5)', fontSize: '13px', margin: '0px 0px 0px 0px'}}> Food Wizard 🧙 </p>
                            <Switch edge={'end'} checked={this.state.recommended} onChange={this.handleRecommendedChange} style={{ marginLeft: 'auto' }} color={'primary'}/>
                        </div> : null }
                    </div>
                    { this.state.tab === 0 ? this.renderWeek() : this.renderMeals(this.state.trackingTime) }
                </div>
            </div>
        )
    }

    // renderExpanded = () => {
    //     let temp = new Date(this.state.time), components = []
    //     temp.setDate(temp.getDate() + 1)

    //     while (this.findPlan(timeToLocal(temp))) { 
    //         components.push(
    //             <div key={temp.getDate()}>
    //                 <div>
    //                     { this.renderMeals(temp, true) }
    //                 </div>
    //             </div>
    //         )

    //         temp.setDate(temp.getDate() + 1)
    //     }

    //     components.push(<RecalibrationCard link={{pathname: '/dashboard/recalibration', state: { nutrition: true }}} recalibrateLink={{pathname: '/dashboard/recalibration', state: { nutrition: true, recalibrationOpen: true }}} time={this.state.time} key={temp.getDate() + 1}/>)

    //     return components
    // }

    renderButtons = () => {
        return (
            <div>
                {
                    <Fab style={{ '*:focus' : '{ outline: none }', backgroundColor: Color.PRIMARY, color: 'white', outline: 'none', margin: '10px', position: 'fixed', bottom:'65px', right:'0', zIndex: '50', boxShadow: '0 6px 20px 5px rgba(0, 0, 0, 0.15)'}} onClick={() => this.setState({ search: true })}>
                        <SpeedDialIcon/>
                    </Fab>
                }
            </div>
        )
    }

    render() {
        return (
            <div>                
                { /* Modal Handler/Instantiation */ }
                <div>
                    <NutritionAddModal othersOpen={this.state.addOpen || this.state.customUpdateOpen || this.state.customAddOpen || this.state.infoOpen} open={this.state.search} tod={this.state.addTime} handleClose={this.handleSearchClose} handleAddOpen={this.handleAddOpen} handleInfoOpen={this.handleInfoOpen} handleCustomUpdateOpen={this.handleCustomUpdateOpen} handleCustomAddOpen={() => this.setState({ customAddOpen: true })} time={this.state.time}/>
                    <TrackingAddModalNutrition tod={this.state.addTime} serving={this.state.addServing} targetTime={this.state.trackingTime} time={this.state.time} open={this.state.addOpen} handleClose={this.handleAddClose} meal={this.state.addMeal}/>
                    <TrackingUpdateModalNutrition session={this.state.updateSession} open={this.state.updateOpen && !this.state.addOpen} handleClose={this.handleUpdateClose}/>
                    <CustomFoodEditModal food={this.state.customMeal} open={this.state.customUpdateOpen && !this.state.addOpen} time={this.state.time} handleClose={this.handleCustomUpdateClose}/>
                    <CustomFoodAddModal open={this.state.customAddOpen} trackingTime={this.state.trackingTime} handleClose={(food) => {
                        if (!food) this.setState({ customAddOpen: false, customOpen: false })
                        else this.setState({ customAddOpen: false, customOpen: false, addOpen: true, addMeal: food, addServings: 1, addTime: this.state.addTime })
                    }}/>
                    <NutritionInfoModal history={this.props.history} handleCustomUpdateOpen={this.handleCustomUpdateOpen} tod={this.state.addTime} open={this.state.infoOpen && !this.state.addOpen && !this.state.updateOpen} time={this.state.time} trackingTime={this.state.trackingTime} handleClose={this.handleInfoClose} handleUpdateOpen={this.handleUpdateOpen} handleAddOpen={this.handleAddOpen} infoMeal={this.state.infoMeal} infoSession={this.state.infoSession} infoAmount={this.state.infoAmount}/>
                </div>

                <PageLoading direction={'up'} loading={this.props.dash.sessionLoading}/>

                { /* Main Panel Renders | Overview vs. Render */ }

                <StickyHeader>
                    <h5 style={{ marginBottom: '0px' }}> Nutrition </h5>
                    <DateSelector style={{ marginBottom: '0px', marginLeft: 'auto', marginTop: '0px' }} value={this.state.trackingTime} handleChange={(event) => this.handleTrackingChange(event)}/>
                </StickyHeader>
                <PageCard title={'Nutrition'} style={{ paddingTop: '0px' }}>
                    { this.renderOverview() }
                </PageCard>

                { /*  Main Panel Buttons | Tracking/Overview -> MainNav -> Add/Search */ }
                {this.renderButtons()}
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        loadPlanNutrition,
        getSessions,
        loadSessions,
        getPreferences,
        updateProfile
     }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nutrition))