import React, { useEffect, useState, useRef } from 'react';
import { Fade } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const ChipSelector = (props) => {
    const [open, setOpen] = useState(false)
    const [unset, setUnset] = useState(true)
    const [contentBounds, setContentBounds] = useState({})
    const [menuBounds, setMenuBounds] = useState({})

    const menu = useRef()
    const content = useRef()

    const scrollHandler = () => {
        if (!menu.current) return
        setMenuBounds(getMenuRect())
        if (unset) setUnset(false)
    }

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)
        return () => { document.removeEventListener('scroll', scrollHandler) }
    }, []) 

    useEffect(() => {
        scrollHandler()
    }, [menu])

    useEffect(() => {
        setContentBounds(getContentRect())
        setMenuBounds(getMenuRect())
    }, [menu, content, open])

    const getMenuRect = () => {
        if (!menu || !menu.current) return {}
        return menu.current.getBoundingClientRect()
    }

    const getContentRect = () => {
        if (!content || !content.current) return {}
        return content.current.getBoundingClientRect()
    }

    const getLabelFromValue = (value) => {
        for (let i = 0; i < props.options.length; i++) {
            let option = props.options[i]
            if (option.value === value) return option.label
        }

        return props.blankLabel ? props.blankLabel : value
    }

    return (
        <div>
            <div ref={menu} style={{ padding: '6px 8px 6px 12px', transition: 'background-color 300ms ease, opacity 300ms ease', backgroundColor: !props.disabled ? 'white' : '#EFEFEF4D', display: 'flex', opacity: props.disabled ? '.7' : '1', fontSize: '13px', cursor: 'pointer', border: '1px solid rgba(0,0,0,.1)', boxShadow: '0px 3px 3px rgba(0,0,0,0.03)', borderRadius: '20px', height: '33px', display: 'flex', maxWidth: '90px', alignItems: 'center', overflow: 'hidden', ...props.style }} onClick={() => props.disabled ? null : setOpen(!open)}>
                { props.pref ? props.pref : null }
                <p style={{ marginBottom: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipse', overflow: 'hidden' }}> {getLabelFromValue(props.value)} </p>
                <KeyboardArrowDownIcon style={{ fontSize: '16px', transform: !open ? 'rotate(180deg)' : null, transition: 'transform 300ms ease', marginLeft: 'auto' }}/>
            </div>

            { /* Register Unrender for the Background Body vs. conditional render */ }
            { open ? <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '1025', inset: '0px', cursor: 'pointer' }} onClick={() => setOpen(false)}>
                <Fade in={open}>
                    <div style={{ position: 'absolute', backgroundColor: 'white', borderRadius: '10px', width: '200px', padding: '8px 0px 8px 0px', left: menuBounds.right - 200 < 20 ? 220: (menuBounds.right - menuBounds.width < 0 ? menuBounds.width : menuBounds.right), top: menuBounds.bottom + contentBounds.height >= window.innerHeight - 20 ? window.innerHeight - (contentBounds.height + 20) : menuBounds.bottom + 10, transform: 'translateX(-100%)', boxShadow: '0 6px 20px 10px rgba(0, 0, 0, 0.1)', overflowY: 'hidden' }}>
                        <div ref={content}>
                            { props.options.map((option, i) => {
                                return (
                                    <div key={i} style={{ backgroundColor: option.value === props.value ? 'rgba(0,0,0,.1)' : null, height: '40px', display: 'flex', alignItems: 'center', transition: 'background-color .2s ease', cursor: 'pointer', zIndex: '1000' }} onClick={() => props.handleChange(option.value)}>
                                        <p style={{ marginBottom: '0px', marginLeft: '10px' }}> {option.label} </p>
                                    </div> 
                                )
                            })}
                        </div>
                    </div>
                </Fade>
            </div> : null }
        </div>
    )
}

export default ChipSelector;