//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createWeighin } from '../actions/dashboardActions'
import { Measure } from '../util'
//Components and Pages
import { TextField, CardContent, InputAdornment, Slider } from '@material-ui/core';
import { BodyFatCard, Modal, CardFloat, FileSelector, Image, ModalButton, Input } from './'
//Images
import AddIcon from '@material-ui/icons/Add'

class WeighinModal extends Component {
    constructor (props) {
        super(props)
        this.state = this.generateInitialState()
    }

    generateInitialState = () => {
        return {
            targetTime: this.props.time,
            front: null,
            frontUrl: '',
            back: null,
            backUrl: '',
            side: null,
            sideUrl: '',
            success: false,
            error: false,
            tab : {
                weight : {
                    answer: '',
                    type: 'weight',
                    err: false
                },
                waist_size : {
                    answer: '',
                    type: 'none',
                    err: false
                },
                body_fat : {
                    answer: '',
                    type: 'none',
                    err: false
                },
                img: {
                    answer: '',
                    type: 'image',
                    err: false
                }
            }
        }
    }

    handleFitnessAdd = () => {
        let formdata = new FormData()

        formdata.append('type', 'weigh_in')
        formdata.append('time', this.props.time.getTime())
        formdata.append('weight', this.adjustMeasure("mass", this.state.tab.weight.answer))
        formdata.append('waist_size', this.adjustMeasure("length", this.state.tab.waist_size.answer))
        formdata.append('body_fat', this.state.tab.body_fat.answer)

        if (this.state.front) formdata.append('front', this.state.front)
        if (this.state.side) formdata.append('side', this.state.side)
        if (this.state.back) formdata.append('back', this.state.back)

        return this.props.createWeighin(formdata).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.handleError(error.response.data.msg)
        })
    }

    handleClose = () => {
        this.setState(this.generateInitialState())
        this.props.handleClose()
    }

    handleSuccess = () => {
        this.setState({ success: true })
        setTimeout(this.handleClose, 400)
    }

    handleError = () => {
        
    }

    handleChange = (question, answer) => {
		this.setState({ tab : {...this.state.tab, [question] : { ...this.state.tab[question], answer} } })
	}

    handleFileChange = (event, target, targetUrl) => {
        let file = event.target.files[0] 
        this.setState({ [target]: file, [targetUrl]: URL.createObjectURL(file) })
    }

    adjustMeasure = (type, num) => {
        return Math.round(num * Measure[this.props.auth.user.settings.unit][type].mult * 10) / 10
    } 

    getInputAdornment = (type) => {
        if (this.props.auth.user.settings) {
            return <InputAdornment> {Measure[this.props.auth.user.settings.unit][type].unit} </InputAdornment>
        }
    }

    getRecentBodyFat = () => {
        let history = this.props.dash.history
        history.sort((a, b) => b.time - a.time)
        for (let i in history) {
            if (history[i].body_fat) {
                return history[i].body_fat
            }
        }

        return undefined
    }

    verifyQuestion = (question) => {
		switch (question.type) {
            case ('weight') : {
				if (question.answer > 500 || question.answer < 70 || question.answer === "") {
					return false
				} else {
					return true 
				}
            }

            case ('image') : {
                return true
            }
            
			default : {
				if (question.answer !== "") {
					return true
				}
			}
		}
		return false
    }
    
    handleError = (question, error) => {
		this.setState({ tab : {...this.state.tab, [question] : { ...this.state.tab[question], err: error} } })
    }

	verifyEntries = (questions) => {
		let valid = 0, count = 0
		for (let property in questions) {
			if (this.verifyQuestion(questions[property])) valid += 1
			count += 1
		} 

		return Math.round(valid / count * 100)
    }

	render() {
        return (
            <Modal direction='up' in={this.props.open} handleClose={this.handleClose} head={'Weigh-In'}>
                <div>
                    <CardFloat>
                        <CardContent style={{ padding: '16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: '10px' }}> 
                                    <Input suff={this.getInputAdornment("mass")} type="number" style={{ marginBottom: '10px', width: '100%'}} error={this.state.tab.weight.err} onBlur={() => this.handleError("weight", !this.verifyQuestion(this.state.tab.weight))} value={this.state.tab.weight.answer} placeholder={"Weight"} onChange = {(event) => {this.handleChange("weight", event.target.value)}}/>
                                </div>
                                <div> 
                                    <Input suff={this.getInputAdornment("length")}  type="number" style={{ marginBottom: '10px', width: '100%'}} error={this.state.tab.waist_size.err} onBlur={() => this.handleError("waist", !this.verifyQuestion(this.state.tab.waist_size))} value={this.state.tab.waist_size.answer} placeholder={"Waist"} onChange = {(event) => {this.handleChange("waist_size", event.target.value)}}/>
                                </div>
                            </div>
                            <div style={{ margin: '5px' }}>
                                <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Current Body Fat </h6> 
                                <h6 style={{ fontSize: '11px', marginBottom: '5px', opacity: '.6'}}> Move the slider to select </h6> 
                                <Slider value={this.state.tab.body_fat.answer !== '' ? this.state.tab.body_fat.answer : 0 } valueLabelFormat={(value) => Math.round(value * 100) + '%'} step={.01} max={.5} min={.08} valueLabelDisplay="auto" style={{ marginLeft: '5px', marginRight: '5px'}} onChange={(event, value) => this.handleChange("body_fat", value)}/>                                    
                            </div>
                            <div style={{ margin: '5px' }}>
                                <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Progress Photos </h6> 
                                <h6 style={{ fontSize: '11px', marginBottom: '10px', opacity: '.6'}}> Optional </h6> 
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <h6 style={{ marginBottom: '5px', opacity: '.7', fontSize: '12px', textAlign: 'center' }}> Front </h6>
                                        <FileSelector handleChange={(event) => this.handleFileChange(event, 'front', 'frontUrl')} type='image/*'>
                                            { !this.state.front ? <div style={{ padding: '10px', width: '50px', height: '50px', backgroundColor: 'lightgrey', opacity: '.7', borderRadius: '10px' }}>
                                                <AddIcon style={{ marginLeft: '50%', transform: 'translateX(-50%)', fontSize: '20px' }}/>
                                            </div> : <Image style={{ height: '50px', width: '50px', borderRadius: '10px', objectFit: 'cover' }} src={this.state.frontUrl}/> }
                                        </FileSelector>
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h6 style={{ marginBottom: '5px', opacity: '.7', fontSize: '12px', textAlign: 'center' }}> Side </h6>
                                        <FileSelector handleChange={(event) => this.handleFileChange(event, 'side', 'sideUrl')} type='image/*'>
                                            { !this.state.side ? <div style={{ padding: '10px', width: '50px', height: '50px', backgroundColor: 'lightgrey', opacity: '.7', borderRadius: '10px' }}>
                                                <AddIcon style={{ marginLeft: '50%', transform: 'translateX(-50%)', fontSize: '20px' }}/>
                                            </div>  : <Image style={{ height: '50px', width: '50px', borderRadius: '10px', objectFit: 'cover' }} src={this.state.sideUrl}/> }
                                        </FileSelector>
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h6 style={{ marginBottom: '5px', opacity: '.7', fontSize: '12px', textAlign: 'center' }}> Back </h6>
                                        <FileSelector handleChange={(event) => this.handleFileChange(event, 'back', 'backUrl')} type='image/*'>
                                            { !this.state.back ? <div style={{ padding: '10px', width: '50px', height: '50px', backgroundColor: 'lightgrey', opacity: '.7', borderRadius: '10px' }}>
                                                <AddIcon style={{ marginLeft: '50%', transform: 'translateX(-50%)', fontSize: '20px' }}/>
                                            </div> : <Image style={{ height: '50px', width: '50px', borderRadius: '10px', objectFit: 'cover' }} src={this.state.backUrl}/> }
                                        </FileSelector>
                                    </div>
                                </div>
                            </div>  
                        </CardContent>
                    </CardFloat>
                    { this.state.tab.body_fat.answer !== '' ? <BodyFatCard foreground={{ body_fat : this.state.tab.body_fat.answer, gender : this.props.auth.user.gender }} background={ this.getRecentBodyFat() ? { body_fat: this.getRecentBodyFat(), gender: this.props.auth.user.gender } : undefined }/> : null }
                </div> 
                <ModalButton success={this.state.success} label={'Add Weigh-In'} handleClick={this.handleFitnessAdd} loading={this.props.dash.historyLoading} disabled={this.verifyEntries(this.state.tab) !== 100}/>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        createWeighin }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WeighinModal)

