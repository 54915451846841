//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
//Components and Pages
import { withRouter } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import api from '../api'
import { Table, Selector } from '../components'  
import { getAuthRequest, Color } from '../util'
//Images
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add'
import FilterListIcon from '@material-ui/icons/FilterList';

class Admin extends Component {
    constructor (props) {
        super(props)
		this.state = {
            time: new Date(),
            recent: [],
            loading: false,
            history: {},
            historyUsers: [],
            historyLoading: false,
            tooltipLoading: false,
            offset: 24,
            user: null
		}
    }

    componentDidMount() {
        this.timeClock = setInterval(this.tick, 1000)
        this.handleRefresh()
    }

    componentWillUnmount() { 
        clearInterval(this.timeClock)
        document.body.style.overflow = 'unset';
    }

    handleRefresh = () => {
        this.getRecent()
        this.getHistory()
    }

    getRecent = () => {
        let req = getAuthRequest()
        let date = new Date()
        date.setDate(date.getDate() - (this.state.offset / 24))
        req.params = { start: date.getTime() }
        this.setState({ loading: true })
        api.getLoginActive(req).then((response) => {
            this.setState({ recent: response.data, loading: false })
        }).catch((error) => {
            this.setState({ loading: false })
        })
    }

    getHistory = () => {
        let req = getAuthRequest()
        this.setState({ historyLoading: true })
        api.getWeighinsStat(req).then((response) => {
            this.setState({ history: response.data[0], historyUsers: response.data[0].users, historyLoading: false })
        }).catch((error) => {
            this.setState({ historyLoading: false })
        })
    }

    getCount = () => {
        let sum = 0
        this.state.recent.map((recent) => {
            return sum += recent.count
        })

        return sum
    }

    handleHover = (_id) => {
        this.setState({ tooltipLoading: true, user: null })
        let req = getAuthRequest()
        req.params = { _id }
        api.getUserData(req).then((response) => {
            this.setState({ user: response.data[0], tooltipLoading: false })
        }).catch((error) => {
            this.setState({ tooltipLoading: false })
        })
    }

    renderTooltip = () => {
        return (
            !this.state.tooltipLoading ? <div>
                <p style={{ marginBottom: '0px', fontSize: '12px', padding: '5px', color: Color.PRIMARY }}> { this.state.user ? this.state.user.email : 'n/a' } </p>
            </div> : <div style={{ width: '50px', height: '30px', position: 'relative'}}>
                <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translateX(-50%) translateY(-50%)'}}>
                    <CircularProgress size={13}/>
                </div>
            </div>
        )
    }

    tick = () => {
        this.setState({ time: new Date() })
    }

    render() {
        return (
            <div>
                <div>
                    <h1> Admin </h1>
                    <div style={{ outline: 'none', userSelect: 'none', marginBottom: '20px' }}>
                        <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={this.handleRefresh}> Refresh <RefreshIcon style={{ fontSize: '13px' }}/> </span>
                        <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={() => this.setState({ articleAdd: true })}> Create <AddIcon style={{ fontSize: '13px'}}/> </span>
                        <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={() => this.setState({ filters: !this.state.filters })}> Filters <FilterListIcon style={{ fontSize: '13px'}}/> </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <h6 style={{ marginBottom: '5px' }}> {this.state.recent.length} Recent Users </h6>
                            <h6 style={{ fontSize: '14px', opacity: '.7', marginBottom: '20px'}}> {this.getCount()} logins </h6>
                        </div>
                        <Selector style={{ marginLeft: 'auto' }} value={this.state.offset} options={[{ label: 'Day', value: 24 }, { label: 'Week', value: 168 }, { label: 'Month', value: 720 }]} handleChange={(value) => {
                            this.setState({ offset: value }, this.getRecent)
                        }}/>
                    </div>
                        <Table loading={this.state.loading} columns = {[
                            { width: 20, name: 'User ID', handleClick: (entry) => this.props.history.push(`/dashboard/admin/users/${entry}`)}, 
                            { width: 20, name: 'Email' },
                            { width: 20, name: 'Count' },
                            { width: 40, name: 'Most Recent', transform: (date) => { 
                                    let obj = new Date(date)
                                    return obj.toLocaleString()
                                }
                            }
                        ]} data = {
                            this.state.recent.map((row) => {
                                return [
                                    row._id,
                                    row.email,
                                    row.count,
                                    row.recent
                                ]
                            })
                        } raw = {this.state.recent} />

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                        <div>
                            <h6 style={{ marginBottom: '5px' }}> {this.state.historyUsers.length} Contributors </h6>
                            <h6 style={{ fontSize: '14px', opacity: '.7', marginBottom: '20px'}}> {Math.round(this.state.history.total)} lbs total </h6>
                        </div>
                    </div>
                    <Table loading={this.state.historyLoading} columns = {[
                        { width: 100/3, name: 'User ID', handleClick: (entry) => this.props.history.push(`/dashboard/admin/users/${entry}`)}, 
                        { width: 100/3, name: 'Email' },
                        { width: 100/3, name: 'Weight Change', transform: (entry) => Math.round(entry) }
                    ]} data = {
                        this.state.historyUsers.map((row) => {
                            return [
                                row._id,
                                row.email,
                                row.total
                            ]
                        })
                    } raw = {this.state.historyUsers} />
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

export default connect(mapStateToProps)(withRouter(Admin))
