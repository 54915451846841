//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createCustomActivity } from '../actions/dashboardActions'
//Components and Pages
import { CardContent, Chip } from '@material-ui/core';
import { AttributeModal, Modal, CardFloat, ModalButton, Input } from './'
import { equipment, extremities, main_muscle, movement } from '../util'
//Images
import AddIcon from '@material-ui/icons/Add'


const initialState = {
    name: '',
    equipment: [],
    attributeOpen: false,
    attributeOptions: [],
    attributeName: '',
    attributeLabel: '',
    success: false,
    error: false
}

const attributes = {
    equipment: {
        label: 'Equipment',
        options: equipment
    },
    muscle_groups: {
        label: 'Muscle Groups',
        options: main_muscle
    },
    extremities: {
        label: 'Extremities',
        options: extremities
    },
    movement: { 
        label: 'Movement',
        options: movement
    }
}

class CustomActivityAddModal extends Component {
    constructor (props) {
        super(props)
        this.state = initialState
    }

    handleChange = (question, answer) => {
        this.setState( { [question]: answer })
    }

    handleClose = () => {
        this.setState(initialState)
        this.props.handleClose()
    }

    confirmSubmit = () => {
        if (this.props.dash.customLoading) return false
        else if (this.state.name === '') return false
        return true
    }
    
    handleCreate = () => {
        let { name, equipment } = this.state
        this.props.createCustomActivity({ name, equipment }).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.handleError(error.response.data.msg)
        })
    }

    handleSuccess = () => {
        this.setState({ success: true })
        setTimeout(this.handleClose, 400)
    }

    handleError = () => {

    }

    renderAttributes = (name) => {
        let chips = this.state[name].length ? this.state[name].map((muscle_group, i) => {
            return <Chip key={i} style={{ margin: '2px'}} label={muscle_group} onDelete={() => this.handleChange(name, this.state[name].filter(item => item !== muscle_group))}/>
        }) : [<Chip key={0} style={{ margin: '2px'}} label={'None'}/>]

        chips.push(<AddIcon key={chips.length} fontSize='small' style={{ margin: '2px', opacity: '.7', cursor: 'pointer'}} onClick={() => this.setState({ attributeOpen: true, attributeName: name, attributeOptions: attributes[name].options, attributeLabel: attributes[name].label })}/>)
        return chips
    }

	render() {
        return (
            <div>
                <Modal direction='up' in={this.props.open && !this.state.attributeOpen} handleClose={this.handleClose} head={`Create Custom`}>
                    <CardFloat>
                        <CardContent style={{ padding: '20px' }}>
                            <Input 
                                style={{ marginBottom: '10px', width: '100%' }}
                                value={this.state.name} 
                                placeholder="Name"
                                onChange = {(event) => {
                                this.handleChange("name", event.target.value)}}/>
                        </CardContent>
                    </CardFloat>
                    <ModalButton success={this.state.success} disabled={!this.confirmSubmit()} loading={this.props.dash.customLoading} handleClick={this.handleCreate} label={`Create ${this.state.name || 'activity'}`}/>
                </Modal>
                <AttributeModal value={this.state[this.state.attributeName]} open={this.state.attributeOpen} label={this.state.attributeLabel} handleClose={() => this.setState({ attributeOpen: false })} options={this.state.attributeOptions} handleChange={this.handleChange} name={this.state.attributeName}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        createCustomActivity }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomActivityAddModal)

