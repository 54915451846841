import React, { Component, createRef } from 'react';

class FileSelector extends Component {
    inputRef = createRef()
    render() {
        return (
            <div>
                <input ref={this.inputRef} type='file' style={{ display: 'none' }} accept={this.props.type} onChange={this.props.handleChange}/>
                <div onClick={() => this.inputRef.current.click()}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default FileSelector;