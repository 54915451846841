import { useEffect, useState } from "react"
import { days, Color } from '../util'
import Chip from "./Chip"

const GenerationCalendar = (props) => {
    const [mode, setMode] = useState('convenient')
    const modes = {
        convenient : {
            color: Color.PRIMARY,
            symbol: '⏱️',
            text: 'No heat, low assembly required'
        },
        cooked : {
            color: Color.CARBS,
            symbol: '👨‍🍳',
            text: 'Foods that require heat'
        },
        restaurant : {
            color: Color.PROTEIN,
            symbol: '🥡',
            text: 'Dine in or take out'
        },
        mealprep : {
            color: Color.FAT,
            symbol: '🍱',
            text: 'Foods that can be made in large batches'
        }
    }

    const [map, setMap] = useState(props.value || [
        [0, 0, 0], 
        [0, 0, 0], 
        [0, 0, 0], 
        [0, 0, 0], 
        [0, 0, 0], 
        [0, 0, 0], 
        [0, 0, 0]
    ])

    useEffect(() => {
        if (props.value) setMap(props.value)
    }, [props.value])

    const changeMeal = (day, meal, type) => {
        let temp = [...map]
        temp[day][meal] = type
        setMap([...temp])
        if (props.handleChange) props.handleChange([...temp])
    }

    const Entry = (props) => {
        return <div style={{ transition: 'background-color 300ms ease', backgroundColor: mode === props.value ? modes[props.value]?.color || 'rgba(0,0,0,0.05)' : 'rgba(0,0,0,0.05)', height: '70px', width: '70px', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => changeMeal(props.day, props.meal, mode)}> 
            <h6 style={{ fontSize: '32px', margin: '0px' }}> { modes[props.value]?.symbol } </h6>
        </div>
    }

    return (
        <div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }} disabled={false}>
                <Chip label={'Convenient ⏱️'} color={Color.PRIMARY} style={{ margin: '5px' }} handleClick={() => setMode('convenient')} disabled={mode === 'convenient'}/>
                <Chip label={'Cooked 👨‍🍳'} color={Color.CARBS} style={{ margin: '5px' }} handleClick={() => setMode('cooked')} disabled={mode === 'cooked'}/>
                <Chip label={'Restaurant 🥡'} color={Color.PROTEIN} style={{ margin: '5px' }} handleClick={() => setMode('restaurant')} disabled={mode === 'restaurant'}/>
                <Chip label={'Meal Prep 🍱'} color={Color.FAT} style={{ margin: '5px' }} handleClick={() => setMode('mealprep')} disabled={mode === 'mealprep'}/>
            </div>

            <div style={{ margin: '15px 0px 0px 0px', fontSize: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'rgba(0,0,0,.7)' }}>
                <p style={{ margin: '0px' }}> {modes[mode]?.symbol + ' ' + modes[mode]?.text} </p>
            </div>

            <div style={{ margin: '15px 5px 5px 5px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '48px' }}>
                    <h6 style={{ margin: '5px 5px 5px 5px', width: '75px', textAlign: 'center', fontSize: '14px', opacity: '.7' }}> Breakfast </h6>
                    <h6 style={{ margin: '5px 5px 5px 5px', width: '75px', textAlign: 'center', fontSize: '14px', opacity: '.7' }}> Lunch </h6>
                    <h6 style={{ margin: '5px 5px 5px 5px', width: '75px', textAlign: 'center', fontSize: '14px', opacity: '.7' }}> Dinner </h6>
                </div>
                { map.map((day, i) => {
                    return <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                        <h6 style={{ margin: '0px', width: '40px' }}> {days[i].substring(0, 3)} </h6>
                        <Entry value={day[0]} day={i} meal={0}/>
                        <Entry value={day[1]} day={i} meal={1}/>
                        <Entry value={day[2]} day={i} meal={2}/> 
                    </div>
                }) }
            </div>
        </div>
    )
}

export default GenerationCalendar