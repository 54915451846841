import React,  { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateSubscription, createCancellation } from '../actions/authActions'
import { CardContent, TextField, Fab, CircularProgress, Grow } from '@material-ui/core'
import { Modal, RadioButtons, PageHandler, CardFloat, Page, Input } from './'

class CancelModal extends Component {
    constructor(props) {
        super(props)
        this.state = this.initializeState()
    }

    initializeState = () => {
        return {
            details: '',
            critical: '',
            tab: 0
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open) this.setState(this.initializeState)
        if (!this.props.auth.subscriptionLoading && prevProps.auth.subscriptionLoading) {
            this.handleClose()
        }
    }

    handleChange = (variable, value) => {
        this.setState({ [variable] : value })
    }

    handleClose = () => {
        this.setState(this.initializeState())
        clearTimeout(this.close)
        this.props.handleClose()
    }

    createReport = () => {
        this.props.createCancellation({ details: this.state.details, critical: this.state.critical })
        if (!this.state.critical) this.props.updateSubscription(this.props.auth.user.subscription.subscription_id, { cancel_at_period_end: true })
        else {
            this.setState({ tab: this.state.tab + 1 })
            this.close = setTimeout(this.handleClose, 3000)
        }
    }

    considerSubmit = () => {
        switch (this.state.tab) {
            case (0) : {
                if (this.state.details !== '') return true
                break
            }

            case (1) : {
                if (this.state.critical !== '') return true
                break
            }

            default : {
                break
            }
        }

        return false
    }

    handleContinue = () => {
        if (this.state.tab === 1) this.createReport()
        else this.setState({ tab: this.state.tab + 1})  
    }

    renderTab = () => {
        return (
            <div>
                <CardFloat>
                    <CardContent>
                        <h6 style={{ textAlign: 'left' }}> Hey { this.props.auth.user ? this.props.auth.user.first_name : 'name'}, sorry to see you go! </h6>
                        <h6 style={{ opacity: '.7', textAlign: 'left', fontSize: '13px'}}>  Please let us know what you felt was missing so we can improve in the future. </h6>
                    </CardContent>
                </CardFloat>
                <PageHandler tab={this.state.tab}>
                    <Page>
                        <CardFloat>
                            <CardContent>
                                <h6> Let us know </h6>
                                <div style={{ margin: '10px'}}>
                                    <div style={{ width: '100%' }}> 
                                        <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> What can be improved? </h6> 
                                        <Input 
                                            value={this.state.details}
                                            onChange = {(event) => this.handleChange('details', event.target.value)} 
                                            placeholder="Explain"
                                            multiline/>
                                    </div>
                                </div>
                            </CardContent>
                        </CardFloat>
                    </Page>
                    <Page>
                        <CardFloat>
                            <CardContent>
                                <h6> Let us know </h6>
                                <div style={{ margin: '10px'}}>
                                    <h6 style={{ fontSize: '14px', marginBottom: '10px', opacity: '.8'}}> If we’re able to fix this issue in the next 2-3 days would you want to stay? </h6>
                                    <RadioButtons value={this.state.critical} options={[{ variable: 'critical', label: 'Yes', value: true }, { variable: 'critical', label: 'No', value: false }]} handleChange={this.handleChange}/> 
                                </div>
                            </CardContent>
                        </CardFloat>
                    </Page>
                    <CardFloat>
                        <CardContent>
                            <h6 style={{ textAlign: 'left' }}> Thanks for the feedback, { this.props.auth.user ? this.props.auth.user.first_name : 'name' } </h6>
                            <h6 style={{ opacity: '.7', textAlign: 'left', fontSize: '13px'}}>  We’ll keep you active and we’ll get on it ASAP. We’ll reach out via email. </h6>
                        </CardContent>
                    </CardFloat>
                </PageHandler>
            </div>
        )
    }

    render() {
        return (
            <Modal direction="up" in={this.props.open} handleClose={this.props.handleClose} head={'Cancel Subscription'}>
                { this.renderTab() }

                { this.state.tab !== 2 ? <Fab disabled={!this.considerSubmit() || this.props.auth.subscriptionLoading} onClick={this.handleContinue}  style={{ marginBottom: '20px', outline: 'none', float: 'right' }} color="primary" variant="extended">
                    {this.props.auth.subscriptionLoading ? <CircularProgress size={20} /> : ( this.state.tab === 0 ? 'Continue' : 'Submit')}
                </Fab> : null }
                <Grow in={this.state.tab > 0 && this.state.tab !== 2}>
                    <Fab onClick = {() => this.setState({ tab: this.state.tab - 1 })} color="primary" variant="extended" style={{marginBottom: "20px", outline: 'none', float: 'left'}}>
                        Back
                    </Fab>
                </Grow>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
	return {
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSubscription,
        createCancellation
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelModal)