import React, { Component } from 'react';
import { Modal, RadioButtons } from './'

class ConfirmationModal extends Component {
    handleAction = () => {
        this.props.action()
        this.props.handleClose()
    }
    
    render() {
        return (
            <Modal desktop in={this.props.open}>
                <h6> {this.props.head} </h6>
                <RadioButtons options={[{ label: 'No', action: this.props.handleClose, value: false }, { label: 'Yes', action: this.handleAction, value: false }]}/>
            </Modal>
        );
    }
}

export default ConfirmationModal;