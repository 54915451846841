//Classes and Functions
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { refreshToken } from '../actions/authActions'
import { connect } from 'react-redux'
import api from '../api'
//Components and Pages
import { CardContent, CircularProgress, Slide, Fade } from '@material-ui/core'
import { CardProviders, Image, CardElementLoad, PaymentRequestLoad, CardFloat, ModalButton, Input, PageCard, StickyHeader } from '../components'
import { toTitleCase } from '../util'
import { MdOutlineShortcut } from 'react-icons/md'
//Images
import StripeBadge from '../images/powered_by_stripe3.svg'
import instagramVerification from '../images/instagramVerification.svg'
import stnbly from '../images/sustainablyapp.svg'

import { Color } from '../util'

const PlanCard = (props) => {
	return (
		<CardFloat outline style={{ border: props.disabled ? `1px solid ${Color.PRIMARY}` : null, backgroundColor: props.disabled ? Color.PRIMARY + '08' : 'white', color: props.disabled ? Color.PRIMARY : null, transition: 'all .3s ease', padding: '16px', borderRadius: '15px', cursor: 'pointer' }} onClick={props.onClick}>
			{props.children}
		</CardFloat>
	)
}

// /checkout?p={x}&c={p}`
class Checkout_revised extends Component {
    constructor (props) {
        super(props)
        let params = new URLSearchParams(this.props.location.search)
        this.state = {
            status: 'idle',
            redirect: false,
            subscription: null,
            code: params.get('c') || null,
            plan: params.get('p') || null,
            uri: params.get('redirect_uri') || null,
            promocode: null,
            promocodeLoading: false,
            promocodeLoaded: false,
            first_name: this.props.auth.user ? this.props.auth.user.first_name : '',
            last_name: this.props.auth.user ? this.props.auth.user.last_name : '',
            email: this.props.auth.user ? this.props.auth.user.email : '',
            plansLoading: false,
            plansLoaded: false,
            plans: [],
            prLoading: false,
            prLoaded: false,
            pr: null,
            tab: 0,
            activating: false
        }
    }

    handleTab = async () => {
        if (this.state.plan.unit_amount !== 0) return this.setState({ tab: 1 })
        this.setState({ loading: true })
        await this.handlePurchase()
    }

    handleInitialLoad = async () => {
        // load the promocode
        try { 
            this.setState({ status: 'loading' })
            if (this.state.code) {
                let response = await api.stripeGetPromoCode({ params: { code: this.state.code }})
                this.setState({ promocode: response.data })
            }
        } catch (error) {
            this.setState({ status: 'idle' })
        }

        // load the plans
        try {
            this.setState({ plansLoading: true })
            let response = await api.stripeGetPlans({})
            let plans = response.data.data.filter((plan) => plan.type === 'recurring' && plan.active), plan = plans[0]
            if (this.state.plan) {
                let match = plans.find((plan) => plan.id === this.state.plan)
                if (match) plan = match
            }
            this.setState({ plans, plan, status: 'idle', plansLoading: false })
        } catch (error) {
            this.setState({ status: 'idle', plansLoading: false })
        }
    }

    handlePaymentRequestLoad = async (updated) => {
        try { 
            if ((this.props.stripe && !this.state.prLoading && !this.state.prLoaded) || updated) {
                this.setState({ prLoading: true, status: 'loading' })
                let pr = this.createPaymentRequest(this.state.plans[this.state.plan])
                let confirm = await pr.canMakePayment()
                this.setState({ prLoading: false, prLoaded: true, status: 'idle' })
                if (!confirm) throw new Error('Failed to confirm canMakePayment')
                
                pr.on('paymentmethod', async (event) => {
                    let { paymentMethod } = event
                    this.setState({ status: 'loading' })
                    try {
                        if (!this.state.subscription) {
                            let subscription = await this.createSubscription(this.props.auth.user.subscription.customer_id, paymentMethod.id, this.state.plans[this.state.plan].id, this.state.promocode?.id)
                            this.setState({ subscription })
                            return this.handlePostPurchase(subscription, paymentMethod.id, event)
                        } else {
                            let latestInvoice = await this.retryInvoice(this.props.auth.user.subscription.customer_id, paymentMethod.id, this.state.subscription.latest_invoice.id)
                            this.setState({ subscription: { ...this.state.subscription, latest_invoice: latestInvoice }})
                            return this.handlePostPurchase(this.state.subscription, paymentMethod.id)
                        }
                    } catch (error) {
                        event.complete('success')
                    }
                })

                this.setState({ pr })
            } 
        } catch (error) {
            this.setState({ prLoading: false })
        }
    }

    componentDidMount() {
        this.handleInitialLoad()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.subscription !== this.props.subscription)  this.setState({ subscription: this.props.subscription })
        //this.handlePaymentRequestLoad(this.state.promocode !== prevState.promocode || this.state.plan === prevState.plan)

        /*if (prevProps.open === false && this.props.open === true) {
            if (this.props.ReactPixel && !this.state.checkoutInitiated) this.props.ReactPixel.track('InitiateCheckout')
            if (this.props.SnapchatPixel && !this.state.checkoutInitiated) this.props.SnapchatPixel.snaptr('track', 'START_CHECKOUT')
            if (this.props.subscription && this.props.subscription.latest_invoice.payment_intent.status === 'requires_action') {
                this.handleActionRequired(this.props.subscription, this.props.subscription.customer.invoice_settings.default_payment_method)
            }

            this.setState({ checkoutInitiated: true })
        }*/
    }

    //Create Payment Request Helper Function
    createPaymentRequest = (plan) => {
        return this.props.stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            requestPayerName: true,
            requestPayerEmail: true,
            total: {
                label: `Sustainably ${this.state.plan.toUpperCase()}`,
                amount: this.getPrice(plan, this.state.promocode) * 100,
            }
        })
    }

    //Verify All Entries Before Submission
    verifyEntries = (questions) => {
		let valid = 0, count = 0
		for (let property in questions) {
			if (this.verifyQuestion(questions[property])) valid += 1
			count += 1
		} 

		return Math.round(valid / count * 100)
    }

    //Handle input changes
    handleChange = (question, answer) => {
		this.setState({ [question]: answer })
	}
    
    handleError = (question, error) => {
		this.setState({ tab : {...this.state.tab, [question] : { ...this.state.tab[question], err: error} } })
    }

    //Create subscription helper function
    createSubscription = async (customer_id, payment_method_id, plan_id, promotion_code_id) => {
        let subscription = { customer_id, payment_method_id, plan_id }
        if (promotion_code_id) subscription.promotion_code_id = promotion_code_id
        return api.stripeCreateSubscription(subscription)
    }

    //Retry invoice helper function
    retryInvoice = async (customer_id, payment_method_id, invoice_id) => {
        return api.stripeRetryInvoice({ customer_id, payment_method_id, invoice_id })
    }

    handlePurchase = async () => {
        let paymentMethodId = null

        this.setState({ status: "loading" })
        this.handleCreatePaymentMethod().then((response) => {
            //if (this.state.subscription) return this.retryInvoice(this.props.auth.user.subscription.customer_id, response.paymentMethod.id, this.state.subscription.latest_invoice.id)
            return this.createSubscription(this.props.auth.user.subscription.customer_id, response?.paymentMethod?.id, this.state.plan.id, this.state.promocode ? this.state.promocode.id : null)
        }).then((response) => {
            if (this.state.subscription) {
                this.setState({ subscription: { ...this.state.subscription, latest_invoice: response.data }})
                this.handlePostPurchase(this.state.subscription, paymentMethodId)
            } else {
                this.setState({ subscription: response.data })
                this.handlePostPurchase(response.data, paymentMethodId)
            }
        }).catch((error) => {
            console.log(error)
            this.setState({ status: "error", errorMessage: "Card Error"})
            this.error = setInterval(() => this.errorTick(), 1000)
        })
    }

    handleCreatePaymentMethod = async () => {
        if (this.state.plan.unit_amount === 0) return null
        return this.props.stripe.createPaymentMethod({ 
            type: 'card', 
            card: this.props.elements.getElement('card'),             
            billing_details : {
                name: `${this.state.first_name} ${this.state.last_name}`,
                email: this.state.email
            }
        })
    }

    errorTick = () => {
        clearInterval(this.error)
        this.setState({ status: "idle" })
    }

    successTick = () => {
        clearInterval(this.success)
        window.gtag('event', 'conversion', { send_to: 'AW-356819193/CtZhCLyaq8oCEPnBkqoB', transaction_id: '' })
        if (this.props.ReactPixel) this.props.ReactPixel.track('Purchase', { value: this.props.plan.unit_amount / 100, currency: 'USD' })
        if (this.props.SnapchatPixel) this.props.SnapchatPixel.snaptr('track', 'PURCHASE', { currency: 'USD', price: this.props.plan.unit_amount / 100, transaction_id: 11111111 })
        this.props.refreshToken(false, '/activate/onboarding')
    }

    handlePostPurchase = async (subscription, paymentMethodId, event) => {
        if (subscription.status === 'active') {
            if (event) event.complete('success')
            this.setState({ status: "success" })
            this.success = setInterval(this.successTick, 1000)
        } else if (subscription.status === 'incomplete') {
            this.handleActionRequired(subscription, paymentMethodId, event)
        }
    }

    handleActionRequired = async (subscription, paymentMethodId, event) => {
        let paymentIntent = subscription.latest_invoice.payment_intent
        if (paymentIntent.status === 'requires_action' || (this.state.subscription && paymentIntent.status === 'requires_payment_method')) {
            this.setState({ status: "authorizing" })
            return this.props.stripe.confirmCardPayment(paymentIntent.client_secret, {
                payment_method : paymentMethodId
            }).then((response) => {
                if (response.error) {
                    this.setState({ status: "error", errorMessage: "Authorization Error", authorizing: false })
                    this.error = setInterval(() => this.errorTick(), 2000)
                } else {
                    if (event) event.complete('success')
                    this.setState({ status: "success" })
                    this.success = setInterval(() => this.successTick(), 1000)
                }
            }).catch((error) => {
                this.setState({ status: "error", errorMessage: "Card Error", authorizing: false })
                this.error = setInterval(() => this.errorTick(), 2000)
            })
        } else {
            this.setState({ status: 'idle' })
        }
    }

    getMonthFromWeeks = (weeks) => {
		let date = new Date()
		for (let i = 0; i < weeks; i++) {
			date.setDate(date.getDate() + 7)
		}

		return date
    }

    getPrice = (plan, promocode) => {
        if (!plan) return 0
        let price = plan.unit_amount, coupon = promocode ? promocode.coupon : null
        if (coupon && coupon.valid) {
            let type = coupon.percent_off ? 'percent' : 'amount', temp = price / plan.duration
            if (type === 'percent') price = price - temp * (coupon.percent_off / 100) * (coupon.duration_in_months && coupon.duration_in_months > plan.duration ? plan.duration : coupon.duration_in_months)
            else price = price - coupon.amount_off
        }

        return Math.ceil(price) / 100
    }

    getMonthlyPrice = (plan, promocode) => {
        if (!plan) return 0
        let price = plan.unit_amount, coupon = promocode ? promocode.coupon : null, units = { 'year' : 12, 'month': 1 }
        if (coupon && coupon.valid) {
            let type = coupon.percent_off ? 'percent' : 'amount', temp = price / plan.duration
            if (type === 'percent') price = price - temp * (coupon.percent_off / 100) * (coupon.duration_in_months && coupon.duration_in_months > plan.duration ? plan.duration : coupon.duration_in_months)
            else price = price - coupon.amount_off
        }

        return price / (units[plan.recurring.interval] * plan.recurring.interval_count) / 100
    }

    render() {
        return (
            <div>
                <StickyHeader>
                    <div>
                        <h5 style={{ marginBottom: '5px' }}> Checkout </h5>
                        <h6 style={{ margin: '0px', fontSize: '12px', opacity: '.7' }}> { this.state.tab === 0 ? 'Select your plan' : 'Payment' } </h6>
                    </div>
                    <img alt='STNBLY Icon' src={stnbly} style={{ width: '35px', marginLeft: 'auto' }} />
                </StickyHeader>
                <PageCard style={{ paddingTop: '0px' }}>
                    { this.state.tab === 0 ? <div> { /* Select Plan */ }
                        { !this.state.plansLoading ? <Fade in={true}>
                            <div style={{ marginTop: '-15px' }}>
                                { this.state.plans.map((plan) => {
                                    return (
                                        <PlanCard disabled={this.state.plan?.id === plan.id} onClick={() => this.setState({ plan })}>
                                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                                <h6> {this.getPrice(plan, this.state.promocode) === 0 ? 'Free' : plan.recurring.interval_count + ' ' + toTitleCase(plan.recurring.interval) + (plan.recurring.interval_count > 1 ? 's' : '')} </h6>
                                                <h6 style={{ fontSize: '14px', marginLeft: 'auto' }}> ${this.getPrice(plan, this.state.promocode)} </h6>
                                            </div>
                                            <p style={{ marginBottom: '0px', fontSize: '12px' }}> ${Math.round(this.getMonthlyPrice(plan, this.state.promocode) * 100) / 100} <span style={{ opacity: '.7', fontSize: '12px' }}>/month </span> </p>
                                        </PlanCard>
                                    )
                                }) }
                            </div>
                        </Fade> : <div style={{ display: 'flex', marginTop: '50px', alignItems: 'center', justifyContent: 'center'}}> 
                            <CircularProgress size={30}/>
                        </div> }
                    </div> : <div> { /* Payment Method */ }
                        <div style={{ borderRadius: '25px', backgroundColor: 'rgba(0,0,0,.1)', margin: '5px', padding: '15px' }}>
                            <h6> Free Plan </h6>
                            <h6> Your card </h6>
                        </div>

                        { this.state.pr ? <div>
                            <PaymentRequestLoad options={{ paymentRequest: this.state.pr }}/>
                            <div style={{ height: '10px', borderBottom: '1px solid rgba(0,0,0,.1)', marginTop: '30px', marginBottom: '30px', position: 'relative' }}>
                                <p style={{ fontSize: '14px', position: 'absolute', bottom: '-25px', backgroundColor: 'white', left: '50%', transform: 'translateX(-50%)', padding: '0px 20px 0px 20px', color: 'rgba(0,0,0,.5)' }}> Or pay with card </p>
                            </div>
                        </div> : null }
                        
                        <CardFloat>
                            <CardContent style={{ padding: '10px', paddingBottom: '0' }}>
                                <div style={{ marginBottom: '10px'}}>
                                    <div style={{ margin: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <div style={{ display: 'inline-block', margin: '5px 5px 0px 5px', width: '100%' }}> 
                                            <Input style={{ marginBottom: '10px', width: '100%'}} value={this.state.first_name} placeholder="First Name" onChange = {(event) => {this.handleChange("first_name", event.target.value)}}/>
                                        </div>
                                        <div style={{ display: 'inline-block', margin: '5px 5px 0px 5px', width: '100%' }}> 
                                            <Input style={{ marginBottom: '10px', width: '100%'}} value={this.state.last_name} placeholder="Last Name" onChange = {(event) => {this.handleChange("last_name", event.target.value)}}/>
                                        </div>
                                    </div>
                                    <div style={{ margin: '0px 10px 10px 10px' }}>
                                        <Input style={{ marginBottom: '15px', width: '100%'}} value={this.state.email} placeholder="Email" onChange = {(event) => {this.handleChange("email", event.target.value)}}/>
                                    </div>
                                    <div style={{ margin: '10px'}}>
                                        <CardElementLoad/>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <CardProviders/>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                                            <Image alt="Stripe Authentication" style={{ width: '100px', opacity: '.8', margin: '10px' }} src={StripeBadge}/>
                                        </div>
                                    </div>

                                    <ModalButton handleClick={this.handlePurchase} label={`Pay $${this.getPrice(this.state.plan, this.state.promocode)}`} loading={this.state.status === 'loading' || this.state.status === 'authorizing'} error={this.state.status === 'error'} errorMessage={this.state.errorMessage} success={this.state.status === 'success'} style={{ position: 'relative', bottom: '0px', marginTop: '10px' }}/>
                                </div>
                            </CardContent>
                        </CardFloat>
                        <div style={{ margin: '10px', marginTop: '30px' }}>
                            <div style={{ padding: '0px 0px 15px 0px', borderBottom: '1px solid rgba(0, 0, 0, 0.05'}}>
                                <h6> Cancel Anytime </h6>
                                <h6 style={{ fontSize: '12px', opacity: '.6'}}> Settings {'>'} Payment {'>'} Cancel </h6>
                                <h6 style={{ fontSize: '13px', opacity: '.7'}}> Cancel your membership no questions asked. </h6>
                            </div>
                            <div style={{ padding: '15px 0px 15px 0px', borderBottom: '1px solid rgba(0, 0, 0, 0.05', position: 'relative' }}>
                                <h6 style={{ textAlign: 'right'}}> 100% Risk-Free Guarantee </h6>
                                <h6 style={{ fontSize: '13px', opacity: '.7', textAlign: 'right'}}> You'll love it or it's on us </h6>
                                <img alt='Risk-Free Guarantee' src={instagramVerification} style={{ opacity: '.7', height: '35px', position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '27px'}}/>
                            </div>
                            <div style={{ padding: '15px 0px 15px 0px', borderBottom: '1px solid rgba(0, 0, 0, 0.05', position: 'relative' }}>
                                <h6> Secured </h6>
                                <h6 style={{ fontSize: '13px', opacity: '.7'}}> This site is protected by <a href={'https://policies.google.com/privacy?hl=en-US'}> Google Privacy Policy </a> Terms of Service Apply </h6>
                            </div>
                        </div>

                        <h6 style={{ opacity: '.5', fontSize: '12px', textAlign: 'center', marginTop: '15px'}} > STNBLY - 30 N Gould Street, Sheridan, WY 82801 </h6>
                    </div> }
                    <Slide in={this.state.tab === 0 ? true : false} direction='up' style={{ position: 'fixed', bottom: '20px', left: '20px', width: 'calc(100% - 40px)'}}>
                        <div>
                            <ModalButton loading={this.state.loading || this.state.plansLoading} label={'Continue to Payment'} icon={<MdOutlineShortcut/>} handleClick={this.handleTab}/>
                        </div>
                    </Slide>
                </PageCard>
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        refreshToken }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout_revised)
