//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createSession, updateSession, deleteSession } from '../actions/dashboardActions'
//Components and Pages
import { CardContent } from '@material-ui/core';
import { Modal, CardFloat, Button, ModalButton, WorkoutCard, Input } from './'
import { Color, Measure } from '../util'
//Images
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete';

const initialState = {
    targetTime: new Date(),
    sets: {},
    time: 'now',
    success: false,
    error: false,
    loading: false
}

class TrackingAddModalFitness extends Component {
    constructor (props) {
        super(props)
        this.state = initialState
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.targetTime !== prevProps.targetTime) this.setState({ targetTime: this.props.targetTime, time: this.state.targetTime.getDate() === this.props.time.getDate() ? "now" : "" } )
        if (this.state.targetTime !== prevState.targetTime) this.setState({ time: this.state.targetTime.getDate() === this.props.time.getDate() ? "now" : ""})
        if (!prevProps.open && this.props.open) this.setState({ targetTime: this.props.targetTime, time: this.state.targetTime.getDate() === this.props.time.getDate() ? "now" : "", sets: this.generateSets(this.props.workouts) })
    }

    getSessions = () => {
        return this.props.dash.sessions.filter((session) => {
            let date = new Date(session.time), target = new Date(this.state.targetTime)
            return session.type === 'workout' && date.getDate() === target.getDate()
        })
    }

    isWeight = (workout) => {
        return workout && workout.equipment && (workout.equipment.includes('Barbells') || workout.equipment.includes('Dumbbells'))
    }

    isDuration = (workout) => {
        return workout && workout.duration
    }

    isCompleted = (workout_id) => {
        let sessions = this.getSessions()
        for (let i = 0; i < sessions.length; i++) {
            let session = sessions[i]
            if (session.target_id === workout_id) return true
        }

        return false
    }

    generateSets = (workouts) => {
        let sets = {}
        for (let i = 0; i < workouts.length; i++) {
            let workout = workouts[i], set = []
            if (this.isCompleted(workout.workout_id)) {
                let sessions = this.getSessions()
                for (let j = 0; j < sessions.length; j++) {
                    let session = sessions[j]
                    if (session.target_id === workout.workout_id) {
                        sets[workout.workout_id] = {
                            source: 'session', 
                            session_id: session._id,
                            edited: false,
                            sets: session.session_meta.sets,
                            workout: workout.workout
                        }
                    }
                }
            } else {
                let setCount = workout.session.sets, reps = workout.session.reps, duration = workout.session.duration
                for (let j = 0; j < setCount; j++) {
                    let temp = {}
                    if (duration) temp.duration = duration
                    if (reps) temp.reps = reps
                    set.push(temp)
                }
                
                sets[workout.workout_id] = {
                    source: 'plan',
                    sets: set,
                    workout: workout.workout 
                }
            }
        }

        return sets
    }

    generateSessionsAdd = (sets) => {
        let tempSets = Object.entries(sets)
        return tempSets.map((set) => {
            let [key, entry] = set
            if (entry.source === 'session' || !entry.sets.length) return null
            let temp = this.state.targetTime
            if (this.state.time === 'now') temp = this.props.time
            return { 
                time: temp.getTime(), 
                type: 'workout', 
                target_class: 'workout', 
                target_id: key, 
                session_meta: { sets: entry.sets },
                workout: entry.workout
            }
        }).filter((session) => session !== null)
    }

    generateSessionsUpdate = (sets) => {
        let tempSets = Object.entries(sets)
        return tempSets.map((set) => {
            let [key, entry] = set
            if (entry.source === 'plan' || !entry.sets.length || !entry.edited) return null
            return { 
                session_id: entry.session_id,
                session_meta: { sets: entry.sets },
                workout: entry.workout
            }
        }).filter((session) => session !== null)
    }

    generateSessionsDelete = (sets) => {
        let tempSets = Object.entries(sets)
        return tempSets.map((set) => {
            let [key, entry] = set
            if (entry.source === 'plan' || entry.sets.length) return null
            return entry.session_id
        }).filter((session) => session !== null)
    }

    // Set Handlers
    handleAddSet = (workout_id) => {
        let set = { }
        if (this.isWeight()) set.weight = this.state.weight || ''
        if (this.isDuration()) set.duration = this.state.duration || ''
        else set.reps = this.state.reps || ''

        let sets = this.state.sets[workout_id].sets
        if (sets) sets = [...sets, set]
        else sets = [set]

        this.setState({ sets: { ...this.state.sets, [workout_id] : { ...this.state.sets[workout_id], sets }}})
    }

    handleRemoveSet = (index, workout_id) => {
        let sets = this.state.sets, set = sets[workout_id].sets
        set.splice(index, 1)
        this.setState({ sets })
    }
    
    // Submissions Handlers
    handleAdd = () => {
        let sessions = this.generateSessionsAdd(this.state.sets)
        if (sessions.length) {
            return this.props.createSession(sessions, this.props.workouts.map((workout) => workout.workout)).then((response) => {
                this.handleSuccess()
            }).catch((error) => {
                this.handleError()
            })
        } else {
            return null
        }
    }

    handleUpdate = async () => {
        let sessions = this.generateSessionsUpdate(this.state.sets)
        for (let i = 0; i < sessions.length; i++) {
            let session = sessions[i]
            try {
                await this.props.updateSession(session.session_id, { session_meta: session.session_meta }, session.workout)
            } catch (error) {
                throw error
            }
        }
    }

    handleDelete = async () => {
        let sessions = this.generateSessionsDelete(this.state.sets)
        for (let i = 0; i < sessions.length; i++) {
            let session = sessions[i]
            try {
                await this.props.deleteSession(session)
            } catch (error) {
                throw error
            }
        }
    }

    handleSubmit = async () => {
        this.setState({ loading: true })
        try {
            await this.handleAdd()
            await this.handleUpdate()
            await this.handleDelete()
            this.handleSuccess()
        } catch (error) {
            this.handleError()
        }
        this.setState({ loading: false })
    }


    // Submission success and error handlers
    handleSuccess = () => {
        this.setState({ success: true })
        setTimeout(this.handleClose, 400)
    }

    handleError = () => {

    }

    handleEmbeddedChange = (index, workout_id, target, value) => {
        let sets = this.state.sets[workout_id].sets
        sets[index][target] = value
        let set = { ...this.state.sets[workout_id], sets }
        if (set.source === 'session') set.edited = true
        this.setState({ sets: { ...this.state.sets, [workout_id] : set }})
    }

    handleClose = () => {
        this.setState(initialState)
        this.props.handleClose()
    }

    getInputAdornment = (type) => {
        if (!this.props.auth.user.settings) return
        return Measure[this.props.auth.user.settings.unit][type].unit
    }

    getWorkoutType = (workouts) => {
        let types = []

        for (let workout in workouts) {
            if (workouts[workout] && workouts[workout].workout) {
                for (let type in workouts[workout].workout.extremities) {
                    types.push(workouts[workout].workout.extremities[type])
                }
            }
        }

        if (types.includes('Lower') && types.includes('Upper')) return 'Full Body'
        if (types.includes('Lower')) return 'Lower'
        return 'Upper'
    }

    renderSets = (workout) => {
        let sets = this.state.sets[workout.workout_id] ? this.state.sets[workout.workout_id].sets : []
        if (!sets) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }} onClick={() => this.handleAddSet(workout.workout_id)}>
            <h6 style={{ textAlign: 'center', color: Color.PRIMARY, marginBottom: '0px' }}> Add Set </h6>
            <AddIcon style={{ fontSize: '16px', marginLeft: '3px', color: Color.PRIMARY }}/>
        </div>
        
        sets = sets.map((set, i) => {
            return (
                <div key={i} style={{ display: 'flex', alignItems: 'center', position: 'relative', padding: '0px 10px 0px 10px' }}>
                    <h6 style={{ color: Color.PRIMARY, marginRight: '20px', marginBottom: '0px', whiteSpace: 'nowrap' }}> Set {i + 1} </h6>
                    <div style={{ display: 'flex', marginRight: '40px' }}>
                        { !this.isDuration(workout.workout) ?
                            <Input 
                                type="number"
                                style={{ marginBottom: '0px', width: '100%', marginRight: '10px' }}
                                value={set.reps} 
                                placeholder="Reps"
                                onChange = {(event) => this.handleEmbeddedChange(i, workout.workout_id, "reps", event.target.value)}/>
                            : 
                            <Input 
                                type="number"
                                style={{  marginBottom: '0px', width: '100%', marginRight: '10px' }}
                                value={set.duration} 
                                suff={'s'}
                                placeholder="Duration"
                                onChange = {(event) => this.handleEmbeddedChange(i, workout.workout_id, "duration", event.target.value)}/>
                        }
                        { this.isWeight(workout.workout) ?
                            <Input 
                                type="number"
                                style={{  marginBottom: '0px', width: '100%' }}
                                value={set.weight} 
                                suff={this.getInputAdornment('mass')}
                                placeholder="Weight"
                                onChange = {(event) => this.handleEmbeddedChange(i, workout.workout_id, "weight", event.target.value)}/>
                        : null }
                    </div>
                    <Button style={{ backgroundColor: Color.CALORIES_ERROR, position: 'absolute' }} onClick={() => this.handleRemoveSet(i, workout.workout_id)}>
                        <DeleteIcon/>
                    </Button>
                </div>
            )
        })

        return <div style={{ position: 'relative' }}>
            {
                sets.map((item, i) => {
                    return (
                        <div key={i}>
                            { item }
                            { i < sets.length - 1 ? <div style={{ height: '1px', borderTop: '1px solid', margin: '15px 20px 15px 20px', opacity: '.1' }}/> : <div style={{ height: '1px', borderTop: '1px solid', margin: '15px 20px 20px 20px', opacity: '.1' }}/> }
                        </div>
                    )
                })
            }
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }} onClick={() => this.handleAddSet(workout.workout_id)}>
                <h6 style={{ textAlign: 'center', color: Color.PRIMARY, marginBottom: '0px' }}> Add Set </h6>
                <AddIcon style={{ fontSize: '16px', marginLeft: '3px', color: Color.PRIMARY }}/>
            </div>
        </div>
    }

    confirmSubmit = () => {
        return Object.entries(this.state.sets).length 
    }

	render() {
        return (
            <Modal nobackdrop direction='left' in={this.props.open} style={{ borderRadius: 'none', height: '100%', boxShadow: 'none' }} handleClose={this.handleClose} head={`Complete ${this.getWorkoutType()} Workout`}>
                <div>
                    { this.props.workouts ? this.props.workouts.map((workout, i) => {
                        return (<div key={i} style={{ marginBottom: '30px', position: 'relative' }}>
                            <WorkoutCard handleInfoOpen={this.props.handleInfoOpen} workout={workout.workout} sets={workout.session}/>
                            <CardFloat>
                                <CardContent>
                                    { this.renderSets(workout) }
                                </CardContent>
                            </CardFloat>
                        </div>)
                    }) : null }
                </div>
                <ModalButton success={this.state.success} disabled={!this.confirmSubmit()} loading={this.state.loading} handleClick={this.handleSubmit} label={'Save Workout'}/>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
	return {
        auth: state.auth,
		dash: state.dash,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        createSession,
        updateSession,
        deleteSession }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingAddModalFitness)

