import React, { useEffect, useState } from 'react'

function StickyHeader(props) {
    const [scrollPosition, setScrollPosition] = useState(0)

    const handleScroll = () => { 
        setScrollPosition(window.pageYOffset) 
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => { window.removeEventListener('scroll', handleScroll) }
    }, [])

    return (
        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', position: 'sticky', top: '0px', zIndex: '100', padding: '15px', backgroundColor: scrollPosition > 0 ? 'white' : '', borderBottom: scrollPosition > 0 ? '1px solid rgba(0,0,0,.1)' : '', transition: 'background-color ease .1s' }}>
            { props.children }
        </div>
    )
}

export default StickyHeader