//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { searchFitness, loadPlanFitness, createPreference, updatePreference, deletePreference, loadSessions, getSessions } from '../actions/dashboardActions'
import { timeToLocal, localStart, localEnd } from '../util'
//Components and Pages
import { Card, CardContent, Grow, CircularProgress, TextField, Backdrop } from '@material-ui/core';
import { withRouter } from 'react-router-dom'
import { SpeedDialIcon, SpeedDial, SpeedDialAction } from '@material-ui/lab'
import { WorkoutCard, TrackingUpdateModalFitness, TrackingAddModalFitness, ActivityAddModal, RecalibrationCard, FitnessCardGroup, ModalSlide, Modal, PageCard, CustomWorkoutAddModal, CustomWorkoutEditModal, CustomFoodCards, CloseIcon, CustomActivityAddModal, CustomActivityEditModal, TrackingAddModalActivities, ActivityCard, TrackingUpdateModalActivities, FavoritesModal, HelpButton, DateSelector, SessionStats, PageLoading, FitnessInfoModal, FitnessPreviewModal, StickyHeader } from '../components'
//Images
import FilterListIcon from '@material-ui/icons/FilterList';
import stnblyGrayScale from '../images/stnblyGrayScale.svg'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

class Fitness extends Component {
    constructor (props) {
        super(props)
		this.state = {
            activityOpen: false,
            dial: false,
            preview: false,
            //Add Information
            addOpen: this.props.location.state ? this.props.location.state.addOpen : false,
            addWorkout: this.props.location.state ? this.props.location.state.addWorkout : undefined,
            addSession: this.props.location.state ? this.props.location.state.addSession: undefined,
            addCustom: false,

            activityAddOpen: false,
            addActivity: undefined,
            activityDuration: undefined,
            //Custom Add Modal Information
            customAddOpen: false,
            customOpen: false,
            //Custom Update Modal Information
            customUpdateOpen: false,
            customMeal: null,
            //Update Information
            updateOpen: false,
            updateSession: undefined,

            activityUpdateOpen: false,
            updateActivitySession: undefined,
            //Infotab Information
            infoOpen: this.props.location.state ? this.props.location.state.infoOpen : false,
            infoWorkout: this.props.location.state ? this.props.location.state.infoWorkout : undefined,
            infoSession: null,
            infoSession_id: null,
            //Search Information
            search: false,
            searchFilter: false,
            searchFilters: { $in : ["Vegan"] },
            searchVal: "",
            //Expanded Workouts
            expanded: false,
            expandedDate: this.props.location.state ? this.props.location.state.expanded : 0,
            //General Nutrition Information
            trackingTime: new Date(),
            time: new Date(),
            today: true,
            tab: this.props.location.state ? this.props.location.state.tab : 1
		}

        console.log(this.props.match.params)
    }

    componentDidMount() {
        this.timeClock = setInterval(this.tick, 1000)
        let query = new URLSearchParams(this.props.location.search)
        let trackingTime = query.get('trackingTime')
        if (trackingTime) this.handleTrackingChange(new Date(Number(query.get('trackingTime'))))
    }

    componentWillUnmount() { 
        clearInterval(this.timeClock)
        this.setState({ mounted: false })
    }

    tick = () => {
        this.setState({ time: new Date()})
    }

    handleSearchChange = (question, answer) => {
        this.setState({ [question] : answer, searchToggle: false, searchFilter: false })
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.searchToggle(answer);
        }, 1000)
    }

    searchToggle = (answer) => {
        if (answer) {
            this.setState({ searchToggle: true })
            this.props.searchFitness({ filters: { name: answer, equipment: this.props.auth.user.settings.equipment, physical_limitations: this.props.auth.user.settings.physical_limitations }})
        }
    }

    //Input and rendering handlers
    handleOverview = () => {
        this.setState({ tab: 0 })
    }

    handleTracking = () => {
        this.setState({ tab: 1 })
    }

    handleRedirect = () => {
        if (this.state.search) {
            this.setState({ search: false })
        } else if (this.state.tab !== 0) {
            this.setState({ tab: this.state.tab - 1 })
        } else {
            this.props.history.push('/dashboard')
        }
    }

    handleAddOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ addOpen: true, addWorkouts: args.workouts })
        if (args.addCustom) this.setState({ addCustom: true })
    }

    handleAddClose = () => {
        this.setState({ addOpen: false, search: false, updateOpen: false, infoOpen: false, activityOpen: false, customOpen: false })
    }

    handleActivityAddOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ activityAddOpen: true, addActivity: args.activity, activityDuration: args.duration })
    }

    handleActivityAddClose = () => {
        this.setState({ activityAddOpen: false, activityUpdateOpen: false, infoOpen: false })
    }

    handleUpdateOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ updateOpen: true, updateSession: args.session })
    }

    handleUpdateClose = () => {
        this.setState({ updateOpen: false, search: false, addOpen: false, infoOpen: false })
    }

    handleActivityUpdateOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ activityUpdateOpen: true, updateActivitySession: args.session })
    }

    handleActivityUpdateClose = () => {
        this.setState({ activityUpdateOpen: false, search: false, addOpen: false })
    }

    handleInfoOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ infoOpen: true, infoWorkout: args.workout, infoSession: args.session, infoSession_id: args.session_id, infoSets: args.sets })
    }

    handleInfoClose = () => {
        this.setState({ infoOpen: false })
    }

    handlePreviewOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ preview: true, previewWorkouts: args.workouts})
    }

    handlePreviewClose = () => {
        this.setState({ preview: false })
    }

    handleCustomUpdateOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ customUpdateOpen: true, customUpdateWorkout: args.workout })
    }

    handleCustomActivityUpdateOpen = (args) => {
        args.event.stopPropagation()
        this.setState({ customActivityUpdateOpen: true, customUpdateActivity: args.activity })
    }
    
    handleTrackingChange = (event) => {
        if (event.getDate() === this.state.time.getDate()) this.setState({ trackingTime: event, today: true })
        else this.setState({ trackingTime: event, today: false })

        let start = localStart(event), end = localEnd(event)
        if (this.verifyBuffer(start, end)) this.props.getSessions({ time: { $gte: start, $lt: end }, user_id: this.props.auth.user._id, expand: ['target'] })
        this.props.loadSessions(this.props.dash.sessions.filter(session => session.type === 'workout' && !session.target))

        let plan = this.findPlan(timeToLocal(event))
        if (plan) {
            let dates = []
            if (this.props.dash.buffer.indexOf(plan.date) === -1) {
                dates.push(plan)
            } else {
                for (let i in plan.fitness) {
                    if (!plan.fitness[i].workout) dates.push(plan)
                }
            }

            if (dates.length) this.props.loadPlanFitness(dates)
        }
    }

    verifyBuffer = (start, end) => {
        return start > this.props.dash.sessionBuffer.max || start < this.props.dash.sessionBuffer.min || end < this.props.dash.sessionBuffer.min || end > this.props.dash.sessionBuffer.max
    }

    handleExpand = (open) => {
        if (open) {
            let temp = new Date(this.state.time)
            let dates = []
    
            while (this.findPlan(timeToLocal(temp))) { 
                let plan = this.findPlan(timeToLocal(temp))
                if (this.props.dash.buffer.indexOf(plan.date) === -1) {
                    dates.push(plan)
                } else {
                    for (let i in plan.fitness) {
                        if (!(plan.fitness[i].workout)) {
                            dates.push(plan)
                        }
                    }
                }

                temp.setDate(temp.getDate() + 1)

            }
    
            if (dates.length > 0) {
                this.props.loadPlanFitness(dates)
            }
        }

        this.setState({ expanded : open })
    }


    handleFavoritesOpen = () => {
        this.setState({ favoritesOpen: true })
    }

    handleFavoritesClose = () => {
        this.setState({ favoritesOpen: false })
    }

    handleExclusionsOpen = () => {
        this.setState({ exclusionsOpen: true })
    }

    handleExclusionsClose = () => {
        this.setState({ exclusionsOpen: false })
    }

    renderTime = (sessions) => {
        return sessions.length ? <FitnessCardGroup handlePreviewOpen={this.handleAddOpen} time={this.state.time} workouts={sessions.map((session) => session.target)}/> : null
    }

    renderCardio = (sessions) => {
        return sessions.map((session, i) => {
            return <ActivityCard key={i} activity={session.target} time={this.state.time} handleUpdateOpen={this.handleActivityUpdateOpen} session={session}/>
        })
    }

    renderWeek = () => {
        let workouts = [], activities = []

        let sessions = this.props.dash.sessions.filter((session) => session.type === 'workout')
        sessions.map((session) => {
            let temp = new Date(session.time)
            if (temp.getDate() === (this.state.today ? this.state.time : this.state.trackingTime).getDate()) workouts.push(session)
            return session
        })

        let cardioSessions = this.props.dash.sessions.filter((session) => session.type === 'activity')
        cardioSessions.map((session) => {
            let temp = new Date(session.time)
            if (temp.getDate() === (this.state.today ? this.state.time : this.state.trackingTime).getDate()) activities.push(session)
            return session
        })
        
        // || entryTime
        if (workouts.length > 1) workouts.sort((a, b) => a.time -  b.time)
        if (activities.length > 1) activities.sort((a, b) => a.time -  b.time)

        return (
            <div>
                { workouts.length < 1 && activities.length < 1 ? <Grow in={true}>
                        <Card style={{ position: 'relative', borderRadius: '25px', marginBottom: '15px', marginTop: '20px', backgroundColor: 'grey', opacity: '.5'}}>
                            <CardContent>
                                <h6 style={{ textAlign: 'center' , marginTop: '20px'}}> No Entries for Today </h6>
                            </CardContent>
                        </Card>
                    </Grow> : null}
                { this.renderTime(workouts) }
                { this.renderCardio(activities) }
            </div>
        )
    }

    renderSearchResults = () => {
        let results = this.props.dash.search.fitnessResults.map((workout, i) => {
            return <WorkoutCard key={i} workout={workout} time={this.state.time} handleInfoOpen={this.handleInfoOpen} handleAddOpen={this.handleAddOpen} />
        })

        if (this.props.dash.search.searchLoading || !this.state.searchToggle) {
            return (<Grow in={true}>
                <Card style={{ borderRadius: '25px', marginBottom: '15px', marginTop: '20px', backgroundColor: 'grey', opacity: '.5'}}>
                    <CardContent>
                        <div style={{ position: 'relative', height: '40px', marginTop: '5px'}}>
                            <div style={{justifyContent: 'center', display: 'flex'}}>
                                <CircularProgress style={{ color: 'black', position: 'absolute'}}/>
                            </div>
                            <div style={{justifyContent: 'center', display: 'flex'}}>
                                <img alt='stnbly loading' style={{ color: 'black', position: 'absolute', height: '25px', marginTop: '7px'}} src={stnblyGrayScale}/>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Grow>)
        } else if (results.length === 0 && !this.props.dash.search.searchLoading) {
            return (<Grow in={true}>
                <Card style={{ borderRadius: '25px', marginBottom: '15px', marginTop: '20px', backgroundColor: 'grey', opacity: '.5', justifyContent: 'center', display: 'flex'}}>
                    <CardContent>
                        <Grow key={1} in={true}>
                            <h5 style={{ marginTop: '20px', marginBottom: '10px'}}> No results </h5>
                        </Grow>
                    </CardContent>
                </Card>
            </Grow>)        
        } else {
            return results
        }
    }

    renderCustom = () => {
        return (
            <Modal direction='up' in={this.state.customOpen && !this.state.addOpen && !this.state.infoOpen && !this.state.customAddOpen && !this.state.customUpdateOpen} handleClose={() => this.setState({ customOpen: false })} head={'Add Custom Workout'}>
                <Card style={{ borderRadius: '25px', marginBottom: '15px', backgroundColor: 'grey', opacity: '.5', marginTop: '20px'}} onClick={(event) => this.setState({ customAddOpen: true })}>
                    <CardContent>
                        <h6 style={{ textAlign: 'center' , marginTop: '20px'}}> Create new custom workout + </h6>
                    </CardContent>
                </Card>
                <CustomFoodCards workouts open={this.state.customOpen} handleAddOpen={this.handleAddOpen} handleInfoOpen={this.handleInfoOpen} handleCustomUpdateOpen={this.handleCustomUpdateOpen}/>
            </Modal>
        )
    }

    renderSearch = () => {
        return (
            <ModalSlide backdrop in={this.state.search && !this.state.addOpen && !this.state.infoOpen}>
                <Card style={{ position: 'fixed', top: '0', right: '0', left: '0', maxWidth: '600px', marginRight: 'auto', marginLeft: 'auto',  borderRadius: '0px 0px 25px 25px', zIndex: '1010', width: '100%', maxHeight: '100%', overflowY: 'auto', overflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', flex: '1', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <CardContent style={{ paddingTop: '13px'}}>
                        <CloseIcon handleClose={() => this.setState({ search: false })} style={{ top: '15px', right: '10px'}}/>

                        <div style={{ marginRight: '60px'}}> 
                            <TextField variant="outlined" label={"Search"} style={{ width: '100%', marginBottom: '15px' }} InputProps={{ style: { borderRadius: '25px'}, endAdornment: <FilterListIcon/>}} onChange={(event) => this.handleSearchChange("searchVal", event.target.value)}/>
                        </div>
                    </CardContent>
                </Card>
            </ModalSlide>    
        )
    }

    renderWorkout = (workout, i) => {
        return <WorkoutCard key={i} sets={workout.session} workout={workout.workout} time={this.state.time} handleInfoOpen={this.handleInfoOpen} handleAddOpen={this.handleAddOpen} />
    }

    //Renders User-Curr Workouts
    renderWorkouts = (timeT, stamps) => {
        let time = new Date(timeT), expanded = false
        let plan = this.findPlan(timeToLocal(time))
        if (plan && plan.date === this.state.expandedDate) expanded = true

        return (<div>
            { stamps ? (
                <div>
                    {time.getDate() === this.state.time.getDate() ? <h6 style={{ opacity: '.7' }}> Today </h6> :                         <div>
                            <h5> { days[time.getDay()] } </h5>
                            <h6 style={{ opacity: '.7'}}> { months[time.getMonth()] + " " + time.getDate() + ", " + time.getFullYear() } </h6> 
                        </div>  }
                </div> ) : null
            }
            { plan ? 
                ( plan.fitness.length || plan.activities.length ? 
                <div>
                    { plan.fitness.length ? <FitnessCardGroup handlePreviewOpen={this.handlePreviewOpen} completeAll={true} expanded={expanded} workouts={plan.fitness} time={this.state.time} trackingTime={this.state.trackingTime}/> : null }
                    { plan.activities.map((activity, i) => {
                        return <ActivityCard key={i} verifyWorkout={true} trackingTime={this.state.trackingTime} activity={activity.activity} handleAddOpen={this.handleActivityAddOpen} duration={activity.duration} verifyActivity={this.state.time.getDate() === time.getDate()}/>
                    })}
                </div>  :                 
            <Grow in={true}>
                <Card style={{ borderRadius: '25px', marginBottom: '15px', backgroundColor: 'grey', opacity: '.5', marginTop: '20px'}}>
                    <CardContent>
                        <h6 style={{ textAlign: 'center' , marginTop: '20px'}}> No Workouts for Today </h6>
                    </CardContent>
                </Card>
            </Grow>) :          
                <Grow in={true}>
                    <Card style={{ borderRadius: '25px', marginBottom: '15px', backgroundColor: 'grey', opacity: '.5', marginTop: '20px'}}>
                        <CardContent>
                            <h6 style={{ textAlign: 'center' , marginTop: '20px'}}> No Workouts for Today </h6>
                        </CardContent>
                    </Card>
                </Grow>
            }
        </div>)
    }

    findPlan = (time) => {
        for (let i = 0; i < this.props.dash.plan.length; i++) {
            if (this.props.dash.plan[i].date === time) {
                return this.props.dash.plan[i]
            }
        }

        return false
    }

    renderExpanded = () => {
        let temp = new Date(this.state.time), components = []
        temp.setDate(temp.getDate() + 1)

        while (this.findPlan(timeToLocal(temp))) { 
            components.push(
                <div key={temp.getDate()}>
                    <div>
                        { this.renderWorkouts(temp, true) }
                    </div>
                </div>
            )

            temp.setDate(temp.getDate() + 1)
        }

        components.push(<RecalibrationCard link={{pathname: '/dashboard/recalibration', state: { fitness: true }}} recalibrateLink={{pathname: '/dashboard/recalibration', state: { fitness: true, recalibrationOpen: true }}} time={this.state.time} key={temp.getDate() + 1}/>)

        return components
    }

    fitnessLevel = (fitness_level) => {
        switch(fitness_level) {
            case (1) : {
                return 'Beginner'
            }

            case (2) : {
                return 'Intermediate'
            }

            case (3) : {
                return 'Advanced'
            }

            default : {
                return 'Beginner'
            }
        }
    }


    renderOverview = () => {
        return (
            <div>
                <SessionStats/>
                <div style={{ padding: '0px 0px 0px 0px' }}>
                    {/* <Selector style={{ marginBottom: '20px' }} value={this.state.tab} options={[{ value: 0, label: 'Tracking'}, { value: 1, label: 'Plan'}]} handleChange={(value) => this.setState({ tab: value })}/>*/}
                    { this.state.tab === 0 ? this.renderWeek() : this.renderWorkouts(this.state.trackingTime) }
                </div>
            </div>
        )
    }

    renderActivities = () => {
        return (
            <Modal direction='left' in={this.state.activityOpen && !this.state.addOpen && !this.state.customActivityAddOpen && !this.state.customActivityUpdateOpen && !this.state.activityAddOpen} head={'Add Activity'} handleClose={this.handleActivityClose}>
                <Card style={{ borderRadius: '25px', marginBottom: '15px', backgroundColor: 'grey', opacity: '.5', marginTop: '20px'}} onClick={() => this.setState({ customActivityAddOpen: true })}>
                    <CardContent>
                        <h6 style={{ textAlign: 'center' , marginTop: '20px'}}> Create new custom activity + </h6>
                    </CardContent>
                </Card>
                <CustomFoodCards activities open={this.state.activityOpen} handleAddOpen={this.handleActivityAddOpen} handleCustomUpdateOpen={this.handleCustomActivityUpdateOpen}/>
            </Modal>
        )
    }

    renderFavorites = () => {
        return (
            <FavoritesModal open={this.state.favoritesOpen && !this.state.addOpen && !this.state.infoOpen} type='workout' handleClose={this.handleFavoritesClose} handleAddOpen={this.handleAddOpen} handleInfoOpen={this.handleInfoOpen}/>
        )
    }

    renderExclusions = () => {
        return (
            <FavoritesModal exclusions open={this.state.exclusionsOpen && !this.state.addOpen && !this.state.infoOpen} type='workout' handleClose={this.handleExclusionsClose} handleAddOpen={this.handleAddOpen} handleInfoOpen={this.handleInfoOpen}/>
        )
    }

    handleActivityOpen = () => {
        this.setState({ activityOpen: true, dial: false })
    }

    handleActivityClose = () => {
        console.log(this.state.dial)
        this.setState({ activityOpen: false, dial: false })
    }

    renderButtons = () => {
        let actions = [/*{
            name: 'Search',
            icon: <SearchIcon/>,
            tooltipTitle: 'Search',
            onClick: () => this.setState({ search: true })
        }*/, {
            name: 'Activity',
            icon: <DirectionsRunIcon/>,
            tooltipTitle: 'Activities',
            onClick: this.handleActivityOpen
        }, /*{
            name: 'Create',
            icon: <NoteAddIcon/>,
            tooltipTitle: 'Create',
            onClick: () => this.setState({ customOpen: true })
        }, {
            name: 'Favorites',
            icon: <ThumbUp/>,
            tooltipTitle: 'Favorites',
            onClick: this.handleFavoritesOpen
        }, {
            name: 'Exclusions',
            icon: <ThumbDown/>,
            tooltipTitle: 'Exclusions',
            onClick: this.handleExclusionsOpen
        }*/]

        return (
            <div>
                { 
                    <SpeedDial FabProps={{ style: { outline: 'none', boxShadow: '0 6px 20px 5px rgba(0, 0, 0, 0.15)'} }} ariaLabel="SpeedDial tooltip example" icon={<SpeedDialIcon style={{ outline: 'none'}}/>} color="primary" style={{ '*:focus' : '{ outline: none }', outline: 'none', margin: '10px', position: 'fixed', bottom:'70px', right:'0', zIndex: '50' }} onOpen={() => this.setState({ dial: true })} onClose={() => this.setState({ dial: false })} open={this.state.dial}>
                        { actions.map((action) => {
                            return (<SpeedDialAction
                                FabProps={{ style: { outline: 'none'} }}
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                style={{ outline: 'none' }}
                                tooltipOpen
                                onClick={action.onClick}
                            />)
                        })}
                    </SpeedDial>
                }
                <Backdrop style={{ zIndex: '49' }} in={this.state.dial}/>
            </div>
        )
    }

    render() {
        return (
            <div>
                { /* Modal Handler/Instantiation */ }
                <div>
                    <TrackingAddModalActivities open={this.state.activityAddOpen} activity={this.state.addActivity} duration={this.state.activityDuration} targetTime={this.state.trackingTime} time={this.state.time} handleClose={this.handleActivityAddClose}/>
                    <TrackingAddModalFitness handleInfoOpen={this.handleInfoOpen} open={this.state.addOpen} workouts={this.state.addWorkouts} session={this.state.addSession} targetTime={this.state.trackingTime} time={this.state.time} handleClose={this.handleAddClose}/>
                    <FitnessPreviewModal open={this.state.preview} trackingTime={this.state.trackingTime} handleInfoOpen={this.handleInfoOpen} handleClose={this.handlePreviewClose} handleAddOpen={this.handleAddOpen} workouts={this.state.previewWorkouts}/>
                    <TrackingUpdateModalFitness open={this.state.updateOpen} session={this.state.updateSession} time={this.state.time} handleClose={this.handleUpdateClose}/>
                    <TrackingUpdateModalActivities open={this.state.activityUpdateOpen} session={this.state.updateActivitySession} targetTime={this.state.trackingTime} time={this.state.time} handleClose={this.handleActivityUpdateClose}/>
                    <CustomWorkoutAddModal open={this.state.customAddOpen} handleClose={() => this.setState({ customAddOpen: false })}/>
                    <CustomWorkoutEditModal open={this.state.customUpdateOpen} workout={this.state.customUpdateWorkout} handleClose={() => this.setState({ customUpdateOpen: false })}/>
                    <CustomActivityAddModal open={this.state.customActivityAddOpen} handleClose={() => this.setState({ customActivityAddOpen: false })}/>
                    <CustomActivityEditModal open={this.state.customActivityUpdateOpen} handleClose={() => this.setState({ customActivityUpdateOpen : false })} activity={this.state.customUpdateActivity}/>
                    <FitnessInfoModal open={this.state.infoOpen && !this.state.updateOpen} handleClose={this.handleInfoClose} handleAddOpen={this.handleAddOpen} handleUpdateOpen={this.handleUpdateOpen} infoSets={this.state.infoSets} infoWorkout={this.state.infoWorkout} infoSession={this.state.infoSession}/>
                    <ActivityAddModal open={this.state.activityOpen} handleClose={this.handleActivityClose} handleCustomActivityAddOpen={() => this.setState({ customActivityAddOpen: true })} handleCustomActivityUpdateOpen={this.handleCustomActivityUpdateOpen} handleActivityAddOpen={this.handleActivityAddOpen}/>
                </div>

                { /* Search Slide Down */ }
                {this.renderSearch()}

                { /* Custom Workouts Menu */ }
                {this.renderCustom()}

                { /* User Favorites */ }
                {this.renderFavorites()}

                { /* User Favorites */ }
                {this.renderExclusions()}
                
                {/* Activities Modals */}
                {/* this.renderActivities() */}

                { /*  Main Panel Buttons */ }
                {this.renderButtons()}

                <PageLoading direction={'up'} loading={this.props.dash.sessionLoading}/>

                { /* Main Panel Renders | Overview vs. Render */ }
                <StickyHeader>
                    <h5 style={{ marginBottom: '0px' }}> Fitness </h5>
                    <DateSelector style={{ marginBottom: '0px', marginLeft: 'auto', marginTop: '0px' }} value={this.state.trackingTime} handleChange={(event) => this.handleTrackingChange(event)}/>
                </StickyHeader>
                <PageCard title={'Fitness'} style={{ paddingTop: '0px', marginTop: '-15px' }}>
                    { this.renderOverview() }
                </PageCard>
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        searchFitness,
        loadPlanFitness,
        createPreference,
        updatePreference,
        deletePreference,
        loadSessions,
        getSessions
     }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Fitness))
