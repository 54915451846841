import React from 'react';

import { ReactComponent as Amex } from '../images/amex2.svg'
import { ReactComponent as Visa } from '../images/visa2.svg'
import { ReactComponent as Mastercard } from '../images/mastercard2.svg'
import { ReactComponent as Discover } from '../images/discover2.svg'

const CardProviders = (props) => {
    return (
        <div style={{ margin: '10px', display: 'flex', alignItems: 'center' }}>
            <Amex alt='American Express' style={{ height: '20px', width: '20px' }}/>
            <Visa alt='Visa' style={{ height: '20px', width: '20px' }}/>    
            <Mastercard alt='Mastercard' style={{ height: '20px', width: '20px' }}/>
            <Discover alt='Discover' style={{ height: '20px', width: '20px' }}/>    
        </div>
    )
}

export default CardProviders