import React, { Component } from 'react';
import { CircularProgress, CardContent } from '@material-ui/core'
import { Modal, NutritionInfo, NutritionBar, Chip, Selector, CardFloat, ModalButton, Input, ChipSelector, Image } from './'
import { Color, getAttribute, toTitleCase, searchFoodPortions } from '../util'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createPreference, updatePreference, deletePreference, createSession, swapMealPlanItem } from '../actions/dashboardActions'

import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { TbRepeat } from 'react-icons/tb'
import EditIcon from '@material-ui/icons/Edit'
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import apis from "../api";
import { BiBlock, BiHeart } from 'react-icons/bi';

let verbs = ["arrange", "add", "boil", "remove", "place", "reduce", "baste", "beat", "blend", "brown", "build", "bury", "carve", "check", "chop", "close", "cool", "correct", "cover", "crumple", "cut", "decorate", "discard", "divide", "drape", "drop", "dry", "film", "fold", "follow", "form", "force", "glaze", "insert", "lay", "leave", "lift", "make", "melt", "mince", "mix", "moisten", "mound", "open", "pack", "paint", "pierce", "pour", "prepare", "press", "prick", "pull", "puree", "push", "quarter", "raise", "reduce", "refresh", "reheat", "replace", "return", "ring", "roast", "roll", "salt", "saute", "scatter", "scoop", "scrape", "scrub", "season", "separate", "set", "settle", "shave", "simmer", "skim", "slice", "slide", "slip", "slit", "smear", "soak", "spoon", "spread", "sprinkle", "stir", "strain", "strew", "stuff", "surround", "taste", "thin", "tie", "tilt", "tip", "top", "toss", "trim", "turn", "twist", "warm", "wilt", "wind", "wrap"]

const SearchListing = (props) => {
    return (
        props.food ? <div style={{ margin: '7px 0px 7px 0px', padding: '8px', backgroundColor: 'rgba(0,0,0,.05)', borderRadius: '10px', fontSize: '14px'}} onClick={() => props.handleInfoOpen?.({ meal: props.food, populate: true })}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                { props.food.image_url || props.food.thumb_url ? <Image src={props.food.image_url || props.food.thumb_url} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', backgroundColor: 'grey', border: 'none', marginRight: '8px' }}/> : null }
                <p style={{ margin: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}> { toTitleCase(props.food.name) }  </p>       
                <p style={{ marginLeft: 'auto', margin: '0px', opacity: '.7', whiteSpace: 'nowrap' }}> { props.metadata.servings } { props.metadata.serving_unit }  </p>       
            </div>
        </div> : null
    )
}

class NutritionInfoModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: 'ingredients',
            populating: false,
            food: null,
            servings: 1,
            servingType: null,
            meal: 'breakfast',
            add: false,
            success: false
        }
    }

    verify = (food) => food.is_complete !== false

    isAdd = (food) => {
        return food.class === 'branded' || food.class === 'common' || food.class === 'custom' 
    }

    async componentDidUpdate (prevProps) {
        if (prevProps.infoMeal !== this.props.infoMeal) {
            if (!this.props.infoMeal) return
            let food = this.props.infoMeal
            if (!this.verify(this.props.infoMeal)) food = await this.populate(this.props.infoMeal)
            this.setState({ 
                food, 
                populating: false, 
                success: false, 
                meal: this.props.tod ? this.props.tod.toLowerCase() : (this.props.trackingTime.getDate() === this.props.time.getDate() ? this.handleMealTime(this.props.time) : 'breakfast'), 
                servingType: this.props.infoSession ? this.props.infoSession.metadata.serving_unit : food.def_unit || 'serving', 
                servings: this.props.infoSession ? this.props.infoSession.metadata.servings : food.def_size || 1, 
                add: this.isAdd(food) 
            })
        } else if (this.props.tod !== prevProps.tod || this.props.trackingTime !== prevProps.trackingTime) {
            this.setState({ 
                meal: this.props.tod ? this.props.tod.toLowerCase() : (this.props.trackingTime.getDate() === this.props.time.getDate() ? this.handleMealTime(this.props.time) : 'breakfast')
            })
        }
    }

    handleClose = () => {
        //this.props.history.push('/dashboard/nutrition')
        this.setState({ success: false })
        this.props.handleClose()
    }

    handleAdd = () => {
        this.props.createSession({ 
            time: this.props.trackingTime?.getTime() || this.props.time.getTime(), 
            type: 'food', 
            target_class: this.state.food.class, 
            target_id: this.state.food._id, 
            target_tag_id: this.state.food.tag_id,
            target_nix_id: this.state.food.nix_item_id,
            target_upc: this.state.food.upc,
            target: this.state.food.class === 'common' || this.state.food.class === 'branded' ? {
                ...this.state.food,
                class: null
            } : null,
            metadata : { 
                meal: this.state.meal,
                servings: Number(this.state.servings), 
                serving_unit: this.state.servingType
            }
        }, this.state.food).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            console.log(error)
        })
    }

    handleMealTime = (time) => {
        let hours = time.getHours()
        if (hours <= 10) return 'breakfast'
        else if (hours <= 13) return 'lunch'
        else return 'dinner'
    }

    handleChange = (question, answer) => {
        switch (question) {
            case ('servingType') : {
                return this.setState({
                    servingType: answer,
                    servings: Math.round((
                        this.state.servings * (searchFoodPortions(this.state.servingType, this.state.food) / searchFoodPortions(answer, this.state.food)) || 1
                    ))
                })
            }

            default: this.setState( { [question]: answer })
        }
    }

    populate = async (food) => {
        let populate = null
        this.setState({ populating: true })

        try { 
            switch (food.class) {
                case ('common') : 
                    populate = await apis.getCommonFoods(food.food_name) // get common nix foods
                    break
                case ('branded') : 
                    populate = await apis.getBrandedFoods(food.nix_item_id) // get branded nix foods
                    break
                case ('recipe') : 
                    populate = await apis.getRecipeById(food._id)
            }
        } catch (error) {
            console.log(error)
        }

        return populate?.data 
    }

    findPreference = (food, exclusion) => {
        switch (food.class) {
            case ('common') : {
                return this.props.dash.preferences.find((preference) => preference.target_tag_id === food.tag_id && ((exclusion && preference.exclusion) || (!exclusion && !preference.exclusion)))
            }

            case ('branded') : {
                return this.props.dash.preferences.find((preference) => preference.target_nix_id === food.nix_item_id && ((exclusion && preference.exclusion) || (!exclusion && !preference.exclusion)))
            }

            default : {
                return this.props.dash.preferences.find((preference) => preference.target_id === food._id && ((exclusion && preference.exclusion) || (!exclusion && !preference.exclusion)))
            }
        }
    }

    handleFavorite = (food) => {
        let pref = this.findPreference(food, true)
        if (pref) return this.props.updatePreference(pref._id, { ...pref, exclusion: false })
        return this.props.createPreference({ 
            type: 'food', 
            target_class: food.class, 
            target_id: food._id, 
            target_tag_id: food.tag_id,
            target_nix_id: food.nix_item_id,
            target_upc: food.upc,
            target: { ...food },
            user_id: this.props.auth.user._id, 
            exclusion: false 
        }, food)
    }

    handleExclude = (food) => {
        let pref = this.findPreference(food, false)
        if (pref) return this.props.updatePreference(pref._id, { ...pref, exclusion: true })
        return this.props.createPreference({ 
            type: 'food', 
            target_class: food.class, 
            target_id: food._id, 
            target_tag_id: food.tag_id,
            target_nix_id: food.nix_item_id,
            target_upc: food.upc,
            target: { ...food },
            user_id: this.props.auth.user._id,
            exclusion: true 
        }, food)
    }

    renderMenuItems = (food) => {
        if (!food) return []
        if (!food.alt_units) return [food.def_unit || 'serving']
        let items = food.alt_units.map((unit) => unit.measure)
        return items
    }

    renderFavoritesButton = (food) => {
        if (!food) return null
        let pref = this.findPreference(food, false)
        if (pref) return <BiHeart style={{ marginBottom: '50%', fontSize: '20px', color: 'white', backgroundColor: Color.PRIMARY }} onClick={() => this.props.deletePreference(pref._id)}/>
        return <BiHeart style={{ marginBottom: '50%', color: 'white', fontSize: '20px' }} onClick={() => this.handleFavorite(this.state.food)}/>
    }

    renderExclusionsButton = (food) => {
        if (!food) return null
        let pref = this.findPreference(food, true)
        if (pref) return <BiBlock style={{ marginBottom: '50%', fontSize: '20px', color: 'white', backgroundColor: Color.CARBS_ERROR }} onClick={() => this.props.deletePreference(pref._id)}/>
        return <BiBlock style={{ marginBottom: '50%', color: 'white', fontSize: '20px'}} onClick={() => this.handleExclude(this.state.food)}/>
    }

    renderInstructions = (instructions) => {
        if (!instructions) return null
        let temp = instructions.split(":")[1], list = []
        try {
            let instr = temp.split('\n')
            for (let element in instr) {
                let instruction = instr[element]
                if (instruction.trim() !== "") {
                    list.push(
                        <div key={element} style={{ display: 'flex', alignItems: 'center', padding: '0px 10px 0px 10px' }}>
                            <h6 style={{ color: Color.PRIMARY, marginRight: '20px', marginBottom: '0px' }}> {element}. </h6>
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h6 style={{ fontSize: '14px', marginBottom: '0px'}}> {instruction}  </h6>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        } catch (error) {
            console.log(error)
        }
        
        return (
            <div style={{ position: 'relative' }}>
                {
                    list.map((item, i) => {
                        return (
                            <div key={i}>
                                { item }
                                { i < list.length - 1 ? <div style={{ height: '1px', borderTop: '1px solid', margin: '15px 20px 15px 20px', opacity: '.1' }}/> : null }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderRecipe = (instructions) => {
        if (!instructions) return null
        let temp = instructions, list = []
        try {
            let instr = temp.split('\n')
            let count = 1
            for (let element in instr) {
                let instruction = instr[element]
                if (instruction.trim() !== "" && !instruction.trim().includes('Step')) {
                    list.push(
                        <div key={element} style={{ display: 'flex', alignItems: 'center', padding: '0px 10px 0px 10px' }}>
                            <h6 style={{ color: Color.PRIMARY, marginRight: '20px', marginBottom: '0px' }}> {count}. </h6>
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px'}}>
                                    <h6 style={{ fontSize: '14px', marginBottom: '0px'}}> {instruction.split(' ').map((el, i) => {
                                        if (verbs.includes(el.trim().toLowerCase())) {
                                            return <span key={i} style={{ color: Color.PRIMARY }}> {el} </span>
                                        } else return <span key={i}> {el} </span>
                                    })} </h6>
                                </div>
                            </div>
                        </div>
                    )

                    count += 1
                }
            }
        } catch (error) {
            console.log(error)
        }
        
        return (
            <div style={{ position: 'relative' }}>
                {
                    list.map((item, i) => {
                        return (
                            <div key={i}>
                                { item }
                                { i < list.length - 1 ? <div style={{ height: '1px', borderTop: '1px solid', margin: '15px 20px 15px 20px', opacity: '.1' }}/> : null }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderTitle = (food) => {
        if (!food || !food.name) return 'food'
        return toTitleCase(food.name)
    }

    confirmSubmit = () => {
        if (!this.state.add) return true
        return this.state.servings && Number(this.state.servings) > 0 & this.state.meal !== ""
    }

    handleSuccess = () => {
        this.setState({ success: true })
        setTimeout(this.handleClose, 400)
    }

    handleSwap = () => {
        this.props.swapMealPlanItem({
            new_food_id: this.state.food._id,
            food_id: this.props.swapInfo.food_id,
            plan_id: this.props.swapInfo.plan_id,
            date: this.props.swapInfo.date,
            meal: this.props.swapInfo.meal
        }).then((response) => {
            if (this.props.onSwap) this.props.onSwap()
            this.handleSuccess()
        }).catch((error) => {
            console.log(error)
        })
    }

    handleClick = (event) => {
        if (this.props.swap)  this.handleSwap()
        else if (this.state.add) this.handleAdd()
        else this.props.handleAddOpen({ event, meal: this.state.food, serving: this.props.infoAmount })
    }

    render() {
        let options = [{ variable: 'time', value: 'breakfast', label: 'Breakfast'}, { variable: 'time', value: 'lunch', label: 'Lunch'}, { variable: 'time', value: 'dinner', label: 'Dinner'}, { variable: 'time', value: 'snack', label: 'Snack' }]

        return (
            <Modal direction='up' in={this.props.open} style={{ maxHeight: '90%' }} head={ !this.state.populating ? this.renderTitle(this.state.food) : null } img={ this.state.food && !this.state.populating ? (this.state.food.image_url || this.state.food.thumb_url) : null} handleClose={this.handleClose} functions={
                !this.state.populating ? [
                    ...(this.state.food?.class === 'recipe' ? [this.renderExclusionsButton(this.state.food)] : []),
                    this.renderFavoritesButton(this.state.food),
                    this.props.infoSession ? <EditIcon style={{ marginBottom: '50%', color: 'white', fontSize: '20px'}} onClick={(event) => this.props.handleUpdateOpen({ event: event, session: this.props.infoSession, meal: this.state.food })}/> : null,
                ] : null
            }>
                <div>
                    { this.state.food && !this.state.populating ? 
                        <div> 
                            { this.state.food.cooking_level ? <Chip style={{ position: 'absolute', top: '-65px', left: '-5px', zIndex: '1005', border: 'none' }} label={this.state.food.cooking_level} icon={<LocalDiningIcon style={{ fontSize: '16px', marginLeft: '3px' }}/>}/> : null }
                            { this.state.food.brand_name ? <h6 style={{ opacity: '.7', fontSize: '16px' }}> {this.state.food.brand_name} </h6> : null }
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                        <NutritionBar style={{ position: 'relative', width: '100px', height: '8px'}} carbs={getAttribute('carbs', this.state.food, this.props.infoSession)} protein={getAttribute('protein', this.state.food, this.props.infoSession)} fat={getAttribute('fat', this.state.food, this.props.infoSession)}/>
                                        <p style={{ marginBottom: '0px', fontSize: '12px', marginLeft: '5px', opacity: '.7' }}> {getAttribute('calories', this.state.food, this.props.infoSession)} Cals </p>
                                    </div>
                                    <p style={{ fontSize: '12px', marginBottom: '0px' }}> <span style={{ color: Color.CARBS}}> {getAttribute('carbs', this.state.food, this.props.infoSession)}C </span> <span style={{ color: Color.PROTEIN }}> {getAttribute('protein', this.state.food, this.props.infoSession)}P </span> <span style={{ color: Color.FAT }}> {getAttribute('fat', this.state.food, this.props.infoSession)}F </span> </p>
                                </div>
                                <div style={{ marginLeft: 'auto' }}>
                                    { this.state.food.total_time ? <p style={{ fontSize: '14px', marginBottom: '0px' }}> {this.state.food.total_time} min total </p> : null }
                                    { this.state.food.prep_time ? <p style={{ fontSize: '12px', marginBottom: '0px', opacity: '.7' }}> {this.state.food.prep_time} min prep </p> : null }
                                </div>
                            </div>

                            <NutritionInfo add simple_macros={this.props.auth?.user.settings.simple_macros} trackingTime={this.props.trackingTime} servings={this.state.servings} servingType={this.state.servingType} time={this.props.time} food={this.state.food}/>

                            { this.state.food.ingredients && this.state.food.instructions ? <Selector style={{ marginBottom: '20px', marginTop: '20px' }} value={this.state.tab} options={[{ label: 'Ingredients', value: 'ingredients' }, { label: 'Instructions', value: 'instructions' }]} handleChange={(value) => this.setState({ tab: value })}/> : null }

                            { /* Ingredients */ }
                            { this.state.food.ingredients && this.state.tab === 'ingredients' ? <CardFloat outline >
                                <CardContent>
                                    { this.renderInstructions(this.state.food.ingredients) }
                                </CardContent>
                            </CardFloat> : null }

                            {/* Instructions */}
                            { this.state.food.instructions && this.state.tab === 'instructions' ? <CardFloat outline>
                                <CardContent>
                                    { this.renderRecipe(this.state.food.instructions) }
                                </CardContent>
                            </CardFloat> : null }

                            {/* Custom components */}
                            { this.state.food.is_meal && this.state.food.class === 'custom' ? <CardFloat>
                                <CardContent style={{ padding: '9px 16px 9px 16px' }}>
                                    { this.state.food.components.map((component) => {
                                        return <SearchListing food={component.target} metadata={component.metadata}/>
                                    })}
                                    <p style={{ opacity: '.7', textAlign: 'center', marginBottom: '0px', marginTop: '10px' }}> {this.state.food.components.length} items </p>
                                </CardContent>
                            </CardFloat> : null }

                            { /* Adding card for common and branded foods */ }
                            { this.state.add ? <CardFloat>
                                <CardContent style={{ padding: '16px' }}>
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}> 
                                            <Input 
                                                type="number"
                                                style={{ width: '100%', marginBottom: '5px' }}
                                                error={Number(this.state.servings) <= 0}
                                                value={this.state.servings} 
                                                placeholder="Servings"
                                                selector={<ChipSelector value={this.state.servingType} style={{ backgroundColor: 'inherit', border: 'none', boxShadow: '' }} handleChange={(value) => this.handleChange('servingType', value)} options={this.renderMenuItems(this.state.food).map((portion) => {
                                                    return {
                                                        value: portion,
                                                        label: portion,
                                                        variable: 'servingType'
                                                    }
                                                })}/>}
                                                onChange = {(event) => {
                                                this.handleChange("servings", event.target.value)}}/>
                                                <ChipSelector blankLabel={'Select Time'} value={this.state.meal} handleChange={(value) => this.handleChange('meal', value)} options={options} style={{ padding: '10px', borderRadius: '10px', height: '100%', marginBottom: '5px', marginLeft: '10px' }} />
                                        </div>
                                    </div>
                                </CardContent>
                            </CardFloat> : null }

                            { /* Modal buttons */ }
                            { /* Custom add/edit combination */ }
                            { this.state.food.class === 'custom' ? <div style={{ display: 'flex', position: 'sticky', bottom: '25px' }}>
                                <ModalButton style={{ width: '50%' }} icon={<EditIcon style={{ fontSize: '20px' }}/>} handleClick={(event) => {
                                    this.props.handleCustomUpdateOpen({ event: event, meal: this.state.food })
                                    this.handleClose()
                                }} label={`Edit`}/>
                                <ModalButton style={{ width: '50%', marginLeft: '10px' }} success={this.state.success} loading={this.props.dash.sessionLoading} disabled={!this.confirmSubmit()} icon={<AddCircleRoundedIcon/>} handleClick={(event) => this.state.add ? this.handleAdd() : this.props.handleAddOpen({ event: event, meal: this.state.food, serving: this.props.infoAmount })} label={`Add`}/>
                            </div> : null }

                            { /* General add */ }
                            { this.state.food.class !== 'custom' && !this.props.swap ? <ModalButton success={this.state.success} loading={this.props.dash.sessionLoading} disabled={!this.confirmSubmit()} icon={<AddCircleRoundedIcon/>} handleClick={this.handleClick} label={`Add ${this.renderTitle(this.state.food)}`}/>  : null }
                            
                            { /* Swap */ }
                            { this.props.swap ? <ModalButton success={this.state.success} loading={this.props.dash.mealPlanLoading} icon={<TbRepeat/>} handleClick={this.handleClick} label={`Swap`}/> : null }
                       </div> : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px 20px 20px 20px' }}>
                            <CircularProgress size={30}/>
                        </div>
                    }
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        createPreference,
        updatePreference,
        deletePreference,
        createSession,
        swapMealPlanItem
     }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NutritionInfoModal)