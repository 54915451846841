import { Color, toTitleCase } from '../util'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useEffect } from 'react'
import { useState } from 'react'
import { Image } from './' 
import { predictiveMealplan } from '../actions/dashboardActions'
import { CircularProgress, Fade } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddRounded'

const SearchListing = (props) => {
    const handleAddOpen = (e, options) => {
        e.stopPropagation()
        props.handleAddOpen(options)
    }

    const renderFood = (food) => {
        if (!food) return null
        switch (food.class) {
            case ('custom') :
            case ('recipe') : {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        { props.food.image_url ? <Image src={props.food.image_url} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', backgroundColor: 'grey', border: 'none', marginRight: '8px' }}/> : null }
                        <p style={{ margin: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}> { toTitleCase(props.food.name) } </p>       
                        { props.handleAddOpen ? <div style={{ marginLeft: 'auto', padding: '3px', backgroundColor: 'rgba(0,0,0,.1)', borderRadius: '50%' }} onClick={(e) => handleAddOpen(e, { event: e, meal: props.food, populate: true, tod: props.meal })}>
                            <AddIcon/>
                        </div> : null }
                    </div>
                )
            }

            case ('branded') :
            case ('common') : {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        { props.food.photo.thumb ? <Image src={props.food.photo.thumb} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', backgroundColor: 'grey', border: 'none', marginRight: '8px' }}/> : null }
                        <p style={{ margin: '0px', marginRight: '10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}> { toTitleCase(props.food.food_name) }  </p>       
                        { props.handleAddOpen ? <div style={{ marginLeft: 'auto', padding: '3px', backgroundColor: 'rgba(0,0,0,.1)', borderRadius: '50%' }} onClick={(e) => handleAddOpen(e, { event: e, meal: props.food, populate: true })}>
                            <AddIcon/>
                        </div> : null }
                    </div>
                )
            }
        }
    }

    return props.food ? <Fade in={true}>
            <div style={{ margin: '10px 0px 10px 0px', padding: '8px', backgroundColor: 'rgba(0,0,0,.1)', borderRadius: '10px', height: '46px', fontSize: '14px', ...props.style }} onClick={() => props.handleInfoOpen?.({ meal: props.food, populate: true })}>
            { renderFood(props.food) } 
        </div>
    </Fade> : null
}

const FoodWizard = (props) => {
    const meals = ['breakfast', 'lunch', 'dinner', 'snack']
    const cards = [
        'Based on your meal planner',
        'Something quick',
        'Quick & easy',
        'Restaurants and takeout',
        'Meal prep options',
        'Meals under 15 minutes',
        'Meals under 25 minutes',
        'Something to cook',
        'Some assembly required'
    ]

    const [tod, setTod] = useState(null)
    const [loaded, setLoaded] = useState(false)
    
    useEffect(() => {
        setTod(getTOD())
    }, [props.dash.sessions])

    useEffect(() => {
        if (!loaded && props.dash.predictiveLoaded || !tod) return
        props.predictiveMealplan(getMacros(), props.auth.user.settings, { 
            exclude: meals.filter((meal) => meal !== tod),
            limit: 2,
            multi: true, 
            multi_options: [{
                
            }, 
            {
                convenient: true
            },
            {
                filter : {
                    cooking_level: 1
                }
            }, 
            {
                restaurant: true
            }, 
            {
                meal_prep: true
            }, 
            {
                filter: {
                    cooking_time: {
                        type: '<=',
                        value: 15
                    }
                }
            }, 
            {
                filter: {
                    cooking_time: {
                        type: '<=',
                        value: 25
                    }
                }
            }, 
            {
                filter: {
                    is_recipe: true
                }
            }, 
            {
                filter: {
                    cooking_level: 2
                }
            }] 
        })
        setLoaded(true)
    }, [tod])

    const getMacros = () => {
        return props.auth.user.settings.macros
    }

    const getTOD = () => {
        const date = new Date()
        let sessions = props.dash.sessions.filter((session) => session.type === 'food' && ((new Date(session.time)).getDate()) === date.getDate())
        if (sessions.find((session) => session.metadata.meal === 'lunch') || (date.getHours() * 60 + date.getMinutes() > 960)) return 'dinner'
        else if (sessions.find((session) => session.metadata.meal === 'lunch') && (date.getHours() * 60 + date.getMinutes() > 810)) return 'snack'
        else if (sessions.find((session) => session.metadata.meal === 'breakfast') || (date.getHours() * 60 + date.getMinutes() > 630)) return 'lunch'
        else if (sessions.length === 0) return 'breakfast'
        else return 'snack'
    }

    const getHeader = (macros) => {
        switch(tod) {
            case ('breakfast'): return `Breakfast for ${Math.round(macros.calories)} cals & ${Math.round(macros.protein)}g of protein`
            case ('lunch'): return `Lunch for ${Math.round(macros.calories)} cals & ${Math.round(macros.protein)}g of protein`
            case ('dinner'): return `Dinner for ${Math.round(macros.calories)} cals & ${Math.round(macros.protein)}g of protein`
            case ('snack'): return `Snacks for ${Math.round(macros.calories)} cals & ${Math.round(macros.protein)}g of protein`
        }    
    }

    const getIcon = () => {
        switch(tod) {
            case ('breakfast'): return '🌅'
            case ('lunch'): return '☀️'
            case ('dinner'): return '🌃'
            case ('snack'): return '🍿'
        }
    }

    return (
        <>
            { props.active ? <div style={{ overflowX: 'scroll', display: 'flex', marginLeft: '-15px', marginRight: '-15px', marginTop: '-10px', padding: '10px', gap: '10px' }}>
                {
                    cards.map((card, i) => {
                        return (
                            !props.dash.predictiveLoaded || props.dash.predictive[i]?.[tod]?.data.length ?
                                <div style={{ marginLeft: '5px', height: '175px', width: '90%', flexShrink: '0', backgroundColor: 'rgba(0,0,0,0.05)', boxShadow: '0 0px 10px 3px ' + Color.PRIMARY + '50', backgroundImage: `linear-gradient(${!(i % 2) ? 'to bottom right' : 'to top right'}, ${!(i % 2) ? Color.PRIMARY + 'FF' : Color.FAT + 'FF'}, ${!(i % 2) ? Color.FAT + 'FF' : Color.PRIMARY + 'FF'}`, color: 'white', borderRadius: '20px', position: 'relative', padding: '14px', display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <h6 style={{ fontSize: '14px', margin: '0px' }}> {getHeader(props.dash.predictive[i]?.[tod]?.macros || { calories : 0, protein: 0 })} </h6>
                                        <span style={{ marginLeft: 'auto', backgroundColor: 'rgba(0,0,0,.1)', padding: '2px 6px', borderRadius: '50%' }}> {getIcon()} </span>
                                    </div>
                                    <p style={{ fontSize: '12px', opacity: '.7', marginBottom: '10px', marginTop: '-5px' }}> {card} </p>
                                    {
                                        props.dash.predictive[i]?.[tod]?.data.length ? <div style={{ display: 'flex', gap: '5px', flexDirection: 'column'}}>
                                            { props.dash.predictive[i][tod]?.data.map((food, i) => {
                                                return i < 2 ? <SearchListing handleAddOpen={props.handleAddOpen} handleInfoOpen={props.handleInfoOpen} food={food} style={{ margin: '0px' }}/> : null
                                            }) }
                                        </div> : <div style={{ height: '90px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <CircularProgress size={20} style={{ color: 'white' }}/>
                                        </div>
                                    }
                                </div> : null 
                        )
                    })
                }
            </div> : null }
        </>
    )
}

function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ predictiveMealplan }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FoodWizard)