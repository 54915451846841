//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { createSession } from '../actions/dashboardActions'
//Components and Pages
import { CardContent, Grow } from '@material-ui/core'
import { SkeletonCard, CardFloat, Image } from './' 
//Images
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';
import Button from './Button'

class FitnessCardGroup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            expanded: this.props.expanded ? this.props.expanded : true
        }
    }

    verifyCompletion = (array) => {
        let val = this.props.workouts.map(workout => { return this.verifyWorkout(workout.workout) })
        if (array) return val
        return !val.includes(false)
    }

    verifyWorkout = (workout) => {
        if (!workout) return false
        let sessions = this.props.dash.sessions.filter((session) => session.type === 'workout')
        for (let index in sessions) {
            let session = sessions[index], temp = new Date(session.time), curr = new Date(this.props.trackingTime ? new Date(this.props.trackingTime) : new Date())
            if (temp.getDate() === curr.getDate() && session.target_id === workout._id) return true
        }

        return false
    }

    getRemainder = () => {
        let ret = [], val = this.verifyCompletion(true)
        this.props.workouts.map((workout, i) => !val[i] ? ret.push(workout) : null)
        return ret
    }

    generateSession = (session) => {
        let sets = []
        for (let i = 0; i < session.sets; i++) {
            if (session.duration) sets.push({ duration: session.duration })
            else sets.push({ reps: session.reps })
        }

        return sets
    }

    handleCompleteAll = () => {
        this.props.createSession(this.getRemainder().map(workout => { return { target_id: workout.workout_id, time: this.props.trackingTime.getTime() ? this.props.trackingTime.getTime() : Date.now(), type: 'workout', target_class: 'workout', session_meta: { sets: this.generateSession(workout.session)}}}), this.props.workouts.map((workout) => workout.workout))
    }

    getWorkoutType = (workouts) => {
        let types = []

        for (let workout in workouts) {
            if (workouts[workout] && workouts[workout].workout) {
                for (let type in workouts[workout].workout.extremities) {
                    types.push(workouts[workout].workout.extremities[type])
                }
            }
        }

        if (types.includes('Lower') && types.includes('Upper')) return 'Full Body'
        if (types.includes('Lower')) return 'Lower'
        return 'Upper'
    }

    getFirstVideoThumbnail = (workouts) => {
        for (let i = 0; i < workouts.length; i++) {
            let workout = workouts[i]
            if (workout.workout && workout.workout.video_url) return workout.workout.video_url
        }

        return null
    }

    getVideoId = (video_url) => {
        if (!video_url) return null
        let breaks = video_url.split('/')
        if (breaks.length) return breaks[breaks.length - 1]
        return null
    }

    render() {
        let video_url = this.getFirstVideoThumbnail(this.props.workouts)

        return (
            <div>
                {this.props.workouts ? (
                    <Grow in={true}>
                        <CardFloat outline>
                            { video_url ? <Image src={`https://vumbnail.com/${this.getVideoId(video_url)}.jpg`} style={{ position: 'absolute', height: '100%', left: '0px', top: '0px', objectFit: 'cover', width: '70px' }}/> : null }
                            <CardContent style={{ marginLeft: video_url ? '70px' : '0px'}}>
                                <h6 style={{ marginBottom: '5px' }}> {this.getWorkoutType(this.props.workouts)} Workout </h6>
                                <h6 style={{ opacity: '.7', fontSize: '14px' }}> {this.props.workouts.length} Exercises </h6>
                                <Button onClick={(event) => this.props.handlePreviewOpen({ event, workouts: this.props.workouts})}>
                                    <ArrowForwardIcon style={{ fontSize: '18px' }}/>
                                </Button>
                            </CardContent>
                        </CardFloat>
                    </Grow>
                ) : <SkeletonCard/>}
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        createSession
     }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FitnessCardGroup))