import React from 'react';
import { Color } from '../util'
import { CircularProgress, Fade } from '@material-ui/core' 
import CheckIcon from '@material-ui/icons/CheckRounded';

function ModalButton(props) {
    const getBackgroundColor = () => {
        if (props.success) return Color.CALORIES_SUCCESS
        if (props.error) return Color.CALORIES_ERROR
        if (props.loading || props.disabled) return 'grey'
        return Color.PRIMARY
    }

    const renderLabel = () => {
        if (props.loading) return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={20} style={{ color: 'black' }}/>
            </div>
        ) 

        if (props.success) return (
            <Fade in={true}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CheckIcon style={{ color: 'white', fontSize: '20px' }}/>
                </div>
            </Fade>
        )

        if (props.error) return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h6 style={{ maxWidth: '80%', textAlign: 'center', color: 'white', marginBottom: '0px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}> {props.errorMessage} </h6>
            </div>
        )

        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: props.disabled ? 'black' : (props.fontColor || 'white') }}>
                { props.icon ? <div style={{ marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                    { props.icon }
                </div> : null }
                <h6 style={{ maxWidth: '200px', textAlign: 'center', margin: '0px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}> {props.label} </h6>
            </div>
        )
    }

    return (
        <div onClick={!props.loading && !props.disabled && !props.error && !props.success ? props.handleClick : null} style={{ position: 'sticky', display: 'flex', justifyContent: 'center', cursor: !props.loading && !props.disabled && !props.success && !props.error ? 'pointer' : '', transition: 'opacity .15s ease', width: '100%', maxWidth: '100%', height: '45px', backgroundColor: getBackgroundColor(), opacity: (props.disabled || props.loading) && !props.error && !props.success ? '.5' : '1', bottom: '25px', left: '0px', padding: '13px', borderRadius: '15px', boxShadow: '0px 3px 3px rgba(0,0,0,0.03)', ...props.style }}>
            { renderLabel() }
        </div>
    )
}

export default ModalButton;