import React, { Component } from 'react';
import { withStyles, LinearProgress } from '@material-ui/core'
import { Color } from '../util' 


const LinearProgressCarbs = withStyles(theme => ({
	root: {
	  height: 8,
      width: 100,
      borderRadius: 5
	},
    colorPrimary: {
        backgroundColor: 'transparent'
    },
	bar1Determinate: {
      backgroundColor: Color.CARBS,
	  borderRadius: 5,
	}
  }))(LinearProgress);


const LinearProgressProtein = withStyles(theme => ({
	root: {
	  height: 8,
      width: 100,
      borderRadius: 5
	},
    colorPrimary: {
        backgroundColor: 'transparent'
    },
	bar1Determinate: {
      backgroundColor: Color.PROTEIN,
	  borderRadius: 5,
	}
  }))(LinearProgress);


const LinearProgressFat = withStyles(theme => ({
	root: {
	  height: 8,
      width: 100,
      borderRadius: 5
	},
    colorPrimary: {
        backgroundColor: 'transparent'
    },
	bar1Determinate: {
      backgroundColor: Color.FAT,
	  borderRadius: 5,
	}
  }))(LinearProgress);


class NutritionBar extends Component {
    render() {
        let { carbs, protein, fat } = this.props
        
        return (
            <div {...this.props} style={{ ...this.props.style }}>
                <LinearProgressFat style={{ position: 'absolute', top: '0px', left: '0px', color: Color.FAT }} value={100} variant={'determinate'}/>
                <LinearProgressProtein style={{ position: 'absolute', top: '0px', left: '0px', color: Color.PROTEIN }} value={((carbs + protein) / (carbs + protein + fat)) * 100} variant={'determinate'}/>
                <LinearProgressCarbs style={{ position: 'absolute', top: '0px', left: '0px', color: Color.CARBS }} value={(carbs / (carbs + protein + fat)) * 100} variant={'determinate'}/>            
            </div>
        );
    }
}

export default NutritionBar;