//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import api from '../api'
//Components and Pages
import { TextField, CircularProgress, MenuItem } from '@material-ui/core';
import { Color } from '../util'
import { FileSelector, Modal, ArticlePreview } from './'

const initialState = (decorator) => {
    return {
        title: '',
        image: '',
        image_file: null,
        short_description: '',
        author: '',
        author_img_file: null,
        author_img: '',
        author_title: '',
        tags: '',
        type: 'article',
        loading: false
    }
}

const options = ['article', 'training', 'update']

class ArticleAddModal extends Component {
    constructor (props) {
        super(props)
        this.state = initialState()
    }

    handleChange = (question, answer) => {
        this.setState( { [question]: answer })
    }

    handleClose = (refresh) => {
        this.setState(initialState)
        this.props.handleClose(refresh)
    }

    confirmSubmit = () => {
        return true
    }

    handleImageUrl = (image) => {
        return image
    }
    
    handleCreate = async () => {
        let { title, img, img_file, short_description, content, author, author_title, author_img, author_img_file, type, tags } = this.state
        let req = this.getAuthRequest()
        this.setState({ loading: true })

        try {
            if (img_file) {
                let formdata = new FormData()
                formdata.append('file', img_file)
                let response = await api.uploadImage(formdata, req)
                img = response.data.data.Location
            }

            if (author_img_file) {  
                let formdata = new FormData()
                formdata.append('file', author_img_file)
                let response = await api.uploadImage(formdata, req)
                author_img = response.data.data.Location
            }
        } catch (error) {
            this.setState({ loading: false })
            this.handleClose(true)
        }

        api.createArticle({ title, img, short_description, content, author, author_title, author_img, type, public: true, published: true, tags: tags.toLowerCase().replace(' ', '').split(',') }, req).then((response) => {
            this.setState({ loading: false })
            this.handleClose(true)
        }).catch((error) => {
            this.setState({ loading: false })
        })
    }

    getAuthRequest = () => {
        const token = localStorage.getItem('accessToken')
        const req = {
            headers: {
                'Content-type': 'application/json'
            }
        }
    
        if (token) req.headers['x-access-token'] = token
        return req
    }

    handleFileChange = (event, variable, variable_name) => {
        let file = event.target.files[0]
        this.setState({ [variable] : file, [variable_name] : file.name })
    }

	render() {
        return (
            <Modal desktop in={this.props.open} handleClose={() => this.handleClose(false)} head={`Create Article`}>
                <div>
                    <div style={{ margin: '10px'}}>
                        <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Title </h6> 
                        <TextField 
                            style={{ marginBottom: '10px', width: '100%' }}
                            value={this.state.title} 
                            placeholder="Title"
                            onChange = {(event) => {
                            this.handleChange("title", event.target.value)}}/>
                    </div>
                    <div style={{ margin: '10px'}}>
                        <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Image </h6> 
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField 
                                style={{ marginBottom: '10px', width: '100%' }}
                                value={this.state.img} 
                                placeholder="Image"
                                onChange = {(event) => {
                                this.handleChange("img", event.target.value)}}/>
                            <FileSelector handleChange={(file) => this.handleFileChange(file, 'img_file', 'img')}> 
                                <p style={{ fontSize: '14px', color: Color.PRIMARY, marginBottom: '0px', marginLeft: '20px', cursor: 'pointer' }}> Upload </p>
                            </FileSelector>
                        </div>
                    </div>
                    <div style={{ margin: '10px'}}>
                        <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Short Description </h6> 
                        <TextField 
                            style={{ marginBottom: '10px', width: '100%' }}
                            value={this.state.short_description} 
                            placeholder="Description"
                            multiline
                            onChange = {(event) => {
                            this.handleChange("short_description", event.target.value)}}/>
                    </div>
                    <div style={{ margin: '10px'}}>
                        <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Content </h6> 
                        <ArticlePreview initial convertFromRaw={this.state.content} handleChange={(value) =>  this.handleChange('content', value)}/>
                    </div>
                    <div style={{ margin: '10px'}}>
                        <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Type </h6> 
                        <TextField 
                                select
                                style={{ marginBottom: '10px', width: '100%' }}
                                placeholder="Servings"
                                value={this.state.type}
                                onChange = {(event) => {
                                this.handleChange("type", event.target.value)}}>
                                    { options.map((portion, i) => {
                                        return <MenuItem key={i} value={portion}> {portion} </MenuItem>
                                    })}
                        </TextField>
                    </div>
                    <div style={{ margin: '10px'}}>
                        <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Author Name </h6> 
                        <TextField 
                            style={{ marginBottom: '10px', width: '100%' }}
                            value={this.state.author} 
                            placeholder="Name"
                            onChange = {(event) => {
                            this.handleChange("author", event.target.value)}}/>
                    </div>
                    <div style={{ margin: '10px'}}>
                        <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Author Title </h6> 
                        <TextField 
                            style={{ marginBottom: '10px', width: '100%' }}
                            value={this.state.author_title} 
                            placeholder="Title"
                            onChange = {(event) => {
                            this.handleChange("author_title", event.target.value)}}/>
                    </div>
                    <div style={{ margin: '10px'}}>
                        <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Author Image </h6> 
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField 
                                style={{ marginBottom: '10px', width: '100%' }}
                                value={this.state.author_img} 
                                placeholder="Image"
                                onChange = {(event) => {
                                this.handleChange("author_img", event.target.value)}}/>
                            <FileSelector handleChange={(file) => this.handleFileChange(file, 'author_img_file', 'author_img')}> 
                                <p style={{ fontSize: '14px', color: Color.PRIMARY, marginBottom: '0px', marginLeft: '20px', cursor: 'pointer' }}> Upload </p>
                            </FileSelector>
                        </div>
                    </div>
                    <div style={{ margin: '10px'}}>
                        <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Tags </h6> 
                        <TextField 
                            style={{ marginBottom: '10px', width: '100%' }}
                            value={this.state.tags} 
                            placeholder="Tags"
                            onChange = {(event) => {
                            this.handleChange("tags", event.target.value)}}/>
                    </div>
                    <div style={{ float: 'right', margin: '10px' }}>
                        <p style={{ color: Color.PRIMARY, cursor: 'pointer', display: 'inline-block' }} onClick={this.handleCreate}> { !this.state.loading ? 'Create' : <CircularProgress size={20}/> } </p>
                    </div>
                </div>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

export default connect(mapStateToProps)(ArticleAddModal)

