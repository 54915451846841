//Classes and Functions
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { refreshToken } from '../actions/authActions'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import api from '../api'
//Components and Pages
import { CardContent } from '@material-ui/core'
import { CardProviders, Image, Infographic, CardElementLoad, PaymentRequestLoad, CardFloat, ModalButton, Input, Chip } from '../components'

//Images
import StripeBadge from '../images/powered_by_stripe3.svg'
import instagramVerification from '../images/instagramVerification.svg'
import { getAuthRequest, Color } from '../util'

const PlanCard = (props) => {
	return (
		<CardFloat outline style={{ border: props.disabled ? `1px solid ${Color.PRIMARY}` : null, color: props.disabled ? Color.PRIMARY : null, transition: 'border .3s ease', padding: '16px', borderRadius: '15px', cursor: 'pointer' }} onClick={props.onClick}>
			{props.children}
		</CardFloat>
	)
}

class Checkout extends Component {
    constructor (props) {
        super(props)
        this.state = {
            plan: 'monthly',
            status: 'idle',
            redirect: false,
            checkoutInitiated: false,
            subscription: null,
            promocode: null,
            tab : {
                first_name: {
                    answer: this.props.auth.user ? this.props.auth.user.first_name : '',
                    type: "none",
                    err: false
                },
                last_name: {
                    answer: this.props.auth.user ? this.props.auth.user.last_name : '',
                    type: "none",
                    err: false
                },
                email: {
                    answer: this.props.auth.user ? this.props.auth.user.email : '',
                    type: "email",
                    err: false
                }
            },
            plans : {
                'monthly' : {
                    id: process.env.NODE_ENV === 'production' ? 'price_1HGpGkFE3sVcpjXe48fUsftI' : 'price_1HGpR6FE3sVcpjXeX9qAN53D',
                    unit_amount: 2499,
                    duration: 1
                },
                'semi-annual' : {
                    id: process.env.NODE_ENV === 'production' ? 'price_1HGpGkFE3sVcpjXe48fUsftI' : 'price_1HGpR6FE3sVcpjXeX9qAN53D',
                    unit_amount: 24999,
                    duration: 6
                },
                'annual' : {
                    id: process.env.NODE_ENV === 'production' ? 'price_1HGpGkFE3sVcpjXe48fUsftI' : 'price_1HGpR6FE3sVcpjXeX9qAN53D',
                    unit_amount: 24999,
                    duration: 12
                }
            }
        }
    }

    componentDidMount() {
        if (this.props.code) {
            let req = getAuthRequest()
            req.params = { code: this.props.code } 
            this.setState({ status: 'loading '})
            api.stripeGetPromoCode(req).then((response) => {
                console.log(response.data)
                this.setState({ promocode: response.data, status: 'idle' })
            }).catch((error) => {
                this.setState({ status: 'idle' })
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.subscription !== this.props.subscription)  this.setState({ subscription: this.props.subscription })

        if ((this.props.stripe !== null && !this.state.prLoading && !this.state.prLoaded) || this.state.promocode !== prevState.promocode || this.state.plan !== prevState.plan) {
            this.setState({ prLoading: true })
            let pr = this.createPaymentRequest(this.state.plans[this.state.plan])
            pr.canMakePayment().then((result) => {
                this.setState({ prLoaded: true, prLoading: false })
                if (result) {
                    this.setState({ pr })
                    pr.on('paymentmethod', async (event) => {
                        let paymentMethod = event.paymentMethod
                        this.setState({ status: 'loading' })
                        if (this.state.subscription) {
                            this.retryInvoice(this.props.auth.user.subscription.customer_id, paymentMethod.id, this.state.subscription.latest_invoice.id).then((response) => {
                                this.setState({ subscription: { ...this.state.subscription, latest_invoice: response.data }})
                                return this.handlePostPurchase(this.state.subscription, paymentMethod.id)
                            }).catch((error) => {
                                this.setState({ status: "error", errorMessage: "Card Error", authorizing: false })
                                this.error = setInterval(() => this.errorTick(), 2000)
                                event.complete('success')
                            })
                        } else {
                            this.createSubscription(this.props.auth.user.subscription.customer_id, paymentMethod.id, this.state.plans[this.state.plan].id, this.state.promocode ? this.state.promocode.id : null).then((response) => {
                                this.setState({ subscription: response.data })
                                return this.handlePostPurchase(response.data, paymentMethod.id, event)
                            }).catch((error) => {
                                this.setState({ status: "error", errorMessage: "Card Error", authorizing: false })
                                this.error = setInterval(() => this.errorTick(), 2000)
                                event.complete('success')
                            })
                        }              
                    })
                }
            })
        }

        if (prevProps.open === false && this.props.open === true) {
            if (this.props.ReactPixel && !this.state.checkoutInitiated) this.props.ReactPixel.track('InitiateCheckout')
            if (this.props.SnapchatPixel && !this.state.checkoutInitiated) this.props.SnapchatPixel.snaptr('track', 'START_CHECKOUT')
            if (this.props.subscription && this.props.subscription.latest_invoice.payment_intent.status === 'requires_action') {
                this.handleActionRequired(this.props.subscription, this.props.subscription.customer.invoice_settings.default_payment_method)
            }

            this.setState({ checkoutInitiated: true })
        }
    }

    //Create Payment Request Helper Function
    createPaymentRequest = (plan) => {
        return this.props.stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            requestPayerName: true,
            requestPayerEmail: true,
            total: {
                label: `Sustainably ${this.state.plan.toUpperCase()}`,
                amount: this.getPrice(plan, this.state.promocode) * 100,
            }
        })
    }

    //Verify All Entries Before Submission
    verifyEntries = (questions) => {
		let valid = 0, count = 0
		for (let property in questions) {
			if (this.verifyQuestion(questions[property])) valid += 1
			count += 1
		} 

		return Math.round(valid / count * 100)
    }

    //Handle input changes
    handleChange = (question, answer) => {
		this.setState({ tab : {...this.state.tab, [question] : { ...this.state.tab[question], answer} } })
	}

    //Verify Inputs of Given Type
    verifyQuestion = (question) => {
		switch (question.type) {
			case ("email") : {
				if (question.answer.includes('@')) return true
				break
			}

			default : {
				if (question.answer !== "") return true
			}
		}
		return false
    }
    
    handleError = (question, error) => {
		this.setState({ tab : {...this.state.tab, [question] : { ...this.state.tab[question], err: error} } })
    }


    //Create subscription helper function
    createSubscription = async (customer_id, payment_method_id, plan_id, promotion_code_id) => {
        let subscription = { customer_id, payment_method_id, plan_id }
        if (promotion_code_id) subscription.promotion_code_id = promotion_code_id
        return api.stripeCreateSubscription(subscription)
    }

    //Retry invoice helper function
    retryInvoice = async (customer_id, payment_method_id, invoice_id) => {
        return api.stripeRetryInvoice({ customer_id, payment_method_id, invoice_id })
    }

    handlePurchase = async () => {
        let paymentMethodId = null

        this.setState({ status: "loading" })
        this.props.stripe.createPaymentMethod({ 
            type: 'card', 
            card: this.props.elements.getElement('card'),             
            billing_details : {
                name: `${this.state.tab.first_name.answer} ${this.state.tab.last_name.answer}`,
                email: this.state.tab.email.answer
            }
        }).then((response) => {
            paymentMethodId = response.paymentMethod.id
            if (this.state.subscription) return this.retryInvoice(this.props.auth.user.subscription.customer_id, response.paymentMethod.id, this.state.subscription.latest_invoice.id)
            return this.createSubscription(this.props.auth.user.subscription.customer_id, response.paymentMethod.id, this.state.plans[this.state.plan].id, this.state.promocode ? this.state.promocode.id : null)
        }).then((response) => {
            if (this.state.subscription) {
                this.setState({ subscription: { ...this.state.subscription, latest_invoice: response.data }})
                this.handlePostPurchase(this.state.subscription, paymentMethodId)
            } else {
                this.setState({ subscription: response.data })
                this.handlePostPurchase(response.data, paymentMethodId)
            }
        }).catch((error) => {
            this.setState({ status: "error", errorMessage: "Card Error"})
            this.error = setInterval(() => this.errorTick(), 1000)
        })
    }

    errorTick = () => {
        clearInterval(this.error)
        this.setState({ status: "idle" })
    }

    successTick = () => {
        clearInterval(this.success)
        window.gtag('event', 'conversion', { send_to: 'AW-356819193/CtZhCLyaq8oCEPnBkqoB', transaction_id: '' })
        if (this.props.ReactPixel) this.props.ReactPixel.track('Purchase', { value: this.props.plan.unit_amount / 100, currency: 'USD' })
        if (this.props.SnapchatPixel) this.props.SnapchatPixel.snaptr('track', 'PURCHASE', { currency: 'USD', price: this.props.plan.unit_amount / 100, transaction_id: 11111111 })
        this.props.refreshToken(!this.props.redirect, this.props.redirect)
    }

    handlePostPurchase = async (subscription, paymentMethodId, event) => {
        if (subscription.status === 'active') {
            if (event) event.complete('success')
            this.setState({ status: "success" })
            this.success = setInterval(this.successTick, 1000)
        } else if (subscription.status === 'incomplete') {
            this.handleActionRequired(subscription, paymentMethodId, event)
        }
    }

    handleActionRequired = async (subscription, paymentMethodId, event) => {
        let paymentIntent = subscription.latest_invoice.payment_intent
        if (paymentIntent.status === 'requires_action' || (this.state.subscription && paymentIntent.status === 'requires_payment_method')) {
            this.setState({ status: "authorizing" })
            return this.props.stripe.confirmCardPayment(paymentIntent.client_secret, {
                payment_method : paymentMethodId
            }).then((response) => {
                if (response.error) {
                    this.setState({ status: "error", errorMessage: "Authorization Error", authorizing: false })
                    this.error = setInterval(() => this.errorTick(), 2000)
                } else {
                    if (event) event.complete('success')
                    this.setState({ status: "success" })
                    this.success = setInterval(() => this.successTick(), 1000)
                }
            }).catch((error) => {
                this.setState({ status: "error", errorMessage: "Card Error", authorizing: false })
                this.error = setInterval(() => this.errorTick(), 2000)
            })
        } else {
            this.setState({ status: 'idle' })
        }
    }

    getMonthFromWeeks = (weeks) => {
		let date = new Date()
		for (let i = 0; i < weeks; i++) {
			date.setDate(date.getDate() + 7)
		}

		return date
    }

    getPrice = (plan, promocode) => {
        if (!plan) return 0
        let price = plan.unit_amount, coupon = promocode ? promocode.coupon : null
        if (coupon && coupon.valid) {
            let type = coupon.percent_off ? 'percent' : 'amount', temp = price / plan.duration
            if (type === 'percent') price = price - temp * (coupon.percent_off / 100) * (coupon.duration_in_months && coupon.duration_in_months > plan.duration ? plan.duration : coupon.duration_in_months)
            else price = price - coupon.amount_off
        }

        return Math.ceil(price) / 100
    }

    render() {
        let date = new Date()
        return (
            <div>
                {this.state.redirect ? <Redirect to='/dashboard'/> : null}

                { this.props.projection && this.props.projection.progressPlan.length > 1 ?
                    <Infographic type='custom' title={'Your Weight Projection'} data={{
                        points: this.props.projection.progressPlan,
                        previousDate: date,
                        recentDate: this.getMonthFromWeeks(this.props.projection.progressPlan.length),
                        unit: this.props.projection.unit
                    }}/> : null }

                { this.props.projection ? 						
                    <CardFloat>
						<CardContent>
                            <h6> You are just one step away from reaching {this.props.projection.target_weight}{this.props.projection.unit}s for good!  </h6>
							<p style={{ opacity: '.7', fontSize:'14px'}}> Complete the form below and join the countless people that have permanently changed their bodies and lives in record time with STNBLY. </p>
                        </CardContent>
                    </CardFloat> : null
                }
                <CardFloat>
                    <CardContent>
                        <h6> Choose Your Plan </h6>
                        <PlanCard disabled={this.state.plan === 'monthly'} onClick={() => this.setState({ plan: 'monthly' })}>
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <h6> Monthly </h6>
                                <h6 style={{ fontSize: '14px', marginLeft: 'auto' }}> ${this.getPrice(this.state.plans.monthly, this.state.promocode)} </h6>
                            </div>
                            <p style={{ marginBottom: '0px', fontSize: '12px' }}> $24.99 <span style={{ opacity: '.7', fontSize: '12px' }}>/month </span> </p>
                        </PlanCard>
                        { /* <PlanCard disabled={this.state.plan === 'semi-annual'} onClick={() => this.setState({ plan: 'semi-annual' })}>
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <h6> Semi-Annual </h6>
                                <h6 style={{ fontSize: '14px', marginLeft: 'auto' }}> ${this.getPrice(this.state.plans['semi-annual'], this.state.promocode)} </h6>
                            </div>
                            <p style={{ marginBottom: '0px', fontSize: '12px' }}> ${this.getPrice(this.state.plans['semi-annual'], this.state.promocode) / this.state.plans['semi-annual'].duration} <span style={{ opacity: '.7', fontSize: '12px' }}>/month </span> </p>
                        </PlanCard>
                        <PlanCard disabled={this.state.plan === 'annual'} onClick={() => this.setState({ plan: 'annual' })}>
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <h6> Annual </h6>
                                <h6 style={{ fontSize: '14px', marginLeft: 'auto' }}> ${this.getPrice(this.state.plans.annual, this.state.promocode)} </h6>
                            </div>
                            <p style={{ marginBottom: '0px', fontSize: '12px' }}> ${this.getPrice(this.state.plans['annual'], this.state.promocode) / this.state.plans['annual'].duration} <span style={{ opacity: '.7', fontSize: '12px' }}>/month </span> </p>
                        </PlanCard> */ }
                        { this.state.promocode && this.state.promocode.active ? <p style={{ opacity: '.7', marginBottom: '0px', position: 'absolute', bottom: '15px', textAlign: 'center', left: '50%', transform: 'translateX(-50%)', fontSize: '12px' }}> Code <span style={{ color: Color.PRIMARY }}> {this.state.promocode.code.toUpperCase()} </span> applied </p> : null }
                    </CardContent>
                </CardFloat>


                
                { this.props.open && this.state.pr ? <div>
                    <PaymentRequestLoad options={{ paymentRequest: this.state.pr }}/>
                </div> : null }

                { this.state.pr  ? <div style={{ height: '10px', borderBottom: '1px solid rgba(0,0,0,.1)', marginTop: '30px', marginBottom: '30px', position: 'relative' }}>
                    <p style={{ fontSize: '14px', position: 'absolute', bottom: '-25px', backgroundColor: 'white', left: '50%', transform: 'translateX(-50%)', padding: '0px 20px 0px 20px', color: 'rgba(0,0,0,.5)' }}> Or pay with card </p>
                </div> : null }

                <CardFloat>
                    <CardContent style={{ paddingBottom: '0'}}>
                        <div style={{ marginBottom: '20px'}}>
                            <div style={{ margin: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{ display: 'inline-block', margin: '5px 5px 0px 5px', width: '100%' }}> 
                                    <Input style={{ marginBottom: '10px', width: '100%'}} error={this.state.tab.first_name.err} onBlur={() => this.handleError("first_name", !this.verifyQuestion(this.state.tab.first_name))} value={this.state.tab.first_name.answer} placeholder="First Name" onChange = {(event) => {this.handleChange("first_name", event.target.value)}}/>
                                </div>
                                <div style={{ display: 'inline-block', margin: '5px 5px 0px 5px', width: '100%' }}> 
                                    <Input style={{ marginBottom: '10px', width: '100%'}} error={this.state.tab.last_name.err} onBlur={() => this.handleError("last_name", !this.verifyQuestion(this.state.tab.last_name))} value={this.state.tab.last_name.answer} placeholder="Last Name" onChange = {(event) => {this.handleChange("last_name", event.target.value)}}/>
                                </div>
                            </div>
                            <div style={{ margin: '0px 10px 10px 10px' }}>
                                <Input style={{ marginBottom: '15px', width: '100%'}} error={this.state.tab.email.err} onBlur={() => this.handleError("email", !this.verifyQuestion(this.state.tab.email))} value={this.state.tab.email.answer} placeholder="Email" onChange = {(event) => {this.handleChange("email", event.target.value)}}/>
                            </div>
                            <div style={{ margin: '10px'}}>
                                { this.props.open ? <CardElementLoad/> : null }
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CardProviders/>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                                    <Image alt="Stripe Authentication" style={{ width: '100px', opacity: '.8', margin: '10px' }} src={StripeBadge}/>
                                </div>
                            </div>

                            <ModalButton handleClick={this.handlePurchase} label={`Pay $${this.getPrice(this.state.plans[this.state.plan], this.state.promocode)}`} loading={this.state.status === 'loading' || this.state.status === 'authorizing'} error={this.state.status === 'error'} errorMessage={this.state.errorMessage} success={this.state.status === 'success'} style={{ position: 'relative', bottom: '0px', marginTop: '10px' }}/>
                        </div>
                    </CardContent>
                </CardFloat>
                
                <div style={{ margin: '10px', marginTop: '30px' }}>
                    <div style={{ padding: '0px 0px 15px 0px', borderBottom: '1px solid rgba(0, 0, 0, 0.05'}}>
                        <h6> Cancel Anytime </h6>
                        <h6 style={{ fontSize: '12px', opacity: '.6'}}> Settings {'>'} Payment {'>'} Cancel </h6>
                        <h6 style={{ fontSize: '13px', opacity: '.7'}}> Cancel your membership no questions asked. </h6>
                    </div>
                    <div style={{ padding: '15px 0px 15px 0px', borderBottom: '1px solid rgba(0, 0, 0, 0.05', position: 'relative' }}>
                        <h6 style={{ textAlign: 'right'}}> 100% Risk-Free Guarantee </h6>
                        <h6 style={{ fontSize: '13px', opacity: '.7', textAlign: 'right'}}> You'll love it or it's on us </h6>
                        <img alt='Risk-Free Guarantee' src={instagramVerification} style={{ opacity: '.7', height: '35px', position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '27px'}}/>
                    </div>
                    <div style={{ padding: '15px 0px 15px 0px', borderBottom: '1px solid rgba(0, 0, 0, 0.05', position: 'relative' }}>
                        <h6> Secured </h6>
                        <h6 style={{ fontSize: '13px', opacity: '.7'}}> This site is protected by <a href={'https://policies.google.com/privacy?hl=en-US'}> Google Privacy Policy </a> Terms of Service Apply </h6>
                    </div>
                </div>

                <h6 style={{ opacity: '.5', fontSize: '12px', textAlign: 'center', marginTop: '15px'}} > STNBLY - 30 N Gould Street, Sheridan, WY 82801 </h6>
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        refreshToken }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
