//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getSessions } from '../actions/dashboardActions'
//Components and Pages
import { Card, CardContent, Grow } from '@material-ui/core';
import { withRouter } from 'react-router-dom'
import { CardGroup, SkeletonCard, CardFloat, PageCard, StickyHeader } from '../components'
import { Color, timeToLocal, localStart, localEnd } from '../util'
//Images
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

const nutrients = {
    fat: 1004,
    carbs: 1005,
    protein: 1003
}

class Coaching extends Component {
    constructor (props) {
        super(props)
		this.state = {
            time: new Date()
		}
    }

    componentDidMount() {
        let yesterday = new Date()
        yesterday.setDate(this.state.time.getDate() - 1)
        let start = localStart(yesterday), end = localEnd(yesterday)
        if (!this.props.cards && this.props.auth.user && !this.props.dash.sessionLoading && this.verifyBuffer(start, end)) this.props.getSessions({ time: { $gte: start, $lt: end }, user_id: this.props.auth.user._id })
    }

    componentDidUpdate() {
        let yesterday = new Date()
        yesterday.setDate(this.state.time.getDate() - 1)
        let start = localStart(yesterday), end = localEnd(yesterday)
        if (!this.props.cards && this.props.auth.user && !this.props.dash.sessionLoading && this.verifyBuffer(start, end)) this.props.getSessions({ time: { $gte: start, $lt: end }, user_id: this.props.auth.user._id })
    }

    verifyBuffer = (start, end) => {
        return start > this.props.dash.sessionBuffer.max || start < this.props.dash.sessionBuffer.min || end < this.props.dash.sessionBuffer.min || end > this.props.dash.sessionBuffer.max
    }

    findPlan = (time) => {
        for (let i = 0; i < this.props.dash.plan.length; i++) {
            if (this.props.dash.plan[i].date === time) {
                return this.props.dash.plan[i]
            }
        }

        return false
    }


    renderCard = (header, body, type, index) => {
        let img = null
        switch (type) {
            case ('warning') : {
                img = <WarningIcon style={{ fontSize: '20px', color: Color.PROTEIN_ERROR}}/>
                break
            }

            case ('error') : {
                img = <ErrorIcon style={{ fontSize: '20px', color: Color.CALORIES_ERROR}}/>
                break
            }

            default : {
                img = <CheckCircleIcon style={{ fontSize: '20px', color: Color.CALORIES_SUCCESS}}/>
            }
        }


        return (
            <Grow key={index ? index : 0} in={true}>
                <CardFloat>
                    <CardContent>
                        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                            { img }
                        </div>
                        <h6> { header } </h6>
                        <p style={{ opacity: '.7'}}> { body } </p>
                    </CardContent>
                </CardFloat>
            </Grow>
        )
    }


    getMetaData = (activeStat, percent, additive, hours) => {
        let calories = 0, sessions = this.props.dash.sessions.filter((session) => session.type === 'food')

        if (additive && additive.foodClass && additive.foodClass !== 'Custom') {
            additive = this.getNutrient(activeStat, additive)
        } else if (additive) {
            additive = additive[activeStat]
        }

        let additiveAdjusted = additive

        if (this.props.servings && additive) {
            additiveAdjusted = Math.round(additiveAdjusted * this.props.servings)
            calories += additiveAdjusted
        }

        for (let index in sessions) {
            let session = sessions[index], date = new Date(session.time), food = session.target
            if (food && date.getDate() === this.state.time.getDate() && (hours ? date.getHours() < hours : true)) {
                if (food.foodClass && food.foodClass !== 'Custom') {
                    if (food.foodClass === 'FinalFood') {
                        calories += Math.round(this.getNutrient(activeStat, food, session.metadata.serving_type) * session.metadata.servings)
                    } else {
                        calories += Math.round(this.getNutrient(activeStat, food) * session.metadata.servings)
                    }
                } else {
                    calories += Math.round(food[activeStat] * session.metadata.servings)
                }
            }
        }

        if (percent) return calories / (this.props.auth.user.settings.macros[activeStat] || this.props.sampleMacros[activeStat]) * 100
        return calories
    }

    searchFoodPortions = (type, food) => {
        if (type === 'g') return 1
        if (type && food.foodPortions && food.foodPortions !== null) {
            let portion = food.foodPortions.find((element) => element.modifier === type)
            if (portion) return portion.gramWeight / portion.amount
        } else {
            return 100
        }
    }

    getNutrient = (name, food, servingType) => {
        if (name === 'calories') {
            return ((4 * this.getNutrient('protein', food, servingType)) + (4 * this.getNutrient('carbs', food, servingType)) + (this.getNutrient('fat', food, servingType) * 9))
        } else {
            let nutrient = food.foodNutrients.find((element) => element.nutrient.id === nutrients[name])
            if (nutrient) {
                if (food.servingSize && food.servingSize !== null) {
                    return (food.servingSize / 100) * nutrient.amount
                } else {
                    if (food.foodClass === 'FinalFood') {
                        if (servingType) {
                            return (this.searchFoodPortions(servingType, food) / 100) * nutrient.amount
                        }
                        return (this.searchFoodPortions(this.props.servingType, food) / 100) * nutrient.amount
                    }
                    return nutrient.amount                
                }
            } 
            return 'NA'
        }
    }

    isNutritionMet = () => {
        let caloriesPercentage = this.getMetaData('calories', true), proteinPercentage = this.getMetaData('protein', true), carbsPercentage = this.getMetaData('carbs', true), fatPercentage = this.getMetaData('fat', true)
        if (caloriesPercentage >= 95 && caloriesPercentage <= 105 &&
            proteinPercentage >= 95 && proteinPercentage <= 105 &&
            carbsPercentage >= 95 && carbsPercentage <= 105 &&
            fatPercentage >= 95 && fatPercentage <= 105) return true
        return false
    }

    isFitnessMet = (time) => {
        let sessions = this.props.dash.sessions.filter((session) => session.type === 'workout'), plan = this.findPlan(timeToLocal(time))
        let curr = new Date(time)

        if (plan && plan.length) {
            let ret = plan.fitness.map((workout) => {
                return sessions.filter((session) => { 
                    let temp = new Date(session.time)
                    return session.target_id === workout.workout_id && temp.getDate() === curr.getDate()
                }).length ? true : false
            })

            return !ret.includes(false)
        }

        return false
    }

    renderToday = () => {
        let calories = this.getMetaData('calories', false)
        let caloriesPercentage = this.getMetaData('calories', true)

        let protein = this.getMetaData('protein', false)
        let proteinPercentage = this.getMetaData('protein', true)
        
        let cards = []

        //Calorie Adjustment Notifications
        if (this.getMetaData('calories', true, undefined, 18) > 75) {
            cards.push({ 
                head: 'High Consumption', 
                description: 'You consumed 75% of your calories for breakfast and lunch, which doesn’t leave much for dinner. Have a lighter dinner and consider spreading out your calories tomorrow!',
                type: 'warning'
            })
        } else if (calories < 300 && this.state.time.getHours() >= 18) {
            cards.push({
                head: 'Low Calories',
                description: `Did you log everything today? We’re only seeing ${calories} late in the day. Please finish tracking to help us help you!`,
                type: 'warning'
            })
        } else if (calories - (this.props.auth.user ? this.props.auth.user.settings.macros.calories : calories) > 100) {
            cards.push({
                head: 'Calories Exceeded',
                description: `You are over your calorie goal. Make a mental note of how this happened today and try to aim for ${this.props.auth.user ? this.props.auth.user.settings.macros.calories : 0} calories tomorrow.`,
                type: 'error'
            })
        }

        //Macro Imbalances
        if (caloriesPercentage >= 80 && proteinPercentage <= 60) {
            cards.push({
                head: 'Low Protein Intake',
                description: 'You are at 80% of your calories and have met less than 60% of your protein goal. Consider drinking a protein shake to boost protein with minimal calories to finish strong!',
                type: 'error'
            })
        } else if ((protein * 4) / calories <= .2) {
            cards.push({
                head: 'Low Protein Intake',
                description: 'Your protein intake is too low compared to your carbs and fats. Look at the recommended meals for suggestions on how to hit your goals. Consider a protein shake to make today a success!',
                type: 'error'
            })
        }

        /*let plan = this.findPlan(timeToLocal(this.state.time))
        if (plan && plan.fitness.length) {

            //General Notifications
            cards.push({
                head: 'Workout Scheduled for Today',
                description: 'Don\'t forget to finish and track your workout today!',
                type: 'success'
            })

            /*cards.push(
                this.renderCard('Recalibration Tomorrow', 'Tomorrow is recalibration day! Make sure to get a dry weight first thing in the morning.', 'warning')
            )

            //Workout Cards
            cards.push(
                this.renderCard('Incomplete Workout', 'Make sure to complete all the exercises in the workouts! Exercising boosts metabolism and builds lean muscle mass!', 'error')
            )

            cards.push(
                this.renderCard('Skipped Sets and Reps', 'Tracking your sets and reps will show you your progress over time. Try to increase sets, reps, or weight every session- This is known as progressive overload.', 'warning')
            )
        } else if (plan) {
            cards.push
            cards.push(
                this.renderCard('No Workout Scheduled', 'Don\'t forget to finish and track your workout today!', 'success', cards.length)
            )
        }*/

        if (this.isFitnessMet(this.state.time) && this.isNutritionMet()) {
            cards.push({
                head: 'Day Completed',
                description: 'You’re doing great! <GOAL WEIGHT> is on the horizon! Keep it up!',
                type: 'success'
            })
        } else if (this.isFitnessMet(this.state.time)) {
            cards.push({
                head: 'Workout Completed',
                description: 'Nice job on your workout 💪',
                type: 'success'
            })
        } else if (this.isNutritionMet()) {
            cards.push({
                head: 'Macros Met',
                description: 'Excellent job on your nutrition goals. Keep it up!',
                type: 'success'
            })
        }

        /*cards.push({
            head: 'Macros Met',
            description: 'Excellent job on your nutrition goals. Keep it up!',
            type: 'success'
        })


        /*if (!cards.length) return (
            <Card style={{ position: 'relative', borderRadius: '25px', marginBottom: '15px', marginTop: '20px', backgroundColor: 'grey', opacity: '.5' }}>
                <CardContent>
                    <h6 style={{ textAlign: 'center' , marginTop: '20px'}}> No Coaching for Today </h6>
                </CardContent>
            </Card>
        )*/

        /*if (this.props.cards) {
            cards.push(
                <Grow in={true} key={cards.length}>
                    <CardFloat style={{ paddingTop: '10px' }} onClick={() => this.setState({ tab: 1 })}>
                        <CardContent style={{ width: '100%', marginLeft: '5px'}}>
                            <h6 style={{ marginBottom: '2px'}}> View More </h6>
                            <p style={{ opacity: '.7', marginBottom: '3px'}}> Review Yesterday's Overview </p>
                            <ArrowForwardIosIcon fontSize='small' style={{ margin: '0px 5px 0px 5px', opacity: '.5', position: 'absolute', top:'50%', transform:'translateY(-50%)', right: '20px' }}/>
                        </CardContent>
                    </CardFloat>
                </Grow>
            )
        }*/

        return (
            <div>
                <CardFloat style={{ marginBottom: '10px', marginTop: !this.props.cards ? '0px' : null }}>
                    <CardContent style={{ height: this.props.cards ? '175px' : null, minHeight: '175px' }}>
                        <div style={{ display: 'flex' }}>
                            <h6 style={{ fontSize: '14px' }}> Today's Feedback </h6>
                            { this.props.cards ? <h6 style={{ fontSize: '14px', marginLeft: 'auto', color: Color.PRIMARY }}> See All </h6> : null }
                        </div>
                        <div style={{ margin: '7px 2px 2px 2px' }}>
                            { cards.length ? cards.map((card, i) => {
                                return (
                                    <div key={i}>
                                        <div style={{ display: 'flex', alignItems: 'center'}}>
                                            <div>
                                                { card.type === 'success' ? <CheckCircleIcon style={{ fontSize: '20px', marginRight: '15px', color: Color.CALORIES_SUCCESS}}/> : null }
                                                { card.type === 'warning' ? <WarningIcon style={{ fontSize: '20px', marginRight: '15px', color: Color.PROTEIN_ERROR}}/> : null }
                                                { card.type === 'error' ? <ErrorIcon style={{ fontSize: '20px', marginRight: '15px', color: Color.CALORIES_ERROR}}/> : null }
                                            </div>
                                            <div style={{ position: 'relative', width: 'calc(100% - 40px)' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px'}}>
                                                    <h6 style={{ fontSize: '14px', marginBottom: '0px'}}> { card.head } </h6>
                                                </div>
                                                <p style={{ fontSize: '12px', opacity: '.7', marginBottom: '0px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}> { card.description } </p>
                                            </div>
                                        </div>
                                        { i < cards.length - 1 ? <div style={{ height: '1px', borderTop: '1px solid', margin: '15px 20px 15px 20px', opacity: '.1' }}/> : null }
                                    </div>
                                )
                            }) : <h6 style={{ opacity: '.7', textAlign: 'center', marginBottom: '0px', margin: '50px 10px 10px 10px', fontSize: '14px' }}> You're all caught up! </h6> }
                        </div>
                    </CardContent>
                </CardFloat>
            </div>
        )
    }

    renderYesterday = () => {
        let yesterday = new Date()
        yesterday.setDate( this.state.time.getDate() - 1 )
        let plan = this.findPlan(timeToLocal(yesterday.getTime())), cards = []
        
        if (plan && plan.fitness.length) {
            if (this.isFitnessMet(yesterday.getTime())) {
                cards.push(
                    this.renderCard('Workout Completed', 'Fitness boosts metabolism, mood, and lean muscle mass. Nice job exercising yesterday.', 'success')
                )
            } else {
                cards.push(
                    this.renderCard('Missed Workout', 'You missed a workout yesterday. Try placing it on your calendar to dedicate time to do it. As long as all the workouts happen within the week you will make great progress!', 'error')
                )
            }
        } else if (plan) {
            cards.push(
                this.renderCard('No Workout Scheduled', 'Don\'t forget to finish and track your workout today!', 'success', cards.length)
            )
        }

        if (!cards.length) return (
            <Card style={{ position: 'relative', borderRadius: '25px', marginBottom: '15px', marginTop: '20px', backgroundColor: 'grey', opacity: '.5'}}>
                <CardContent>
                    <h6 style={{ textAlign: 'center' , marginTop: '20px'}}> No Coaching Yesterday </h6>
                </CardContent>
            </Card>
        )

        return cards
    }

    render() {
        if (this.props.cards) {
            return (
                <CardGroup paginate>
                    { this.props.auth.user && !this.props.dash.dayLoading && !this.props.dash.planLoading ? this.renderToday() : <SkeletonCard/> }
                </CardGroup>
            )
        }

        return (
            <div>
                <StickyHeader>
                    <h5 style={{ marginBottom: '0px' }}>
                        Coaching
                    </h5>
                </StickyHeader>
                <PageCard title={'Coaching'} style={{ paddingTop: '0px' }}>
                    { this.props.auth.user ? this.renderToday() : <SkeletonCard/> }

                    {/*<h5> Yesterday </h5>
                    <h6 style={{ opacity: '.7' }}> November 28, 2020 </h6>*/}

                    {/* this.props.auth.user ? this.renderYesterday() : <SkeletonCard/> */ }
                </PageCard>
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ 
        getSessions
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Coaching))
