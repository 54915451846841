import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import store from '../store'
import { loadUser } from '../actions/authActions'
import AppState from './AppState'

import 'bootstrap/dist/css/bootstrap.min.css'

const App = () => {
    useEffect(() => {
        store.dispatch(loadUser())
    }, [])

    return (
        <Provider store={store}>
            <AppState/>
        </Provider>
    )
}

export default App
