import React from 'react'

const Navbar = (props) => {
    return (
        <div style={{ width: '150px', height: '100%', position: 'fixed', left: '0', top: '0', backgroundColor: '#f2f2f2' }}>        
            { props.children }
        </div>
    )
}

export default Navbar