import React from 'react'
import { connect } from 'react-redux'
import { CardFloat } from '.'
import { CardContent } from '@material-ui/core'
import { Color, days, timeToLocal } from '../util'
import styled from 'styled-components'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRunRounded';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenterRounded';

const Toggle = styled.div`
  border-radius: 50%;
  padding: 4px;
  border: 1px solid;
  display: flex;
  border-color: ${ (props) => props.color ? props.color : Color.PRIMARY };
  margin-right: 8px;
  background-color: ${ (props) => props.complete ? (props.color ? props.color : Color.PRIMARY) : 'white' };
`

function FitnessInfo(props) {
    const findPlan = (time) => {
        for (let i = 0; i < props.dash.plan.length; i++) {
            if (props.dash.plan[i].date === time) return props.dash.plan[i]
        }

        return null
    }

    let plan = findPlan(timeToLocal(Date.now())), date = new Date(), workouts = false, activities = false
    if (plan) {
        if (plan.fitness.length) workouts = true
        if (plan.activities.length) activities = true
    }

    return (
        <CardFloat style={{ marginBottom: '10px' }}>
            <CardContent style={{ height: '143px'}}>
                <h6 style={{ color: Color.PRIMARY, fontSize: '14px', marginBottom: '0px' }}> {days[date.getDay()]} </h6>
                <p style={{ fontSize: '24px', marginBottom: '5px' }}> {date.getDate()} </p>
                { activities ? <div style={{ display: 'flex', alignItems: 'center', width: '100%'}}>
                    <Toggle color={Color.FAT}>
                        <DirectionsRunIcon style={{ color: Color.FAT, fontSize: '15px' }}/>
                    </Toggle>
                    <p style={{ fontSize: '12px', whiteSpace: 'nowrap', marginBottom: '0px', textOverflow: 'ellipsis', overflow: 'hidden'}}> Activity Scheduled </p>
                </div> : null }
                { workouts ? <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px'}}>
                    <Toggle color={Color.PROTEIN}>
                        <FitnessCenterIcon style={{ color: Color.PROTEIN, fontSize: '15px' }}/>
                    </Toggle>
                    <p style={{ fontSize: '12px', whiteSpace: 'nowrap', marginBottom: '0px', textOverflow: 'ellipsis', overflow: 'hidden'}}> Workout Scheduled </p>
                </div> : null }
                { !workouts && !activities ? <div>
                    <p style={{ opacity: '.7', fontSize: '12px', margin: '8px 10px 10px 0px' }}> Nothing Scheduled </p>
                </div> : null }
            </CardContent>
        </CardFloat>
    )
}

function mapStateToProps(state) {
    return {
        dash: state.dash
    }
}

export default connect(mapStateToProps)(FitnessInfo)