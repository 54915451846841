//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
//Components and Pages
import { Fade } from '@material-ui/core'
import { predictiveMealplan } from '../actions/dashboardActions'
import { Image } from '../components'
import { toTitleCase, Color } from '../util'
//Images
import AddIcon from '@material-ui/icons/Add'

const SearchListing = (props) => {
    const handleAddOpen = (e, options) => {
        e.stopPropagation()
        props.handleAddOpen(options)
    }

    const renderFood = (food) => {
        if (!food) return null
        switch (food.class) {
            case ('custom') :
            case ('recipe') : {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        { props.food.image_url ? <Image src={props.food.image_url} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', backgroundColor: 'grey', border: 'none', marginRight: '8px' }}/> : null }
                        <p style={{ margin: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}> { toTitleCase(props.food.name) } </p>       
                        { props.handleAddOpen ? <div style={{ marginLeft: 'auto', padding: '3px', backgroundColor: 'rgba(0,0,0,.05)', color: Color.PRIMARY, borderRadius: '50%' }} onClick={(e) => handleAddOpen(e, { event: e, meal: props.food, populate: true, tod: props.meal })}>
                            <AddIcon/>
                        </div> : null }
                    </div>
                )
            }

            case ('branded') :
            case ('common') : {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        { props.food.photo.thumb ? <Image src={props.food.photo.thumb} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', backgroundColor: 'grey', border: 'none', marginRight: '8px' }}/> : null }
                        <p style={{ margin: '0px', marginRight: '10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}> { toTitleCase(props.food.food_name) }  </p>       
                        { props.handleAddOpen ? <div style={{ marginLeft: 'auto', padding: '3px', backgroundColor: 'rgba(0,0,0,.05)', color: Color.PRIMARY, borderRadius: '50%' }} onClick={(e) => handleAddOpen(e, { event: e, meal: props.food, populate: true })}>
                            <AddIcon/>
                        </div> : null }
                    </div>
                )
            }
        }
    }

    return props.food ? <Fade in={true}>
            <div style={{ margin: '10px 0px 10px 0px', padding: '8px', opacity: '.7', backgroundColor: 'rgba(0,0,0,.05)', borderRadius: '10px', fontSize: '14px' }} onClick={() => props.handleInfoOpen?.({ meal: props.food, populate: true })}>
            { renderFood(props.food) } 
        </div>
    </Fade> : null
}


class RecommendedCardGroup extends Component {
    constructor(props) {
        super(props)
        this.state = { }
    }

    render() {
        return (
            <div>
                { this.props.dash.predictive[this.props.meal || 'breakfast']?.data.map((meal, i) => {
                    return <SearchListing meal={this.props.meal} key={i} food={meal} handleAddOpen={this.props.handleAddOpen} handleInfoOpen={this.props.handleInfoOpen}/>
                }) }
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        predictiveMealplan }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RecommendedCardGroup))