import React, { useEffect, useState } from "react";

function PageHandler(props) {
    const [tab, setTab] = useState(props.tab)

    useEffect(() => {
        async function handler () {
            if (props.tab === tab && props.children[tab].props.onRender) await props.children[tab].props.onRender()
            if (props.tab !== tab && props.children[tab].props.onDismount) await props.children[tab].props.onDismount()
            setTab(props.tab)
        }

        handler()
    }, [tab, props.tab])

    return (
        <div key={props.tab}>
            { props.children[props.tab] ? props.children[props.tab] : <h6> No tab </h6> }
        </div>
    )
}

export default PageHandler;
