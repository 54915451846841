//Classes and Functions
import React, { Component } from 'react' 
import { connect } from 'react-redux'
//Components and Pages
import { Grow, Fab } from '@material-ui/core';
//Images
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class CardGroup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tab: 0
        }
    }

    handleForward = (event) => {
        event.stopPropagation()
        event.preventDefault()
        this.setState({ tab : this.state.tab + 1 })
    }

    handleBack = (event) => {
        event.stopPropagation()
        event.preventDefault()
        this.setState({ tab : this.state.tab - 1 })
    }

    renderButtons = (max) => {
        return (
            this.props.auth.user ? <div>
            { this.state.tab < max ? <Grow in={true}>
                <Fab size="small" color="primary" variant="extended" style={{outline: 'none', margin: '0px 10px 0px 10px', position: 'absolute', top:'50%', transform:'translateY(-50%)', right:'-20px', zIndex: '48'}} onClick={(event) => this.handleForward(event)}>
                    <ArrowForwardIosIcon fontSize="small"/>
                </Fab>
            </Grow> : null }
            { this.state.tab > 0 ? <Grow in={true}>
                <Fab size="small" color="primary" variant="extended" style={{outline: 'none', margin: '0px 10px 0px 10px', position: 'absolute', top:'50%', transform:'translateY(-50%)', left:'-20px', zIndex: '48'}} onClick={(event) => this.handleBack(event)}>
                    <ArrowBackIosIcon fontSize="small"/>
                </Fab>
            </Grow> : null }
        </div> : null
        )
    }

    standardize = () => {
        let ret = []

        if (this.props.children) {
            if (Array.isArray(this.props.children)) {
                ret = this.props.children.filter(child => child !== null)
            } else if (this.props.children !== null) {
                ret.push(this.props.children)
            }
        }

        return ret
    }

    renderCard = () => {
        let children = this.standardize()
        return children[this.state.tab] 
    }

    render() {
        let children = this.standardize()
        
        return (
            <div style={{ position: 'relative', marginBottom: '5px' }}>
                <div style={{ position: 'relative '}}>
                    { this.renderButtons(children ? children.length - 1 : 0) }
                    { this.renderCard() }
                </div>
                { this.props.paginate && Array.isArray(this.props.children) && this.props.children.length > 1 ? <div style={{ backgroundColor: 'grey', height: '15px', marginLeft: '50%', transform: 'translateX(-50%)', borderRadius: '25px', opacity: '.5', display: 'inline-block', position: 'relative' }}>
                    { this.props.children.map((child, i) => {
                        return <div key={i} style={{ backgroundColor: 'white', borderRadius: '50%', width: '5px', height: '5px', display: 'inline-block', margin: '5px', marginBottom: '10px', opacity: i === this.state.tab ? '1' : '.5'}}/>
                    })}
                </div> : null }
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	}
}

export default connect(mapStateToProps)(CardGroup)
