import React, { Component } from 'react';
import { Color } from '../util';
import { Modal, FileSelector, CardFloat, ModalButton } from './'
import { Card, CardContent } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateAvatar } from '../actions/authActions'

class ChangeAvatarModal extends Component {
    constructor(props) { 
        super(props)
        this.state = {
            file: null,
            fileUrl: null,
            uploading: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.auth.updateProfileLoading && !this.props.auth.updateProfileLoading && this.props.open) this.props.handleClose()
    }

    handleFileChange = (event) => {
        this.setState({ file: event.target.files[0], fileUrl: URL.createObjectURL(event.target.files[0]) })
    }

    handleSubmit = () => {
        let formdata = new FormData()
        formdata.append('file', this.state.file)
        this.setState({ uploading: true })
        this.props.updateAvatar(formdata)
    }

    render() {
        return (
            <Modal direction='up' head={'Change Avatar'} in={this.props.open} handleClose={this.props.handleClose}>
                <div>
                    { !this.state.file ? <FileSelector type='image/*' handleChange={this.handleFileChange}>
                        <Card style={{ borderRadius: '25px', marginBottom: '15px', backgroundColor: 'grey', opacity: '.5', marginTop: '20px'}}>
                            <CardContent>
                                <h6 style={{ textAlign: 'center' , marginTop: '20px'}}> Choose a File to Upload </h6>
                            </CardContent>
                        </Card>
                    </FileSelector> : null }
                    { this.state.file ? <CardFloat>
                        <CardContent>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img alt={'Selected File'} style={{ height: '100px', width: '100px', borderRadius: '50%', objectFit: 'cover' }} src={this.state.fileUrl}/>
                                <div style={{ marginLeft: '20px', overflow: 'hidden' }}>
                                    <h6 style={{ wordWrap: 'break-word', textOverflow: 'ellipse' }}> {this.state.file.name} </h6>
                                    <FileSelector type='image/*' handleChange={this.handleFileChange}>
                                        <h6 style={{ color: Color.PRIMARY, fontSize: '14px' }}> Choose a new photo </h6>
                                    </FileSelector>
                                </div>
                            </div>
                        </CardContent>
                    </CardFloat> : null }
                </div>
                <ModalButton loading={this.props.auth.updateProfileLoading} disabled={!this.state.file} handleClick={this.handleSubmit} label={'Update Avatar'}/>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        updateAvatar 
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAvatarModal)