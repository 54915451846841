import { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CreateMealPlanModal, FoodCard, MealPlanSwapModal, NutritionBar, NutritionInfoModal, PageCard, StickyHeader, TrackingAddModalNutrition } from '../components'
import { Color, toTitleCase, getAttribute } from '../util'
import { createMealPlan, getMealPlan } from '../actions/dashboardActions'
import AddIcon from '@material-ui/icons/Add'

const meals = ['breakfast', 'lunch', 'dinner', 'snack']
const colors = {
    'breakfast' : 'white',
    'lunch' : '#e6eafa',
    'dinner' : '#b5bfef',
    'snack' : '#9caae9'
}

const MealPlan = (props) => {
    const [length, setLength] = useState(3)
    
    const [swapModalOpen, setSwapModalOpen] = useState(false)
    const [swapFood, setSwapFood] = useState(null)
    const [swapDate, setSwapDate] = useState(null)
    const [swapMeal, setSwapMeal] = useState(null)
    const [swapInfo, setSwapInfo] = useState(null)

    const [infoModalOpen, setInfoModalOpen] = useState(false)
    const [infoFood, setInfoFood] = useState(null)

    const [addModalOpen, setAddModalOpen] = useState(false)
    const [addFood, setAddFood] = useState(null)

    const [createModal, setCreateModal] = useState(false)

    useEffect(() => {
        if (props.dash.mealPlanLoading || props.dash.mealPlan) return
        props.getMealPlan()
    }, [])

    const handleCreate = () => {
        createMealPlan({
            user_id: props.auth.user._id,
            length
        })
    }

    const handleInfoOpen = (food) => {
        setInfoFood(food)
        setInfoModalOpen(true)
    }

    const handleAddOpen = (food) => {
        setAddFood({...food, class: 'recipe' })
        setAddModalOpen(true)
    }

    const handleSwapOpen = (food, date, meal) => {
        setSwapFood(food)
        setSwapDate(date)
        setSwapMeal(meal)
        setSwapInfo({ plan_id: props.dash.mealPlan._id, food_id: food._id, date, meal })
        setSwapModalOpen(true)
    }

    const renderMeal = (meal, name, date) => {
        let carbs = 0, protein = 0, fat = 0, calories = 0
        
        meal.forEach((item) => {
            carbs += getAttribute('carbs', item.food, { servings: 1 })
            protein += getAttribute('protein', item.food, { servings: 1 })
            fat += getAttribute('fat', item.food, { servings: 1 })
            calories += getAttribute('calories', item.food, { servings: 1 })
        })

        return (
            <div style={{ backgroundColor: colors['breakfast'], boxShadow: '0 0px 20px 5px rgba(0,0,0,.08)', padding: '20px 20px 30px 20px', borderRadius: name === 'snack' ? '25px' : '25px 25px 0px 0px', width: '100%', marginBottom: name === 'snack' ? '0px' : '-20px' }}>
                <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                    <h6 style={{ margin: '0px' }}> {toTitleCase(name)} </h6>
                    <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginBottom: '0px', fontSize: '12px', marginRight: '5px', opacity: '.7' }}> {calories} Cals </p>
                        <NutritionBar style={{ width: '100px', height: '8px', position: 'relative' }} carbs={carbs} protein={protein} fat={fat}/>
                    </div>
                </div>
                {
                    meal.map((item) => {
                        return <FoodCard handleInfoOpen={() => handleInfoOpen(item.food)}  handleSwapOpen={() => handleSwapOpen(item.food, date, name)} handleAddOpen={() => handleAddOpen(item.food)} style={{ color: 'white' }} food={item.food}/>
                    })
                }
            </div>
        )
    }

    const renderDay = (day, index) => {
        return (
            <div style={{ width: '90%', paddingBottom: '20px', flexShrink: '0', marginLeft: '15px', marginRight: index === props.dash.mealPlan.days.length - 1 ? '15px' : '0px' }}>
                <h6> Day {index + 1} </h6>
                {
                    meals.map((meal) => {
                        return renderMeal(day.meals[meal], meal, day.date)
                    })
                }
            </div>
        )
    }

    const renderPlan = () => {
        return (
            <div style={{ display: 'flex', overflowX: 'scroll', overflowY: 'hidden', marginLeft: '-15px', marginRight: '-15px' }}>
                {
                    props.dash.mealPlan.days.map((day, i) => {
                        return renderDay(day, i)
                    })
                }
            </div>
        )
    }

    return (
        <div>
            <StickyHeader>
                <h5 style={{ marginBottom: '0px' }}> Meal Plan </h5>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', color: Color.PRIMARY, cursor: 'pointer', gap: '3px' }}>
                    <AddIcon style={{ fontSize: '16px' }}/>
                    <p style={{ marginLeft: 'auto', marginBottom: '0px', fontSize: '16px' }} onClick={() => setCreateModal(true)}> Create Plan </p>                
                </div>
            </StickyHeader>
            <PageCard title={'Meal Plan'} style={{ paddingTop: '0px' }}>
                { 
                    props.dash.mealPlan ? renderPlan() : <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '200px' }}>
                        {/* {
                        <div style={{ display: 'flex', overflowX: 'scroll', overflowY: 'hidden', marginLeft: '-15px', marginRight: '-15px' }}>
                            {
                              renderMeal([], 'Breakfast')
                            }
                        </div>
                        } */}
                        <p> Explore by <span style={{ color: Color.PRIMARY }}> Creating a Meal Plan </span> </p>
                    </div> }
            </PageCard>
            
            <MealPlanSwapModal open={swapModalOpen} handleClose={() => setSwapModalOpen(false)} handleInfoOpen={handleInfoOpen} food={swapFood} date={swapDate} meal={swapMeal}/>
            <NutritionInfoModal time={new Date()} trackingTime={new Date()} infoMeal={{ ...infoFood, is_complete: true, class: 'recipe' }} open={infoModalOpen} onSwap={() => setSwapModalOpen(false)} swapInfo={swapInfo} handleClose={() => setInfoModalOpen(false)} swap={swapModalOpen}/>
            <TrackingAddModalNutrition open={addModalOpen} time={new Date()} targetTime={new Date()} meal={addFood} handleClose={() => {
                setAddModalOpen(false)
                setAddFood(null)
            }}/>
            <CreateMealPlanModal open={createModal} handleClose={() => setCreateModal(false)}/>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        dash: state.dash,
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        createMealPlan,
        getMealPlan
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MealPlan)