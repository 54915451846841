import { useEffect, useState } from 'react'
import { Slide } from '@material-ui/core'
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import { Color } from '../util'
import { useHistory } from "react-router-dom";

const BackButton = (props) => {
    const [back, setBack] = useState(true)
    const [scrollPosition, setScrollPosition] = useState(0)
    const history = useHistory()

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    const handleScroll = (event) => {
        let scrollPositionTemp = window.scrollY
        if (scrollPositionTemp < 0) scrollPositionTemp = 0
        setScrollPosition((current) => {
            if (scrollPositionTemp > current) {
                setBack(false)
                return scrollPositionTemp
            } else {
                setBack(true)
                return scrollPositionTemp
            }
        })
    }

    const handleClick = (event) => {
        event.stopPropagation()
        event.preventDefault()
        history.goBack()
    } 

    return (
        <div style={{ position: 'fixed', left: '50%', bottom: '75px', transform: 'translateX(-50%)', zIndex: '500'}} onClick={handleClick}>
            <Slide direction='up' in={back && history.location?.state?.from} timeout={300}>
                <div onClick={props.handleClose} style={{ backgroundColor: Color.PRIMARY, borderRadius: '25px', padding: '7px 12px 7px 12px',  display: 'flex', alignItems: 'center', boxShadow: '0 6px 20px 10px rgba(0, 0, 0, 0.1)' }}>
                    <BackIcon style={{ color: 'white', fontSize: '14px', marginRight: '3px' }}/>
                    <p style={{ fontSize: '14px', color: 'white', marginBottom: '0px'}}> {`Back to ${history.location?.state?.from}`} </p>
                </div>
            </Slide>
        </div>
    )
}

export default BackButton