import React, { Component } from 'react';
import { CardContent, Slide } from '@material-ui/core'
import { PageCard, CardFloat, ModalButton, Input } from '../components'
import stnbly from '../images/sustainablyapp.svg'
import api from '../api'
import { Color } from '../util'
import { MdOutlineShortcut } from 'react-icons/md'

class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            loading: false,
            sent: false,
            interval: null,
            timer: 0,
            error: {}
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.interval)
    }

    tick = () => {
        if (this.state.timer === 1) clearInterval(this.state.interval)
        this.setState({ timer: this.state.timer - 1 })
    }

    handleChange = (variable, value) => {
        this.setState({ [variable] : value, error: { ...this.state.error, [variable]: null } })
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') this.handleForgotPassword()
    }

    handleForgotPassword = () => {
        this.setState({ loading: true })
		api.userForgotPassword({ email: this.state.email }).then((response) => {
            this.setState({ loading: false, sent: true, timer: 30, interval: setInterval(this.tick, 1000) })
		}).catch((error) => {
            this.setState({ loading: false, error: { email: error.response.data.msg } })
		})
    }

    render() {
        return (
            <PageCard style={{ padding: '0px' }} title='Forgot Password'>
                <div style={{ display: 'flex', justifyContent: 'center', height: '70px', borderBottom: '1px solid rgba(0,0,0,.1)'  }}>
                    <img alt='STNBLY Icon' src={stnbly} style={{ width: '50px' }} />
                </div>
                <div style={{ maxWidth: '450px', margin: 'auto' }}>
                    <div>
                        <h6 style={{ textAlign: 'center', fontSize: '18px', marginTop: '20px' }}> Reset your password </h6>
                        <div style={{ padding: '0px 20px 15px 20px' }}>
                            <CardFloat style={{ marginBottom: '15px'}}>
                                <CardContent>
                                    <Input error={this.state.error.email} onFocus={() => this.setState({ error: { ...this.state.error, email: null }})} type={'email'} value={this.state.email} onKeyPress={this.handleKeyPress} onChange={(event) => this.handleChange('email', event.target.value)} placeholder={'Email Address'} style={{ width: '100%'}}/>
                                    <ModalButton icon={<MdOutlineShortcut/>} disabled={!this.state.email || this.state.timer > 0} loading={this.state.loading} handleClick={this.handleForgotPassword} label={this.state.sent ? (this.state.timer > 0 ? `Resend in ${this.state.timer}s` : 'Resend') : 'Send'} style={{ position: 'relative', margin: '20px 0px 0px 0px', width: '100%', bottom: '0px' }}/>
                                </CardContent>
                            </CardFloat>
                            <p style={{ textAlign: 'center', marginBottom: '10px' }}>
                                <a href={'/login'} style={{ color: Color.PRIMARY }}> Back to Sign In </a>
                            </p>
                        </div>
                    </div>
                </div>
                { this.state.sent ? <div style={{ position: 'fixed', left: '50%', top: '17px', transform: 'translateX(-50%)', zIndex: '1010'}}>
                    <Slide direction='down' in={true}>
                        <div style={{ backgroundColor: Color.PRIMARY, borderRadius: '25px', padding: '7px 12px 7px 12px',  display: 'flex', alignItems: 'center', boxShadow: '0 6px 20px 10px rgba(0, 0, 0, 0.1)' }}>
                            <p style={{ fontSize: '14px', color: 'white', marginBottom: '0px', whiteSpace: 'nowrap'}}> Reset link sent to <b>{this.state.email}</b></p>
                        </div>
                    </Slide>
                </div> : null }
            </PageCard>
        );
    }
}

export default ForgotPassword;