import { Card } from '@material-ui/core'
import styled from 'styled-components'

const CardFloat = styled(Card)`
    margin: 15px 5px 15px 5px;
    padding: 5px;
    border-radius: 25px;
    overflow: hidden;
    border: ${props => props.outline ? '1px solid rgba(0,0,0,.1)' : 'none'};
    box-shadow: ${props => !props.outline ? '0 0px 20px 5px rgba(0,0,0,.08)' : 'none'};
    background-color: ${props => props.disabled ? 'grey' : null };
    opacity: ${props => props.disabled ? '.7' : '1' };
    transition: background-color 0.3s ease, opacity 0.3s ease;
    position: relative;
`

export default CardFloat