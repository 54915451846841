import React from 'react';
import { Fade, CardContent } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { CardFloat } from './'

import { ReactComponent as M04 } from '../images/M04.svg'
import { ReactComponent as M06 } from '../images/M06.svg'
import { ReactComponent as M09 } from '../images/M09.svg'
import { ReactComponent as M12 } from '../images/M12.svg'
import { ReactComponent as M15 } from '../images/M15.svg'
import { ReactComponent as M18 } from '../images/M18.svg'
import { ReactComponent as M21 } from '../images/M21.svg'
import { ReactComponent as M28 } from '../images/M28.svg'
import { ReactComponent as M33 } from '../images/M33.svg'
import { ReactComponent as M38 } from '../images/M38.svg'
import { ReactComponent as M42 } from '../images/M42.svg'
import { ReactComponent as M45 } from '../images/M45.svg'

import { ReactComponent as F11 } from '../images/F11.svg'
import { ReactComponent as F13 } from '../images/F13.svg'
import { ReactComponent as F16 } from '../images/F16.svg'
import { ReactComponent as F19 } from '../images/F19.svg'
import { ReactComponent as F22 } from '../images/F22.svg'
import { ReactComponent as F25 } from '../images/F25.svg'
import { ReactComponent as F28 } from '../images/F28.svg'
import { ReactComponent as F33 } from '../images/F33.svg'
import { ReactComponent as F38 } from '../images/F38.svg'
import { ReactComponent as F42 } from '../images/F42.svg'
import { ReactComponent as F45 } from '../images/F45.svg'

const BodyFatCard = (props) => {
    const getBodyFat = (current) => {
		let subhead, image
		switch(current.gender) {
			case ("Male") : {
				if (current.bodyFat <= .04) { 
					subhead = '0-4%'
					image = M04
				} else if (current.bodyFat <= .06) {
					subhead = '5-6%'
					image = M06
				} else if (current.bodyFat <= .09) {
					subhead = '7-9%'
					image = M09
				} else if (current.bodyFat <= .12) {
					subhead = '10-12%'
					image = M12
				} else if (current.bodyFat <= .15) {
					subhead = '13-15%'
					image = M15
				} else if (current.bodyFat <= .18) {
					subhead = '16-18%'
					image = M18
				} else if (current.bodyFat <= .21) {
					subhead = '19-21%'
					image = M21
				} else if (current.bodyFat <= .28) {
					subhead = '22-28%'
					image = M28
				} else if (current.bodyFat <= .33) {
					subhead = '29-33%'
					image = M33
				} else if (current.bodyFat <= .38) {
					subhead = '34-38%'
					image = M38
				} else if (current.bodyFat <= .42) {
					subhead = '39-42%'
					image = M42
				} else {
					subhead = '45%+'
					image = M45
				}
				break
			}

			case ("Other") : 
			case ("Female") : {
				if (current.bodyFat <= .11) { 
					subhead = '0-4%'
					image = F11
				} else if (current.bodyFat <= .13) {
					subhead = '5-6%'
					image = F13
				} else if (current.bodyFat <= .16) {
					subhead = '7-9%'
					image = F16
				} else if (current.bodyFat <= .19) {
					subhead = '10-12%'
					image = F19
				} else if (current.bodyFat <= .22) {
					subhead = '13-15%'
					image = F22
				} else if (current.bodyFat <= .25) {
					subhead = '16-18%'
					image = F25
				} else if (current.bodyFat <= .28) {
					subhead = '19-21%'
					image = F28
				} else if (current.bodyFat <= .33) {
					subhead = '22-28%'
					image = F33
				} else if (current.bodyFat <= .38) {
					subhead = '29-33%'
					image = F38
				} else if (current.bodyFat <= .42) {
					subhead = '34-38%'
					image = F42
				} else if (current.bodyFat <= .45) {
					subhead = '39-42%'
					image = F45
				} else {
					subhead = '45%+'
					image = F45
				}
				break
			}

			default : {
				subhead = 'Choose Gender'
			}
		}

		return { subhead, image }
	}

	const renderBodyFat = (current, goal) => {
		let person1 = getBodyFat(current), person2
		if (goal) person2 = getBodyFat(goal)
		let Comp1 = person1?.image, Comp2 = person2?.image

		return (
			<div>
				<h6> Body Fat Percentage </h6>
				<h6 style={{ opacity: '.7', fontSize: '14px' }}> {person1.subhead} </h6>
				{ goal ? <Comp2 alt='Background' style={{ height: '120px', width: 'auto', position: 'absolute', right: '100px', top: '40px', opacity: '.7'}}/> : null}
				{ person1.image ? <Comp1 alt='Foreground' style={{ height: '190px', width: 'auto', position: 'absolute', right: '25px', top: '2px', opacity: '1'}}/> : <Skeleton variant="rect" height={70} width={70} style={{ position: 'absolute', right: '20px', top: '17px', borderRadius: '15px'}} animation="wave"/>}
			</div>
		)
    }
    
    return (
        <Fade in={true}>
            <CardFloat style={{ ...props.style }}>
                <CardContent>
                    {renderBodyFat({ bodyFat: props.foreground.body_fat, gender: props.foreground.gender }, props.background ? { bodyFat: props.background.body_fat, gender: props.background.gender } : undefined )}
                </CardContent>
            </CardFloat>
        </Fade>
    )
}

export default BodyFatCard