import { CardFloat, Input, GenerationCalendar, DateInput, BodyFatSelector } from './'
import { Color, toTitleCase, Measure } from '../util'
import { Slider } from '@material-ui/core'
import { MdCheck } from 'react-icons/md'
import { useState } from 'react'
import { useEffect } from 'react'

const HeightInput = ({ unit, error, value, onChange, onBlur }) => {
	const [inches, setInches] = useState(0)
	const [feet, setFeet] = useState(0)
	const [centimeters, setCentimeters] = useState(0)

	useEffect(() => {
		setInches(value % 12 || '')
		setFeet(Math.floor(value / 12) || '')
		setCentimeters(adjustMeasure('length', value, null, unit) || '')
	}, [])

	useEffect(() => {
		onChange(unit === 'Imperial' ? Number(inches) + Number(feet) * 12 : adjustMeasure('length', Number(centimeters), true, 'Imperial'))
	}, [inches, feet, centimeters])

	const handleChange = (type, value) => {
		switch(type) {
			case ('inches'):
				setInches(value)
				break
			case ('feet'):
				setFeet(value)
				break
			case ('centimeters'):
				setCentimeters(value)
				break
		}
	}

	return (
		unit === 'Imperial' ? <div>
			<div style={{ display: 'flex', width: '100%' }}>
				<Input disablePlaceholder suff={'ft'} error={error} onBlur={onBlur} type="number" style={{ marginBottom: '10px', width: '100%'}} value={feet} placeholder={'Feet'} onChange = {(event) => {handleChange('feet', event.target.value)}}/>
				<Input disablePlaceholder suff={'in'} error={error} onBlur={onBlur} type="number" style={{ marginBottom: '10px', width: '100%', marginLeft: '10px' }}value={inches} placeholder={'Inches'} onChange = {(event) => {handleChange('inches', event.target.value)}}/>
			</div>
		</div> : <Input disablePlaceholder suff={'cm'} error={error} onBlur={onBlur} type="number" style={{ marginBottom: '10px', width: '100%'}} value={centimeters} placeholder={'Height'} onChange = {(event) => {handleChange('centimeters', event.target.value)}}/>
	)
}

const adjustMeasure = (type, num, base, unit) => {
	if (!base) return Math.round(num * Measure[unit][type].mult * 10) / 10
	return Math.round(num / Measure[unit][type].mult * 10) / 10
}

const QuestionCard = (props) => {
	return (
		<CardFloat outline style={{ border: props.disabled ? `1px solid ${props.color || Color.PRIMARY}` : null, margin: '0px 5px 0px 5px', backgroundColor: props.disabled ? ((props.color || Color.PRIMARY) + '08') : 'white', color: props.disabled ? props.color || Color.PRIMARY : null, transition: 'all .3s ease', textAlign: 'center', padding: '16px', borderRadius: '15px', cursor: 'pointer' }} onClick={props.onClick}>
			{props.children}
		</CardFloat>
	)
}

const renderSelect = (values, answer, name, multi, onChange, options) => {
	const handleCase = (string) => {
		if (options.capitalized === false) return string
		else return toTitleCase(string)
	}

	return <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
		{
			values.map((condition, i) => {
				let label = null
				let action = null
				let active = null
				let render = null
		
				if (options) {
					if (options.labels) label = options.labels[condition]
					if (options.actions) action = options.actions[condition]
					if (options.active) active = options.active[condition]
					if (options.render) render = options.render[condition]
				}
		
				return (
					multi ? ( !options?.multi_negative ? <QuestionCard key={i} disabled={active ? active(answer) : answer.includes(condition)} onClick={(event) => { 
						onChange(options?.question || name, answer.includes(condition) ? answer.filter((ans) => ans !== condition) : [...answer, condition])
						if (action) action()
					}}>
						{ (active ? active(answer) : answer.includes(condition)) ? <MdCheck style={{ position: 'absolute', right: '15px', top: '50%', transform: 'translateY(-50%)', fontSize: '16px' }}/> : null }
						{ render ? render () : <div style={{margin: '0px 30px 0px 30px'}}>
							<h6 style={{ fontSize: '14px', margin: '0px' }}> {label?.header || label || handleCase(condition)} </h6> 
							{ label?.subheader ? <h6 style={{ opacity: '.7', fontSize: '12px', marginBottom: '0px' }}> {label.subheader} </h6> : null }
						</div> }
					</QuestionCard> : <QuestionCard key={i} color={Color.CALORIES_ERROR} disabled={active ? active(answer) : answer.includes(condition)} onClick={(event) => { 
						onChange(options?.question || name, answer.includes(condition) ? answer.filter((ans) => ans !== condition) : [...answer, condition])
						if (action) action()
					}}>
						{ render ? render () : <div>
							{ active ? active(answer) : answer.includes(condition) ? <strike>
								<h6 style={{ fontSize: '14px', marginBottom: '0px', margin: '0px 30px 0px 30px'}}> {label || handleCase(condition)} </h6> 
							</strike> : <h6 style={{ fontSize: '14px', marginBottom: '0px', margin: '0px 30px 0px 30px'}}> {label || handleCase(condition)} </h6> }
						</div> }
					</QuestionCard> )	: <QuestionCard key={i} disabled={active ? active(answer) : answer === condition} onClick={(event) => { 
						if (action) action()
						onChange(options?.question || name, condition)
					}}>
						{ render ? render () : <div style={{margin: '0px 30px 0px 30px'}}>
							<h6 style={{ fontSize: '14px', margin: '0px' }}> {label?.header || label || handleCase(condition)} </h6> 
							{ label?.subheader ? <h6 style={{ opacity: '.7', fontSize: '12px', marginBottom: '0px' }}> {label.subheader} </h6> : null }
						</div> }				
					</QuestionCard>	
				)
			})
		}
	</div>
}

const renderInput = (answer, error, name, onChange, onBlur, options) => {
	return <Input 
		style={{ margin: '0px 5px 0px 5px' }}
		onChange={(event) => onChange(name, event.target.value)} 
		onBlur={onBlur}
		error={error}
		value={answer} disablePlaceholder
		suff={options?.suff} 
		placeholder={options?.placeholder}/>
}

const renderBodyFatSelector = (answer, name, onChange, options) => {
	return <BodyFatSelector value={answer} gender={options?.gender} onChange={(value) => onChange(name, value)}/>
}

// return <Slider 
// 	min={.08} 
// 	valueLabelFormat={(value) => Math.round(value * 100) + '%'} 
// 	value={answer} 
// 	step={.01} 
// 	max={.5} 
// 	valueLabelDisplay="auto"
// 	style={{ marginLeft: '5px', marginRight: '5px'}} 
// 	onChange={(event, value) => onChange(name, value)}/>                                    

const renderHeight = (answer, error, name, onChange, onBlur, options) => {
	return <HeightInput unit={options?.unit} error={error} value={answer} onChange={(value) => onChange(name, value)} onBlur={onBlur}/>
}

const renderDate = (answer, name, onChange, options) => {
	return <DateInput value={answer} handleChange={(value) => onChange(name, value)}/>
}

const renderGenerationCalendar = (answer, name, onChange, options) => {
	return <GenerationCalendar value={answer} handleChange={(value) => onChange(name, value)}/>
}

const Question = ({ values, type, answer, error, name, onChange, onBlur, options }) => {
	const renderQuestion = (type) => {
		switch(type) {
			case ('select'):
			case ('multi'):
				return renderSelect(values, answer, name, options?.multi, onChange, options)
			case ('height'):
				return renderHeight(answer, error, name, onChange, onBlur, options)
			case ('weight'):
				return renderInput(answer, error, name, onChange, onBlur, options)
			case ('body_fat_percentage'):
				return renderBodyFatSelector(answer, name, onChange, options)
			case ('dob'):
			case ('date'):
				return renderDate(answer, name, onChange, options)
			case ('generation_calendar'):
				return renderGenerationCalendar(answer, name, onChange, options)
			default:
				return renderInput(answer, name, onChange, options)
		}
	}

	return renderQuestion(type)
}

export default Question