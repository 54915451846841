//Classes and Functions
import React, { Suspense } from 'react'
import { connect } from 'react-redux'
//Components and Pages
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { PrivateRoute } from '../components'
import { Backdrop, CircularProgress } from '@material-ui/core';

const SignIn = React.lazy(() => import('../pages/SignIn'));
const SignUp = React.lazy(() => import('../pages/SignUp'))
const PasswordReset = React.lazy(() => import('../pages/PasswordReset'));
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'));
const DashboardHandler = React.lazy(() => import('./DashboardHandler'));
const ActivateHandler = React.lazy(() => import('./ActivateHandler'))

const AffirmThankYou = React.lazy(() => import('../pages/AffirmThankYou'))
const AffirmCancelled = React.lazy(() => import('../pages/AffirmCancelled'))

function AppState(props) {
    return (
        <>
            <Backdrop transitionDuration={600} open={props.auth.isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {!props.auth.isLoading ? <div>
                <Suspense fallback={<Backdrop transitionDuration={600} open={true}>
                        <CircularProgress color="inherit" />
                    </Backdrop>}>
                    <Router>
                        <Switch>
                            <Route path="/signup" exact component={SignUp}/>
                            <Route path='/login' exact component={SignIn}/>
                            <Route path='/reset' exact component={ForgotPassword}/>
                            <Route path="/reset/:token" exact component={PasswordReset}/>

                            <Route path="/affirm/thank-you" exact component={AffirmThankYou}/>
                            <Route path="/affirm/cancelled" exact component={AffirmCancelled}/>

                            <PrivateRoute isAuthenticated={props.auth.isAuthenticated} path='/activate' component={ActivateHandler}/>
                            <PrivateRoute isAuthenticated={props.auth.isAuthenticated} path='/dashboard' component={DashboardHandler}/>
                            <Route path="/">
                                <Redirect to='/dashboard'/>
                            </Route>
                        </Switch>
                    </Router>
                </Suspense>
                {/* <ToastNotifications/> */ }
            </div> : null }
        </>
    )
}


function mapStateToProps(state) {
	return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(AppState)

