//Classes and Functions
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
//Components and Pages
import { CircularProgress, Fade } from '@material-ui/core'
import { loadPreferences, getPreferences } from '../actions/dashboardActions'
import { ChipSelector, FoodCard, SkeletonCard, WorkoutCard } from '../components'
import { MdSort } from 'react-icons/md'
//Images

const sortingOptions = [
    {
        value: 'newest',
        label: 'Newest'
    },
    {
        value: 'oldest',
        label: 'Oldest'
    },
    {
        value: 'most used',
        label: 'Most used'
    },
    {
        value: 'least used',
        label: 'Least used'
    }
]

const FavoritesCards = (props) => {
    const [sort, setSort] = useState(props.dash.favoritesSort || 'newest')
    const preferences = props.dash.preferences.filter((preference) => preference.type === props.type && preference.exclusion === (props.exclusion ? true : false))

    useEffect(() => {
        if (sort === props.dash.favoritesSort) return
        props.getPreferences(props.auth.user._id, sort)
    }, [sort])

    return (
        <div>
            {
                <div style={{ display: 'flex', justifyContent: 'right', marginTop: '5px', alignItems: 'center' }}>
                    <ChipSelector handleChange={(value) => setSort(value)} pref={<MdSort style={{ marginRight: '4px' }}/>} style={{ maxWidth: '120px'}} value={sort} options={sortingOptions}/>
                </div>
            }
            {
                props.dash.preferencesLoading ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginBottom: '10px' }}>
                    <CircularProgress size={25}/>
                </div> : (preferences.length ? <div>
                    {
                        preferences.map((preference, i) => {
                            return <FoodCard key={i} food={preference.target} handleAddOpen={props.handleAddOpen} handleInfoOpen={props.handleInfoOpen} handleCustomUpdateOpen={props.handleCustomUpdateOpen}/>
                        })
                    }
                </div> : <p style={{ marginTop: '30px', textAlign: 'center', opacity: '.7' }}> No Favorited Foods </p>)
            }
        </div>
    )
}

// class FavoritesCards extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             sort: 'newest'
//         }
//     }

//     // componentDidMount = () => {
//     //     this.invokeLoad()
//     // }

//     // componentDidUpdate = (prevProps) => {
//     //     this.invokeLoad()
//     // }

//     // verifyContents = (preferences) => {
//     //     let contents = []

//     //     for (let i = 0; i < preferences.length; i++) {
//     //         if (!preferences[i].target && !this.props.dash.preferenceBuffer.includes(preferences[i]._id)) contents.push(preferences[i])
//     //     }

//     //     return contents
//     // }

//     // invokeLoad = () => {
//     //     /*if (!this.props.dash.preferencesLoaded && !this.props.dash.preferencesLoading && this.props.auth.user) {
//     //         this.props.getPreferences(this.props.auth.user._id)
//     //     }*/

//     //     if (this.props.open && this.props.auth.user && !this.props.dash.preferencesLoading) {
//     //         let contents = this.verifyContents(this.props.dash.preferences.filter((preference) => preference.type === this.props.type && preference.exclusion === (this.props.exclusions ? true : false)))
//     //         if (contents.length) this.props.loadPreferences(contents)
//     //     }
//     // }

//     handleSortChange = (value) => {
//         this.setState({ sort: value })
//         this.props.getPreferences(this.props.auth.user._id, value)
//     }

//     render() {
//         let contents = this.props.dash.preferences.filter((preference) => preference.type === this.props.type && preference.exclusion === (this.props.exclusions ? true : false)), contentsRendered = []
//         if (this.props.type === 'food' && !this.props.dash.preferencesLoading) {
//             contentsRendered = contents.map((preference, i) => {
//                 return <FoodCard key={i} food={preference.target} handleAddOpen={this.props.handleAddOpen} handleInfoOpen={this.props.handleInfoOpen}/>
//             })
//         }
//         // } else {
//         //     contentsRendered = contents.map((preference, i) => {
//         //         return <WorkoutCard key={i} workout={preference.target} handleAddOpen={this.props.handleAddOpen} handleInfoOpen={this.props.handleInfoOpen}/>
//         //     })
//         // }

//         if (this.props.dash.preferencesLoading) contentsRendered.push(<div style={{ display: 'flex', alignItems:'center', justifyContent: 'center', margin: '40px' }}> <CircularProgress size={25}/> </div>)
//         else if (!contents.length) contentsRendered.push(<p style={{ marginTop: '30px', textAlign: 'center', opacity: '.7' }}> No { this.props.exclusions ? 'Excluded' : 'Favorited'} { this.props.type === 'food' ? 'Foods' : 'Workouts' } </p>)

//         contentsRendered = [<div style={{ display: 'flex', justifyContent: 'right', marginTop: '5px', alignItems: 'center' }}>
//             <ChipSelector handleChange={this.handleSortChange} pref={<MdSort style={{ marginRight: '4px' }}/>} style={{ maxWidth: '120px'}} value={this.state.sort} options={sortingOptions}/>
//         </div>,...contentsRendered]

//         return (
//             <div>
//                 { contentsRendered }       
//             </div>
//         )
//     }
// }

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        loadPreferences,
        getPreferences }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesCards)