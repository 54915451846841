//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createCustomWorkout } from '../actions/dashboardActions'
//Components and Pages
import { TextField, CardContent, Chip } from '@material-ui/core';
import { AttributeModal, Modal, RadioButtons, CardFloat, ModalButton } from './'
import { equipment, extremities, main_muscle, movement } from '../util'
//Images
import AddIcon from '@material-ui/icons/Add'

const initialState = {
    name: '',
    description: '',
    duration: false,
    extremities: [],
    muscle_groups: [],
    movement: [],
    equipment: ['Body Weight'],
    attributeOpen: false,
    attributeOptions: [],
    attributeName: '',
    attributeLabel: '',
    success: false,
    error: false
}

const attributes = {
    equipment: {
        label: 'Equipment',
        options: equipment
    },
    muscle_groups: {
        label: 'Muscle Groups',
        options: main_muscle
    },
    extremities: {
        label: 'Extremities',
        options: extremities
    },
    movement: { 
        label: 'Movement',
        options: movement
    }
}

class CustomWorkoutAddModal extends Component {
    constructor (props) {
        super(props)
        this.state = initialState
    }

    handleChange = (question, answer) => {
        this.setState( { [question]: answer })
    }

    handleClose = () => {
        this.setState(initialState)
        this.props.handleClose()
    }

    confirmSubmit = () => {
        return !(this.state.name === '' || !this.state.equipment.length || !this.state.description)
    }
    
    handleCreate = () => {
        let { name, description, duration, equipment, muscle_groups, movement, extremities } = this.state
        this.props.createCustomWorkout({ name, description, duration, equipment, muscle_groups, movement, extremities }).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.handleError()
        })
    }

    handleSuccess = () => {
        this.setState({ success: true })
        setTimeout(this.handleClose, 400)
    }

    handleError = () => {

    }
    
    renderAttributes = (name) => {
        let chips = this.state[name].length ? this.state[name].map((muscle_group, i) => {
            return <Chip key={i} style={{ margin: '2px'}} label={muscle_group} onDelete={() => this.handleChange(name, this.state[name].filter(item => item !== muscle_group))}/>
        }) : [<Chip key={0} style={{ margin: '2px'}} label={'None'}/>]

        chips.push(<AddIcon key={chips.length} fontSize='small' style={{ margin: '2px', opacity: '.7', cursor: 'pointer'}} onClick={() => this.setState({ attributeOpen: true, attributeName: name, attributeOptions: attributes[name].options, attributeLabel: attributes[name].label })}/>)
        return chips
    }

	render() {
        return (
            <div>
                <Modal direction='up' in={this.props.open && !this.state.attributeOpen} handleClose={this.handleClose} head={`Create Exercise`}>
                    <CardFloat>
                        <CardContent>
                            <h6> Create Custom Exercise </h6>
                            <div style={{ margin: '10px'}}>
                                <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Name </h6> 
                                <TextField 
                                    style={{ marginBottom: '10px', width: '100%' }}
                                    value={this.state.name} 
                                    placeholder="Name"
                                    onChange = {(event) => {
                                    this.handleChange("name", event.target.value)}}/>
                            </div>
                            <div style={{ margin: '10px'}}>
                                <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Description </h6> 
                                <TextField 
                                    style={{ marginBottom: '10px', width: '100%' }}
                                    value={this.state.description} 
                                    placeholder="Description"
                                    multiline
                                    onChange = {(event) => {
                                    this.handleChange("description", event.target.value)}}/>
                            </div>
                            <div style={{ margin: '10px' }}>
                                <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Duration </h6> 
                                <h6 style={{ fontSize: '11px', opacity: '.6'}}> Each set has durations vs. reps </h6>  
                                <RadioButtons value={this.state.duration} options={[{ value: true, label: 'Yes', variable: 'duration' }, { label: 'No', value: false, variable: 'duration'}]} handleChange={this.handleChange}/>
                            </div>
                            <div style={{ margin: '10px' }}>
                                <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Equipment </h6>
                                { this.renderAttributes('equipment') }
                            </div>
                            <div style={{ margin: '10px' }}>
                                <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Extremities </h6>
                                { this.renderAttributes('extremities') }
                            </div>
                            <div style={{ margin: '10px' }}>
                                <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Muscle Groups </h6>
                                { this.renderAttributes('muscle_groups') }
                            </div>
                            <div style={{ margin: '10px' }}>
                                <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Movement </h6>
                                { this.renderAttributes('movement') }
                            </div>
                        </CardContent>
                    </CardFloat>
                    <ModalButton success={this.state.success} disabled={!this.confirmSubmit()} loading={this.props.dash.customLoading} handleClick={this.handleCreate} label={`Create ${this.state.name || 'exercise'}`}/>
                </Modal>
                <AttributeModal value={this.state[this.state.attributeName]} open={this.state.attributeOpen} label={this.state.attributeLabel} handleClose={() => this.setState({ attributeOpen: false })} options={this.state.attributeOptions} handleChange={this.handleChange} name={this.state.attributeName}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        createCustomWorkout }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomWorkoutAddModal)

