//General dashboard functions
const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD'

//General fitness and nutrition loaded
const NUTRITION_LOADED = 'NUTRITION_LOADED'
const FITNESS_LOADED = 'FITNESS_LOADED'

//Fitness load adjustments
const FITNESS_SEARCH_LOADING = 'FITNESS_SEARCH_LOADING'
const FITNESS_SEARCH_SUCCESS = 'FITNESS_SEARCH_SUCCESS'
const FITNESS_SEARCH_ERROR = 'FITNESS_SEARCH_ERROR'

//Nutrition load adjustments
const NUTRITION_SEARCH_LOADING = 'NUTRITION_SEARCH_LOADING'
const NUTRITION_SEARCH_SUCCESS = 'NUTRITION_SEARCH_SUCCESS'
const NUTRITION_SEARCH_ERROR = 'NUTRITION_SEARCH_ERROR'

const SESSION_LOADING = 'SESSION_LOADING'
const SESSION_LOAD_SUCCESS = 'SESSION_LOAD_SUCCESS'
const SESSION_LOAD_ERROR = 'SESSION_LOAD_ERROR'
const SESSION_GET_SUCCESS = 'SESSION_GET_SUCCESS'
const SESSION_GET_ERROR = 'SESSION_GET_ERROR'
const SESSION_ADD_SUCCESS = 'SESSION_ADD_SUCCESS'
const SESSION_ADD_ERROR = 'SESSION_ADD_ERROR'
const SESSION_UPDATE_SUCCESS = 'SESSION_UPDATE_SUCCESS'
const SESSION_UPDATE_ERROR = 'SESSION_UPDATE_ERROR'
const SESSION_DELETE_SUCCESS = 'SESSION_DELETE_SUCCESS'
const SESSION_DELETE_ERROR = 'SESSION_DELETE_ERROR'
const SESSION_STATS_LOADING = 'SESSION_STATS_LOADING'
const SESSION_STATS_GET_SUCCESS = 'SESSION_STATS_GET_SUCCESS'
const SESSION_STATS_GET_ERROR = 'SESSION_STATS_GET_ERROR'

const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

const PLAN_LOADING = 'PLAN_LOADING'
const PLAN_LOAD_SUCCESS = 'PLAN_LOAD_SUCCESS'
const PLAN_LOAD_ERROR = 'PLAN_LOAD_ERROR'

const PREDICTIVE_LOADING = 'PREDICTIVE_LOADING'
const PREDICTIVE_SUCCESS = 'PREDICTIVE_SUCCESS'
const PREDICTIVE_ERROR = 'PREDICTIVE_ERROR'

const HISTORY_LOADING = 'HISTORY_LOADING'
const HISTORY_GET_SUCCESS = 'HISTORY_GET_SUCCESS'
const HISTORY_GET_ERROR = 'HISTORY_GET_ERROR'
const HISTORY_ADD_SUCCESS = 'HISTORY_ADD_SUCCESS'
const HISTORY_ADD_ERROR = 'HISTORY_ADD_ERROR'

const PREFERENCES_LOADING = 'PREFERENCES_LOADING'
const PREFERENCES_GET_SUCCESS = 'PREFERENCES_GET_SUCCESS'
const PREFERENCES_GET_ERROR = 'PREFERENCES_GET_ERROR'
const PREFERENCES_UPDATE_SUCCESS = 'PREFERENCES_UPDATE_SUCCESS'
const PREFERENCES_UPDATE_ERROR = 'PREFERENCES_UPDATE_ERROR'
const PREFERENCES_ADD_SUCCESS = 'PREFERENCES_ADD_SUCCESS'
const PREFERENCES_ADD_ERROR = 'PREFERENCES_ADD_ERROR'
const PREFERENCES_DELETE_SUCCESS = 'PREFERENCES_DELETE_SUCCESS'
const PREFERENCES_DELETE_ERROR = 'PREFERENCES_DELETE_ERROR'
const PREFERENCES_LOAD_SUCCESS = 'PREFERENCES_LOAD_SUCCESS'
const PREFERENCES_LOAD_ERROR = 'PREFERENCES_LOAD_ERROR'

const CUSTOM_LOADING = 'CUSTOM_LOADING'
const CUSTOM_FOOD_LOAD_SUCCESS = 'CUSTOM_FOOD_LOAD_SUCCESS'
const CUSTOM_FOOD_LOAD_ERROR = 'CUSTOM_FOOD_LOAD_ERROR'
const CUSTOM_FOOD_ADD_SUCCESS = 'CUSTOM_FOOD_ADD_SUCCESS'
const CUSTOM_FOOD_ADD_ERROR = 'CUSTOM_FOOD_ADD_ERROR'
const CUSTOM_FOOD_UPDATE_SUCCESS = 'CUSTOM_FOOD_UPDATE_SUCCESS'
const CUSTOM_FOOD_UPDATE_ERROR = 'CUSTOM_FOOD_UPDATE_ERROR'
const CUSTOM_FOOD_DELETE_SUCCESS = 'CUSTOM_FOOD_DELETE_SUCCESS'
const CUSTOM_FOOD_DELETE_ERROR = 'CUSTOM_FOOD_DELETE_ERROR'

const CUSTOM_WORKOUT_LOAD_SUCCESS = 'CUSTOM_WORKOUT_LOAD_SUCCESS'
const CUSTOM_WORKOUT_LOAD_ERROR = 'CUSTOM_WORKOUT_LOAD_ERROR'
const CUSTOM_WORKOUT_ADD_SUCCESS = 'CUSTOM_WORKOUT_ADD_SUCCESS'
const CUSTOM_WORKOUT_ADD_ERROR = 'CUSTOM_WORKOUT_ADD_ERROR'
const CUSTOM_WORKOUT_UPDATE_SUCCESS = 'CUSTOM_WORKOUT_UPDATE_SUCCESS'
const CUSTOM_WORKOUT_UPDATE_ERROR = 'CUSTOM_WORKOUT_UPDATE_ERROR'
const CUSTOM_WORKOUT_DELETE_SUCCESS = 'CUSTOM_WORKOUT_DELETE_SUCCESS'
const CUSTOM_WORKOUT_DELETE_ERROR = 'CUSTOM_WORKOUT_DELETE_ERROR'

const CUSTOM_ACTIVITY_LOAD_SUCCESS = 'CUSTOM_ACTIVITY_LOAD_SUCCESS'
const CUSTOM_ACTIVITY_LOAD_ERROR = 'CUSTOM_ACTIVITY_LOAD_ERROR'
const CUSTOM_ACTIVITY_ADD_SUCCESS = 'CUSTOM_ACTIVITY_ADD_SUCCESS'
const CUSTOM_ACTIVITY_ADD_ERROR = 'CUSTOM_ACTIVITY_ADD_ERROR'
const CUSTOM_ACTIVITY_UPDATE_SUCCESS = 'CUSTOM_ACTIVITY_UPDATE_SUCCESS'
const CUSTOM_ACTIVITY_UPDATE_ERROR = 'CUSTOM_ACTIVITY_UPDATE_ERROR'
const CUSTOM_ACTIVITY_DELETE_SUCCESS = 'CUSTOM_ACTIVITY_DELETE_SUCCESS'
const CUSTOM_ACTIVITY_DELETE_ERROR = 'CUSTOM_ACTIVITY_DELETE_ERROR'

const BUG_REPORT_TOGGLE = 'BUG_REPORT_TOGGLE'
const BUG_REPORT_LOADING = 'BUG_REPORT_LOADING'
const BUG_REPORT_SUCCESS = 'BUG_REPORT_SUCCESS'
const BUG_REPORT_ERROR = 'BUG_REPORT_ERROR'

const MODULE_LOADING = 'MODULE_LOADING'
const MODULE_GET_SUCCESS = 'MODULE_GET_SUCCESS'
const MODULE_GET_ERROR = 'MODULE_GET_ERROR'

//Auth Action Types
const LOGIN_LOADING = 'LOGIN_LOADING'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_ERROR = 'LOGIN_ERROR'

const USER_SIGNOUT_LOADING = 'USER_SIGNOUT_LOADING'
const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS'
const USER_SIGNOUT_ERROR = 'USER_SIGNOUT_ERROR'

const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING'
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'

const SUBSCRIPTION_LOADING = 'SUBSCRIPTION_LOADING'
const SUBSCRIPTION_LOAD_SUCCESS = 'SUBSCRIPTION_LOAD_SUCCESS'
const SUBSCRIPTION_LOAD_ERROR = 'SUBSCRIPTION_LOAD_ERROR'

const USER_CREATE_LOADING = 'USER_CREATE_LOADING'
const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
const USER_CREATE_ERROR = 'USER_CREATE_ERROR'

const TOKEN_LOADING = 'TOKEN_LOADING'
const TOKEN_LOAD_SUCCESS = 'TOKEN_LOAD_SUCCESS'
const TOKEN_LOAD_ERROR = 'TOKEN_LOAD_ERROR'

const UPDATE_PROFILE_LOADING = 'UPDATE_PROFILE_LOADING'
const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'

const RECALIBRATION_LOADING = 'RECALIBRATION_LOADING'
const RECALIBRATION_SUCCESS = 'RECALIBRATION_SUCCESS'
const RECALIBRATION_ERROR = 'RECALIBRATION_ERROR'

const TUTORIAL_OPEN = 'TUTORIAL_OPEN'
const TUTORIAL_CLOSE = 'TUTORIAL_CLOSE'

//Snackbar Action Types
const SET_SNACKBAR = 'SET_SNACKBAR'
const CLEAR_SNACKBAR = 'CLEAR_SNACKBAR'

export const sbarActionTypes = {
    SET_SNACKBAR,
    CLEAR_SNACKBAR
}

export const authActionTypes = {
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    CHANGE_PASSWORD_LOADING,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    USER_SIGNOUT_LOADING,
    USER_SIGNOUT_SUCCESS,
    USER_SIGNOUT_ERROR,
    SUBSCRIPTION_LOADING,
    SUBSCRIPTION_LOAD_SUCCESS,
    SUBSCRIPTION_LOAD_ERROR,
    USER_CREATE_LOADING,
    USER_CREATE_SUCCESS,
    USER_CREATE_ERROR,
    TOKEN_LOADING,
    TOKEN_LOAD_SUCCESS,
    TOKEN_LOAD_ERROR,
    UPDATE_PROFILE_LOADING,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
    RECALIBRATION_LOADING,
    RECALIBRATION_SUCCESS,
    RECALIBRATION_ERROR,
}

export const dashActionTypes = {
    CLEAR_DASHBOARD,
    NUTRITION_LOADED,
    MEALPLAN_LOADING: 'MEALPLAN_LOADING',
    MEALPLAN_LOADED: 'MEALPLAN_LOADED',
    MEALPLAN_ERROR: 'MEALPLAN_ERROR',
    FITNESS_LOADED,
    FITNESS_SEARCH_LOADING,
    FITNESS_SEARCH_SUCCESS,
    FITNESS_SEARCH_ERROR,
    NUTRITION_SEARCH_LOADING,
    NUTRITION_SEARCH_SUCCESS,
    NUTRITION_SEARCH_ERROR,
    SESSION_LOADING,
    SESSION_LOAD_SUCCESS,
    SESSION_LOAD_ERROR,
    SESSION_GET_SUCCESS,
    SESSION_GET_ERROR,
    SESSION_ADD_SUCCESS,
    SESSION_ADD_ERROR,
    SESSION_UPDATE_SUCCESS,
    SESSION_UPDATE_ERROR,
    SESSION_DELETE_SUCCESS,
    SESSION_DELETE_ERROR,
    SESSION_STATS_LOADING,
    SESSION_STATS_GET_SUCCESS,
    SESSION_STATS_GET_ERROR,
    CREATE_NOTIFICATION,
    REMOVE_NOTIFICATION,
    PLAN_LOADING,
    PLAN_LOAD_SUCCESS,
    PLAN_LOAD_ERROR,
    PREDICTIVE_LOADING,
    PREDICTIVE_SUCCESS,
    PREDICTIVE_ERROR,
    HISTORY_LOADING,
    HISTORY_ADD_SUCCESS,
    HISTORY_ADD_ERROR,
    HISTORY_GET_SUCCESS,
    HISTORY_GET_ERROR,
    PREFERENCES_LOADING,
    PREFERENCES_GET_SUCCESS,
    PREFERENCES_GET_ERROR,
    PREFERENCES_ADD_SUCCESS,
    PREFERENCES_ADD_ERROR,
    PREFERENCES_UPDATE_SUCCESS,
    PREFERENCES_UPDATE_ERROR,
    PREFERENCES_DELETE_SUCCESS,
    PREFERENCES_DELETE_ERROR,
    PREFERENCES_LOAD_SUCCESS,
    PREFERENCES_LOAD_ERROR,
    CUSTOM_LOADING,
    CUSTOM_FOOD_LOAD_SUCCESS,
    CUSTOM_FOOD_LOAD_ERROR,
    CUSTOM_FOOD_ADD_SUCCESS,
    CUSTOM_FOOD_ADD_ERROR,
    CUSTOM_FOOD_UPDATE_SUCCESS,
    CUSTOM_FOOD_UPDATE_ERROR,
    CUSTOM_FOOD_DELETE_SUCCESS,
    CUSTOM_FOOD_DELETE_ERROR,
    CUSTOM_WORKOUT_LOAD_SUCCESS,
    CUSTOM_WORKOUT_LOAD_ERROR,
    CUSTOM_WORKOUT_ADD_SUCCESS,
    CUSTOM_WORKOUT_ADD_ERROR,
    CUSTOM_WORKOUT_UPDATE_SUCCESS,
    CUSTOM_WORKOUT_UPDATE_ERROR,
    CUSTOM_WORKOUT_DELETE_SUCCESS,
    CUSTOM_WORKOUT_DELETE_ERROR,
    BUG_REPORT_TOGGLE,
    BUG_REPORT_LOADING,
    BUG_REPORT_SUCCESS,
    BUG_REPORT_ERROR,
    MODULE_LOADING,
    MODULE_GET_SUCCESS,
    MODULE_GET_ERROR,
    CUSTOM_ACTIVITY_LOAD_SUCCESS,
    CUSTOM_ACTIVITY_LOAD_ERROR,
    CUSTOM_ACTIVITY_ADD_SUCCESS,
    CUSTOM_ACTIVITY_ADD_ERROR,
    CUSTOM_ACTIVITY_UPDATE_SUCCESS,
    CUSTOM_ACTIVITY_UPDATE_ERROR,
    CUSTOM_ACTIVITY_DELETE_SUCCESS,
    CUSTOM_ACTIVITY_DELETE_ERROR,
    TUTORIAL_OPEN,
    TUTORIAL_CLOSE
}





