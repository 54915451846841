import React, { useEffect, useState } from 'react';

function AppBar(props){
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)
        return () => { window.removeEventListener('resize', handleWindowResize) }
    }, [])

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth)
    }

    const mobileStyles = { position: 'fixed', bottom: '0px', left: '0px',  backgroundColor: 'white', boxShadow: '0 0px 20px 5px rgba(0,0,0,.08)', borderRadius: '25px 25px 0px 0px', padding: '7px 7px 10px 10px', display: 'flex', flexDirection: 'row', zIndex: '999', width: '100%' }
    const tabletStyles = { position: 'fixed', bottom: '0px', left: '0px', width: `${(windowWidth - 600) / 2}px`, height: '100%', marginLeft: 'auto', backgroundColor: 'white', borderRight: '1px solid rgba(0,0,0,.1)', display: 'flex', flexDirection: 'column', zIndex: '999'}
    const desktopStyles = { position: 'fixed', bottom: '0px', left: '0px', width: `${(windowWidth - 600) / 2}px`, height: '100%', marginLeft: 'auto', backgroundColor: 'white', borderRight: '1px solid rgba(0,0,0,.1)', display: 'flex', flexDirection: 'column', zIndex: '999'}

    const isMobile = windowWidth < 600
    const isTablet = windowWidth > 600 && windowWidth < 1200

    return (
        <div style={ isMobile ? mobileStyles : (isTablet ? tabletStyles : desktopStyles)}>
            { props.children.map((child, i) => {
                return <div key={i} style={{ flex: isMobile ? '1' : '0', width: isMobile ? '0' : 'auto' }}>
                    { child }
                </div>
            }) }
        </div>
    )
}

export default AppBar;