import CheckIcon from '@material-ui/icons/CheckRounded';
import logo from '../images/jbf_logo.png'

const AffirmThankYou = () => {
    return (
        <div>
            <div className='h-[150px] fixed top-0 w-full flex items-center border-b border-[#DDDDDD] border'>
                <img src={logo} className='h-full w-full object-contain'/>
            </div>
            <div className="max-w-screen-md mx-auto container p-8 flex flex-col items-center gap-4 h-screen justify-center">
                <div className='rounded-full bg-calories-success text-white bg-opacity-50 p-2 flex items-center'>
                    <CheckIcon style={{ fontSize: '50px' }}/>
                </div>
                <div className='flex flex-col items-center gap-2 text-center'>
                    <h2 className='mb-0'> You're all set! </h2>
                    <h6 className='font-normal text-xl opacity-70 mb-0'> Your payment was accepted </h6>
                    <p className='mt-4'> Manage your payment through Affirm. Once it's confirmed, we'll start your onboarding as soon as possible!  </p>
                </div>
            </div>
        </div>
    )
}

export default AffirmThankYou