import api from './api'

// Color Pallete Declaration
export const Color = {
    PRIMARY: '#072ac8',
    CALORIES: '#7986cb',
    CARBS: '#4dd0e1',
    PROTEIN: '#ff8a65',
    FAT: '#ba68c8',
    CALORIES_SUCCESS: '#00c853',
    CARBS_SUCCESS: '#00e676',
    PROTEIN_SUCCESS: '#69f0ae',
    FAT_SUCCESS: '#b9f6ca',
    CALORIES_ERROR: '#dd2c00',
    CARBS_ERROR: '#ff3d00',
    PROTEIN_ERROR: '#ff6e40',
    FAT_ERROR: '#ff9e80'
}

// Standard Nutrition Adherence Threshold
export const Threshold = {
    CALORIES: 5,
    CARBS: 5,
    PROTEIN: 5,
    FAT: 5
}

export const Times = {
    BREAKFAST: 0,
    LUNCH: 10,
    DINNER: 14
}

// FDA Nutrient Mapping
export const nutrients = {
    fat: 1004,
    carbs: 1005,
    protein: 1003
}

// Measurement Conversions
export const Measure = {
    Imperial: {
        mass : {
            unit: "lb",
            mult: 1
        },
        length : {
            unit: "in",
            mult: 1
        }
    },
    Metric : {
        mass : {
            unit: "kg",
            mult: 2.2
        },
        length : {
            unit: "cm",
            mult: 0.39
        }
    }
}

// Settings Constants
export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
export const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
export const days_pref = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
export const diets = ['anything', 'vegetarian', 'vegan']
export const allergies = ['gluten', 'peanut', 'eggs', 'fish', 'tree nuts', 'dairy', 'soy', 'shellfish', 'beef', 'pork', 'poultry']
export const equipment = ["Body Weight", "Bands", "Barbells", "Bands (Handles)", "Dumbbells", "Pull up Bar", 'Elliptical', 'Cycling', 'Treadmill', 'Rower', "Superband" ]
export const physical_limitations = ["Knee Pain", "Back Pain", "Shoulder Pain", "Ankle Pain"]
export const main_muscle = ["Abs", "Abductors", "Adductors", "Bicep", "Calves", "Upper Chest", "Inner Chest", "Mid Chest", "Forearms", "Glutes", "Hamstrings", "Lats", "Lower Back", "Middle Back", "Neck", "Obliques", "Quads", "Side Shoulder", "Front Shoulder", "Rear Shoulder", "Traps", "Triceps"]
export const movement = ["Push", "Pull", "Vertical Push", "Vertical Pull", "Horizontal Push", "Horizontal Pull", "Hip Dominant", "Knee Dominant", "Mobility", "Static Streches", "Plyometrics", "Anti Extension Core", "Anti Rotation Core", "General Conditioning", "Unilateral", "Bilateral", "Contralateral", "Ipsilateral"]
export const extremities = ["Upper", "Lower", "Core"]
export const health_conditions = ["High Blood Pressure", "Respiratory Issues", "Cholesterol", "Type II Diabetes", "Arthritis", "Thyroid Complications", "Plantar Fasciitis", "IBS", "GERD", "PCOS", "Sleep Apnea", "Leaky Gut", 'Heart Disease Or Stroke', 'Testosterone deficiency']
export const verbs = ["arrange", "add", "boil", "remove", "place", "reduce", "baste", "beat", "blend", "brown", "build", "bury", "carve", "check", "chop", "close", "cool", "correct", "cover", "crumple", "cut", "decorate", "discard", "divide", "drape", "drop", "dry", "film", "fold", "follow", "form", "force", "glaze", "insert", "lay", "leave", "lift", "make", "melt", "mince", "mix", "moisten", "mound", "open", "pack", "paint", "pierce", "pour", "prepare", "press", "prick", "pull", "puree", "push", "quarter", "raise", "reduce", "refresh", "reheat", "replace", "return", "ring", "roast", "roll", "salt", "saute", "scatter", "scoop", "scrape", "scrub", "season", "separate", "set", "settle", "shave", "simmer", "skim", "slice", "slide", "slip", "slit", "smear", "soak", "spoon", "spread", "sprinkle", "stir", "strain", "strew", "stuff", "surround", "taste", "thin", "tie", "tilt", "tip", "top", "toss", "trim", "turn", "twist", "warm", "wilt", "wind", "wrap"]

export const toTitleCase = (str) => {
    if (!str) return null
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
        if (str !== 'and') str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
}

// Authentication Function Headers
export const getAuthRequest = () => {
    const token = localStorage.getItem('accessToken')
    const req = {
        headers: {
            'Content-type': 'application/json'
        }
    }

    if (token) req.headers['x-access-token'] = token
    return req
}

export const getWorkoutType = (workouts) => {
    let types = []

    for (let workout in workouts) {
        if (workouts[workout] && workouts[workout].workout) {
            for (let type in workouts[workout].workout.extremities) {
                types.push(workouts[workout].workout.extremities[type])
            }
        }
    }

    if (types.includes('Lower') && types.includes('Upper')) return 'Full Body'
    if (types.includes('Lower')) return 'Lower'
    return 'Upper'
}

// Nutrition Helper Functions
export const getAttribute = (attribute, food, session) => {
    if (!session || !session.metadata) return Math.round(getNutrient(attribute, food, null))
    return Math.round(getNutrient(attribute, food, session.metadata.serving_unit) * (session.metadata.servings))
}

export const getNutrient = (nutrient, food, unit) => {
    if (!food) return null
    let attribute = food[nutrient]
    let unitScalar = searchFoodPortions(unit, food)
    if (!unitScalar) return attribute
    return attribute * unitScalar           
}

export const searchFoodPortions = (unit, food) => {
    if (food.def_unit === unit && unit) return 1 / food.def_size
    else if (!food.alt_units) return null
    let portion = food.alt_units.find((element) => element.measure === unit)
    if (portion) return (portion.serving_weight / portion.qty) / food.def_weight
    return null
}

// Populate Helper Functions
export const getFoodHelper = async (payload, target_class) => {
    let req = getAuthRequest()
    req.params = { _id : { $in: payload } }
    switch (target_class.toLowerCase()) {
        case ('branded') : {
            return api.getBrandedFoods(req)
        }

        case ('finalfood') : {
            return api.getFoundationFoods(req)
        }

        case ('custom') : {
            return api.getCustomFoods(req)
        }

        default : {
            return api.getFoods(req)
        }
    }
}

export const getWorkoutHelper = async (payload, target_class) => {
    let req = getAuthRequest()
    req.params = { filters : { _id : { $in : payload }}}
    switch (target_class) {
        case ('Custom') : {
            return api.getCustomWorkouts(req)
        }

        default : {
            return api.getWorkouts(req)
        }
    }
}

export const getActivityHelper = async (payload, target_class) => {
    let req = getAuthRequest()
    req.params = { filters : { _id : { $in : payload }}}
    switch (target_class) {
        case ('Custom') : {
            return api.getCustomActivities(req)
        }

        default : {
            return api.getActivities(req)
        }
    }
}

// Populate Functions 
export const populateSessions = async (sessions, type) => {
    let ids = {}
    sessions.map((session) => {
        ids[session.target_class] = ids[session.target_class] ? ([...ids[session.target_class], session.target_id]) : [session.target_id]
        return session
    })

    let entries = Object.entries(ids), targets = []

    if (type === 'food') {
        for (let i = 0; i < entries.length; i++) {
            let entry = entries[i]
            try {
                let target = await getFoodHelper(entry[1], entry[0])
                targets = [...targets, ...target.data]
            } catch (error) {
                return error
            }
        }
    } else if (type === 'workout') {
        for (let i = 0; i < entries.length; i++) {
            let entry = entries[i]
            try {
                let target = await getWorkoutHelper(entry[1], entry[0])
                targets = [...targets, ...target.data]
            } catch (error) {
                return error
            }
        } 
    } else {
        for (let i = 0; i < entries.length; i++) {
            let entry = entries[i]
            try {
                let target = await getActivityHelper(entry[1], entry[0])
                targets = [...targets, ...target.data]
            } catch (error) {
                return error
            }
        } 
    }

    let applied = sessions.map((session) => {
        for (let i = 0; i < targets.length; i++) {
            if (session.target_id === targets[i]._id) {
                return { ...session, target: targets[i] }
            }
        }

        return session
    })

    return applied
}

// Time Functions
export const timeToUTC = (time) => {
    let date = new Date(time)
    date.setUTCHours(0,0,0,0)
    return date.getTime()
}

export const timeToLocal = (time) => {
    let date = new Date(time)
    let test = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
    test.setUTCHours(0,0,0,0)
    return test.getTime()
}

export const localStart = (time) => {
    let date = new Date(time ? time : Date.now())
    date.setHours(0, 0, 0, 0)
    return date.getTime()
}

export const localEnd = (time) => {
    let date = new Date(time ? time : Date.now())
    date.setHours(24, 0, 0, 0)
    return date.getTime()
}

// Initial Onboarding State & Questions
export const questions = {
    start: new Date(),
    tab: 0,
    tt : {
        gender: {
            active: true,
            gender: {
                answer: '',
                type: 'none',
                err: false,
                save_state: 'profile'
            }
        },
        unit: {
            active: true,
            unit: {
                answer: 'Imperial',
                type: 'none',
                err: false
            }
        },
        starting_weight: {
            active: true,
            starting_weight: {
                answer: '',
                type: 'weight',
                err: false
            }
        },
        height: {
            active: true,
            height_feet : {
                answer : '',
                type: "height_feet",
                err: false
            },
            height_inches : {
                answer : '',
                type: "height_inches",
                err : false
            },
            height_meters : {
                answer : '',
                type: "height_meters",
                err: false
            },
            height : {
                answer: '',
                type: 'height',
                err: false
            },
        },
        dob: {
            active: true,
            day: {
                answer: '',
                type: 'day',
                err: false
            },
            month: {
                answer: 0,
                type: 'month',
                err: false
            }, 
            year: { 
                answer: '',
                type: 'year',
                err: false
            },
            dob: {
                answer: '',
                type: 'birth',
                err: false,
                save_state: 'profile'
            }
        },
        body_fat: {
            active: true, 
            starting_body_fat : {
                answer : '',
                type : "percent",
                err : false
            },
        },
        target_weight: {
            active: true,
            target_weight : {
                answer : '',
                type : "weight",
                err : false
            },
        },
        pace : {
            active: true,
            pace : {
                answer : "",
                type : "none",
                err : false
            },
        },
        /*physical_limitations_active : {
            active: true,
            physical_limitations_active: {
                answer: '',
                type: 'emotional',
                err: false
            }
        },
        physical_limitations : {
            active: false,
            physical_limitations : {
                answer: [],
                type: 'physical_limitations',
                err: false
            }
        },*/
        activity_level : {
            active: true,
            activity_level : {
                answer : "",
                type : "none",
                err : false
            },
        },
        health_conditions_active : {
            active: true, 
            health_conditions_active: {
                answer: '',
                type: 'emotional',
                err: false
            }
        },
        health_conditions : {
            active: false,
            health_conditions: {
                answer: [],
                type: 'health_conditions',
                err: false
            }
        },
        diet : {
            active: true,
            diet: {
                answer: '',
                type: 'none',
                err: false,
                options: diets
            }
        },
        allergies_active: {
            active: true,
            allergies_active: {
                answer: '',
                type: 'emotional',
                err: false
            }
        },
        allergies: {
            active: false,
            allergies: {
                answer: [],
                type: 'allergies',
                err: false,
                options: allergies
            }
        },
        fat_percentage: {
            active: true,
            fat_percentage: {
                answer: '',
                type: 'none',
                err: false 
            }
        },
        rec_restaurants: {
            active: true,
            rec_restaurants: {
                answer: '',
                type: 'none',
                err: false
            }
        },
        rec_convenient: {
            active: true,
            rec_convenient: {
                answer: [],
                type: 'convenient',
                err: false
            }
        },
        rec_cooking_breakfast: { 
            active: true,
            breakfast: {
                answer: '',
                type: 'cooking_breakfast',
                err: false
            }
        },
        rec_cooking_lunch: {
            active: true,
            lunch: {
                answer: '',
                type: 'cooking_lunch',
                err: false
            }
        },
        rec_cooking_dinner: {
            active: true,
            dinner: {
                answer: '',
                type: 'cooking_dinner',
                err: false
            },
            rec_cooking: {
                answer: '',
                type: 'exclude',
                err: false
            }
        },
        rec_meal_prep: {
            active: true,
            rec_meal_prep: {
                answer: '',
                type: 'none',
                err: false
            }
        },
        rec_protein_powder: {
            active: true,
            rec_protein_powder: {
                answer: '', 
                type: 'none',
                err: false
            }
        },
        rec_gen_map: {
            active: true,
            rec_gen_map: {
                answer: [
                    [0, 0, 0],
                    [0, 0, 0],
                    [0, 0, 0],
                    [0, 0, 0],
                    [0, 0, 0],
                    [0, 0, 0],
                    [0, 0, 0]
                ],
                type: 'map',
                err: false
            }
        },
        /*fitness_level: {
            active: true,
            fitness_level: {
                answer: '',
                type: 'none',
                err: false
            }
        },
        equipment : {
            active: true,
            equipment : {
                answer : ["Body Weight"],
                type : "none",
                err : false
            },
        }*/
    }
}