//Classes and Functions
import React, { Component } from 'react' 
//Components and Pages
import { Fade, Backdrop, Modal } from '@material-ui/core';
//Images

class ModalGrow extends Component {
    render() {
        return (
            <Modal open={this.props.in} BackdropComponent={Backdrop} BackdropProps={{ timeout: 400 }} hideBackdrop={!this.props.backdrop} onClose={this.props.handleClose}>
                <Fade in={this.props.in} timeout={this.props.timeout || 250} style={{ outline: 'none' }}>
                    { this.props.children }
                </Fade>
            </Modal>
        )
    }
}

export default ModalGrow
