//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
//Components and Pages
import { withRouter } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core';
import { Table } from '../components'
import { Color } from '../util'
import api from '../api'
//Images
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh'
import FilterListIcon from '@material-ui/icons/FilterList';
import DeleteIcon from '@material-ui/icons/Delete';

class AdminReports extends Component {
    constructor (props) {
        super(props)
		this.state = {
            time: new Date(),
            loading: false,
            tooltipLoading: false,
            articles: []
		}
    }

    componentDidMount() {
        this.timeClock = setInterval(this.tick, 1000)
        this.getUsers()
    }

    getAuthRequest = () => {
        const token = localStorage.getItem('accessToken')
        const req = {
            headers: {
                'Content-type': 'application/json'
            }
        }
    
        if (token) req.headers['x-access-token'] = token
        return req
    }

    getUsers = () => {
        this.setState({ loading: true })
        let req = this.getAuthRequest()
        req.params = { sort: "[[\"createdAt\", -1]]" }
        api.getBugReports(req).then((response) => {
            this.setState({ articles: response.data, loading: false })
        }).catch((error) => {
            this.setState({ loading: false })
        })
    }

    getTypeColor = (type) => {
        switch(type) {
            case (true):
                return Color.CALORIES_SUCCESS
            case (false):
                return Color.CALORIES_ERROR
            default:
                return 'lightgrey'
        }
    }

    renderTooltip = () => {
        return (
            !this.state.tooltipLoading ? <div>
                <p style={{ marginBottom: '0px', fontSize: '12px', padding: '5px', color: Color.PRIMARY }}> { this.state.user ? this.state.user.email : 'n/a' } </p>
            </div> : <div style={{ width: '50px', height: '30px', position: 'relative'}}>
                <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translateX(-50%) translateY(-50%)'}}>
                    <CircularProgress size={13}/>
                </div>
            </div>
        )
    }

    handleHover = (_id) => {
        this.setState({ tooltipLoading: true, user: null })
        let req = this.getAuthRequest()
        req.params = { _id }
        api.getUserData(req).then((response) => {
            this.setState({ user: response.data[0], tooltipLoading: false })
        }).catch((error) => {
            this.setState({ tooltipLoading: false })
        })
    }

    handleRefresh = () => {
        this.getUsers()
    }

    componentWillUnmount() { 
        clearInterval(this.timeClock)
        document.body.style.overflow = 'unset';
    }

    tick = () => {
        this.setState({ time: new Date() })
    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                    <h1> Reports </h1>
                    <div style={{ outline: 'none', userSelect: 'none', marginBottom: '20px' }}>
                        <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={this.handleRefresh}> Refresh <RefreshIcon style={{ fontSize: '13px' }}/> </span>
                        <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={() => this.setState({ articleAdd: true })}> Create <AddIcon style={{ fontSize: '13px'}}/> </span>
                        <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={() => this.setState({ filters: !this.state.filters })}> Filters <FilterListIcon style={{ fontSize: '13px'}}/> </span>
                    </div>

                    <Table functions={[{ icon: <DeleteIcon style={{ opacity: '.7', fontSize: '20px' }}/> }]} loading = {this.state.loading} columns = {[
                    { width: 15, name: 'User ID', handleHover: (recalibration) => this.handleHover(recalibration.user_id), renderHover: () => this.renderTooltip(), handleClick: (entry) => this.props.history.push(`/dashboard/admin/users/${entry}`)}, 
                    { width: 30, name: 'Details', renderHover: (report) => <p style={{ marginBottom: '0px', fontSize: '12px', padding: '5px', color: Color.PRIMARY }}> { report } </p> }, 
                    { width: 20, name: 'Feature'},
                    { width: 15, name: 'Resolved', render: (session) => { return <span style={{ fontSize: '12px', backgroundColor: this.getTypeColor(session), verticalAlign: 'center', color: 'white', borderRadius: '5px', padding: '4px' }}> { session ? 'true' : 'false' } </span> } },
                    { width: 20, name: 'Date Created', transform: (date) => { 
                            let obj = new Date(date)
                            return obj.toLocaleString()
                        }
                    }
                ]} data = {
                    this.state.articles.map((row) => {
                        return [
                            row.user_id,
                            row.details,
                            row.feature,
                            row.resolved,
                            row.createdAt
                        ]
                    })
                } raw = {this.state.articles} />
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

export default connect(mapStateToProps)(withRouter(AdminReports))
