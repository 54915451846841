//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createSession, getSessions, loadSessions } from '../actions/dashboardActions'
//Components and Pages
import { CardContent, CircularProgress } from '@material-ui/core';
import { NutritionInfo, Modal, CardFloat, ModalButton, ChipSelector, Input } from './'
import { searchFoodPortions, toTitleCase } from '../util'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import apis from '../api'

const initialState = {
    targetTime: new Date(),
    food: null,
    servings: 1,
    servingType: 'g',
    time: '',
    success: false,
    error: false,
    populating: false
}

class TrackingAddModalNutrition extends Component {
    constructor (props) {
        super(props)
        this.state = initialState
    }

    verify = (food) => food.is_complete !== false

    async componentDidUpdate (prevProps, prevState) {
        if (this.props.targetTime !== prevProps.targetTime) this.setState({ targetTime: this.props.targetTime, time: this.props.tod ? this.props.tod.toLowerCase() : (this.state.targetTime.getDate() === this.props.time.getDate() ? this.handleMealTime(this.props.time) : 'breakfast' )})
        if (this.state.targetTime !== prevState.targetTime) this.setState({ time: this.props.tod ? this.props.tod.toLowerCase() : (this.state.targetTime.getDate() === this.props.time.getDate() ? this.handleMealTime(this.props.time) : 'breakfast' )})
        
        if (prevProps.meal !== this.props.meal) {
            if (!this.verify(this.props.meal)) {
                let food = await this.populate(this.props.meal)
                return this.setState({ food, populating: false, servingType: food.def_unit || 'serving', servings: food.def_size || 1 })
            }

            this.setState({ food: this.props.meal })
        }

        if (prevProps.open === false && this.props.open === true) {
            this.setState({ targetTime: this.props.targetTime, time: this.props.tod ? this.props.tod.toLowerCase() : (this.state.targetTime.getDate() === this.props.time.getDate() ? this.handleMealTime(this.props.time) : 'breakfast' )})
            if (this.props.serving && this.props.serving !== prevState.servings) this.setState({ servings: this.props.serving })
            else this.setState({ servings: this.props.meal.def_size || 1, servingType: this.props.meal.def_unit || 'serving' })
        }
    }

    handleMealTime = (time) => {
        let hours = time.getHours()
        if (hours <= 10) return 'breakfast'
        else if (hours <= 13) return 'lunch'
        else return 'dinner'
    }

    populate = async (food) => {
        let populate = null
        this.setState({ populating: true })

        try { 
            switch (food.class) {
                case ('common') : 
                    populate = await apis.getCommonFoods(food.food_name) // get common nix foods
                    break
                case ('branded') : 
                    populate = await apis.getBrandedFoods(food.nix_item_id) // get branded nix foods
                    break
                case ('recipe') : 
                    populate = await apis.getRecipeById(food._id)
            }
        } catch (error) {
            console.log(error)
        }

        return populate?.data 
    }

    // @TODO make sure that change handler properly sources the unit measurements

    handleChange = (question, answer) => {
        switch (question) {
            case ('servingType') : {
                return this.setState({
                    servingType: answer,
                    servings: Math.round((
                        this.state.servings * (searchFoodPortions(this.state.servingType, this.state.food) / searchFoodPortions(answer, this.state.food)) || 1
                    ))
                })
            }

            default: this.setState( { [question]: answer })
        }
    }
    
    handleAdd = () => {
        this.props.createSession({ 
            time: this.state.targetTime?.getTime() || this.props.time.getTime(), 
            type: 'food', 
            target_class: this.state.food.class, 
            target_id: this.state.food._id, 
            target_tag_id: this.state.food.tag_id,
            target_nix_id: this.state.food.nix_item_id,
            target_upc: this.state.food.upc,
            target: this.state.food.class === 'common' || this.state.food.class === 'branded' ? {
                ...this.state.food,
                class: null
            } : null,
            metadata : { 
                meal: this.state.time,
                servings: Number(this.state.servings), 
                serving_unit: this.state.servingType
            }
        }, this.state.food).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            console.log(error)
        })
    }

    handleClose = () => {
        this.setState(initialState)
        this.props.handleClose()
    }

    handleError = () => {

    }

    handleSuccess = () => {
        this.setState({ success: true })
        setTimeout(this.handleClose, 400)
    }

    renderMenuItems = (food) => {
        if (!food) return []
        if (!food.alt_units) return [food.def_unit || 'serving']
        let items = food.alt_units.map((unit) => unit.measure)
        return items
    }

    confirmSubmit = () => {
        return this.state.servings && Number(this.state.servings) > 0 & this.state.time !== ""
    }

    renderTitle = (food) => {
        if (!food) return 'food'
        return toTitleCase(food.name)
    }

	render() {
        let options = [{ variable: 'time', value: 'breakfast', label: 'Breakfast'}, { variable: 'time', value: 'lunch', label: 'Lunch'}, { variable: 'time', value: 'dinner', label: 'Dinner'}, { variable: 'time', value: 'snack', label: 'Snack' }]

        return (
            <Modal direction='up' in={this.props.open} handleClose={this.handleClose} head={ !this.state.populating ? `Add ${this.renderTitle(this.state.food)}` : null }>
                { !this.state.populating ? <div>
                    <NutritionInfo add simple_macros={this.props.auth?.user.settings.simple_macros} today={this.state.targetTime.getDate() === this.props.time.getDate()} trackingTime={this.state.targetTime} time={this.props.time} food={this.state.food} servingType={this.state.servingType} servings={this.state.servings ? this.state.servings : 0}/>
                    <CardFloat>
                        <CardContent style={{ padding: '16px' }}>
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}> 
                                    <Input 
                                        type="number"
                                        style={{ width: '100%', marginBottom: '5px' }}
                                        error={Number(this.state.servings) <= 0}
                                        value={this.state.servings} 
                                        placeholder="Servings"
                                        selector={<ChipSelector value={this.state.servingType} style={{ backgroundColor: 'inherit', border: 'none', boxShadow: '' }} handleChange={(value) => this.handleChange('servingType', value)} options={this.renderMenuItems(this.state.food).map((portion) => {
                                            return {
                                                value: portion,
                                                label: portion,
                                                variable: 'servingType'
                                            }
                                        })}/>}
                                        onChange = {(event) => {
                                        this.handleChange("servings", event.target.value)}}/>
                                        <ChipSelector blankLabel={'Select Time'} value={this.state.time} handleChange={(value) => this.handleChange('time', value)} options={options} style={{ padding: '10px', borderRadius: '10px', height: '100%', marginBottom: '5px', marginLeft: '10px' }} />
                                </div>
                            </div>
                        </CardContent>
                    </CardFloat>
                    <div style={{ display: 'flex' }}>
                        <ModalButton success={this.state.success} icon={<AddCircleRoundedIcon/>} loading={this.props.dash.sessionLoading} disabled={!this.confirmSubmit()} handleClick={this.handleAdd} label={`Add ${this.renderTitle(this.state.food)}`}/>
                    </div>
                </div> : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px 20px 20px 20px' }}>
                    <CircularProgress size={30}/>
                </div> } 
            </Modal>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        createSession,
        getSessions,
        loadSessions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingAddModalNutrition)

