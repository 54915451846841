import { Card, Slide, CardContent } from "@material-ui/core"
import { useState } from "react"
import { ModalSlide, HorizontalScroll, Chip, CustomFoodCards } from "."
import { Color } from '../util'

import AddIcon from '@material-ui/icons/Add'
import BackIcon from '@material-ui/icons/ArrowBackRounded';

const ActivityAddModal = (props) => {
    const [back, setBack] = useState(true)
    const [scrollPosition, setScrollPosition] = useState(0)
    const [searchDisplayType, setSearchDisplayType] = useState('Recommended')
    const searchDisplayTypes = ['Recommended', 'Custom']


    const handleScroll = (event) => {
        let scroll = event.target.scrollTop
        if (scroll < 0) scrollPosition = 0
        if (scroll > scrollPosition) setBack(false)
        else setBack(true)
        setScrollPosition(scroll)
    }

    const handleSearchDisplayChange = (type) => {
        setSearchDisplayType(type)
    }

    const renderSearchDisplay = () => {
        switch (searchDisplayType) {
            case ('Recommended') : {
                return (
                    <CustomFoodCards activities open={props.open} handleAddOpen={props.handleActivityAddOpen} handleCustomUpdateOpen={props.handleCustomActivityUpdateOpen}/>
                )
            }

            case ('Custom') : {
                return (
                    <CustomFoodCards activities open={props.open} handleAddOpen={props.handleActivityAddOpen} handleCustomUpdateOpen={props.handleCustomActivityUpdateOpen}/>
                )
            }
        }
    }

    return (
        <div>
            <ModalSlide direction='left' in={props.open}>
                <Card onScroll={handleScroll} style={{ position: 'fixed', top: '0', right: '0', left: '0', marginRight: 'auto', marginLeft: 'auto', zIndex: '1000', width: '100%', height: '100%', overflowY: 'auto', overflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', boxShadow: '' }}>
                    <CardContent style={{ padding: '20px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <h5 style={{ marginBottom: '0px' }}> Add Activity </h5>
                            <div style={{ display: 'flex', alignItems: 'flex-end', color: Color.PRIMARY, marginLeft: 'auto' }} onClick={props.handleCustomActivityAddOpen}>
                                <AddIcon style={{ fontSize: '16px', margin: '0px 3px 3px 0px' }}/>
                                <p style={{ marginLeft: 'auto', marginBottom: '0px', fontSize: '16px' }} onClick={props.handleCustomAddOpen}> Custom Activity </p>
                            </div>  
                        </div>

                        {/*<div style={{ height: '33px', position: 'relative' }}>
                            <div style={{ display: 'flex', position: 'absolute', maxWidth: 'calc(100% + 10px)', left: '-5px', top: '0px', marginBottom: '20px' }}>
                                <div style={{ position: 'absolute', left: '0px', height: '100%', width: '6px', zIndex: '2', background: `linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))` }}/>
                                <div style={{ position: 'absolute', right: '0px', height: '100%', width: '6px', zIndex: '2', background: `linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))` }}/>
                                <HorizontalScroll>
                                    <div style={{ display: 'flex', marginLeft: '3px', marginRight: '3px' }}>
                                        { searchDisplayTypes.map((type, i) => {
                                            return <Chip key={i} disabled={searchDisplayType === type} label={type} handleClick={handleSearchDisplayChange}/>
                                        })}
                                    </div>
                                </HorizontalScroll>
                            </div>
                        </div>*/}

                        { renderSearchDisplay() }

                        <div style={{ position: 'fixed', left: '50%', bottom: '10px', transform: 'translateX(-50%)', zIndex: '1010'}}>
                            <Slide direction='up' in={back}>
                                <div onClick={props.handleClose} style={{ backgroundColor: Color.PRIMARY, borderRadius: '25px', padding: '7px 12px 7px 12px',  display: 'flex', alignItems: 'center', boxShadow: '0 6px 20px 10px rgba(0, 0, 0, 0.1)' }}>
                                    <BackIcon style={{ color: 'white', fontSize: '14px', marginRight: '3px' }}/>
                                    <p style={{ fontSize: '14px', color: 'white', marginBottom: '0px'}}> Back to Fitness </p>
                                </div>
                            </Slide>
                        </div>
                    </CardContent>
                </Card>
            </ModalSlide>
        </div>
    )
}

export default ActivityAddModal