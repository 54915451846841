import React from 'react'
import Close from '@material-ui/icons/CloseRounded';

const CloseIcon = (props) => {
    return (
        <div style={{ margin: '10px', position: 'absolute', height: '30px', width: '30px', zIndex: '1001', borderRadius: '50%', padding: '5px', backgroundColor: 'rgba(128,128,128,.85)', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', ...props.style}} onClick={props.handleClose}>
            <Close style={{ fontSize: '20px', color: 'white' }}/>
        </div>
    )
}

export default CloseIcon