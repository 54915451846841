import React, { Component } from 'react'
import { CardContent, Grow } from '@material-ui/core'
import { Image, Views, CardFloat } from './'

class ArticleCard extends Component {
    render() {
        return (
            <div>
                { !this.props.training ? <CardFloat style={{ padding: '0px', cursor: 'pointer', ...this.props.style }} onClick={() => this.props.handleOpen(this.props.article._id, this.props.module ? this.props.module._id : undefined)}>
                    <CardContent style={{ position: 'relative', height: '175px' }}>
                        { this.props.article.img ? <Image src={this.props.article.img} style={{position: 'absolute', top: '0px', right: '0px', height: '200px', width: '100%', maxWidth: '600px', objectFit: 'cover'}}/> : null }
                        <div style={{ position: 'absolute', top: '0', right: '0', height: '200px', width: '100%', backgroundColor: 'rgba(0,0,0,.4)' }} />
                        { this.props.article.img ? <Views views={this.props.article.views} style={{ position: 'absolute', top: '20px', left: '20px', color: 'white' }}/> : null }
                        <div style={{ zIndex: '1', position: 'relative', margin: '95px 0px 20px 5px' }}>
                            <h6 style={{ marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'white'}}> {this.props.article.title} </h6> 
                            <h6 style={{ fontSize: '14px', opacity: '.7', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'white' }}> {this.props.article.short_description} </h6> 
                        </div>
                    </CardContent>
                </CardFloat> : <CardFloat style={{ padding: '0px', cursor: 'pointer', ...this.props.style }} onClick={() => this.props.handleOpen ? this.props.handleOpen(this.props.article._id, this.props.module ? this.props.module._id : undefined) : null}>
                    <CardContent style={{ position: 'relative', height: this.props.preview ? '143px' : '175px' }}>
                        { this.props.article.img ? <Image src={this.props.article.img} style={{position: 'absolute', top: '0px', right: '0px', height: '200px', width: '100%', maxWidth: '600px', objectFit: 'cover'}}/> : null }
                        <div style={{ position: 'absolute', top: '0', right: '0', height: '200px', width: '100%', backgroundColor: 'rgba(0,0,0,.4)' }} />
                        <div style={{ display: 'flex', position: 'absolute', top: '20px', left: '20px', alignItems: 'center' }}>
                            <Image src={this.props.article.author_img} style={{ borderRadius: '50%', objectFit: 'cover', height: '30px', width: '30px' }}/>
                            <h6 style={{ fontSize: '12px', color: 'white', marginBottom: '0px', marginLeft: '8px' }}> {this.props.article.author} </h6>
                        </div>
                        <div style={{ zIndex: '1', position: 'relative', margin: this.props.preview ? '75px 0px 20px 5px' : '95px 0px 20px 5px' }}>
                            <h6 style={{ marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'white'}}> {this.props.article.title} </h6> 
                            <h6 style={{ fontSize: '14px', opacity: '.7', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'white' }}> {this.props.article.short_description} </h6> 
                        </div>
                    </CardContent>
                </CardFloat> }
            </div>
        )
    }
}

export default ArticleCard