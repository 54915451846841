import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { createUser } from '../actions/authActions'
import { CardContent, Fade } from '@material-ui/core'
import { CardFloat, Input, ModalButton, PageCard } from '../components'
import { Color } from '../util';

import google from '../images/google.svg'
import stnbly from '../images/sustainablyapp.svg'

class SignUp extends Component {
    constructor(props) {
        super(props)
        let params = new URLSearchParams(this.props.location.search)
        this.state = {
            email: '',
            password: '',
            confirmPassword: '',
            first_name: '',
            last_name: '',
            plan: params.get('p') || null,
            code: params.get('c') || null,
            success: false,
            error: {
                email: null
            },
            user: null
        }
    }
 
    handleChange = (variable, value) => {
        this.setState({ [variable] : value, error: { ...this.state.error, [variable]: null } })
    }

    handleKeyPress = (event) => {
        if (!this.state.user && event && event.key === 'Enter') return this.handleVerifyEmail()
        if (event && event.key === 'Enter') return this.handleLogin()
    }

    handleCreate = () => {
        this.props.createUser({ first_name: this.state.first_name, last_name: this.state.last_name, email: this.state.email.toLowerCase(), password: this.state.password }).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.setState({ error: { ...this.state.error, email: error.response.data.msg }})
        })
    }

    handleSuccess = () => {
        this.setState({ success: true })
        let searchParams = new URLSearchParams()
        if (this.state.plan) searchParams.append('p', this.state.plan)
        if (this.state.code) searchParams.append('c', this.state.code)
        this.props.history.push('/activate/checkout' + (searchParams.toString().length ? '?' + searchParams.toString() : ''))
    }

    handleError = (error) => {
        this.setState({ error: true, errorMessage: error })
        setTimeout(() => {
            this.setState({ error: false, errorMessage: null })
        }, 1000)
    }

    render() {
        return (
            <PageCard style={{ padding: '0px' }} title={'Sign Up'}>
                <div style={{ display: 'flex', justifyContent: 'center', height: '70px', borderBottom: '1px solid rgba(0,0,0,.1)'  }}>
                    <img alt='Sustainably Logo' src={stnbly} style={{ width: '50px' }} />
                </div>
                <div style={{ maxWidth: '450px', margin: 'auto', padding: '0px 20px 15px 20px' }}>
                    <div>
                        { /* <Backdrop transitionDuration={600} open={false} style={{ zIndex: '200', color: 'white' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-50px', justifyContent: 'center', alignItems: 'center' }}>
                                <h5> Activating your account </h5>
                                <CircularProgress size={40} style={{ color: 'white' }}/>
                            </div>
                        </Backdrop> */ }
                        <h6 style={{ textAlign: 'center', fontSize: '18px', marginTop: '20px' }}> Sign up for Sustainably </h6>
                        <div style={{ paddingBottom: '30px'}}>
                            <Fade in={true} key={'email'}>
                                <CardFloat style={{ marginBottom: '15px'}}>
                                    <CardContent style={{ paddingBottom: '15px' }}>
                                        <div style={{ display: 'flex' }}>
                                            <Input value={this.state.first_name} onChange={(event) => this.handleChange('first_name', event.target.value)} placeholder={'First Name'} style={{ width: '100%', marginRight: '10px' }}/>
                                            <Input value={this.state.last_name} onChange={(event) => this.handleChange('last_name', event.target.value)} placeholder={'Last Name'} style={{ width: '100%'}}/>  
                                        </div>
                                        <Input value={this.state.email} error={this.state.error.email} onFocus={() => this.setState({ error: { ...this.state.error, email: null }})} onChange={(event) => this.handleChange('email', event.target.value)} placeholder={'Email Address'} type='email' style={{ width: '100%'}}/>
                                        <Input value={this.state.password} onChange={(event) => this.handleChange('password', event.target.value)} placeholder={'Password'} type='password' style={{ width: '100%'}}/>
                                        { /* <Input value={this.state.confirmPassword} error={this.state.password !== this.state.confirmPassword} onKeyPress={this.handleKeyPress} type='password' onChange={(event) => this.handleChange('confirmPassword', event.target.value)} placeholder={'Confirm Password'} style={{ width: '100%'}}/> */ }
                                        <p style={{ textAlign: 'center', fontSize: '12px', margin: '15px 10px 0px 10px', opacity: '.7' }}> By creating an account, you agree to our <a href={'https://www.sustainablyapp.com/terms-and-condition'}>Terms & Conditions</a> and <a href={'https://www.sustainablyapp.com/privacy-policy'}>Privacy Policy</a></p>
                                        <ModalButton disabled={!this.state.password || !(this.state.email && this.state.email.includes('@')) || !this.state.first_name || !this.state.last_name} success={this.state.success} loading={this.props.auth.userCreateLoading} handleClick={this.handleCreate} label={'Create Account'} style={{ position: 'relative', margin: '20px 0px 0px 0px', width: '100%', bottom: '0px' }}/>
                                    </CardContent>
                                </CardFloat>
                            </Fade>
                            <p style={{ textAlign: 'center', marginBottom: '30px', paddingBottom: '30px', borderBottom: '1px solid rgba(0,0,0,.1)', position: 'relative'  }}>
                                <a href={'/login'} style={{ color: Color.PRIMARY }}> Already have an account? </a>
                                <p style={{ backgroundColor: 'white', position: 'absolute', bottom: '-28px', padding: '0px 15px 0px 15px', left: '50%', transform: 'translateX(-50%)' }}> Or </p>
                            </p>
                            <div style={{ padding: '0px 20px 0px 20px'}}>
                                <a href={process.env.NODE_ENV === 'production' ? `${process.env.PUBLIC_URL}/api/auth/google` : 'http://localhost:8000/api/auth/google'} style={{ textDecoration: 'none' }}>
                                    <ModalButton icon={<img alt='Google Logo' src={google} style={{ height: '20px' }}/>} label={'Sign up with Google'} style={{ backgroundColor: 'black', marginBottom: '0px' }}/>
                                </a>
                                {/* a href={'http://localhost:8000/api/auth/google'} style={{ textDecoration: 'none' }}>
                                    <ModalButton icon={<img src={facebook} style={{ height: '20px' }}/>} label={'Sign in with Facebook'} style={{ backgroundColor: 'rgb(3,156,229)' }}/>
                                </a> */ }
                            </div>
                        </div>
                    </div>
                </div>
            </PageCard>
        );
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        createUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUp))