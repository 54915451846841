//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
//Components and Pages
import { TextField, CircularProgress, Card, CardContent, Grow } from '@material-ui/core'
import { withRouter } from 'react-router-dom';
import { ModalSlide } from './'
import api from '../api'
//Images
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

class PasswordResetModal extends Component {
    constructor (props) {
        super(props)
        this.state = {
			new_password: '',
			verify_password: '',
			resetLoading: false
		}
    }

    handleChange = (question, answer) => {
        this.setState( { [question]: answer })
	}

    handlePasswordSubmit = () => {
		this.setState({ resetLoading: true })
        api.userResetPassword({ token: this.props.resetToken, new_password: this.state.new_password}).then((response) => {
			this.setState({ resetLoading: false })
			this.props.handleClose()
        }).catch((error) => {
			this.setState({ resetLoading: false })
			console.log(error)
			this.props.handleClose()
        })
    }

	render() {
		return (
			<ModalSlide backdrop in={this.props.open} direction='up'>
				<Card style={{ position: 'fixed', bottom: '0', right: '0', left: '0', maxWidth: '600px', marginRight: 'auto', marginLeft: 'auto',  maxHeight: '100%', overflowY: 'scroll', borderRadius: '25px 25px 0px 0px', zIndex: '1021', width: '100%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
					<CardContent>
						<h2 style={{ margin: '10px'}}> Reset Password </h2>
						<Card style={{margin: '5px', marginTop: '20px', borderRadius: '25px', marginBottom: '20px',  position: 'relative',  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09)'}}>
							<CardContent>
								<div style={{ margin: '10px'}}>
									<h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> New Password </h6> 
									<TextField 
										type="password"
										autoFocus
										value={this.state.new_password} 
										placeholder="New Password" 
										style={{ width: '60%'}}
										onChange = {(event) => {
										this.handleChange("new_password", event.target.value)}}/> 	
								</div>
								<div style={{ margin: '10px'}}>
									<h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Repeat Password </h6> 
									<TextField 
										type="password"
										error={this.state.verify_password !== this.state.new_password}
										value={this.state.verify_password}
										placeholder="Repeat Password" 
										style={{ width: '60%'}}
										onChange = {(event) => {
										this.handleChange("verify_password", event.target.value)}}/>                                    
								</div>
								<Grow in={this.state.new_password !== '' && this.state.verify_password !== '' && this.state.new_password === this.state.verify_password} style={{ position: 'absolute', right: '10%', top: '50px'}}>
									<div style={{ color: '#3f50b5'}} onClick = {this.handlePasswordSubmit}>
										<p> Reset </p>
										{this.state.resetLoading ? <CircularProgress style={{ marginLeft: '10px' }}size={20} /> : <ArrowForwardIcon style={{ marginLeft: '10px', marginBottom: '10px'}}/>}
									</div>
								</Grow>
							</CardContent>
						</Card>
					</CardContent>
				</Card>
			</ModalSlide>
		)
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
		sbar: state.sbar
	}
}

export default connect(mapStateToProps)(withRouter(PasswordResetModal))
