import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { Color } from '../util';

const Bar = styled.div`
    position: absolute;
    width: ${(props) => props.mobile ? '100%' : '3px'};
    height: ${(props) => props.mobile ? '3px' : '100%'};
    top: ${(props) => props.mobile ? '-1px' : '0px'};
    left: ${(props) => props.mobile ? '0px' : ''};
    right: ${(props) => props.mobile ? '' : '-1px'};
    border-radius: ${(props) => props.mobile ? '0px 0px 3px 3px' : '3px 0px 0px 3px'};
    background-color: ${Color.PRIMARY};
    animation: ${(props) => props.mobile ? 'fadein' : 'fadeinvertical'} .3s;

    @keyframes fadein {
        from { 
            transform: scaleX(0);
        }
        to   { 
            transform: scaleX(1);
        }
    }

    @keyframes fadeinvertical {
        from { 
            transform: scaleY(0);
        }
        to   { 
            transform: scaleY(1);
        }
    }
`

const Icon = styled.div`
    margin: 11.5px;
    transition: color .3s ease;
    padding: 0px 5px 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.active ? 'black': 'rgba(0,0,0,.4)'};
`

function AppBarElement(props){
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)
        return () => { window.removeEventListener('resize', handleWindowResize) }
    }, [])

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth)
    }

    const isActive = () => {
        if (props.includes && props.includes.includes(props.realPath)) return true
        if (props.exact) return props.realPath === props.path
        return props.realPath.includes(props.path)
    }

    const handleClick = () => {
        props.history.push(props.path)
    }

    return (
        <div style={{ flexGrow: '1', position: 'relative', cursor: 'pointer' }} onClick={handleClick}>
            { /* isActive() ? <Bar mobile={windowWidth < 600}/> : null */ }
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Icon active={isActive()}>
                    { props.icon }
                </Icon>
                <div style={{ backgroundColor: Color.PRIMARY, position: 'absolute', bottom: '2px', width: !isActive() ? '0px' : '16px', height: '4px', borderRadius: '2px', transition: 'all 300ms ease' }}/>
            </div>
        </div>
    )
}

export default AppBarElement;