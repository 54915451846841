//Classes and Functions
import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
//Components and Pages
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Grow } from '@material-ui/core';
import { ArticleCard, BackButton, PageCard, PageLoading, StickyHeader } from '../components'
import { getArticles } from '../actions/dashboardActions'
import { Skeleton } from '@material-ui/lab'

class Articles extends Component {
    constructor (props) {
        super(props)
		this.state = {
            time: new Date(),
            articleOpen: false,
            article_id: null,
            noMore: false,
            loading: false
		}
    }

    componentDidMount() {
        this.timeClock = setInterval(this.tick, 1000)
        if (!this.props.dash.articles.length) this.props.getArticles({ type: 'article' }, true, 5)
    }  

    componentWillUnmount() { 
        clearInterval(this.timeClock)
        document.body.style.overflow = 'unset';
    }

    tick = () => {
        this.setState({ time: new Date() })
    }

    handleArticleOpen = (article_id) => {
        this.props.history.push(`/dashboard/articles/${article_id}`)
    }

    getMoreArticles = (node) => {
        if (!this.state.noMore && !this.props.dash.articleLoading) {
            if (this.botObserver.current) this.botObserver.current.disconnect()
            this.botObserver.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting) {
                    console.log('Loading More Articles...')
                    this.props.getArticles({ type: 'article' }, true, 5, undefined, this.props.dash.articles.length)
                }
            })
            if (node) this.botObserver.current.observe(node)
        }
    }

    botObserver = createRef(null)
    botArticle = this.getMoreArticles.bind(this)

    renderArticles = (max, disabled) => {
        let articles = this.props.dash.articles
        articles.sort((a,b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
        let cards = []

        for (let i = 0; i < this.props.dash.articles.length; i++) {
            if (max && i > max -1) break
            cards.push(<div key={i} ref={this.props.dash.articles.length - 1 === i && !this.props.preview ? this.botArticle : null } >
                <ArticleCard style={{ marginBottom: max ? '10px' : '20px' }} article={this.props.dash.articles[i]} handleOpen={!disabled ? this.handleArticleOpen : null}/>
            </div>)
        }
        
        if (!cards.length) cards.push(<Grow in={true} key={articles.length}>
            <Skeleton style={{ height: '170px', borderRadius: '25px', margin: '15px 5px 15px 5px' }}/>
        </Grow>)

        return cards
    }

    renderOverview = () => {
        return (
            <PageCard title={'Articles'} style={{ paddingTop: '0px', marginTop: '-15px' }}>
                { this.renderArticles() }
            </PageCard>
        )
    }

    render() {        
        if (this.props.preview) return (
            this.renderArticles(this.props.max, true)
        )
        return (
            <div>
                <PageLoading loading={this.props.dash.articleLoading} direction={'up'}/>
                <StickyHeader>
                    <h5 style={{ marginBottom: '0px' }}> Articles </h5>
                </StickyHeader>
                <BackButton/>
                { this.renderOverview() }
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        getArticles 
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Articles))
