import { useState } from "react"
import { ChipSelector, Input, ModalButton } from "../components"
import api from '../api'

const AdminUtilities = () => {
    // Address information
    const [addressLineOne, setAddressLineOne] = useState(null)
    const [addressLineTwo, setAddressLineTwo] = useState(null)
    const [country, setCountry] = useState(null)
    const [state, setState] = useState(null)
    const [city, setCity] = useState(null)
    const [postalCode, setPostalCode] = useState(null)

    // Contact information
    const [name, setName] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [email, setEmail] = useState(null)

    // Charge information
    const [total, setTotal] = useState(null)
    
    // Form state information
    const [mode, setMode] = useState('live')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [response, setResponse] = useState(null)
    const [responseMode, setResponseMode] = useState(null)
 
    // Controller function for each input state
    const handleChange = (callback) => {
        return (e) => {
            callback(e.target.value)
        }
    }
    
    // Handle the affirm submission
    const handleSubmit = async () => {
        const callback = mode === 'sandbox' ? api.createAffirmCheckoutSandbox : api.createAffirmCheckout
        try {
            setLoading(true)
            setResponse(null)
            setError(null)
            const response = await callback({
                billing: {
                    name: {
                        full: name
                    },
                    address: {
                        city,
                        state,
                        country,
                        line1: addressLineOne,
                        line2: addressLineTwo,
                        zipcode: postalCode
                    },
                    email,
                    phone_number: phoneNumber
                },
                total: Math.round(Number(total) * 100)
            })

            setResponse(response)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
            setResponseMode(mode)
        }
    }

    // Determine if the submit should be disabled
    const isError = () => {
        return !name
            || !email
            || !phoneNumber
            || !addressLineOne
            || !country
            || !city
            || !state
            || !postalCode
            || !total
    }

    // Render the utility
    return (
        <div>
            <h6> Send affirm checkout link </h6>
            <div className="flex flex-col gap-4">
                <ChipSelector value={mode} handleChange={setMode} options={[
                    {
                        label: 'Live',
                        value: 'live'
                    },
                    {
                        label: 'Sandbox',
                        value: 'sandbox'
                    }
                ]}/>
                <div className="w-[300px] flex flex-col gap-2">
                    <Input value={name} onChange={handleChange(setName)} label={'Name'}/>
                    <Input value={email} onChange={handleChange(setEmail)} label={'Email'}/>
                    <Input value={phoneNumber} onChange={handleChange(setPhoneNumber)} label={'Phone Number'}/>
                    <Input value={addressLineOne} onChange={handleChange(setAddressLineOne)} label={'Address Line 1'}/>
                    <Input value={addressLineTwo} onChange={handleChange(setAddressLineTwo)} label={'Address Line 2'}/>
                    <Input value={country} onChange={handleChange(setCountry)} label={'Country'}/>
                    <Input value={city} onChange={handleChange(setCity)} label={'City'}/>
                    <Input value={state} onChange={handleChange(setState)} label={'State'}/>
                    <Input value={postalCode} onChange={handleChange(setPostalCode)} label={'Postal Code'}/>
                    <Input value={total} onChange={handleChange(setTotal)} label={'Total (in dollars)'} pref={'$'} type='number'/>
                    <ModalButton 
                        disabled={isError()}
                        label={'Send Checkout'} 
                        loading={loading} 
                        handleClick={handleSubmit} 
                        style={{ position: 'relative', marginTop: '30px' }}/>
                    { response && <div className="bg-[#5ee47b] rounded-xl p-2 break-words">
                        Success ({responseMode}): {JSON.stringify(response.data)}
                    </div> }
                    { error && <div className="bg-[#eb9292] rounded-xl p-2">
                        Error ({responseMode}): {error.response.data.msg}
                    </div> }
                </div>
            </div>
        </div>
    )
}

export default AdminUtilities