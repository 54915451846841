import React, { useEffect, useState } from 'react';
import { Fade, LinearProgress, withStyles } from '@material-ui/core';
import { Color } from '../util'

const BorderLinearProgress = withStyles(theme => ({
	root: {
	  height: 3,
	},
	colorPrimary: {
		backgroundColor: 'white'
	},
	bar: {
        borderRadius: 5,
        backgroundColor: Color.PRIMARY
    }
  }))(LinearProgress);

function PageLoading(props) {
    const [loading, setLoading] = useState(false)
    let closeHandler = null

    useEffect(() => {
        setLoading(props.loading)
        return () => clearTimeout(closeHandler)
    }, [])

    useEffect(() => {
        if (props.loading) setLoading(true)
        if (!props.loading) closeHandler = setTimeout(handleClose, 1000)
    }, [props.loading])

    const handleClose = () => {
        setLoading(false)
    }

    return (
        <div style={{ position: 'fixed', bottom: '0px', left: '0px', width: '100%', zIndex: '1000' }}>
            <Fade in={loading} direction={props.direction} timeout={300}>
                <div>
                    <BorderLinearProgress/>
                </div>
            </Fade>
        </div>
    );
}

export default PageLoading;