import React, { useState } from 'react'
import { Skeleton } from '@material-ui/lab'
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js'

const PaymentRequestLoad = (props) => { 
    const [inputLoaded, setInputLoaded] = useState(false)
    return (
        <div>
            <div style={{ display: !inputLoaded ? 'none' : null, width: '100%' }}>
                <PaymentRequestButtonElement options={props.options} onReady={() => setInputLoaded(true)}/>
            </div>
            <Skeleton variant={'rect'} animation={'wave'} style={{ height: '40px', width: '100%', display: inputLoaded ? 'none' : null, borderRadius: '5px' }}/>
        </div>
    )
}

export default PaymentRequestLoad