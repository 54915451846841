import React, { useState } from 'react'
import { Skeleton } from '@material-ui/lab'

const Video = (props) => { 
    const [videoLoaded, setVideoLoaded] = useState(false)
    return (
        <div>
            <iframe title='guideVideo' style={{ ...props.style, backgroundColor: 'black', display: !videoLoaded ? 'none' : null }} onLoad={() => setVideoLoaded(true)} src={props.src} frameBorder="0" allow="autoplay; fullscreen"/>
            <Skeleton variant={'rect'} animation={'wave'} style={{...props.style, ...props.loadingStyle, display: videoLoaded ? 'none' : null }}/>
        </div>
    )
}

export default Video