import React from 'react'
import { Modal } from '.'
import { Card, CardContent } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

const AttributeModal = (props) => {
    return (
        <Modal direction="up" in={props.open} head={props.label} handleClose={props.handleClose}>
            { props.options.map((option, i) => {
                return (<Card key={i} style={{margin: '5px', marginTop: '20px', borderRadius: '25px', marginBottom: '20px',  position: 'relative',  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09)', backgroundColor: props.value.includes(option) ? 'grey' : null, opacity: props.value.includes(option) ? '.7' : '1', cursor: 'pointer'}} onClick={() => props.handleChange(props.name, props.value.includes(option) ? props.value.filter((ans) => ans !== option) : [...props.value, option])}>
                    <CardContent> 
                        <h6 style={{ fontSize: '14px'}}> {option} </h6> 
                        { props.value.includes(option) ? <RemoveIcon style={{ position: 'absolute', right: '25px', top: '27%'}}/> : <AddIcon style={{ position: 'absolute', right: '25px', top: '27%'}}/> }
                    </CardContent>
                </Card>)
            })}
        </Modal>
    )
}

export default AttributeModal