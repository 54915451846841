import React, { useState } from 'react'
import { Color } from '../util'
import { Link } from 'react-router-dom'

const Naventry = (props) => {
    const [hover, setHover] = useState(false)
    let currentPath = props.exact ? props.location.pathname === props.path : props.location.pathname.includes(props.path)

    return (
        <Link to={props.path} style={{ color: 'inherit', outline: 'none', textDecoration: 'none' }}>
            <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ backgroundColor: currentPath ? Color.PRIMARY : ( hover ? Color.PRIMARY : null ), color: currentPath? 'white' : ( hover ? 'white' : Color.PRIMARY), padding: '5px 5px 5px 10px', borderRadius: '0px 10px 10px 0px', margin: '5px 10px 5px 0px', opacity: '.8', transition: 'background-color 0.3s ease, color 0.3s ease'}}>
                <p style={{ marginBottom: '0px'}}> { props.label } </p>
            </div>
        </Link>
    )
}

export default Naventry