//Classes and Functions
import React, { Component } from 'react'
//Components and Pages
import { Popover, Typography } from '@material-ui/core'
//Images
import InfoIcon from '@material-ui/icons/Info';


const leftBottomStyles = {
    position: 'absolute', left: '10px', bottom : '10px', opacity: '.5'
}

const rightBottomStyles = {
    position: 'absolute', right: '10px', bottom : '10px', opacity: '.5'
}

const leftTopStyles = {
    position: 'absolute', left: '10px', top : '10px', opacity: '.5'
}

const rightTopStyles = {
    position: 'absolute', right: '10px', top : '10px', opacity: '.5'
}

class InfoPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            info: false,
            infoEl: null
        }
    }

    getStyles = () => {
        if (this.props.left) {
            if (this.props.top) {
                return leftTopStyles
            } else {
                return leftBottomStyles
            } 
        } else {
            if (this.props.top) {
                return rightTopStyles
            } else {
                return rightBottomStyles
            }
        }
    }

    handleInfoOpen = (event) => {
        event.preventDefault()
        event.stopPropagation()

        this.setState({ infoEl: event.currentTarget, info: !this.state.info })
    }

    render() {
        return (
            <div>
                <InfoIcon style={{ ...this.getStyles(), cursor: 'pointer' }} onClick={(event) => this.props.handleOpen ? this.props.handleOpen(event) : this.handleInfoOpen(event)}/>
                <Popover anchorEl={this.state.infoEl} open={this.state.info} onClose={(event) => this.handleInfoOpen(event)}> <Typography style={{ borderRadius: '25px', padding: '10px'}}> {this.props.info} </Typography> </Popover>
            </div>
        )
    }
}

export default InfoPopup