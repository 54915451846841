const initialState = {
    msg: null,
    type: null,
    snackbar: false
}

export default function snackbarReducer(state = initialState, action) {
    switch(action.type) {
        case "SET_SNACKBAR":
            return {
                ...state,
                type: action.payload.type,
                msg: action.payload.msg,
                status: action.payload.status,
                snackbar: true
            }
        case "CLEAR_SNACKBAR":
            return {
                ...state,
                snackbar: false
            }

        default:
            return state
    }
}
