import React, { Component } from 'react';
import { connect } from 'react-redux'
import { CardContent } from '@material-ui/core'
import { Modal, CardFloat, BodyFatCard } from './'
import { Measure, months, days } from '../util'

class WeighinInfoModal extends Component {
    getMeasure = (type, num) => {
        if (this.props.auth.user) {
            return Math.round( num / Measure[this.props.auth.user.settings.unit][type].mult  * 10) / 10
        } else {
            return 0
        }
    }

    getMostRecent = (time, category) => {
        let history = this.props.dash.history
        history.sort((a, b) => b.time - a.time)

        for (let date in history) {
            if (history[date].time < time && history[date][category]) return history[date]
        }

        return undefined
    }

    render() {
        let time = this.props.infoSession ? new Date(this.props.infoSession.time) : undefined, head = ''
        switch (this.props.infoSession ? this.props.infoSession.type : null) {
            case ('recalibration') : {
                head ='Recalibration'
                break
            }

            case ('weigh_in') : {
                head = 'Weigh-in'
                break
            }

            case ('initial_weigh_in') : {
                head = 'Initial Weigh-in'
                break
            }

            default : {
                head = 'Weigh-in'
            }
        }

        let recentWaist = this.getMostRecent(this.props.infoSession ? this.props.infoSession.time : 0, 'waist_size')
        let recentWeight = this.getMostRecent(this.props.infoSession ? this.props.infoSession.time : 0, 'weight')
        let recentBodyfat = this.getMostRecent(this.props.infoSession ? this.props.infoSession.time : 0, 'body_fat')

        return (
            <Modal direction='up' in={this.props.open} handleClose={this.props.handleClose} image_id={this.props.infoSession ? this.props.infoSession.images : null} head={head}>
                { this.props.infoSession ? 
                    <div>
                        <h6 style={{ opacity: '.7', marginBottom: '10px' }}> {days[time.getDay()]}, {months[time.getMonth()]} {time.getDate()} </h6>
                        <div style={{ display: 'flex'}}>
                            { this.props.infoSession.weight && recentWeight ? 
                                <div style={{ width: '33%', marginTop: '10px' }}>
                                    <h6 style={{ marginBottom: '0px' }}> Weight </h6>
                                    <CardFloat style={{ marginBottom: '0px', marginTop: '10px' }}>
                                        <CardContent>
                                            <div>
                                                <h5 style={{ marginBottom: '0px', color: this.props.infoSession.weight - (recentWeight ? recentWeight.weight : 0) > 0 ? 'red' : this.props.infoSession.weight - (recentWeight ? recentWeight.weight : 0) === 0 ? null : 'green' }}> {this.getMeasure('mass', this.props.infoSession.weight - (recentWeight ? recentWeight.weight : 0))} {Measure[this.props.auth.user ? this.props.auth.user.settings.unit : '']['mass'].unit} </h5>
                                                <p style={{ opacity: '.5', marginBottom: '0px' }}> {this.getMeasure('mass', this.props.infoSession.weight)} </p>
                                            </div>
                                        </CardContent>
                                    </CardFloat> 
                                </div> : null }
                            { this.props.infoSession.waist_size && recentWaist ? 
                                <div style={{ width: '33%', marginTop: '10px' }}>
                                    <h6 style={{ marginBottom: '0px' }}> Waist </h6>
                                    <CardFloat style={{ marginBottom: '0px', marginTop: '10px' }}>
                                        <CardContent>
                                            <div>
                                                <h5 style={{ marginBottom: '0px', color: this.props.infoSession.waist_size - (recentWaist ? recentWaist.waist_size : 0) > 0 ? 'red' : this.props.infoSession.waist_size - (recentWaist ? recentWaist.waist_size : 0) === 0 ? null : 'green' }}> {this.getMeasure('length', this.props.infoSession.waist_size - (recentWaist ? recentWaist.waist_size : 0))} {Measure[this.props.auth.user ? this.props.auth.user.settings.unit : '']['length'].unit} </h5>
                                                <p style={{ marginBottom: '0px', opacity: '.5'}}> {this.getMeasure('length', this.props.infoSession.waist_size)} </p>
                                            </div>
                                        </CardContent>
                                    </CardFloat> 
                                </div> : null }
                            { this.props.infoSession.waist_size && recentWaist ? 
                                <div style={{ width: '33%', marginTop: '10px' }}>
                                    <h6 style={{ marginBottom: '0px' }}> Body Fat </h6>
                                    <CardFloat style={{ marginBottom: '0px', marginTop: '10px' }}>
                                        <CardContent>
                                            <div>
                                                <h5 style={{ marginBottom: '0px', color: this.props.infoSession.body_fat - (recentWaist ? recentBodyfat.body_fat : 0) > 0 ? 'red' : this.props.infoSession.body_fat - (recentBodyfat ? recentBodyfat.body_fat : 0) === 0 ? null : 'green' }}> {Math.round((this.props.infoSession.body_fat - (recentBodyfat ? recentBodyfat.body_fat : 0)) * 100)}% </h5>
                                                <p style={{ marginBottom: '0px', opacity: '.5'}}> {Math.round(this.props.infoSession.body_fat * 100)} </p>
                                            </div>
                                        </CardContent>
                                    </CardFloat> 
                                </div> : null }
                        </div>
                        <BodyFatCard foreground={{ gender: this.props.auth.user.gender, body_fat: this.props.infoSession.body_fat }} background={ recentBodyfat ? { gender: this.props.auth.user.gender, body_fat: recentBodyfat } : undefined }/>
                    </div> : null
                }
            </Modal>
        );
    }
}

function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

export default connect(mapStateToProps)(WeighinInfoModal)
