import React,  { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createBugReport } from '../actions/dashboardActions'
import { CardContent, TextField, MenuItem } from '@material-ui/core'
import { Modal, CardFloat, ModalButton } from './'

const options = ['Dashboard', 'Nutrition', 'Fitness', 'Overview', 'Body Stats', 'Settings', 'Articles', 'University', 'Profile', 'Other']
class BugModal extends Component {
    constructor(props) {
        super(props)
        this.state = this.initializeState()
    }

    initializeState = () => {
        return {
            feature: 'Profile',
            details: '',
            success: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            let path = this.getPath(this.props.location.pathname)
            this.setState({ feature: options.includes(path) ? path : 'Other' })
        }

        if (this.props.dash.bugReport !== prevProps.dash.bugReport) this.setState(this.initializeState)
    }

    handleChange = (variable, value) => {
        this.setState({ [variable] : value })
    }

    createReport = () => {
        this.props.createBugReport({ feature: this.state.feature, details: this.state.details }).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.handleError(error)
        })
    }

    handleSuccess = () => {
        this.setState({ success: true })
        setTimeout(this.props.handleClose, 400)
    }

    handleError = () => {

    }

    considerSubmit = () => {
        if (this.state.feature !== '' && this.state.details !== '') return true
        return false 
    }

    render() {
        return (
            <Modal direction="up" in={this.props.open} handleClose={this.props.handleClose} head={'Report a Bug'}>
                <CardFloat>
                    <CardContent>
                        <h6> What happened? </h6>
                        <div style={{ margin: '10px'}}>
                            <div style={{ width: '80%' }}> 
                                <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Feature </h6> 
                                <TextField 
                                    select
                                    style={{ marginBottom: '10px', width: '100%' }}
                                    placeholder="Servings"
                                    value={this.state.feature}
                                    onChange = {(event) => {
                                    this.handleChange("feature", event.target.value)}}>
                                        { options.map((portion, i) => {
                                            return <MenuItem key={i} value={portion}> {portion} </MenuItem>
                                        })}
                                </TextField>
                            </div>
                        </div>
                        <div style={{ margin: '10px'}}>
                            <div style={{ width: '80%' }}> 
                                <h6 style={{ fontSize: '14px', marginBottom: '5px', opacity: '.8'}}> Details </h6> 
                                <TextField 
                                    style={{ marginBottom: '10px', width: '100%' }}
                                    value={this.state.details}
                                    onChange = {(event) => this.handleChange('details', event.target.value)} 
                                    placeholder="Explain"
                                    multiline/>
                            </div>
                        </div>
                    </CardContent>
                </CardFloat>
                <ModalButton success={this.state.success} disabled={!this.considerSubmit()} loading={this.props.dash.bugReportLoading} handleClick={this.createReport} label={'Submit Report'}/>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash
	}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createBugReport
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BugModal)