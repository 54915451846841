import { CardContent, Fade } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { CardFloat, HelpButton, Infographic, Modal, PageCard, Image, ChangeAvatarModal, BugModal, StickyHeader } from '../components';
import { bindActionCreators } from 'redux'
import { signout } from '../actions/authActions'
import { Link } from 'react-router-dom'

import LinkIcon from '@material-ui/icons/Link';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIosRounded';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { Color, months } from '../util';

import { MdSettings, MdNotifications, MdAnnouncement, MdMonitorWeight, MdReport, MdExitToApp, MdAdminPanelSettings } from 'react-icons/md'
import NutritionInfographic from '../components/NutritionInfographic';

const Divider = (props) => {
    return <div style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}/>
}

const Group = (props) => {
    return <Fade in={true}>
        <div style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h6 style={{ opacity: '.7' }}> { props.name } </h6>
                { props.button }
            </div>
            { 
                props.children.length ? props.children.map((child, i) => {
                    return child ? <div>
                        { child }
                        { i < props.children.length - 1 ? <Divider/>: null }
                    </div> : null
                }) : props.children
            }
        </div>
    </Fade>
}

const Line = (props) => {
    return <div style={{ height: '45px', width: '100%', borderRadius: '12px', padding: '2px', display: 'flex', alignItems: 'center', margin: '2px 0px 2px 0px' }} onClick={props.onClick}>
        <div style={{ borderRadius: '50%', backgroundColor: props.color || Color.PRIMARY, width: '28px', height: '28px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}> 
            { props.icon }
        </div>
        <p style={{ margin: '0px 0px 0px 10px' }}> {props.name} </p>
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <h6 style={{ opacity: '.7', margin: '0px 5px 0px 0px'}}> {props.value} </h6>
            <ArrowForwardIosIcon style={{ fontSize: '14px', opacity: '.7' }} />
        </div>
    </div>
}

class Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            notificationsOpen: false,
            updatesOpen: false,
            avatarOpen: false,
            bugOpen: false
        }
    }

    handleNotificationsOpen = () => {
        this.setState({ notificationsOpen: true })
    }

    handleNotificationsClose = () => {
        this.setState({ notificationsOpen: false })
    }

    handleUpdatesOpen = () => {
        this.setState({ updatesOpen: true })
    }

    handleUpdatesClose = () => {
        this.setState({ updatesOpen: false })
    }

    handleAvatarOpen = () => {
        this.setState({ avatarOpen: true })
    }

    handleAvatarClose = () => {
        this.setState({ avatarOpen: false })
    }

    handleBugOpen = () => {
        this.setState({ bugOpen: true })
    }

    handleBugClose = () => {
        this.setState({ bugOpen: false })
    }

    renderContent = (notification) => {
        return (
            <div>
                { notification.head ? <h6> {notification.head} </h6> : null }
                { notification.msg ? <h6 style={{ fontSize: '14px', opacity: '.7'}}> {notification.msg} </h6> : null }
            </div>
        )
    }

    render() {
        let date = new Date(this.props.auth.user ? this.props.auth.user.created_at : Date.now())
        return (
            <div>
                <StickyHeader>
                    <h5 style={{ marginBottom: '0px' }}> Profile </h5>
                    <HelpButton/>
                </StickyHeader>
                <PageCard title={'Profile'} style={{ paddingTop: '0px' }}>
                    <div>
                        <div style={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
                            <div style={{ position: 'relative' }} onClick={this.handleAvatarOpen}>
                                { !this.props.auth.user.avatar ? <AccountCircleIcon style={{ fontSize: '50px', opacity: '.6' }}/> : <Image src={this.props.auth.user.avatar} style={{ width: '60px', height: '60px', borderRadius: '50%', objectFit: 'cover' }}/> }
                                <div style={{ position: 'absolute', bottom: '-5px', right: '-5px', borderRadius: '50%', backgroundColor: Color.PRIMARY, fontSize: '0px', padding: '7px', boxShadow: '0 6px 20px 10px rgba(0, 0, 0, 0.1)'}}>
                                    <AddAPhotoIcon style={{ fontSize: '16px', color: 'white'}}/>
                                </div>
                            </div>
                            <div style={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <h6 style={{ marginBottom: '0px' }}> {this.props.auth.user.first_name} </h6>
                                    <h6 style={{ fontSize: '14px', opacity: '.7', marginBottom: '0px' }}> {this.props.auth.user.last_name} </h6>
                                </div>
                            </div>
                            <p style={{ fontSize: '12px', opacity: '.7', marginBottom: '0px', textAlign: 'right', marginLeft: 'auto' }}> Started {months[date.getMonth()]} {date.getDate()}, {date.getFullYear()} </p>
                        </div>
                    </div>
                    <NutritionInfographic/>
                    <Infographic type={[ {value: 'weight', label: 'Weight'}, { value: 'waist_size', label: 'Waist size'}, { value: 'body_fat', label: 'Body fat'}]} title={[{ type: 'body_fat', title: 'Body Fat'}, { type: 'weight', title: 'Weight' }, { type: 'waist_size', title: 'Waist Size' }]} buttons/>  
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Group>
                                    <Line name={'Settings'} icon={<MdSettings/>} onClick={() => this.props.history.push('/dashboard/profile/settings', { from: 'Profile' })}/>
                                    <Line name={'Body Stats'} icon={<MdMonitorWeight/>} onClick={() => this.props.history.push('/dashboard/profile/stats', { from: 'Profile' })}/>
                                    { this.props.auth.user.admin ? <Line name={'Admin'} icon={<MdAdminPanelSettings/>} onClick={() => this.props.history.push('/dashboard/admin')}/> : null }
                                    <Line name={'Notifications'} color={Color.FAT} icon={<MdNotifications/>} onClick={this.handleNotificationsOpen}/>
                                    <Line name={'Updates'} color={Color.FAT} icon={<MdAnnouncement/>} onClick={this.handleUpdatesOpen}/>
                                    <Line name={'Report a Bug'} color={Color.FAT} icon={<MdReport/>} onClick={this.handleBugOpen}/>
                                    <Line name={'Sign Out'} color={Color.CARBS} icon={<MdExitToApp/>} onClick={this.props.signout}/>
                                </Group>
                                {/*<Link to={'/dashboard/profile/settings'} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
                                        <SettingsIcon style={{ opacity : '.7'}}/>
                                        <h6 style={{ margin: '0px 0px 0px 20px' }}> Settings </h6>
                                    </div>
                                </Link>
                                <Divider/>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={this.handleNotificationsOpen}>
                                    <div style={{ position: 'relative' }}>
                                        <NotificationsIcon style={{ opacity : '.7'}}/>
                                        { this.props.dash.notifications.length ? <svg height = '16' width= '16' style={{ position: 'absolute', right: '-5px', top: '-3px', zIndex: '999'}}>
                                            <circle cx="8" cy="8" r="8" fill="red"/>
                                            <text x="50%" y="50%" textAnchor="middle" fill="white" fontSize="10px" dy=".3em"> {this.props.dash.notifications.length} </text>
                                        </svg> : null }
                                    </div>
                                    <h6 style={{ margin: '0px 0px 0px 20px' }}> Notifications </h6>
                                </div>
                                <Divider/>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={this.handleUpdatesOpen}>
                                    <AnnouncementIcon style={{ opacity : '.7'}}/>
                                    <h6 style={{ margin: '0px 0px 0px 20px' }}> Updates </h6>
                                </div>
                                <Divider/>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={this.handleBugOpen}>
                                    <BugReportIcon style={{ opacity : '.7'}}/>
                                    <h6 style={{ margin: '0px 0px 0px 20px' }}> Bug Reports </h6>
                                </div>
                                <Divider/>
                                { this.props.auth.user && this.props.auth.user.admin ? <Link to={'/dashboard/admin'} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
                                        <SupervisorAccountIcon style={{ opacity : '.7'}}/>
                                        <h6 style={{ margin: '0px 0px 0px 20px' }}> Admin </h6>
                                    </div>
                                </Link> : null } 
                                <Divider/>
                                <Link to={'/dashboard/profile/stats'} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
                                        <AccessibilityNewIcon style={{ opacity : '.7'}}/>
                                        <h6 style={{ margin: '0px 0px 0px 20px' }}> Body Stats </h6>
                                    </div>
                                </Link>
                                <Divider/>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }} onClick={this.props.signout}>
                                    { !this.props.auth.signingOut ? <ExitToAppIcon style={{ opacity : '.7'}}/> : <CircularProgress size={24}/> }
                                    <h6 style={{ margin: '0px 0px 0px 20px' }}> Sign Out </h6>
        </div>*/}
                            </div>
                    <Modal head={`${this.props.dash.notifications.length} Notification${this.props.dash.notifications.length !== 1 ? 's' : ''}`} in={this.state.notificationsOpen} handleClose={this.handleNotificationsClose} direction='up'>
                        { this.props.dash.notifications.length ? this.props.dash.notifications.map((notification, i) => {
                            return <div key={i}>
                                { notification.route && !notification.route.pathname.startsWith('http') ? <Link to={notification.route} style={{ textDecoration: 'none' }}>
                                    <CardFloat>
                                        <CardContent>
                                            <div>
                                                { this.renderContent(notification) }
                                                <ArrowForwardIosIcon style={{ position: 'absolute', right: '25px', top: '50%', opacity: '.5', fontSize: '20px', transform: 'translateY(-50%)'}}/>
                                            </div>
                                        </CardContent>
                                    </CardFloat>
                                </Link> : (notification.route && notification.route.pathname.startsWith('http') ? <a style={{ textDecoration: 'none' }} href={notification.route.pathname} target="_blank" rel="noopener noreferrer">
                                    <CardFloat>
                                        <CardContent>
                                            <div style={{ position: 'relative'}}>
                                                { this.renderContent(notification) }
                                                <LinkIcon style={{ position: 'absolute', right: '0px', top: '0px', opacity: '.5'}}/>
                                            </div>
                                        </CardContent>
                                    </CardFloat>
                                </a> : <CardFloat>
                                        <CardContent>
                                            { this.renderContent(notification) }
                                        </CardContent>
                                    </CardFloat>)}
                            </div>
                        }) : <h6 style={{ opacity: '.7', textAlign: 'center', marginTop: '30px'}}> No Notifications </h6> }
                    </Modal>
                    <Modal direction='up' head={'Updates'} in={this.state.updatesOpen} handleClose={this.handleUpdatesClose}>
                        <h6 style={{ opacity: '.7', textAlign: 'center', marginTop: '30px'}}> No Updates </h6>
                    </Modal>
                    <ChangeAvatarModal open={this.state.avatarOpen} handleClose={this.handleAvatarClose}/>
                    <BugModal open={this.state.bugOpen} handleClose={this.handleBugClose}/>
                </PageCard> 
            </div>
        );
    }
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        signout,
     }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)