import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { mountUser } from '../actions/authActions'
import { connect } from 'react-redux'
import { Grow, CircularProgress, TextField, CardContent } from '@material-ui/core'
import { CardProviders, Image, CardElementLoad, CardFloat, ModalButton } from '../components'
import { Alert } from '@material-ui/lab'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import StripeBadge from '../images/powered_by_stripe3.svg'
import api from '../api'
import Input from './Input'

class ChangePaymentMethod extends Component {
    constructor (props) {
        super(props)
        this.state = {
            status: "idle",
            tab : {
                first_name: {
                    answer: "",
                    type: "none",
                    err: false
                },
                last_name: {
                    answer: "",
                    type: "none",
                    err: false
                },
                email: {
                    answer: "",
                    type: "email",
                    err: false
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open === true && this.props.open === false) {
            document.body.style.overflow = 'unset';
        }
        
        if (prevProps.open === false && this.props.open === true) {
            document.body.style.overflow = 'hidden';
        }
    }

    handleChange = (question, answer) => {
		this.setState({ tab : {...this.state.tab, [question] : { ...this.state.tab[question], answer} } })
	}

    verifyQuestion = (question) => {
		switch (question.type) {
			case ("email") : {
				if (question.answer.includes('@')) {
					return true
				}
				break
			}

			default : {
				if (question.answer !== "") {
					return true
				}
			}
		}
		return false
    }
    
    handleError = (question, error) => {
		this.setState({ tab : {...this.state.tab, [question] : { ...this.state.tab[question], err: error} } })
    }

	verifyEntries = (questions) => {
		let valid = 0, count = 0
		for (let property in questions) {
			if (this.verifyQuestion(questions[property])) valid += 1
			count += 1
		} 

		return Math.round(valid / count * 100)
    }

    componentDidMount() {
        this.setState({ 
            tab : { 
                email: {...this.state.tab.email, answer: this.props.auth.user.email}, 
                first_name: {...this.state.tab.first_name, answer: this.props.auth.user.first_name}, 
                last_name: {...this.state.tab.last_name, answer: this.props.auth.user.last_name}
            }
        })
    }

    handleUpdatePaymentMethod = async () => {
        if (this.verifyEntries(this.state.tab) !== 100) {
            this.error = setInterval(this.errorTick, 1000)
            this.setState({ status: "error", errorMessage: "Missing Fields" })
            return
        }

        this.setState({ status: "loading" })
        this.props.stripe.createPaymentMethod({
            type: 'card',
            card: this.props.elements.getElement('card'),
            billing_details : {
                name: `${this.state.tab.first_name.answer} ${this.state.tab.last_name.answer}`,
                email: this.state.tab.email.answer
            }
        }).then((response) => {
            return api.stripeUpdatePaymentMethod({ 
                payment_method_id: response.paymentMethod.id 
            }, {
                headers : {
                    'Content-type': 'application/json',
                    'x-access-token' : localStorage.getItem('accessToken')
                },
                params : {
                    customer_id : this.props.auth.user.subscription.customer_id
                }
            })
        }).then((response) => {
            this.setState({ status: "success" })
            this.props.mountUser(response.data)
            this.success = setInterval(this.successTick, 1000)
        }).catch((error) => {
            this.error = setInterval(this.errorTick, 1000)
            this.setState({ status: "error", errorMessage: "Card Error"})
        })
    }

    errorTick = () => {
        clearInterval(this.error)
        this.setState({ status: "idle" })
    }

    successTick = () => {
        clearInterval(this.success)
        this.props.handleClose()
    }

    handleClick = () => {
        switch(this.state.status) {
            case ("idle") : {
                this.handleUpdatePaymentMethod()
            }

            // eslint-disable-next-line
            default : {
                return
            }
        }
    }

    getLabel = () => {
        switch(this.state.status) {
            case ("authorizing") : {
                return "Authorizing"
            }

            case ("loading") : {
                return "Processing"
            }

            case ("idle") : {
                return "Change Method"
            }

            case ("success") : {
                return "Success"
            }

            case ("error") : {
                return this.state.errorMessage
            }

            default : {
                return 'Unknown State'
            }
        }
    }

    renderState = () => {
        if (this.state.status === "loading" || this.state.status === "authorizing") {
            return (
                <Grow key={1} in={true}>
                    <CircularProgress size={18} style={{ position: 'absolute', right: '4px', bottom: '18px'}}/>
                </Grow>
            )
        } else if (this.state.status === "idle") {
            return (
                <Grow key={2} in={true}>
                    <ArrowForwardIosIcon fontSize='small' style={{ position: 'absolute', bottom: '18px', right: '3px' }}/>
                </Grow>
            )
        } else if (this.state.status === 'error') {
            return (
                <Grow key={3} in={true}>
                    <ClearIcon style={{ position: 'absolute', bottom: '16px', right: '3px'}}/>
                </Grow>
            )
        } else if (this.state.status === 'success') {
            return (
                <Grow key={4} in={true}>
                    <CheckIcon style={{ position: 'absolute', bottom: '16px', right: '3px'}}/>
                </Grow>
            )
        }
    }

    render() {
        return (
            <div>
                <CardFloat>
                    <CardContent style={{ padding: '8px' }}>
                        <div>
                            <div style={{ margin: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{ display: 'inline-block', margin: '5px 5px 0px 5px', width: '100%' }}> 
                                    <Input style={{ marginBottom: '10px', width: '100%'}} error={this.state.tab.first_name.err} onBlur={() => this.handleError("first_name", !this.verifyQuestion(this.state.tab.first_name))} value={this.state.tab.first_name.answer} placeholder="First Name" onChange = {(event) => {this.handleChange("first_name", event.target.value)}}/>
                                </div>
                                <div style={{ display: 'inline-block', margin: '5px 5px 0px 5px', width: '100%' }}> 
                                    <Input style={{ marginBottom: '10px', width: '100%'}} error={this.state.tab.last_name.err} onBlur={() => this.handleError("last_name", !this.verifyQuestion(this.state.tab.last_name))} value={this.state.tab.last_name.answer} placeholder="Last Name" onChange = {(event) => {this.handleChange("last_name", event.target.value)}}/>
                                </div>
                            </div>
                            <div style={{ margin: '0px 10px 10px 10px' }}>
                                <Input style={{ marginBottom: '15px', width: '100%'}} error={this.state.tab.email.err} onBlur={() => this.handleError("email", !this.verifyQuestion(this.state.tab.email))} value={this.state.tab.email.answer} placeholder="Email" onChange = {(event) => {this.handleChange("email", event.target.value)}}/>
                            </div>
                            <div style={{ margin: '10px'}}>
                                { this.props.open ? <CardElementLoad/> : null }
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CardProviders/>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                                    <Image alt="Stripe Authentication" style={{ width: '100px', opacity: '.8', margin: '10px' }} src={StripeBadge}/>
                                </div>
                            </div>                    
                        </div>
                    </CardContent>
                </CardFloat>
                <ModalButton handleClick={this.handleUpdatePaymentMethod} label={'Change Payment Method'} loading={this.state.status === 'loading' || this.state.status === 'authorizing'} error={this.state.status === 'error'} errorMessage={this.state.errorMessage} success={this.state.status === 'success'} style={{ position: 'relative', bottom: '0px', marginTop: '10px' }}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
		err: state.err
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        mountUser
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePaymentMethod)
