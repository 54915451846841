import { Image, PageCard, NutritionBar } from '../components'
import InfoIcon from '@material-ui/icons/Info';

const FeedFoodCard = (props: {
    food?: any
}) => {
    return (
        <div className='flex flex-col bg-white shadow-card p-3 rounded-xl relative h-[80%] gap-4'>
                <div className='rounded-xl overflow-hidden relative h-[100%]'>
                    <Image 
                        className='rounded-xl h-[80%] w-full object-cover'
                        src={"https://jimmysfarm.com/wp-content/uploads/2023/06/capybara-1200-800.jpg"}/>
                    <div className='absolute bottom-2 right-2 flex flex-col gap-2'>
                        <div className='bg-black w-8 h-8 rounded-full'/>
                        <div className='bg-black w-8 h-8 rounded-full flex items-center justify-center text-primary bg-white cursor-pointer'>
                            i
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <h5 className='text-xl m-0'> Testing the name of this field </h5>
                    <div className='flex'>
                        <div>
                            <div className='relative'>
                                <NutritionBar calories={200} protein={100} fat={10} carbs={50}/>
                            </div>
                        </div>
                        <div className='ml-auto'>
                            <p className='m-0 text-base'> 30 Min Total </p>
                            <p className='m-0 text-sm opacity-70'> 15 Min Prep </p>
                        </div>
                    </div>
                </div>
        </div>
    )
}


const FoodFeed = () => {
    return (
        <div className='h-[calc(100%-80px)] relative'>
            <PageCard>
                <FeedFoodCard/>
            </PageCard>
        </div>
    )
}

export default FoodFeed