import { authActionTypes } from '../actions/types'

const initialState = {
    isAuthenticated: false,
    isLoading: true,
    isLogging: false,
    updateProfileLoading: false,
    changePasswordLoading: false,
    recalibrationLoading: false,
    planLoading: false
}

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        //User Login Reducers
        case authActionTypes.LOGIN_LOADING : {
            return {
                ...state,
                isLogging: true
            }
        }

        case 'RESET_TOKEN' : {
            return {
                ...state,
                resetToken: action.payload
            }
        }

        case 'CLEAR_RESET_TOKEN' : {
            return {
                ...state,
                resetToken: undefined
            }
        }

        case authActionTypes.LOGIN_SUCCESS : {
            return {
                ...state,
                ...action.payload,
                id: action.payload.user._id,
                isAuthenticated: true,
                isLogging: false
            }
        }

        case authActionTypes.LOGIN_ERROR : {
            return {
                ...state,
                isAuthenticated: false,
                isLogging : false
            }
        }

        case authActionTypes.SUBSCRIPTION_LOADING : {
            return {
                ...state,
                subscriptionLoading: true
            }
        }

        case authActionTypes.SUBSCRIPTION_LOAD_SUCCESS : {
            return {
                ...state,
                subscriptionLoading: false,
                user: action.payload
            }
        }

        case authActionTypes.SUBSCRIPTION_LOAD_ERROR : {
            return {
                ...state,
                subscriptionLoading: false
            }
        }

        case authActionTypes.USER_SIGNOUT_LOADING: {
            return {
                ...state,
                signingOut: true 
            }
        }

        //User Signout
        case authActionTypes.USER_SIGNOUT_SUCCESS : {
            return {
                ...initialState,
                token: null,
                isLoading: false,
                signingOut: false
            }
        }

        case authActionTypes.USER_SIGNOUT_ERROR : {
            return {
                ...state,
                signingOut: false
            }
        }
        
        //User Creation

        case authActionTypes.USER_CREATE_LOADING : {
            return {
                userCreateLoading: true 
            }
        }

        case authActionTypes.USER_CREATE_SUCCESS :
            return {
                ...state,
                ...action.payload,
                userCreateLoading: false,
                isAuthenticated: true
            }

        case authActionTypes.USER_CREATE_ERROR :
            return {
                ...state,
                userCreateLoading: false,
            }
        
        //User auth (JWT)
        case authActionTypes.TOKEN_LOADING :
            return {
                ...state,
                isLoading: true
            }

        case authActionTypes.TOKEN_LOAD_SUCCESS :
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                isLoading: false
            }

        case authActionTypes.TOKEN_LOAD_ERROR :
            localStorage.removeItem('accessToken')
            return {
                ...initialState,
                isLoading: false
            }
        
        case "GENERATE_PLAN_LOADING" :
            return {
                ...state,
                planLoading: true
            }

        case "GENERATE_PLAN_SUCCESS" :
            return {
                ...state,
                planLoading: false
            }
        
        case "GENERATE_PLAN_ERROR" :
            return {
                ...state,
                planLoading: false
            }

        //User db load
        case "USER_DATA_LOADED" :
            return {
                ...state,
                user: action.payload
            }

        case authActionTypes.CHANGE_PASSWORD_LOADING : {
            return {
                ...state,
                changePasswordLoading: true
            }
        }

        case authActionTypes.CHANGE_PASSWORD_SUCCESS : {
            return {
                ...state,
                user: action.payload,
                changePasswordLoading: false
            }
        }

        case authActionTypes.CHANGE_PASSWORD_ERROR : {
            return {
                ...state,
                changePasswordLoading: false
            }
        }

        case authActionTypes.UPDATE_PROFILE_LOADING : {
            return {
                ...state,
                updateProfileLoading: true
            }
        }

        case authActionTypes.UPDATE_PROFILE_SUCCESS : {
            return {
                ...state,
                user: action.payload,
                updateProfileLoading: false
            }
        }

        case authActionTypes.UPDATE_PROFILE_ERROR : {
            return {
                ...state,
                updateProfileLoading: false
            }
        }

        case authActionTypes.RECALIBRATION_LOADING : {
            return {
                ...state,
                recalibrationLoading: true
            }
        }

        case authActionTypes.RECALIBRATION_SUCCESS : {
            return {
                ...state,
                user: action.payload,
                recalibrationLoading: false
            }
        }

        case authActionTypes.RECALIBRATION_ERROR : {
            return {
                ...state,
                recalibrationLoading: false 
            }
        }
        
        default:
            return state
    }
}
