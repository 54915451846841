//Classes and Functions
import React, { Component } from 'react'
//Components and Pages
import { PlanCards, CheckoutModal, Modal } from './'
//Images

const defaultState = {
    plans: [],
    plan: {},
    reset: false,
    checkout: true
}

class PlansModal extends Component {
    constructor (props) {
        super(props)
        this.state = { ...defaultState }
    }

    handleChange = (question, answer) => {
        this.setState( { [question]: answer })
    }
    
	render() {
        return (
            <div>
                <CheckoutModal open={this.state.checkout} plan={this.state.plan}/>
            </div>
        )
    }
}

export default PlansModal

