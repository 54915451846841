import React, { useState, useEffect, useRef } from 'react'
import { ModalSlide, ModalButton, PageHandler, Page, Selector, NutritionInfo, SearchBar, Image, MealGroup } from '.'
import { Card, CardContent, CircularProgress, Fade } from '@material-ui/core'
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { updateProfile } from '../actions/authActions'
import { Color, verbs, toTitleCase } from '../util'
import { MdOutlineShortcut } from 'react-icons/md'

import api from '../api'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import a_hs_1 from '../images/android_hs_1.jpg'
import a_hs_2 from '../images/android_hs_2.jpg'
import a_hs_3 from '../images/android_hs_3.jpg'

import ios_hs_1 from '../images/ios_hs_1.jpg'
import ios_hs_2 from '../images/ios_hs_2.jpg'
import ios_hs_3 from '../images/ios_hs_3.jpg'

const PageIcon = (props) => {
    return (
        <div style={{ backgroundColor: Color.PRIMARY, width: props.mini ? '5px' : (props.active ? '24px' : '8px'), height: props.mini ? '5px' : '5px', borderRadius: '6px', margin: '3px', transition: 'width 400ms ease, opacity 400ms ease' }}/>
    )
}

const SearchListing = (props) => {
    const renderFood = (food) => {
        if (!food) return null
        switch (food.class) {
            case ('custom') :
            case ('recipe') : {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        { props.food.image_url ? <Image src={props.food.image_url} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', backgroundColor: 'grey', border: 'none', marginRight: '8px' }}/> : null }
                        <div style={{ maxWidth: '250px' }}>
                            <p style={{ margin: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}> { toTitleCase(food.name) } </p>       
                        </div>
                    </div>
                )
            }

            case ('branded') :
            case ('common') : {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        { props.food.photo.thumb ? <Image src={props.food.photo.thumb} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', backgroundColor: 'grey', border: 'none', marginRight: '8px' }}/> : null }
                        <div style={{ maxWidth: '250px' }}>
                            <p style={{ margin: '0px', marginRight: '10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}> { toTitleCase(food.food_name) }  </p>       
                            { props.food.brand_name ? <p style={{ margin: '0px', marginRight: '10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '10px', opacity: '.7' }}> { toTitleCase(food.brand_name) }  </p> : null }      
                        </div>                    
                    </div>
                )
            }

            default: return <p> Unknown Food Class </p>
        }
    }

    return (
        props.food ? <div style={{ margin: '7px 0px 7px 0px', padding: '8px', backgroundColor: 'rgba(0,0,0,.05)', borderRadius: '10px', fontSize: '14px' }} onClick={() => props.handleInfoOpen?.({ meal: props.food, populate: true })}>
            { renderFood(props.food) } 
        </div> : null
    )
}

const Group = (props) => {
    const [open, setOpen] = useState(false)
    let contentRef = useRef()

    useEffect(() => {
        setOpen(props.initial)
    }, [props.initial])

    const getContentHeight = () => {
        if (!contentRef || !contentRef.current) return null
        return contentRef.current.getBoundingClientRect().height
    }

    return <Fade in={true}>
        <div style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <h6 style={{ marginRight: '5px', marginBottom: '0px' }}> { props.name } </h6>
                    { props.button }
                </div>
                <KeyboardArrowDownIcon onClick={() => setOpen(!open)} style={{ fontSize: '24px', transform: !open ? 'rotate(180deg)' : null, transition: 'transform 300ms ease', marginLeft: 'auto' }}/>
            </div>
            <div style={{ height: open ? getContentHeight() : '0px', transition: 'height 300ms ease', overflow: 'hidden' }}>
                <div ref={contentRef}>
                    { 
                        props.children.length ? props.children.map((child, i) => {
                            return <div>
                                { child }
                                { i < props.children.length - 1 ? <Divider/>: null }
                            </div>
                        }) : props.children
                    }
                </div>
            </div>
        </div>
    </Fade>
}

const getRandomInt = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min)
  }

const StartPageWidget = (props) => {
    const [objects, setObjects] = useState([])
    const [images, setImages] = useState([])
    const imageRef = useRef()

    useEffect(() => {
        imageRef.current = images;
      }, [images]);

    const spawn = (randomStart, tempImages) => {
        let size = getRandomInt(100, 180)
        return {
            image: tempImages ? tempImages[getRandomInt(0, tempImages.length)] : imageRef.current[getRandomInt(0, imageRef.current.length)],
            left: randomStart ? getRandomInt(-100, window.innerWidth) : -80 - size,
            bottom: getRandomInt(-100, 500 - size),
            velocity: getRandomInt(40, 120),
            imageLoaded: false,
            size: size
        }
    }

    const initialSpawn = async () => {
        let temp_images
        try {
            let response = await api.getFoods({ params: { limit: 200 }})
            temp_images = response.data.map((food) => food.image_url)
            setImages(temp_images)
        } catch (error) {
            console.log(error)
        }

        let temp = []
        for (let i = 0; i < 12; i++) temp.push(spawn(true, temp_images))
        setObjects(temp)
    }

    useEffect(() => {
        initialSpawn()
        let interval = setInterval(() => {
            setObjects((values) => {
                return values.map((object) => {
                    if (object.left > window.innerWidth + object.size) return spawn()
                    return { ...object, left: object.left + (object.velocity / 200) }
                })
            })
        }, 5)
        return () => clearInterval(interval)
    }, [])

    const handleLoad = (index) => {
        let temp = [...objects]
        temp[index] = { ...temp[index], imageLoaded: true }
        setObjects(temp)
    }

    return (
        <div style={{ position: 'relative', height: '500px', width: '100%' }}>
            {
                objects.map((object, i) => {
                    return <div key={object.velocity * object.size * object.bottom} style={{ opacity: !object.imageLoaded ? '0' : '1', transform: !object.imageLoaded ? 'scale(0)' : 'scale(1)', bottom: object.bottom, left: object.left, position: 'absolute', transition: 'opacity ease 500ms, transform ease 500ms' }}>
                        <Image onLoad={() => handleLoad(i)} src={object.image} style={{ width: object.size, boxShadow: '0 0px 20px 3px ' + Color.PRIMARY + '50', height: object.size, objectFit: 'cover', borderRadius: '25px' }}/>
                    </div>
                })
            }
        </div>
    )
}

const Divider = (props) => {
    return <div style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}/>
}

const Line = (props) => {
    return <div>
        <div style={{ height: '45px', width: '100%', borderRadius: '12px', padding: '2px', display: 'flex', alignItems: 'center', margin: '2px 0px 2px 0px' }} onClick={props.onClick}>
            <div style={{ borderRadius: '50%', backgroundColor: props.color || Color.PRIMARY, width: '28px', height: '28px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}> 
                <p style={{ margin: '0px', fontSize: '14px' }}> { props.index } </p>
            </div>
            <p style={{ margin: '0px 5px 0px 15px', textOverflow: 'ellipsis' }}> {props.value} </p>
        </div>
        { props.children }
    </div>
}

const PageIconHandler = (props) => {
    const { children } = props?.children?.props, { tab, max } = props

    const renderPageIcons = () => {
        let render = [], pages = Math.ceil(children.length / max), page = Math.ceil((tab + 1) / max), count = children.length - max * (page - 1)
        if (count > max) count = max  

        if (page > 1) render.push(<PageIcon mini/>)
        for (let i = 0; i < count; i++) render.push(<PageIcon active={i % max === tab % max}/>)
        if (page < pages) render.push(<PageIcon mini/>)

        return render
    }

    return (
        <div>
            <div style={{ position: 'absolute', top: '30px', left: '20px', transition: 'all 500ms ease' }}>
                <div style={{ display: 'flex', marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                    {
                        renderPageIcons()
                    }
                </div>
            </div>
            { props.children }
        </div>
    )
}

const RecipeWidget = (props) => {
    const [mode, setMode] = useState('Ingredients')
    const [recipe, setRecipe] = useState(0)
    const [height, setHeight] = useState(0)
    const contentRef = useRef()
    const interval = useRef()

    const recipes = [
        { 
            name: 'Mahi Mahi With Roasted Mango Chipotle Sauce', 
            img: 'https://s3.us-east-2.amazonaws.com/media.stnbly.io/1623783483-MahiMahiMangoSauce_horizontal-1.jpg',
            ingredients: "Ingredients for 4 servings:\n4 6-oz. mahi-mahi fillets\n2 T. extra virgin olive oil, divided\nSea salt and black pepper, to taste\n2 large mangos, peel removed and diced\n½ large red onion, cut into thin wedges\n1 T. fresh thyme leaves  \n1 T. fresh lemon juice\n1/8 t. chipotle pepper\nFresh thyme sprigs, for garnish",
            instructions: "Step 1\nPlace top oven rack in the center position and pre-heat oven to 400°F. Line a large, rimmed baking sheet with aluminum foil or a Silpat baking sheet. Set aside. \r\n\r\nStep 2\nAdd the mahi-mahi to the sheet pan and drizzle one tablespoon of olive oil on top. Use a pastry brush to spread the oil evenly over the surface \nof the fillets. Sprinkle with salt and black pepper, to taste.  \r\n\r\nStep 3\nArrange the diced mango on the same baking sheet in a single layer without overcrowding."
        }, {

        }, {

        }
    ]

    const renderIngredients = (instructions) => {
        if (!instructions) return null
        let temp = instructions.split(":")[1], list = []
        try {
            let instr = temp.split('\n')
            for (let element in instr) {
                let instruction = instr[element]
                if (instruction.trim() !== "") {
                    list.push(
                        <div key={element} style={{ display: 'flex', alignItems: 'center', padding: '0px 10px 0px 10px' }}>
                            <h6 style={{ color: Color.PRIMARY, marginRight: '20px', marginBottom: '0px' }}> {element}. </h6>
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h6 style={{ fontSize: '14px', marginBottom: '0px'}}> {instruction}  </h6>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        } catch (error) {
            console.log(error)
        }
        
        return (
            <div style={{ position: 'relative' }}>
                {
                    list.map((item, i) => {
                        return (
                            <div key={i}>
                                { item }
                                { i < list.length - 1 ? <div style={{ height: '1px', borderTop: '1px solid', margin: '15px 20px 15px 20px', opacity: '.1' }}/> : null }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const renderInstructions = (instructions) => {
        if (!instructions) return null
        let temp = instructions, list = []
        try {
            let instr = temp.split('\n')
            let count = 1
            for (let element in instr) {
                let instruction = instr[element]
                if (instruction.trim() !== "" && !instruction.trim().includes('Step')) {
                    list.push(
                        <div key={element} style={{ display: 'flex', alignItems: 'center', padding: '0px 10px 0px 10px' }}>
                            <h6 style={{ color: Color.PRIMARY, marginRight: '20px', marginBottom: '0px' }}> {count}. </h6>
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px'}}>
                                    <h6 style={{ fontSize: '14px', marginBottom: '0px'}}> {instruction.split(' ').map((el, i) => {
                                        if (verbs.includes(el.trim().toLowerCase())) {
                                            return <span key={i} style={{ color: Color.PRIMARY }}> {el} </span>
                                        } else return <span key={i}> {el} </span>
                                    })} </h6>
                                </div>
                            </div>
                        </div>
                    )

                    count += 1
                }
            }
        } catch (error) {
            console.log(error)
        }
        
        return (
            <div style={{ position: 'relative' }}>
                {
                    list.map((item, i) => {
                        return (
                            <div key={i}>
                                { item }
                                { i < list.length - 1 ? <div style={{ height: '1px', borderTop: '1px solid', margin: '15px 20px 15px 20px', opacity: '.1' }}/> : null }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const getContentHeight = () => {
        if (!contentRef || !contentRef.current) return null
        return contentRef.current.getBoundingClientRect().height
    }

    const tick = () => {
        setMode((currMode) => {
            if (currMode === 'Instructions') return 'Ingredients'
            else return 'Instructions'
        })
    }

    const handleChange = (value) => {
        setMode(value)
        clearInterval(interval.current)
        interval.current = setInterval(tick, 3000)
    }
 
    useEffect(() => {
        interval.current = setInterval(tick, 3000)
        return () => clearInterval(interval.current)
    }, [])

    useEffect(() => {
        setHeight(getContentHeight())
    }, [mode])

    return (
        <div>
            <div style={{ position: 'relative', borderRadius: '25px', overflow: 'hidden' }}>
                <div style={{ position: 'absolute', top: '0', right: '0', height: '200px', width: '100%', backgroundColor: 'rgba(0,0,0,.4)'}} />
                <Fade in={true}>
                    <Image key={recipe} src={recipes[recipe].img} style={{ width: '100%', height: '200px', objectFit: 'cover' }}/>
                </Fade>
                <div style={{ display: 'flex' }}>
                    <h6 style={{ position: 'absolute', bottom: '15px', left: '15px', color: 'white' }}> {recipes[recipe].name} </h6>
                </div>
            </div>
            <Selector style={{ justifyContent: 'left', marginTop: '20px' }} value={mode} handleChange={handleChange} options={[{ value: 'Ingredients', label: 'Ingredients' }, { value: 'Instructions', label: 'Instructions' }]}/>
            <div style={{ height: height + 40, overflow: 'hidden', transition: 'height ease 300ms', marginTop: '20px' }}>
                <div ref={contentRef} style={{ borderRadius: '25px', border: '1px solid ' + Color.PRIMARY + '40', padding: '20px' }}>
                    {
                        mode === 'Ingredients' ? renderIngredients(recipes[recipe].ingredients) : renderInstructions(recipes[recipe].instructions) 
                    }
                </div>
            </div>
        </div>
    )
}

const TrackingWidget = (props) => {
    const terms = ['panera bread', 'caesar salad', 'chicken sandwich']
    const [buffer, setBuffer] = useState({ index: 0, buffer: '' })
    const [results, setResults] = useState([{ searched: false, data: null }, { searched: false, data: null }, { searched: false, data: null }])
    const [loading, setLoading] = useState(false)
    
    const [id, setId] = useState(null)
    const [tid, setTid] = useState(null)

    const bufferRef = useRef(buffer)
    const intervalRef = useRef()
    const timeoutRef = useRef()
    const resultsRef = useRef()

    useEffect(() => {
        setId(setInterval(type, 50))
        return () => {
            clearTimeout(timeoutRef.current)
            clearInterval(intervalRef.current)
        }
    }, [])

    useEffect(() => {
        bufferRef.current = buffer
        intervalRef.current = id
        timeoutRef.current = tid
        resultsRef.current = results
    }, [buffer, id, tid, results])

    const backspace = () => {
        let temp = bufferRef.current
        if (temp.buffer.length > 0) {
            setBuffer({ ...temp, buffer: temp.buffer.substring(0, temp.buffer.length - 1)})
        } else {
            let index = temp.index === terms.length - 1 ? 0 : temp.index + 1
            clearInterval(intervalRef.current)
            setId(setInterval(type, 50))
            setBuffer({ ...temp, index })
        }
    }

    const type = () => {
        let temp = bufferRef.current, term = terms[temp.index]
        console.log(temp)
        if (temp.buffer.length === term.length) {
            clearInterval(intervalRef.current)
            if (!resultsRef.current[temp.index].searched) handleSearch(temp.buffer, temp.index)
            setTid(setTimeout(() => setId(setInterval(backspace, 50)), 3000))
        } else {
            setBuffer({ ...temp, buffer: temp.buffer += terms[temp.index][temp.buffer.length]})
        }
    }

    const handleSearch = async (query, index) => {
        let temp = resultsRef.current
        temp[index].searched = true

        setLoading(true)
        api.searchFoods({ params: { q: query, recipes: true, branded: true, common: true, combined: true, limit: 4 }}).then((response) => {
            temp[index].data = response.data.combined
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        })

        setResults(temp)
    }

    return (
        <div style={{ overflow: 'hidden', transition: 'height ease 300ms' }}>
            <div style={{ marginBottom: '40px' }}>            
                <SearchBar disabled value={buffer.buffer} handleResults={(data) => setResults(data)} style={{ zIndex: 0 }}/>
                { !loading && results[buffer.index].data ? <Fade in={true}>
                    <div key={buffer.index}>
                        {
                            results[buffer.index].data.map((food) => {
                                return (
                                    <SearchListing food={food}/>
                                )
                            })
                        }
                    </div>
                </Fade> : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '60px' }}>
                    <CircularProgress size={25} />
                </div> }
            </div>
        </div>
    )
}

const PWAWidget = (props) => {
    const [device, setDevice] = useState('')
    const [loaded, setLoaded] = useState(0)
    
    useEffect(() => {
        const userAgent = navigator.userAgent
        let timeout = setTimeout(() => setDevice(getMobileOperatingSystem()), 300)
        return () => clearTimeout(timeout)
    }, []) 

    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
    
        if (/android/i.test(userAgent)) {
            return "Android";
        }
    
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
    
        return "unknown";
    }

    return (
        <div>
            <Group name={'iOS'} initial={device === 'iOS'} button={device === 'iOS' ? <h6 style={{ fontSize: '12px', margin: '0px' }}> Your device </h6> : null}>
                <Line index={1} value={<span> Tap the <b> Share </b> button </span>}>
                    <Image onLoad={() => setLoaded(loaded + 1)} src={ios_hs_1} style={{ borderRadius: '10px', width: '100%', marginBottom: '10px' }}/>
                </Line>
                <Line index={2} value={<span> Select <b> Add to Home Screen </b> </span>}>
                    <Image onLoad={() => setLoaded(loaded + 1)} src={ios_hs_2} style={{ borderRadius: '10px', width: '100%', marginBottom: '10px' }}/>
                </Line>
                <Line index={3} value={<span> Confirm by tapping <b> Add </b> </span>}>
                    <Image onLoad={() => setLoaded(loaded + 1)} src={ios_hs_3} style={{ borderRadius: '10px', width: '100%', marginBottom: '10px' }}/>
                </Line>
                <Line index={4} value={<span> You're all set 🎉 </span>}/>
            </Group>
            <Group name={'Android'} initial={device === 'Android' ? <h6 style={{ fontSize: '12px', margin: '0px' }}> Your device </h6> : null}>
                <Line index={1} value={<span> Tap on the <b> three dots </b> in your browser </span>}>
                    <Image onLoad={() => setLoaded(loaded + 1)} src={a_hs_1} style={{ borderRadius: '10px', width: '100%', marginBottom: '10px' }}/>
                </Line>
                <Line index={2} value={<span> Select <b> Add to Home Screen </b> </span>}>
                    <Image onLoad={() => setLoaded(loaded + 1)} src={a_hs_2} style={{ borderRadius: '10px', width: '100%', marginBottom: '10px' }}/>
                </Line>
                <Line index={3} value={<span> Confirm by tapping <b> Add </b> </span>}>
                    <Image onLoad={() => setLoaded(loaded + 1)} src={a_hs_3} style={{ borderRadius: '10px', width: '100%', marginBottom: '10px' }}/>
                </Line>
                <Line index={4} value={<span> You're all set 🎉 </span>}/>
            </Group>
        </div>
    )
}

const MealPlanWidget = (props) => {
    const options = [{ value: 'anything', label: 'Anything' }, { value: 'vegetarian', label: 'Vegetarian' }, { value: 'vegan', label: 'Vegan' }]

    const [diet, setDiet] = useState('anything')
    const [meals, setMeals] = useState({ anything: null, vegetarian: null, vegan: null })
    const [loading, setLoading] = useState(false)
    const [height, setHeight] = useState(0)
    const contentRef = useRef()
    const interval = useRef()

    const renderTime = (category, name) => {
        if (!category) return
        let carbs = 0, protein = 0, fat = 0, calories = 0
        for (let i = 0; i < category.length; i++) {
            let meal = category[i]
            carbs += meal.carbs
            protein += meal.protein
            fat += meal.fat
            calories += meal.calories
        }

        return (
            <MealGroup nocopy carbs={carbs} fat={fat} protein={protein} calories={calories} name={name} da>
                { category.map((meal, i) =>
                    <SearchListing food={meal}/>
                )}
            </MealGroup>
        )
    }

    const getContentHeight = () => {
        if (!contentRef || !contentRef.current) return null
        return contentRef.current.getBoundingClientRect().height
    }

    const tick = () => {
        setDiet((currDiet) => {
            for (let i = 0; i < options.length; i++) {
                if (currDiet === options[i].value) {
                    if (i === options.length - 1) return options[0].value
                    else return options[i + 1].value
                } 
            }
        }) 
    }

    const handleChange = (value) => {
        setDiet(value)
        clearInterval(interval.current)
        interval.current = setInterval(tick, 3000)
    }

    useEffect(() => {
        interval.current = setInterval(tick, 3000)
        return () => clearInterval(interval.current)
    }, [])

    useEffect(() => {
        setHeight(getContentHeight())
    }, [diet, meals])

    useEffect(() => {
        if (meals[diet]) return
        setLoading(true)
        api.getPredictive({ macros: props.macros, settings: { ...props.settings, allergies: [], diet }, options: {}}).then((response) => {
            setLoading(false)
            setMeals({ ...meals, [diet]: response.data })
        }).catch((error) => {
            setLoading(false)
        })
    }, [diet])

    return (
        <div>
            <Selector value={diet} handleChange={handleChange} style={{ justifyContent: 'left' }} options={options}/>
            <div style={{ height: height + 60, overflow: 'hidden', transition: 'height ease 300ms' }}>
                <div ref={contentRef}>
                    { renderTime(meals[diet]?.[0]?.breakfast.data, 'Breakfast') }
                    { renderTime(meals[diet]?.[0]?.lunch.data, 'Lunch') }
                    { renderTime(meals[diet]?.[0]?.dinner.data, 'Dinner') }
                    { renderTime(meals[diet]?.[0]?.snack.data, 'Snack') }
                    { loading ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '60px' }}>
                        <CircularProgress size={25}/>
                    </div> : null }
                </div>
            </div>
        </div>
    )
}

const NutritionGoalsWidget = (props) => {
    const [mode, setMode] = useState(true)
    const interval = useRef()

    useEffect(() => {
        interval.current = setInterval(tick, 3000)
        return () => clearInterval(interval.current)
    })

    const tick = () => {
        setMode((currMode) => {
            return !currMode
        })    
    }

    const handleChange = (value) => {
        setMode(value)
        clearInterval(interval.current)
        interval.current = setInterval(tick, 3000)
    }

    return (
        <>
            <Selector style={{ justifyContent: 'left' }} value={mode} handleChange={handleChange} options={[{ value: true, label: 'Simple' }, { value: false, label: 'Full' }]}/>
            <NutritionInfo simple_macros={mode} time={new Date()}/>
            <Body style={{ opacity: '.7', fontSize: '13px', padding: '15px', textAlign: 'center', marginTop: '-15px' }}>
                We start in “Simple Mode.” Switch to full macros in <b> Settings </b>
            </Body>
        </>
    )
}

const Header = (props) => <h4 {...props} style={{ marginBottom: '20px', display: 'flex' }}> {props.children} </h4>
const Body = (props) => <p {...props} style={{ fontSize: '18px', ...props.style }}> {props.children} </p>

const TutorialModal = (props) => {
    const [page, setPage] = useState(0)

    useEffect(() => {
        setPage(0)
    }, [props.open])

    const handleContinue = async () => {
        if (page < 6) return setPage(page + 1)
        try {
            if (!props.auth.user.completed_onboarding) await props.updateProfile(props.auth.user._id, { profile: { completed_onboarding: true }})
            if (props.handleClose) props.handleClose()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <ModalSlide direction='left' in={props.open}>
            <Card style={{ position: 'fixed', top: '0', right: '0', left: '0', marginRight: 'auto', marginLeft: 'auto', zIndex: '1000', width: '100%', height: '100%', overflowY: 'scroll', overflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', boxShadow: '' }}>
                <CardContent style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <p style={{ fontSize: '16px', color: Color.PRIMARY, position: 'absolute', margin: '0px', top: '25px', right: '25px', zIndex: '2', cursor: 'pointer' }} onClick={() => {
                        props.updateProfile(props.auth.user._id, { profile: { completed_onboarding: true }})
                        props.handleClose()
                    }}> Skip </p>
                    <div style={{ marginTop: '60px', padding: '5px' }}>
                        <PageIconHandler tab={page} max={7}>
                            <PageHandler tab={page}>
                                <Page>
                                    <Header>Welcome to Sustainably!</Header>
                                    <Body>
                                        We’re going to walk you through a proven, flexible process designed for your specific body type and goals. 
                                    </Body>
                                    <StartPageWidget/>
                                </Page>
                                <Page>
                                    <Header>Accessing the App</Header>
                                    <Body>
                                        Sustainably is a web-app designed for mobile. Follow the instructions below to add us to your device.
                                    </Body>
                                    <PWAWidget/>
                                </Page>
                                <Page>
                                    <Header>Nutrition Goals</Header>
                                    <Body>
                                        Your nutrition targets are customized to your specific body type and goals.
                                    </Body>
                                    <NutritionGoalsWidget/>
                                </Page>
                                <Page>
                                    <Header>Tracking Food </Header>
                                    <Body>
                                        Add custom foods, search our database, and easily add your favorite foods daily.
                                    </Body>
                                    <TrackingWidget/>
                                </Page>
                                <Page>
                                    <Header>Meal Plans</Header>
                                    <Body>
                                        Our meal plans are built to your nutrition goals - but don’t worry about following them top-to-bottom.
                                    </Body>
                                    <MealPlanWidget settings={props.auth.user.settings} macros={props.auth.user.settings.macros}/>
                                </Page>
                                <Page>
                                    <Header>Recipes</Header>
                                    <Body>
                                        Search our nutrition database to see 500+ delicious recipes to stay on top of your goals and never run out of ideas.
                                    </Body>
                                    <RecipeWidget/>
                                </Page>
                                <Page>
                                    <Header> Ready for Results?! </Header>
                                    <p style={{ fontSize: '18px' }}>
                                        Welcome to a revolutionary new way of eating and say "goodbye" to diets for good!
                                    </p>
                                    <Image style={{ width: '110%', height: 'auto', borderRadius: '15px' }} src={'https://media2.giphy.com/media/BHlc32wWwZH20/giphy.gif?cid=790b761121e0cd5bdf5313ca993732e0e11e96ac095d5174&rid=giphy.gif&ct=g'}/>
                                    <p style={{ fontSize: '18px', marginTop: '30px' }}>
                                        Make sure to get started ASAP - You'll be glad you did.
                                    </p>
                                </Page>
                            </PageHandler>
                        </PageIconHandler>
                    </div>
                    <ModalButton icon={<MdOutlineShortcut/>} label={'Continue'} loading={props.auth.userLoading} handleClick={handleContinue} style={{ position: 'sticky', bottom: '25px', width: '100%', left: '20px', marginTop: 'auto' }}/>
                </CardContent>
            </Card>
        </ModalSlide>
    )
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        updateProfile
     }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorialModal)