//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
//Components and Pages
import { withRouter } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core';
import { ConfirmationModal, Hoverable, Image, Table } from '../components'
import { Color, localStart, localEnd, populateSessions, getAuthRequest, getAttribute, timeToLocal, days } from '../util'
import api from '../api'
//Images
import RefreshIcon from '@material-ui/icons/Refresh'
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const Label = styled.p`
    font-size: 13px;
    opacity: .7;
    margin-bottom: 3px;
`

const ChartHeader = styled.h6`
    margin-bottom: 12px;
`

const ChartHeaderAttribute = styled.span`
    color: white;
    background-color: ${Color.PRIMARY};
    margin-left: 8px;
    vertical-align: center;
    border-radius: 5px;
    padding: 6px;
    font-size: 12px;
`

class AdminUser extends Component {
    constructor (props) {
        super(props)
		this.state = {
            time: new Date(),
            loading: false,
            filters: false,
            users: [],
            logins: [],
            loginsLoading: false,
            loginsLoaded: false,
            sessions: [],
            sessionsLoading: false,
            sessionsLoaded: false,
            weighins: [],
            weighinsLoading: false,
            weighinsLoaded: false,
            views: [],
            viewsLoading: false,
            viewsLoaded: false,
            generalSessions: [],
            generalSessionsLoading: false,
            generalSessionsLoaded: false,
            initialWeighin: null,
            article: null,
            articleLoading: false,
            confirmationModal: false,
            confirmationAction: null,
            confirmationHeader: '',
		}
    }

    handleConfirmationClose = () => {
        this.setState({ confirmationModal: false, confirmationAction: null })
    }

    handleConfirmDelete = () => {
        this.setState({ confirmationModal: true, confirmationAction: this.handleDelete, confirmationHeader: 'Would you like to delete this user?' })
    }

    handleConfirmGenerate = () => {
        this.setState({ confirmationModal: true, confirmationAction: this.handleGenerate, confirmationHeader: 'Would you like to overwrite the current plan?' })
    }

    handleArticleHover = (_id) => {
        this.setState({ articleLoading: true })
        let req = getAuthRequest()
        req.params = { _id }
        api.getArticle(req).then((response) => {
            this.setState({ article: response.data[0], articleLoading: false })
        }).catch((error) => {
            this.setState({ articleLoading: false })
        })
    }

    componentDidMount() {
        document.title = 'Users'
        this.timeClock = setInterval(this.tick, 1000)
        this.getUsers()
    }

    getMetaData = (activeStat, percent, minDate, maxDate) => {
        let calories = 0, sessions = this.state.generalSessions.filter((session) => session.type === 'food' && session.time > minDate && session.time < maxDate)
        
        for (let index in sessions) {
            let session = sessions[index], food = session.target
            if (food) calories += Math.round(getAttribute(activeStat, food, session))
        }

        if (percent) return calories / (this.props.auth.user.settings.macros[activeStat] || this.props.sampleMacros[activeStat]) * 100
        return calories
    }

    getPlan = (date) => {
        let user = this.state.users[this.state.users.length - 1]
        for (let i = 0; i < user.curr.days.length; i++) {
            let planDate = new Date(user.curr.days[i].date), localDate = new Date(timeToLocal(date))
            if (planDate.getDate() === localDate.getDate()) return user.curr.days[i].fitness
        }

        return []
    }

    getFitnessMetaData = (activeStat, percent, minDate, maxDate) => {
        let sessions = this.state.generalSessions.filter((session) => (session.type === 'workout') && session.time > minDate && session.time < maxDate)
        let activities = this.state.generalSessions.filter((session) => (session.type === 'activity') && session.time > minDate && session.time < maxDate)
        let user = this.state.users[this.state.users.length - 1]

        switch (activeStat) {
            case ('completion') : {
                let completed = 0, required = this.getPlan(minDate)
                for (let i = 0; i < required.length; i++) {
                    if (sessions.filter((session) => session.target_id === required[i].workout_id ).length) completed++
                }
                
                if (!percent) return completed
                if (required.length === 0) return 100
                return completed / required.length * 100
            }

            case ('walking_calories') : {
                let calories = 0
                activities = activities.filter((activity) => activity.target.walking)
                for (let i = 0; i < activities.length; i++) {
                    if (activities[i].session_meta.calories) calories += activities[i].session_meta.calories
                }

                if (!percent) return calories
                return calories / user.curr.cardio ? user.curr.cardio.walking_calories : 0 * 100
            }

            case ('walking_minutes') : {
                let minutes = 0
                activities = activities.filter((activity) => activity.target.walking)
                for (let i = 0; i < activities.length; i++) {
                    if (activities[i].session_meta.duration) minutes += activities[i].session_meta.duration
                }

                if (!percent) return minutes / 60
                return (minutes / 60) / user.curr.cardio ? user.curr.cardio.duration : 0 * 100
            }

            case ('cardio_calories') : {
                let calories = 0
                activities = activities.filter((activity) => !activity.target.walking)
                for (let i = 0; i < activities.length; i++) {
                    if (activities[i].session_meta.calories) calories += activities[i].session_meta.calories
                }

                if (!percent) return calories
                return calories / user.curr.cardio ? user.curr.cardio.cardio_calories : 0 * 100
            }

            default : {
                return null
            }
        }
    }    

    getUsers = () => {
        this.setState({ loading: true })
        let req = getAuthRequest()
        req.params = {
            sort: "[[\"created_at\", -1]]",
            _id: this.props.match.params.id
        }
        api.getUserData(req).then((response) => {
            this.setState({ users: response.data, loading: false })
            this.handleMount()
        }).catch((error) => {
            this.setState({ loading: false })
        })
    }

    getLogins = () => {
        this.setState({ loginsLoading: true })
        let req = getAuthRequest()
        req.params = { limit: 5, sort: "[[\"time\", -1]]", user_id: this.state.users[this.state.users.length - 1]._id}
        api.getLogins(req).then((response) => {
            this.setState({ logins: response.data, loginsLoading: false, loginsLoaded: true  })
        }).catch((error) => {
            this.setState({ loginsLoading: false, loginsLoaded: true })
        })
    }

    getAverageLogins = () => {
        this.setState({ loginsLoading: true })
        let req = getAuthRequest()
        req.params = { _id: this.state.users[this.state.users.length - 1]._id, created_at: this.state.users[this.state.users.length - 1].created_at }
        api.getLoginAverage(req).then((response) => {
            this.setState({ loginAverage: response.data, loginsLoading: false, loginsLoaded: true  })
        }).catch((error) => {
            this.setState({ loginsLoading: false, loginsLoaded: true })
        })
    }

    getSessions = () => {
        this.setState({ sessionsLoading: true })
        let req = getAuthRequest()
        req.params = { limit: 5, sort: "[[\"created_at\", -1]]", user_id: this.state.users[this.state.users.length - 1]._id}
        api.getSessions(req).then((response) => {
            this.setState({ sessions: response.data, sessionsLoading: false, sessionsLoaded: true  })
        }).catch((error) => {
            this.setState({ sessionsLoading: false, sessionsLoaded: true })
        })
    }

    getGeneralSessions = (start, end) => {
        let req = getAuthRequest()
        req.params = { time : { $gte: start, $lt: end }, user_id: this.state.users[0]._id, expand: ['target'] }
        this.setState({ generalSessionsLoading: true })
        api.getSessions(req).then((response) => {
            console.log(response.data)
            this.setState({ generalSessions: response.data, generalSessionsLoading: false, generalSessionsLoaded: true  })
        }).catch((error) => {
            this.setState({ generalSessionsLoading: false, generalSessionsLoaded: true })
        })
    }

    getWeighins = () => {
        this.setState({ sessionsLoading: true })
        let req = getAuthRequest()
        req.params = { limit: 5, sort: "[[\"time\", -1]]", user_id: this.state.users[this.state.users.length - 1]._id}
        api.getWeighins(req).then((response) => {
            this.setState({ weighins: response.data, weighinsLoading: false, weighinsLoaded: true  })
        }).catch((error) => {
            this.setState({ weighinsLoading: false, weighinsLoaded: true })
        })
    }

    getViews = () => {
        this.setState({ sessionsLoading: true })
        let req = getAuthRequest()
        req.params = { limit: 5, sort: "[[\"created_at\", -1]]", user_id: this.state.users[this.state.users.length - 1]._id}
        api.getViews(req).then((response) => {
            this.setState({ views: response.data, viewsLoading: false, viewsLoaded: true  })
        }).catch((error) => {
            this.setState({ viewsLoading: false, viewsLoaded: true })
        })
    }

    getInitialWeighin = () => {
        this.getGeneral()
        this.setState({ sessionsLoading: true })
        let req = getAuthRequest()
        req.params = { limit: 5, sort: "[[\"time\", -1]]", user_id: this.state.users[this.state.users.length - 1]._id, type: 'initial_weigh_in'}
        api.getWeighins(req).then((response) => {
            this.setState({ initialWeighin: response.data[response.data.length - 1] ? response.data[response.data.length - 1] : null, weighinsLoading: false, weighinsLoaded: true  })
        }).catch((error) => { 
            this.setState({ weighinsLoading: false, weighinsLoaded: true })
        })
    }

    getAge = (date) => {
		if (date === null) return null
		let temp = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
		let curr = new Date(Date.now() - (date.getTimezoneOffset() * 60000)), year =  curr.getUTCFullYear() - temp.getUTCFullYear()
		if (temp.getUTCMonth() > curr.getUTCMonth() || (temp.getUTCMonth() === curr.getUTCMonth() && temp.getUTCDate() > curr.getUTCDate())) year -= 1
		return year
	}

    getTypeColor = (type) => {
        switch(type) {
            case ('active'):
                return Color.CALORIES_SUCCESS
            case ('canceled'):
                return Color.CALORIES_ERROR
            case ('past_due'):
                return Color.PROTEIN
            default:
                return 'lightgrey'
        }
    }

    getGenderColor = (type) => {
        switch(type) {
            case ('Male'):
                return Color.PRIMARY
            case ('Female'):
                return Color.FAT_ERROR
            default: 
                return 'lightgrey'
        }
    }

    getCancelColor = (type) => {
        switch(type) {
            case (false):
                return Color.CALORIES_SUCCESS
            case (true):
                return Color.CALORIES_ERROR
            default:
                return 'lightgrey'
        }
    }

    getCompletedColor = (type) => {
        switch(type) {
            case (false):
                return Color.CALORIES_ERROR
            case (true):
                return Color.CALORIES_SUCCESS
            default:
                return 'lightgrey'
        }
    }

    getLoginsColor = (type) => {
        switch(type) {
            case ('token'):
                return Color.PRIMARY
            case ('password'):
                return Color.PROTEIN
            default:
                return 'lightgrey'
        }
    }

    getSessionsColor = (type) => {
        switch(type) {
            case ('workout'):
                return Color.PRIMARY
            case ('food'):
                return Color.PROTEIN
            case ('activity'):
                return Color.FAT
            default:
                return 'lightgrey'
        }
    }

    getClientTypeColor = (type) => {
        switch(type) {
            case ('mobile'):
                return Color.CALORIES
            case ('desktop'):
                return Color.FAT
            default:
                return 'lightgrey'
        } 
    }

    getDates = (days) => {
        let dates = []
        for (let i = 0; i < days; i++) {
            let date = new Date()
            date.setDate(date.getDate() - i)
            dates.push(new Date(date))
        }

        return dates
    }

    getGeneral = () => {
        return this.getDates(7)
    }
 
    handleGenerate = () => {
        this.setState({ loading: true })
        let req = getAuthRequest()
        req.params = {
            id: this.props.match.params.id
        }
        api.generatePlans({ offset: -4 }, req).then((response) => {
            this.setState({ users: [response.data], loading: false })
        }).catch((error) => {
            this.setState({ loading: false })
        })
    }

    handleDelete = () => {
        this.setState({ loading: true })
        let req = getAuthRequest()
        req.params = {
            _id: this.props.match.params.id
        }
        api.deleteUser(req).then((response) => {
            this.props.history.push('/dashboard/admin/users')
        }).catch((error) => {
            this.setState({ loading: false })
        })
    }

    handleMount = () => {
        let dates = this.getDates(7)
        let start = localStart(dates[dates.length - 1]), end = localEnd(dates[0])
        this.getGeneralSessions(start, end)
        this.getLogins()
        this.getSessions()
        this.getWeighins()
        this.getViews()
        this.getInitialWeighin()
        this.getAverageLogins()
    }

    handleRefresh = () => {
        this.getUsers()
    }

    componentWillUnmount() { 
        clearInterval(this.timeClock)
        document.body.style.overflow = 'unset';
    }

    tick = () => {
        this.setState({ time: new Date() })
    }

    renderArticleTooltip = () => {
        return (
            !this.state.articleLoading ? <div>
                <p style={{ marginBottom: '0px', fontSize: '12px', padding: '5px', color: Color.PRIMARY }}> { this.state.article ? this.state.article.title : 'n/a' } </p>
            </div> : <div style={{ width: '50px', height: '30px', position: 'relative'}}>
                <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translateX(-50%) translateY(-50%)'}}>
                    <CircularProgress size={13}/>
                </div>
            </div>
        )
    }

    getDateObject = (date) => {
        return new Date(date)
    }

    renderGeneralHover = (entry) => {
        return <p style={{ marginBottom: '0px', fontSize: '12px', padding: '5px', color: Color.PRIMARY, whiteSpace: 'nowrap' }}> { entry } </p>
    }

    renderDayHover = (entry) => {
        return <p style={{ marginBottom: '0px', fontSize: '12px', padding: '5px', color: Color.PRIMARY, whiteSpace: 'nowrap' }}> { days[entry.getDay()] } </p>
    }

    renderPercentHover = (entry, variable) => {
        return this.state.users[this.state.users.length - 1].is_activated ? <p style={{ marginBottom: '0px', fontSize: '12px', padding: '5px', color: Color.PRIMARY, whiteSpace: 'nowrap' }}> { Math.round( entry / this.state.users[this.state.users.length - 1].settings.macros[variable] * 100) }% </p> : null 
    }

    render() {
        let user = this.state.users[this.state.users.length - 1]
        let date = user ? new Date(user.dob) : null
        let recalibrationDate = user ? new Date(user.curr.recalibration_date) : null
        let billingDate = user ? new Date(user.subscription.billing_date * 1000) : null
        let createdAt = user ? new Date(user.created_at) : null

        return (
            <div>
                <div style={{ position: 'relative' }}>
                    <h1> Users </h1>
                    <div style={{ outline: 'none', userSelect: 'none' }}>
                        <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={this.handleRefresh}> Refresh <RefreshIcon style={{ fontSize: '13px' }}/> </span>
                        <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px', margin: '10px 20px 10px 0px', cursor: 'pointer'}} onClick={this.handleConfirmDelete}> Delete <DeleteIcon style={{ fontSize: '13px'}}/> </span>
                    </div>
                    { !this.state.loading ? ( user ? <div>
                        <div style={{ width: '100%', paddingTop: '20px' }}>
                            <div style={{ display: 'inline-block', width: '40%', maxWidth: '400px', borderRight: '1px solid rgba(0, 0, 0, 0.1)', paddingBottom: '20px', height: '100%' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    { user.avatar ? <Image style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '50%' }} src={user.avatar}/> : <AccountCircleIcon style={{ fontSize: '50px', opacity: '.6' }}/> }
                                    <div style={{ marginLeft: '20px'}}>
                                        <h4 style={{ marginBottom: '3px' }}> { `${user.first_name} ${user.last_name}` } { user.admin ? <span style={{ textAlign: 'right', color: Color.PRIMARY, fontSize: '13px'}}> admin </span> : null } </h4>
                                        <p style={{ color: Color.PRIMARY, fontSize: '14px', marginBottom: '3px', marginTop: '3px' }}> { user.email } </p>
                                        <p style={{ fontSize: '12px', opacity: '.7', marginBottom: '0px' }}> Created { createdAt.toDateString() } </p>
                                    </div>
                                </div>
                                <div style={{ marginTop: '30px', position: 'relative'}}>
                                    <h6 style={{ fontSize: '14px', borderBottom: '1px solid rgba(0, 0, 0, 0.2)', paddingBottom: '8px' }}> Notes <span style={{ fontSize: '13px', color: Color.PRIMARY, fontWeight: 'normal', position: 'absolute', right: '10px', cursor: 'pointer'}}> create </span></h6>
                                    <div style={{ marginBottom: '15px' }}>
                                        <p style={{ fontSize: '13px', opacity: '.7', marginTop: '20px', textAlign: 'center'}}> No Notes Available </p>
                                    </div>
                                </div>
                                <div style={{ marginTop: '30px', position: 'relative'}}>
                                    <h6 style={{ fontSize: '14px', borderBottom: '1px solid rgba(0, 0, 0, 0.2)', paddingBottom: '8px' }}> Subscription  <span style={{ fontSize: '13px', color: Color.PRIMARY, fontWeight: 'normal', position: 'absolute', right: '10px', cursor: 'pointer'}}> edit </span></h6>
                                    <div style={{ marginBottom: '15px' }}>
                                        <Label> Status </Label>
                                        <span style={{ fontSize: '12px', backgroundColor: this.getTypeColor(user.subscription.status), verticalAlign: 'center', color: 'white', borderRadius: '5px', padding: '4px' }}> { user.subscription.status || <span style={{ opacity: '.7'}}> n/a </span> } </span>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <Label> Customer ID </Label>
                                        <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.subscription.customer_id } </p>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <Label> Default Payment Method </Label>
                                        <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.subscription.card_type || 'null' } { user.subscription.card_last4 ? `•••• ${user.subscription.card_last4}` : '' } </p>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <Label> Subscription ID </Label>
                                        <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.subscription.subscription_id || 'n/a' } </p>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <Label> Billing Date </Label>
                                        <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { billingDate.toLocaleString() } </p>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <Label> Cancel At End </Label>
                                        <span style={{ fontSize: '12px', backgroundColor: this.getCancelColor(user.subscription.cancel_at_end), verticalAlign: 'center', color: 'white', borderRadius: '5px', padding: '4px' }}> { user.subscription.cancel_at_end ? 'true' : 'false' } </span>
                                    </div>
                                </div>
                                <div style={{ position: 'relative'}}>
                                    <h6 style={{ fontSize: '14px', borderBottom: '1px solid rgba(0, 0, 0, 0.2)', paddingBottom: '8px', marginTop: '40px' }}> Description <span style={{ fontSize: '13px', color: Color.PRIMARY, fontWeight: 'normal', position: 'absolute', right: '10px', cursor: 'pointer'}}> edit </span> </h6>
                                    <div style={{ marginBottom: '15px' }}>
                                        <Label> Completed Onboarding </Label>
                                        <span style={{ fontSize: '12px', backgroundColor: this.getCompletedColor(user.completed_onboarding), verticalAlign: 'center', color: 'white', borderRadius: '5px', padding: '4px' }}> { user.completed_onboarding ? 'true' : 'false' } </span>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <Label> Phone Number </Label>
                                        <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> {user.phone_number || 'null'} </p>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <Label> Gender </Label>
                                        <span style={{ fontSize: '12px', backgroundColor: this.getGenderColor(user.gender), verticalAlign: 'center', color: 'white', borderRadius: '5px', padding: '4px' }}> { user.gender || <span style={{ opacity: '.7'}}> n/a </span> } </span>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <div style={{ display: 'inline-block' }}>
                                            <Label> Date of Birth </Label>
                                            <Hoverable tooltip={ <p style={{ marginBottom: '0px', fontSize: '12px', padding: '5px', color: Color.PRIMARY, whiteSpace: 'nowrap' }}> { this.getAge(date) } years old </p>}>
                                                <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px', width: 'fit-content' }}> { `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}` } </p>
                                            </Hoverable>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <div style={{ display: 'inline-block' }}>
                                            <Label> Height </Label>
                                            <Hoverable>
                                                <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> {`${Math.floor(user.settings.height/12)}' ${user.settings.height%12}"`}  </p>
                                            </Hoverable>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '15px', display: 'inline-block', width: '33%' }}>
                                        <Label> Initial Weight </Label>
                                        <Hoverable>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { `${this.state.initialWeighin !== null ? this.state.initialWeighin.weight : null } lbs` } </p>
                                        </Hoverable>
                                    </div>
                                    <div style={{ marginBottom: '15px', display: 'inline-block', width: '33%' }}>
                                        <Label> Initial Waist </Label>
                                        <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { this.state.initialWeighin !== null && this.state.initialWeighin.waist_size ? `${this.state.initialWeighin.waist_size}"` : 'null' } </p>
                                    </div>
                                    <div style={{ marginBottom: '15px', display: 'inline-block', width: '33%' }}>
                                        <Label> Initial Fat </Label>
                                        <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { `${this.state.initialWeighin !== null ? this.state.initialWeighin.body_fat : null }` } </p>
                                    </div>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <h6 style={{ fontSize: '14px', borderBottom: '1px solid rgba(0, 0, 0, 0.2)', paddingBottom: '8px', marginTop: '40px' }}> Current 
                                        <span style={{ fontSize: '13px', color: Color.PRIMARY, fontWeight: 'normal', position: 'absolute', right: '10px', cursor: 'pointer'}}> edit </span> 
                                        <span style={{ fontSize: '13px', color: Color.PRIMARY, fontWeight: 'normal', position: 'absolute', right: '50px', cursor: 'pointer'}} onClick={this.handleConfirmGenerate}> generate </span>         
                                    </h6>
                                    <div style={{ marginBottom: '15px' }}>
                                        <Label> Recalibration Date </Label>
                                        <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { recalibrationDate.toUTCString() } </p>
                                    </div>
                                    <div style={{ marginBottom: '15px'}}>
                                        <div style={{ display: 'inline-block', width: '25%'}}>
                                            <Label> Calories </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.curr?.macros?.calories } </p>
                                        </div>
                                        <div style={{ display: 'inline-block', width: '25%' }}>
                                            <Label> Carbs </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.curr?.macros?.carbs }g </p>
                                        </div>
                                        <div style={{ display: 'inline-block', width: '25%' }}>
                                            <Label> Protein </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.curr?.macros?.protein }g </p>
                                        </div>
                                        <div style={{ display: 'inline-block', width: '25%' }}>
                                            <Label> Fat </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.curr?.macros?.fat }g </p>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '15px'}}>
                                        <div style={{ display: 'inline-block', width: '50%' }}>
                                            <Label> Walking Minutes </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.curr.cardio ? user.curr.cardio.duration : 'null' } </p>
                                        </div>
                                        <div style={{ display: 'inline-block', width: '50%' }}>
                                            <Label> Walking Calories </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.curr.cardio ? user.curr.cardio.walking_calories : 'null' } </p>
                                        </div>
                                    </div>
                                    <div>  
                                        <div style={{ display: 'inline-block', width: '50%' }}>
                                            <Label> Cardio Calories </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.curr.cardio ? user.curr.cardio.cardio_calories : 'null' } </p>
                                        </div>
                                        <div style={{ display: 'inline-block', width: '50%' }}>
                                            <Label> Cardio Days </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.curr.cardio ? user.curr.cardio.cardio_days : 'null' } </p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <h6 style={{ fontSize: '14px', borderBottom: '1px solid rgba(0, 0, 0, 0.2)', paddingBottom: '8px', marginTop: '40px' }}> Settings 
                                        <span style={{ fontSize: '13px', color: Color.PRIMARY, fontWeight: 'normal', position: 'absolute', right: '10px', cursor: 'pointer'}}> edit </span> 
                                    </h6>
                                    <div style={{ marginBottom: '15px' }}>
                                        <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'top' }}>
                                            <Label> Activity Level </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.settings.activity_level } </p>
                                        </div>
                                        <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'top' }}>
                                            <Label> Fitness Level </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.settings.fitness_level } </p>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'top' }}>
                                            <Label> Unit </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.settings.unit } </p>
                                        </div>
                                        <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'top' }}>
                                            <Label> Pace </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.settings.pace } </p>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'top' }}>
                                            <Label> Target Weight </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.settings.target_weight } lbs </p>
                                        </div>
                                        <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'top' }}>
                                            <Label> Target Body Fat </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.settings.target_body_fat ? `${user.settings.target_body_fat}` : 'null' } </p>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'top' }}>
                                            <Label> Diet Type </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.settings.diet } </p>
                                        </div>
                                        <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'top' }}>
                                            <Label> Allergies </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.settings.allergies ? user.settings.allergies.map((diet, i) => {
                                                if (i === user.settings.allergies.length - 1) return diet
                                                return diet + ", "
                                            }) : 'None'} </p>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'top' }}>
                                            <Label> Convenient Options </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.settings.rec_convenient ? user.settings.rec_convenient.map((diet, i) => {
                                                if (i === user.settings.rec_convenient.length - 1) return diet
                                                return diet + ", "
                                            }) : 'None'} </p>
                                        </div>
                                        <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'top' }}>
                                            <Label> Health Conditions </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.settings.health_conditions.length ? user.settings.health_conditions.map((diet, i) => {
                                                if (i === user.settings.health_conditions.length - 1) return diet
                                                return diet + ", "
                                            }) : 'None'} </p>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'top' }}>
                                            <Label> Physical Limitations </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.settings.physical_limitations.length ? user.settings.physical_limitations.map((diet, i) => {
                                                if (i === user.settings.physical_limitations.length - 1) return diet
                                                return diet + ", "
                                            }) : 'None'} </p>
                                        </div>
                                        <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'top' }}>
                                            <Label> Equipment </Label>
                                            <p style={{ color: Color.PRIMARY, fontSize: '12px', marginBottom: '0px' }}> { user.settings.equipment.map((diet, i) => {
                                                if (i === user.settings.equipment.length - 1) return diet
                                                return diet + ", "
                                            })} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div style={{ display: 'inline-block', width: '60%', verticalAlign: 'top', paddingLeft: '40px'}}>
                                <div style={{ marginBottom: '20px', position: 'relative', width: '100%' }}>
                                    <ChartHeader> Nutrition 
                                        <ChartHeaderAttribute>{`${this.state.generalSessions.filter((session) => session.type === 'food').length} entries this week`} </ChartHeaderAttribute> 
                                    </ChartHeader>

                                    <Table loading = {this.state.generalSessionsLoading} columns = {[
                                        { width: 25, name: 'Date', renderHover: this.renderDayHover, transform: (entry) => { return `${entry.getMonth() + 1}/${entry.getDate()}/${entry.getFullYear()}` } }, 
                                        { width: 18.75, name: 'Calories', renderHover: (entry) => this.renderPercentHover(entry, 'calories')  }, 
                                        { width: 18.75, name: 'Protein', renderHover: (entry) => this.renderPercentHover(entry, 'protein')  },
                                        { width: 18.75, name: 'Carbs', renderHover: (entry) => this.renderPercentHover(entry, 'carbs')  },
                                        { width: 18.75, name: 'Fat', renderHover: (entry) => this.renderPercentHover(entry, 'fat')  }
                                    ]} data = {
                                        this.getGeneral().map((row) => {
                                            let calories = this.getMetaData('calories', false, localStart(row), localEnd(row))
                                            let carbs = this.getMetaData('carbs', false, localStart(row), localEnd(row))
                                            let protein = this.getMetaData('protein', false, localStart(row), localEnd(row))
                                            let fat = this.getMetaData('fat', false, localStart(row), localEnd(row))

                                            return [
                                                row,
                                                calories,
                                                carbs,
                                                protein,
                                                fat
                                            ]
                                        })
                                    } raw={this.getGeneral()}/>
                                </div>
                                <div style={{ marginBottom: '20px', position: 'relative', width: '100%' }}>
                                    <ChartHeader> Fitness 
                                        <ChartHeaderAttribute>{`${this.state.generalSessions.filter((session) => session.type === 'workout' ).length} exercises this week`} </ChartHeaderAttribute> 
                                    </ChartHeader>

                                    <Table loading = {this.state.generalSessionsLoading} columns = {[
                                        { width: 25, name: 'Date', renderHover: this.renderDayHover, transform: (entry) => { return `${entry.getMonth() + 1}/${entry.getDate()}/${entry.getFullYear()}` } }, 
                                        { width: 18.75, name: 'Walking Minutes', renderHover: (entry) => <p style={{ marginBottom: '0px', fontSize: '12px', padding: '5px', color: Color.PRIMARY, whiteSpace: 'nowrap' }}> { this.getFitnessMetaData('walking_minutes', true, localStart(entry), localEnd(entry)) }% </p>  }, 
                                        { width: 18.75, name: 'Walking Calories', renderHover: (entry) => <p style={{ marginBottom: '0px', fontSize: '12px', padding: '5px', color: Color.PRIMARY, whiteSpace: 'nowrap' }}> { this.getFitnessMetaData('walking_calories', true, localStart(entry), localEnd(entry)) }% </p>  },
                                        { width: 18.75, name: 'Cardio Calories', renderHover: (entry) => <p style={{ marginBottom: '0px', fontSize: '12px', padding: '5px', color: Color.PRIMARY, whiteSpace: 'nowrap' }}> { this.getFitnessMetaData('cardio_calories', true, localStart(entry), localEnd(entry)) }% </p> },
                                        { width: 18.75, name: 'Exercises', renderHover: (entry) => <p style={{ marginBottom: '0px', fontSize: '12px', padding: '5px', color: Color.PRIMARY, whiteSpace: 'nowrap' }}> { this.getFitnessMetaData('completion', true, localStart(entry), localEnd(entry)) }% </p>  }
                                    ]} data = {
                                        this.getGeneral().map((row) => {
                                            let walkingCalories = this.getFitnessMetaData('walking_calories', false, localStart(row), localEnd(row))
                                            let cardioCalories = this.getFitnessMetaData('cardio_calories', false, localStart(row), localEnd(row))
                                            let walkingMinutes = this.getFitnessMetaData('walking_minutes', false, localStart(row), localEnd(row))
                                            let exercises = this.getFitnessMetaData('completion', false, localStart(row), localEnd(row)) 

                                            return [
                                                row,
                                                walkingMinutes,
                                                walkingCalories,
                                                cardioCalories,
                                                exercises
                                            ]
                                        })
                                    } raw={this.getGeneral()}/>
                                </div>
                                
                                <div style={{ marginBottom: '20px', position: 'relative', width: '100%' }}>
                                    <ChartHeader> Logins 
                                        <ChartHeaderAttribute>{this.state.loginAverage && this.state.loginAverage.avg ? `~${Math.round(this.state.loginAverage.avg * 100) / 100} / day` : 'No History' }</ChartHeaderAttribute>
                                        <ChartHeaderAttribute>{this.state.logins.length ? `Last Login: ${this.getDateObject(this.state.logins[0].time).toLocaleString()}` : 'No Recent Login' }</ChartHeaderAttribute>  
                                    </ChartHeader>

                                    <Table loading = {this.state.loginsLoading} columns = {[
                                        { width: 20, name: 'Client Address' }, 
                                        { width: 20, name: 'Client Type', render: (entry) => <span style={{ fontSize: '12px', backgroundColor: this.getClientTypeColor(entry), verticalAlign: 'center', color: 'white', borderRadius: '5px', padding: '4px' }}> { entry } </span> }, 
                                        { width: 15, name: 'Browser'},
                                        { width: 20, name: 'Method', render: (entry) => <span style={{ fontSize: '12px', backgroundColor: this.getLoginsColor(entry), verticalAlign: 'center', color: 'white', borderRadius: '5px', padding: '4px' }}> { entry } </span> },
                                        { width: 25, name: 'Date', transform: (date) => { 
                                            let obj = new Date(date)
                                            return obj.toLocaleString()
                                        } },
                                    ]} data = {
                                        this.state.logins.map((row) => {
                                            return [
                                                row.client_address,
                                                row.client_type,
                                                row.browser,
                                                row.method,
                                                row.time
                                            ]
                                        })
                                    } raw={this.state.logins}/>
                                </div>
                                <div style={{ marginBottom: '20px', position: 'relative', width: '100%' }}>
                                    <ChartHeader> Sessions </ChartHeader>
                                    <Table loading = {this.state.sessionsLoading} columns = {[
                                        { width: 30, name: 'Type', render: (entry) => <span style={{ fontSize: '12px', backgroundColor: this.getSessionsColor(entry), verticalAlign: 'center', color: 'white', borderRadius: '5px', padding: '4px' }}> { entry } </span> }, 
                                        { width: 30, name: 'Target ID'}, 
                                        { width: 40, name: 'Date Created', transform: (date) => { 
                                                let obj = new Date(date)
                                                return obj.toLocaleString()
                                            }
                                        }
                                    ]} data = {
                                        this.state.sessions.map((row) => {
                                            return [
                                                row.type,
                                                row.target_id,
                                                row.created_at
                                            ]
                                        })
                                    } raw = {this.state.sessions} />
                                </div>
                                <div style={{ marginBottom: '20px', position: 'relative', width: '100%' }}>
                                    <ChartHeader> Weighins
                                        <ChartHeaderAttribute> 
                                            { this.state.initialWeighin ? `${ Math.round(this.state.initialWeighin.weight - ( this.state.weighins[0] ? this.state.weighins[0].weight : this.state.initialWeighin.weight))} pounds lost` : 'No History' } 
                                        </ChartHeaderAttribute> 
                                    </ChartHeader>

                                    <Table loading = {this.state.weighinsLoading} columns = {[
                                        { width: 15, name: 'Type', render: (entry) => <span style={{ fontSize: '12px', backgroundColor: this.getTypeColor(entry), verticalAlign: 'center', color: 'white', borderRadius: '5px', padding: '4px' }}> { entry } </span> }, 
                                        { width: 15, name: 'Weight'}, 
                                        { width: 15, name: 'Waist Size'}, 
                                        { width: 15, name: 'Body Fat'}, 
                                        { width: 40, name: 'Date Created', transform: (date) => { 
                                                let obj = new Date(date)
                                                return obj.toLocaleString()
                                            }
                                        }
                                    ]} data = {
                                        this.state.weighins.map((row) => {
                                            return [
                                                row.type,
                                                row.weight,
                                                row.waist_size,
                                                row.body_fat,
                                                row.time
                                            ]
                                        })
                                    } raw = {this.state.weighins} />
                                </div>
                                <div style={{ marginBottom: '20px', position: 'relative', width: '100%'}}>
                                    <ChartHeader> Views </ChartHeader>
                                    <Table loading = {this.state.viewsLoading} columns = {[
                                        { width: 50, name: 'Article ID', handleHover: (recalibration) => this.handleArticleHover(recalibration.article_id), renderHover: () => this.renderArticleTooltip()}, 
                                        { width: 50, name: 'Date Created', transform: (date) => { 
                                                let obj = new Date(date)
                                                return obj.toLocaleString()
                                            }
                                        }
                                    ]} data = {
                                        this.state.views.map((row) => {
                                            return [
                                                row.article_id,
                                                row.created_at
                                            ]
                                        })
                                    } raw = {this.state.views} />
                                </div>
                            </div>
                        </div>
                    </div> : null ) : <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                        <CircularProgress size={25}/>
                    </div> }
                </div>
                <ConfirmationModal head={this.state.confirmationHeader} open={this.state.confirmationModal} handleClose={this.handleConfirmationClose} action={this.state.confirmationAction}/>
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
		dash: state.dash,
		auth: state.auth
	}
}

export default connect(mapStateToProps)(withRouter(AdminUser))
