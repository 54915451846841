import React, { useRef, useState } from 'react'
import { Card, CardContent, Grow } from '@material-ui/core'
import { ModalSlide, ModalGrow, ModalFunctions, CloseIcon, Image, Video, MoreIcon } from './'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIosRounded';
import AnimateHeight from 'react-animate-height';
import { Color } from '../util'
import TransitionContainer from "./TransitionContainer";

const Modal = (props) => {
    const [imageIterator, setImageIterator] = useState(0)
    const [imageHeight, setImageHeight] = useState(150)

    const bodyRef = useRef()

    const handleImageScroll = () => {
        if (bodyRef.current) return setImageHeight(150 - (bodyRef.current.scrollTop / 2) || 0)
        return null
    }

    const hasImage = () => {
        return props.img || (props.image_id ? (Array.isArray(props.image_id) ? props.image_id.length : true) : false)
    }

    const hasVideo = () => {
        return props.video
    }

    let headerStyles = { margin: '0px 50px 8px 0px', opacity: '1', transition: 'opacity .3s ease' }
    if (props.functions && !hasImage() && !hasVideo()) headerStyles = { ...headerStyles, margin: '0px 120px 8px 0px' }
    if (hasImage() || hasVideo()) headerStyles = { ...headerStyles, margin: `0px 60px 8px 0px` }

    const renderImage = () => {
        if (props.image_id) {
            if (Array.isArray(props.image_id) && props.image_id.length) {
                return <div>
                    { imageIterator < props.image_id.length - 1 ? <ArrowForwardIosIcon style={{ position: 'absolute', top: '75px', right: '15px', color: 'white', fontSize: '18px', zIndex: '1003' }} onClick={() => setImageIterator(imageIterator + 1)}/> : null }
                    { imageIterator > 0 ? <ArrowBackIosIcon style={{ position: 'absolute', top: '75px', left: '15px', color: 'white', fontSize: '18px', zIndex: '1003' }} onClick={() => setImageIterator(imageIterator - 1)}/> : null }
                    { props.image_id.map((image, i) => {
                        return (
                            <div key={i}>
                                { image.image_id ? <div style={{ display: imageIterator !== i ? 'none' : null }}>
                                    <Image style={{ position: 'absolute', width: '100%', maxwidth: '600px', height: '150px', top: '0', left: '0', objectFit: 'cover' }} image_id={image.image_id}/>
                                </div> : null }
                            </div>
                        )
                    })}

                    { props.image_id[imageIterator].type ? <Grow in={true} key={imageIterator}>
                        <h6 style={{ position: 'absolute', top: '110px', left: '10px', backgroundColor: Color.PRIMARY, padding: '5px', borderRadius: '25px', color: 'white' }}> {props.image_id[imageIterator].type} </h6>
                    </Grow> : null }
                </div>
            } else if (!Array.isArray(props.image_id)) {
                return <Image style={{ position: 'absolute', width: '100%', maxwidth: '600px', height: '150px', top: '0', left: '0', zIndex: '1002', objectFit: 'cover'}} image_id={props.image_id}/>
            }
        } else if (props.img) {
            return <Image style={{ position: 'absolute', width: '100%', maxwidth: '600px', height: '150px', top: '0', left: '0', zIndex: '1002', objectFit: 'cover'}} src={props.img}/>
        } else {
            return null
        }
    }

    const renderVideo = () => {
        return props.video ? <Video style={{ width: '100%', height: `${150}px`, position: 'absolute', top: '0px', left: '0px' }} src={props.video} loadingStyle={{ marginBottom: '20px' }}/> : null 
    }

    const handleClose = () => {
        setImageIterator(0)
        props.handleClose()
    }

    const renderHead = () => {
        return (
            props.head ? <div>
                <h5 style={headerStyles}> {props.head} </h5>
            </div> : null
        )
    }

    return (
        window.innerWidth < 600 && !props.desktop ? <ModalSlide direction={props.direction} in={props.in} backdrop={!props.nobackdrop} handleClose={props.handleClose}>
            <Card ref={bodyRef} onScroll={handleImageScroll} style={{ position: 'fixed', bottom: '0', right: '0', left: '0', marginLeft: 'auto', marginRight: 'auto', maxWidth: '600px', maxHeight: '100%', overflowY: 'scroll', WebkitOverflowScrolling: 'touch', borderRadius: `25px 25px 0px 0px`, zIndex: '1002', width: '100%', ...props.style}}>
                <CardContent style={{ position: 'relative' }}>
                <AnimateHeight height={'auto'}>
                    { props.handleClose ? <CloseIcon handleClose={handleClose} style={{ top: '8px', right: '8px', zIndex: '1003' }}/> : null }
                    { props.functions && !hasImage() && !hasVideo() ? <ModalFunctions style={{ position: 'absolute', top: '8px', right: '48px' }}>
                            { props.functions.map((func, i) => {
                                if (func) return <span key={i} backgroundColor={func.props.style.backgroundColor}> {func} </span>
                                return null 
                            }) }
                    </ModalFunctions> : null }
                    { props.functions && (hasImage() || hasVideo()) ? 
                        <ModalFunctions style={{ top: '8px', left: '8px', position: 'absolute', zIndex: '1003'}}>
                            { props.functions.map((func, i) => {
                                if (func) return <span key={i} backgroundColor={func.props.style.backgroundColor}> {func} </span>
                                return null 
                            }) }
                        </ModalFunctions> : null }
                    
                    { renderImage() }
                    { renderVideo() }
                    <div style={{ position: 'relative', marginTop: hasImage() ? `${150}px` : (hasVideo() ? '150px' : '5px')}}>
                        { renderHead() }
                        { props.children }
                    </div>
                    </AnimateHeight>
                </CardContent>
            </Card>
        </ModalSlide> : <ModalGrow in={props.in} backdrop={true} handleClose={props.handleClose}>
            <Card ref={bodyRef} onScroll={handleImageScroll} style={{ position: 'fixed', bottom: '50%', right: '0', left: '0', transform: 'translateY(50%)', boxShadow: 'none', marginLeft: 'auto', marginRight: 'auto', width: '700px', maxHeight: '700px', overflowY: 'scroll', WebkitOverflowScrolling: 'touch', borderRadius: '15px', zIndex: '1002' }}>
                <CardContent>
                    { props.handleClose ? <CloseIcon handleClose={handleClose} style={{ top: '8px', right: '8px', zIndex: '1003' }}/> : null }
                    { props.functions && !hasImage() && !hasVideo() ? (props.functions.length > 1 ? <MoreIcon>
                        <ModalFunctions style={{ display: 'flex' }}>
                            { props.functions.map((func, i) => {
                                if (func) return <span key={i} backgroundColor={func.props.style.backgroundColor}> {func} </span>
                                return null 
                            }) }
                        </ModalFunctions> 
                    </MoreIcon> : <ModalFunctions style={{ position: 'absolute', top: '8px', right: '48px' }}>
                            { props.functions.map((func, i) => {
                                if (func) return <span key={i} backgroundColor={func.props.style.backgroundColor}> {func} </span>
                                return null 
                            }) }
                    </ModalFunctions>  ) : null }
                    { props.functions && (hasImage() || hasVideo()) ? 
                        <ModalFunctions style={{ top: '8px', left: '8px', position: 'absolute', zIndex: '1003'}}>
                            { props.functions.map((func, i) => {
                                if (func) return <span key={i} backgroundColor={func.props.style.backgroundColor}> {func} </span>
                                return null 
                            }) }
                        </ModalFunctions> : null }
                    
                    { renderImage() }
                    { renderVideo() }
                    <div style={{ position: 'relative', marginTop: hasImage() ? `${150}px` : (hasVideo() ? '150px' : '5px')}}>
                        { renderHead() }
                        { props.children }
                    </div>
                </CardContent>
            </Card>
        </ModalGrow>
    )
}

export default Modal