import React, { Component } from 'react';
import styled from 'styled-components'
import { Fade } from '@material-ui/core'
import { Color } from '../util'

const ChipContainer = styled.div`
    padding: 7px 12px 7px 12px;
    border-radius: 20px;
    background-color: ${(props) => props.disabled ? props.color || Color.PRIMARY : 'white'};
    transition: background-color 300ms ease;
    color: ${(props) => !props.disabled ? props.color || Color.PRIMARY : 'white'};
    border: ${(props) => `1px solid ${props.color || Color.PRIMARY}`};
    margin: 0px 2px 0px 2px;
    display: flex;
    align-items: center;
    font-size: 13px;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.03);
`

class Chip extends Component {
    render() {
        return (
            <Fade in={true} key={this.props.key}>
                <div>
                    <ChipContainer style={{ ...this.props.style }} color={this.props.color} disabled={this.props.disabled} key={this.props.key} onClick={(event) => this.props.disabled || !this.props.handleClick ? null : this.props.handleClick(this.props.label, event)}>
                        <p style={{ marginBottom: '0px', whiteSpace: 'nowrap' }}> {this.props.label} </p>
                        { this.props.icon ? this.props.icon : null }
                    </ChipContainer>
                </div>
            </Fade>
        );
    }
}

export default Chip;