import { useEffect, useState } from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { predictiveMealplan } from '../actions/dashboardActions'
import { getAttribute } from '../util'

const RecommendedGenerationGroup = (props) => {
    const [lengths, setLengths] = useState([])
    const meals = ['breakfast', 'lunch', 'dinner', 'snack']

    const getMetaData = (activeStat, additive, sessions) => {
        let calories = 0
        additive = getAttribute(activeStat, additive, { metadata: { serving_unit: props.servingType, servings: props.servings }})
        if (additive) calories += additive

        for (let index in sessions) {
            let session = sessions[index], food = session.target
            calories += Math.round(getAttribute(activeStat, food, session))
        }

        return props.sampleCurr ? props.sampleCurr[activeStat] : calories
    }

    useEffect(() => {
        if (props.dash.predictiveLoaded || props.dash.sessionLoading) return
        let sessions = props.dash.sessions.filter((session) => (new Date(session.time).getDate() === (new Date()).getDate()) && Math.abs(Date.now() - session.time) < 50000000)
        if (!props.dash.predictiveLoading && !props.dash.predictiveLoaded) props.predictiveMealplan(props.auth.user.settings.macros, props.auth.user.settings)
        setLengths([ 
            sessions.filter((session) => session?.metadata?.meal === 'breakfast').length, 
            sessions.filter((session) => session?.metadata?.meal === 'lunch').length, 
            sessions.filter((session) => session?.metadata?.meal === 'dinner').length, 
            sessions.filter((session) => session?.metadata?.meal === 'snack').length 
        ])
    }, [])

    useEffect(() => {
        if (props.dash.predictiveLoading || props.dash.sessionLoading) return
        let sessions = props.dash.sessions.filter((session) => (new Date(session.time).getDate() === (new Date()).getDate()) && Math.abs(Date.now() - session.time) < 50000000)
        
        let filtered_sessions = [
            sessions.filter((session) => session?.metadata?.meal === 'breakfast'),
            sessions.filter((session) => session?.metadata?.meal === 'lunch'),
            sessions.filter((session) => session?.metadata?.meal === 'dinner'),
            sessions.filter((session) => session?.metadata?.meal === 'snack')
        ]

        let new_lengths = filtered_sessions.map((meal) => meal.length)
        let changed_meal, changed_meal_index

        for (let i = 0; i < new_lengths.length; i++) {
            if (new_lengths[i] - lengths[i] !== 0) {
                changed_meal = meals[i]
                changed_meal_index = i
            }
        }
        
        if (!changed_meal) return
        setLengths(new_lengths)
        if (!props.dash.predictiveLoaded) return props.predictiveMealplan(props.auth.user.settings.macros, props.auth.user.settings)
        props.predictiveMealplan(props.auth.user.settings.macros, { ...props.auth.user.settings, deductions: { [meals[changed_meal_index]] : getDeductions(filtered_sessions[changed_meal_index]) }, exclude: meals.filter((meal, i) => i !== changed_meal_index)})
    }, [props.dash.sessions])

    const getDeductions = (sessions) => {
        return {
            calories: getMetaData('calories', false, sessions),
            protein: getMetaData('protein', false, sessions),
            carbs: getMetaData('carbs', false, sessions),
            fat: getMetaData('fat', false, sessions)
        }
    }

    return props.children
}

function mapStateToProps(state) {
	return {
        dash: state.dash,
        auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ predictiveMealplan }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedGenerationGroup)