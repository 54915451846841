import React, { Component } from 'react';
import { Color } from '../util'

class Button extends Component {
    render() {
        return (
            <div {...this.props} style={{ borderRadius: '50%', width: '32px', height: '32px', backgroundColor: Color.PRIMARY, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 'auto', color: 'white', fontSize: '0', cursor: 'pointer', ...this.props.style }}>
                { this.props.children }
            </div>
        );
    }
}

export default Button;