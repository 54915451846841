//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { deleteSession, updateSession } from '../actions/dashboardActions'
//Components and Pages
import { TextField, CircularProgress, CardContent, InputAdornment } from '@material-ui/core';
import { Modal, CardFloat, Button, ModalButton } from './'
import { Measure, Color } from '../util'
//Images
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add'

const initialState = {
    targetTime: new Date(),
    expanded: true,
    setCount: 0,
    sets: [],
    reps: 0,
    weight: 0,
    time: '',
    success: false,
    error: false
}

class TrackingUpdateModalFitness extends Component {
    constructor (props) {
        super(props)
        this.state = initialState
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) this.setState({ sets: [...this.props.session.session_meta.sets], setCount: this.props.session.session_meta.sets.length, targetTime: new Date(this.props.session.time)})
    }

    handleSets = (newSets) => {
        let sets = this.state.sets
        if (this.state.setCount > newSets) {
            for (let i = 0; i < this.state.setCount - newSets; i++) sets.pop()
        } else if (this.state.setCount < newSets) {
            for (let i = 0; i < newSets - this.state.setCount; i++) {
                let set = {}
                if (this.isWeight()) set.weight = this.state.weight
                if (this.isDuration()) set.duration = this.state.duration
                else set.reps = this.state.reps 
                sets.push(set)
            }
        }

        this.setState({ sets, setCount: newSets })
    }

    handleAddSet = () => {
        let set = { }
        if (this.isWeight()) set.weight = this.state.weight
        if (this.isDuration()) set.duration = this.state.duration
        else set.reps = this.state.reps 
        this.setState({ sets: [...this.state.sets, set], setCount: this.state.setCount + 1 })
    }

    handleChange = (question, answer) => {
        this.setState( { [question]: answer })
    }

    handleDelete = () => {
        this.props.deleteSession(this.props.session._id).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.handleError()
        })
    }

    handleEmbeddedChange = (index, target, value) => {
        let sets = this.state.sets
        sets[index][target] = value
        this.setState({ sets })
    }

    handleTopInformation = (target, value) => {
        this.setState({ [target] : value, sets: this.state.sets.map((set) => { return {...set, [target]: value }})})
    }

    handleRemoveSet = (index) => {
        let sets = this.state.sets
        sets.splice(index, 1)
        this.setState({ sets, setCount: this.state.setCount - 1 })
    }

    isWeight = () => {
        return this.props.session && this.props.session.target.equipment && (this.props.session.target.equipment.includes('Barbells') || this.props.session.target.equipment.includes('Dumbbells'))
    }

    isDuration = () => {
        return this.props.session && this.props.session.target.duration
    }

    handleClose = () => {
        this.setState(initialState)
        this.props.handleClose()
    }

    handleUpdate = () => {
        let targetTime = new Date(this.props.session.time), temp = new Date(this.state.targetTime)

        if (this.state.targetTime.getDate() !== targetTime) {
            temp.setHours(targetTime.getHours(), targetTime.getMinutes(), targetTime.getSeconds(), targetTime.getMilliseconds())
            targetTime = temp            
        }

        this.props.updateSession(this.props.session._id, { time: targetTime.getTime(), session_meta: { ...this.props.session.session_meta, sets: this.state.sets }}, this.props.session.target).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.handleError()
        })
    }

    handleSuccess = () => {
        this.setState({ success: true })
        setTimeout(this.handleClose, 400)
    }

    handlError = () => {

    }

    renderSets = () => {
        let sets = this.state.sets.map((set, i) => {
            return (
                <div key={i} style={{ display: 'flex', alignItems: 'center', position: 'relative', padding: '0px 10px 0px 10px' }}>
                    <h6 style={{ color: Color.PRIMARY, marginRight: '20px', marginBottom: '0px' }}> Set {i + 1}: </h6>
                    <div>
                        { this.props.session.target && !this.props.session.target.duration ? <div style={{ width: '20%', marginRight: '5%', display: 'inline-block' }}> 
                            <h6 style={{ fontSize: '14px', opacity: '.8'}}> Reps </h6> 
                            { /* <Input type='number' style={{ width: '100%', fontSize: '14px' }}/> */ }
                            <TextField 
                                type="number"
                                style={{ marginBottom: '5px', width: '100%' }}
                                value={set.reps} 
                                placeholder="Reps"
                                onChange = {(event) => this.handleEmbeddedChange(i, "reps", event.target.value)}/>
                        </div> : 
                        <div style={{ width: '50px', marginRight: '5%', display: 'inline-block' }}> 
                            <h6 style={{ fontSize: '14px', opacity: '.8'}}> Duration </h6> 
                            <TextField 
                                type="number"
                                style={{ marginBottom: '5px', width: '100%' }}
                                value={set.duration} 
                                InputProps={{endAdornment: <InputAdornment> s </InputAdornment>}}
                                placeholder="Duration"
                                onChange = {(event) => this.handleEmbeddedChange(i, "duration", event.target.value)}/>
                        </div>
                        }
                        { this.isWeight() ? <div style={{ width: '20%', marginLeft: '5%', display: 'inline-block' }}> 
                            <h6 style={{ fontSize: '14px', opacity: '.8'}}> Weight </h6> 
                            <TextField 
                                type="number"
                                style={{ width: '100%' }}
                                value={set.weight} 
                                InputProps={{endAdornment: this.getInputAdornment('mass')}}
                                placeholder="Weight"
                                onChange = {(event) => this.handleEmbeddedChange(i, "weight", event.target.value)}/>
                        </div> : null }
                        <Button style={{ backgroundColor: Color.CALORIES_ERROR }} onClick={() => this.handleRemoveSet(i)}>
                            <DeleteIcon/>
                        </Button>
                    </div>
                </div>
            )
        })

        return  <div style={{ position: 'relative' }}>
            {
                sets.map((item, i) => {
                    return (
                        <div key={i}>
                            { item }
                            { i < sets.length - 1 ? <div style={{ height: '1px', borderTop: '1px solid', margin: '15px 20px 15px 20px', opacity: '.1' }}/> : <div style={{ height: '1px', borderTop: '1px solid', margin: '15px 20px 20px 20px', opacity: '.1' }}/> }
                        </div>
                    )
                })
            }
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }} onClick={this.handleAddSet}>
                <h6 style={{ textAlign: 'center', color: Color.PRIMARY, marginBottom: '0px' }}> Add Set </h6>
                <AddIcon style={{ fontSize: '16px', marginLeft: '3px', color: Color.PRIMARY }}/>
            </div>
        </div>

    }

    getInputAdornment = (type) => {
        if (this.props.auth.user.settings) return <InputAdornment> {Measure[this.props.auth.user.settings.unit][type].unit} </InputAdornment>
    }

    confirmSubmit = () => {
        if (this.props.session) {
            if (this.props.session.session_meta.sets) {
                if (this.state.sets.length > 0) {
                    if (this.props.session.target.duration) {
                        if (this.state.sets.every((set) => set.duration > 0)) {
                            if ((this.props.session.target.equipment.includes('Barbells') || this.props.session.target.equipment.includes('Dumbbells'))) {
                                if (this.state.sets.every((set) => set.weight > 0)) {
                                    return true 
                                }
                            } else return true                       
                        }
                    } else {
                        if (this.state.sets.every((set) => set.reps > 0)) {
                            if ((this.props.session.target.equipment.includes('Barbells') || this.props.session.target.equipment.includes('Dumbbells'))) {
                                if (this.state.sets.every((set) => set.weight > 0)) {
                                    return true 
                                }
                            } else return true                        
                        }
                    }
                }
            } else {
                if (this.state.hours * 3600 + this.state.minutes * 60 + this.state.seconds > 0) return true 
            }
        }

        return false 
    }

	render() {
        return (
            <Modal direction='up' in={this.props.open} handleClose={this.handleClose} head={`Update ${this.props.session ? this.props.session.target.name : "item"}`} functions={[
                this.props.dash.sessionLoading ? <CircularProgress size={20} style={{ color: 'white' }}/> : <DeleteIcon style={{ fontSize: '20px', color: 'white', marginBottom: '50%', backgroundColor: Color.CALORIES_ERROR }} onClick={() => this.handleDelete(this.props.session, this.props.workout)}/>
            ]}>
                <CardFloat>
                    <CardContent>
                        { this.renderSets() }
                    </CardContent>
                </CardFloat>
                
                <ModalButton success={this.state.success} handleClick={this.handleUpdate} loading={this.props.dash.sessionLoading} disabled={!this.confirmSubmit()} label={`Update ${this.state.sets.length} Sets`}/>
            </Modal>
        )
	}
}

function mapStateToProps(state) {
	return {
        auth: state.auth,
		dash: state.dash,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        deleteSession,
        updateSession }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingUpdateModalFitness)

