//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { loginUser, loadUser, changePassword } from '../actions/authActions'
//Components and Pages
import { ModalButton, Input } from './'
import { MdOutlineShortcut } from 'react-icons/md'

const defaultState = {
    new_password : '',
    password : '',
    verify_password : '',
    success: false,
    error: false
}

class ChangePasswordModal extends Component {
    constructor (props) {
        super(props)
        this.state = { ...defaultState }
    }

    handleChange = (question, answer) => {
        this.setState( { [question]: answer })
    }

    handleClose = () => {
        this.setState(defaultState)
        this.props.handleClose()
    }

    handleSubmit = () => {
        this.props.changePassword(this.props.auth.user._id, { password: this.state.password, new_password: this.state.new_password }).then((response) => {
            this.handleSuccess()
        }).catch((error) => {
            this.handleError(error.response.data.msg)
        })
    }

    handleSuccess = () => {
        this.setState({ success: true })
        setTimeout(this.handleClose, 400)
    }

    handleError = (error) => {
        this.setState({ error: true, errorMessage: error })
        setTimeout(() => {
            this.setState({ error: false, errorMessage: null })
        }, 1000)
    }

    verifyButton = () => {
        return this.state.verify_password !== '' && this.state.new_password !== '' && this.state.password !== '' && this.state.verify_password === this.state.new_password
    }
    
	render() {
		return (
            <div>
                <div>
                    <Input 
                        type="password"
                        style={{ width: '100%' }}
                        error={this.state["Servings"] <= 0}
                        value={this.state.password} 
                        placeholder="Current Password"
                        onChange = {(event) => {
                        this.handleChange("password", event.target.value)}}/>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '100%' }}> 
                        <Input 
                            type="password"
                            style={{ width: '100%' }}
                            value={this.state.new_password} 
                            placeholder="New Password"
                            onChange = {(event) => {
                            this.handleChange("new_password", event.target.value)}}/>
                    </div>
                    <div style={{ marginLeft: '10px', width: '100%' }}> 
                        <Input 
                            type="password"
                            style={{ width: '100%' }}
                            value={this.state.verify_password} 
                            placeholder="Verify Password"
                            error={this.state.verify_password !== this.state.new_password}
                            onChange = {(event) => {
                            this.handleChange("verify_password", event.target.value)}}/>
                    </div>
                </div>
                <ModalButton icon={<MdOutlineShortcut/>} style={{ marginBottom: '0px' }} error={this.state.error} errorMessage={this.state.errorMessage} success={this.state.success} label={'Save'} handleClick={this.handleSubmit} loading={this.props.auth.changePasswordLoading} disabled={!this.verifyButton()}/>
            </div>
		)
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
		sbar: state.sbar
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	 	loginUser: loginUser,
        loadUser: loadUser,
        changePassword: changePassword }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal)
