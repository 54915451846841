import { dashActionTypes } from '../actions/types'

const initialState = {
    search: { searchLoading: false, nutritionResults: {}, fitnessResults: {}, cancelToken: null },
    sessionStats: {},
    predictiveBuffer: { macros: {}, settings: {} },
    predictive: {},
    preferenceBuffer: [],
    predictiveLoading: false,
    predictiveLoaded: false,
    preferences: [],
    sessions: [],
    sessionBuffer: { min: null, max: null },
    notifications: [],
    mealPlan: null,
    mealPlanLoading: false,
    plan: [],
    buffer: [],
    history: [],
    customFoods: [],
    customWorkouts: [],
    customActivities: [],
    activities: [],
    articles: [],
    articleBuffer: [],
    modules: [],
    articleLoading: false,
    moduleLoading: false,
    moduleLoaded: false,
    servingLoading: false,
    sessionLoading: false,
    sessionStatsLoading: false,
    sessionStatsLoaded: false,
    historyLoading: false,
    customLoading: false,
    customFoodLoaded: false,
    customWorkoutsLoaded: false,
    customActivitiesLoaded: false,
    customAddLoading: false,
    bugReportLoading: false,
    bugReport: false,
    tutorial: false
}

export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case dashActionTypes.CLEAR_DASHBOARD : {
            return initialState
        }

        /*
            Tutorial reducers
        */

        case dashActionTypes.TUTORIAL_OPEN : {
            return ({
                ...state,
                tutorial: true
            })
        }

        case dashActionTypes.TUTORIAL_CLOSE : {
            return ({
                ...state,
                tutorial: false
            })
        }

        /*
            Meal plan reducers
        */

        case dashActionTypes.MEALPLAN_LOADING: {
            return ({
                ...state,
                mealPlanLoading: true
            })
        }

        case dashActionTypes.MEALPLAN_LOADED: {
            return ({
                ...state,
                mealPlanLoading: false,
                mealPlan: action.payload
            })
        }

        case dashActionTypes.MEALPLAN_ERROR : {
            return ({
                ...state,
                mealPlanLoading: false
            })
        }

        /*
            Module reducers
        */

        case dashActionTypes.MODULE_LOADING : {
            return ({
                ...state,
                moduleLoading: true
            })
        }

        case dashActionTypes.MODULE_GET_SUCCESS : {
            return ({
                ...state,
                moduleLoading: false,
                moduleLoaded: true,
                modules: [...state.modules, ...action.payload]
            })
        }

        case dashActionTypes.MODULE_GET_ERROR : {
            return ({
                ...state,
                moduleLoading: false
            })
        }

        case "ARTICLE_GET_LOADING" : {
            return ({
                ...state,
                articleLoading: true,
                articleBuffer: action.payload ? action.payload.buffer : []
            })
        }

        case "ARTICLE_GET_SUCCESS" : {
            let articles = state.articles, ids = action.payload.data.map(article => article._id), modules = state.modules
            let module = modules.filter((mod) => mod._id === action.payload.module_id)[0]

            if (module) {
                modules = modules.filter((mod) => mod._id !== module._id)
                module.articles.map((article, i) => {
                    for (let entry in action.payload.data) {
                        if (action.payload.data[entry]._id === article.article._id) {
                            module.articles[i] = {...module.articles[i], article: action.payload.data[entry]}
                        }
                    }

                    return article
                })

                modules = [...modules, module]
            } else {
                articles = articles.filter(article => !ids.includes(article._id))
                articles = [...articles, ...action.payload.data]
            }

            return ({
                ...state,
                articles,
                modules,
                articleBuffer: state.articleBuffer.filter(article => !ids.includes(article._id)),
                articleLoading: false
            })
        }

        case dashActionTypes.SESSION_LOADING : {
            let sessionBuffer = state.sessionBuffer
            if (action.payload) {
                if (action.payload.min < sessionBuffer.min || sessionBuffer.min === null) sessionBuffer.min = action.payload.min
                if (action.payload.max > sessionBuffer.max || sessionBuffer.max === null) sessionBuffer.max = action.payload.max
            }

            return ({
                ...state,
                sessionLoading: true,
                sessionBuffer
            })
        }

        case dashActionTypes.SESSION_LOAD_SUCCESS: {
            let preferences = state.sessions
            if (action.payload.length) {
                preferences.map((preference, i) => {
                    for (let j = 0; j < action.payload.length; j++) {
                        if (preference.target_id === action.payload[j]._id) {
                            state.sessions[i].target = action.payload[j]
                            break
                        }
                    }

                    return null
                })
            }

            return ({
                ...state,
                sessionLoading: false,
                sessions: preferences
            })
        }

        case dashActionTypes.SESSION_ADD_SUCCESS : {
            return ({
                ...state,
                sessions: [...state.sessions, ...action.payload],
                sessionLoading: false
            })
        }

        case dashActionTypes.SESSION_ADD_ERROR : {
            return ({
                ...state,
                sessionLoading: false
            })
        }

        case dashActionTypes.SESSION_GET_SUCCESS : {
            let sessions = state.sessions, addition = action.payload
            sessions.map((session) => {
                let match = addition.filter((payload) => payload._id === session._id)
                if (match) {
                    addition = addition.filter((payload) => payload._id !== session._id)
                    return match
                }
                return session
            })

            return ({
                ...state,
                sessions: [...sessions, ...addition],
                sessionLoading: false
            })
        }

        case dashActionTypes.SESSION_GET_ERROR : {
            return ({
                ...state,
                sessionLoading: false
            })
        }

        case dashActionTypes.SESSION_UPDATE_SUCCESS : {
            return ({
                ...state,
                sessionLoading: false,
                sessions: [...state.sessions.filter((session) => session._id !== action.payload._id), action.payload]
            })
        }

        case dashActionTypes.SESSION_UPDATE_ERROR : {
            return ({
                ...state,
                sessionLoading: false
            })
        }

        case dashActionTypes.SESSION_DELETE_SUCCESS : {
            return ({
                ...state,
                sessionLoading: false,
                sessions: state.sessions.filter((session) => session._id !== action.payload._id)
            })
        }

        case dashActionTypes.SESSION_DELETE_ERROR : {
            return ({
                ...state,
                sessionLoading: false
            })
        }

        case dashActionTypes.SESSION_STATS_LOADING : {
            return ({
                ...state,
                sessionStatsLoading: true
            })
        }

        case dashActionTypes.SESSION_STATS_GET_SUCCESS : {
            return ({
                ...state,
                sessionStatsLoading: false,
                sessionStatsLoaded: true,
                sessionStats: action.payload
            })
        }

        case dashActionTypes.SESSION_STATS_GET_ERROR : {
            return ({
                ...state,
                sessionStatsLoading: false,
                sessionStatsLoaded: true
            })
        }
        
        case dashActionTypes.PREFERENCES_LOADING : {
            return ({
                ...state,
                preferencesLoading: true,
                preferenceBuffer: action.payload ? [...state.preferenceBuffer, ...action.payload] : state.preferenceBuffer,
                ...(action.payload ? { favoritesSort: action.payload } : {})
            })
        }

        case dashActionTypes.PREFERENCES_LOAD_SUCCESS : {
            let preferences = state.preferences
            if (action.payload.length) {
                preferences.map((preference, i) => {
                    for (let j = 0; j < action.payload.length; j++) {
                        if (preference.target_id === action.payload[j]._id) {
                            state.preferences[i].target = action.payload[j]
                            break
                        }
                    }

                    return null
                })
            }

            return ({
                ...state,
                preferencesLoading: false,
                preferences
            })
        }

        case dashActionTypes.PREFERENCES_LOAD_ERROR : {
            return ({
                ...state,
                preferencesLoading: false
            })
        }

        case dashActionTypes.PREFERENCES_GET_SUCCESS : {
            return ({
                ...state,
                preferencesLoading: false,
                preferencesLoaded: true,
                preferences: action.payload
            })
        }

        case dashActionTypes.PREFERENCES_GET_ERROR : {
            return ({
                ...state,
                preferencesLoading: false
            })
        }

        case dashActionTypes.PREFERENCES_ADD_SUCCESS : {
            return ({
                ...state,
                preferencesLoading: false,
                preferences: [...state.preferences, action.payload]
            })
        }

        case dashActionTypes.PREFERENCES_ADD_ERROR : {
            return ({
                ...state,
                preferencesLoading: false
            })
        }

        case dashActionTypes.PREFERENCES_UPDATE_SUCCESS : {
            let preferences = state.preferences
            for (let i = 0; i < preferences.length; i++) {
                if (preferences[i]._id === action.payload._id) {
                    preferences[i] = { ...preferences[i], ...action.payload }
                    break
                }
            }

            return ({
                ...state,
                preferencesLoading: false,
                preferences
            })
        }

        case dashActionTypes.PREFERENCES_UPDATE_ERROR : {
            return ({
                ...state,
                preferencesLoading: false
            })
        }

        case dashActionTypes.PREFERENCES_DELETE_SUCCESS : {
            return ({
                ...state,
                preferencesLoading: false,
                preferences: state.preferences.filter(preference => preference._id !== action.payload)
            })
        }

        case dashActionTypes.PREFERENCES_DELETE_ERROR : {
            return ({
                ...state,
                preferencesLoading: false
            })
        }

        case dashActionTypes.BUG_REPORT_TOGGLE : {
            return ({
                ...state,
                bugReport: !state.bugReport
            })
        }

        case dashActionTypes.BUG_REPORT_LOADING : {
            return ({
                ...state,
                bugReportLoading: true 
            })
        }

        case dashActionTypes.BUG_REPORT_SUCCESS : {
            return ({
                ...state,
                bugReportLoading: false,
                bugReport: false
            })
        }

        case dashActionTypes.BUG_REPORT_ERROR : {
            return ({
                ...state,
                bugReportLoading: false
            })
        }

        case dashActionTypes.CUSTOM_LOADING : {
            return ({
                ...state,
                customLoading: true,
                ...(action.payload ? { customSort: action.payload } : {})
            })
        }

        case dashActionTypes.CUSTOM_FOOD_LOAD_SUCCESS : {
            return ({
                ...state,
                customFoods: action.payload,
                customFoodLoaded: true,
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_FOOD_LOAD_ERROR : {
            return ({
                ...state,
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_FOOD_ADD_SUCCESS : {
            return ({
                ...state,
                customFoods: [...state.customFoods, action.payload],
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_FOOD_ADD_ERROR : {
            return ({
                ...state,
                customLoading: false 
            })
        }

        case dashActionTypes.CUSTOM_FOOD_UPDATE_SUCCESS : {
            return ({
                ...state,
                customFoods: [...state.customFoods.filter(food => food._id !== action.payload._id), action.payload],
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_FOOD_UPDATE_ERROR : {
            return ({
                ...state,
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_FOOD_DELETE_SUCCESS : {
            return ({
                ...state,
                customFoods: state.customFoods.filter(food => food._id !== action.payload._id),
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_FOOD_DELETE_ERROR : {
            return ({
                ...state,
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_WORKOUT_LOAD_SUCCESS : {
            return ({
                ...state,
                customWorkouts: [...state.customWorkouts, ...action.payload],
                customLoading: false,
                customWorkoutsLoaded: true
            })
        }

        case dashActionTypes.CUSTOM_WORKOUT_LOAD_ERROR : {
            return ({
                ...state,
                customLoading: false 
            })
        }

        case dashActionTypes.CUSTOM_WORKOUT_ADD_SUCCESS : {
            return ({
                ...state,
                customWorkouts: [...state.customWorkouts, action.payload],
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_WORKOUT_ADD_ERROR : {
            return ({
                ...state,
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_WORKOUT_UPDATE_SUCCESS : {
            return ({
                ...state,
                customWorkouts: [...state.customWorkouts.filter(food => food._id !== action.payload._id), action.payload],
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_WORKOUT_UPDATE_ERROR : {
            return ({
                ...state,
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_WORKOUT_DELETE_SUCCESS : {
            return ({
                ...state,
                customWorkouts: state.customWorkouts.filter(food => food._id !== action.payload._id),
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_WORKOUT_DELETE_ERROR : {
            return ({
                ...state,
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_ACTIVITY_LOAD_SUCCESS : {
            return ({
                ...state,
                customActivities: [...state.customActivities, ...action.payload],
                customActivitiesLoaded: true,
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_ACTIVITY_LOAD_ERROR : {
            return ({
                ...state,
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_ACTIVITY_ADD_SUCCESS : {
            return ({
                ...state,
                customActivities: [...state.customActivities, action.payload],
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_ACTIVITY_ADD_ERROR : {
            return ({
                ...state,
                customLoading: false 
            })
        }

        case dashActionTypes.CUSTOM_ACTIVITY_UPDATE_SUCCESS : {
            return ({
                ...state,
                customActivities: [...state.customActivities.filter(food => food._id !== action.payload._id), action.payload],
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_ACTIVITY_UPDATE_ERROR : {
            return ({
                ...state,
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_ACTIVITY_DELETE_SUCCESS : {
            return ({
                ...state,
                customActivities: state.customActivities.filter(food => food._id !== action.payload._id),
                customLoading: false
            })
        }

        case dashActionTypes.CUSTOM_ACTIVITY_DELETE_ERROR : {
            return ({
                ...state,
                customLoading: false
            })
        }

        case "LOAD_PLAN" : {
            return ({
                ...state,
                plan : action.payload.days
            })
        }

        case dashActionTypes.HISTORY_LOADING : {
            return ({
                ...state,
                historyLoading: true
            })
        }

        case dashActionTypes.HISTORY_GET_SUCCESS : {
            return ({
                ...state,
                historyLoading: false,
                history: action.payload
            })
        }

        case dashActionTypes.HISTORY_GET_ERROR : {
            return ({
                ...state,
                historyLoading: false 
            })
        }

        case dashActionTypes.HISTORY_ADD_SUCCESS : {
            return ({
                ...state,
                historyLoading: false,
                history: [...state.history, action.payload]
            })
        }

        case dashActionTypes.HISTORY_ADD_ERROR : {
            return ({
                ...state,
                historyLoading: false
            })
        }

        case dashActionTypes.CREATE_NOTIFICATION : {
            return ({
                ...state,
                notifications : [...state.notifications, action.payload]
            })
        }

        case dashActionTypes.REMOVE_NOTIFICATION : {
            return ({
                ...state,
                notifications: state.notifications.filter((notification) => notification.msg !== action.payload.msg)
            })
        }

        case "PLAN_LOADING" : {
            let dates = action.payload.map((day) => {
                return day.date
            })

            return ({
                ...state,
                buffer: [...state.buffer, ...dates]
            })
        }

        case "PLAN_LOAD_SUCCESS" : {
            let days = state.plan, buffer = state.buffer

            for (let time in action.payload) {
                days = days.filter((day) => day.date !== action.payload[time].date)
                buffer = buffer.filter((day) => day !== action.payload[time].date)
            }

            days = [...days, ...action.payload]
            return ({
                ...state,
                plan: days,
                buffer: buffer
            })
        }

        //Search Actions 
        case dashActionTypes.NUTRITION_SEARCH_LOADING : 
            return ({
                ...state,
                search: {
                    ...state.search,
                    searchLoading: true,
                    nutritionResults: {},
                    cancelToken: action.payload
                }
            })
        
        case dashActionTypes.NUTRITION_SEARCH_SUCCESS :
            return ({
                ...state,
                search : {
                    ...state.search,
                    searchLoading: false,
                    nutritionResults : action.payload,
                    cancelToken: null
                }
            })
        
        case dashActionTypes.NUTRITION_SEARCH_ERROR : 
            return ({
                ...state,
                search: {
                    ...state.search,
                    searchLoading: false,
                    nutritionResults: {},
                    cancelToken: null
                }
            })

        case dashActionTypes.FITNESS_SEARCH_LOADING :
            return ({
                ...state,
                search: {
                    ...state.search,
                    searchLoading: true,
                    fitnessResults: []
                }
            })
        

        case dashActionTypes.FITNESS_SEARCH_SUCCESS :
            return ({
                ...state,
                search: {
                    ...state.search,
                    searchLoading: false,
                    fitnessResults: action.payload
                }
            })
        
        case dashActionTypes.FITNESS_SEARCH_ERROR :
            return ({
                ...state,
                search: {
                    ...state.search,
                    searchLoading: false,
                    fitnessResults: []
                }
            })

        case dashActionTypes.PREDICTIVE_LOADING : {
            return ({
                ...state,
                predictiveBuffer: { macros: action.payload.macros, settings: action.payload.settings },
                predictiveError: false,
                predictiveLoading: true
            })
        }

        case dashActionTypes.PREDICTIVE_SUCCESS : {
            return ({
                ...state,
                predictiveBuffer : { macros: action.payload.macros, settings: action.payload.settings },
                predictive : action.payload.plan,
                predictiveLoading: false,
                predictiveLoaded: true
            })
        }

        case dashActionTypes.PREDICTIVE_ERROR : {
            return ({
                ...state,
                predictiveLoading: false,
                predictiveError: true
            })
        }

        case "SUBSCRIPTION_LOAD_SUCCESS" :
            return ( {
                ...state,
                settings : {
                    ...state.settings,
                    subscription: action.payload,
                }
            })

        case "SUBSCRIPTION_CANCEL" : 
            return ( {
                ...state,
                settings : {
                    ...state.settings,
                    subscription: action.payload,
                    changeLoading: false
                }
            })
        case "SUBSCRIPTION_RENEW" :
            return ( {
                ...state,
                settings : {
                    ...state.settings,
                    subscription: action.payload,
                    changeLoading: false
                }
            })

        default:
            return state
    }
}
