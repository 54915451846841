//Classes and Functions
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateProfile, activateUser, generatePlans } from '../actions/authActions'
//Components and Pages
import { LinearProgress, Fade } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import { PageCard, PageHandler, Page, ModalButton, Question } from '../components'
import { Measure, Color, questions, health_conditions, diets, allergies } from '../util'
import styled from 'styled-components'
//Images
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import stnbly from '../images/sustainablyapp.svg'
import { MdOutlineShortcut } from 'react-icons/md'

const QuestionHeader = styled.h6`
	font-size: 16px;
	text-align: center;
	margin-bottom: ${(props) => props.hasSubheader ? '5px' : '20px' };
`

const QuestionSubheader = styled.h6`
	font-size: 14px;
	text-align: center;
	margin-bottom: 20px;
	opacity: .5;
`

const BorderLinearProgress = withStyles(theme => ({
	root: {
	  height: 7,
	  borderRadius: 10
	},
	colorPrimary: {
		backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
	},
	bar: {
	  borderRadius: 5,
	  backgroundColor: Color.PRIMARY
	}
  }))(LinearProgress);

class Onboarding extends Component {
	constructor (props) {
		super(props)
		let params = new URLSearchParams(this.props.location.search)
		this.state = {
			error: {},
			answers: {
				unit: 'Imperial',
				starting_weight: null,
				target_weight: null,
				height: null,
				dob: Date.now() - 1000 * 60 * 60 * 24 * 365 * 25,
				starting_body_fat: null,
				pace: null,
				activity_level: null,
				health_conditions_active: null,
				health_conditions: [],
				rec_convenient: [],
				allergies: []
			},
			tabs: [
				{
					active: true,
					header: 'Gender',
					questions: [
						{
							name: 'gender',
							header: `What's your gender?`,
							type: 'select',
							values: ['Male', 'Female', 'Other', 'Prefer not to say'],
							save_state: 'profile'
						},
						{
							name: 'dob',
							header: `What's your birthday?`,
							type: 'dob',
							save_state: 'profile'
						}
					]
				},
				{
					active: true,
					header: 'Basics',
					questions: [
						{
							name: 'unit',
							header: `What's your preferred measurement?`,
							type: 'select',
							values: ['Imperial', 'Metric'],
							options: {
								labels: {
									Imperial: 'Imperial (lb, ft)',
									Metric: 'Metric (kg, m)'
								}
							}
						},
						{
							name: 'starting_weight',
							header: `What's your current weight?`,
							measure: 'mass',
							type: 'weight',
							options: {
								placeholder: 'Weight'
							}
						},
						{
							name: 'target_weight',
							header: `What's your target weight?`,
							measure: 'mass',
							type: 'weight',
							options: {
								placeholder: 'Target Weight'
							}
						},
						{ 
							name: 'height',
							header: `What's your height?`,
							measure: 'length',
							type: 'height',
							options: {
								placeholder: 'Height'
							}
						}
					]
				},
				{
					active: true,
					header: 'Body Fat',
					questions: [
						{
							name: 'starting_body_fat',
							header: `What's your estimated body fat?`,
							type: 'body_fat_percentage'
						}
					]
				},
				{
					active: true,
					header: 'Pace',
					questions: [
						{
							name: 'pace',
							header: `How fast do you want to hit your goal?`,
							subheader: `We'll design an exact plan that works for you and your goals`,
							values: [1, 2, 3],
							type: 'select',
							options: {
								labels: {
									[1]: {
										header: 'Slow',
										subheader: 'I prefer slow, steady, and easy'
									},
									[2]: {
										header: 'Medium',
										subheader: 'I want results with a moderate challenge'
									},
									[3]: {
										header: 'Fast',
										subheader: 'I want to be pushed and challenged'
									}
								}
							}

						},
						{
							name: 'activity_level',
							header: `I would describe my activity level as...`,
							values: [1, 2, 3],
							type: 'select',
							options: {
								labels: {
									[1]: {
										header: 'Sedentary',
										subheader: 'I spend my day seated'
									},
									[2]: {
										header: 'Lightly Active',
										subheader: 'I walk around when I have time'
									},
									[3]: {
										header: 'Active',
										subheader: 'I exercise, play sports, or move the majority of the day'
									}
								}
							}
						},
						{
							name: 'health_conditions_active',
							header: `Are you currently diagnosed with any health conditions?`,
							subheader: `(Diabetes, hypertension, etc...)`,
							type: 'select',
							exclude: true,
							values: ['Yes', 'No'],
							options: {
								actions: {
									Yes: () => this.handleActiveChange('health_conditions', true),
									No: () => this.handleActiveChange('health_conditions', false)
								} 
							}
						}
					]
				},		
				{
					active: false,
					header: 'Health Conditions',
					questions: [
						{
							name: 'health_conditions',
							header: 'Health Conditions',
							subheader: 'Select all that apply',
							type: 'select',
							values: health_conditions,
							options: {
								multi: true,
								capitalized: false
							}
						}
					]
				},		
				{
					active: true,
					header: 'Diet and Preference',
					questions: [
						{
							name: 'diet',
							header: `What's your diet type?`,
							type: 'select',
							values: diets
						},
						{
							name: 'fat_percentage',
							header: 'Which foods do you prefer eating regularly?',
							type: 'select',
							values: [.35, .30, .33],
							options: {
								labels: {
									[.35]: {
										header: 'Fats',
										subheader: 'I prefer cheese, nuts, avocados and other fats'
									},
									[.30]: {
										header: 'Carbs',
										subheader: 'I prefer bread, pasta, chips, rice and other carbs'
									},
									[.33]: {
										header: 'Balanced',
										subheader: 'I prefer a mixture of both'
									}
								}
							}
						},
						{
							name: 'allergies_active',
							header: 'Do you have any allergies, dietary restrictions, or preferences?',
							type: 'select',
							exclude: true,
							values: ['Yes', 'No'],
							options: {
								actions: {
									Yes: () => this.handleActiveChange('allergies', true),
									No: () => this.handleActiveChange('allergies', false)
								}
							}
						}
					]
				},		
				{
					active: false,
					header: 'Allergies',
					questions: [
						{
							name: 'allergies',
							type: 'select',
							header: 'Allergies & Preferences',
							subheader: 'Select all that apply',
							values: allergies,
							options: {
								multi: true,
								multi_negative: true
							}
						}
					]
				},		
				{
					active: true,
					header: 'Recommended 1',
					questions: [
						{
							name: 'rec_convenient',
							header: 'When do you prefer convenient foods?',
							subheader: 'No heat, low assembly required',
							type: 'select',
							values: ['breakfast', 'lunch', 'dinner'],
							options: {
								multi: true
							}
						},
						{
							name: 'rec_restaurants',
							type: 'select',
							header: 'How often do you typically dine out, get takeout, or get delivery each week?',
							values: [0, 1, 2, 3],
							options: {
								labels: {
									[0]: 'None',
									[1]: '1-3',
									[2]: '3-5',
									[3]: '5 or more'
								}
							}
						}
					]
				},
				{
					active: true,
					header: 'Recommended 2',
					questions: [
						{
							name: 'rec_cooking',
							header: 'When do you prefer cooking?',
							type: 'select',
							values: [0, 1, 2, 3, 4],
							options: {
								labels: {
									[0] : 'None',
									[1] : 'Sometimes',
									[2] : 'About half',
									[3] : 'Most of the time',
									[4] : 'All'
								}
							}	
						}
					]
				},
				{
					active: true,
					header: 'Recommended 3',
					questions: [
						{
							name: 'rec_meal_prep',
							header: 'Do you want meal prep cooking options?',
							subheader: '(foods that can be made in large batches)',
							type: 'select',
							values: [true, false],
							options: {
								labels: {
									[true]: 'Yes',
									[false]: 'No'
								}
							}
						},
						{
							name: 'rec_protein_powder',
							header: 'Do you regularly use a protein powder?',
							type: 'select',
							values: [true, false],
							options: {
								labels: {
									[true]: 'Yes',
									[false]: 'No'
								}
							}						
						}
					]
				},
				{
					active: true,
					header: 'Generation Calendar',
					questions: [
						{
							name: 'rec_gen_map',
							header: 'Fill in your meal planner',
							subheader: 'Tap to plan out your week of eating for meal plans and recommended options',
							type: 'generation_calendar'
						}
					],
					options: {
						onRender: this.handleGenMapPrefill
					}
				}
			],		
			page: Number(params.get('p') || 0),
			tab_length: Object.entries(questions.tt).length,
			loading: false
		}
	}

	async componentDidMount() {
		if (this.props.auth.user.subscription.status !== 'active' && !this.props.auth.user.admin) this.props.history.push('/activate/checkout')
		let index = await this.loadAnswers()
		if (this.state.tab > index || this.state.tab === 0) this.setState({ tab: index })
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.search !== this.props.location.search) {
			let prev_params = new URLSearchParams(prevProps.location.search)
			let params = new URLSearchParams(this.props.location.search)
			if (prev_params.get('p') !== params.get('p')) {
				this.setState({ tab: Number(params.get('p') || 0 )})
			}
		}
	}

	loadAnswers = async () => {
		let { settings } = this.props.auth.user 
		let answers = this.state.answers

		for (let i = 0; i < this.state.tabs.length; i++) {
			let tab = this.state.tabs[i]
			for (let j = 0; j < tab.questions.length; j++) {
				let question = tab.questions[j], answer = this.formatLoadAnswer(question.name, question.save_state === 'profile' ? this.props.auth.user[question.name] : settings[question.name])
				if (question.exclude) continue
				if (answer === null) {
					this.setState({ answers })
					return i
				} else {
					answers[question.name] = answer
				}
			}
		}

		try {
			await this.props.activateUser(this.props.auth.user._id)
			this.props.history.push('/dashboard')
		} catch (error) {
			console.log(error)
		}
	}

	formatLoadAnswer = (question, answer) => {
		switch (question) {
			case ('physical_limitations_active'): return this.props.auth.user.settings.physical_limitations?.length ? 'Yes' : 'No'
			case ('allergies_active'): return this.props.auth.user.settings.allergies?.length ? 'Yes' : 'No'
 			case ('health_conditions_active'): return this.props.auth.user.settings.health_conditions?.length ? 'Yes' : 'No'
			default: return answer
		}
	}

	formatAnswer = (question, answer) => {
		switch (question) {
			case ('height_meters') :
			case ('height_inches') :
			case ('height_feet') : 
			case ('breakfast') :
			case ('lunch') :
			case ('dinner') :
			case ('day') :
			case ('month') :
			case ('year') :
			case ('emotional') : return null
			case ('target_weight') : 
			case ('starting_weight') : return this.adjustMeasure("mass", answer)
			case ('height') :
			default: return answer
		}
	}

	handleBack = () => {
		let page = Number(this.state.page) - 1
		while (this.state.tabs[page] && this.state.tabs[page].active === false) page -= 1
		window.scrollTo(0, 0)
		this.setState({ page: page < 0 ? 0 : page })	
		this.props.history.push(`/activate/onboarding?p=${page}`)
	}

	handleContinue = async (iterator) => {
		try {
			const time = new Date()
			await this.handleUpdate(this.state.tabs[this.state.page])
			if (this.state.page === this.state.tabs.length - 1) {
				await this.props.activateUser(this.props.auth.user._id)
				await this.props.generatePlans(this.props.auth.user._id, { offset: time.getTimezoneOffset() })
				return this.props.history.push('/dashboard')
			}
			this.setState({ loading: false })
			this.handleContinueTab(iterator)
		} catch (error) {
			console.log(error)
			this.setState({ loading: false })
		}
	}

	handleContinueTab = (iterator) => {
		let page = Number(this.state.page) + ((typeof iterator) === 'number' ? iterator : 1)
		if (this.state.tabs[page]) while (this.state.tabs[page].active === false) page += 1
		window.scrollTo(0, 0)
		this.setState({ page })
		this.props.history.push(`/activate/onboarding?p=${page}`)
		return page
	}

	handleUpdate = async (tab) => {
		let updates = { profile: {}, settings: {}, preserve: ['settings.rec_cooking'] }
		if (!tab.active) return

		for (let i in tab.questions) {
			let question = tab.questions[i], answer = this.formatAnswer(question.name, this.state.answers[question.name])
			if (!answer || question.exclude) continue
			updates[question.save_state ? question.save_state : 'settings'][question.name] = answer
		}
		
		this.setState({ loading: true }) 
		if (!Object.entries(updates.settings).length) delete updates.settings
		return this.props.updateProfile(this.props.auth.user._id, updates)
	}

	adjustMeasure = (type, num, base, unit) => {
		if (!base) return Math.round(num * Measure[unit || this.state.answers.unit][type].mult * 10) / 10
		return Math.round(num / Measure[unit || this.state.answers.unit][type].mult * 10) / 10
    }

	verifyQuestion = (question, answer) => {
		switch (question.name) {
			case ('height') : return answer > 40
			case ('starting_weight'): 
			case ('target_weight'): return !(this.adjustMeasure("mass", answer) > 500 || this.adjustMeasure("mass", answer) < 70 || this.adjustMeasure("mass", answer) === "")
			case ('physical_limitations') :
			case ('allergies') :
			case ('health_conditions') :
			case ('dob'):
			case ('rec_convenient') : return true
		
			default : {
				if (Array.isArray(question.answer)) {
					if (answer.length > 0) {
						return true
					}
				} else if (answer !== undefined && answer !== null && answer !== "") {
					return true
				}
			}
		}

		return false
	}

	verifyEntries = (tab) => {
		let questions = tab.questions, valid = 0, count = 0
		for (let i = 0; i < questions.length; i++) {
			if (this.verifyQuestion(questions[i], this.state.answers[questions[i].name])) valid +=1
			count += 1
		} 

		return Math.round(valid / count * 100)
	}

	handleChange = (question, value) => {
		let answers = {...this.state.answers}
		for (let i = 0; i < this.state.tabs.length; i++) {
			for (let j = 0; j < this.state.tabs[i].questions.length; j++) {
				if (this.state.tabs[i].questions[j].name === question) {
					answers[question] = value
					this.setState({ answers })
				}
			}
		}

		return null
	}

	handleActiveChange = (question, status) => {
		let temp = [...this.state.tabs]
		for (let i = 0; i < this.state.tabs.length; i++) {
			for (let j = 0; j < this.state.tabs[i].questions.length; j++) {
				if (this.state.tabs[i].questions[j].name === question) {
					temp[i].active = status
					return this.setState({ tabs: temp })
				}
			}
		}

		return null
	}

	handleError = (question, error) => {
		let tabs = this.state.tt
		tabs[Object.keys(tabs)[this.state.tab]][question] = {...tabs[Object.keys(tabs)[this.state.tab]][question], err: error}
		this.setState({ tt : tabs })
	}

	getRandomInt = (min, max) => {
		min = Math.ceil(min)
		max = Math.floor(max)
		return Math.floor(Math.random() * (max - min) + min)
	}

	handleGenMapPrefill = () => {
		let map = []
		for (let i = 0; i < 7; i++) map[i] = ['convenient', 'convenient', 'convenient']
		
		let restaurants = this.state.answers.rec_restaurants
		let cooking = [this.state.answers.rec_cooking, this.state.answers.rec_cooking, this.state.answers.rec_cooking]
		let meal_prep = this.state.answers.rec_meal_prep
		let meals = ['breakfast', 'lunch', 'dinner']

		// map cooking preferences
		meals.map((meal, i) => {
			switch (cooking[i]) {
				case (1) :
					for (let i = 0; i < 2; i++) {
						let ind = this.getRandomInt(0, 7)
						let mea = this.getRandomInt(0, 3)
						map[ind][mea] = 'cooked'
					}
					break
				case (2) :
					for (let i = 0; i < 5; i++) {
						let ind = this.getRandomInt(0, 7)
						let mea = this.getRandomInt(0, 3)
						map[ind][mea] = 'cooked'
					}
					break
				case (3) :
					for (let i = 0; i < 7; i++) {
						let ind = this.getRandomInt(0, 7)
						let mea = this.getRandomInt(0, 3)
						map[ind][mea] = 'cooked'
					}
					break
				case (4) :
					for (let i = 0; i < 9; i++) {
						let ind = this.getRandomInt(0, 7)
						let mea = this.getRandomInt(0, 3)
						map[ind][mea] = 'cooked'
					}
			}

			return null
		})

		// map meal_prep preferences
		if (meal_prep === true && this.state.answers.rec_cooking !== 0) {
			for (let i = 0; i < meals.length; i++) {
				for (let j = 0; j < 4; j++) {
					let ind = this.getRandomInt(0, 7)
					let mea = this.getRandomInt(1, 3)
					map[ind][mea] = 'mealprep'
				}
			}
		}

		// map restaurant preferences 
		switch (restaurants) {
			case (1):
				for (let i = 0; i < 3; i++) {
					let ind = this.getRandomInt(0, 7)
					let mea = this.getRandomInt(0, 3)
					map[ind][mea] = 'restaurant'
				}
				break
			case (2):
				for (let i = 0; i < 5; i++) {
					let ind = this.getRandomInt(0, 7)
					let mea = this.getRandomInt(0, 3)
					map[ind][mea] = 'restaurant'
				}
				break
			case (3):
				for (let i = 0; i < 7; i++) {
					let ind = this.getRandomInt(0, 7)
					let mea = this.getRandomInt(0, 3)
					map[ind][mea] = 'restaurant'
				}
		}

		return this.handleChange('rec_gen_map', map)
	}

	getInputAdornment = (type) => {
		switch(type) {
			case ('percent') : return '%'
			case ('feet') : return 'ft'
			case ('inches') : return 'in'
			case ('meters') : return 'm'
			default : return Measure[this.state.answers.unit]?.[type]?.unit || null
		}
	}

    renderTab = () => {
		return (
			<PageHandler tab={this.state.page}>
				{
					this.state.tabs.map((tab, i) => {
						return <Page key={i} onRender={tab?.options?.onRender}>
							<div style={{ display: 'flex', flexDirection: 'column', gap: '40px', padding: '10px' }}>
								{
									tab.questions.map((question, i) => {
										return (
											<div key={i}>
												{ question.header ? <QuestionHeader hasSubheader={question.subheader}> {question.header} </QuestionHeader>: null }
												{ question.subheader ? <QuestionSubheader> {question.subheader} </QuestionSubheader> : null }
												<Question 
													type={question.type} 
													name={question.name} 
													error={this.state.error[question.name]}
													onChange={question.options?.onChange || this.handleChange} 
													onBlur={() => {
														console.log('Checking error...')
														const status = this.verifyQuestion(question, this.state.answers[question.name])
														if (status !== true) this.setState({ error: {...this.state.error, [question.name]: status || true }})
														else this.setState({ error: { ...this.state.error, [question.name]: false } })
													}}
													values={question.values} 
													options={{
														...question.options,
														suff: this.getInputAdornment(question.measure),
														unit: this.state.answers.unit,
														gender: this.state.answers.gender
													}} 
													answer={this.state.answers[question.name]}/>
											</div>
										)
									})
								}
							</div>
						</Page>
					})
				}
			</PageHandler>
		)
	}

	render() {
		return (
			<div>
				<div style={{ width: '100%', height: '60px', borderBottom: '1px solid rgba(0,0,0,.1)', display: 'flex', justifyContent: 'center'}}>
					<div style={{ width: '600px', position: 'relative', display: 'flex', justifyContent: 'center' }}>
						<img alt='STNBLY Icon' src={stnbly} style={{ width: '45px' }} onClick={() => this.props.history.push('/dashboard')}/>
						<Fade in={this.state.page > 0}>
							<div onClick={this.handleBack}>
								<ArrowBackIcon style={{ fontSize: '22px', opacity: '.7', position: 'absolute', top: '18px', left: '18px', cursor: 'pointer' }}/>
							</div>
						</Fade>
					</div>
				</div>
				
				<PageCard title='Get Started' style={{ maxWidth: '600px', marginLeft: '50%', transform: 'translateX(-50%)', width: '100%' }}>
					<div style={{ margin: '10px 10px 30px 10px'}}>
						<BorderLinearProgress variant='determinate' value={this.state.page / this.state.tabs.length * 100} fontSize={40}/>
					</div>
					{ this.renderTab() }
				</PageCard>

				<ModalButton icon={<MdOutlineShortcut/>} style={window.innerWidth < 600 ? { position: 'fixed', bottom: '20px', marginLeft: '20px', marginRight: '20px', width: 'calc(100% - 40px)'} : { position: 'sticky', bottom: '20px', marginTop: '-20px', width: 'calc(600px - 40px)', marginLeft: '50%', transform: 'translateX(-50%)' }} handleClick={this.handleContinue} disabled={this.verifyEntries(this.state.tabs[this.state.page]) < 100} label='Continue' loading={this.state.loading}/>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateProfile,
		activateUser,
		generatePlans
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding)