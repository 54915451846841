import React, { Component } from 'react';
import { Skeleton } from '@material-ui/lab'
import { getAuthRequest } from '../util' 
import api from '../api'

class Image extends Component {
    constructor(props) {
        super(props)
        this.state = {
            imageSrc: null,
            imageRetrieved: false,
            imageLoading: false,
            imageLoaded: false
        }
    }

    componentDidMount() {
        if (this.props.image_id && !this.state.imageRetrieved && !this.state.imageLoading) {
            this.loadImage()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.image_id && prevProps.image_id !== this.props.image_id) {  
            this.setState({ imageSrc: null, imageLoaded: false, imageRetrieved: false })          
            this.loadImage()
        } 
    }

    loadImage = () => {
        let req = getAuthRequest()
        req.params =  { id: this.props.image_id }
        this.setState({ imageLoading: true })
        api.getImage(req).then((response) => {
            this.setState({ imageRetrieved: true, imageLoading: false, imageSrc: response.data.url })
        }).catch((error) => {
            this.setState({ imageRetrieved: true, imageLoading: false})
        })
    }

    render() {
        return (
            !this.props.image_id ? <div style={{ display: 'flex' }}>
                <img className={this.props.className} alt={this.props.alt} src={this.props.src} style={{ ...this.props.style, display: !this.state.imageLoaded ? 'none' : '' }} onLoad={() => {
                    this.setState({ imageLoaded: true })
                    if (this.props.onLoad) this.props.onLoad()
                }}/>
                <div style={{...this.props.style, ...this.props.loadingStyle, display: this.state.imageLoaded ? 'none' : '', backgroundColor: 'rgb(211,211,211)' }}/>
            </div> : <div style={{ display: 'flex' }}>
                { this.state.imageSrc ? <img className={this.props.className} alt={this.props.alt} src={this.state.imageSrc} style={{ ...this.props.style, display: !this.state.imageLoaded ? 'none' : '' }} onLoad={() => this.setState({ imageLoaded: true })}/> : null }
                <div style={{...this.props.style, ...this.props.loadingStyle, display: this.state.imageLoaded ? 'none' : '', backgroundColor: 'rgb(211,211,211)' }}/>
            </div>
        );
    }
}

export default Image;